import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import BasicConfirmationForModal from "../../../components/ui/BasicConfirmationForModal";
import styles from "../../../components/ui/ui.module.css";
import { History, SetState } from "../../../types";
import { getErrorMessage } from "../../error/getErrorMessage";
import useHandleOnChange from "../../form/useHandleOnChange";
import useModal, { OpenModal } from "../../modal/useModal";
import useQuery from "../../url/useQuery";
import useChannelRequest, {
  Channel,
  ChannelSearchForm,
  ChannelSearchView,
  DeleteChannel,
  SaveChannel,
} from "./useChannelRequest";

type State = {
  items: Channel[];
  filter: string;
  page: number;
  loading: boolean;
  total: number;
  totalItems: number;
};

const initialState: State = {
  filter: "",
  page: 1,
  total: 1,
  items: [],
  loading: false,
  totalItems: 1,
};

export default function useCompanyChannelPage() {
  const [state, setState] = useState<State>(initialState);

  const { openModal } = useModal();

  const history = useHistory();

  const { deleteChannel, saveChannel, searchChannel } = useChannelRequest();

  const { page: pageStr } = useParams<{ page: string }>();
  const page = parseInt(pageStr);

  const query = useQuery();

  const { filter: formFilter } = state;

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const findChannel = useCallback(
    () => baseFindOnSubmit(searchChannel, page, openModal, setState, filter),
    [page, filter, state]
  );

  const openChannelCreateModal = () =>
    openModal(
      <BaseOpenChannelSaveModal
        save={saveChannel}
        openModal={openModal}
        refresh={findChannel}
      />
    );

  const openChannelEditModal = (channel: Channel) =>
    openModal(
      <BaseOpenChannelSaveModal
        save={saveChannel}
        openModal={openModal}
        refresh={findChannel}
        channel={channel}
      />
    );

  const handleDeleteChannelOnConfirm = (id: number) =>
    baseHandleDeleteChannelOnConfirm(id, openModal, deleteChannel, findChannel);

  const openChannelDeleteModal = (id: number) =>
    openModal(
      <BasicConfirmationForModal
        message="Essa ação apagará o canal."
        onConfirm={() => handleDeleteChannelOnConfirm(id)}
      />
    );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFilterOnSubmit(history, formFilter, e),
    [formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [formFilter]
  );

  async function baseFindOnSubmit(
    search: (params: ChannelSearchForm) => Promise<ChannelSearchView>,
    page: number,
    openModal: OpenModal,
    setState: SetState<State>,
    filter: string
  ) {
    try {
      const pageSize = 100;
      setState((prev) => ({ ...prev, loading: true }));
      const response = await search({ filter, page, pageSize });
      setState((prev) => ({
        ...prev,
        items: response.items,
        total: response.total,
        totalItems: response.totalItems,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }

  useEffect(() => {
    findChannel();
  }, [page, filter]);

  return {
    ...state,
    handleOnChange,
    openChannelCreateModal,
    openChannelEditModal,
    openChannelDeleteModal,
    handleFindOnSubmit,
    handlePageOnChange,
  };
}

function baseHandleFilterOnSubmit(
  history: History,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();

  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("/company/channel/1?filter=" + filter);
}

function baseHandlePageOnChange(
  history: History,
  filter: string | null,
  page: number
) {
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("/company/channel/" + page + "?filter=" + filter);
}

function BaseOpenChannelSaveModal(props: {
  save: SaveChannel;
  openModal: OpenModal;
  refresh: () => Promise<void>;
  channel?: Channel;
}) {
  const { save, openModal, refresh, channel } = props;

  const [state, setState] = useState<{
    id: number | undefined;
    name: string;
    loading: boolean;
  }>({
    id: channel ? channel.id : undefined,
    name: channel ? channel.name : "",
    loading: false,
  });

  const { id, name, loading } = state;

  const handleOnSaveChannel = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      await save({ id, name });
      await refresh();
      openModal("Canal salvo com sucesso.");
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [save, id, name, refresh, openModal]);

  return (
    <div className={styles.center}>
      <div className={styles.left}>
        <Input
          value={name}
          onChange={(e) =>
            setState((prev) => ({ ...prev, name: e.target.value }))
          }
          placeholder="Digite..."
          label="Nome"
        />
      </div>

      <Button
        onClick={handleOnSaveChannel}
        type="confirm"
        disabled={!name || loading}
      >
        {loading ? "Carregando..." : "Salvar Canal"}
      </Button>
    </div>
  );
}

async function baseHandleDeleteChannelOnConfirm(
  id: number,
  openModal: OpenModal,
  deleteChannel: DeleteChannel,
  refresh: () => Promise<void>
) {
  try {
    await deleteChannel(id);
    await refresh();

    openModal("O Canal foi apagado");
  } catch (e: any) {
    openModal(getErrorMessage(e));
  }
}
