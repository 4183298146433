import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";

export type Category = {
  id: number;
  description: string;
  channel: string;
  channelId: number;
};

export type CategorySaveForm = {
  id: number | undefined;
  description: string;
  channelId: number | undefined;
  channel: string;
};

export type CategorySearchForm = {
  page?: number;
  pageSize?: number;
  filter?: string;
};

export type CategorySearchView = {
  items: Category[];
  total: number;
  page: number;
};

export type UseCategoryRequests = typeof UseCategoryRequest;
export type SaveCategory = ReturnType<UseCategoryRequests>["saveCategory"];
export type SearchCategory = ReturnType<UseCategoryRequests>["searchCategory"];
export type DeleteCategory = ReturnType<UseCategoryRequests>["deleteCategory"];

export default function UseCategoryRequest() {
  const saveCategory = (category: CategorySaveForm) =>
    baseSaveCategory(category);

  const searchCategory = (params: CategorySearchForm) =>
    baseSearchCategory(params);

  const deleteCategory = (id: number) => baseDeleteCategory(id);

  return {
    saveCategory,
    searchCategory,
    deleteCategory,
  };
}

async function baseSaveCategory(category: CategorySaveForm): Promise<number> {
  const { data } = await http.post(
    `${baseUrl}/api/v1/company/category/`,
    category
  );
  return data;
}

async function baseSearchCategory(
  params?: CategorySearchForm
): Promise<CategorySearchView> {
  const response = await http.get(`${baseUrl}/api/v1/company/category/list`, {
    params,
  });
  return response.data;
}

async function baseDeleteCategory(categoriaId: number): Promise<void> {
  await http.delete(`${baseUrl}/api/v1/company/category/${categoriaId}`);
}
