import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import useClientRequests, {
  FindById as FindClientById,
} from "../../../service/client/useClientRequests";
import { DashboardAssociationForm } from "../../../service/dashboard/types";
import useDashboardAssociationRequests, {
  FindById as FindAssociationById,
  Save,
} from "../../../service/dashboard/useDashboardAssociationRequests";
import useDashboardRequests, {
  FindById as FindDashboardById,
} from "../../../service/dashboard/useDashboardRequests";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useHandleOnChange from "../../../service/form/useHandleOnChange";
import useLoading, { StartLoading } from "../../../service/loading/useLoading";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import Button from "../../form/Button";
import useGroupRequests, {
  FindById as FindGroupById,
} from "../../user/group/useGroupRequests";
import useUserRequests, {
  FindById as FindUserById,
} from "../../user/user/useUserRequests";
import { AssociationTargetData } from "./types";

function useAssociateDashboardForm() {
  const { startLoading, isLoading } = useLoading();

  const { findById: findUserById } = useUserRequests();
  const { findById: findGroupById } = useGroupRequests();
  const { findById: findClientById } = useClientRequests();
  const { findById: findDashboardById } = useDashboardRequests();

  const [state, setState] = useState(initialState);

  const { associationTargetData: data } = state;

  const { openModal, closeModal } = useModal();

  const history = useHistory();

  const { id: pathId } = useParams<{ id?: string }>();

  const { findById, save } = useDashboardAssociationRequests();

  const handleOnChange = useHandleOnChange(setState);

  const handleAvailableOnCheck = () =>
    setState((prev) => ({ ...prev, available: !prev.available }));

  const contextAlert = getContextAlertText(
    state?.associationTargetData,
    pathId
  );

  const validation = useMemo(() => validate(state), [state]);

  const handleOnExpireNowClick = () =>
    setState((prev) => ({ ...prev, expiredAt: getTodayExpiration() }));

  const handleOnBackClick = () => history.push("/dashboards/association");

  const handleOnConfirmClick = useCallback(
    () =>
      baseHandleOnConfirmClick({
        history,
        state,
        save,
        startLoading,
        openModal,
      }),
    [state, save]
  );

  const handleOnSaveClick = useCallback(
    () =>
      baseHandleOnSaveClick({
        openModal,
        closeModal,
        data,
        pathId,
        handleOnConfirmClick,
      }),
    [state, data, pathId]
  );

  useEffect(() => {
    loadAssociationByPathIdEffect({
      setState,
      findById,
      openModal,
      startLoading,
      history,
      pathId,
      findUserById,
      findClientById,
      findGroupById,
      findDashboardById,
    });
  }, [openModal, pathId]);

  return {
    ...state,
    handleOnChange,
    handleAvailableOnCheck,
    contextAlert,
    validation,
    handleOnExpireNowClick,
    isLoading,
    handleOnBackClick,
    handleOnSaveClick,
  };
}

export type UseAssociateDashboardForm = typeof useAssociateDashboardForm;

export interface State {
  id: number | null;
  userId: number | null;
  groupId: number | null;
  clientId: number | null;
  dashboardId: number | null;
  overrideReportIdBi: string | null;
  overrideGroupIdBi: string | null;
  overrideDatasetIdBi: string | null;
  overrideDescription: string | null;
  customData: string | null;
  roleBi: string | null;
  expiredAt: string | null;
  available: boolean;
  associationTargetData: Partial<AssociationTargetData> | null;
}

export type SetState = Dispatch<SetStateAction<State>>;

type History = ReturnType<typeof useHistory>;

interface Validation {
  expiredAt: string[];
  total: number;
}

const initialState: State = {
  id: null,
  userId: null,
  clientId: null,
  groupId: null,
  dashboardId: null,
  overrideReportIdBi: null,
  overrideGroupIdBi: null,
  overrideDatasetIdBi: null,
  overrideDescription: null,
  customData: null,
  roleBi: null,
  expiredAt: null,
  available: false,
  associationTargetData: null,
};

function validate(state: State) {
  const validation: Validation = {
    expiredAt: [],
    total: 0,
  };

  if (state.expiredAt && !state.expiredAt.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    validation.expiredAt.push(
      "A data de expiração deve estar no formato dd/mm/yyyy!"
    );
    validation.total++;
  }

  return validation;
}

function getContextAlertText(
  data: Partial<AssociationTargetData> | null,
  id?: string
) {
  if (!data) return "";

  const action = id ? "editando o" : "criando um";

  if (data.associationType === "client") {
    return `Você está ${action} vínculo entre o dashboard ${data.dashboard?.description} e os usuários do cliente ${data.client?.name}`;
  }
  if (data.associationType === "group") {
    return `Você está ${action} vínculo entre o dashboard ${data.dashboard?.description} e os usuários do grupo ${data.group?.name}`;
  }
  if (data.associationType === "user") {
    return `Você está ${action} vínculo entre o dashboard ${data.dashboard?.description} e o usuário ${data.user?.name}`;
  }

  return "";
}

function needsChooseTarget(pathId?: string): boolean {
  return !pathId && sessionStorage.getItem("associationTarget") == null;
}

interface LoadAssociationByPathIdEffectParams {
  setState: SetState;
  openModal: OpenModal;
  findById: FindAssociationById;
  findDashboardById: FindDashboardById;
  findUserById: FindUserById;
  findGroupById: FindGroupById;
  findClientById: FindClientById;
  startLoading: StartLoading;
  history: History;
  pathId?: string;
}

async function loadAssociationByPathIdEffect(
  params: LoadAssociationByPathIdEffectParams
) {
  const { setState, openModal, startLoading, history, pathId } = params;

  const finish = startLoading({ key: "loadAssociationBy" });

  try {
    if (needsChooseTarget(pathId)) {
      history.push("/dashboards/association");
      openModal("Selecione o dashboard e usuários");
      return;
    }

    const { form, data: associationTargetData } = await getFormAndTargetData(
      params
    );

    setState((prev) => ({ ...prev, ...form, associationTargetData }));

    finish();
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

type FormAndTargetData = {
  form: DashboardAssociationForm;
  data: Partial<AssociationTargetData>;
};

async function getFormAndTargetData(
  params: LoadAssociationByPathIdEffectParams
): Promise<FormAndTargetData> {
  const {
    findById,
    findDashboardById,
    findUserById,
    findGroupById,
    findClientById,
    pathId,
  } = params;

  if (pathId) {
    const form = await findById(parseInt(pathId));
    let data: Partial<AssociationTargetData> = {};

    data.dashboard = await findDashboardById(form.dashboardId);

    if (form.clientId) {
      const client = await findClientById(form.clientId);
      data.client = { id: client.id + "", name: client.name };
      data.associationType = "client";
    }

    if (form.groupId) {
      const group = await findGroupById(form.groupId);
      data.group = { id: group.id + "", name: group.name };
      data.associationType = "group";
    }

    if (form.userId) {
      const user = await findUserById(form.userId);
      data.user = { id: user.id + "", name: user.username };
      data.associationType = "user";
    }

    return {
      form: {
        ...form,
        expiredAt: form.expiredAt
          ? form.expiredAt.replace(/^(\d+)-(\d+)-(\d+).*$/, "$3/$2/$1")
          : null,
      },
      data,
    };
  }

  const json = sessionStorage.getItem("associationTarget");
  const data = JSON.parse(json ?? "");
  const form = targetDataToForm(data);

  return { form, data };
}

function targetDataToForm(
  data: AssociationTargetData
): DashboardAssociationForm {
  return {
    id: null,
    userId:
      data.associationType === "user" ? parseInt(data?.user?.id ?? "0") : null,
    groupId:
      data.associationType === "group"
        ? parseInt(data?.group?.id ?? "0")
        : null,
    clientId:
      data.associationType === "client"
        ? parseInt(data?.client?.id ?? "0")
        : null,
    dashboardId: data.dashboard.id,
    overrideReportIdBi: null,
    overrideGroupIdBi: null,
    overrideDatasetIdBi: null,
    overrideDescription: null,
    customData: null,
    roleBi: null,
    expiredAt: null,
    available: true,
  };
}

function getTodayExpiration() {
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = today.getMonth() + 1;
  const dd = today.getDate();
  const ddStr = dd >= 10 ? dd : "0" + dd;
  const mmStr = mm >= 10 ? mm : "0" + mm;

  return ddStr + "/" + mmStr + "/" + yyyy;
}

function getConfirmationText(
  data: Partial<AssociationTargetData> | null,
  id?: string
) {
  if (!data) return "";

  const action = id ? "editar o" : "criar um";

  if (data?.associationType === "client") {
    return `Você irá ${action} vínculo entre o dashboard ${data?.dashboard?.description} e os usuários do cliente ${data?.client?.name}`;
  }
  if (data?.associationType === "group") {
    return `Você irá ${action} vínculo entre o dashboard ${data?.dashboard?.description} e os usuários do grupo ${data?.group?.name}`;
  }
  if (data?.associationType === "user") {
    return `Você irá  ${action} vínculo entre o dashboard ${data?.dashboard?.description} e o usuário ${data?.user?.name}`;
  }

  return "";
}

interface HandleOnSaveClickParams {
  openModal: OpenModal;
  closeModal: CloseModal;
  data: Partial<AssociationTargetData> | null;
  pathId?: string;
  handleOnConfirmClick: () => void;
}

function baseHandleOnSaveClick(params: HandleOnSaveClickParams) {
  const { openModal, closeModal, data, pathId, handleOnConfirmClick } = params;

  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        {getConfirmationText(data, pathId)}
      </div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          handleOnConfirmClick();
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

interface HandleOnConfirmClickParams {
  openModal: OpenModal;
  state: State;
  save: Save;
  startLoading: StartLoading;
  history: History;
}

async function baseHandleOnConfirmClick(params: HandleOnConfirmClickParams) {
  const { openModal, state, save, startLoading, history } = params;

  const finishLoading = startLoading({ key: "saveAssociation" });

  try {
    const { id } = await save({
      id: state.id,
      userId: state.userId,
      groupId: state.groupId,
      clientId: state.clientId,
      dashboardId: state.dashboardId ?? 0,
      overrideReportIdBi: state.overrideReportIdBi,
      overrideGroupIdBi: state.overrideGroupIdBi,
      overrideDatasetIdBi: state.overrideDatasetIdBi,
      overrideDescription: state.overrideDescription,
      customData: state.customData,
      roleBi: state.roleBi,
      expiredAt: state.expiredAt
        ? state.expiredAt.replace(/^(\d+)\/(\d+)\/(\d+)$/, "$3-$2-$1T00:00:00")
        : null,
      available: state.available,
    });

    history.push(`/dashboards/association/edit/${id}`);

    openModal("Vínculo salvo com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    finishLoading();
  }
}

export default useAssociateDashboardForm;
