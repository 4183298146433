import Table from "../../Table";
import Button from "../../Button";

export default function NielsenCatDisplay({ categories, goToEdit }) {
  return (
    <Table>
      <thead>
        <tr>
          <th className="align-center">Id</th>
          <th className="align-center">Categoria Produto</th>
          <th className="align-center">Categoria Nielsen</th>
          <th className="align-center">Categoria Mãe</th>
          <th className="align-center">Categoria Nível 1</th>
          <th className="align-center">Ações</th>
        </tr>
      </thead>
      <tbody>
        {
          categories.map((category, i) => {
            return (
              <tr key={category.id}>
                <td className="align-center">{category.id}</td>
                <td className="align-center">{category.productCategory}</td>
                <td className="align-center">{category.nielsen}</td>
                <td className="align-center">{category.mother}</td>
                <td className="align-center">{category.lvl1ProdCategory}</td>
                <td className="align-center">
                  <Button layout="info" onClick={() => goToEdit(category)}>
                    Editar
                  </Button>
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  )
}