import { http } from "../api/http";
import { baseUrl } from "../constants";

function useClientCompany() {
  const getAllClientCompanies = () => http.get(baseUrl + "/client-company");

  const getClientCompanyById = (companyId) =>
    http.get(baseUrl + "/client-company/" + companyId);

  const getAllCompanyUsersById = (companyId) =>
    http.get(baseUrl + "/client-company-users/" + companyId);

  const getCompanyDashboards = (companyId) =>
    http.get(baseUrl + "/client-company-dashboards/" + companyId);

  const createClientCompany = (clientCompany) =>
    http.post(baseUrl + "/client-company", clientCompany);

  const updateClientCompany = (clientCompany) =>
    http.put(baseUrl + "/client-company", clientCompany);

  const attachCompanyDashboard = (dash) =>
    http.post(baseUrl + "/company-dashboard-promote", dash);

  const removeCompanyDash = (dash) =>
    http.post(baseUrl + "/company-dashboard-remove", dash);

  const deleteClientCompany = (clientCompany) =>
    http.delete(baseUrl + "/client-company/" + clientCompany.id);

  const deleteCompanyUser = (user) =>
    http.put(baseUrl + "/client-company-users/delete", user);

  const addClientWithSegmentation = (companyId) =>
    http.post(baseUrl + "/client-company/special-segmentation/" + companyId);

  const removeClientWithSegmentation = (companyId) =>
    http.delete(baseUrl + "/client-company/special-segmentation/" + companyId);

  return {
    getAllClientCompanies,
    getClientCompanyById,
    getAllCompanyUsersById,
    getCompanyDashboards,
    createClientCompany,
    updateClientCompany,
    attachCompanyDashboard,
    removeCompanyDash,
    deleteClientCompany,
    deleteCompanyUser,
    addClientWithSegmentation,
    removeClientWithSegmentation,
  };
}

export default useClientCompany;
