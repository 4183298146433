import AsyncSelect from "../../../form/AsyncSelect";
import Button from "../../../form/Button";
import Checkbox from "../../../form/Checkbox";
import Input from "../../../form/Input";
import ValidationMessages from "../../../form/ValidationMessages";
import useNielsenCategoryCreatePage from "./useNielsenCategoryCreatePage";

import { SectionTitle } from "../../../ui/SectionTitle";
import styles from "./NielsenCategoryCreatePage.module.css";

interface Props {
  edit?: boolean;
}

function NielsenCategoryCreatePage(props: Props) {
  const { edit = false } = props;

  const {
    fetchNielsenCategoryOpts,
    handleEditCategoryOnChange,
    selectedEditCategory,
    fetchingCategory,
    showForm,
    description,
    handleOnChange,
    validation,
    fetchMotherCategoryOpts,
    fetchProductCategoryOpts,
    handleMotherCategoryOnChange,
    handleLvl1CategoryOnChange,
    handleProductCategorySelectOnChange,
    handleSendButtonOnClick,
    selectedLvl1Category,
    selectedMotherCategory,
    selectedProductCategories,
    sendingCategory,
    buttonText,
    removeProductCategory,
    handleOnCheck,
    createNewMotherCategory,
    motherCategoryDescription,
    createNewAuntCategory,
    auntCategoryDescription,
    fetchAuntCategoryOpts,
    handleAuntCategoryOnChange,
    selectedAuntCategory,
    handleDeleteButtonOnClick,
    toggleConfirmation,
    confirmation,
  } = useNielsenCategoryCreatePage(edit);

  return (
    <div>
      {!edit ? (
        <SectionTitle
          title="Criar categoria nielsen"
          description="Crie uma nova categoria nielsen"
        />
      ) : (
        <>
          <SectionTitle
            title="Editar categoria nielsen"
            description="Editar uma categoria nielsen já existente"
          />
          <AsyncSelect
            fetchData={fetchNielsenCategoryOpts}
            onChange={handleEditCategoryOnChange}
            value={selectedEditCategory}
            label="Selecione a categoria para editar"
          />
        </>
      )}
      {fetchingCategory ? (
        <div style={{ padding: ".2em", fontSize: ".8em" }}>
          Buscando categoria......
        </div>
      ) : !showForm ? null : (
        <>
          <Input
            label="Novo Nome"
            name="description"
            value={description}
            onChange={handleOnChange}
          />

          <ValidationMessages messages={validation.description} />

          <Checkbox
            name="createNewMotherCategory"
            label="Criar uma nova categoria mãe"
            onChange={handleOnCheck}
            checked={createNewMotherCategory}
          />

          {createNewMotherCategory ? (
            <Input
              label="Nome da nova categoria mãe"
              name="motherCategoryDescription"
              value={motherCategoryDescription}
              onChange={handleOnChange}
            />
          ) : (
            <AsyncSelect
              fetchData={fetchMotherCategoryOpts}
              onChange={handleMotherCategoryOnChange}
              value={selectedMotherCategory}
              label="Categoria Mãe"
            />
          )}

          <ValidationMessages messages={validation.motherCategory} />

          <Checkbox
            name="createNewAuntCategory"
            label="Criar uma nova categoria Tia"
            onChange={handleOnCheck}
            checked={createNewAuntCategory}
          />

          {createNewAuntCategory ? (
            <Input
              label="Nome da nova categoria Tia"
              name="auntCategoryDescription"
              value={auntCategoryDescription}
              onChange={handleOnChange}
            />
          ) : (
            <AsyncSelect
              fetchData={fetchAuntCategoryOpts}
              onChange={handleAuntCategoryOnChange}
              value={selectedAuntCategory}
              label="Categoria Tia"
            />
          )}

          <AsyncSelect
            fetchData={fetchProductCategoryOpts}
            onChange={handleLvl1CategoryOnChange}
            value={selectedLvl1Category}
            label="Categoria Nível 1"
          />

          <ValidationMessages messages={validation.lvl1Category} />

          <AsyncSelect
            fetchData={fetchProductCategoryOpts}
            onChange={handleProductCategorySelectOnChange}
            label="Adicione categorias de produto"
          />

          <ValidationMessages messages={validation.productCategory} />

          <div className={styles.removeLines}>
            {selectedProductCategories.map((category) => (
              <div key={category.id} className={styles.removeLine}>
                <button
                  className={styles.removeButton}
                  onClick={() => removeProductCategory(category.id)}
                >
                  -
                </button>
                {category.name}
              </div>
            ))}
          </div>
          {confirmation === "save" ? (
            <div>
              <h3>Tem certeza que quer savar?</h3>
              <Button
                type="confirm"
                disabled={validation.errors > 0 || sendingCategory}
                onClick={handleSendButtonOnClick}
              >
                Sim
              </Button>{" "}
              <Button
                type="cancel"
                disabled={validation.errors > 0 || sendingCategory}
                onClick={() => toggleConfirmation(null)}
              >
                Não
              </Button>
            </div>
          ) : confirmation === "delete" ? (
            <div>
              <h3>Tem certeza que quer savar?</h3>
              <Button
                type="confirm"
                disabled={validation.errors > 0 || sendingCategory}
                onClick={handleDeleteButtonOnClick}
              >
                Sim
              </Button>{" "}
              <Button
                type="cancel"
                disabled={validation.errors > 0 || sendingCategory}
                onClick={() => toggleConfirmation(null)}
              >
                Não
              </Button>
            </div>
          ) : (
            <div>
              <Button
                type="confirm"
                disabled={validation.errors > 0 || sendingCategory}
                onClick={() => toggleConfirmation("save")}
              >
                {buttonText}
              </Button>{" "}
              <Button
                type="cancel"
                disabled={sendingCategory || !edit}
                onClick={() => toggleConfirmation("delete")}
              >
                Deletar
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default NielsenCategoryCreatePage;
