import { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../../../components/Input";
import { Title } from "../../../components/Titles";
import useHorusAccess from "../../../hooks/useHorusAccess";
import { authCredentials } from "../../../utils/authCredentials";
import useQueueSetup from "../../service/prioritization-massive-moderation/useQueueSetup";
import ProductQueueSetup from "./queueSetup/product";

const Alert = styled.div`
  border: solid 1px #b5930c;
  border-radius: 5px;
  padding: 10px;
  background-color: #fefad4;
  margin: 10px 0;
  color: #b5930c;
  font-size: 12px;

  .__title {
    font-weight: bolder;
  }

  .__body {
    margin-top: 10px;
  }
`;

function QueueSetupPage() {
  // # STATE
  const [
    {
      data,
      searchAttributes,
      searchItemsList,
      selectedItems,
      loading,
      type,
      prioritizedItems,
    },
    setState,
  ] = useState({
    data: {
      items: [],
      totalItems: 0,
      page: 1,
      total: 1,
      pageSize: 200,
    },
    searchAttributes: null,
    searchItemsList: null,
    selectedItems: [],
    loading: false,
    type: "PROD",
    prioritizedItems: false,
  });

  // # OTHER HOOKS
  const { createEvent } = useHorusAccess();
  const [event] = useState({});
  const {
    searchProducts,
    searchCompanies,
    fetchPrioritizedProducts,
    fetchPrioritizedCompanies,
    prioritizeProducts,
    prioritizeCompanies,
    updateProducts,
    updateCompanies,
    removeProductsPrioritization,
    removeCompaniesPrioritization,
    removeAllProductsPrioritization,
    removeAllCompaniesPrioritization,
  } = useQueueSetup();

  // # HELPERS
  const clearItems = () =>
    setState((prev) => ({
      ...prev,
      data: { ...prev.data, items: [], page: 1, total: 1, totalItems: 0 },
      selectedItems: [],
    }));

  const selectItem = (id) => {
    if (selectedItems.includes(id)) {
      setState((prev) => ({
        ...prev,
        selectedItems: prev.selectedItems.filter((i) => i !== id),
      }));
    } else {
      setState((prev) => ({
        ...prev,
        selectedItems: [...prev.selectedItems, id],
      }));
    }
  };

  const setSearchItemsList = function (stringList) {
    const list = stringList?.replaceAll(/ +/g, "").split(",");
    setState((prev) => ({ ...prev, searchItemsList: list }));
  };

  // CALLERS
  const onChangePage = async (pageNumber) => {
    clearItems();
    if (type === "PROD") {
      const retrievedProducts = await searchProducts({
        searchAttributes,
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          items: retrievedProducts?.product,
          page: pageNumber,
          totalItems: retrievedProducts?.listSize,
        },
      }));
    }
    if (type === "COMP") {
      const retrievedCompanies = await searchCompanies({
        searchAttributes,
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          ...retrievedCompanies,
          totalItems: retrievedCompanies?.totalItems
            ? retrievedCompanies?.totalItems
            : 0,
        },
      }));
    }
  };

  const handleSearchQueueOnClick = async (searchAttributes) => {
    clearItems();
    const pageNumber = 1;
    setState((prev) => ({ ...prev, prioritizedItems: false, loading: true }));
    if (type === "PROD") {
      let retrievedProducts = await searchProducts({
        searchAttributes,
        searchItemsList,
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      if (searchItemsList)
        retrievedProducts.product.forEach((p) => selectItem(p.id));
      setState((prev) => ({
        ...prev,
        loading: false,
        searchAttributes: searchAttributes,
        data: {
          ...prev.data,
          items: retrievedProducts?.product,
          page: pageNumber,
          totalItems: retrievedProducts?.listSize,
        },
      }));
    }
    if (type === "COMP") {
      let retrievedCompanies = await searchCompanies({
        searchAttributes,
        searchItemsList,
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      if (searchItemsList)
        retrievedCompanies?.items?.forEach((p) => selectItem(p.id));
      setState((prev) => ({
        ...prev,
        loading: false,
        searchAttributes: searchAttributes,
        data: {
          ...prev.data,
          ...retrievedCompanies,
          totalItems: retrievedCompanies?.totalItems
            ? retrievedCompanies?.totalItems
            : 0,
        },
      }));
    }
  };

  const handleFetchPriorityItemsOnClick = async () => {
    clearItems();
    const pageNumber = 1;
    setState((prev) => ({ ...prev, prioritizedItems: true, loading: true }));
    if (type === "PROD") {
      const retrievedProducts = await fetchPrioritizedProducts({
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        data: {
          ...prev.data,
          items: retrievedProducts?.product,
          page: pageNumber,
          totalItems: retrievedProducts?.listSize,
        },
      }));
    }
    if (type === "COMP") {
      const retrievedCompanies = await fetchPrioritizedCompanies({
        itemsPerPage: data.pageSize,
        pageNumber,
      });
      setState((prev) => ({
        ...prev,
        loading: false,
        data: {
          ...prev.data,
          ...retrievedCompanies,
          totalItems: retrievedCompanies?.totalItems
            ? retrievedCompanies?.totalItems
            : 0,
        },
      }));
    }
  };

  const handleSendItems = async (priority) => {
    if (data.items?.length > 0) {
      setState((prev) => ({ ...prev, loading: true }));
      if (type === "PROD") {
        await prioritizeProducts({ selectedItems, priority });
      }
      if (type === "COMP") {
        await prioritizeCompanies({ selectedItems, priority });
      }
      setState((prev) => ({ ...prev, loading: false }));
      clearItems();
    }
  };

  const handleUpdateItems = async (newItem) => {
    if (data.items?.length > 0 && selectedItems?.length > 0) {
      setState((prev) => ({ ...prev, loading: true }));
      if (type === "PROD") {
        await updateProducts({ newItem, selectedItems });
      }
      if (type === "COMP") {
        await updateCompanies({ newItem, selectedItems });
      }
      setState((prev) => ({ ...prev, loading: false }));
      clearItems();
    }
  };

  const handleRemovePriorities = async () => {
    if (data.items?.length > 0) {
      setState((prev) => ({ ...prev, loading: true }));
      if (type === "PROD") {
        await removeProductsPrioritization({ selectedItems });
      }
      if (type === "COMP") {
        await removeCompaniesPrioritization({ selectedItems });
      }
      setState((prev) => ({
        ...prev,
        prioritizedItems: false,
        loading: false,
      }));
      clearItems();
    }
  };

  const handleRemoveAllPriorities = async () => {
    if (data.items?.length > 0) {
      setState((prev) => ({ ...prev, loading: true }));
      if (type === "PROD") {
        await removeAllProductsPrioritization({ selectedItems });
      }
      if (type === "COMP") {
        await removeAllCompaniesPrioritization({ selectedItems });
      }
      setState((prev) => ({
        ...prev,
        prioritizedItems: false,
        loading: false,
      }));
      clearItems();
    }
  };

  // # EFFECT
  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access queue setup";

    createEvent(event);
  }, []);

  // PAGE STRATEGY
  const queueType = {
    PROD: (
      <ProductQueueSetup
        items={data.items}
        itemsPerPage={data.pageSize}
        pageNumber={data.page}
        searchItemsList={searchItemsList}
        selectedItems={selectedItems}
        fullListSize={data.totalItems}
        loading={loading}
        prioritizedItems={prioritizedItems}
        selectItem={selectItem}
        setSearchItemsList={setSearchItemsList}
        handleSearchQueueOnClick={handleSearchQueueOnClick}
        handleFetchPriorityItemsOnClick={handleFetchPriorityItemsOnClick}
        handleSendItems={handleSendItems}
        handleUpdateItems={handleUpdateItems}
        handleRemovePriorities={handleRemovePriorities}
        handleRemoveAllPriorities={handleRemoveAllPriorities}
        onChangePage={onChangePage}
        clearItems={clearItems}
      />
    ),
    COMP: (
      <p>Funcionalidade em manutenção</p>
      // <CompanyQueueSetup
      //   data={data}
      //   searchItemsList={searchItemsList}
      //   selectedItems={selectedItems}
      //   loading={loading}
      //   prioritizedItems={prioritizedItems}
      //   selectItem={selectItem}
      //   setSearchItemsList={setSearchItemsList}
      //   handleSearchQueueOnClick={handleSearchQueueOnClick}
      //   handleFetchPriorityItemsOnClick={handleFetchPriorityItemsOnClick}
      //   handleSendItems={handleSendItems}
      //   handleUpdateItems={handleUpdateItems}
      //   handleRemovePriorities={handleRemovePriorities}
      //   handleRemoveAllPriorities={handleRemoveAllPriorities}
      //   onChangePage={onChangePage}
      //   clearItems={clearItems}
      // />
    ),
  };

  // # HTML
  return (
    <>
      <div className="h-row">
        <div className="h-col h-col-7">
          <Title>Priorizar / Atualizar Múltiplos Itens</Title>
        </div>
      </div>
      <div className="h-row">
        <div className="h-col h-col-2">
          <Input
            label="Tipo da fila"
            value={type}
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                type: e.target.value,
                data: {
                  pageSize: 200,
                  items: [],
                  page: 1,
                  total: 1,
                  totalItems: 0,
                },
                searchItemsList: null,
                selectedItems: [],
              }));
            }}
            options={[{ id: "PROD", name: "PRODUTO" }]}
          />
        </div>
      </div>
      {queueType[type]}
      {data?.items?.length > 0 ? null : (
        <Alert>
          <div className="__title">
            <i className="fas fa-exclamation-triangle"></i> Buscar itens
          </div>
          <div className="__body">
            A busca pode ser feita por cada campo exclusivamente ou pela
            combinação de dois ou mais.
            <br />
            A busca por quantidade e quantidade por pack deve conter os valores
            mínimos e máximos; em caso de valor único, basta repetir o valor
            inicial e final.
            <br />
            Após o carregamento dos items, será possível selecionar os
            desejados.
          </div>
        </Alert>
      )}
    </>
  );
}

export default QueueSetupPage;
