import axios from "axios";
import { http } from "../api/http";
import { baseUrl } from "../constants";

function useUsers() {
  const getUserById = (userId) => http.get(baseUrl + "/user/" + userId);

  const userExists = (username) =>
    http.get(baseUrl + "/user/exists/" + username);

  const getAllUsers = () => http.get(baseUrl + "/user");

  const createUser = (user) => http.post(baseUrl + "/user", user);

  const updateUser = (user) => http.put(baseUrl + "/user", user);

  const blockUser = (user) => http.put(baseUrl + "/block-user", user);

  const deleteUser = (user) => http.delete(baseUrl + "/user/" + user.id);

  const sendForgetPasswordLink = (credentials) =>
    axios.post(baseUrl + "/api/v1/auth/forget-password", credentials);

  const sendChangePassword = (credentials, token) =>
    axios.post(baseUrl + "/user/change/password", credentials, {
      headers: { Token: token },
    });

  return {
    getUserById,
    userExists,
    getAllUsers,
    createUser,
    updateUser,
    blockUser,
    deleteUser,
    sendForgetPasswordLink,
    sendChangePassword,
  };
}

export default useUsers;
