import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { isCNPJ } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import {
  ChangeEvent,
  Manufacturer,
} from "../../../service/product/useManufacturerManagement";
import Button from "../../form/Button";
import Input from "../../form/Input";
import ValidationMessages from "../../form/ValidationMessages";

export type Props = {
  selectedManufacturer: Manufacturer;
  reloadManufactures: () => void;
};

export default function ManufacturerEdit(props: Props) {
  const { selectedManufacturer, reloadManufactures } = props;

  const { openModal } = useModal();

  const [state, setState] = useState<{
    loading: boolean;
    manuf: Manufacturer;
    cnpjString: string;
  }>({
    loading: false,
    manuf: {
      id: selectedManufacturer.id,
      name: selectedManufacturer.name,
      site: selectedManufacturer.site,
      cnpj: selectedManufacturer.cnpj,
      ean13: selectedManufacturer.ean13,
      brandQtt: selectedManufacturer.brandQtt,
    },
    cnpjString:
      selectedManufacturer.cnpj.length === 0
        ? ""
        : selectedManufacturer.cnpj.join(","),
  });

  const validation = useMemo(() => validate(state.manuf), [state.manuf]);

  const handleOnSave = useCallback(
    () => saveManufacture(openModal, setState, state, reloadManufactures),
    [openModal, setState, state, reloadManufactures]
  );

  const handleCnpjOnChange = (e: ChangeEvent) => setCnpj(e, setState);

  if (state.loading) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <Input
        label="Nome"
        name="name"
        placeholder="Nome"
        value={state.manuf.name}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            manuf: {
              ...prev.manuf,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <ValidationMessages messages={validation.name} />

      <Input
        label="Site"
        name="site"
        placeholder="Url do site"
        value={state.manuf.site}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            manuf: {
              ...prev.manuf,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <Input
        label="CNPJ da sede"
        name="cnpj"
        placeholder="XX.XXX.XXX/XXXX-XX (Em caso de múltiplos, separar por vígula)"
        value={state.cnpjString}
        onChange={handleCnpjOnChange}
      />

      <ValidationMessages messages={validation.cnpj} />

      <Input
        label="EAN13"
        name="ean13"
        value={state.manuf.ean13}
        placeholder="XXXXXXXX,XXXXXXXX,XXXXXXXX,..."
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            manuf: {
              ...prev.manuf,
              [e.target.name]: e.target.value,
            },
          }))
        }
        disabled
      />

      <ValidationMessages messages={validation.ean13} />

      <Button
        type="confirm"
        disabled={validation.errors > 0 || state.loading}
        onClick={() => handleOnSave()}
      >
        Salvar
      </Button>
    </>
  );
}

type SetState = Dispatch<
  SetStateAction<{ loading: boolean; manuf: Manufacturer; cnpjString: string }>
>;

interface Validation {
  name: string[];
  cnpj: string[];
  ean13: string[];
  errors: number;
}

function setCnpj(e: ChangeEvent, setState: SetState) {
  setState((prev) => ({ ...prev, cnpjString: e.target.value }));

  let cnpjs: string[] = [];
  e.target.value?.split(",").forEach((cnpj: string) => {
    cnpjs.push(cnpj.replace(/\D/g, ""));
  });

  let uniqueCnpjs = cnpjs.filter((element, index) => {
    return cnpjs.indexOf(element) === index;
  });

  setState((prev) => ({
    ...prev,
    manuf: {
      ...prev.manuf,
      cnpj: uniqueCnpjs,
    },
  }));
}

function validate(manuf: Manufacturer) {
  const validation: Validation = {
    name: [],
    cnpj: [],
    ean13: [],
    errors: 0,
  };

  if (!manuf?.name) {
    validation.name.push("O nome é obrigatório");
    validation.errors++;
  } else if (
    !manuf?.name.match(
      /^[a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+( [a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+)*$/
    )
  ) {
    validation.name.push(
      "O nome possui formatação inválida e/ou caracteres inválidos"
    );
    validation.errors++;
  }

  let cnpjErrorCounter = 0;
  if (manuf?.cnpj) {
    manuf?.cnpj?.forEach((cnpj) => {
      if (!isCNPJ(cnpj)) {
        cnpjErrorCounter++;
      }
    });
    if (cnpjErrorCounter > 0) {
      validation.cnpj.push(cnpjErrorCounter + " CNPJ(s) inválido(s)");
      validation.errors++;
    }
  }
  if (manuf?.ean13 && !manuf?.ean13.match(/^\d{8}(,\d{8})*$/)) {
    validation.ean13.push(
      "EAN13 deve conter 8 dígitos. Separar por vírgula em caso de múltiplos"
    );
    validation.errors++;
  }

  return validation;
}

async function saveManufacture(
  openModal: OpenModal,
  setState: SetState,
  state: { loading: boolean; manuf: Manufacturer },
  reloadManufactures: () => void
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const responseStr = state.manuf.id
      ? "Fabricante alterado com sucesso."
      : "Fabricante cadastrado com sucesso.";

    const { data } = await http.post(
      `${baseUrl}/api/v1/product/manufacturer`,
      state.manuf
    );

    setState((prev) => ({ ...prev, manuf: data.manufacturer }));

    openModal(responseStr);

    reloadManufactures();
  } catch (e: any) {
    openModal(getErrorMessage(e));
  }
}
