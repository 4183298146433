import styled from 'styled-components'
import Input from '../form/Input'

const operations = [
  { id: "=", name: "ESTÁ EM" },
  { id: "!=", name: "NÃO ESTÁ EM" },
]

const Columns = styled.div`
  margin: 0 -5px;
  :after {
    clear: both;
    display: block;
    content: ' ';
  }
`

const Column1 = styled.div`
  width: 10%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const Column2 = styled.div`
  width: 20%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const Column3 = styled.div`
  width: 30%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const Column4 = styled.div`
  width: 40%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const RemoveButton = styled.div`
  width: 100%;
  background-color: red;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding: 5px 0;
  border-radius: 5px;
`

const AddButton = styled.div`
  width: 100%;
  background-color: green;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  padding: 5px;
  box-sizing: border-box;
`

const Filter = ({ value, onChange, index, options }) => {
  const set = (field) => {
    return e => {
      onChange(value.map((item, i) => {
        if (i !== index) {
          return item
        }
        return { ...item, [field]: e.target.value }
      }))
    }
  }
  return (
    <Columns>
      <Column2>
        <Input
          options={ options }
          onChange={ set("field") }
          value={ value[index].field }
          label="Campo"
        />
      </Column2>
      <Column3>
        <Input
          options={ operations }
          onChange={ set("operator") }
          value={ value[index].operator }
          label="Operador"
        />
      </Column3>
      <Column4>
        <Input
          onChange={ set("value") }
          value={ value[index].value }
          label="Valores Separados por ;"
        />
      </Column4>
      <Column1>
        <RemoveButton onClick={ () => onChange([ ...value ].filter((_, i) => i !== index)) }>
          -
        </RemoveButton>
      </Column1>
    </Columns>
  )
}

export default function GroupedImportReportFilters({ value, onChange, fieldNames }) {
  const options = Object.entries(fieldNames).map(i => {
    return { id: i[0], name: i[1] }
  })
  return (
    <>
      {
        value.map((_, index) => {
          return (
            <Filter
              value={ value }
              onChange={ onChange }
              index={ index }
              key={ index }
              options={ options }
            />
          )
        })
      }
      <AddButton onClick={ () => onChange([ ...value, { field: "type", operator: "=", value: "SEFAZ" } ]) }>
        Adicionar Filtro
      </AddButton>
    </>
  )
}