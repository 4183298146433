import Input from "../../form/Input";
import Button from "../../form/Button";
import ValidationMessages from "../../form/ValidationMessages";
import LoadingUser from "./LoadingUser";
import usePartnerCreate from "./usePartnerCreatePage";
import Select from "../../form/Select";
import {SectionTitle} from "../../ui/SectionTitle";

const level = [
  { id: "Partners Level 1", name: "Parceiro HorusPromo 1" },
  { id: "Partners Level 2", name: "Parceiro HorusPromo 2" },
  { id: "Partners Level 3", name: "Parceiro HorusPromo 3" },
  { id: "Partners Level 4", name: "Parceiro HorusPromo 4" },
];

const reprocessPermissions = [
    { id: "PERMITIDO", name: "Permitido" },
    { id: "NEGADO", name: "Não Permitido" },
];

interface Props {
  edit?: boolean 
}

export default function PartnerCreatePage(props: Props) {

  const { edit = false } = props;

  const userCreate = usePartnerCreate(edit);

  const {

    validation, form, sendingUser, fetchingUser, buttonText,
    handleFormOnChange, handleSendButtonOnClick

  } = userCreate;

  return (
    <div>
      {
        !edit ? <SectionTitle title="Criação de parceiro" description="Crie parceiros HorusPromo." /> : (
            <div>
              <SectionTitle title="Edição de usuário" description="Edite as informações de usuários." />
            </div>
        )
      }
      {
        fetchingUser ? <LoadingUser /> : (
           (
            <>

              <Input
                  label="Usuário de Acesso"
                  name="username"
                  value={ form.username }
                  onChange={ handleFormOnChange }
              />

              <ValidationMessages messages={ validation.username } />

              <Input
                label="Nome Parceiro"
                name="name"
                value={ form.name }
                onChange={ handleFormOnChange }
              />
  
              <ValidationMessages messages={ validation.name } />

              <Input
                  label="Email"
                  name="email"
                  value={ form.email }
                  onChange={ handleFormOnChange }
              />

              <ValidationMessages messages={ validation.email } />

              <Input
                  label="ContractId"
                  name="contractId"
                  value={ form.contractId }
                  onChange={ handleFormOnChange }
              />

              <Input
                  label="Contractor"
                  name="contractor"
                  value={ form.contractor }
                  onChange={ handleFormOnChange }
              />

              <Input
                  label="BP"
                  name="bp"
                  value={ form.bp }
                  onChange={ handleFormOnChange }
              />

              <Input
                  label="ProductId"
                  name="productId"
                  value={ form.productId }
                  onChange={ handleFormOnChange }
              />

              <div>
                <Select
                    label="Nível HorusPromo"
                    name="partnerPermissionLevel"
                    options={ level }
                    value={ form.partnerPermissionLevel }
                    onChange={ handleFormOnChange }
                />
              </div>

              <ValidationMessages messages={ validation.partnerPermissionLevel } />

              <div>
                <Select
                    label="Permissão de Reprocessamento de Notas"
                    name="reprocessPermission"
                    options={ reprocessPermissions }
                    value={ form.reprocessPermission }
                    onChange={ handleFormOnChange }
                />
              </div>

              <ValidationMessages messages={ validation.reprocessPermission } />

              <Button type="confirm" disabled={ validation.errors > 0 || sendingUser || !form.partnerPermissionLevel } onClick={ handleSendButtonOnClick }>
                { buttonText }
              </Button>   
            
            </>
          )
        )
      }

    </div>
  );

}