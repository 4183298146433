import { Title } from '../../../components/Titles';
import Card from '../ui/Card';
import SubTitle from '../ui/SubTitle';
import Tabs from '../ui/Tabs';
import DashboardForm from './DashboardForm';
import { Route, Switch } from 'react-router';
import DashboardListPage from './DashboardListPage';
import ChooseAssociationForm from './association/ChooseAssociationForm';
import AssociateDashboardForm from './association/AssociateDashboardForm';
import SearchDashboardAssociations from './association/SearchDashboardAssociations';
import AnalysisServicesManagementForm from './analysis-services/AnalysisServicesManagementForm';
import { useMenu } from '../../service/context/globalContext';
import Platform from '../ui/Platform';
import DashboardPage from '../../../pages/DashboardPage';
import SingleDashboardPage from '../../../pages/SingleDashboardPage';
import PlansPage from '../../../pages/PlansPage';
import DashboardAccessVisualizationPage from './DashboardAccessVisualizationPage';

function DashboardManagementPage() {

  const menu = useMenu("/dashboards");

  return (
    <Platform>
      <Title>Gestão de Dashboards</Title>
      <SubTitle>
        Criação ou alteração de dashboards
      </SubTitle>
      <Tabs menu={ menu } />
      <Card>
        <Switch>
          <Route exact path="/dashboards/new">
            <DashboardForm key="create-form" />
          </Route>
          <Route exact path={ [ "/dashboards/edit", "/dashboards/edit/:id" ] }>
            <DashboardForm key="edit-form" edit />
          </Route>
          <Route path="/dashboards/list/:page">
            <DashboardListPage />
          </Route>
          <Route exact path="/dashboards/association">
            <ChooseAssociationForm />
          </Route>
          <Route path="/dashboards/association/new">
            <AssociateDashboardForm />
          </Route>
          <Route path="/dashboards/association/edit/:id">
            <AssociateDashboardForm />
          </Route>
          <Route path="/dashboards/analysis-services">
            <AnalysisServicesManagementForm />
          </Route>
          <Route path="/dashboards/search">
            <SearchDashboardAssociations />
          </Route>
          <Route path="/dashboards/access-report/:page">
            <DashboardAccessVisualizationPage />
          </Route>
          <Route exact path="/dashboards/legacy-dashboard">
            <DashboardPage />
          </Route>
          <Route path="/dashboards/legacy-dashboard/:dashId">
            <SingleDashboardPage />
          </Route>
          <Route path="/dashboards/legacy-plan">
            <PlansPage />
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}

export default DashboardManagementPage