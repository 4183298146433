import useClientCreatePage from "../../service/client/useClientCreatePage";
import AsyncSelect from "../form/AsyncSelect";
import Button from "../form/Button";
import Input from "../form/Input";
import ValidationMessages from "../form/ValidationMessages";
import { SectionTitle } from "../ui/SectionTitle";
import ClientUserList from "./ClientUserList";
import EditClientHeader from "./EditClientHeader";

interface Props {
  edit?: boolean;
}

export default function ClientEditPage(props: Props) {

  const { edit = false } = props

  const useClientCreate = useClientCreatePage(edit);

  const {

    validation, showForm, form, loading, fetchingClient, buttonText, selectedEditClient, handleEditClientOnChange,
    handleFormOnChange, fetchClientOpts, handleSendButtonOnClick

  } = useClientCreate;

  return (
    <div>
      {
        !edit ? (
          <SectionTitle
            title="Criar Cliente"
            description="Criar uma novo cliente"
          />
        ) : (
          <div>
            <SectionTitle
              title="Editar Cliente"
              description="Alterar um cliente já existente"
            />

            <AsyncSelect
              fetchData={ fetchClientOpts }
              onChange={ handleEditClientOnChange }
              value={ selectedEditClient }
              label="Selecione o cliente para editar"
            />
          </div>
        )
      }
      {
        fetchingClient ? <div>Carregando...</div> : (
          !showForm ? null : (
            <>

              <EditClientHeader { ...useClientCreate } />

              <Input
                label="Novo Nome"
                name="name"
                value={ form.name }
                onChange={ handleFormOnChange }
              />
  
              <ValidationMessages messages={ validation.name } />

              <Input
                label="CNPJ ou CPF"
                name="cnpj"
                value={ form.cnpj }
                onChange={ handleFormOnChange }
              />
  
              <ValidationMessages messages={ validation.cnpj } />

              <Input
                label="Validade do contrato"
                name="expireDate"
                type="date"
                value={ form.expireDate }
                onChange={ handleFormOnChange }
              />

              <Input
                label="Id do Hubspot"
                name="hubspotId"
                value={ form.hubspotId }
                onChange={ handleFormOnChange }
              />
  
              <Button type="confirm" disabled={ validation.errors > 0 || loading } onClick={ handleSendButtonOnClick }>
                { buttonText }
              </Button>
              <br/><br/>

              <ClientUserList { ...useClientCreate } />
            
            </>
          )
        )
      }
    </div>
  )

}