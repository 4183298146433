import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useLocation, useParams } from "react-router";
import { http } from "../api/http";
import AsyncSelect2 from "../components/AsyncSelect2";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import GroupedSelect from "../components/GroupedSelect";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Template from "../components/Template";
import { Title } from "../components/Titles";
import SaveProductBrandForm from "../components/productBrand/form/SaveProductBrandForm";
import { baseUrl, mainColor, mediaPrefix } from "../constants";
import useModal from "../hooks/useModal";
import useProductCategory from "../hooks/useProductCategory";
import useSetTimeout from "../hooks/useSetTimeout";
import { getErrorMessage } from "../intranet/service/error/getErrorMessage";
import {
  isEmpty,
  isEmptyList,
  isEmptyObj,
  isNullText,
  validateNumericSize,
  validateTextSize,
} from "../utils";

function useSyncColumns(leftColumnRef, rightColumnRef) {
  useEffect(() => {
    const leftColumn = leftColumnRef.current;
    const rightColumn = rightColumnRef.current;

    if (!leftColumn || !rightColumn || !ResizeObserver) return;

    let observer = new ResizeObserver((entries) => {
      rightColumn.style.height = `${entries[0].contentRect.height}px`;
    });

    observer.observe(leftColumn);

    return () => observer.unobserve(leftColumn);
  });
}

/**
 * Creates the initial state for ProductPage component
 */
function makeDefaultState({ path }) {
  return {
    product: null,
    originalProduct: null,
    duplicateProduct: false,
    productImgSrc: null,
    error: false,
    title: "Carregando...",
    categories: null,
    ncms: null,
    search: {
      currentType: "ean",
      types: {
        ean: {
          results: [],
          loading: true,
          err: false,
        },
        description: {
          results: [],
          loading: true,
          err: false,
        },
      },
    },
    unit: {
      value: "",
      unit: "",
      finalValue: null,
      quantity: "1",
    },
    categoryComplete: {
      categoryId: null,
      category: null,
      unitOfMeasurement: null,
      median: null,
      reportId: null,
    },
    brandComplete: {
      Id: null,
      name: null,
      image: null,
      manufacturer: null,
      manufacturerId: null,
      economicGroup: null,
      tagCes: null,
      tagSeason: false,
      tagPackage: false,
      tagEspecificity: false,
      tagLine: false,
      tagLinearMeasure: false,
    },
    ncm: null,
    modalConfirm: false,
    modalNextProduct: false,
    restrictedTags: null,
    tags: [],
    tagTypeChosen: null,
    productTags: {
      pack: { content: null },
      base: { content: null, name: null },
      flavor: { content: [] },
      color: { content: [] },
      essence: { content: [] },
      package: { content: [] },
      especificity: { content: [] },
      linearMeasure: { content: [] },
      season: { content: [] },
      line: { content: null },
    },
    relatedEans: [],
    relatedEanToAdd: "",
    isPack: false,
    isOwnBrand: false,
    economicGroups: null,
    warnings: {
      quantity: null,
      unitOfMeasurement: null,
      category: null,
    },
    locks: {
      categoryId: true,
      brand: true,
    },
    updateBlock: false,
    modLevel: "COMMON",
    refDescCategory: [],
    refBrandCategory: [],
    breadcrump: [
      {
        name: "Home",
        path: "/dashboard",
      },
      {
        name: "Moderação de Produto",
        path: "/product/moderation",
      },
      {
        name: "Carregando...",
        path,
      },
    ],
  };
}

const unities = [
  {
    style: "Kg",
    type: "Massa",
    options: [
      {
        id: "mg",
        name: "Miligrama (mg)",
        ratio: 0.000001,
      },
      {
        id: "g",
        name: "Gramas (g)",
        ratio: 0.001,
      },
      {
        id: "Kg",
        name: "Quilos (kg)",
        ratio: 1,
      },
    ],
  },
  {
    style: "Lt",
    type: "Volume",
    options: [
      {
        id: "ml",
        name: "Mililitro (ml)",
        ratio: 0.001,
      },
      {
        id: "Lt",
        name: "Litro (l)",
        ratio: 1,
      },
    ],
  },
  {
    style: "Und",
    type: "Unidade",
    options: [
      {
        id: "cx",
        name: "Caixas",
        ratio: 1,
      },
      {
        id: "Und",
        name: "Unidades",
        ratio: 1,
      },
      {
        id: "cpmd",
        name: "Comprimidos",
        ratio: 1,
      },
      {
        id: "fl",
        name: "Folhas",
        ratio: 1,
      },
      {
        id: "rl",
        name: "Rolos",
        ratio: 1,
      },
      {
        id: "lmn",
        name: "Lâminas",
        ratio: 1,
      },
      {
        id: "pstl",
        name: "Pastilhas",
        ratio: 1,
      },
    ],
  },
];

const packTagOptions = [
  { id: "Caixa", name: "Caixa" },
  { id: "Fardo", name: "Fardo" },
  { id: "Pack", name: "Pack" },
  { id: "Pacote", name: "Pacote" },
];

const searchTypes = {
  ean: {
    name: "Ean",
    getTerm: (p) => p.ean,
    googleLink: (p) => `https://www.google.com/search?q=${p.ean}`,
  },
  description: {
    name: "Descrição",
    getTerm: (p) => p.description,
    googleLink: (p) => `https://www.google.com/search?q=${p.description}`,
  },
};

function greaterThen(tag, comparison) {
  if (!(comparison.length > 0)) return true;
  let found = comparison.filter((c) => c.index === null || tag.index < c.index);
  if (found.length === comparison.length) return true;
  return false;
}

function lesserThen(tag, comparison) {
  if (!(comparison.length > 0)) return true;
  let found = comparison.filter((c) => c.index === null || tag.index > c.index);
  if (found.length === comparison.length) return true;
  return false;
}

function textMatcher(product, tags, productTags) {
  if (product.description && product.description.length > 0) {
    let tagCheck = {
      pack: [
        {
          name: productTags.pack.content,
          match: false,
          inPosition: false,
          index: null,
        },
      ],
      base: [
        {
          name: productTags.base.content,
          match: false,
          inPosition: false,
          index: null,
        },
      ],
      flavor: productTags.flavor.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      essence: productTags.essence.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      color: productTags.color.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      package: productTags.package.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      especificity: productTags.especificity.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      linearMeasure: productTags.linearMeasure.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      season: productTags.season.content.map((j, index) => {
        return {
          name: tags.find((t) => t.id.toString() === j.toString())?.name,
          match: false,
          inPosition: false,
          index: null,
        };
      }),
      line: [
        {
          name: productTags.line.content,
          match: false,
          inPosition: false,
          index: null,
        },
      ],
      brand: [
        { name: product.brand, match: false, inPosition: false, index: null },
      ],
    };
    let tagKey = Object.keys(tagCheck);
    for (let i = 0; i < tagKey.length; i++) {
      let tagValue = Object.values(tagCheck[tagKey[i]]);
      if (tagValue.length > 0 && tagValue[0].name) {
        let contentList = [];
        for (let j = 0; j < tagValue.length; j++) {
          let tagName = tagValue[j].name
            .toLowerCase()
            .split(/ *\/ *| *- *| com | e /gi);
          let lowerText = product.description.toLowerCase();
          for (let k = 0; k < tagName.length; k++) {
            let reTagName = tagName[k]
              .replaceAll("+", "\\+")
              .replaceAll(".", "\\.")
              .replaceAll("(", "")
              .replaceAll(")", "");
            let regex = new RegExp(
              "\\W" +
                reTagName +
                "\\W|^" +
                reTagName +
                "\\W|\\W" +
                reTagName +
                "$",
              "gi"
            );
            if (lowerText.search(regex) >= 0) {
              contentList.push({
                ...tagValue[j],
                name: tagName[k],
                match: true,
                index: lowerText.search(regex),
              });
            } else {
              contentList.push({
                ...tagValue[j],
                name: tagName[k],
                match: false,
                index: null,
              });
            }
          }
        }
        if (tagKey[i] === "pack" && contentList.length > 0)
          tagCheck = { ...tagCheck, pack: contentList };
        if (tagKey[i] === "base" && contentList.length > 0)
          tagCheck = { ...tagCheck, base: contentList };
        if (tagKey[i] === "flavor" && contentList.length > 0)
          tagCheck = { ...tagCheck, flavor: contentList };
        if (tagKey[i] === "essence" && contentList.length > 0)
          tagCheck = { ...tagCheck, essence: contentList };
        if (tagKey[i] === "color" && contentList.length > 0)
          tagCheck = { ...tagCheck, color: contentList };
        if (tagKey[i] === "package" && contentList.length > 0)
          tagCheck = { ...tagCheck, package: contentList };
        if (tagKey[i] === "especificity" && contentList.length > 0)
          tagCheck = { ...tagCheck, especificity: contentList };
        if (tagKey[i] === "LinearMeasure" && contentList.length > 0)
          tagCheck = { ...tagCheck, LinearMeasure: contentList };
        if (tagKey[i] === "season" && contentList.length > 0)
          tagCheck = { ...tagCheck, season: contentList };
        if (tagKey[i] === "line" && contentList.length > 0)
          tagCheck = { ...tagCheck, line: contentList };
        if (tagKey[i] === "brand" && contentList.length > 0)
          tagCheck = { ...tagCheck, brand: contentList };
      }
    }

    let content = [];
    for (let i = 0; i < Object.values(tagCheck.pack).length; i++) {
      if (
        tagCheck.pack[i].match &&
        greaterThen(tagCheck.pack[i], tagCheck.base) &&
        greaterThen(tagCheck.pack[i], tagCheck.flavor) &&
        greaterThen(tagCheck.pack[i], tagCheck.essence) &&
        greaterThen(tagCheck.pack[i], tagCheck.color) &&
        greaterThen(tagCheck.pack[i], tagCheck.package) &&
        greaterThen(tagCheck.pack[i], tagCheck.especificity) &&
        greaterThen(tagCheck.pack[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.pack[i], tagCheck.season) &&
        greaterThen(tagCheck.pack[i], tagCheck.line) &&
        greaterThen(tagCheck.pack[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.pack[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.pack[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, pack: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.base).length; i++) {
      if (
        tagCheck.base[i].match &&
        lesserThen(tagCheck.base[i], tagCheck.pack) &&
        greaterThen(tagCheck.base[i], tagCheck.flavor) &&
        greaterThen(tagCheck.base[i], tagCheck.essence) &&
        greaterThen(tagCheck.base[i], tagCheck.color) &&
        greaterThen(tagCheck.base[i], tagCheck.package) &&
        greaterThen(tagCheck.base[i], tagCheck.especificity) &&
        greaterThen(tagCheck.base[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.base[i], tagCheck.season) &&
        greaterThen(tagCheck.base[i], tagCheck.line) &&
        greaterThen(tagCheck.base[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.base[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.base[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, base: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.flavor).length; i++) {
      if (
        tagCheck.flavor[i].match &&
        lesserThen(tagCheck.flavor[i], tagCheck.base) &&
        lesserThen(tagCheck.flavor[i], tagCheck.pack) &&
        greaterThen(tagCheck.flavor[i], tagCheck.essence) &&
        greaterThen(tagCheck.flavor[i], tagCheck.color) &&
        greaterThen(tagCheck.flavor[i], tagCheck.package) &&
        greaterThen(tagCheck.flavor[i], tagCheck.especificity) &&
        greaterThen(tagCheck.flavor[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.flavor[i], tagCheck.season) &&
        greaterThen(tagCheck.flavor[i], tagCheck.line) &&
        greaterThen(tagCheck.flavor[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.flavor[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.flavor[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, flavor: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.essence).length; i++) {
      if (
        tagCheck.essence[i].match &&
        lesserThen(tagCheck.essence[i], tagCheck.base) &&
        lesserThen(tagCheck.essence[i], tagCheck.flavor) &&
        lesserThen(tagCheck.essence[i], tagCheck.pack) &&
        greaterThen(tagCheck.essence[i], tagCheck.color) &&
        greaterThen(tagCheck.essence[i], tagCheck.package) &&
        greaterThen(tagCheck.essence[i], tagCheck.especificity) &&
        greaterThen(tagCheck.essence[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.essence[i], tagCheck.season) &&
        greaterThen(tagCheck.essence[i], tagCheck.line) &&
        greaterThen(tagCheck.essence[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.essence[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.essence[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, essence: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.color).length; i++) {
      if (
        tagCheck.color[i].match &&
        lesserThen(tagCheck.color[i], tagCheck.base) &&
        lesserThen(tagCheck.color[i], tagCheck.flavor) &&
        lesserThen(tagCheck.color[i], tagCheck.essence) &&
        lesserThen(tagCheck.color[i], tagCheck.pack) &&
        greaterThen(tagCheck.color[i], tagCheck.package) &&
        greaterThen(tagCheck.color[i], tagCheck.especificity) &&
        greaterThen(tagCheck.color[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.color[i], tagCheck.season) &&
        greaterThen(tagCheck.color[i], tagCheck.line) &&
        greaterThen(tagCheck.color[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.color[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.color[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, color: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.package).length; i++) {
      if (
        tagCheck.package[i].match &&
        lesserThen(tagCheck.package[i], tagCheck.base) &&
        lesserThen(tagCheck.package[i], tagCheck.flavor) &&
        lesserThen(tagCheck.package[i], tagCheck.essence) &&
        lesserThen(tagCheck.package[i], tagCheck.color) &&
        lesserThen(tagCheck.package[i], tagCheck.pack) &&
        greaterThen(tagCheck.package[i], tagCheck.especificity) &&
        greaterThen(tagCheck.package[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.package[i], tagCheck.season) &&
        greaterThen(tagCheck.package[i], tagCheck.line) &&
        greaterThen(tagCheck.package[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.package[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.package[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, package: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.especificity).length; i++) {
      if (
        tagCheck.especificity[i].match &&
        lesserThen(tagCheck.especificity[i], tagCheck.base) &&
        lesserThen(tagCheck.especificity[i], tagCheck.flavor) &&
        lesserThen(tagCheck.especificity[i], tagCheck.essence) &&
        lesserThen(tagCheck.especificity[i], tagCheck.color) &&
        lesserThen(tagCheck.especificity[i], tagCheck.package) &&
        lesserThen(tagCheck.especificity[i], tagCheck.pack) &&
        greaterThen(tagCheck.especificity[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.especificity[i], tagCheck.season) &&
        greaterThen(tagCheck.especificity[i], tagCheck.line) &&
        greaterThen(tagCheck.especificity[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.especificity[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.especificity[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, especificity: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.linearMeasure).length; i++) {
      if (
        tagCheck.linearMeasure[i].match &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.base) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.flavor) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.essence) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.color) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.package) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.especificity) &&
        lesserThen(tagCheck.linearMeasure[i], tagCheck.pack) &&
        greaterThen(tagCheck.linearMeasure[i], tagCheck.season) &&
        greaterThen(tagCheck.linearMeasure[i], tagCheck.line) &&
        greaterThen(tagCheck.linearMeasure[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.linearMeasure[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.linearMeasure[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, linearMeasure: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.season).length; i++) {
      if (
        tagCheck.season[i].match &&
        lesserThen(tagCheck.season[i], tagCheck.base) &&
        lesserThen(tagCheck.season[i], tagCheck.flavor) &&
        lesserThen(tagCheck.season[i], tagCheck.essence) &&
        lesserThen(tagCheck.season[i], tagCheck.color) &&
        lesserThen(tagCheck.season[i], tagCheck.package) &&
        lesserThen(tagCheck.season[i], tagCheck.especificity) &&
        lesserThen(tagCheck.season[i], tagCheck.linearMeasure) &&
        lesserThen(tagCheck.season[i], tagCheck.pack) &&
        greaterThen(tagCheck.season[i], tagCheck.line) &&
        greaterThen(tagCheck.season[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.season[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.season[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, season: content };

    content = [];
    for (let i = 0; i < Object.values(tagCheck.line).length; i++) {
      if (
        tagCheck.line[i].match &&
        lesserThen(tagCheck.line[i], tagCheck.base) &&
        lesserThen(tagCheck.line[i], tagCheck.flavor) &&
        lesserThen(tagCheck.line[i], tagCheck.essence) &&
        lesserThen(tagCheck.line[i], tagCheck.color) &&
        lesserThen(tagCheck.line[i], tagCheck.package) &&
        lesserThen(tagCheck.line[i], tagCheck.especificity) &&
        lesserThen(tagCheck.line[i], tagCheck.linearMeasure) &&
        greaterThen(tagCheck.line[i], tagCheck.season) &&
        lesserThen(tagCheck.line[i], tagCheck.pack) &&
        greaterThen(tagCheck.line[i], tagCheck.brand)
      ) {
        content.push({ ...tagCheck.line[i], inPosition: true });
      } else {
        content.push({ ...tagCheck.line[i], inPosition: false });
      }
    }
    tagCheck = { ...tagCheck, line: content };

    return tagCheck;
  }

  return false;
}

function reducer(prev, action) {
  if (action.type === "ERROR") {
    return { ...prev, error: true };
  }

  if (action.type === "FETCH_PRODUCT") {
    const {
      ncm,
      product,
      comercialReferences,
      categoryComplete,
      brandComplete,
      fixesSuggestions,
      tags,
      restrictedTags,
      search,
      refDescCategory,
      refBrandCategory,
    } = action;
    const pricePerUnit =
      !product.quantity ||
      !categoryComplete?.median ||
      isNaN(product.median) ||
      isNaN(product.quantity) ||
      product.quantity <= 0
        ? 0
        : product.median /
          (product.quantity *
            (product.quantityPerPack ? product.quantityPerPack : 1));
    const outOfRangePrice =
      categoryComplete?.median > 0 &&
      product.median &&
      (categoryComplete?.median * 4 < pricePerUnit ||
        categoryComplete?.median / 4 > pricePerUnit);
    const wrongUnitOfMeasurement =
      categoryComplete?.unitOfMeasurement &&
      categoryComplete?.unitOfMeasurement !== product.unitOfMeasurement;
    const wrongCategory =
      ncm &&
      ncm.catProdId &&
      !ncm.catProdId.includes(categoryComplete?.id?.toString());
    const prodTagsList = isEmptyList(product.tags)
      ? null
      : product.tags.map((j) => tags.find((t) => t.id === j));
    let suggestedPack = { quantity: null, pack: null };
    if (
      !(
        !product.quantity ||
        !categoryComplete?.median ||
        isNaN(product.median) ||
        isNaN(product.quantity) ||
        product.quantity <= 0 ||
        categoryComplete?.median <= 0
      ) &&
      product.median / categoryComplete?.median >= 3
    ) {
      let pack = (
        product.median /
        (categoryComplete?.median * product.quantity)
      ).toFixed(0);
      suggestedPack = { quantity: product.quantity, pack: pack };
    }

    return {
      ...prev,
      product,
      comercialReferences,
      originalProduct: product,
      breadcrump: [
        prev.breadcrump[0],
        prev.breadcrump[1],
        { ...prev.breadcrump[2], name: action.product.description },
      ],
      productImgSrc: product.file
        ? mediaPrefix + product.file
        : "/static/placeholder.png",
      title: product.description || product.ean || `Produto ${product.id}`,
      error: false,
      search: {
        currentType: "ean",
        types: {
          ean: {
            results: search,
            err: !search,
          },
          description: {},
        },
      },
      unit: {
        value: product.quantity ? product.quantity.toString() : null,
        unit: product.unitOfMeasurement ? product.unitOfMeasurement : null,
        quantity: product.quantityPerPack
          ? product.quantityPerPack.toString()
          : "1",
        finalValue: product.quantity ? product.quantity.toString() : null,
      },
      isPack:
        product.quantityPerPack && product.quantityPerPack > 1 ? true : false,
      isOwnBrand: brandComplete?.ownBrand ? true : false,
      warnings: {
        quantity: outOfRangePrice
          ? "O preço por unidade do produto difere mais de 4 vezes o valor da mediana da categoria. Verifique se a quantidade e a unidade de medida estão corretas antes de salvar."
          : null,
        unitOfMeasurement: wrongUnitOfMeasurement
          ? "Unidade de medida " +
            product.unitOfMeasurement +
            ", não é aceita pela categoria selecionada"
          : null,
        category: wrongCategory
          ? "A categoria selecionada não faz parte das categorias do NCM"
          : null,
      },
      suggestedPack: suggestedPack,
      categoryComplete: categoryComplete,
      brandComplete: brandComplete,
      fixesSuggestions: fixesSuggestions,
      tags: tags,
      productTags: {
        pack:
          product.quantityPerPack && product.quantityPerPack > 1
            ? { content: "Caixa" }
            : { content: null },
        base: prodTagsList?.find((t) => t.type === "BASE")?.id
          ? {
              content: prodTagsList.find((t) => t.type === "BASE")?.id,
              name: prodTagsList.find((t) => t.type === "BASE")?.name,
            }
          : { content: product.category, name: product.category },
        flavor:
          prodTagsList?.find((t) => t.type === "SABOR")?.id &&
          categoryComplete?.tagCes === "SABOR"
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "SABOR")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        color:
          prodTagsList?.find((t) => t.type === "COR")?.id &&
          categoryComplete?.tagCes === "COR"
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "COR")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        essence:
          prodTagsList?.find((t) => t.type === "ESSENCIA")?.id &&
          categoryComplete?.tagCes === "ESSENCIA"
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "ESSENCIA")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        package:
          prodTagsList?.find((t) => t.type === "EMBALAGEM")?.id &&
          categoryComplete?.tagPackage
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "EMBALAGEM")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        especificity:
          prodTagsList?.find((t) => t.type === "ESPECIFICIDADE")?.id &&
          categoryComplete?.tagEspecificity
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "ESPECIFICIDADE")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        linearMeasure:
          prodTagsList?.find((t) => t.type === "METRAGEM")?.id &&
          categoryComplete?.tagLinearMeasure
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "METRAGEM")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        season:
          prodTagsList?.find((t) => t.type === "SAZONAL")?.id &&
          categoryComplete?.tagSeason
            ? {
                content: prodTagsList
                  .filter((t) => t.type === "SAZONAL")
                  ?.map((i) => i.id),
              }
            : { content: [] },
        line:
          prodTagsList?.find((t) => t.type === "LINHA")?.id &&
          categoryComplete?.tagLine
            ? {
                content: prodTagsList.find((t) => t.type === "LINHA")?.name,
              }
            : { content: null },
      },
      restrictedTags: restrictedTags?.length > 0 ? restrictedTags : null,
      ncm: ncm,
      refDescCategory: refDescCategory,
      refBrandCategory: refBrandCategory,
    };
  }

  if (action.type === "FETCH_NEW_RELATED_EANS") {
    const { relatedEans: prevEans } = prev;
    const { description, eans } = action;
    const prevEanList = [];

    return {
      ...prev,
      relatedEans: [
        ...prevEans.filter((i) => {
          if (i.selected) {
            prevEanList.push(i.ean);
            return true;
          }
          return false;
        }),
        ...eans
          .filter(
            (ean) => !prevEanList.includes(ean) && ean !== prev.product?.ean
          )
          .map((ean) => ({
            description,
            ean,
            selected: false,
          })),
      ],
    };
  }

  if (action.type === "FETCH_RELATED_EANS") {
    const { type, ...relatedEans } = action;
    return { ...prev, ...relatedEans };
  }

  if (action.type === "UPDATE_TAGS") {
    const { type, ...tag } = action;
    return {
      ...prev,
      productTags: {
        ...prev.productTags,
        ...tag,
      },
    };
  }

  if (action.type === "TAG_TYPE_CHOSEN") {
    const { type, tagTypeChosen } = action;
    return { ...prev, tagTypeChosen };
  }

  if (action.type === "UPDATE_PRODUCT") {
    const { type, ...product } = action;
    return {
      ...prev,
      product: {
        ...prev.product,
        ...product,
      },
    };
  }

  if (action.type === "NEW_PRODUCT_IMAGE") {
    const { type, productImgSrc, file } = action;
    return {
      ...prev,
      productImgSrc,
      product: { ...prev.product, newProductImg: file },
    };
  }

  if (action.type === "UPDATE_CATEGORY") {
    const { type, categoryComplete } = action;
    let productCategory = {
      categoryId: categoryComplete.categoryId,
      category: categoryComplete.category,
      nielsen: categoryComplete.nielsen,
      categoryReportId: categoryComplete.reportId,
    };
    const categoryMedian = categoryComplete.median;
    const pricePerUnit =
      !prev.product.quantity ||
      !categoryMedian ||
      isNaN(prev.product.median) ||
      isNaN(prev.product.quantity) ||
      prev.product.quantity <= 0
        ? 0
        : prev.product.median /
          (prev.product.quantity *
            (prev.product.quantityPerPack ? prev.product.quantityPerPack : 1));
    const zeroQuantity =
      isNaN(prev.product.quantity) || prev.product.quantity <= 0;
    const outOfRangePrice =
      !zeroQuantity &&
      categoryMedian > 0 &&
      prev.product.median > 0 &&
      (categoryMedian * 4 < pricePerUnit || categoryMedian / 4 > pricePerUnit);
    const wrongUnitOfMeasurement =
      categoryComplete?.unitOfMeasurement &&
      categoryComplete.unitOfMeasurement !== prev.product.unitOfMeasurement;
    const wrongCategory =
      prev.ncm?.catProdId &&
      !prev.ncm.catProdId.includes(categoryComplete?.categoryId?.toString());

    let newWarnings = {
      quantity: zeroQuantity
        ? "A quantidade do produto precisa ser preenchida."
        : outOfRangePrice
        ? "O preço por unidade do produto difere mais de 4 vezes o valor da mediana da categoria. Verifique se a quantidade e a unidade de medida estão corretas antes de salvar."
        : null,
      unitOfMeasurement: wrongUnitOfMeasurement
        ? "Unidade de medida " +
          prev.product.unitOfMeasurement +
          ", não é aceita pela categoria selecionada"
        : null,
      category: wrongCategory
        ? "A categoria selecionada não faz parte das categorias do NCM"
        : null,
    };

    return {
      ...prev,
      product: {
        ...prev.product,
        ...productCategory,
      },
      categoryComplete: action.categoryComplete,
      warnings: newWarnings,
    };
  }

  if (action.type === "UPDATE_BRAND") {
    const { type, brandComplete } = action;
    let brandManuf = {
      brandId: brandComplete.id,
      brand: brandComplete.name,
      manufacturer: brandComplete.manufacturer,
      manufacturerId: brandComplete.manufacturerId,
      economicGroup: brandComplete.economicGroup,
    };
    return {
      ...prev,
      product: {
        ...prev.product,
        ...brandManuf,
      },
      brandComplete: action.brandComplete,
    };
  }

  if (action.type === "UPDATE_BRAND_COMPLETE") {
    const { type, brandComplete } = action;
    let brandManuf = { economicGroup: brandComplete.economicGroup };
    return {
      ...prev,
      product: {
        ...prev.product,
        ...brandManuf,
      },
      brandComplete: { ...prev.brandComplete, ...brandComplete },
    };
  }

  if (action.type === "FETCH_COMERCIAL_REFERENCES") {
    const { type, comercialReferences, duplicate } = action;
    return { ...prev, comercialReferences, duplicateProduct: duplicate };
  }

  if (action.type === "FETCH_ECONOMIC_GROUPS") {
    const { type, economicGroups } = action;
    return { ...prev, economicGroups };
  }

  if (action.type === "LINK_EAN") {
    const { link: selected, ean } = action;
    return {
      ...prev,
      relatedEans: prev.relatedEans
        .map((i) => {
          return i.ean === ean ? { ...i, selected } : i;
        })
        .filter((item) => item.description || item.selected),
    };
  }

  if (action.type === "LINK_ALL_EANS") {
    const { link: selected } = action;
    return {
      ...prev,
      relatedEans: prev.relatedEans.map((e) => ({ ...e, selected })),
    };
  }

  if (action.type === "TOGGLE_IS_PACK") {
    return { ...prev, isPack: !prev.isPack };
  }

  if (action.type === "TOGGLE_UPDATE_BLOCK") {
    return { ...prev, updateBlock: !prev.updateBlock };
  }

  if (action.type === "TOGGLE_IS_OWN_BRAND") {
    return { ...prev, isOwnBrand: !prev.isOwnBrand };
  }

  if (action.type === "UPDATE_LOCKS") {
    const { type, ...newLocks } = action;
    return { ...prev, locks: { ...prev.locks, ...newLocks } };
  }

  if (action.type === "UPDATE_EAN_TO_ADD") {
    let ean = validateNumericSize(action.ean, 1, 20);
    return { ...prev, relatedEanToAdd: ean };
  }

  if (action.type === "UPDATE_REFDESCCATEGORY") {
    return { ...prev, refDescCategory: action.refDescCategory };
  }

  if (action.type === "UPDATE_REFBRANDCATEGORY") {
    return { ...prev, refBrandCategory: action.refBrandCategory };
  }

  if (action.type === "UPDATE_RESTRICTEDTAGS") {
    return { ...prev, restrictedTags: action.restrictedTags };
  }

  if (action.type === "ADD_RELATED_EAN") {
    return {
      ...prev,
      relatedEanToAdd: "",
      relatedEans: [
        ...prev.relatedEans,
        {
          description: null,
          selected: true,
          ean: prev.relatedEanToAdd,
        },
      ],
    };
  }

  if (action.type === "FETCHING_GOOGLE") {
    return {
      ...prev,
      search: {
        ...prev.search,

        currentType: action.searchType,

        types: {
          ...prev.search.types,
          [action.searchType]: {
            loading: true,
          },
        },
      },
    };
  }

  if (action.type === "FETCHED_GOOGLE") {
    return {
      ...prev,
      search: {
        ...prev.search,

        types: {
          ...prev.search.types,
          [prev.search.currentType]: {
            loading: false,
            results: action.results,
          },
        },
      },
    };
  }

  if (action.type === "FETCH_GOOGLE_ERR") {
    return {
      ...prev,
      search: {
        ...prev.search,

        types: {
          ...prev.search.types,
          [prev.search.currentType]: {
            err: true,
          },
        },
      },
    };
  }

  if (action.type === "SET_GOOGLE_TYPE") {
    return {
      ...prev,
      search: { ...prev.search, currentType: action.searchType },
    };
  }

  if (action.type === "UPDATE_UNIT") {
    const { type, unit } = action;
    const newUnit = { ...prev.unit, ...unit };
    let finalValue = null;
    let suggestedPack = { quantity: null, pack: null };

    if (newUnit.unit && newUnit.value?.match(/\d+([.,]\d+)?/)) {
      const unitConfig = unities
        .flatMap((g) => g.options)
        .find((u) => u.id === newUnit.unit);
      finalValue = (
        parseFloat(newUnit.value.replaceAll(",", ".")) * unitConfig.ratio
      ).toFixed(6);
    }

    const categoryMedian = prev.categoryComplete?.median;
    const pricePerUnit =
      !finalValue ||
      !categoryMedian ||
      isNaN(prev.product.median) ||
      isNaN(finalValue) ||
      finalValue <= 0
        ? 0
        : prev.product.median /
          (finalValue * (newUnit.quantity ? newUnit.quantity : 1));
    const unitOfMeasurement =
      "unit" in newUnit
        ? unities.find((u) => u.options.find((o) => o.id === newUnit.unit))
            ?.style
        : null;
    const zeroQuantity =
      isNaN(parseFloat(newUnit.value?.replaceAll(",", "."))) ||
      parseFloat(newUnit.value?.replaceAll(",", ".")) <= 0;
    const outOfRangePrice =
      !zeroQuantity &&
      categoryMedian > 0 &&
      prev.product.median > 0 &&
      (categoryMedian * 4 < pricePerUnit || categoryMedian / 4 > pricePerUnit);
    const wrongUnitOfMeasurement =
      prev.categoryComplete?.unitOfMeasurement &&
      prev.categoryComplete?.unitOfMeasurement !== unitOfMeasurement;

    if (
      !(
        !finalValue ||
        !categoryMedian ||
        isNaN(prev.product.median) ||
        isNaN(finalValue) ||
        finalValue <= 0 ||
        categoryMedian <= 0
      ) &&
      prev.product.median / categoryMedian >= 3
    ) {
      if ("value" in unit || "unit" in unit) {
        let pack = (
          prev.product.median /
          (categoryMedian * finalValue)
        ).toFixed(0);
        suggestedPack = { quantity: finalValue, pack: pack };
      }

      if ("quantity" in unit) {
        let quantity =
          unitOfMeasurement === "Und"
            ? (prev.product.median / (categoryMedian * unit.quantity)).toFixed(
                0
              )
            : (prev.product.median / (categoryMedian * unit.quantity)).toFixed(
                6
              );
        suggestedPack = { quantity: quantity, pack: unit.quantity };
      }
    }

    let newWarnings = {
      quantity: zeroQuantity
        ? "A quantidade do produto precisa ser preenchida."
        : outOfRangePrice
        ? "O preço por unidade do produto difere mais de 4 vezes o valor da mediana da categoria. Verifique se a quantidade e a unidade de medida estão corretas antes de salvar."
        : null,
      unitOfMeasurement: wrongUnitOfMeasurement
        ? "Unidade de medida " +
          prev.product.unitOfMeasurement +
          ", não é aceita pela categoria selecionada"
        : null,
    };

    return {
      ...prev,
      unit: { ...newUnit, finalValue },
      warnings: { ...prev.warnings, ...newWarnings },
      product: {
        ...prev.product,
        quantity: finalValue,
        unitOfMeasurement,
        quantityPerPack: newUnit.quantity,
      },
      suggestedPack,
    };
  }

  if (action.type === "TOGGLE_MODAL_CONFIRM") {
    const { type, modalConfirm } = action;

    return {
      ...prev,
      modalConfirm,
    };
  }

  if (action.type === "TOGGLE_MODAL_NEXT_PRODUCT") {
    const { type, modalNextProduct } = action;

    return {
      ...prev,
      modalNextProduct,
    };
  }

  if (action.type === "SAVE_PRODUCT" || action.type === "NEXT_PRODUCT") {
    const {
      type,
      relatedEans,
      status,
      warnings,
      productTags,
      product,
      originalProduct,
    } = action;
    let tags = productTags.flavor.content.concat(
      productTags.color.content,
      productTags.essence.content,
      productTags.package.content,
      productTags.especificity.content,
      productTags.linearMeasure.content,
      productTags.season.content
    );
    let isJumpedProduct = type === "NEXT_PRODUCT" ? true : false;
    let finalUpdate = {
      multipleEans:
        relatedEans.filter((i) => i.selected).map((ean) => ean.ean).length > 0
          ? relatedEans.filter((i) => i.selected).map((ean) => ean.ean)
          : null,
      tags: tags,
      tagLine: productTags.line.content,
      productWarnings: { ...warnings },
      status: status,
      isJumpedProduct: isJumpedProduct,
      quantityPerPack:
        product.quantityPerPack > 0 ? product.quantityPerPack : 1,
    };

    if (type === "NEXT_PRODUCT") {
      if (isEmpty(product.description))
        finalUpdate["description"] = originalProduct.description;
      if (isEmpty(product.ean)) finalUpdate["ean"] = originalProduct.ean;
      if (!product.brandId) {
        finalUpdate["brandId"] = originalProduct.brandId;
        finalUpdate["manufacturerId"] = originalProduct.manufacturerId;
        finalUpdate["brand"] = originalProduct.brand;
        finalUpdate["manufacturer"] = originalProduct.manufacturer;
      }
      if (!product.categoryId) {
        finalUpdate["categoryId"] = originalProduct.categoryId;
        finalUpdate["category"] = originalProduct.category;
        finalUpdate["categoryReportId"] = originalProduct.categoryReportId;
      }
      if (isEmpty(product.quantity) || product.quantity <= 0)
        finalUpdate["quantity"] = originalProduct.quantity;
      if (!product.unitOfMeasurement)
        finalUpdate["unitOfMeasurement"] = originalProduct.unitOfMeasurement;
      return {
        ...prev,
        product: { ...prev.product, ...finalUpdate },
        modalNextProduct: true,
      };
    }

    return {
      ...prev,
      product: { ...prev.product, ...finalUpdate },
      modalConfirm: true,
    };
  }

  if (action.type === "MODERATOR_LEVEL") {
    const { type, modLevel } = action;
    return { ...prev, modLevel };
  }

  return prev;
}

function GoogleSearchItem(props) {
  const { item } = props;

  return (
    <div className="h-google-item">
      <div className="h-google-header">
        <a
          className="h-google-item-title"
          target="_blank"
          href={item.link}
          rel="noreferrer"
        >
          {item.title}
        </a>
      </div>
      <div className={`h-google-header-content`}>
        {item.content}
        <div style={{ display: "block", clear: "both" }} />
      </div>
    </div>
  );
}

function ImageItem(props) {
  const { src } = props;

  const { createModal } = useModal();

  const zoomImage = () => {
    createModal(
      <div className="h-pmo-image-zoom">
        <img src={src} alt=""></img>
      </div>
    );
  };

  return (
    <div className="h-pmo-image-wrapper">
      <div className="h-pmo-image" onClick={zoomImage}>
        <div className="h-pmo-hover"></div>
        <img className="h-brand-image" src={src} alt=""></img>
      </div>
    </div>
  );
}

function EanBullet(props) {
  const { selected, ean, description, onToggle } = props;

  const tooltip = useRef();

  const adjustTooltipPositions = useCallback(() => {
    tooltip.current.style.transform = null;
    const rect = tooltip.current.getBoundingClientRect();
    const diff = Math.max(30 - rect.left, 0);
    const percentDiff = (diff / rect.width) * 100;
    tooltip.current.style.transform = `translateX(-${50 - percentDiff}%)`;
  }, [tooltip]);

  useEffect(() => {
    window.addEventListener("resize", adjustTooltipPositions);
    return () => window.removeEventListener("resize", adjustTooltipPositions);
  }, [adjustTooltipPositions]);

  useEffect(() => {
    adjustTooltipPositions();
  });

  return (
    <div
      className={`h-tooltip-wrapper h-bullet ${
        selected ? "h-bullet-confirm" : ""
      }`}
    >
      <div className="h-bullet-content">{ean}</div>
      {selected ? (
        <div className="h-bullet-btn" onClick={() => onToggle(false)}>
          -
        </div>
      ) : (
        <div className="h-bullet-btn" onClick={() => onToggle(true)}>
          +
        </div>
      )}
      <div className="h-tooltip-caret" />
      <div className="h-tooltip" ref={tooltip}>
        {description || "Sem descrição"}
      </div>
    </div>
  );
}

function validateProduct(product, duplicateProduct, isOwnBrand, productTags) {
  const errors = [];
  if (duplicateProduct)
    errors.push({
      duplicateProduct: "O EAN já está cadastrado para outro produto",
    });
  if (isEmpty(product.description))
    errors.push({ description: "A descrição está vazia" });
  if (isEmpty(product.ean)) errors.push({ ean: "O EAN está vazio" });
  if (!product.brandId) errors.push({ brand: "A marca está vazia" });
  if (!product.manufacturerId)
    errors.push({ manufacturer: "Marca não possui fabricante" });
  if (!product.categoryId) errors.push({ category: "A categoria está vazia" });
  if (isEmpty(product.quantity) || product.quantity <= 0)
    errors.push({ quantity: "A quantidade unitária está vazia" });
  if (!product.unitOfMeasurement)
    errors.push({ unitOfMeasurement: "A unidade de medida está vazia" });
  if (isOwnBrand && !product.economicGroup)
    errors.push({ economicGroup: "A rede está vazia" });

  return errors.length ? errors : null;
}

function ProductVerification(props) {
  const { state, updateProduct, dispatch, jumpedProduct } = props;
  const {
    product,
    brandComplete,
    categoryComplete,
    ncm,
    productImgSrc,
    duplicateProduct,
    isOwnBrand,
    productTags,
    updateBlock,
  } = state;
  const [brandSuggestion, setBrandSuggestion] = useState(null);

  const onWriteMsg = (e) => {
    const brandSuggestion = validateTextSize(e.target.value, 1, 60);
    setBrandSuggestion(brandSuggestion);
  };

  const onRadioClick = (e) => {
    setBrandSuggestion(null);
    dispatch({ type: "UPDATE_PRODUCT", moderatorMsg: e.target.value });
  };
  let validation = null;
  if (product.status === "LIBERADO" && !jumpedProduct)
    validation = validateProduct(
      product,
      duplicateProduct,
      isOwnBrand,
      productTags
    );
  return (
    <div>
      {jumpedProduct ? (
        <>
          <p>Indique o motivo de ter pulado.</p>
          <br />
          <input
            type="radio"
            name="message"
            value="Não deu busca na internet."
            onClick={(e) => onRadioClick(e)}
          />
          Não deu busca na internet
          <br />
          <input
            type="radio"
            name="message"
            value="Não encontrou a Categoria no sistema."
            onClick={(e) => onRadioClick(e)}
          />
          Não encontrou a Categoria no sistema
          <br />
          <input
            type="radio"
            name="message"
            value="Não encontrou a Marca no sistema. Marca Sugerida: "
            onClick={(e) => onRadioClick(e)}
          />
          Não encontrou a Marca no sistema
          <br />
          <br />
          {!product.moderatorMsg?.includes("Marca") ? null : (
            <Input
              label="Sugestão de Marca"
              value={brandSuggestion}
              placeholder="Sugira o nome da marca que deveria ser criada."
              onChange={(e) => onWriteMsg(e)}
            />
          )}
          <Button
            disabled={
              updateBlock ||
              !product.moderatorMsg ||
              (product.moderatorMsg?.includes("Marca") &&
                (!brandSuggestion || brandSuggestion?.trim() === ""))
            }
            layout="info"
            onClick={() =>
              updateProduct(
                product,
                brandComplete,
                categoryComplete,
                ncm,
                brandSuggestion
              )
            }
          >
            Pular
          </Button>
        </>
      ) : product.status === "PAUSADO" ? (
        <>
          <p>Tem certeza que deseja pausar o produto?</p>
          {!product.multipleEans || product.multipleEans.length <= 0 ? null : (
            <p>
              Outros EANS que serão PAUSADOS:
              {product.multipleEans.map((ean, i) => (
                <li key={i}>{ean}</li>
              ))}
            </p>
          )}
          <br />
          <Button
            disabled={updateBlock}
            layout="info"
            onClick={() =>
              updateProduct(product, brandComplete, categoryComplete, ncm)
            }
          >
            Pausar
          </Button>
        </>
      ) : validation ? (
        <div>
          <p>Os seguintes erros foram encontrados e precisam ser corrigidos:</p>
          <ul>
            {validation.map((val, i) => (
              <li key={i} style={{ color: "red", weight: "500" }}>
                {Object.values(val)[0]}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <p>Confirme os dados a serem salvos:</p>
          <p>ID: {product.id}</p>
          <p>EAN: {product.ean}</p>
          <p>Descrição: {product.description}</p>
          {product?.productWarnings?.category ? (
            <>
              <p style={{ color: "red" }}>
                Categoria Produto: {product.category} - {product.categoryId}
              </p>
              <span style={{ color: "red" }}>
                ATENÇÃO:
                <br />
              </span>
              <span style={{ color: "red" }}>
                {product.productWarnings.category}
              </span>
            </>
          ) : (
            <p>
              Categoria Produto: {product.category} - {product.categoryId}
            </p>
          )}
          {product.nielsen ? (
            <p>
              Categoria: {product.nielsen} - {product.categoryReportId}
            </p>
          ) : null}
          <p>
            Marca: {product.brand} - {product.brandId}
          </p>
          <p>
            Fabricante: {product.manufacturer} - {product.manufacturerId}
          </p>
          {product.productWarnings?.quantity ||
          product.productWarnings?.unitOfMeasurement ? (
            <>
              <p style={{ color: "red" }}>Quantidade: {product.quantity}</p>
              <p style={{ color: "red" }}>
                Quantidade por pack:{" "}
                {product.quantityPerPack ? product.quantityPerPack : "1"}
              </p>
              <p style={{ color: "red" }}>
                Unidade de medida: {product.unitOfMeasurement}
              </p>
              <span style={{ color: "red" }}>
                ATENÇÃO:
                <br />
              </span>
              <span style={{ color: "red" }}>
                {product.productWarnings.quantity}
                <br />
              </span>
              <span style={{ color: "red" }}>
                {product.productWarnings.unitOfMeasurement}
                <br />
              </span>
            </>
          ) : (
            <>
              <p>Quantidade: {product.quantity}</p>
              <p>
                Quantidade por pack:{" "}
                {product.quantityPerPack ? product.quantityPerPack : "1"}
              </p>
              <p>Unidade de medida: {product.unitOfMeasurement}</p>
            </>
          )}
          <p>
            Imagem:{" "}
            <img
              width="100px"
              heigth="100px"
              src={productImgSrc}
              alt="/static/placeholder.png"
            />
          </p>
          {!product?.multipleEans?.length ? null : (
            <p>
              Outros EANS que serão alterados:{" "}
              {product.multipleEans.map((ean, i) => (
                <li key={i}>{ean}</li>
              ))}
            </p>
          )}
          <hr />
          <Button
            disabled={updateBlock}
            layout="confirm"
            onClick={() =>
              updateProduct(product, brandComplete, categoryComplete, ncm)
            }
          >
            Salvar
          </Button>
        </div>
      )}
    </div>
  );
}

/**
 * The Product edit page component
 */
function ProductPage2() {
  // const safeHttp = useSafeHttp();
  const { openModal } = useModal();
  const location = useLocation();
  const { ean } = useParams();
  const params = new URLSearchParams(useLocation().search);
  const productToken = params.get("token") ?? null;
  const [state, dispatch] = useReducer(
    reducer,
    { path: location.pathname },
    makeDefaultState
  );
  const {
    breadcrump,
    product,
    originalProduct,
    relatedEans,
    comercialReferences,
    search,
    locks,
    economicGroups,
    fixesSuggestions,
    unit,
    modalConfirm,
    tagTypeChosen,
    refBrandCategory,
    tags,
    productTags,
    restrictedTags,
    brandComplete,
    categoryComplete,
    isOwnBrand,
    isPack,
    suggestedPack,
    warnings,
    modalNextProduct,
    modLevel,
    refDescCategory,
  } = state;
  const descriptionTags =
    product && tags && productTags
      ? textMatcher(product, tags, productTags)
      : null;
  const { createModal } = useModal();
  const fileInput = useRef();
  const leftColumn = useRef();
  const rightColumn = useRef();

  const setLoadRelatedEansTimeout = useSetTimeout();
  const setLoadSearchTimeout = useSetTimeout();

  useSyncColumns(leftColumn, rightColumn);

  const getModeratorLevel = async () => {
    try {
      const { data: moderatorLevel } = await http.get(
        baseUrl + "/api/v1/moderator-level/"
      );
      return moderatorLevel;
    } catch (e) {
      console.log(e);
    }
  };

  const refreshReservation = useCallback(async () => {
    try {
      http.get(baseUrl + "/api/v1/refresh-reservation/" + ean);
    } catch (e) {
      console.log(e);
    }
  }, [ean]);

  const fetchQueue = async function () {
    const { data: ean } = await http.get(baseUrl + `/api/v1/products/queue`);
    return ean;
  };

  const getNextProductOnUpdate = async () => {
    try {
      const nextProd = await fetchQueue();
      const token = nextProd.token ?? "";
      nextProd
        ? window.location.replace(
            "/product2/" + nextProd.ean + "?token=" + token
          )
        : createModal("Nenhum ean encontrado para iniciar a fila de moderação");
    } catch (err) {
      createModal("Erro ao buscar fila de produtos");
    }
  };

  const updateProductRequest = (form) =>
    http.put(`${baseUrl}/api/v1/products`, form, {}, true);

  const updateProduct = useCallback(
    async (product, brand, category, ncm, brandSuggestion = "") => {
      dispatch({ type: "TOGGLE_UPDATE_BLOCK" });
      try {
        const formData = new FormData();

        let unitOfMeasurement = product.unitOfMeasurement;

        if (product.productWarnings?.unitOfMeasurement) {
          unitOfMeasurement = undefined;
        }

        if (product.id) formData.append("id", product.id);
        if (product.description)
          formData.append("description", product.description?.trim());
        if (product.ean) formData.append("ean", product.ean?.trim());
        if (product.ncm) formData.append("ncm", product.ncm);
        if (product.median) formData.append("median", product.median);
        if (product.categoryId)
          formData.append("categoryId", product.categoryId);
        if (product.category) formData.append("category", product.category);
        if (product.categoryReportId)
          formData.append("categoryReportId", product.categoryReportId);
        if (product.manufacturerId)
          formData.append("manufacturerId", product.manufacturerId);
        if (product.manufacturer)
          formData.append("manufacturer", product.manufacturer);
        if (product.brandId) formData.append("brandId", product.brandId);
        if (product.brand) formData.append("brand", product.brand);
        if (product.quantity) formData.append("quantity", product.quantity);
        if (product.quantityPerPack)
          formData.append("quantityPerPack", product.quantityPerPack);
        if (unitOfMeasurement)
          formData.append("unitOfMeasurement", unitOfMeasurement);
        if (product.status) formData.append("status", product.status);
        if (product.moderatorMsg)
          formData.append(
            "moderatorMsg",
            product.moderatorMsg + (brandSuggestion ? brandSuggestion : "")
          );
        if (product.isJumpedProduct) formData.append("isJumpedProduct", true);
        if (product.multipleEans)
          formData.append("multipleEans", product.multipleEans);
        if (!isEmptyList(product.tags)) formData.append("tags", product.tags);
        if (!isEmpty(product.tagLine))
          formData.append("tagLine", product.tagLine);
        if (product.newProductImg)
          formData.append("newProductImg", product.newProductImg);
        if (product.file) formData.append("file", product.file);
        if (product.productWarnings) {
          formData.append(
            "warningQuantity",
            product.productWarnings.quantity
              ? product.productWarnings.quantity
              : null
          );
          formData.append(
            "warningUnitOfMeasurement",
            product.productWarnings.unitOfMeasurement
              ? product.productWarnings.unitOfMeasurement
              : null
          );
          formData.append(
            "warningCategory",
            product.productWarnings.category
              ? product.productWarnings.category
              : null
          );
        }
        if (category?.unitOfMeasurement)
          formData.append(
            "categoryUnitOfMeasurement",
            category.unitOfMeasurement
          );
        if (category?.median)
          formData.append("categoryMedian", category.median);
        if (category?.reportId)
          formData.append("categoryReportId", category.reportId);
        if (category?.tagCes)
          formData.append("categoryTagCes", category.tagCes);
        if (category?.tagEspecificity)
          formData.append("categoryTagEspecificity", category.tagEspecificity);
        if (category?.tagLine)
          formData.append("categoryTagLine", category.tagLine);
        if (category?.tagLinearMeasure)
          formData.append(
            "categoryTagLinearMeasure",
            category.tagLinearMeasure
          );
        if (category?.tagPackage)
          formData.append("categoryTagPackage", category.tagPackage);
        if (category?.tagSeason)
          formData.append("categoryTagSeason", category.tagSeason);
        if (brand?.image) formData.append("brandImage", brand.image);
        if (brand?.economicGroup)
          formData.append(
            "brandEconomicGroup",
            brand.economicGroup?.toUpperCase().trim()
          );
        if (ncm?.catProdId) formData.append("ncmCatProdId", ncm.catProdId);
        if (ncm?.description)
          formData.append("ncmDescription", ncm.description);

        const response = await updateProductRequest(formData);
        window.history.pushState({}, "", "/product/load-next/product");
        dispatch({ type: "TOGGLE_MODAL_CONFIRM", modalConfirm: false });
        dispatch({
          type: "TOGGLE_MODAL_NEXT_PRODUCT",
          modalNextProduct: false,
        });
        openModal(
          <div className="align-center">
            <p>
              Produto atualizado com sucesso!
              <br />
              Aguarde o carregamento do próximo item.
            </p>
            <br />
            <br />
          </div>,
          { replace: true }
        );
        getNextProductOnUpdate();
      } catch (e) {
        dispatch({ type: "TOGGLE_MODAL_CONFIRM", modalConfirm: false });
        dispatch({
          type: "TOGGLE_MODAL_NEXT_PRODUCT",
          modalNextProduct: false,
        });
        openModal(getErrorMessage(e));
      } finally {
        dispatch({ type: "TOGGLE_UPDATE_BLOCK" });
      }
    }
  );

  const fetchProductData = useCallback(function (ean) {
    return http.post(`${baseUrl}/api/v2/product/${ean}`);
  }, []);

  const fetchProductDataByToken = useCallback(async function (
    ean,
    productToken
  ) {
    try {
      const response = await http.get(
        `${baseUrl}/api/v1/products/verify?ean=${ean}&token=${productToken}`
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  },
  []);

  const fetchComercialReferencesByEan = useCallback(function (ean) {
    return http.post(`${baseUrl}/api/v1/products/comercialRefs/${ean}`);
  }, []);

  const fetchRefDescCategory = useCallback(function (catId) {
    catId = !catId ? 0 : catId;
    return http.get(`${baseUrl}/api/v1/products/category-reference/${catId}`);
  }, []);

  const fetchRefBrandCategory = useCallback(function (brandId) {
    brandId = !brandId ? 0 : brandId;
    return http.get(
      `${baseUrl}/api/v1/products/brand-category-reference/${brandId}`
    );
  }, []);

  const fetchTags = useCallback(function () {
    return http.get(`${baseUrl}/api/v1/tags-all`);
  }, []);

  const fetchRestrictedTags = useCallback(function (catId) {
    return http.get(`${baseUrl}/api/v1/category-restricted-tags/${catId}`);
  }, []);

  const getEansList = useCallback(function (desc) {
    if (!desc.description || desc.description === "") return [];
    try {
      return http.get(
        `${baseUrl}/api/v1/products/related-eans/${desc.description}`
      );
    } catch (e) {
      return [];
    }
  }, []);

  const fetchEconomicGroups = async function () {
    if (economicGroups) return;

    const { data: ecoGroups } = await http.get(
      baseUrl + `/api/v1/products/economic-groups`
    );
    dispatch({ type: "FETCH_ECONOMIC_GROUPS", economicGroups: ecoGroups });
  };

  const setIsOwnBrand = () => {
    dispatch({ type: "TOGGLE_IS_OWN_BRAND" });
    dispatch({
      type: "UPDATE_BRAND_COMPLETE",
      brandComplete: { economicGroup: null },
    });
  };

  const setBrandComplete = (option) => {
    let brandComplete = {
      id: option ? option.id : null,
      name: option ? option.brand : null,
      ownBrand: option ? option.ownBrand : false,
      image: option ? option.image : null,
      manufacturer: option ? option.manufacturer : null,
      manufacturerId: option ? option.manufacturerId : null,
      economicGroup: option ? option.economicGroup : null,
    };

    if (brandComplete.ownBrand === !isOwnBrand) {
      dispatch({ type: "TOGGLE_IS_OWN_BRAND" });
    }
    dispatch({ type: "UPDATE_BRAND", brandComplete });
  };

  const setDescriptionWithTags = () => {
    let packTag = productTags.pack.content
      ? productTags.pack.content + " "
      : "";
    let baseTag = productTags.base.name ? productTags.base.name + " " : "";
    let flavorDesc = productTags.flavor.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let essenceDesc = productTags.essence.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let colorDesc = productTags.color.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let packageDesc = productTags.package.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let especificityDesc = productTags.especificity.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let linearMeasureDesc = productTags.linearMeasure.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let seasonDesc = productTags.season.content?.map(
      (j) => tags.find((t) => t.id.toString() === j.toString())?.name
    );
    let tagsDesc =
      flavorDesc
        .concat(
          essenceDesc,
          colorDesc,
          packageDesc,
          especificityDesc,
          linearMeasureDesc,
          seasonDesc
        )
        .join(" ") + " ";
    let lineTag = productTags.line.content
      ? productTags.line.content + " "
      : "";
    let brand = product.brand ? product.brand + " " : "";
    let qtd = state.unit?.value ? state.unit.value.toString() : "";
    let unit = state.unit?.unit ? state.unit.unit : "";
    let description =
      packTag + baseTag + tagsDesc + lineTag + brand + qtd + unit;

    description = validateTextSize(description, 1, 300);
    dispatch({ type: "UPDATE_PRODUCT", description });
  };

  const setProductDescription = useCallback(
    function (description) {
      description = validateTextSize(description, 1, 300);

      setLoadRelatedEansTimeout(async function () {
        const responseEans = await getEansList({ description });
        const eans = responseEans.data ? responseEans.data : [];
        dispatch({ type: "FETCH_NEW_RELATED_EANS", eans, description });
      }, 3000);

      dispatch({ type: "UPDATE_PRODUCT", description });
    },
    [dispatch, setLoadRelatedEansTimeout]
  );

  const setProductEan = useCallback(
    function (ean) {
      ean = validateNumericSize(ean, 1, 20);
      dispatch({ type: "UPDATE_PRODUCT", ean });
      let duplicate = false;

      setLoadSearchTimeout(async function () {
        setSearchType("ean", ean);
        if (originalProduct && ean !== originalProduct.ean) {
          const responseProduct = await getProductByEan(ean);
          if (responseProduct) duplicate = true;
        }
        const response = await fetchComercialReferencesByEan(ean);
        const comercialReferences = response?.data;
        dispatch({
          type: "FETCH_COMERCIAL_REFERENCES",
          comercialReferences,
          duplicate,
        });
      }, 1000);
    },
    [dispatch, fetchComercialReferencesByEan, setLoadSearchTimeout]
  );

  const setIsPack = () => {
    dispatch({ type: "TOGGLE_IS_PACK" });
    dispatch({ type: "UPDATE_UNIT", unit: { quantity: "1" } });
    dispatch({
      type: "UPDATE_TAGS",
      pack: !isPack ? { content: "Caixa" } : { content: null },
    });
  };

  const setPackQuantity = useCallback(function (qtt) {
    qtt = validateNumericSize(qtt, 1, 4);
    dispatch({ type: "UPDATE_UNIT", unit: { quantity: qtt } });
  });

  const setSuggestedPack = function (pack) {
    dispatch({ type: "TOGGLE_IS_PACK" });
    dispatch({ type: "UPDATE_UNIT", unit: { quantity: pack } });
  };

  const setRefDescCategory = async function (catId) {
    const responseRef = await fetchRefDescCategory(catId);
    const refDescCategory = responseRef.data;
    dispatch({ type: "UPDATE_REFDESCCATEGORY", refDescCategory });
  };

  const setRefBrandCategory = async function (catId) {
    const responseRef = await fetchRefBrandCategory(catId);
    const refBrandCategory = responseRef.data;
    dispatch({ type: "UPDATE_REFBRANDCATEGORY", refBrandCategory });
  };

  const setRestrictedTags = async function (catId) {
    if (!catId) catId = 0;
    const responseRetrict = await fetchRestrictedTags(catId);
    const restrictedTags = responseRetrict.data;
    dispatch({ type: "UPDATE_RESTRICTEDTAGS", restrictedTags });
  };

  const getProductByEan = async function (ean) {
    const { data: productCheck } = await http.get(
      baseUrl + "/api/v1/products/" + ean
    );
    return productCheck;
  };

  const { fetchProductCategories } = useProductCategory();

  const fetchBrands = async function (search, offset) {
    if (product.ean) {
      const eanList = locks.brand
        ? [
            ...relatedEans
              .filter((i) => i.selected)
              .map((ean) => ean.ean.substr(0, 8)),
            product.ean.substr(0, 8),
          ].toString()
        : null;
      const { data: brands } = await http.get(
        baseUrl + `/api/v1/products/brands`,
        {
          params: {
            search,
            offset,
            eanList,
            limit: 10,
          },
        }
      );

      return {
        options: brands.map((b) => ({
          id: b.id,
          name:
            b.name +
            " - " +
            (b.manufacturer ? b.manufacturer : "Sem fabricante"),
          brand: b.name,
          manufacturerId: b.manufacturerId,
          manufacturer: b.manufacturer,
          image: b.image,
          ownBrand: b.ownBrand,
          economicGroup: b.economicGroup,
        })),
        hasMore: brands.length === 10,
      };
    }
    let brands = [];
    return {
      options: brands.map((b) => ({
        id: b.id,
        name: b.name,
        brand: b.name,
        manufacturerId: b.manufacturerId,
        manufacturer: b.manufacturer,
        image: b.image,
        ownBrand: b.ownBrand,
        economicGroup: b.economicGroup,
      })),
      hasMore: brands.length === 10,
    };
  };

  const handleProductBrandOnSave = (brandComplete) => {
    if (brandComplete.ownBrand === !isOwnBrand)
      dispatch({ type: "TOGGLE_IS_OWN_BRAND" });
    dispatch({ type: "UPDATE_BRAND", brandComplete });
  };

  const handleNewBrandOnClick = () =>
    createModal(
      <SaveProductBrandForm
        brandComplete={brandComplete}
        onSavedBrand={handleProductBrandOnSave}
      />,
      { width: "900px", backDropClose: false }
    );

  const fetchNcmCategories = (search, offset) =>
    fetchProductCategories(search, offset, product.ncm);

  const selectQuantity = () => {
    let selectList = [];
    for (let i = 0; i < 100; i++) {
      selectList.push({ id: i + 1, name: i + 1 });
    }
    return selectList;
  };

  const setSearchType = async function (type, term) {
    if (search.types[type].results && !term) {
      dispatch({ type: "SET_GOOGLE_TYPE", searchType: type });
      return;
    }

    dispatch({ type: "FETCHING_GOOGLE", searchType: type });

    try {
      const { data: results } = await http.get(
        baseUrl +
          `/api/v1/products/googleSearch/${
            term || searchTypes[type].getTerm(product)
          }`
      );

      dispatch({ type: "FETCHED_GOOGLE", results });
    } catch (e) {
      dispatch({ type: "FETCH_GOOGLE_ERR" });
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    fileInput.current.files = e.dataTransfer.files;
    setPreviewImage(e.dataTransfer.files[0]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const setPreviewImage = (file) => {
    if (!file) return;

    if (file.size > 120000) {
      alert("Este arquivo excede o limite de 120KB!");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      dispatch({
        type: "NEW_PRODUCT_IMAGE",
        productImgSrc: e.target.result,
        file: file,
      });
    };

    reader.readAsDataURL(file);
  };

  const onUploadFileSelected = () => {
    const file = fileInput.current.files[0];
    setPreviewImage(file);
  };

  function scroll(idLeft, idRight) {
    const elementLeft = document.getElementById(idLeft);
    const elementRight = document.getElementById(idRight);

    if (!elementLeft || !elementRight) {
      return;
    }

    rightColumn.current.scrollTop = Math.max(
      elementRight.offsetTop - elementLeft.offsetTop,
      0
    );
  }

  const callGetEansList = async function (description, prodEan) {
    const responseRelatedEans = await getEansList({ description });
    let relatedEans = (responseRelatedEans.data || [])
      .filter((data) => data !== prodEan)
      .map((item) => ({
        description: description,
        ean: item,
        selected: true,
      }));
    dispatch({ type: "FETCH_RELATED_EANS", relatedEans });
  };

  /**
   * Effect of fetching initial component state: the current product with ncm and categories options list.
   */
  useEffect(() => {
    const fn = async () => {
      try {
        let data;

        if (productToken) {
          const response = await fetchProductDataByToken(ean, productToken);
          data = JSON.parse(response.data);
        } else {
          const response = await fetchProductData(ean);
          data = response.data;
        }

        const {
          product,
          ncm,
          comercialReferences,
          categoryComplete,
          restrictedTags,
          brandComplete,
          fixesSuggestions,
          search,
        } = data;

        product.ncmDescription = ncm ? ncm.ncm + " - " + ncm.description : null;
        product.economicGroup = brandComplete?.economicGroup;
        product.ean = ean;

        callGetEansList(product.description, product.ean);

        const responseTags = await fetchTags();
        const tags = responseTags.data;

        const responseRefDescCategory = await fetchRefDescCategory(
          product.categoryId
        );
        const refDescCategory = responseRefDescCategory.data;

        const responseRefBrandCategory = await fetchRefBrandCategory(
          product.brandId
        );
        const refBrandCategory = responseRefBrandCategory.data;

        dispatch({
          type: "FETCH_PRODUCT",
          ncm,
          product,
          comercialReferences,
          categoryComplete,
          brandComplete,
          fixesSuggestions,
          tags,
          restrictedTags,
          search,
          refDescCategory,
          refBrandCategory,
        });
      } catch (e) {
        dispatch({ type: "ERROR" });
      }
    };

    fn();
  }, [ean, productToken]);

  useEffect(function () {
    const fn = async () => {
      try {
        const modLevel = await getModeratorLevel();
        if (modLevel) dispatch({ type: "MODERATOR_LEVEL", modLevel });
        refreshReservation();
        const intervalId = setInterval(refreshReservation, 1000 * 60 * 10);

        return () => {
          clearInterval(intervalId);
        };
      } catch (e) {
        dispatch({ type: "ERROR" });
      }
    };
    fn();
  }, []);

  return (
    <Template breadcrump={breadcrump}>
      {!modalConfirm ? null : (
        <Modal
          close={() =>
            dispatch({ type: "TOGGLE_MODAL_CONFIRM", modalConfirm: false })
          }
        >
          <ProductVerification
            state={state}
            updateProduct={updateProduct}
            dispatch={dispatch}
            jumpedProduct={false}
          />
        </Modal>
      )}
      {!modalNextProduct ? null : (
        <Modal
          close={() =>
            dispatch({
              type: "TOGGLE_MODAL_NEXT_PRODUCT",
              modalNextProduct: false,
            })
          }
        >
          <ProductVerification
            state={state}
            updateProduct={updateProduct}
            dispatch={dispatch}
            jumpedProduct={true}
          />
        </Modal>
      )}
      <Title>
        {!params.get("token") || modLevel === "COMMON" ? null : (
          <span style={{ fontSize: "12px", color: "red" }}>
            {originalProduct?.moderator ? (
              <span>ENVIADO POR: {originalProduct.moderator}; </span>
            ) : null}
            {originalProduct?.moderatorMsg ? (
              <span>
                MENSAGEM:{" "}
                {isNullText(originalProduct.moderatorMsg)
                  ? ""
                  : originalProduct.moderatorMsg}
                ;{" "}
              </span>
            ) : null}
            <span>
              ATENÇÃO:
              {isNullText(originalProduct?.productWarnings?.category)
                ? ""
                : " categoria em relação ao NCM; "}
              {isNullText(originalProduct?.productWarnings?.quantity)
                ? ""
                : " quantidade; "}
              {isNullText(originalProduct?.productWarnings?.unitOfMeasurement)
                ? ""
                : " unidade de medida; "}
              {originalProduct?.isJumpedProduct ? " Produto Pulado; " : ""}
            </span>
          </span>
        )}
        {!fixesSuggestions ? null : (
          <span style={{ fontSize: "12px", color: "blue" }}>
            <span>Recuperado pela IA para correção.</span>
            <span>
              Revisar:
              {isNullText(fixesSuggestions?.prodFixes?.newUnit)
                ? ""
                : " Unidade de medida;"}
              {isEmpty(fixesSuggestions?.prodFixes?.newQuantity) &&
              isEmpty(fixesSuggestions?.prodFixes?.newQuantityPack)
                ? ""
                : " Qtd unitária e qtd no Pack;"}
              {isEmpty(fixesSuggestions?.prodFixes?.newManufacturer)
                ? ""
                : " Fabricante;"}
              {isNullText(fixesSuggestions?.prodFixes?.newDescription)
                ? ""
                : " Descrição;"}
              {isEmptyObj(fixesSuggestions?.brandComplete) ? "" : " Marca;"}
              {isEmptyObj(fixesSuggestions?.categoryComplete)
                ? ""
                : " Categoria;"}
            </span>
          </span>
        )}
        <br />
        <span>{state.title}</span>
      </Title>
      <Card>
        {state.error ? (
          "Ocorreu um erro ao recuperar os dados, por favor tente novamente!"
        ) : !product || !originalProduct ? (
          "Carregando..."
        ) : (
          <>
            <div className="h-row h-pmo-main-row">
              <div className="h-col h-col-7" ref={leftColumn}>
                <div className="h-pmo-left-column">
                  <div className="h-row">
                    <div className="h-col h-col-2">
                      <div className="h-separator" id="image-title">
                        Imagem
                      </div>
                      <div className="h-ignore-input-spacing" />
                      <p className="h-section-explanation">
                        Defina a imagem do produto
                      </p>
                      <div className="h-row">
                        <div className="h-col h-col-2">
                          <input
                            ref={fileInput}
                            type="file"
                            style={{ display: "none" }}
                            onChange={onUploadFileSelected}
                          />
                          <div
                            className="h-dropzone"
                            onDrop={onDrop}
                            onDragOver={onDragOver}
                          >
                            <img
                              className="h-brand-image"
                              src={state.productImgSrc}
                              alt="/static/placeholder.png"
                            />
                          </div>
                        </div>
                        <div className="h-col h-col-8">
                          <Button
                            layout="info"
                            style={{
                              verticalAlign: "top",
                              marginBottom: "10px",
                            }}
                            onClick={() => fileInput.current.click()}
                            onFocus={() =>
                              scroll("image-title", "image-ref-title")
                            }
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="h-col h-col-8">
                      <div className="h-separator" id="identification-title">
                        Identificação
                      </div>
                      <div className="h-ignore-input-spacing" />
                      <p className="h-section-explanation">
                        Defina o ean e as especificações do produto
                      </p>
                      <div className="h-row">
                        <div className="h-col h-col-3">
                          <Input
                            label="Ean"
                            value={product.ean}
                            onChange={(e) => setProductEan(e.target.value)}
                            onFocus={() =>
                              scroll(
                                "identification-title",
                                "identification-ref-title"
                              )
                            }
                          />
                          <div className="h-ignore-input-spacing" />
                          <div className="h-input-observation">
                            Ean original: {originalProduct.ean || "Nenhum"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="h-spacing" />

                  <div className="h-separator" id="relatedEans-title">
                    Vincular EANs
                    <div className="h-title-add-ons"></div>
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <p className="h-section-explanation">
                    Selecione aqui os EANS que apontam para este mesmo produto.
                    Ao salvar este formulário, as alterações feitas para este
                    produto serão replicados para todos os outros EANs que forem
                    referenciados.
                    <br />
                    Mudar o ean irá recarregar a lista de eans relacionados mas
                    a lista de eans vinculados será preservada!
                  </p>
                  <div
                    className="h-input-with-buttons"
                    style={{ maxWidth: "300px", marginTop: "10px" }}
                  >
                    <Input
                      label="Adicionar EAN"
                      placeholder="Ean"
                      type="number"
                      value={state.relatedEanToAdd}
                      onChange={(e) =>
                        dispatch({
                          type: "UPDATE_EAN_TO_ADD",
                          ean: e.target.value,
                        })
                      }
                      readOnly={
                        !isEmptyObj(fixesSuggestions) &&
                        product.status === "LIBERADO"
                      }
                    />
                    <button
                      className="h-input-button"
                      type="button"
                      disabled={
                        !state.relatedEanToAdd ||
                        state.relatedEanToAdd === originalProduct.ean ||
                        relatedEans
                          ?.map((e) => e.ean)
                          .includes(state.relatedEanToAdd)
                      }
                      onClick={() => dispatch({ type: "ADD_RELATED_EAN" })}
                    >
                      Adicionar
                    </button>
                  </div>

                  <div className="h-form-label">
                    Eans Relacionados
                    {!relatedEans.find((i) => i.selected) ? null : (
                      <>
                        &nbsp;
                        <span
                          className="h-link"
                          onClick={() =>
                            dispatch({ type: "LINK_ALL_EANS", link: false })
                          }
                        >
                          Remover Todos
                        </span>
                      </>
                    )}
                    {!relatedEans.find((i) => !i.selected) ? null : (
                      <>
                        &nbsp;
                        <span
                          className="h-link"
                          onClick={() =>
                            dispatch({ type: "LINK_ALL_EANS", link: true })
                          }
                        >
                          Adicionar Todos
                        </span>
                      </>
                    )}
                  </div>
                  <div style={{ height: "5px" }} />
                  {relatedEans.length ? (
                    relatedEans.map(function (item) {
                      return (
                        <EanBullet
                          key={item.ean}
                          ean={item.ean}
                          description={item.description}
                          selected={item.selected}
                          onToggle={(link) =>
                            dispatch({ type: "LINK_EAN", ean: item.ean, link })
                          }
                        />
                      );
                    })
                  ) : (
                    <>
                      <div className="h-input-observation">Vazio</div>
                      <div className="h-spacing" />
                    </>
                  )}

                  <div className="h-spacing" />

                  <div className="h-separator" id="category-title">
                    Categoria Produto
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <p className="h-section-explanation">
                    Selecione a categoria do produto.{" "}
                    <a
                      target="_blank"
                      href="https://drive.google.com/drive/folders/1U_nPZTUGFQPxCZA-bP5FQaAkB6x5aZBx"
                    >
                      Acessar definições das categorias
                    </a>
                  </p>
                  {!isEmptyObj(fixesSuggestions) &&
                  product.status === "LIBERADO" &&
                  isEmptyObj(fixesSuggestions.categoryComplete) ? (
                    <Input
                      label="Categoria Produto"
                      readOnly
                      value={product.category}
                    />
                  ) : (
                    <div>
                      <div
                        style={
                          fixesSuggestions?.prodFixes?.newCategory &&
                          fixesSuggestions?.prodFixes?.newCategory !==
                            product.categoryId
                            ? { backgroundColor: "red" }
                            : null
                        }
                        onClick={
                          fixesSuggestions?.prodFixes?.newCategory &&
                          fixesSuggestions?.prodFixes?.newCategory !==
                            product.categoryId
                            ? () =>
                                scroll(
                                  "ai-suggestion-category",
                                  "ai-suggestion-ref"
                                )
                            : null
                        }
                        id="ai-suggestion-category"
                      >
                        <AsyncSelect2
                          label="Categoria Produto"
                          value={product.categoryId}
                          valueName={product.category}
                          onChange={(option) => {
                            setRefDescCategory(option?.id);
                            setRestrictedTags(option?.id);
                            dispatch({
                              type: "UPDATE_CATEGORY",
                              categoryComplete: {
                                categoryId: option ? option.id : null,
                                category: option ? option.name : null,
                                unitOfMeasurement: option ? option.um : null,
                                median: option ? option.median : null,
                                nielsen: option ? option.nielsen : null,
                                reportId: option ? option.reportId : null,
                                tagCes: option ? option.tagCes : null,
                                tagSeason: option ? option.tagSeason : null,
                                tagPackage: option ? option.tagPackage : null,
                                tagEspecificity: option
                                  ? option.tagEspecificity
                                  : null,
                                tagLine: option ? option.tagLine : null,
                                tagLinearMeasure: option
                                  ? option.tagLinearMeasure
                                  : null,
                              },
                            });
                            dispatch({
                              type: "UPDATE_TAGS",
                              base: product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "BASE")?.id
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .find((t) => t.type === "BASE")?.id,
                                    name: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .find((t) => t.type === "BASE")
                                      ?.description,
                                  }
                                : { content: option?.name, name: option?.name },
                              flavor:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "SABOR")?.id &&
                                option?.tagCes === "SABOR"
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter((t) => t.type === "SABOR")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              color:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "COR")?.id &&
                                option?.tagCes === "COR"
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter((t) => t.type === "COR")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              essence:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "ESSENCIA")?.id &&
                                option?.tagCes === "ESSENCIA"
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter((t) => t.type === "ESSENCIA")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              package:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "EMBALAGEM")?.id &&
                                option?.tagPackage
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter((t) => t.type === "EMBALAGEM")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              especificity:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "ESPECIFICIDADE")
                                  ?.id && option?.tagEspecificity
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter(
                                          (t) => t.type === "ESPECIFICIDADE"
                                        )
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              linearMeasure:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "METRAGEM")?.id &&
                                option?.tagLinearMeasure
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .filter((t) => t.type === "METRAGEM")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              season:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "SAZONAL")?.id &&
                                option?.tagSeason
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .find((t) => t.type === "SAZONAL")
                                        ?.map((i) => i.id),
                                    }
                                  : { content: [] },
                              line:
                                product.tags
                                  .map((j) => tags.find((t) => t.id === j))
                                  .find((t) => t.type === "LINHA")?.id &&
                                option?.tagLine
                                  ? {
                                      content: product.tags
                                        .map((j) =>
                                          tags.find((t) => t.id === j)
                                        )
                                        .find((t) => t.type === "LINHA")?.name,
                                    }
                                  : { content: null },
                            });
                          }}
                          onFocus={() =>
                            scroll("category-title", "category-ref-title")
                          }
                          fetchData={
                            locks.categoryId
                              ? fetchNcmCategories
                              : fetchProductCategories
                          }
                        />
                      </div>
                      <div className="h-pmo-input-lock-wrapper">
                        <div className="h-input-observation">
                          Categoria Produto Original:{" "}
                          {originalProduct.category || "Nenhuma"}
                        </div>
                        {locks.categoryId ? (
                          <div
                            className="h-input-observation h-pmo-input-lock"
                            onClick={() =>
                              dispatch({
                                type: "UPDATE_LOCKS",
                                categoryId: false,
                              })
                            }
                          >
                            <i className="fa fa-lock" /> Exibindo categorias
                            produto vinculadas ao ncm
                          </div>
                        ) : (
                          <div
                            className="h-input-observation h-pmo-input-lock h-pmo-input-lock--unlocked"
                            onClick={() =>
                              dispatch({
                                type: "UPDATE_LOCKS",
                                categoryId: true,
                              })
                            }
                          >
                            <i className="fa fa-unlock" /> Exibindo todas as
                            categorias produto
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="h-spacing" />

                  <div className="h-separator" id="brand-title">
                    Marca
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <p className="h-section-explanation">
                    Selecione a marca do produto
                  </p>
                  <label className="h-checkbox">
                    <input
                      type="checkbox"
                      checked={isOwnBrand}
                      onChange={setIsOwnBrand}
                      disabled={
                        !isEmptyObj(fixesSuggestions) &&
                        product.status === "LIBERADO" &&
                        isEmptyObj(fixesSuggestions.brandComplete)
                      }
                    />
                    É marca própria
                  </label>
                  <div style={{ height: "10px" }} />
                  {!isOwnBrand ? null : (
                    <div className="h-row">
                      <div className="h-col h-col-2">
                        <Input
                          list="Redes"
                          label="Rede"
                          placeholder="Rede"
                          value={brandComplete.economicGroup}
                          onChange={(e) =>
                            dispatch({
                              type: "UPDATE_BRAND_COMPLETE",
                              brandComplete: { economicGroup: e.target.value },
                            })
                          }
                          onFocus={fetchEconomicGroups}
                          readOnly={
                            !isEmptyObj(fixesSuggestions) &&
                            product.status === "LIBERADO" &&
                            isEmptyObj(fixesSuggestions.brandComplete)
                          }
                        />
                        <datalist id="Redes">
                          {(economicGroups || []).map((e) => (
                            <option key={e}>{e}</option>
                          ))}
                        </datalist>
                      </div>
                    </div>
                  )}
                  <div className="h-row">
                    <div className="h-col h-col-1">
                      <span>
                        {brandComplete?.image ? (
                          <img
                            className="h-brand-image"
                            src={mediaPrefix + brandComplete.image}
                            alt="/static/placeholder.png"
                          />
                        ) : (
                          <img
                            className="h-brand-image"
                            src="/static/placeholder.png"
                            alt="/static/placeholder.png"
                          />
                        )}
                      </span>
                    </div>
                    <div className="h-col h-col-8">
                      {!isEmptyObj(fixesSuggestions) &&
                      product.status === "LIBERADO" &&
                      isEmptyObj(fixesSuggestions.brandComplete) ? (
                        <Input label="Marca" value={product.brand} readOnly />
                      ) : (
                        <div>
                          <div
                            style={
                              fixesSuggestions?.prodFixes?.newBrand &&
                              fixesSuggestions?.prodFixes?.newBrand !==
                                product.brandId
                                ? { backgroundColor: "red" }
                                : null
                            }
                            onClick={
                              fixesSuggestions?.prodFixes?.newBrand &&
                              fixesSuggestions?.prodFixes?.newBrand !==
                                product.brandId
                                ? () =>
                                    scroll(
                                      "ai-suggestion-brand",
                                      "ai-suggestion-ref"
                                    )
                                : null
                            }
                            id="ai-suggestion-brand"
                          >
                            <AsyncSelect2
                              onChange={(option) => {
                                setRefBrandCategory(option?.id);
                                setBrandComplete(option);
                              }}
                              value={product.brandId}
                              valueName={product.brand}
                              label="Marca"
                              fetchData={fetchBrands}
                              onFocus={() =>
                                scroll("brand-title", "brand-ref-title")
                              }
                            />
                          </div>
                          <div className="h-pmo-input-lock-wrapper">
                            <div className="h-input-observation">
                              Marca original:{" "}
                              {originalProduct.brand || "Nenhuma"}
                            </div>
                            {locks.brand ? (
                              <div
                                className="h-input-observation h-pmo-input-lock"
                                onClick={() =>
                                  dispatch({
                                    type: "UPDATE_LOCKS",
                                    brand: false,
                                  })
                                }
                              >
                                <i className="fa fa-lock" /> Exibindo marcas
                                vinculadas ao EAN
                              </div>
                            ) : (
                              <div
                                className="h-input-observation h-pmo-input-lock h-pmo-input-lock--unlocked"
                                onClick={() =>
                                  dispatch({
                                    type: "UPDATE_LOCKS",
                                    brand: true,
                                  })
                                }
                              >
                                <i className="fa fa-unlock" /> Exibindo todas as
                                marcas
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <br />
                      <div
                        id="ai-suggestion-manufacturer"
                        onClick={
                          fixesSuggestions?.prodFixes?.newQuantityPack &&
                          fixesSuggestions?.prodFixes?.newQuantityPack !==
                            product.quantityPerPack
                            ? () =>
                                scroll(
                                  "ai-suggestion-manufacturer",
                                  "ai-suggestion-ref"
                                )
                            : null
                        }
                      >
                        <p>
                          <span
                            style={
                              fixesSuggestions?.prodFixes?.newManufacturer &&
                              fixesSuggestions?.prodFixes?.newManufacturer !==
                                product.manufacturerId
                                ? { backgroundColor: "red" }
                                : null
                            }
                          >
                            Fabricante:
                          </span>
                          &nbsp;
                          {brandComplete?.manufacturer
                            ? brandComplete.manufacturer
                            : "Sem Fabricante"}
                        </p>
                      </div>
                      {modLevel === "COMMON" ||
                      (!isEmptyObj(fixesSuggestions) &&
                        product.status === "LIBERADO" &&
                        isEmptyObj(fixesSuggestions.brandComplete)) ? null : (
                        <div>
                          <Button onClick={handleNewBrandOnClick}>
                            Editar/Criar Marca
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="h-spacing" />

                  <div className="h-separator" id="quantity-title">
                    Quantidade
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <p className="h-section-explanation">
                    Informações sobre medições e quantidades do produto.
                    <br />
                    Caso o EAN seja de um pack de produtos, deve-se marcar o
                    campo 'O produto é pack' e preencher com a quantidade de
                    itens no pack.
                    <br />A quantidade unitária é apenas para valores de um
                    item, mesmo que o EAN seja de um pack.
                  </p>
                  <label className="h-checkbox">
                    <input
                      type="checkbox"
                      checked={isPack}
                      onChange={setIsPack}
                      onFocus={() =>
                        scroll("quantity-title", "quantity-ref-title")
                      }
                      disabled={
                        !isEmptyObj(fixesSuggestions) &&
                        product.status === "LIBERADO" &&
                        (fixesSuggestions.newQuantity ||
                          fixesSuggestions.newQuantityPack)
                      }
                    />
                    O produto é pack
                  </label>
                  <div style={{ height: "10px" }} />
                  {!isPack ? null : (
                    <div className="h-row">
                      <div className="h-col h-col-2">
                        <div
                          style={
                            fixesSuggestions?.prodFixes?.newQuantityPack &&
                            fixesSuggestions?.prodFixes?.newQuantityPack !==
                              product.quantityPerPack
                              ? { backgroundColor: "red" }
                              : null
                          }
                          onClick={
                            fixesSuggestions?.prodFixes?.newQuantityPack &&
                            fixesSuggestions?.prodFixes?.newQuantityPack !==
                              product.quantityPerPack
                              ? () =>
                                  scroll(
                                    "ai-suggestion-quantity-pack",
                                    "ai-suggestion-ref"
                                  )
                              : null
                          }
                          id="ai-suggestion-quantity-pack"
                        >
                          <Input
                            label="Quantidade de unidades no Pack"
                            value={unit.quantity}
                            onChange={(e) => setPackQuantity(e.target.value)}
                            onFocus={() =>
                              scroll("quantity-title", "quantity-ref-title")
                            }
                            readOnly={
                              !isEmptyObj(fixesSuggestions) &&
                              product.status === "LIBERADO" &&
                              (fixesSuggestions.newQuantity ||
                                fixesSuggestions.newQuantityPack)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="h-row">
                    <div className="h-col h-col-2">
                      <div
                        style={
                          fixesSuggestions?.prodFixes?.newQuantity &&
                          fixesSuggestions?.prodFixes?.newQuantity.toFixed(
                            6
                          ) !== product.quantity?.toString()
                            ? { backgroundColor: "red" }
                            : null
                        }
                        onClick={
                          fixesSuggestions?.prodFixes?.newQuantity &&
                          fixesSuggestions?.prodFixes?.newQuantity.toFixed(
                            6
                          ) !== product.quantity?.toString()
                            ? () =>
                                scroll(
                                  "ai-suggestion-quantity",
                                  "ai-suggestion-ref"
                                )
                            : null
                        }
                        id="ai-suggestion-quantity"
                      >
                        <Input
                          placeholder="Quantidade unitária"
                          label="Quantidade unitária"
                          value={unit.value}
                          onChange={(e) =>
                            dispatch({
                              type: "UPDATE_UNIT",
                              unit: { value: e.target.value },
                            })
                          }
                          onFocus={() =>
                            scroll("quantity-title", "quantity-ref-title")
                          }
                          readOnly={
                            !isEmptyObj(fixesSuggestions) &&
                            product.status === "LIBERADO" &&
                            (fixesSuggestions.newQuantity ||
                              fixesSuggestions.newQuantityPack)
                          }
                        />
                      </div>
                      <div className="h-ignore-input-spacing" />
                      <div className="h-input-observation">
                        {isNaN(
                          product?.median / categoryComplete?.median
                        ) ? null : (
                          <span>
                            Quantidade sugerida:
                            {categoryComplete.unitOfMeasurement === "Und"
                              ? (
                                  product?.median / categoryComplete?.median
                                ).toFixed(0) +
                                " " +
                                categoryComplete.unitOfMeasurement
                              : (
                                  product?.median / categoryComplete?.median
                                ).toFixed(6) +
                                " " +
                                categoryComplete.unitOfMeasurement}
                          </span>
                        )}
                      </div>
                    </div>
                    {!isEmptyObj(fixesSuggestions) &&
                    product.status === "LIBERADO" &&
                    (fixesSuggestions.newQuantity ||
                      fixesSuggestions.newQuantityPack) ? (
                      <Input
                        label="Unidade de Medida"
                        value={unit.unit}
                        readOnly
                      />
                    ) : (
                      <div className="h-col h-col-2">
                        <div
                          style={
                            fixesSuggestions?.prodFixes?.newUnit &&
                            fixesSuggestions?.prodFixes?.newUnit !==
                              product.unitOfMeasurement
                              ? { backgroundColor: "red" }
                              : null
                          }
                          onClick={
                            fixesSuggestions?.prodFixes?.newUnit &&
                            fixesSuggestions?.prodFixes?.newUnit !==
                              product.unitOfMeasurement
                              ? () =>
                                  scroll(
                                    "ai-suggestion-unit",
                                    "ai-suggestion-ref"
                                  )
                              : null
                          }
                          id="ai-suggestion-unit"
                        >
                          <GroupedSelect
                            options={
                              !categoryComplete?.unitOfMeasurement
                                ? unities
                                : unities.filter(
                                    (i) =>
                                      i.style ===
                                      categoryComplete.unitOfMeasurement
                                  )
                            }
                            label="Unidade de Medida"
                            value={unit.unit}
                            onChange={(e) =>
                              dispatch({
                                type: "UPDATE_UNIT",
                                unit: { unit: e.target.value },
                              })
                            }
                            onFocus={() =>
                              scroll("quantity-title", "quantity-ref-title")
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <Collapse
                    collapsed={
                      !isEmptyObj(fixesSuggestions) &&
                      product.status === "LIBERADO"
                    }
                  >
                    <div className="h-spacing" />
                    <div className="h-separator">Tags</div>
                    <div>
                      {productTags.flavor.content?.length === 0 ? null : (
                        <span>
                          {productTags.flavor.content.map((j, index) => (
                            <span className="h-tag" key={index}>
                              <strong>Sabor: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.flavor.content.splice(index, 1);
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    flavor: {
                                      content: productTags.flavor.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.essence.content?.length === 0 ? null : (
                        <span>
                          {productTags.essence.content.map((j, index) => (
                            <span className="h-tag" key={index}>
                              <strong>Essência: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.essence.content.splice(index, 1);
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    essence: {
                                      content: productTags.essence.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.color.content?.length === 0 ? null : (
                        <span>
                          {productTags.color.content.map((j, index) => (
                            <span className="h-tag" key={j}>
                              <strong>Cor: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.color.content.splice(index, 1);
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    color: {
                                      content: productTags.color.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.package.content?.length === 0 ? null : (
                        <span>
                          {productTags.package.content.map((j, index) => (
                            <span className="h-tag" key={j}>
                              <strong>Embalagem: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.package.content.splice(index, 1);
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    package: {
                                      content: productTags.package.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.especificity.content?.length === 0 ? null : (
                        <span>
                          {productTags.especificity.content.map((j, index) => (
                            <span className="h-tag" key={j}>
                              <strong>Especificidade: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.especificity.content.splice(
                                    index,
                                    1
                                  );
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    especificity: {
                                      content: productTags.especificity.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.season.content?.length === 0 ? null : (
                        <span>
                          {productTags.season.content.map((j, index) => (
                            <span className="h-tag" key={j}>
                              <strong>Sazonal: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.season.content.splice(index, 1);
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    season: {
                                      content: productTags.season.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {productTags.linearMeasure.content?.length ===
                      0 ? null : (
                        <span>
                          {productTags.linearMeasure.content.map((j, index) => (
                            <span className="h-tag" key={j}>
                              <strong>Metragem: </strong>
                              {
                                tags.find(
                                  (t) => t.id.toString() === j.toString()
                                )?.name
                              }
                              <Button
                                type="button"
                                layout="error"
                                small
                                onClick={() => {
                                  productTags.linearMeasure.content.splice(
                                    index,
                                    1
                                  );
                                  dispatch({
                                    type: "UPDATE_TAGS",
                                    linearMeasure: {
                                      content:
                                        productTags.linearMeasure.content,
                                    },
                                  });
                                }}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </span>
                          ))}
                        </span>
                      )}
                      {!productTags.line.content ||
                      productTags.line.content.length === 0 ? null : (
                        <span className="h-tag">
                          <strong>Linha: </strong>
                          {productTags.line.content}
                          <Button
                            type="button"
                            layout="error"
                            small
                            onClick={() =>
                              dispatch({
                                type: "UPDATE_TAGS",
                                line: { content: null },
                              })
                            }
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </span>
                      )}
                    </div>
                    <div className="h-row" width="10%">
                      <div className="h-separator">Adicionar tags</div>
                    </div>
                    <div className="h-row">
                      <div className="h-col h-col-1">
                        {(function () {
                          let typeList = [{ id: null, name: "Selecione..." }];
                          if (categoryComplete?.tagCes === "SABOR")
                            typeList.push({ id: "SABOR", name: "SABOR" });
                          if (categoryComplete?.tagCes === "ESSENCIA")
                            typeList.push({ id: "ESSENCIA", name: "ESSENCIA" });
                          if (categoryComplete?.tagCes === "COR")
                            typeList.push({ id: "COR", name: "COR" });
                          if (categoryComplete?.tagPackage)
                            typeList.push({
                              id: "EMBALAGEM",
                              name: "EMBALAGEM",
                            });
                          if (categoryComplete?.tagEspecificity)
                            typeList.push({
                              id: "ESPECIFICIDADE",
                              name: "ESPECIFICIDADE",
                            });
                          if (categoryComplete?.tagSeason)
                            typeList.push({ id: "SAZONAL", name: "SAZONAL" });
                          if (categoryComplete?.tagLinearMeasure)
                            typeList.push({ id: "METRAGEM", name: "METRAGEM" });
                          if (categoryComplete?.tagLine)
                            typeList.push({ id: "LINHA", name: "LINHA" });

                          return (
                            <Input
                              label="Tipo"
                              value={tagTypeChosen}
                              placeholder="Selecione..."
                              onChange={(e) => {
                                dispatch({
                                  type: "TAG_TYPE_CHOSEN",
                                  tagTypeChosen: e.target.value,
                                });
                              }}
                              options={typeList}
                            />
                          );
                        })()}
                      </div>
                      {!(tagTypeChosen === "SABOR") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Sabor"
                            value={productTags.flavor.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                flavor: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter((i) => i.type === "SABOR")
                                .length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "SABOR" &&
                                      !productTags.flavor.content.includes(i.id)
                                  )
                                : tags.filter(
                                    (i) =>
                                      i.type === "SABOR" &&
                                      !productTags.flavor.content.includes(i.id)
                                  )
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "COR") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Cor"
                            value={productTags.color.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                color: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter((i) => i.type === "COR")
                                .length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "COR" &&
                                      !productTags.color.content.includes(i.id)
                                  )
                                : tags.filter((i) => i.type === "COR")
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "ESSENCE") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Essência"
                            value={productTags.essence.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                essence: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter(
                                (i) => i.type === "ESSENCIA"
                              ).length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "ESSENCIA" &&
                                      !productTags.essence.content.includes(
                                        i.id
                                      )
                                  )
                                : tags.filter((i) => i.type === "ESSENCIA")
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "EMBALAGEM") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Embalagem"
                            value={productTags.package.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                package: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter(
                                (i) => i.type === "EMBALAGEM"
                              ).length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "EMBALAGEM" &&
                                      !productTags.package.content.includes(
                                        i.id
                                      )
                                  )
                                : tags.filter((i) => i.type === "EMBALAGEM")
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "ESPECIFICIDADE") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Especificidade"
                            value={productTags.especificity.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                especificity: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter(
                                (i) => i.type === "ESPECIFICIDADE"
                              ).length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "ESPECIFICIDADE" &&
                                      !productTags.especificity.content.includes(
                                        i.id
                                      )
                                  )
                                : tags.filter(
                                    (i) => i.type === "ESPECIFICIDADE"
                                  )
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "SAZONAL") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Sazonalidade"
                            value={productTags.season.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                season: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter((i) => i.type === "SAZONAL")
                                .length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "SAZONAL" &&
                                      !productTags.season.content.includes(i.id)
                                  )
                                : tags.filter((i) => i.type === "SAZONAL")
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "METRAGEM") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            label="Metragem"
                            value={productTags.linearMeasure.content}
                            placeholder="Selecione..."
                            onChange={(content) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                linearMeasure: { content },
                              });
                            }}
                            options={
                              restrictedTags &&
                              restrictedTags.filter(
                                (i) => i.type === "METRAGEM"
                              ).length > 0
                                ? restrictedTags.filter(
                                    (i) =>
                                      i.type === "METRAGEM" &&
                                      !productTags.linearMeasure.content.includes(
                                        i.id
                                      )
                                  )
                                : tags.filter((i) => i.type === "METRAGEM")
                            }
                            multiple
                          />
                        </div>
                      )}
                      {!(tagTypeChosen === "LINHA") ? null : (
                        <div className="h-col h-col-1">
                          <Input
                            list="Linhas"
                            label="Linha"
                            value={productTags.line.content}
                            placeholder="Linha"
                            onChange={(e) => {
                              dispatch({
                                type: "UPDATE_TAGS",
                                line: { content: e.target.value },
                              });
                            }}
                          />
                          <datalist id="Linhas">
                            {restrictedTags &&
                            restrictedTags.filter((i) => i.type === "LINHA")
                              .length > 0
                              ? restrictedTags
                                  .filter((i) => i.type === "LINHA")
                                  .map((e) => (
                                    <option key={e.id} value={e.name}>
                                      {e.name}
                                    </option>
                                  ))
                              : tags
                                  .filter((i) => i.type === "LINHA")
                                  .map((e) => (
                                    <option key={e.id} value={e.name}>
                                      {e.name}
                                    </option>
                                  ))}
                          </datalist>
                        </div>
                      )}
                    </div>
                  </Collapse>

                  <div className="h-separator">Descrição</div>
                  <p>
                    Descrição original:{" "}
                    {originalProduct.description || "Nenhuma"}
                  </p>
                  <div className="h-row">
                    <div className="h-col h-col-6">
                      <div
                        style={
                          fixesSuggestions?.prodFixes?.newDescription &&
                          fixesSuggestions?.prodFixes?.newDescription !==
                            product.description
                            ? { backgroundColor: "red" }
                            : null
                        }
                        onClick={
                          fixesSuggestions?.prodFixes?.newDescription &&
                          fixesSuggestions?.prodFixes?.newDescription !==
                            product.description
                            ? () =>
                                scroll(
                                  "ai-suggestion-description",
                                  "ai-suggestion-ref"
                                )
                            : null
                        }
                        id="ai-suggestion-description"
                      >
                        <Input
                          label={
                            "Sugestão: " +
                            (!productTags.pack.content
                              ? ""
                              : productTags.pack.content + " ") +
                            (!productTags.base.name
                              ? ""
                              : productTags.base.name + " ") +
                            (productTags.flavor.content.length === 0
                              ? ""
                              : productTags.flavor.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.essence.content.length === 0
                              ? ""
                              : productTags.essence.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.color.content.length === 0
                              ? ""
                              : productTags.color.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.package.content.length === 0
                              ? ""
                              : productTags.package.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.especificity.content.length === 0
                              ? ""
                              : productTags.especificity.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.season.content.length === 0
                              ? ""
                              : productTags.season.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (productTags.linearMeasure.content?.length === 0
                              ? ""
                              : productTags.linearMeasure.content.map(
                                  (j, index) =>
                                    tags.find(
                                      (t) => t.id.toString() === j.toString()
                                    )?.name + " "
                                )) +
                            (!productTags.line.content ||
                            productTags.line.content.length === 0
                              ? ""
                              : productTags.line.content + " ") +
                            (!product.brand ? "" : product.brand + " ") +
                            (unit.value > 0 ? unit.value : "") +
                            (unit.unit ? unit.unit : "")
                          }
                          value={product.description}
                          onChange={(e) =>
                            setProductDescription(e.target.value)
                          }
                          onFocus={() =>
                            scroll(
                              "identification-title",
                              "identification-ref-title"
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="h-col h-col-1">
                      <span>
                        <button
                          style={{
                            marginLeft: "10px",
                            marginTop: "18px",
                            height: "30px",
                            cursor: "pointer",
                            backgroundColor: mainColor,
                            color: "white",
                          }}
                          onClick={setDescriptionWithTags}
                        >
                          Atualizar com tags
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <div style={{ height: "10px" }} />
                  <div className="h-row">
                    {!isPack ? null : (
                      <div
                        style={{ marginRight: "5px" }}
                        className={
                          !descriptionTags || !descriptionTags.pack[0].match
                            ? "h-tag-unconfirm"
                            : descriptionTags.pack[0].inPosition
                            ? "h-tag-confirm"
                            : "h-tag-partial"
                        }
                      >
                        <Input
                          label="Pack"
                          value={productTags.pack.content}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_TAGS",
                              pack: { content: e.target.value },
                            });
                          }}
                          options={packTagOptions}
                        />
                      </div>
                    )}
                    <div
                      style={{ marginRight: "5px" }}
                      className={
                        !descriptionTags || !descriptionTags.base[0].match
                          ? "h-tag-unconfirm"
                          : descriptionTags.base[0].inPosition
                          ? "h-tag-confirm"
                          : "h-tag-partial"
                      }
                    >
                      {(restrictedTags || []).filter((i) => i.type === "BASE")
                        .length > 0 ? (
                        <Input
                          label="Base"
                          value={productTags.base.content}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_TAGS",
                              base: {
                                content: e.target.value,
                                name: e.target.options[e.target.selectedIndex]
                                  .text,
                              },
                            });
                          }}
                          options={(restrictedTags || []).filter(
                            (i) => i.type === "BASE"
                          )}
                        />
                      ) : (
                        <Input
                          label="Base"
                          value={productTags.base.content}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_TAGS",
                              base: {
                                content: e.target.value,
                                name: e.target.value,
                              },
                            });
                          }}
                          options={[
                            { id: product.category, name: product.category },
                          ]}
                        />
                      )}
                    </div>
                    {productTags.flavor.content?.length === 0 ? null : (
                      <span>
                        {productTags.flavor.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.flavor[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.flavor[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.essence.content?.length === 0 ? null : (
                      <span>
                        {productTags.essence.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.essence[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.essence[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.color.content?.length === 0 ? null : (
                      <span>
                        {productTags.color.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.color[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.color[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.package.content?.length === 0 ? null : (
                      <span>
                        {productTags.package.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.package[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.package[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.especificity.content?.length === 0 ? null : (
                      <span>
                        {productTags.especificity.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.especificity[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.especificity[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.season.content?.length === 0 ? null : (
                      <span>
                        {productTags.season.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.season[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.season[index].inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {productTags.linearMeasure.content?.length === 0 ? null : (
                      <span>
                        {productTags.linearMeasure.content.map((j, index) => (
                          <span
                            className={`h-tag ${
                              !descriptionTags ||
                              !descriptionTags.linearMeasure[index].match
                                ? "h-tag-unconfirm"
                                : descriptionTags.linearMeasure[index]
                                    .inPosition
                                ? "h-tag-confirm"
                                : "h-tag-partial"
                            }`}
                            key={index}
                          >
                            {
                              tags.find((t) => t.id.toString() === j.toString())
                                ?.name
                            }
                          </span>
                        ))}
                      </span>
                    )}
                    {!productTags.line.content ||
                    productTags.line.content.length === 0 ? null : (
                      <span
                        className={`h-tag ${
                          !descriptionTags || !descriptionTags.line[0].match
                            ? "h-tag-unconfirm"
                            : descriptionTags.line[0].inPosition
                            ? "h-tag-confirm"
                            : "h-tag-partial"
                        }`}
                      >
                        {productTags.line.content}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="h-col h-col-3 h-pmo-references" ref={rightColumn}>
                <div className="h-separator" id="image-ref-title">
                  Imagens
                </div>
                <p>
                  A imagem deve ter tamanho máximo de 120kb; Estar com o produto
                  centralizado; Sem fundo ou com fundo branco; Não possuir
                  marcas d água ou qualquer outro tipo de texto
                </p>
                {(function () {
                  const current = search.types.ean;

                  if (!current || current.err) {
                    return <span>Erro ao fazer pesquisa google</span>;
                  }

                  if (current.loading) {
                    return <span>Carregando...</span>;
                  }

                  return (
                    <div className="h-pmo-images">
                      {current.results
                        .filter((i) => i.image)
                        .map((item, index) => (
                          <ImageItem key={index} src={item.image} />
                        ))}
                      <div style={{ clear: "both" }} />
                    </div>
                  );
                })()}
                <div className="h-separator" id="identification-ref-title">
                  Referências Comerciais
                </div>
                {(function () {
                  if (
                    !comercialReferences ||
                    comercialReferences.length === 0
                  ) {
                    return (
                      <p>A busca pelo EAN informado não retornou resultados.</p>
                    );
                  }

                  return (
                    <>
                      {comercialReferences.map((ref, index) => (
                        <div key={index}>
                          <span>{ref.description}</span>
                          <span>
                            <button
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                backgroundColor: mainColor,
                                color: "white",
                              }}
                              onClick={() =>
                                setProductDescription(ref.description)
                              }
                            >
                              Atribuir
                            </button>
                          </span>
                          <br />
                          <br />
                        </div>
                      ))}
                    </>
                  );
                })()}
                <div className="h-separator">Pesquisa Google</div>
                <div className="h-form-label">Exibir resultados por:</div>
                <div className="h-pmo-search-options">
                  <label>
                    <input
                      type="checkbox"
                      checked={search.currentType === "ean"}
                      onChange={() => setSearchType("ean")}
                    />
                    Ean
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      checked={search.currentType === "description"}
                      onChange={() => setSearchType("description")}
                    />
                    Descrição
                  </label>
                </div>
                {(function () {
                  const current = search.types[search.currentType];

                  if (!current || current.err) {
                    return <span>Erro ao fazer pesquisa google</span>;
                  }

                  if (current.loading) {
                    return <span>Carregando...</span>;
                  }

                  if (current.results.length === 0) {
                    return (
                      <p>A busca pelo EAN informado não retornou resultados.</p>
                    );
                  }

                  return (
                    <>
                      {current.results.map((item, i) => (
                        <GoogleSearchItem key={i} item={item} />
                      ))}
                      <a
                        className="h-link"
                        href={searchTypes[search.currentType].googleLink(
                          product
                        )}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          fontSize: ".8em",
                          textDecoration: "none",
                          display: "block",
                          marginTop: "-.5em",
                        }}
                      >
                        Ver mais resultados...
                      </a>
                    </>
                  );
                })()}
                {!state.ncm && !refDescCategory?.length > 0 ? null : (
                  <>
                    <div style={{ height: "10px" }} />
                    <div className="h-separator" id="category-ref-title">
                      Referências de Categoria:
                    </div>
                    {!product.ncmDescription ? null : (
                      <p>NCM: {product.ncmDescription}</p>
                    )}
                    {refDescCategory?.length > 0 ? (
                      <>
                        <p>produtos da categoria:</p>
                        {refDescCategory.map((r, i) => (
                          <ul key={i}>{r}</ul>
                        ))}
                      </>
                    ) : null}
                  </>
                )}
                {!refBrandCategory?.length > 0 ? null : (
                  <>
                    <div style={{ height: "10px" }} />
                    <div className="h-separator" id="brand-ref-title">
                      Referências de Marca:
                    </div>
                    <p>Categorias da marca:</p>
                    {refBrandCategory.map((r, i) => (
                      <ul key={i}>
                        {r.category} - qtd: {r.total}{" "}
                      </ul>
                    ))}
                  </>
                )}
                <div className="h-separator" id="quantity-ref-title">
                  Referências de Quantidade
                </div>
                {!categoryComplete?.median ? null : (
                  <p>Mediana por unidade: R${categoryComplete.median}</p>
                )}
                {!product?.median || isNaN(product?.median) ? null : (
                  <p>Preço do produto: R${product?.median}</p>
                )}
                {!categoryComplete?.unitOfMeasurement ? null : (
                  <p>
                    <span>
                      Unidade de medida: {categoryComplete.unitOfMeasurement}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "UPDATE_UNIT",
                            unit: { unit: categoryComplete.unitOfMeasurement },
                          })
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {(function () {
                  if (
                    categoryComplete?.median <= 0 ||
                    product?.median <= 0 ||
                    isNaN(product?.median) ||
                    isNaN(categoryComplete?.median)
                  ) {
                    return null;
                  }

                  if (categoryComplete.unitOfMeasurement === "Und") {
                    return (
                      <div>
                        <p>Sugestões produto unitário:</p>
                        <span style={{ marginLeft: "10px" }}>
                          Quantidade unitária sugerida:{" "}
                          {(product.median / categoryComplete.median).toFixed(
                            0
                          )}
                        </span>
                        <span>
                          <button
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              backgroundColor: mainColor,
                              color: "white",
                            }}
                            onClick={() =>
                              dispatch({
                                type: "UPDATE_UNIT",
                                unit: {
                                  value: (
                                    product.median / categoryComplete.median
                                  ).toFixed(0),
                                },
                              })
                            }
                          >
                            Atribuir
                          </button>
                        </span>
                      </div>
                    );
                  }

                  return (
                    <div>
                      <p>Sugestões produto unitário:</p>
                      <span style={{ marginLeft: "10px" }}>
                        Quantidade sugerida:
                        {(product.median / categoryComplete.median).toFixed(6)}
                      </span>
                      <span>
                        <button
                          style={{
                            marginLeft: "10px",
                            cursor: "pointer",
                            backgroundColor: mainColor,
                            color: "white",
                          }}
                          onClick={() =>
                            dispatch({
                              type: "UPDATE_UNIT",
                              unit: {
                                value: (
                                  product.median / categoryComplete.median
                                ).toFixed(6),
                              },
                            })
                          }
                        >
                          Atribuir
                        </button>
                      </span>
                    </div>
                  );
                })()}
                {product.ean && product.ean.length === 14 ? (
                  <p>De acordo com o EAN, o produto deveria ser um pack</p>
                ) : null}
                {!suggestedPack.quantity && !suggestedPack.pack ? null : (
                  <div>
                    <p>Sugestões pack:</p>
                    <span style={{ marginLeft: "10px" }}>
                      Quantidade unitária: {suggestedPack.quantity}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "UPDATE_UNIT",
                            unit: { value: suggestedPack.quantity },
                          })
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                    <br />
                    {suggestedPack.pack > 100 ? (
                      <span style={{ marginLeft: "10px", color: "red" }}>
                        Itens no pack: {suggestedPack.pack}
                      </span>
                    ) : (
                      <div>
                        <span style={{ marginLeft: "10px" }}>
                          Itens no pack: {suggestedPack.pack}
                        </span>
                        <span>
                          <button
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              backgroundColor: mainColor,
                              color: "white",
                            }}
                            onClick={() => setSuggestedPack(suggestedPack.pack)}
                          >
                            Atribuir
                          </button>
                        </span>
                      </div>
                    )}
                  </div>
                )}
                <div className="h-separator" id="ai-suggestion-ref">
                  Sugestões da IA
                </div>
                {!fixesSuggestions?.prodFixes?.newDescription ? null : (
                  <p>
                    <span>
                      Descrição: {fixesSuggestions?.prodFixes.newDescription}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          setProductDescription(
                            fixesSuggestions.prodFixes.newDescription
                          )
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {!fixesSuggestions?.categoryComplete ? null : (
                  <p>
                    <span>
                      Category: {fixesSuggestions?.categoryComplete.name}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() => {
                          setRefDescCategory(
                            fixesSuggestions.categoryComplete.id
                          );
                          setRestrictedTags(
                            fixesSuggestions.categoryComplete.id
                          );
                          dispatch({
                            type: "UPDATE_CATEGORY",
                            categoryComplete: {
                              categoryId: fixesSuggestions.categoryComplete.id,
                              category: fixesSuggestions.categoryComplete.name,
                              unitOfMeasurement:
                                fixesSuggestions.categoryComplete.um,
                              median: fixesSuggestions.categoryComplete.median,
                              nielsen:
                                fixesSuggestions.categoryComplete
                                  .nielsenCategory,
                              reportId:
                                fixesSuggestions.categoryComplete.reportId,
                              tagCes: fixesSuggestions.categoryComplete.tagCes,
                              tagSeason:
                                fixesSuggestions.categoryComplete.tagSeason,
                              tagPackage:
                                fixesSuggestions.categoryComplete.tagPackage,
                              tagEspecificity:
                                fixesSuggestions.categoryComplete
                                  .tagEspecificity,
                              tagLine:
                                fixesSuggestions.categoryComplete.tagLine,
                              tagLinearMeasure:
                                fixesSuggestions.categoryComplete
                                  .tagLinearMeasure,
                            },
                          });
                          dispatch({
                            type: "UPDATE_TAGS",
                            base: product.tags
                              .map((j) => tags.find((t) => t.id === j))
                              .find((t) => t.type === "BASE")?.id
                              ? {
                                  content: product.tags
                                    .map((j) => tags.find((t) => t.id === j))
                                    .find((t) => t.type === "BASE")?.id,
                                  name: product.tags
                                    .map((j) => tags.find((t) => t.id === j))
                                    .find((t) => t.type === "BASE")
                                    ?.description,
                                }
                              : {
                                  content:
                                    fixesSuggestions.categoryComplete.name,
                                  name: fixesSuggestions.categoryComplete.name,
                                },
                            flavor:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "SABOR")?.id &&
                              fixesSuggestions.categoryComplete?.tagCes ===
                                "SABOR"
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter((t) => t.type === "SABOR")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            color:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "COR")?.id &&
                              fixesSuggestions.categoryComplete?.tagCes ===
                                "COR"
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter((t) => t.type === "COR")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            essence:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "ESSENCIA")?.id &&
                              fixesSuggestions.categoryComplete?.tagCes ===
                                "ESSENCIA"
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter((t) => t.type === "ESSENCIA")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            package:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "EMBALAGEM")?.id &&
                              fixesSuggestions.categoryComplete?.tagPackage
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter((t) => t.type === "EMBALAGEM")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            especificity:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "ESPECIFICIDADE")?.id &&
                              fixesSuggestions.categoryComplete?.tagEspecificity
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter(
                                        (t) => t.type === "ESPECIFICIDADE"
                                      )
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            linearMeasure:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "METRAGEM")?.id &&
                              fixesSuggestions.categoryComplete
                                ?.tagLinearMeasure
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .filter((t) => t.type === "METRAGEM")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            season:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "SAZONAL")?.id &&
                              fixesSuggestions.categoryComplete?.tagSeason
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .find((t) => t.type === "SAZONAL")
                                      ?.map((i) => i.id),
                                  }
                                : { content: [] },
                            line:
                              product.tags
                                .map((j) => tags.find((t) => t.id === j))
                                .find((t) => t.type === "LINHA")?.id &&
                              fixesSuggestions.categoryComplete?.tagLine
                                ? {
                                    content: product.tags
                                      .map((j) => tags.find((t) => t.id === j))
                                      .find((t) => t.type === "LINHA")?.name,
                                  }
                                : { content: null },
                          });
                          dispatch({
                            type: "TAG_TYPE_CHOSEN",
                            tagTypeChosen: null,
                          });
                        }}
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {!fixesSuggestions?.brandComplete ? null : (
                  <p>
                    <span>Marca: {fixesSuggestions?.brandComplete.name}</span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() => {
                          setRefBrandCategory(
                            fixesSuggestions?.brandComplete.id
                          );
                          setBrandComplete(fixesSuggestions?.brandComplete);
                        }}
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {!fixesSuggestions?.prodFixes?.newManufacturer ||
                fixesSuggestions?.prodFixes?.newManufacturer ===
                  fixesSuggestions?.brandComplete?.manufacturerId ? null : (
                  <p>
                    Fabricante: {fixesSuggestions?.prodFixes?.newManufacturer}
                  </p>
                )}
                {!fixesSuggestions?.prodFixes?.newQuantityPack ? null : (
                  <p>
                    <span>
                      Quantidade no pack:{" "}
                      {fixesSuggestions?.prodFixes?.newQuantityPack}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          setSuggestedPack(
                            fixesSuggestions?.prodFixes?.newQuantityPack
                          )
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {!fixesSuggestions?.prodFixes?.newQuantity ? null : (
                  <p>
                    <span>
                      Quantidade unitária:{" "}
                      {fixesSuggestions?.prodFixes?.newQuantity}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "UPDATE_UNIT",
                            unit: {
                              value:
                                fixesSuggestions?.prodFixes?.newQuantity.toString(),
                            },
                          })
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
                {!fixesSuggestions?.prodFixes?.newUnit ? null : (
                  <p>
                    <span>
                      Unidade de medida: {fixesSuggestions?.prodFixes?.newUnit}
                    </span>
                    <span>
                      <button
                        style={{
                          marginLeft: "10px",
                          cursor: "pointer",
                          backgroundColor: mainColor,
                          color: "white",
                        }}
                        onClick={() =>
                          dispatch({
                            type: "UPDATE_UNIT",
                            unit: {
                              unit: fixesSuggestions?.prodFixes?.newUnit,
                            },
                          })
                        }
                      >
                        Atribuir
                      </button>
                    </span>
                  </p>
                )}
              </div>
            </div>
            <div className="h-row">
              <div className="h-col h-col-7">
                <div className="h-pmo-left-column">
                  <div style={{ height: "10px" }} />
                  <div className="h-separator" id="attention-title">
                    Ações
                  </div>
                  <div className="h-ignore-input-spacing" />
                  <p className="h-section-explanation">
                    Libere o produto caso os dados estejam completos ou pause
                    caso estejam faltando informações para concluir o cadastro.
                    <br />
                    Voltar irá fazer com que todos os dados sejam perdidos!
                  </p>
                  <Button
                    layout="info"
                    onClick={() =>
                      dispatch({
                        type: "SAVE_PRODUCT",
                        product,
                        unit,
                        relatedEans,
                        warnings,
                        status: "PAUSADO",
                        productTags,
                      })
                    }
                  >
                    Pausar
                  </Button>
                  &nbsp;
                  <Button
                    layout="confirm"
                    onClick={() =>
                      dispatch({
                        type: "SAVE_PRODUCT",
                        product,
                        unit,
                        relatedEans,
                        warnings,
                        status: "LIBERADO",
                        productTags,
                      })
                    }
                  >
                    Liberar
                  </Button>
                  &nbsp;
                  {modLevel !== "COMMON" ? null : (
                    <Button
                      layout="confirm"
                      onClick={() =>
                        dispatch({
                          type: "NEXT_PRODUCT",
                          product,
                          unit,
                          relatedEans,
                          warnings,
                          status: product.status,
                          productTags,
                          originalProduct,
                        })
                      }
                    >
                      Pular
                    </Button>
                  )}
                </div>
              </div>
              <div className="h-col h-col-3">
                <div style={{ height: "10px" }} />
                {(function () {
                  if (
                    warnings.quantity ||
                    warnings.unitOfMeasurement ||
                    warnings.category
                  ) {
                    return (
                      <>
                        <div className="h-separator" id="attention-ref-title">
                          Incoerências de cadastro
                        </div>
                        {Object.values(warnings).map((warning, i) => (
                          <p key={i} style={{ color: "red" }}>
                            {warning}
                          </p>
                        ))}
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </>
        )}
      </Card>
    </Template>
  );
}

export default ProductPage2;
