import { SectionTitle } from "../ui/SectionTitle";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../service/modal/useModal";
import Input from "../form/Input";
import TextArea from "../form/TextArea";
import { useState, useCallback, useEffect } from "react";
import { SetState } from "../../types";
import Button from "../form/Button";
import useQueryRequests, {
  QueryExecute
} from "../../service/report/useQueryRequests";
import { QUERY_API } from "../../../constants";

export function QueryApiPage() {

  const { openModal } = useModal();

  const initialState: State = {
    host: "",
    dbUser: "",
    dbPassword: "",
    charset: "",
    intoOutfile: "",
    fieldsTerminatedBy: "",
    enclosedBy: "",
    linesTerminatedBy: "",
    query: "",
    loading: false,
    userId: null
  }

  const [state, setState] = useState(() => {
    const savedHost = localStorage.getItem(QUERY_API.HOST);
    const savedDbUser = localStorage.getItem(QUERY_API.USER);
    const savedDbPassword = localStorage.getItem(QUERY_API.PASSWORD);
    return { ...initialState, host: savedHost ?? "", dbUser: savedDbUser ?? "", dbPassword: savedDbPassword ?? "" }
  });

  const [fieldsFilled, setFieldsFilled] = useState(false);

  const { host, dbUser, dbPassword, charset, intoOutfile, fieldsTerminatedBy, enclosedBy, linesTerminatedBy, query, loading } = state;

  const { queryExecute } = useQueryRequests();

  const executeQuery = useCallback(() => {

    localStorage.setItem(QUERY_API.HOST, host);
    localStorage.setItem(QUERY_API.USER, dbUser);
    localStorage.setItem(QUERY_API.PASSWORD, dbPassword);

    baseQueryExecute(queryExecute, host, dbUser, dbPassword, charset, intoOutfile, fieldsTerminatedBy, enclosedBy,
        linesTerminatedBy, query, setState, openModal);
  }, [queryExecute, host, dbUser, dbPassword, charset, intoOutfile, fieldsTerminatedBy, enclosedBy, linesTerminatedBy, query, setState, openModal]);

  const checkFieldsFilled = useCallback(() => {
    return host.trim() !== "" && dbUser.trim() !== "" && dbPassword.trim() !== "" && charset.trim() !== "" && intoOutfile.trim() !== ""
        && fieldsTerminatedBy.trim() !== "" && enclosedBy.trim() !== "" && linesTerminatedBy.trim() !== "" && query.trim() !== "";
  }, [host, dbUser, dbPassword, charset, intoOutfile, fieldsTerminatedBy, enclosedBy, linesTerminatedBy, query]);

  useEffect(() => {
    setFieldsFilled(checkFieldsFilled());
  }, [checkFieldsFilled]);

  return (
    <div>
      <SectionTitle title="Api de Consulta" description="Consultas pela QueryApi" />

      <div style={{ minWidth: "300px" }}>
        <Input
          label="Host"
          onChange={ e => setState(prev => ({ ...prev, host: e.target.value })) }
          placeholder="Host do banco de dados"
          value={ host }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
          label="Usuário BD"
          onChange={ e => setState(prev => ({ ...prev, dbUser: e.target.value })) }
          placeholder="Usuário do banco de dados"
          value={ dbUser }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
          label="Senha do usuário"
          onChange={ e => setState(prev => ({ ...prev, dbPassword: e.target.value })) }
          placeholder="Senha do usuário do banco de dados"
          value={ dbPassword }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
            label="Into Outfile"
            onChange={ e => setState(prev => ({ ...prev, intoOutfile: e.target.value })) }
            placeholder="Ex: example.csv"
            value={ intoOutfile }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
            label="Character Set"
            onChange={ e => setState(prev => ({ ...prev, charset: e.target.value })) }
            placeholder="Ex: utf8"
            value={ charset }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
            label="Fields Terminated By"
            onChange={ e => setState(prev => ({ ...prev, fieldsTerminatedBy: e.target.value })) }
            placeholder="Ex: ,"
            value={ fieldsTerminatedBy }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
            label="Enclosed By"
            onChange={ e => setState(prev => ({ ...prev, enclosedBy: e.target.value })) }
            placeholder='Ex: ""'
            value={ enclosedBy }
        />
      </div>

      <div style={{ minWidth: "300px" }}>
        <Input
            label="Lines Terminated By"
            onChange={ e => setState(prev => ({ ...prev, linesTerminatedBy: e.target.value })) }
            placeholder='Ex: \n'
            value={ linesTerminatedBy }
        />
      </div>

      <div style={{ minWidth: "300px",cursor: "text" }}>
        <TextArea
          label="Query"
          onChange={ e => setState(prev => ({ ...prev, query: e.target.value })) }
          minHeight="300px"
          value={ query }
        />
      </div>

      <Button onClick={() => executeQuery()} type="confirm" disabled={ loading || !fieldsFilled }>{ loading ? "Carregando..." : "Executar query" }</Button>

    </div>

  )
}

interface State {
  host: string;
  dbUser: string;
  dbPassword: string;
  charset: string,
  intoOutfile: string,
  fieldsTerminatedBy: string,
  enclosedBy: string,
  linesTerminatedBy: string,
  query: string;
  loading: boolean;
  userId: number | null;
}



async function baseQueryExecute(queryExecute: QueryExecute, host: string, dbUser: string, dbPassword: string, charset: string, intoOutfile: string,
                                fieldsTerminatedBy: string, enclosedBy: string, linesTerminatedBy: string, query: string,
   setState: SetState<State>, openModal: OpenModal) {
  
  setState(prev => ({ ...prev, loading: true }));

  try {
    const data = await queryExecute({ query, host, dbUser, dbPassword, charset, intoOutfile, fieldsTerminatedBy, enclosedBy, linesTerminatedBy });
    setState(prev => ({ ...prev, data }))
    openModal(getResult(data.result))
  } catch (e) {
    openModal(getErrorMessage(e))
  } finally {
    setState(prev => ({ ...prev, loading: false }));
  }

}

function getResult(result: string) {

  return (
    <div style={{ textAlign: "center" }}> 
      <div style={{ fontWeight: "bolder", marginBottom: "1em" }}>
        Sucesso
      </div>
      { 
        !result ? null : (
          <div style={{ marginTop: "1.6em", fontSize: "1em" }}>
          { result }
        </div>
        ) 
      }
    </div>
  )

}


export default QueryApiPage;
