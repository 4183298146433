import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useState } from "react";

import styles from  "./loading.module.css";

export type UseLoading = typeof useLoading;
export type StartLoading = ReturnType<UseLoading>["startLoading"];
export type FinishLoading = ReturnType<StartLoading>;
export type IsLoading = ReturnType<UseLoading>["isLoading"];

interface StackItem {
  key: string,
  message?: string,
  show?: boolean
}

interface State {
  messageStack: Array<StackItem>
}

export const initialState: State = {
  messageStack: []
}

export type ContextType = [ State, Dispatch<SetStateAction<State>> ]

export const Context = createContext<ContextType>([ initialState, () => {} ]);

export function LoadingProvider(props: { children: ReactNode }) {

  const [ state, setState ] = useState(initialState);

  const currentMessage = getCurrentMessage(state.messageStack);

  return (
    <Context.Provider value={[ state, setState ]}>
      {
        !currentMessage ? null : (
          <div className={ styles.loadingWrapper }>
            <div className={ styles.loadingMessage }>
              { currentMessage }
            </div>
          </div>
        )
      }
      { props.children }
    </Context.Provider>
  )

}

function getCurrentMessage(stack: Array<StackItem>): string | undefined {

  const filtered = stack.filter(i => i.show !== undefined ? i.show : true);

  if (!filtered.length) return undefined;

  return filtered[filtered.length - 1].message || "Carregando...";

}

export default function useLoading() {

  const [ state, setState ] = useContext(Context);

  const isLoading = useCallback((key?: string) => {
    if (key === undefined) {
      return state.messageStack.length !== 0;
    }
    return state.messageStack.find(i => i.key === key) ? true : false
  }, [ state ]);

  const startLoading = useCallback((params: StackItem) => {

    setState(prev => ({ ...prev, messageStack: [ ...prev.messageStack, params ] }));

    return () => setState(prev => ({ ...prev, messageStack: prev.messageStack.filter(i => i.key !== params.key) }));

  }, [ setState ])

  return { isLoading, startLoading };

}