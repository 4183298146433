import { Switch, Route } from "react-router-dom";

import { Title } from '../../../components/Titles';
import UserListPage from "./user/UserListPage"
import UserCreatePage from "./user/UserCreatePage";
import Card from "../ui/Card";
import Tabs from "../ui/Tabs";
import SubTitle from "../ui/SubTitle";
import GroupManagementPage from "./group/GroupManagementPage";
import { useMenu } from "../../service/context/globalContext";
import Platform from "../ui/Platform";
import PartnerPage from "../../../pages/PartnerPage";
import PartnerProfilePage from "../../../pages/PartnerProfilePage";
import PartnerCreatePage from "./user/PartnerCreatePage";
import PartnerListPage from "./user/PartnerListPage";
import PartnerFiltersPage from "./user/PartnerFiltersPage";
import PartnerWebhookPage from "./user/PartnerWebhookPage";
import ResetPartnerPasswordPage from "./user/ResetPartnerPasswordPage";
import PartnerCouponReport from "./partner/PartnerCouponReport";

function UserManagementPage() {

  const menu = useMenu("/user");

  return (
    <Platform>
      <Title>Gestão de Usuários</Title>
      <SubTitle>
        Criação e edição de usuários e grupos
      </SubTitle>
      <Tabs menu={ menu } />
      <Card>
        <Switch>
          <Route exact path="/user/search/:page">
            <UserListPage />
          </Route>
          <Route exact path="/user/new">
            <UserCreatePage />
          </Route>
          <Route exact path={ [ "/user/edit", "/user/edit/:id" ] }>
            <UserCreatePage edit />
          </Route>
          <Route exact path="/user/groups">
            <GroupManagementPage />
          </Route>
          <Route exact path="/user/promotion-partner">
            <PartnerPage />
          </Route>
          <Route path="/user/promotion-partner/:partner/:partnerId">
            <PartnerProfilePage />
          </Route>
          <Route exact path="/user/partner/new">
            <PartnerCreatePage />
          </Route>
          <Route exact path={ [ "/user/partner/edit", "/user/partner/edit/:id" ] }>
            <PartnerCreatePage edit />
          </Route>
          <Route exact path="/user/partner/search/:page">
            <PartnerListPage />
          </Route>
          <Route exact path="/user/partner/filters/:partnerUsername/:partnerName">
            <PartnerFiltersPage/>
          </Route>
          <Route exact path="/user/partner/webhook/:partnerUsername/:partnerName">
            <PartnerWebhookPage/>
          </Route>
          <Route exact path="/user/partner/reset-password/:partnerId/:partnerName">
            <ResetPartnerPasswordPage/>
          </Route>
          <Route path="/user/partner/report-coupon">
            <PartnerCouponReport/>
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}

export default UserManagementPage