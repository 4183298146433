import { http } from "../api/http";
import { baseUrl } from "../constants";
import useModal from "../hooks/useModal";

function useProductBrand() {
  const { createModal } = useModal();

  const searchBrands = async function (search, offset) {
    try {
      const lockList = null;
      const { data: brands } = await http.get(
        baseUrl + `/api/v1/products/brands`,
        {
          params: {
            search,
            offset,
            lockList,
            limit: 10,
          },
        }
      );

      return {
        options: brands.map((b) => ({
          id: b.id,
          name: b.name,
          manufacturerId: b.manufacturerId,
          manufacturer: b.manufacturer,
          image: b.image,
          ownBrand: b.ownBrand,
          economicGroup: b.economicGroup,
        })),
        hasMore: brands.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar marcas.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const fetchEconomicGroups = async () => {
    try {
      const { data: ecoGroups } = await http.get(
        baseUrl + `/api/v1/products/economic-groups`
      );
      return ecoGroups;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao buscar grupos econômicos</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const existsBrand = async (data) => {
    try {
      return await http.get(`${baseUrl}/api/v1/products/brand/exists`, {
        params: { id: data.id, name: data.name?.trim() },
      });
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao verificar duplicidade de marca de produto</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const saveBrand = async (brand) => {
    try {
      const formData = new FormData();
      formData.append("changeManufacturer", brand.changeManufacturer);
      if (brand.id) formData.append("id", brand.id);
      if (brand.name) formData.append("name", brand.name?.trim());
      if (brand.economicGroup)
        formData.append(
          "economicGroup",
          brand.economicGroup?.toUpperCase().trim()
        );
      if (brand.ownBrand) formData.append("ownBrand", brand.ownBrand);
      if (brand.manufacturerId)
        formData.append("manufacturerId", brand.manufacturerId);
      if (brand.manufacturer)
        formData.append("manufacturer", brand.manufacturer?.trim());
      if (brand.image) formData.append("image", brand.image);
      if (brand.newBrandImg) formData.append("newBrandImg", brand.newBrandImg);

      return await http.post(`${baseUrl}/api/v1/products/brand`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar criar a marca.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const removeProductBrand = async (brandId) => {
    try {
      await http.delete(baseUrl + "/api/v1/products/brand/" + brandId);
      createModal(
        <div className="align-center">
          <p>Marca removida com sucesso.</p>
          <br />
          <br />
        </div>,
        { replace: true }
      );
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar remover a marca.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  };

  return {
    searchBrands,
    saveBrand,
    existsBrand,
    fetchEconomicGroups,
    removeProductBrand,
  };
}

export default useProductBrand;
