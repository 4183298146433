import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { TAG_TYPES } from "../../../constants";
import Button from "../../components/form/Button";
import Input from "../../components/form/Input";
import Select from "../../components/form/Select";
import TextArea from "../../components/form/TextArea";
import BasicConfirmationForModal from "../../components/ui/BasicConfirmationForModal";
import Pagination from "../../components/ui/Pagination";
import { TableResponsiveWrapper } from "../../components/ui/TableResponsiveWrapper";
import styles from "../../components/ui/ui.module.css";
import { History, SetState } from "../../types";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import { ChangeEvent } from "../product/useManufacturerManagement";
import useQuery from "../url/useQuery";
import useTagRequests, {
  AttachProductsToTag,
  AttachSegmentsToTag,
  DeleteTag,
  DetachProductFromTag,
  DetachSegmentFromTag,
  DownloadTagCsv,
  DownloadTagRelationshipCsv,
  GetRelationshipInformation,
  SaveTag,
  Tag,
  TagRelationshipSearchView,
  TagSearchForm,
  TagSearchView,
} from "./useTagRequests";

export default function useTagListPage() {
  const [state, setState] = useState<State>(initialState);

  const {
    searchTag,
    saveTag,
    deleteTag,
    getRelationshipInformation,
    attachProductsToTag,
    detachProductFromTag,
    attachSegmentsToTag,
    detachSegmentFromTag,
    downloadTagCsv,
    downloadTagRelationshipCsv,
  } = useTagRequests();

  const { openModal } = useModal();

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const query = useQuery();

  const { filter: formFilter } = state;

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const findTags = useCallback(
    () => baseFindOnSubmit(searchTag, page, openModal, setState, filter),
    [page, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFilterOnSubmit(history, formFilter, e),
    [formFilter]
  );

  const onDownloadTagCsvClick = useCallback(
    () => baseOnDownloadTagCsvClick(downloadTagCsv, setState, openModal, state),
    [state]
  );

  const openTagCreatetModal = () =>
    openModal(
      <BaseOpenTagSaveModal
        save={saveTag}
        openModal={openModal}
        refresh={findTags}
      />
    );

  const openTagEditModal = (tag: Tag) =>
    openModal(
      <BaseOpenTagSaveModal
        save={saveTag}
        openModal={openModal}
        refresh={findTags}
        tag={tag}
      />
    );

  const handleDeleteTagOnConfirm = (id: number) =>
    baseHandleDeleteTagOnConfirm(id, openModal, deleteTag, findTags);

  const openTagDeleteModal = (id: number) =>
    openModal(
      <BasicConfirmationForModal
        message="Tem certeza que deseja apagar a tag?"
        onConfirm={() => handleDeleteTagOnConfirm(id)}
      />
    );

  const openRelationshipInformationModal = useCallback(
    (tag: Tag, type: "PRODUCT" | "SEGMENT") =>
      openModal(
        <BaseOpenRelationshipInformationModal
          getRelationshipInformation={getRelationshipInformation}
          attachProductsToTag={attachProductsToTag}
          attachSegmentsToTag={attachSegmentsToTag}
          detachProductFromTag={detachProductFromTag}
          detachSegmentFromTag={detachSegmentFromTag}
          downloadTagRelationshipCsv={downloadTagRelationshipCsv}
          openModal={openModal}
          tag={tag}
          type={type}
        />
      ),
    [
      getRelationshipInformation,
      attachProductsToTag,
      detachProductFromTag,
      attachSegmentsToTag,
      detachSegmentFromTag,
      downloadTagRelationshipCsv,
      openModal,
    ]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [formFilter]
  );

  useEffect(() => {
    findTags();
  }, [page, filter]);

  return {
    ...state,
    page,
    handleOnChange,
    handleFindOnSubmit,
    openTagCreatetModal,
    openTagEditModal,
    openTagDeleteModal,
    openRelationshipInformationModal,
    handlePageOnChange,
    onDownloadTagCsvClick,
  };
}

interface State {
  filter: string;
  totalPages: number;
  items: Tag[];
  loading: boolean;
  loadingExcel: boolean;
}

const initialState: State = {
  filter: "",
  totalPages: 1,
  items: [],
  loading: false,
  loadingExcel: false,
};

async function baseFindOnSubmit(
  search: (params: TagSearchForm) => Promise<TagSearchView>,
  page: number,
  openModal: OpenModal,
  setState: SetState<State>,
  filter: string
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await search({ filter, page });
    setState((prev) => ({
      ...prev,
      items: response.items,
      totalPages: response.total,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFilterOnSubmit(
  history: History,
  filter: string,
  e: FormEvent
) {
  e.preventDefault();

  if (filter) {
    history.push("/tag/list/1?filter=" + filter);
  } else {
    history.push("/tag/list/1?filter=");
  }
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number
) {
  if (filter) {
    history.push("/tag/list/" + page + "?filter=" + filter);
  } else {
    history.push("/tag/list/" + page + "?filter=");
  }
}

async function baseOnDownloadTagCsvClick(
  downloadTagCsv: DownloadTagCsv,
  setState: SetState<State>,
  openModal: OpenModal,
  state: State
) {
  try {
    setState((prev) => ({ ...prev, loadingExcel: true }));

    const csvData = await downloadTagCsv(state.filter ? state.filter : "");

    const csvFile = new Blob([csvData], { type: "text/csv" });

    let tempLink = document.createElement("a");

    tempLink.download = "tag.csv";
    tempLink.href = window.URL.createObjectURL(csvFile);

    tempLink.style.display = "none";
    document.body.appendChild(tempLink);

    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingExcel: false }));
  }
}

function BaseOpenTagSaveModal(props: {
  save: SaveTag;
  openModal: OpenModal;
  refresh: () => Promise<void>;
  tag?: Tag;
}) {
  const { save, openModal, refresh, tag } = props;

  const [state, setState] = useState<{
    id?: number | undefined;
    description: string;
    type: string;
    loading: boolean;
  }>({
    id: tag ? tag.id : undefined,
    description: tag ? tag.description : "",
    type: tag ? tag.type : "",
    loading: false,
  });

  const { id, description, type, loading } = state;

  const handleOnSaveGroup = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      await save({ id, description, type });
      await refresh();
      openModal("Tag foi salva com sucesso.");
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [save, openModal, refresh, id, description, type]);

  return (
    <div className={styles.center}>
      <div className={styles.left}>
        <Input
          value={description}
          onChange={(e) =>
            setState((prev) => ({ ...prev, description: e.target.value }))
          }
          placeholder="Digite..."
          label="Nome"
        />
        <Select
          value={type}
          onChange={(e) =>
            setState((prev) => ({ ...prev, type: e.target.value }))
          }
          placeholder="Selecione..."
          label="Tipo"
          options={TAG_TYPES}
        />
      </div>

      <Button
        onClick={handleOnSaveGroup}
        type="confirm"
        disabled={!description || !type || loading}
      >
        {loading ? "Carregando..." : "Salvar Tag"}
      </Button>
    </div>
  );
}

async function baseHandleDeleteTagOnConfirm(
  id: number,
  openModal: OpenModal,
  deleteTag: DeleteTag,
  refresh: () => Promise<void>
) {
  try {
    await deleteTag(id);
    await refresh();

    openModal("A Tag foi apagada");
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function BaseOpenRelationshipInformationModal(props: {
  getRelationshipInformation: GetRelationshipInformation;
  attachProductsToTag: AttachProductsToTag;
  attachSegmentsToTag: AttachSegmentsToTag;
  detachProductFromTag: DetachProductFromTag;
  detachSegmentFromTag: DetachSegmentFromTag;
  downloadTagRelationshipCsv: DownloadTagRelationshipCsv;
  openModal: OpenModal;
  tag: Tag;
  type: "PRODUCT" | "SEGMENT";
}) {
  const {
    getRelationshipInformation,
    attachProductsToTag,
    detachProductFromTag,
    attachSegmentsToTag,
    detachSegmentFromTag,
    downloadTagRelationshipCsv,
    openModal,
    tag,
    type,
  } = props;

  const [state, setState] = useState<{
    data: TagRelationshipSearchView;
    filter: string;
    pageSize: number;
    loading: boolean;
    loadingExcel: boolean;
  }>({
    data: {
      items: [],
      page: 1,
      total: 1,
    },
    filter: "",
    pageSize: 100,
    loading: false,
    loadingExcel: false,
  });
  const { data, filter, pageSize, loading, loadingExcel } = state;
  const [filterForm, setFilterForm] = useState("");
  const [textAreaForm, setTextAreaForm] = useState({
    openForm: false,
    sending: false,
    value: "",
  });
  const buttonDescription = textAreaForm.openForm
    ? "voltar"
    : `Relacionar ${"PRODUCT" === type ? "Ids de Produtos" : "Ids de Segmentos"
    }`;

  const parseTextAreaValue = useCallback(() => {
    return Array.from(
      new Set(textAreaForm.value.split(";").map((item) => item.trim()))
    ).filter((item) => item !== "");
  }, [textAreaForm.value]);

  const onDownloadTagRelationshipCsvClick = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, loadingExcel: true }));

      const csvData = await downloadTagRelationshipCsv({ tagId: tag.id, type });

      const csvFile = new Blob([csvData], { type: "text/csv" });

      let tempLink = document.createElement("a");

      tempLink.download =
        "PRODUCT" === type ? "tag-products.csv" : "tag-segments.csv";
      tempLink.href = window.URL.createObjectURL(csvFile);

      tempLink.style.display = "none";
      document.body.appendChild(tempLink);

      tempLink.click();
      document.body.removeChild(tempLink);
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loadingExcel: false }));
    }
  }, [setState, downloadTagRelationshipCsv, openModal, tag, type]);

  const loadRelationships = useCallback(
    async (page: number) => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const response = await getRelationshipInformation({
          page,
          type,
          filter: filterForm,
          pageSize,
          tagId: tag.id,
        });
        setState((prev) => ({
          ...prev,
          data: {
            items: response.items,
            page: response.page,
            total: response.total,
          },
        }));
      } catch (e) {
        getErrorMessage(e);
      } finally {
        setState((prev) => ({ ...prev, loading: false }));
      }
    },
    [getRelationshipInformation, setState, type, filterForm, pageSize, tag.id]
  );

  const handleRemoveProductOnClick = useCallback(
    async (id: number) => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        if ("PRODUCT" === type) {
          await detachProductFromTag({ tagId: tag.id, productId: id });
        }
        if ("SEGMENT" === type) {
          await detachSegmentFromTag({ tagId: tag.id, segmentId: id });
        }
        await loadRelationships(1);
        openModal("Relação com id: " + id + " removida com sucesso.");
      } catch (e) {
        openModal(getErrorMessage(e));
      } finally {
        setState((prev) => ({ ...prev, loading: true }));
      }
    },
    [
      detachProductFromTag,
      detachSegmentFromTag,
      loadRelationships,
      openModal,
      setState,
      tag.id,
      type,
    ]
  );

  const handleSendRelationsOnClick = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      let errors: string[] = [];
      if ("PRODUCT" === type) {
        const response = await attachProductsToTag({
          tagId: tag.id,
          productIds: parseTextAreaValue(),
        });
        errors = response;
      }
      if ("SEGMENT" === type) {
        const response = await attachSegmentsToTag({
          tagId: tag.id,
          segmentIds: parseTextAreaValue(),
        });
        errors = response;
      }
      openModal(
        `Relacionamentos enviados. ${errors.length > 0
          ? `${errors.length} erro(s) ocorrido(s). Verifique seu email para maiores detalhes.`
          : ""
        }`
      );
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: true }));
    }
  }, [
    attachProductsToTag,
    attachSegmentsToTag,
    parseTextAreaValue,
    openModal,
    setState,
    tag.id,
    type,
  ]);

  const handleTextAreaChange = (e: ChangeEvent) => {
    let inputValue = e.target.value;

    inputValue = inputValue.replace(/[^0-9;]/g, "");
    inputValue = inputValue.replace(/;+/g, ";");
    inputValue = inputValue.replace(/([^0-9]|^);/g, "$1");

    setTextAreaForm((prev) => ({ ...prev, value: inputValue }));
  };
  useEffect(() => {
    loadRelationships(1);
  }, [loadRelationships]);

  if (textAreaForm.openForm) {
    return (
      <div>
        <h3
          style={{
            textAlign: "center",
          }}
        >
          Tag: {tag.description}
        </h3>
        <br />

        <Button
          type="info"
          disabled={loading}
          onClick={() =>
            setTextAreaForm((prev) => ({ ...prev, openForm: !prev.openForm }))
          }
        >
          {buttonDescription}
        </Button>

        <div style={{ margin: "10px 0" }}>
          <TextArea
            label="Digite os Ids separando-os por ponto e vírgula ( ; )"
            onChange={(e) => handleTextAreaChange(e)}
            minHeight="300px"
            value={textAreaForm.value}
            disabled={textAreaForm.sending}
          />
        </div>

        {!textAreaForm.sending ? (
          <div>
            <Button
              type="confirm"
              disabled={loading || parseTextAreaValue().length === 0}
              onClick={() =>
                setTextAreaForm((prev) => ({ ...prev, sending: !prev.sending }))
              }
            >
              {parseTextAreaValue().length > 0
                ? `Enviar (${parseTextAreaValue().length} Ids)`
                : "Nenhum Id"}
            </Button>
          </div>
        ) : (
          <div>
            <p>Tem certeza que deseja enviar?</p>
            <Button
              type="confirm"
              disabled={loading || parseTextAreaValue().length === 0}
              onClick={handleSendRelationsOnClick}
            >
              {parseTextAreaValue().length > 0
                ? `Sim (${parseTextAreaValue().length} Ids)`
                : "Nenhum Id"}
            </Button>{" "}
            <Button
              type="cancel"
              disabled={loading}
              onClick={() =>
                setTextAreaForm((prev) => ({ ...prev, sending: !prev.sending }))
              }
            >
              Não
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <h3
        style={{
          textAlign: "center",
        }}
      >
        Tag: {tag.description}
      </h3>
      <br />

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            type="info"
            onClick={() =>
              setTextAreaForm((prev) => ({ ...prev, openForm: !prev.openForm }))
            }
          >
            {buttonDescription}
          </Button>
        </div>
        <div>
          <Button
            type="confirm"
            onClick={onDownloadTagRelationshipCsvClick}
            disabled={loadingExcel}
          >
            {loadingExcel ? "Baixando..." : "Baixar Excel"}
          </Button>
        </div>
      </div>
      <br />
      <br />

      {loading ? (
        <div>Carregando...</div>
      ) : !data.items ? (
        <div>Nenhum item encontrado</div>
      ) : (
        <>
          <form
            onSubmit={(e: FormEvent) => {
              e.preventDefault();
              setFilterForm(filter);
            }}
          >
            <Input
              onChange={(e: ChangeEvent) =>
                setState((prev) => ({ ...prev, filter: e.target.value }))
              }
              value={filter}
              name="filter"
              placeholder="Pesquisar..."
              label={"Filtre o " + ("PRODUCT" === type ? "produto" : "segmento") + " pelo nome"}
              addons={[
                <Button key="1" type="info" submit>
                  Pesquisar
                </Button>,
              ]}
            />
          </form>

          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Id</th>
                  <th className={`${styles.fullWidth} ${styles.left}`}>
                    Descrição
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item) => (
                  <tr key={item.value}>
                    <td>
                      <Button
                        small
                        type="cancel"
                        onClick={() => handleRemoveProductOnClick(item.value)}
                      >
                        Remover
                      </Button>
                    </td>
                    <td>{item.value}</td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {item.description || "Não Informado"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableResponsiveWrapper>
          <Pagination
            page={data.page}
            totalPages={data.total === null ? undefined : data.total}
            onChangePage={loadRelationships}
          />
        </>
      )}
    </div>
  );
}
