import { ChangeEvent } from "react";
import styles from "./Form.module.css";

export interface Option {
  id: string;
  name: string;
}

export interface Props {
  label?: string;
  placeholder?: string;
  options: Option[];
  onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  value?: string | null;
  name?: string;
}

function Select(props: Props) {
  const { label, onChange, value, placeholder, name, options } = props;

  return (
    <div className={styles.inputGroup}>
      {!label ? null : <label className={styles.label}>{label}</label>}
      <select
        className={styles.input}
        placeholder={placeholder}
        value={value === null ? "" : value}
        onChange={onChange}
        name={name}
      >
        <option value="">Selecione...</option>
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default Select;
