import React from "react";
import { Title } from "../../Titles";
import NielsenCatDisplay from "./nielsen-category-display";
import ProdCatDisplay from "./product-category-display";

export default function displayCategory({ category, goToEdit }) {

  const strategy = {
    PRODUCTCATEGORY: (<ProdCatDisplay category={category} goToEdit={goToEdit} />),
    NIELSENCATEGORY: (<NielsenCatDisplay categories={category} goToEdit={goToEdit} />)
  }

  const displayType = Array.isArray(category) ? "NIELSENCATEGORY" : "PRODUCTCATEGORY"

  return (
    <>
      <Title>Categoria(s)</Title>
      {strategy[displayType]}
    </>
  );
}