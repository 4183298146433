import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Generic from "../components/Generic";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import { DASHBOARDS } from "../constants";
import useClientCompany from "../hooks/useClientCompany";
import useDashboard from "../hooks/useDashboard";
import useGroup from "../hooks/useGroup";
import useHorusAccess from "../hooks/useHorusAccess";
import useUsers from "../hooks/useUsers";
import {
  allRules,
  blockUserPermission,
  isCpfOrCnpj,
  isEmptyObj,
  isNotEmpty,
  makeValidator,
} from "../utils";
import { authCredentials } from "../utils/authCredentials";

function UpdateCompanyForm(props) {
  const { value: newCompany, onChange: setNewCompany } = props;
  const [collapsed, setCollapsed] = useState(true);
  const { onUpdateCompany } = props;
  const userValidator = makeValidator({
    name: isNotEmpty("Nome"),
    cnpj: allRules(isNotEmpty("CPF/CNPJ"), isCpfOrCnpj("CPF/CNPJ")),
  });
  const { getNameErrors, getCnpjErrors, getAllErrors } = userValidator;

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          window.confirm(
            "Atualizar empresa: " +
              newCompany.name +
              "?\n\nATENÇÃO: Certifique-se que todos os dados estão corretos."
          )
        ) {
          onUpdateCompany(newCompany);
          setCollapsed(true);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Atualizar Empresa</SubTitle>
        <Card>
          <Input
            label="Nome"
            value={newCompany.name}
            onChange={(e) =>
              setNewCompany({ ...newCompany, name: e.target.value })
            }
            validate={getNameErrors}
          />
          <Input
            type="number"
            label="CPF/CNPJ"
            value={newCompany.cnpj}
            onChange={(e) =>
              setNewCompany({ ...newCompany, cnpj: e.target.value })
            }
            validate={getCnpjErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Atualizar Empresa
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newCompany)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

function AttachDashboardForm(props) {
  const { onAttachDashboard } = props;
  const [companyDashboard, setCompanyDashboard] = useState({});
  const [dashboards, setDashboards] = useState(null);
  const [collapsedDash, setCollapsedDash] = useState(true);
  const [loading, setLoading] = useState(true);
  const { getAllDashboards } = useDashboard();

  useEffect(() => {
    if (!dashboards) {
      getAllDashboards().then((response) => {
        setDashboards(response.data);
        setLoading(false);
      });
    }
  });

  return (
    <>
      {!loading ? (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "Vincular dashboards à empresa?\n\nATENÇÃO: Essa ação dará acesso, aos dashboards selecionados, à todos os usuários dessa empresa."
              )
            ) {
              onAttachDashboard(companyDashboard);
              setCollapsedDash(true);
            }
          }}
        >
          <Collapse collapsed={collapsedDash}>
            <SubTitle>Vincular dashboards</SubTitle>
            <Card>
              <Input
                label="Dashboards"
                value={companyDashboard.dashIds}
                placeholder="Selecione..."
                onChange={(dashIds) =>
                  setCompanyDashboard({ ...companyDashboard, dashIds })
                }
                options={[
                  ...DASHBOARDS,
                  ...(dashboards || []).map((dash, index) => {
                    return {
                      id: dash.id,
                      name: dash.link
                        ? dash.description + " | Link -> " + dash.link
                        : dash.description,
                      key: index,
                    };
                  }),
                ]}
                multiple
              />
            </Card>
          </Collapse>
          {collapsedDash ? (
            <Button
              type="button"
              layout="info"
              onClick={() => setCollapsedDash(false)}
            >
              Vincular dashboards
            </Button>
          ) : (
            <>
              <Button
                layout="confirm"
                disabled={
                  !companyDashboard.dashIds ||
                  isEmptyObj(companyDashboard.dashIds)
                }
              >
                Enviar
              </Button>{" "}
              <Button
                type="button"
                layout="cancel"
                onClick={() => setCollapsedDash(true)}
              >
                Cancelar
              </Button>
            </>
          )}
        </form>
      ) : null}
    </>
  );
}

function AttachUserOnDashboardForm(props) {
  const { dashboards, companyUsers, onAttachUserOnDashboard } = props;
  const [dashUsers, setDashUsers] = useState({});
  const [collapsedUserDashboard, setCollapsedUserDashboard] = useState(true);
  const userValidator = makeValidator({
    menu: isNotEmpty("Dashboard"),
    userIds: isNotEmpty("Usuários"),
  });
  const { getUserIdsErrors, getMenuErrors, getAllErrors } = userValidator;

  return (
    <>
      {companyUsers ? (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "Vincular dashboards à empresa?\n\nATENÇÃO: Essa ação dará acesso, aos dashboards selecionados, à todos os usuários dessa empresa."
              )
            ) {
              onAttachUserOnDashboard(dashUsers);
              setCollapsedUserDashboard(true);
            }
          }}
        >
          <Collapse collapsed={collapsedUserDashboard}>
            <SubTitle>Vincular usuários</SubTitle>
            <Card>
              <Input
                label="Dashboard"
                value={dashUsers.menu}
                placeholder="Selecione..."
                onChange={(e) =>
                  setDashUsers({ ...dashUsers, menu: e.target.value })
                }
                options={(dashboards || []).map((dash, index) => {
                  return { id: dash.id, name: dash.description, key: index };
                })}
                validate={getMenuErrors}
              />
              <Input
                label="Usuários"
                value={dashUsers.userIds}
                placeholder="Selecione..."
                onChange={(userIds) => setDashUsers({ ...dashUsers, userIds })}
                options={(companyUsers || []).map((user, index) => {
                  return { id: user.id, name: user.username, key: index };
                })}
                multiple
                validate={getUserIdsErrors}
              />
            </Card>
          </Collapse>
          {collapsedUserDashboard ? (
            <Button
              type="button"
              layout="info"
              onClick={() => setCollapsedUserDashboard(false)}
            >
              Vincular Usuários a um dashboard da empresa
            </Button>
          ) : (
            <>
              <Button layout="confirm" disabled={getAllErrors(dashUsers)}>
                Enviar
              </Button>{" "}
              <Button
                type="button"
                layout="cancel"
                onClick={() => setCollapsedUserDashboard(true)}
              >
                Cancelar
              </Button>
            </>
          )}
        </form>
      ) : null}
    </>
  );
}

function UserList(props) {
  const {
    companyId,
    companyUsers,
    setCompanyUsers,
    loadingUsers,
    setLoadingUsers,
  } = props;
  const { getAllCompanyUsersById, deleteCompanyUser } = useClientCompany();
  const { blockUser } = useUsers();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const currentCompanyUsers = companyUsers
    ? companyUsers.slice(indexFirstItem, indexLastItem)
    : {};
  const totalItems = companyUsers ? companyUsers.length : 0;

  const authUser = authCredentials.get.authUser();

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleOnDeleteClick = async (user) => {
    if (blockUserPermission(authUser, user)) {
      setLoadingUsers(true);
      try {
        await deleteCompanyUser(user);
        const companyUsersResponse = await getAllCompanyUsersById(companyId);
        setCompanyUsers(companyUsersResponse.data);
        setMessage("Usuário: " + user.username + " deletado da empresa.");
      } catch (error) {
        let err;
        err =
          error.response && error.response.status === 401
            ? new Error("Alteração não autorizada.")
            : error;
        err =
          error.response && error.response.status === 500
            ? new Error(
                "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
              )
            : err;
        setError(err);
      } finally {
        setLoadingUsers(false);
      }
    } else {
      setError(
        new Error(
          "Sua conta não tem permissão para ativar/desativar usuários da empresa HORUS"
        )
      );
    }
  };

  const handleOnBlockUser = async (user) => {
    if (blockUserPermission(userAuth, user)) {
      setLoadingUsers(true);
      try {
        await blockUser(user);
        const companyUsersResponse = await getAllCompanyUsersById(companyId);
        setCompanyUsers(companyUsersResponse.data);
        setMessage(
          user.active
            ? "Usuário: " + user.username + " desativado."
            : "Usuário: " + user.username + " reativado."
        );
      } catch (error) {
        let err;
        err =
          error.response && error.response.status === 401
            ? new Error("Alteração não autorizada.")
            : error;
        err =
          error.response && error.response.status === 500
            ? new Error(
                "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
              )
            : err;
        setError(err);
      } finally {
        setLoadingUsers(false);
      }
    } else {
      setError(
        new Error(
          "Sua conta não tem permissão para ativar/desativar usuários da empresa HORUS"
        )
      );
    }
  };

  return (
    <>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      {!loadingUsers ? (
        companyUsers ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>E-mail</th>
                  <th>Função</th>
                  <th>Ativo</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                {currentCompanyUsers.map((user, index) => {
                  return (
                    <Generic key={index}>
                      <tr
                        className={
                          user.deleted
                            ? "deleted"
                            : !user.active
                            ? "inactive"
                            : "active"
                        }
                      >
                        <td>
                          {user.firstName} {user.lastName}
                        </td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        {user.active ? <td>Sim</td> : <td>Não</td>}
                        <td>
                          <button
                            type="button"
                            layout="delete"
                            onClick={(e) => history.push("/users/" + user.id)}
                          >
                            Visualizar Perfil
                          </button>
                          {user.active ? (
                            <button
                              style={{ marginLeft: "5px" }}
                              type="button"
                              layout="delete"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Desativar usuário: " +
                                      user.email +
                                      "?\n\nAVISO: Essa ação impedirá que o usuário acesse a plataforma Horus."
                                  )
                                ) {
                                  handleOnBlockUser(user);
                                }
                              }}
                            >
                              Desativar
                            </button>
                          ) : (
                            <button
                              style={{ marginLeft: "5px" }}
                              type="button"
                              layout="delete"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Reativar usuário: " +
                                      user.email +
                                      "?\n\nATENÇÃO: Verifique os dados cadastrados e atualize-os, caso necessário."
                                  )
                                ) {
                                  handleOnBlockUser(user);
                                }
                              }}
                            >
                              Ativar
                            </button>
                          )}
                          <Button
                            small
                            type="button"
                            layout="error"
                            style={{ marginLeft: "5px" }}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Remover usuário " +
                                    user.username +
                                    " da empresa?"
                                )
                              ) {
                                handleOnDeleteClick(user);
                              }
                            }}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    </Generic>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          <p>NENHUM USUÁRIO ENCONTRADO</p>
        )
      ) : (
        <Card>
          <Loading style={{ margin: "20px auto" }} />
        </Card>
      )}
      <br />
    </>
  );
}

function DashboardList(props) {
  const {
    dashboards,
    setDashboards,
    loadingDash,
    setLoadingDash,
    onAttachDashboard,
    companyId,
    companyUsers,
  } = props;
  const { getCompanyDashboards, removeCompanyDash } = useClientCompany();
  const { removeGroupDash } = useGroup();
  const { dashVisualizationPermission, removeUserSegmentation } =
    useDashboard();
  const history = useHistory();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [users, setUsers] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const currentDashboards = dashboards
    ? dashboards.slice(indexFirstItem, indexLastItem)
    : {};
  const totalItems = dashboards ? dashboards.length : 0;

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (companyUsers && !users) {
      setUsers(
        companyUsers.map((user) => {
          let key = user.id;
          let value = user.username;
          let obj = {};
          obj[key] = value;
          return obj;
        })
      );
    }
  }, [companyUsers, users]);

  const onAttachDetachAllUsers = async (dash, type) => {
    setLoadingDash(true);
    dash.config.all.v = type;
    let dashUsers = {};
    dashUsers.menu = dash.id;
    dashUsers.clientCompany = companyId;
    dashUsers["config"] = {};
    dashUsers["config"]["all"] = dash.config.all;

    try {
      await dashVisualizationPermission(dashUsers);
      setMessage("Permissão de usuário atualizada.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoadingDash(false);
    }
  };

  const handleOnDeleteSegmentationClick = async (dashId, userId) => {
    setLoadingDash(true);

    try {
      await removeUserSegmentation(companyId, dashId, userId);
      const companyDashboardsResponse = await getCompanyDashboards(companyId);
      setDashboards(companyDashboardsResponse.data);
      setMessage("Segmentação removida.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoadingDash(false);
    }
  };

  const onRemoveDash = async (companyDash) => {
    setLoadingDash(true);
    try {
      companyDash.typeId = companyId;
      await removeCompanyDash(companyDash);
      const companyDashboardsResponse = await getCompanyDashboards(companyId);
      setDashboards(companyDashboardsResponse.data);
      setMessage("Dashboard foi desvinculado da empresa com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoadingDash(false);
    }
  };

  const onRemoveGroup = async (companyDash, index) => {
    setLoadingDash(true);
    try {
      companyDash.userIds = companyDash.userIds.split(",")[index];
      await removeGroupDash(companyDash);
      const companyDashboardsResponse = await getCompanyDashboards(companyId);
      setDashboards(companyDashboardsResponse.data);
      setMessage("Usuário removido do grupo com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoadingDash(false);
    }
  };

  return (
    <>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      {!loadingDash ? (
        dashboards ? (
          <>
            <h1>Vinculados à empresa</h1>
            <Table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Visibilidade geral</th>
                  <th>Usuários</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentDashboards.map((dash, index) => {
                  return (
                    <tr key={index}>
                      {!dash.userIds ? (
                        <>
                          <td>
                            <p
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Desvincular dashboard " +
                                      dash.description +
                                      " da empresa?\n\nAtenção: Nenhum dos usuários dessa empresa poderá acessar a dashboard, a menos que esteja vinculado por grupo."
                                  )
                                ) {
                                  onRemoveDash(dash);
                                }
                              }}
                              style={{
                                cursor: "pointer",
                                textDecorationLine: "underline",
                              }}
                            >
                              {dash.description}
                            </p>
                          </td>
                          {dash.config && users ? (
                            <>
                              <td>
                                {dash.config.all.v ? <p>Sim</p> : <p>Não</p>}
                              </td>
                              <td>
                                {Object.keys(dash.config).map((user, index) => {
                                  if (user !== "all") {
                                    return (
                                      <p>
                                        <span
                                          key={index}
                                          onClick={(e) => {
                                            history.push(
                                              "/dashboard-segmentation/" +
                                                companyId +
                                                "/" +
                                                dash.id +
                                                "/" +
                                                user
                                            );
                                          }}
                                          style={{
                                            cursor: "pointer",
                                            textDecorationLine: "underline",
                                          }}
                                        >
                                          {users.map((u) => {
                                            if (Object.keys(u)[0] === user) {
                                              return Object.values(u)[0];
                                            }
                                          })}{" "}
                                          {dash.config[user].v
                                            ? "Visualiza"
                                            : "Não Visualiza"}
                                        </span>
                                        <span>
                                          <Button
                                            small
                                            type="button"
                                            layout="error"
                                            style={{ marginLeft: "5px" }}
                                            onClick={(e) => {
                                              if (
                                                window.confirm(
                                                  "Remover segmentação?"
                                                )
                                              ) {
                                                handleOnDeleteSegmentationClick(
                                                  dash.id,
                                                  user
                                                );
                                              }
                                            }}
                                          >
                                            <i className="fa fa-trash" />
                                          </Button>
                                        </span>
                                      </p>
                                    );
                                  }
                                  return;
                                })}
                              </td>
                              <td>
                                {dash.config.all.v ? (
                                  <button
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) =>
                                      onAttachDetachAllUsers(dash, false)
                                    }
                                  >
                                    Negar visualização
                                  </button>
                                ) : (
                                  <button
                                    style={{ marginLeft: "5px" }}
                                    onClick={(e) =>
                                      onAttachDetachAllUsers(dash, true)
                                    }
                                  >
                                    Liberar visualização
                                  </button>
                                )}
                                <button
                                  style={{ marginLeft: "5px" }}
                                  onClick={(e) => {
                                    history.push(
                                      "/dashboard-segmentation/" +
                                        companyId +
                                        "/" +
                                        dash.id
                                    );
                                  }}
                                >
                                  Segmentação geral
                                </button>
                              </td>
                            </>
                          ) : (
                            <td></td>
                          )}
                        </>
                      ) : null}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <br />
            <h1>Vinculados por grupos</h1>
            <Table>
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Usuários</th>
                </tr>
              </thead>
              {currentDashboards.map((dash, index) => {
                return (
                  <tbody key={index}>
                    {dash.userIds ? (
                      <tr key={index}>
                        <td>
                          <p
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Promover acesso ao dashboard " +
                                    dash.description +
                                    " para toda empresa?"
                                )
                              ) {
                                onAttachDashboard(dash);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }}
                          >
                            {dash.description}
                          </p>
                        </td>
                        <td>
                          {dash.users.split(",").map((user, index) => {
                            return (
                              <p
                                key={index}
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Desvincular: " +
                                        user +
                                        " e " +
                                        dash.description +
                                        " ?"
                                    )
                                  ) {
                                    onRemoveGroup(dash, index);
                                  }
                                }}
                                style={{
                                  cursor: "pointer",
                                  textDecorationLine: "underline",
                                }}
                              >
                                {user}
                              </p>
                            );
                          })}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                );
              })}
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          <p>NENHUM DASHBOARD ENCONTRADO</p>
        )
      ) : (
        <Card>
          <Loading style={{ margin: "20px auto" }} />
        </Card>
      )}
      <br />
    </>
  );
}

const Wrapper = styled.div``;

function SingleClientCompanyPage() {
  const { companyId } = useParams();
  const {
    updateClientCompany,
    attachCompanyDashboard,
    getCompanyDashboards,
    getClientCompanyById,
    getAllCompanyUsersById,
    addClientWithSegmentation,
    removeClientWithSegmentation,
  } = useClientCompany();
  const { dashVisualizationPermission } = useDashboard();
  const [company, setCompany] = useState(null);
  const [newCompany, setNewCompany] = useState({});
  const [companyUsers, setCompanyUsers] = useState(null);
  const [dashboards, setDashboards] = useState(null);
  const [dashboardList, setDashboardList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingDash, setLoadingDash] = useState(true);
  const [loadingSpecialSeg, setLoadingSpecialSeg] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [collapsedUser, setCollapsedUser] = useState(true);
  const [collapsedDash, setCollapsedDash] = useState(true);
  const { createEvent } = useHorusAccess();
  const [event] = useState({});

  const breadcrump = [
    {
      name: "Início",
      path: "/dashboard",
    },
    {
      name: "Empresas",
      path: "/client-company",
    },
    {
      name: companyId,
      path: "/client-company/" + companyId,
    },
  ];

  const onUpdateCompany = async (data) => {
    setLoading(true);
    data.cnpj.length === 14 ? (data.type = "J") : (data.type = "F");
    const payload = { ...data, id: companyId };
    try {
      await updateClientCompany(payload);
      const companyResponse = await getClientCompanyById(companyId);
      setCompany(companyResponse.data);
      setMessage("Empresa atualizada com sucesso!");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onAttachDashboard = async (companyDash) => {
    setLoading(true);
    try {
      companyDash.typeId = companyId;
      await attachCompanyDashboard(companyDash);
      const companyDashboardsResponse = await getCompanyDashboards(companyId);
      setDashboards(companyDashboardsResponse.data);
      setMessage("Dashboard foi vinculado à empresa com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 400
          ? new Error(
              "Ocorreu um erro ao tentar salvar às dashboard.\nVerifique os dados digitados e tente novamente.\n\nSe o problema persistir entre em contato com o setor de TI"
            )
          : error;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onAttachUserOnDashboard = async (dashUsers) => {
    setLoadingDash(true);
    dashUsers.clientCompany = companyId;
    dashUsers["config"] = {};
    for (let i = 0; i < dashUsers.userIds.length; i++) {
      dashUsers["config"][dashUsers.userIds[i]] = {
        v: true,
        segmentations: [],
      };
    }

    try {
      await dashVisualizationPermission(dashUsers);
      setMessage("Permissão de usuário atualizada.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoadingDash(false);
    }
  };

  const onChangeSpecialSegmentation = async (companyid) => {
    try {
      setLoadingSpecialSeg(true);
      if (company.hasSegmentation) {
        await removeClientWithSegmentation(companyid);
        setCompany({ ...company, hasSegmentation: false });
        setMessage("Cliente removido na lista de segmentação");
      } else {
        await addClientWithSegmentation(companyid);
        setCompany({ ...company, hasSegmentation: true });
        setMessage("Cliente incluído na lista de segmentação");
      }
    } catch (e) {
      setError(e);
    } finally {
      setLoadingSpecialSeg(false);
    }
  };

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access client company id " + companyId;

    createEvent(event);
  }, [companyId]);

  useEffect(() => {
    if (!company) {
      getClientCompanyById(companyId).then((response) => {
        setCompany(response.data);
        setNewCompany(response.data);
        setLoading(false);
      });
    }
  }, [company, setCompany, setLoading, getClientCompanyById, companyId]);

  useEffect(() => {
    if (!companyUsers) {
      getAllCompanyUsersById(companyId).then((response) => {
        setCompanyUsers(response.data);
        setLoadingUsers(false);
      });
    }
  }, [
    companyUsers,
    setCompanyUsers,
    setLoading,
    getAllCompanyUsersById,
    companyId,
  ]);

  useEffect(() => {
    if (!dashboards) {
      getCompanyDashboards(companyId).then((response) => {
        setDashboards(response.data);
        let dashList = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!response.data[i].userIds) {
            dashList.push(response.data[i]);
          }
        }
        setDashboardList(dashList);
        setLoadingDash(false);
      });
    }
  }, [
    dashboards,
    setDashboards,
    getCompanyDashboards,
    setLoadingDash,
    companyId,
  ]);

  return (
    <Template breadcrump={breadcrump}>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Visão do Cliente</Title>
      <Wrapper>
        <div>
          <UpdateCompanyForm
            onUpdateCompany={onUpdateCompany}
            value={newCompany}
            onChange={setNewCompany}
          />
          <br />
          <AttachDashboardForm
            onAttachDashboard={onAttachDashboard}
            companyId={companyId}
          />
          <br />
          <AttachUserOnDashboardForm
            dashboards={dashboardList}
            companyUsers={companyUsers}
            onAttachUserOnDashboard={onAttachUserOnDashboard}
          />
        </div>
        <div>
          {!loading ? (
            <div>
              <h3>Nome: {company.name}</h3>
              <h3>CNPJ: {company.cnpj}</h3>
              <h3>
                Possui Segmentação Especial:{" "}
                {company.hasSegmentation ? "Sim" : "Não"}&nbsp;
                <Button
                  type="button"
                  layout="info"
                  disabled={loadingSpecialSeg}
                  onClick={() => onChangeSpecialSegmentation(companyId)}
                >
                  Alterar
                </Button>
              </h3>
              <br />
            </div>
          ) : (
            <Card>
              <Loading style={{ margin: "20px auto" }} />
            </Card>
          )}
        </div>
        <button
          type="button"
          layout="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsedUser(!collapsedUser)}
        >
          <SubTitle>Usuários</SubTitle>
        </button>
        <Collapse collapsed={collapsedUser}>
          <UserList
            companyUsers={companyUsers}
            setCompanyUsers={setCompanyUsers}
            loadingUsers={loadingUsers}
            setLoadingUsers={setLoadingUsers}
            companyId={companyId}
          />
        </Collapse>
        <button
          type="button"
          layout="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsedDash(!collapsedDash)}
        >
          <SubTitle>Dashboards</SubTitle>
        </button>
        <Collapse collapsed={collapsedDash}>
          <DashboardList
            dashboards={dashboards}
            setDashboards={setDashboards}
            loadingDash={loadingDash}
            setLoadingDash={setLoadingDash}
            onAttachDashboard={onAttachDashboard}
            companyId={companyId}
            companyUsers={companyUsers}
          />
        </Collapse>
      </Wrapper>
    </Template>
  );
}

export default SingleClientCompanyPage;
