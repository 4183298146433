import Button from "../../../components/form/Button";
import Input from "../../../components/form/Input";
import styles from "../../../components/ui/ui.module.css";
import useModal, { OpenModal } from "../../modal/useModal";

import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CHANNELS } from "../../../../constants";
import Select from "../../../components/form/Select";
import BasicConfirmationForModal from "../../../components/ui/BasicConfirmationForModal";
import { History, SetState } from "../../../types";
import { getErrorMessage } from "../../error/getErrorMessage";
import useHandleOnChange from "../../form/useHandleOnChange";
import useQuery from "../../url/useQuery";
import UseCategoryRequest, {
  Category,
  CategorySearchForm,
  CategorySearchView,
  DeleteCategory,
  SaveCategory,
} from "./useCategoryRequest";

export default function useCompanyCategoryPage() {
  const [state, setState] = useState<State>(initialState);

  const { openModal } = useModal();

  const history = useHistory();

  const { saveCategory, searchCategory, deleteCategory } = UseCategoryRequest();

  const { page: pageStr } = useParams<{ page: string }>();
  const page = parseInt(pageStr);

  const query = useQuery();

  const { filter: formFilter } = state;

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const findCategories = useCallback(
    () => baseFindOnSubmit(searchCategory, page, openModal, setState, filter),
    [page, filter, state]
  );

  const openCategoryCreateModal = () =>
    openModal(
      <BaseOpenCategorySaveModal
        save={saveCategory}
        openModal={openModal}
        refresh={findCategories}
      />
    );

  const openCategoryEditModal = (category: Category) =>
    openModal(
      <BaseOpenCategorySaveModal
        save={saveCategory}
        openModal={openModal}
        refresh={findCategories}
        category={category}
      />
    );

  const handleDeleteCategoryOnConfirm = (id: number) =>
    baseHandleDeleteCategoryOnConfirm(
      id,
      openModal,
      deleteCategory,
      findCategories
    );

  const openCategoryDeleteModal = (id: number) =>
    openModal(
      <BasicConfirmationForModal
        message="Essa ação apagará a categoria."
        onConfirm={() => handleDeleteCategoryOnConfirm(id)}
      />
    );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFilterOnSubmit(history, formFilter, e),
    [formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [formFilter]
  );

  useEffect(() => {
    findCategories();
  }, [page, filter]);

  return {
    ...state,
    page,
    openCategoryCreateModal,
    openCategoryEditModal,
    openCategoryDeleteModal,
    handlePageOnChange,
    handleFindOnSubmit,
    handleOnChange,
  };
}

interface State {
  filter: string;
  totalPages: number;
  items: Category[];
  loading: boolean;
}

const initialState: State = {
  filter: "",
  totalPages: 1,
  items: [],
  loading: false,
};

async function baseFindOnSubmit(
  search: (params: CategorySearchForm) => Promise<CategorySearchView>,
  page: number,
  openModal: OpenModal,
  setState: SetState<State>,
  filter: string
) {
  try {
    const pageSize = 100;
    setState((prev) => ({ ...prev, loading: true }));
    const response = await search({ filter, page, pageSize });
    setState((prev) => ({
      ...prev,
      items: response.items,
      totalPages: response.total,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleDeleteCategoryOnConfirm(
  id: number,
  openModal: OpenModal,
  deleteCategory: DeleteCategory,
  refresh: () => Promise<void>
) {
  try {
    await deleteCategory(id);
    await refresh();

    openModal("A Categoria foi apagada");
  } catch (e: any) {
    openModal(getErrorMessage(e));
  }
}

function baseHandleFilterOnSubmit(
  history: History,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();

  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("/company/category/1?filter=" + filter);
}

function baseHandlePageOnChange(
  history: History,
  filter: string | null,
  page: number
) {
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("/company/category/" + page + "?filter=" + filter);
}

function BaseOpenCategorySaveModal(props: {
  save: SaveCategory;
  openModal: OpenModal;
  refresh: () => Promise<void>;
  category?: Category;
}) {
  const { save, openModal, refresh, category } = props;

  const [state, setState] = useState<{
    id: number | undefined;
    description: string;
    channelId: number | undefined;
    channel: string;
    loading: boolean;
  }>({
    id: category ? category.id : undefined,
    description: category ? category.description : "",
    channelId: category ? category.channelId : undefined,
    channel: category ? category.channel : "",
    loading: false,
  });

  const { id, description, channelId, channel, loading } = state;

  const handleOnSaveCategory = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, loading: true }));
      const response = await save({ id, description, channelId, channel });
      await refresh();
      openModal(
        "Categoria salva" +
          (response
            ? ", porém ocorreu um problema na atualização das empresas relacionadas. Favor verificar seu email para maiores detalhes."
            : " com sucesso.")
      );
    } catch (e: any) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [save, id, description, channelId, channel, refresh, openModal]);

  const channels = CHANNELS.map((c) => ({
    id: c.id.toString(),
    name: c.name,
  }));

  return (
    <div className={styles.center}>
      <div className={styles.left}>
        <Input
          value={description}
          onChange={(e) =>
            setState((prev) => ({ ...prev, description: e.target.value }))
          }
          placeholder="Digite..."
          label="Nome"
        />

        {state.id ? null : (
          <Select
            value={channelId?.toString()}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                channelId: parseInt(e.target.value),
              }))
            }
            placeholder="Selecione..."
            label="Canal"
            options={channels}
          />
        )}
      </div>

      <Button
        onClick={handleOnSaveCategory}
        type="confirm"
        disabled={!description || !channelId || loading}
      >
        {loading ? "Carregango..." : "Salvar Categoria"}
      </Button>
    </div>
  );
}
