import { Chart as ChartJS } from "chart.js";
import { useEffect, useMemo, useRef, useState } from "react";
import { ufCodes } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useReportRequests, {
  GetPartnerCouponExtractorReports,
  PartnerCouponExtractorReportView,
} from "../../service/report/useReportRequests";
import { SetState } from "../../types";
import Button from "../form/Button";
import InlineForm from "../form/InlineForm";
import Input from "../form/Input";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import styles from "./reports.module.css";

export default function PartnerCouponExtractor() {
  const [state, setState] = useState(initialState);

  const { queue, uf, filteredReports, reports } = state;

  const handleOnChange = useHandleOnChange(setState);

  const { openModal } = useModal();

  const { getPartnerCouponExtractorReports } = useReportRequests();

  const handleOnFilterClick = () => baseOnFilterClick(state, setState);

  useEffect(() => {
    onEnterComponentEffect(
      setState,
      getPartnerCouponExtractorReports,
      openModal
    );
  }, []);

  return (
    <div>
      <SectionTitle
        title="Extrator de Notas do Parceiro"
        description="Saúde do Processo de Enviar Zips do Parceiro para File Importer"
      />
      <InlineForm>
        <Input
          label="Fila"
          value={queue}
          onChange={handleOnChange}
          name="queue"
          placeholder="Fila de Importação"
        />
        <Input
          label="UF"
          value={uf}
          onChange={handleOnChange}
          name="uf"
          placeholder="Por exemplo: RJ, MG..."
        />
        <Button type="confirm" onClick={handleOnFilterClick}>
          Filtrar
        </Button>
      </InlineForm>
      {!filteredReports ? (
        "Carregando..."
      ) : (
        <>
          <TestsCharts {...state} reports={filteredReports} />
          <Spacer />
          <SectionTitle
            title="Quantidade de Testes por UF"
            description="Distribuições dos testes em todas as UFs"
          />
          <TestsByUfChart {...state} reports={reports!} />
        </>
      )}
    </div>
  );
}

function TestsCharts(props: {
  reports: PartnerCouponExtractorReportView[];
  start: number;
  end: number;
  blockTime: number;
}) {
  const { reports, start, end, blockTime } = props;

  const testsChartRef = useRef<HTMLCanvasElement>(null);
  const testTimeChartRef = useRef<HTMLCanvasElement>(null);
  const positionChartRef = useRef<HTMLCanvasElement>(null);

  const chartData = useMemo(
    () => getChartData(reports, start, end, blockTime),
    [reports, start, end, blockTime]
  );

  useEffect(() => {
    if (
      !testsChartRef.current ||
      !testTimeChartRef.current ||
      !positionChartRef.current
    ) {
      return;
    }

    const testsChart = new ChartJS(testsChartRef.current, {
      type: "line",
      data: chartData.testsChartData,
      options: chartData.options,
    });

    const testTimeAvgChart = new ChartJS(testTimeChartRef.current, {
      type: "line",
      data: chartData.testTimeAverageData,
      options: chartData.options,
    });

    const positionAvgChart = new ChartJS(positionChartRef.current, {
      type: "line",
      data: chartData.positionAverageData,
      options: chartData.options,
    });

    return () => {
      testsChart.destroy();
      testTimeAvgChart.destroy();
      positionAvgChart.destroy();
    };
  }, [chartData, testsChartRef, testTimeChartRef, positionChartRef]);

  return (
    <div className={styles.chartsWrapper}>
      <div className={styles.mainChartWrapper}>
        <canvas ref={testsChartRef} />
      </div>
      <div className={styles.secondaryChartWrapper}>
        <canvas ref={testTimeChartRef} />
      </div>
      <div className={styles.secondaryChartWrapper}>
        <canvas ref={positionChartRef} />
      </div>
    </div>
  );
}

function TestsByUfChart(props: {
  reports: PartnerCouponExtractorReportView[];
  start: number;
  end: number;
  blockTime: number;
}) {
  const { reports, start, end, blockTime } = props;

  const testsByUfRef = useRef<HTMLCanvasElement>(null);

  const data = useMemo(
    () => getUfTestsChartData(reports, start, end, blockTime),
    [reports, start, end, blockTime]
  );

  useEffect(() => {
    if (!testsByUfRef.current) return;

    const chart = new ChartJS(testsByUfRef.current, {
      type: "line",
      data: data.data,
      options: data.options,
    });

    return () => {
      chart.destroy();
    };
  }, [testsByUfRef, data]);

  return (
    <div className={styles.chartsWrapper}>
      <div className={styles.mainChartWrapper}>
        <canvas ref={testsByUfRef} />
      </div>
    </div>
  );
}

interface State {
  reports: null | PartnerCouponExtractorReportView[];
  filteredReports: null | PartnerCouponExtractorReportView[];
  queue: null | string;
  uf: null | string;
  start: number;
  end: number;
  blockTime: number;
}

const initialState: State = {
  reports: null,
  filteredReports: null,
  queue: null,
  uf: null,
  start: defaultStartTimestamp().getTime(),
  end: new Date().getTime(),
  blockTime: 600000,
};

async function onEnterComponentEffect(
  setState: SetState<State>,
  getPartnerCouponExtractorReports: GetPartnerCouponExtractorReports,
  openModal: OpenModal
) {
  try {
    const { result: reports } = await getPartnerCouponExtractorReports({
      start: initialState.start,
    });
    setState((prev) => ({ ...prev, reports, filteredReports: reports }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function defaultStartTimestamp() {
  const date = new Date();
  date.setHours(date.getHours() - 6);
  return date;
}

function getUfTestsChartData(
  reports: PartnerCouponExtractorReportView[],
  start: number,
  end: number,
  blockTime: number
) {
  const ufs: Record<
    string,
    Record<number, PartnerCouponExtractorReportView>
  > = {};

  reports.forEach((report) => {
    const currentState = report.state;

    if (!ufs[currentState]) {
      ufs[currentState] = {};
    }

    const timeBlock = Math.floor(report.timestamp / blockTime);
    ufs[currentState][timeBlock] = ufs[currentState][timeBlock]
      ? mergeReports(ufs[currentState][timeBlock], report)
      : report;
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  let labels: string[] = [];

  const datasets: Array<{
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }> = Object.entries(ufs).map((entry) => {
    const groupedReports = Object.values(entry[1]);
    const filledReports = fillReportBlocks(
      groupedReports,
      start,
      end,
      blockTime
    );

    labels = filledReports.map((i) => getLabel(i, blockTime));

    const uf = ufCodes.find((i) => i.code === entry[0]) || {
      color: getRandomRgb(),
      name: "Desconhecido",
    };

    return {
      label: uf.name,
      data: filledReports.map((i) => i.tests),
      borderColor: `rgba(${uf.color[0]}, ${uf.color[1]}, ${uf.color[2]}, 1)`,
      backgroundColor: `rgba(${uf.color[0]}, ${uf.color[1]}, ${uf.color[2]}, 0.2)`,
    };
  });

  return {
    options,
    data: { labels, datasets },
  };
}

function getChartData(
  reports: PartnerCouponExtractorReportView[],
  start: number,
  end: number,
  blockTime: number
) {
  const groups: Record<number, PartnerCouponExtractorReportView> = {};

  reports.forEach((report) => {
    const timeBlock = Math.floor(report.timestamp / blockTime);
    groups[timeBlock] = groups[timeBlock]
      ? mergeReports(groups[timeBlock], report)
      : report;
  });

  const groupedReports = Object.values(groups);
  const filledReports = fillReportBlocks(groupedReports, start, end, blockTime);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  const labels = filledReports.map((i) => getLabel(i, blockTime));

  const testsChartData = {
    labels,
    datasets: [
      {
        label: "Quantidade de Testes",
        data: filledReports.map((i) => i.tests),
        borderColor: "orange",
        backgroundColor: "rgba(245, 191, 39, 0.2)",
      },
      {
        label: "Quantidade de Tentativas",
        data: filledReports.map((i) => i.attempts),
        borderColor: "blue",
        backgroundColor: "rgba(50, 39, 245, 0.2)",
      },
      {
        label: "Quantidade de Encontros",
        data: filledReports.map((i) => i.founded),
        borderColor: "green",
        backgroundColor: "rgba(58, 245, 39, 0.2)",
      },
      {
        label: "Quantidade de Desistências",
        data: filledReports.map((i) => i.giveUps),
        borderColor: "red",
        backgroundColor: "rgba(245, 39, 39, 0.2)",
      },
    ],
  };

  const testTimeAverageData = {
    labels,
    datasets: [
      {
        label: "Tempo Médio de Teste (Segundos)",
        data: filledReports.map((i) => i.testTimeAverage / 1000),
        borderColor: "orange",
        backgroundColor: "rgba(245, 191, 39, 0.2)",
      },
    ],
  };

  const positionAverageData = {
    labels,
    datasets: [
      {
        label: "Posição Média",
        data: filledReports.map((i) => i.positionAverage),
        borderColor: "orange",
        backgroundColor: "rgba(245, 191, 39, 0.2)",
      },
    ],
  };

  return { options, testsChartData, positionAverageData, testTimeAverageData };
}

function mergeReports(
  a: PartnerCouponExtractorReportView,
  b: PartnerCouponExtractorReportView
): PartnerCouponExtractorReportView {
  return {
    queue: "",
    state: "",
    timestamp: a.timestamp / 2 + b.timestamp / 2,
    attempts: a.attempts + b.attempts,
    tests: a.tests + b.tests,
    founded: a.founded + b.founded,
    giveUps: a.giveUps + b.giveUps,
    positionAverage:
      a.founded + b.founded > 0
        ? (a.positionAverage * a.founded + b.positionAverage * b.founded) /
          (a.founded + b.founded)
        : 0,
    testTimeAverage:
      a.tests + b.tests > 0
        ? (a.testTimeAverage * a.tests + b.testTimeAverage * b.tests) /
          (a.tests + b.tests)
        : 0,
  };
}

function getLabel(view: PartnerCouponExtractorReportView, blockTimne: number) {
  const startOfBlockTimestamp = view.timestamp - (view.timestamp % blockTimne);
  const date = new Date(startOfBlockTimestamp);
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${hour < 10 ? "0" + String(hour) : hour}:${
    minute < 10 ? "0" + String(minute) : minute
  }`;
}

function fillReportBlocks(
  reports: PartnerCouponExtractorReportView[],
  start: number,
  end: number,
  blockTime: number
) {
  const reportsCopy = [...reports];
  const filledBlocks: PartnerCouponExtractorReportView[] = [];

  let currentBlock = Math.floor(start / blockTime);
  const lastBlock = Math.floor(end / blockTime);

  while (currentBlock <= lastBlock) {
    if (reportsCopy.length) {
      const firstBlock = reportsCopy.shift()!;

      if (Math.floor(firstBlock.timestamp / blockTime) === currentBlock) {
        filledBlocks.push(firstBlock);
        currentBlock += 1;
        continue;
      } else {
        reportsCopy.unshift(firstBlock);
      }
    }

    filledBlocks.push({
      queue: "",
      state: "",
      timestamp: currentBlock * blockTime,
      attempts: 0,
      tests: 0,
      founded: 0,
      giveUps: 0,
      positionAverage: 0,
      testTimeAverage: 0,
    });

    currentBlock += 1;
  }

  filledBlocks.shift(); // Discard first block, it's incomplete
  filledBlocks.pop(); // Discard last block, it's incomplete

  return filledBlocks;
}

function baseOnFilterClick(state: State, setState: SetState<State>) {
  if (!state.reports) return;

  const uf = ufCodes.find((i) => i.name === state.uf);
  const code = uf ? uf.code : state.uf;

  const filteredReports = state.reports.filter((r) => {
    if (state.queue && r.queue !== state.queue) return false;
    if (state.uf && r.state !== code) return false;
    return true;
  });

  setState((prev) => ({ ...prev, filteredReports }));
}

function getRandomRgb() {
  let num = Math.round(0xffffff * Math.random());
  let r = num >> 16;
  let g = (num >> 8) & 255;
  let b = num & 255;

  return [r, g, b];
}
