import { mainColor } from "../../../../../constants";
import Button from "../../../../../components/Button";

export default function QueueSetupButtons(props) {
  const {
    prioritizedItems, selectedItems, loading, openModerationModal, openQueueModal, openRemovePriorityModal, clearItems
  } = props;

  return (
    <>
      {
        (function () {
          if (prioritizedItems) {
            return (
              <span>
                <Button
                  style={{"backgroundColor": mainColor, "color": "white"}}
                  disabled={ loading }
                  onClick={ () => openRemovePriorityModal() }>
                  { loading ? "Carregando..." : "Remover Prioridade" }
                </Button>&nbsp;
              </span>
            );
          }
          if (selectedItems.length > 0) {
            return (
              <span>
                <Button
                  style={{"backgroundColor": mainColor, "color": "white"}}
                  disabled={ loading }
                  onClick={ () => openQueueModal() }>
                  { loading ? "Carregando..." : "Priorizar" }
                </Button>&nbsp;
                <Button
                  style={{"backgroundColor": mainColor, "color": "white"}}
                  disabled={ loading }
                  onClick={ () => openModerationModal() }>
                  { loading ? "Carregando..." : "Moderar" }
                </Button>&nbsp;
              </span>
            );
          }

          return null;
        })()
      }
      <Button
        layout="info"
        disabled={ loading }
        onClick={ clearItems }>
        { loading ? "Carregando..." : "Limpar Busca" }
      </Button>
    </>
  );
}