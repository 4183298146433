import AsyncSelect from "../form/AsyncSelect";
import styles from "../ui/ui.module.css";
import { SectionTitle } from "../ui/SectionTitle";
import useDashboardAccessVisualizationPage from "./useDashboardAccessVisualizationPage";
import Pagination from "../ui/Pagination";
import Input from "../form/Input";
import Button from "../form/Button";
import Spacer from "../ui/Spacer";

export default function DashboardAccessVisualizationPage() {

  const {
    fetchClientOpts, handleClientOnChange, fetchUserOpts, handleUserOnChange, handlePageOnChange, handleOnChange, handleOnFilterClick,
    selectedClient, selectedUser, startDate, finishDate, items, totalPages, page, onDownloadExcelClick, loadingExcel
  } = useDashboardAccessVisualizationPage();
  
  return (
    <div>
      <SectionTitle title="Relatório de Utilização de Dashboard" description="Listar as visualizações, e respectivas datas, de dashboards por usuários." />
      <div>
        <AsyncSelect
          fetchData={ fetchClientOpts }
          onChange={ handleClientOnChange }
          value={ selectedClient }
          label="Selecione a empresa do usuário"
        />
      </div>
      {
        selectedClient == null ? null : (
          <div>
            <AsyncSelect
              fetchData={ fetchUserOpts }
              onChange={ handleUserOnChange }
              value={ selectedUser }
              label="Selecione o usuário para para ver quando e quais dashboards visualizou."
            />
          </div>
        )
      }
      <div>
        <Input
          label="Data Inicial"
          name="startDate"
          placeholder="aaaa-mm-dd"
          value={ startDate }
          type="date"
          onChange={ handleOnChange }
        />
        <Input
          label="Data Final"
          name="finishDate"
          placeholder="aaaa-mm-dd"
          value={ finishDate }
          type="date"
          onChange={ handleOnChange }
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button type="info" onClick={ handleOnFilterClick } disabled={ !items }>
            Filtrar
          </Button>
          {
            !selectedClient ? null : (
              <Button type="confirm" onClick={ onDownloadExcelClick } disabled={ !items || loadingExcel }>
                Baixar como Excel
              </Button>
            )
          }
        </div>
      </div>
      <br/>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>Início</th>
            <th>Fim</th>
            <th>Usuário</th>
            <th className={ styles.left }>Empresa</th>
            <th className={ styles.left }>Dashboard</th>
          </tr>
        </thead>
        <tbody>
          {
            !items ? (
              <tr><td colSpan={ 7 }>Carregando....</td></tr>
            ) : (
              items.length === 0 ? (
                <tr><td colSpan={ 7 }>Sem resultados</td></tr>
              ) : (
                items.map((item, index) => (
                  <tr key={ index }>
                    <td>
                      { formatDate(item.enteredAt) }
                    </td>
                    <td>
                      { formatDate(item.leftAt) }
                    </td>
                    <td>
                      { item.username }
                    </td>
                    <td className={ styles.left }>
                      { item.client }
                    </td>
                    <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                      { item.dashboard }
                    </td>
                  </tr>
                ))
              )
            )
          }
        </tbody>
      </table>
      <Spacer />
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}

function formatDate(date: string) {
  const regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})$/;
  const match = regex.exec(date);
  if (match == null) {
    return date;
  }
  const dia = match[3];
  const mes = match[2];
  const ano = match[1];
  const hora = match[4];
  const minuto = match[5];
  return `${dia}/${mes}/${ano} ${hora}:${minuto}`;
}