import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";
import useManufacturerManagement, { Manufacturer } from "../../../service/product/useManufacturerManagement";

export default function ManufacturerManagementPage() {

  const emptyManufacturer: Manufacturer = {
    id: null,
    name: "",
    site:"",
    cnpj: [],
    ean13: "",
    brandQtt: null
  }

  const {
    handleFindOnSubmit, handleOnChange, handlePageOnChange, filter, items, totalPages, page, handleEditOnClick, handleOnDeleteClick
  } = useManufacturerManagement();

  return (
    <div>
      <SectionTitle title="Listagem de Fabricantes" description="Consulta, edição e criação de fabricantes." />
      
      <form onSubmit={ handleFindOnSubmit }>
        <Input
          onChange={ handleOnChange }
          value={ filter }
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre o fabricante pelo nome"
          addons={
            [ <Button key="1" type="info" submit>Pesquisar</Button> ]
          }
        />
      </form>
      <div>
        <Button onClick={ () => handleEditOnClick(emptyManufacturer) } type="confirm">Criar Fabricante</Button>
        <Spacer ratio={ 2/3 } />
      </div>
      <TableResponsiveWrapper>
        <table className={ styles.table }>
          <thead>
            <tr>
              <th>Ações</th>
              <th>id</th>
              <th className={ `${ styles.fullWidth } ${ styles.left }` }>Nome</th>
              <th>Site</th>
              <th>CNPJ Sede</th>
              <th>Qtd. Marcas</th>
            </tr>
          </thead>
          <tbody>
            {
              !items ? (
                <tr><td colSpan={ 7 }>Carregando....</td></tr>
              ) : (
                items.length === 0 ? (
                  <tr><td colSpan={ 7 }>Sem resultados</td></tr>
                ) : (
                  items.map(item => (
                    <tr key={ item.id }>
                      <td>
                        <Button small type="warning" onClick={ () => handleEditOnClick(item) }>
                          Editar
                        </Button> { ' ' }
                        <Button onClick={ () => handleOnDeleteClick(item) } disabled={ item.brandQtt! > 0 } type="cancel" small>
                          Apagar
                        </Button>
                      </td>
                      <td>
                        { item.id }
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { item.name || "Não Informado" }
                      </td>
                      <td>
                        { item.site || "Não Informado" }
                      </td>
                      <td>
                        { item.cnpj?.toString() || "Não Informado" }
                      </td>
                      <td>
                        { item.brandQtt }
                      </td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}