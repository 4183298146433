import Button from "../../../Button";
import { UF } from "../../../../constants";

const MetricsWebSelect = ({ childCategories, plan, setPlan }) => {

  const saveItems = (items) => setPlan((prevState) => ({
    ...prevState,
    params: {
      ...prevState.params,
      categories: items,
    },
  }));

  const addUF = ({ target }) => {
    let items = []
    if (plan?.params?.categories) {
      items = [...plan.params.categories];
    }
    const selectedIndex = target.options.selectedIndex;
    let id = target.options[selectedIndex].getAttribute("data-category-id");
    const itemId = items.findIndex((item) => Number(item.id) === Number(id));
    items[itemId].ufs.push(target.value);

    saveItems(items)
    target.value = "DEFAULT";
  };

  const addCategorie = ({ target }) => {
    let items = []
    if (plan?.params?.categories) {
      items = [...plan.params.categories];
    }
    const selectedIndex = target.options.selectedIndex;
    let id = target.options[selectedIndex].getAttribute("data-id");
    items.push({ id: Number(id), name: target.value, ufs: [] });

    saveItems(items)
    target.value = "DEFAULT";
  };

  const onRemove = (id) => {
    let items = [...plan.params.categories];
    items.splice(items.findIndex((item) => Number(item.id) === Number(id)), 1)
    saveItems(items)
  }

  return (
    <>
      <label style={{fontSize: "12px", fontWeight: "600"}}>Categorias</label><br/>
      <select defaultValue={"DEFAULT"} onChange={addCategorie}>
        <option value="DEFAULT" selected disabled>
          Selecione
        </option>

        {childCategories &&
          childCategories
            .filter(
              (category) =>
                !plan?.params?.categories?.find(
                  (selectCategory) => Number(selectCategory.id) === Number(category.id)
                )
            )
            .map((category, index) => {
              return (
                <option key={index} data-id={category.id}>
                  {category.name}
                </option>
              );
            })}
      </select>

      <ul>
        {plan?.params?.categories?.map((category, i) => (
          <>
            <li key={category.id}>
              <Button type="button" layout="error" small onClick={() => onRemove(category.id)}>
                <i className="fa fa-times" />
              </Button>
              {category.name}

              <ul>
                {category.ufs.map((uf) => (
                  <li>{uf}</li>
                ))}
              </ul>
              <select defaultValue={"DEFAULT"} onChange={addUF}>
                <option value="DEFAULT" selected disabled>
                  Selecione
                </option>

                {UF?.filter(
                  (uf) =>
                    !plan.params?.categories[i]?.ufs?.find(
                      (selectUF) => selectUF === uf
                    )
                )?.map((uf, index) => {
                  return (
                    <option
                      key={index}
                      data-category-id={category.id}
                      value={uf}
                    >
                      {uf}
                    </option>
                  );
                })}
              </select>
            </li>
          </>
        ))}
      </ul>
    </>
  );
};

export default MetricsWebSelect;
