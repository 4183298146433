import Button from "../../Button";
import { Col, Row } from "../../Grid";
import Input from '../../Input';
import AsyncSelect2 from '../../AsyncSelect2';
import { isEmptyObj, isNullText, validateNumericSize, validateTextSize } from "../../../utils";

const leftCol = "66%";
const rightCol = "33%";

export default function NielsenCategoryForm({categoryToSave, editNielsenCategory, createNielsenCategory, editMotherCategory, createMotherCategory,
  onChangeEditNielsen, onChangeCreateNielsen, onChangeEditMother, onChangeCreateMother, fetchProductCategories, fetchLvl1ParentCategories,
  fetchNielsenCategories, fetchParentNielsenCategories, submitCategory, updateCategory}) {

  // Helpers
  const isValidCategory = category => {
    if (
      (!categoryToSave || isEmptyObj(categoryToSave)) ||
      (isNullText(categoryToSave?.name?.trim())) ||
      (editNielsenCategory && (isNullText(categoryToSave?.nielsenCategory?.trim()) || categoryToSave?.nielsenCategory?.trim() === category.nielsenCategory))
    ){
      return false;
    }
    
    return true;
  }

  return (
    <div>
      <Row>
        <Col style={{borderRightStyle: "ridge"}} width={ leftCol }>
          <Row>
            <Col width="50%">
              {
                !categoryToSave?.id ? (
                  <AsyncSelect2
                    label="Categoria Produto*"
                    placeholder="Selecione..."
                    value={ categoryToSave?.prodCategoryId }
                    valueName={ categoryToSave?.productCategory }
                    onChange={ option => updateCategory({
                      prodCategoryId: option ? option.id : null, productCategory: option ? option.name : null
                    }) }
                    fetchData={ fetchProductCategories }
                  />
                ): (
                  <Input
                    label="Categoria Produto*"
                    placeholder="Descrição"
                    value={ categoryToSave?.productCategory }
                    readOnly
                  />
                )
              }
            </Col>

            <Col width="50%">
              {
                (!editNielsenCategory && !createNielsenCategory) ? (
                  <AsyncSelect2
                    label="Categoria Nielsen*"
                    placeholder="Selecione..."
                    value={ categoryToSave?.nielsenId }
                    valueName={ categoryToSave?.nielsen }
                    onChange={ option => updateCategory({
                      nielsenId: option ? option.id : null, nielsen: option ? option.name : null
                    }) }
                    fetchData={ fetchNielsenCategories }
                  />
                ): (
                  <Input
                    label="Categoria Nielsen*"
                    placeholder="Nome"
                    value={ categoryToSave?.nielsen }
                    onChange={ e => updateCategory({ nielsen: validateTextSize(e.target.value, 1, 100) }) }
                  />
                )
              }
            </Col>
          </Row>

          <Row>
            <Col width="50%">
              <AsyncSelect2
                label="Categoria Produto Nível 1*"
                placeholder="Selecione..."
                value={ categoryToSave?.lvl1ProdCategoryId }
                valueName={ categoryToSave?.lvl1ProdCategory }
                onChange={ option => updateCategory({
                  lvl1ProdCategoryId: option ? option.id : null, lvl1ProdCategory: option ? option.name : null
                }) }
                fetchData={ fetchLvl1ParentCategories }
              />
            </Col>

            <Col width="50%">
              {
                (!editMotherCategory && !createMotherCategory) ? (
                  <AsyncSelect2
                    label="Categoria Nielsen Mãe*"
                    placeholder="Selecione..."
                    value={ categoryToSave?.motherId }
                    valueName={ categoryToSave?.mother }
                    onChange={ option => updateCategory({
                      motherId: option ? option.id : null, mother: option ? option.name : null
                    }) }
                    fetchData={ fetchParentNielsenCategories }
                  />
                ) : (
                  <Input
                    label="Categoria Nielsen Mãe*"
                    placeholder="Nome"
                    value={ categoryToSave?.mother }
                    onChange={ e => updateCategory({ mother: validateTextSize(e.target.value, 1, 100) }) }
                  />
                )
              }
            </Col>
          </Row>
        </Col>

        <Col width={ rightCol }>
          <Row style={{textAlign: "center"}}>
            <p><strong>Habilitadores de Edição</strong></p>
          </Row>
          
          <Row>
            <Col width="50%">
            <input 
              type="checkbox"
              disabled={ createNielsenCategory }
              checked={ editNielsenCategory }
              onChange={ () => onChangeEditNielsen() }
            />
            Editar Categoria Nielsen
            </Col>
            
            <Col width="50%">
            <input 
              type="checkbox"
              disabled={ editNielsenCategory }
              checked={ createNielsenCategory }
              onChange={ () => onChangeCreateNielsen() }
            />
            Criar Categoria Nielsen
            </Col>
          </Row>
          
          <br/>
          <Row>
            <Col width="50%">
            <input 
              type="checkbox"
              disabled={ createMotherCategory }
              checked={ editMotherCategory }
              onChange={ () => onChangeEditMother() }
            />
            Editar Categoria Mãe
            </Col>
            
            <Col width="50%">
            <input 
              type="checkbox"
              disabled={ editMotherCategory }
              checked={ createMotherCategory }
              onChange={ () => onChangeCreateMother() }
            />
            Criar Categoria Mãe
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Button
          type="button"
          layout="confirm"
          disabled={ !isValidCategory }
          onClick={() => submitCategory(categoryToSave)}
        >
          Salvar
        </Button>
      </Row>
    </div>
  )
}