import AsyncSelect from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Checkbox from "../../form/Checkbox";
import Input from "../../form/Input";
import Select from "../../form/Select";
import ValidationMessages from "../../form/ValidationMessages";
import { SectionTitle } from "../../ui/SectionTitle";
import EditUserHeader from "./EditUserHeader";
import LoadingUser from "./LoadingUser";
import UserGroupsSelection from "./UserGroupsSelection";
import useUserCreate from "./useUserCreatePage";

const roles = [
  { id: "ADMIN", name: "Administrador" },
  { id: "TI", name: "Tecnologia da Informação" },
  { id: "FINANCIAL", name: "Financeiro" },
  { id: "DATA_SCIENCE", name: "Data Science" },
  { id: "REGISTER", name: "Cadastro" },
  { id: "INSIGHTS", name: "Insight" },
  { id: "READER", name: "Somente Leitura" },
  { id: "COMERCIAL", name: "Setor Comercial" },
  { id: "MODERATION", name: "Moderação" },
];

interface Props {
  edit?: boolean;
}

export default function UserCreatePage(props: Props) {
  const { edit = false } = props;

  const userCreate = useUserCreate(edit);

  const {
    moderator,
    validation,
    showForm,
    form,
    sendingUser,
    fetchingUser,
    buttonText,
    selectedClient,
    selectedEditUser,
    selectedEditClient,
    selectedGroups,
    handleFetchClientIpts: handleFetchClientOpts,
    handleFormOnChange,
    handleClientOnChange,
    handleSendButtonOnClick,
    handleEditClientOnChange,
    handleEditUserOnChange,
    fetchUserOpts,
    fetchClientOpts,
    handleOnSelectedGroupsChange,
    handleCheckOnClick,
  } = userCreate;

  return (
    <div>
      {!edit ? (
        <SectionTitle
          title="Criação de usuário"
          description="Crie usuários para serem usados no Webtool e/ou Intranet."
        />
      ) : (
        <div>
          <SectionTitle
            title="Edição de usuário"
            description="Edite as informações de usuários."
          />
          <div>
            <AsyncSelect
              fetchData={handleFetchClientOpts()}
              filterData={
                moderator?.role === "ADMIN"
                  ? []
                  : [
                      { id: "1", name: "HORUS" },
                      { id: "168", name: "HORUS - FREELANCER" },
                    ]
              }
              onChange={handleEditClientOnChange}
              value={selectedEditClient}
              label="Selecione a empresa do usuário"
            />
          </div>
          {selectedEditClient == null ? null : (
            <div>
              <AsyncSelect
                fetchData={fetchUserOpts}
                onChange={handleEditUserOnChange}
                value={selectedEditUser}
                label="Selecione o usuário para editar"
              />
            </div>
          )}
        </div>
      )}
      {fetchingUser ? (
        <LoadingUser />
      ) : !showForm ? null : (
        <>
          <EditUserHeader {...userCreate} />

          <Input
            label="Email"
            name="email"
            value={form.email}
            onChange={handleFormOnChange}
          />

          <ValidationMessages messages={validation.email} />

          <Input
            label="Nome"
            name="name"
            value={form.name}
            onChange={handleFormOnChange}
          />

          <ValidationMessages messages={validation.name} />

          <AsyncSelect
            label="Empresa"
            fetchData={fetchClientOpts}
            filterData={
              moderator?.role === "ADMIN"
                ? []
                : [
                    { id: "1", name: "HORUS" },
                    { id: "168", name: "HORUS - FREELANCER" },
                  ]
            }
            value={selectedClient}
            onChange={handleClientOnChange}
          />

          <ValidationMessages messages={validation.clientId} />

          <UserGroupsSelection
            value={selectedGroups}
            onChange={handleOnSelectedGroupsChange}
          />

          {!(
            selectedClient?.name.toUpperCase().includes("HORUS") &&
            moderator?.role === "ADMIN"
          ) ? null : (
            <div>
              <SectionTitle
                title="Intranet"
                description="Informações adicionais para acesso à intranet."
              />

              <div>
                <Select
                  label="Função"
                  name="role"
                  options={roles}
                  value={form.role}
                  onChange={handleFormOnChange}
                />
              </div>
            </div>
          )}

          {edit ? null : (
            <div>
              <SectionTitle
                title="Alerta de cadastro"
                description="Deixe marcado caso deseje que o email de boas-vindas seja enviado no ato da criação do usuário."
              />

              <Checkbox
                name="sendMail"
                checked={form.sendEmail}
                label="Enviar email de boas-vindas."
                onChange={handleCheckOnClick}
              />
            </div>
          )}

          <Button
            type="confirm"
            disabled={validation.errors > 0 || sendingUser}
            onClick={handleSendButtonOnClick}
          >
            {buttonText}
          </Button>
        </>
      )}
    </div>
  );
}
