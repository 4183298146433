import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Table from "../components/Table";
import { SubTitle, Title } from "../components/Titles";
import useClientCompany from "../hooks/useClientCompany";
import useDashboard from "../hooks/useDashboard";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { allRules, isNotEmpty, makeValidator, maxLength } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const InputWithButton = styled.div`
  & > div {
    width: calc(100% - 100px);
    float: left;
  }

  button {
    margin-top: 18px;
    width: 100px;
    float: left;
  }

  ::after {
    content: " ";
    display: block;
    clear: both;
  }
`;

const dashValidator = makeValidator({
  reportBiId: allRules(
    isNotEmpty("Id do relatório"),
    maxLength("Id do relatório", 50)
  ),
  groupBiId: allRules(
    isNotEmpty("Id do grupo do relatório"),
    maxLength("Id do grupo do relatório", 50)
  ),
  description: allRules(isNotEmpty("Descrição"), maxLength("Descrição", 50)),
  link: allRules(isNotEmpty("Link"), maxLength("Link", 50)),
  companyId: isNotEmpty("Empresa"),
});

const roles = [
  { id: "ADMIN", name: "Administrador" },
  { id: "TI", name: "Tecnologia da Informação" },
  { id: "FINANCIAL", name: "Financeiro" },
  { id: "DATA_SCIENCE", name: "Data Science" },
  { id: "REGISTER", name: "Cadastro" },
  { id: "READER", name: "Somente Leitura" },
];

const Wrapper = styled.div``;
//descption, reportId, groupId, datasetId, link, role
//on single attach user verifying if dashboard has a group equals to it's name , if not, create one.
//attach multiple users on single dashboard page
//show utilization of the dashboard - last six months.
function UpdateDashForm(props) {
  const { createModal } = useModal();
  const { value: newDashboard, onChange: setNewDashboard } = props;
  const { onUpdateDash } = props;
  const [loadingDataset, setLoadingDataset] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [companies, setCompanies] = useState(null);
  const { getAllClientCompanies } = useClientCompany();
  const { getDatasetId } = useDashboard();
  const {
    getReportBiIdErrors,
    getGroupBiIdErrors,
    getDescriptionErrors,
    getLinkErrors,
    getCompanyIdErrors,
    getAllErrors,
  } = dashValidator;

  const handleSearchDatasetIdOnClick = async () => {
    setLoadingDataset(true);
    try {
      const { data } = await getDatasetId(newDashboard);
      setNewDashboard((prev) => ({ ...prev, ...data }));
    } catch (e) {
      createModal("Ocorreu um erro ao recuperar datasetId");
    }
    setLoadingDataset(false);
  };

  useEffect(() => {
    if (!companies) {
      getAllClientCompanies().then((response) => {
        setCompanies(response.data);
      });
    }
  }, [companies, setCompanies, getAllClientCompanies]);

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          window.confirm(
            "Atualizar Dashboard: " +
              newDashboard.description +
              "?\n\nATENÇÃO: Certifique-se que todos os dados estão corretos."
          )
        ) {
          onUpdateDash(newDashboard);
          setCollapsed(true);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Atualizar Dashboard</SubTitle>
        <Card>
          <Input
            label="Id do relatório"
            value={newDashboard.reportBiId}
            onChange={(e) =>
              setNewDashboard({ ...newDashboard, reportBiId: e.target.value })
            }
            validate={getReportBiIdErrors}
          />
          <Input
            label="Id do grupo do relatório"
            value={newDashboard.groupBiId}
            onChange={(e) =>
              setNewDashboard({ ...newDashboard, groupBiId: e.target.value })
            }
            validate={getGroupBiIdErrors}
          />
          <InputWithButton>
            <Input
              label="Id do dataset"
              value={newDashboard.datasetBiId}
              onChange={(e) =>
                setNewDashboard({
                  ...newDashboard,
                  datasetBiId: e.target.value,
                })
              }
            />
            <Button
              type="button"
              onClick={handleSearchDatasetIdOnClick}
              disabled={
                !newDashboard.reportBiId ||
                !newDashboard.groupBiId ||
                loadingDataset
              }
            >
              {loadingDataset ? "Carregando..." : "Buscar"}
            </Button>
          </InputWithButton>
          <Input
            label="Descrição"
            id="email"
            value={newDashboard.description}
            onChange={(e) =>
              setNewDashboard({ ...newDashboard, description: e.target.value })
            }
            validate={getDescriptionErrors}
          />
          <Input
            label="Link"
            value={newDashboard.link}
            onChange={(e) =>
              setNewDashboard({ ...newDashboard, link: e.target.value })
            }
            validate={getLinkErrors}
          />
          <Input
            label="Empresa"
            value={newDashboard.companyId}
            placeholder="Selecione..."
            onChange={(e) =>
              setNewDashboard({ ...newDashboard, companyId: e.target.value })
            }
            options={(companies || []).map((company, index) => {
              return { id: company.id, name: company.name };
            })}
            validate={getCompanyIdErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Atualizar Dashboard
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newDashboard)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
      <br />
    </form>
  );
}

function AttachCompanyForm(props) {
  const { onAttachCompany } = props;
  const [companyDashboard, setCompanyDashboard] = useState({});
  const [clientCompanies, setClientCompanies] = useState(null);
  const [collapsedDash, setCollapsedDash] = useState(true);
  const [loading, setLoading] = useState(true);
  const { getAllClientCompanies } = useClientCompany();
  const userValidator = makeValidator({
    companyIds: isNotEmpty("Empresas"),
  });
  const { getCompanyIdsErrors, getAllErrors } = userValidator;

  useEffect(() => {
    if (!clientCompanies) {
      getAllClientCompanies().then((response) => {
        setClientCompanies(response.data);
        setLoading(false);
      });
    }
  }, [clientCompanies, setClientCompanies, setLoading, getAllClientCompanies]);

  return (
    <>
      {!loading ? (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "Vincular dashboards às empresa?\n\nATENÇÃO: Essa ação dará acesso ao dashboard à todos os usuários das empresas selecionadas."
              )
            ) {
              onAttachCompany(companyDashboard);
              setCollapsedDash(true);
            }
          }}
        >
          <Collapse collapsed={collapsedDash}>
            <SubTitle>Vincular clientes</SubTitle>
            <Card>
              <Input
                label="Empresas"
                value={companyDashboard.companyIds}
                placeholder="Selecione..."
                onChange={(companyIds) =>
                  setCompanyDashboard({ ...companyDashboard, companyIds })
                }
                options={(clientCompanies || []).map(
                  (clienteCompany, index) => {
                    return { id: clienteCompany.id, name: clienteCompany.name };
                  }
                )}
                multiple
                validate={getCompanyIdsErrors}
              />
            </Card>
          </Collapse>
          {collapsedDash ? (
            <Button
              type="button"
              layout="info"
              onClick={() => setCollapsedDash(false)}
            >
              Vincular clientes
            </Button>
          ) : (
            <>
              <Button
                layout="confirm"
                disabled={getAllErrors(companyDashboard)}
              >
                Enviar
              </Button>{" "}
              <Button
                type="button"
                layout="cancel"
                onClick={() => setCollapsedDash(true)}
              >
                Cancelar
              </Button>
            </>
          )}
        </form>
      ) : null}
    </>
  );
}

function CompanyList(props) {
  const history = useHistory();
  const { onRemoveCompany, companies, loadingComp } = props;

  return (
    <>
      {!loadingComp ? (
        <Table>
          <thead>
            <tr>
              <th>Empresa</th>
              <th>CNPJ</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {companies ? (
              companies.map((company, index) => {
                return (
                  <tr key={index}>
                    <td>{company.name}</td>
                    <td>{company.cnpj}</td>
                    <td>
                      <button
                        type="button"
                        layout="delete"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          history.push("/client-company/" + company.id)
                        }
                      >
                        Visualizar perfil
                      </button>
                      <Button
                        small
                        type="button"
                        layout="error"
                        style={{ marginLeft: "5px" }}
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Desvincular empresa " + company.name + "?"
                            )
                          ) {
                            onRemoveCompany(company);
                          }
                        }}
                      >
                        <i className="fa fa-trash" />
                      </Button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <td>
                <strong>
                  Não foram encontrados usuários com acesso ao Dashboard
                </strong>
              </td>
            )}
          </tbody>
        </Table>
      ) : null}
      <br />
    </>
  );
}

function UserList(props) {
  const history = useHistory();
  const { users, loadingUsers } = props;

  return (
    <>
      {!loadingUsers ? (
        <Table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>E-mail</th>
              <th>Empresa</th>
              <th>Função</th>
              <th>Ativo</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {users ? (
              users.map((user, index) => {
                return (
                  <tr key={index}>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{user.company}</td>
                    <td>
                      {typeof roles.find((role) => role.id === user.role) ===
                      "undefined"
                        ? ""
                        : roles.find((role) => role.id === user.role).name}
                    </td>
                    {user.active ? <td>Sim</td> : <td>Não</td>}
                    <td>
                      {user.company ? (
                        <button
                          type="button"
                          layout="delete"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => history.push("/users/" + user.id)}
                        >
                          Visualizar Perfil
                        </button>
                      ) : null}
                    </td>
                  </tr>
                );
              })
            ) : (
              <td>
                <strong>
                  Não foram encontrados usuários com acesso ao Dashboard
                </strong>
              </td>
            )}
          </tbody>
        </Table>
      ) : null}
      <br />
    </>
  );
}

function SingleDashboardPage() {
  const { dashId } = useParams();
  const {
    updateDash,
    getDashboard,
    getAllDashboardCompanies,
    getAllDashboardUsers,
  } = useDashboard();
  const { attachCompanyDashboard, removeCompanyDash } = useClientCompany();
  const [dashboard, setDashboard] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [users, setUsers] = useState(null);
  const [companyDashboard] = useState({});
  const [newDashboard, setNewDashboard] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingComp, setLoadingComp] = useState(true);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [collapsedCompany, setCollapsedCompany] = useState(true);
  const [collapsedUser, setCollapsedUser] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { createEvent } = useHorusAccess();
  const [event] = useState({});

  const onUpdateDash = async (dash) => {
    setLoading(true);
    setLoadingComp(true);
    try {
      await updateDash(dash);
      const dashboardResponse = await getDashboard(dashId);
      setDashboard(dashboardResponse.data);
      setNewDashboard(dashboardResponse.data);
      const companiesResponse = await getAllDashboardCompanies(dashId);
      setCompanies(companiesResponse.data);
      setMessage("Dashboard atualizada com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os campos preenchidos e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
      setLoadingComp(false);
    }
  };

  const onAttachCompany = async (companyDashboard) => {
    setLoading(true);
    setLoadingComp(true);
    try {
      companyDashboard.id = dashId;
      await attachCompanyDashboard(companyDashboard);
      const companiesResponse = await getAllDashboardCompanies(dashId);
      setCompanies(companiesResponse.data);
      setMessage("Empresas vinculadas ao dashboard com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
      setLoadingComp(false);
    }
  };

  const onRemoveCompany = async (company) => {
    setLoading(true);
    setLoadingComp(true);
    try {
      companyDashboard.id = dashId;
      companyDashboard.typeId = company.id;
      await removeCompanyDash(companyDashboard);
      const companiesResponse = await getAllDashboardCompanies(dashId);
      setCompanies(companiesResponse.data);
      setMessage("Empresa desvinculada com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
      setLoadingComp(false);
    }
  };

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access dashboard id " + dashId;

    createEvent(event);
  }, [dashId]);

  useEffect(() => {
    if (!users) {
      getAllDashboardUsers(dashId).then((response) => {
        setUsers(response.data);
        setLoadingUsers(false);
      });
    }
  }, [users, dashId]);

  useEffect(() => {
    if (!companies) {
      getAllDashboardCompanies(dashId).then((response) => {
        setCompanies(response?.data);
        setLoadingComp(false);
      });
    }
    if (!dashboard) {
      getDashboard(dashId).then((response) => {
        setDashboard(response.data);
        setNewDashboard(response.data);
        setLoading(false);
      });
    }
  }, [dashId]);

  return (
    <>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Dashboard</Title>
      <Wrapper>
        <UpdateDashForm
          onUpdateDash={onUpdateDash}
          value={newDashboard}
          onChange={setNewDashboard}
          loading={loading}
          setLoading={setLoading}
        />
        <br />
        <AttachCompanyForm
          onAttachCompany={onAttachCompany}
          value={dashboard}
        />
        <div>
          {!loading ? (
            <div>
              <h3>Id do relatório: {dashboard.reportBiId}</h3>
              <h3>Id do grupo do relatório: {dashboard.groupBiId}</h3>
              <h3>Id do dataset: {dashboard.datasetBiId}</h3>
              <h3>Descrição: {dashboard.description}</h3>
              <h3>Link: {dashboard.link}</h3>
              <h3>Role: {dashboard.roleBi}</h3>
            </div>
          ) : (
            <Card>
              <Loading style={{ margin: "20px auto" }} />
            </Card>
          )}
        </div>
        <button
          type="button"
          layout="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsedCompany(!collapsedCompany)}
        >
          <SubTitle>Empresas com acesso ao Dashboard</SubTitle>
        </button>
        <Collapse collapsed={collapsedCompany}>
          <CompanyList
            companies={companies}
            onRemoveCompany={onRemoveCompany}
            loadingComp={loadingComp}
          />
        </Collapse>
        <button
          type="button"
          layout="delete"
          style={{ cursor: "pointer" }}
          onClick={() => setCollapsedUser(!collapsedUser)}
        >
          <SubTitle>Usuários com acesso ao Dashboard</SubTitle>
        </button>
        <Collapse collapsed={collapsedUser}>
          <UserList users={users} loadingUsers={loadingUsers} />
        </Collapse>
      </Wrapper>
    </>
  );
}

export default SingleDashboardPage;
