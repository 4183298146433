import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { isEmpty } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useHandleOnChange from "../../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import useQuery from "../../../service/url/useQuery";

export default function useUserListPage() {
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const { filter: formFilter } = state;

  const query = useQuery();

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const { openModal } = useModal();

  const findUsers = useCallback(
    () => baseFindUsers(page, openModal, setState, filter),
    [page, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, formFilter, e),
    [history, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [history, formFilter]
  );

  const handleEditOnClick = useCallback(
    (id: number) => baseHandleOnEditClick(history, id),
    [history]
  );

  useEffect(() => {
    findUsers();
  }, [findUsers]);

  return {
    ...state,
    page,
    findUsers,
    handleFindOnSubmit,
    handlePageOnChange,
    handleOnChange,
    handleEditOnClick,
  };
}

interface State {
  filter: string;
  totalPages: number | null;
  items: Array<UserResumedInfo> | null;
  loading: boolean;
}

interface UserResumedInfo {
  id: number;
  username: string;
  email: string;
  name: string;
  client: string;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  totalPages: null,
  loading: false,
  items: null,
};

async function baseFindUsers(
  page: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(`${baseUrl}/api/v1/user/search`, {
      params: { page, filter },
    });

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  if (filter) {
    history.push("/user/search/1?filter=" + filter);
  } else {
    history.push("/user/search/1?filter=");
  }
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number
) {
  history.push(
    "/user/search/" + page + "?filter=" + (!isEmpty(filter) ? filter : "")
  );
}

function baseHandleOnEditClick(history: History, id: number) {
  history.push("/user/edit/" + id);
}
