import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

export default function usePartnerRequests() {
  const getPartnerCouponConsumed: GetPartnerCouponConsumed = (start, end) =>
    baseGetPartnerCouponConsumed(start, end);

  const downloadPartnerCouponConsumedCsv: DownloadPartnerCouponConsumedCsv = (
    form
  ) => baseDownloadPartnerCouponConsumedCsv(form);

  return { getPartnerCouponConsumed, downloadPartnerCouponConsumedCsv };
}

export interface PartnerCouponConsumed {
  importValid: number;
  importInvalid: number;
  readValid: number;
  readInvalid: number;
  authRequests: number;
  partner: string;
  detail: PartnerCouponConsumedDetail[];
}

export interface PartnerCouponConsumedDetail {
  date: string;
  importValid: number;
  importInvalid: number;
  readValid: number;
  readInvalid: number;
  authRequests: number;
}

export interface DownloadPartnerCouponConsumedCsvForm {
  startMonth: string;
  endMonth: string;
}

export type GetPartnerCouponConsumed = (
  start: string,
  end: string
) => Promise<PartnerCouponConsumed[]>;

export type DownloadPartnerCouponConsumedCsv = (
  form: DownloadPartnerCouponConsumedCsvForm
) => Promise<string>;

async function baseGetPartnerCouponConsumed(
  startMonth: string,
  endMonth: string
) {
  const response = await http.get(`${baseUrl}/api/v1/partner/coupon-consumed`, {
    params: { startMonth, endMonth },
  });
  return response.data.result as PartnerCouponConsumed[];
}

async function baseDownloadPartnerCouponConsumedCsv(
  form: DownloadPartnerCouponConsumedCsvForm
) {
  const response = await http.post(
    `${baseUrl}/api/v1/partner/download/partnerCouponConsumed.csv`,
    form
  );
  return response.data as string;
}
