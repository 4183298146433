import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import Button from "../../form/Button";
import Input from "../../form/Input";
import { SectionTitle } from "../../ui/SectionTitle";
import styles from "../../ui/ui.module.css";

type Props = {};

type Params = {
  partnerUsername: string; // partnerUsername
  partnerName: string; // partnerName
};

interface InputData {
  url: string;
  tokenKey: string;
  tokenValue: string;
}

const PartnerWebhookPage: React.FC<Props> = () => {
  const { partnerName, partnerUsername } = useParams<Params>();

  const [loading, setLoading] = useState(true);

  const [inputs, setInputs] = useState<InputData[]>([
    { url: "", tokenKey: "", tokenValue: "" },
  ]);

  const [partnerData, setPartnerData] = useState<PartnerData | null>(null); // Inicializa com null

  const { openModal, closeModal } = useModal();

  const fetchPartnerData = useCallback(
    () =>
      baseFetchPartnerData({
        partnerName,
        partnerUsername,
        openModal,
        setLoading,
        setPartnerData,
        setInputs,
      }),
    [partnerName, partnerUsername]
  );

  const openDeleteWebhookModal = (webhook: PartnerData | null) =>
    openModal(
      <WebhookDeleteModal
        webhook={webhook}
        openModal={openModal}
        closeModal={closeModal}
        fetchPartnerData={fetchPartnerData}
      />
    );

  const openHandleActiveModal = (webhook: PartnerData | null) =>
    openModal(
      <WebhookHandleActiveModal
        partner={partnerUsername}
        webhook={webhook}
        openModal={openModal}
        closeModal={closeModal}
        fetchPartnerData={fetchPartnerData}
      />
    );

  const handleInputChange = (
    index: number,
    inputName: keyof InputData,
    value: string
  ) => {
    const newInputs = [...inputs];
    newInputs[index] = {
      ...newInputs[index],
      [inputName]: value,
    };
    setInputs(newInputs);
    setIsDataChanged(true);
  };

  const clearInputFields = () => {
    setInputs([{ url: "", tokenKey: "", tokenValue: "" }]);
  };

  async function handleConfirmClick() {
    setLoading(true);
    try {
      await baseHandleOnAddWebhookClick(
        openModal,
        partnerUsername,
        inputs,
        partnerData
      );
      setLoading(false);
      clearInputFields();
      await fetchPartnerData();
    } catch (e) {
      setLoading(false);
      openModal(getErrorMessage(e));
    }
  }

  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    fetchPartnerData();
  }, [fetchPartnerData]);

  return (
    <div>
      <SectionTitle
        title={`Gerenciar Webhook do Parceiro - ${partnerName}`}
        description={`Cadastre e gerencie um webhook para o parceiro.`}
      />

      <div>
        <Input
          label="URL"
          value={inputs[0].url.trim()}
          name="url"
          onChange={(e) => handleInputChange(0, "url", e.target.value)}
        />
        <Input
          label="Token Key"
          value={inputs[0].tokenKey}
          name="tokenKey"
          onChange={(e) => handleInputChange(0, "tokenKey", e.target.value)}
        />
        <Input
          label="Token Value"
          value={inputs[0].tokenValue}
          name="tokenValue"
          onChange={(e) => handleInputChange(0, "tokenValue", e.target.value)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          marginTop: "5px",
        }}
      >
        <Button
          onClick={handleConfirmClick}
          type="confirm"
          disabled={
            loading ||
            (partnerData !== null &&
              Object.keys(partnerData).length > 0 &&
              !isDataChanged)
          }
        >
          {loading
            ? "Aguarde..."
            : partnerData !== null && Object.keys(partnerData).length > 0
            ? "Editar"
            : "Cadastrar"}
        </Button>
        <div style={{ width: "5px" }} />
        <Button
          onClick={() => openDeleteWebhookModal(partnerData)}
          type="cancel"
          disabled={
            loading ||
            partnerData === null ||
            Object.keys(partnerData).length <= 0
          }
        >
          {loading ? "Aguarde..." : "Remover"}
        </Button>
        <div style={{ width: "5px" }} />
        <Button
          onClick={() => openHandleActiveModal(partnerData)}
          type={partnerData?.active ? "cancel" : "confirm"}
          disabled={
            loading ||
            partnerData === null ||
            Object.keys(partnerData).length <= 0
          }
        >
          {loading
            ? "Aguarde..."
            : partnerData?.active
            ? "Desativar"
            : "Ativar"}
        </Button>
      </div>
    </div>
  );
};

interface PartnerData {
  partner: string;
  partnerUsername: string;
  url: string;
  tokenKey: string;
  tokenValue: string;
  active: boolean;
}

async function baseHandleOnAddWebhookClick(
  openModal: OpenModal,
  partner: string,
  inputs: InputData[],
  partnerData: PartnerData | null
) {
  const { url, tokenKey, tokenValue } = inputs[0];
  const edit = partnerData != null;
  const body = { partner, url, tokenKey, tokenValue, edit };
  const response = await http.post(`${baseUrl}/api/v1/partner/webhook`, body);
  openModal(response.data.result);
}

async function baseFetchPartnerData(params: {
  partnerName: string;
  partnerUsername: string;
  openModal: OpenModal;
  setLoading: (loading: boolean) => void;
  setPartnerData: (data: PartnerData) => void;
  setInputs: (data: InputData[]) => void;
}) {
  const { setLoading, setInputs, setPartnerData, partnerUsername, openModal } =
    params;

  setLoading(true);

  try {
    const response = await http.get(
      `${baseUrl}/api/v1/partner/webhook?partnerName=${partnerUsername}`
    );

    const partnerData: PartnerData = response.data.result;

    setPartnerData(partnerData);
    setInputs([
      {
        tokenKey: partnerData?.tokenKey ? partnerData.tokenKey : "",
        tokenValue: partnerData?.tokenValue ? partnerData.tokenValue : "",
        url: partnerData?.url ? partnerData.url : "",
      },
    ]);
  } catch (e) {
    setLoading(false);
    openModal(getErrorMessage(e));
  } finally {
    setLoading(false);
  }
}

function WebhookDeleteModal(props: {
  webhook: PartnerData | null;
  openModal: OpenModal;
  closeModal: CloseModal;
  fetchPartnerData: () => void;
}) {
  const { webhook, openModal, closeModal, fetchPartnerData } = props;

  const [state, setState] = useState<{ loading: boolean }>({ loading: false });

  const handleOnConfirmButtonClick = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      if (webhook === null) throw new Error("Webhook não encontrado");

      await http.delete(
        `${baseUrl}/api/v1/partner/webhook?partnerName=${webhook.partner}&url=${webhook.url}`
      );
      setState((prev) => ({ ...prev, loading: false }));

      fetchPartnerData();

      openModal(<div className={styles.center}>O Webhook foi removido!</div>);
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  }, [webhook]);

  return (
    <div className={styles.center}>
      <p>Deseja realmente remover este webhook?</p>
      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button onClick={handleOnConfirmButtonClick} type="confirm">
            Confirmar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

function WebhookHandleActiveModal(props: {
  partner: string;
  webhook: PartnerData | null;
  openModal: OpenModal;
  closeModal: CloseModal;
  fetchPartnerData: () => void;
}) {
  const { partner, webhook, openModal, closeModal, fetchPartnerData } = props;

  const [state, setState] = useState<{ loading: boolean }>({ loading: false });

  const handleOnConfirmButtonClick = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const webhookData = { partner: partner, url: webhook?.url };

      // Endpoint diferente dependendo do valor de active
      const endpoint = webhook?.active
        ? `${baseUrl}/api/v1/partner/inactivate-webhook`
        : `${baseUrl}/api/v1/partner/activate-webhook`;

      await http.put(endpoint, webhookData);

      fetchPartnerData();

      openModal(
        <div className={styles.center}>
          O Webhook foi {webhook?.active ? "inativado" : "ativado"}!
        </div>
      );
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [webhook, partner]);

  return (
    <div className={styles.center}>
      <p>
        {webhook?.active
          ? "Deseja realmente inativar o webhook?"
          : "Deseja realmente ativar o webhook?"}
      </p>
      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button onClick={handleOnConfirmButtonClick} type="confirm">
            Confirmar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

export default PartnerWebhookPage;
