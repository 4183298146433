import { ReactNode } from "react";

export function TableResponsiveWrapper(props: { children: ReactNode }) {
  return (
    <div style={{ overflowX: "auto" }}>
      <div style={{ minWidth: "560px", maxHeight: "600px" }}>
        {props.children}
      </div>
    </div>
  );
}
