import styled from "styled-components";

const Pag = styled.div`
  display: inline-block;
  font-size: 20px;
  background-color: rgb(153, 21, 43);
  padding: 15px;
  margin-left: 5px;
  margin-right: 5px;
  color: white;
  cursor: pointer;
  :active {
    background-color: blue;
  }
`;

function Pagination(props) {
  const { itemsPerPage, totalItems, onChangePage } = props;
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div style={{ textAlign: "center", marginTop: "10px" }}>
      {pageNumbers.map((number) => {
        return (
          <Pag key={number} onClick={() => onChangePage(number)}>
            {number}
          </Pag>
        );
      })}
    </div>
  );
}

export default Pagination;
