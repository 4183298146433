import JsAsyncSelect from "../../../components/AsyncSelect2";

export interface Option<T> {
  id: string;
  name: string;
  addOns?: T;
}

export interface FetchDataResult<T> {
  options: Option<T>[];
  hasMore: boolean;
}

export type FetchData<T> = (
  search: string,
  offset?: number
) => Promise<FetchDataResult<T>>;

export interface Props<T> {
  placeholder?: string;
  fetchData?: FetchData<T>;
  filterData?: Option<T>[];
  onChange?: (option: Option<T> | null) => void;
  value?: Option<T> | null;
  label?: string;
}

function AsyncSelect<T>(props: Props<T>) {
  const { value, ...otherProps } = props;

  return (
    <JsAsyncSelect {...otherProps} value={value?.id} valueName={value?.name} />
  );
}

export default AsyncSelect;
