import Button from "../../form/Button"
import Input from "../../form/Input"
import { SectionTitle } from "../../ui/SectionTitle"
import usePartnerCouponConsumed from "../../../service/partner/usePartnerCouponConsumed"
import styles from "../../ui/ui.module.css"

export default function PartnerCouponReport() {

  const {
    loading, startMonth, endMonth, partnerCoupons, handleOnChange, handleOnFilterClick, showDetails, handleCsvDownloadOnClick
  } = usePartnerCouponConsumed()

  return (
    <div>
      <SectionTitle
        title="Relatório do Consumo de Notas Enviadas por Parceiros para Importar na SEFAZ"
        description="Acumulado de requests realizados por parceiros para importação e leitura de notas, e autenticação, no período."
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Input
            label="Mês Inicial"
            name="startMonth"
            value={ startMonth }
            onChange={ handleOnChange }
            type="month"
          />
          <Input
            label="Mês Final"
            name="endMonth"
            value={ endMonth }
            onChange={ handleOnChange }
            type="month"
          />
          <Button addInputLabelSpacing onClick={ () => handleOnFilterClick() } disabled={ !startMonth || !endMonth || loading } type="info">
            { loading ? "Carregando..." : "Buscar" }
          </Button>
        </div>
        <Button addInputLabelSpacing onClick={ () => handleCsvDownloadOnClick() } disabled={ !startMonth || !endMonth || loading } type="confirm">
          { loading ? "Carregando..." : "Baixar CSV" }
        </Button>
      </div>
      {
        !partnerCoupons ? null : (
          partnerCoupons.length === 0 ? (
            <div>
              Nenhuma nota consumida no período solicitado
            </div>
          ) : (
            <table className={ styles.table }>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th className={ `${ styles.fullWidth } ${ styles.left }` }>Parceiro</th>
                  <th>Importações Válidas</th>
                  <th>Importações Inválidas</th>
                  <th>Leituras Válidas</th>
                  <th>Leituras Inválidas</th>
                  <th>Autenticações</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  partnerCoupons.map((partnerCoupon) => (
                    <tr key={ partnerCoupon.partner }>
                      <td>
                        <Button small type="warning" onClick={ () => showDetails(partnerCoupon.detail) } transparent>
                          Detalhe
                        </Button>
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { partnerCoupon.partner }
                      </td>
                      <td>
                        { partnerCoupon.importValid }
                      </td>
                      <td>
                        { partnerCoupon.importInvalid }
                      </td>
                      <td>
                        { partnerCoupon.readValid }
                      </td>
                      <td>
                        { partnerCoupon.readInvalid }
                      </td>
                      <td>
                        { partnerCoupon.authRequests }
                      </td>
                      <td>
                        { partnerCoupon.importValid + partnerCoupon.importInvalid + partnerCoupon.readValid + partnerCoupon.readInvalid + partnerCoupon.authRequests }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          )
        )
      }
    </div>
  )
  
}