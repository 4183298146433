import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import useClientRequests, {
  FindById as FindClientById,
} from "../../service/client/useClientRequests";
import useFetchClientOptions from "../../service/client/useFetchClientOptions";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useQuery from "../../service/url/useQuery";
import { Option } from "../form/AsyncSelect";
import useFetchUserOptions from "../user/user/useFetchUserOptions";
import useUserRequests, {
  FindById as FindUserById,
} from "../user/user/useUserRequests";

export default function useDashboardAccessVisualizationPage() {
  const { findById: findClientById } = useClientRequests();
  const { findById: findUserById } = useUserRequests();

  const query = useQuery();

  const clientIdCheck = query.get("clientId") ? query.get("clientId") : null;

  const clientId =
    clientIdCheck && clientIdCheck !== "null" ? parseInt(clientIdCheck) : null;

  const userIdCheck = query.get("userId") ? query.get("userId") : null;

  const userId =
    userIdCheck && userIdCheck !== "null" ? parseInt(userIdCheck) : null;

  const startDate =
    query.get("startDate") && query.get("startDate") !== "null"
      ? query.get("startDate")
      : null;

  const finishDate =
    query.get("finishDate") && query.get("finishDate") !== "null"
      ? query.get("finishDate")
      : null;

  const [state, setState] = useState<State>({
    startDate,
    finishDate,
    selectedClient: null,
    selectedUser: null,
    totalPages: 1,
    pageSize: 20,
    items: null,
    loadingExcel: false,
  });

  const history = useHistory();

  const { openModal } = useModal();

  const { selectedClient, pageSize } = state;

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const form: Form = useMemo(
    () => ({
      page: page,
      pageSize: pageSize,
      clientId: clientId,
      userId: userId,
      startDate: startDate,
      finishDate: finishDate,
    }),
    [page, pageSize, clientId, userId, startDate, finishDate]
  );

  const fetchClientOpts = useFetchClientOptions();

  const handleOnChange = useHandleOnChange(setState);

  const handleClientOnChange = (selectedClient: Option<null> | null) =>
    baseHandleClientOnChange(setState, selectedClient);

  const fetchUserOpts = useFetchUserOptions({
    clientId: selectedClient ? parseInt(selectedClient.id) : undefined,
  });

  const handleUserOnChange = (selectedUser: Option<null> | null) =>
    baseHandleUserOnChange(setState, selectedUser);

  const findEventByUser = useCallback(
    () => baseFindEventByUser(openModal, setState, form),
    [form, page]
  );

  const handlePageOnChange = useCallback(
    (page: number) =>
      baseHandlePageOnChange(
        history,
        page,
        clientId,
        userId,
        startDate,
        finishDate
      ),
    [clientId, userId, startDate, finishDate]
  );

  const handleOnFilterClick = useCallback(
    () => baseHandleOnFilterClick(history, state),
    [state]
  );

  const onDownloadExcelClick = useCallback(
    () => baseOnDownloadExcelClick(openModal, setState, state),
    [openModal, state]
  );

  useEffect(() => {
    fetchSelectedClientEffect(findClientById, clientId, setState);
  }, [clientId]);

  useEffect(() => {
    fetchSelectedUserEffect(findUserById, userId, setState);
  }, [userId]);

  useEffect(() => {
    findEventByUser();
  }, [form, page]);

  return {
    ...state,
    form,
    page,
    handleOnChange,
    fetchClientOpts,
    handleClientOnChange,
    fetchUserOpts,
    handleUserOnChange,
    handlePageOnChange,
    handleOnFilterClick,
    onDownloadExcelClick,
  };
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

interface Form {
  startDate: string | null;
  finishDate: string | null;
  clientId: number | null;
  userId: number | null;
  pageSize: number;
  page: number;
}

interface DashboardVisualization {
  enteredAt: string;
  leftAt: string;
  username: string;
  client: string;
  dashboard: string;
}

interface State {
  startDate: string | null;
  finishDate: string | null;
  selectedClient: Option<null> | null;
  selectedUser: Option<null> | null;
  pageSize: number;
  totalPages: number;
  items: DashboardVisualization[] | null;
  loadingExcel: boolean;
}

function baseHandleClientOnChange(
  setState: (action: SetStateAction<State>) => void,
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    selectedClient: option,
    selectedUser: null,
  }));
}

function baseHandleUserOnChange(
  setState: (action: SetStateAction<State>) => void,
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    selectedUser: option,
  }));
}

async function baseFindEventByUser(
  openModal: OpenModal,
  setState: SetState,
  form: Form
) {
  try {
    setState((prev) => ({ ...prev, totalPages: 1, items: null }));

    const response = await http.post(
      `${baseUrl}/api/v1/dashboard/access-report`,
      form
    );

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseOnDownloadExcelClick(
  openModal: OpenModal,
  setState: SetState,
  state: State
) {
  try {
    const form = {
      clientId: state.selectedClient?.id,
      userId: state.selectedUser?.id,
      startDate: state.startDate,
      finishDate: state.finishDate,
    };

    setState((prev) => ({ ...prev, loadingExcel: true }));
    const { data } = await http.post(
      `${baseUrl}/api/v1/dashboard/access-report/csv`,
      form,
      { responseType: "blob" }
    );

    const url = window.URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "access-report.csv");
    link.click();
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingExcel: false }));
  }
}

function baseHandlePageOnChange(
  history: History,
  page: number,
  clientId: number | null,
  userId: number | null,
  startDate: string | null,
  finishDate: string | null
) {
  history.push(
    "/dashboards/access-report/" +
      page +
      "?clientId=" +
      clientId +
      "&userId=" +
      userId +
      "&startDate=" +
      startDate +
      "&finishDate=" +
      finishDate
  );
}

function baseHandleOnFilterClick(history: History, state: State) {
  const params = new URLSearchParams();

  if (state.selectedClient) {
    params.append("clientId", state.selectedClient.id);
  }
  if (state.selectedUser) {
    params.append("userId", state.selectedUser.id);
  }
  if (state.startDate) {
    params.append("startDate", state.startDate);
  }
  if (state.finishDate) {
    params.append("finishDate", state.finishDate);
  }

  history.push("/dashboards/access-report/1?" + params.toString());
}

async function fetchSelectedClientEffect(
  findById: FindClientById,
  clientId: number | null,
  setState: SetState
) {
  if (!clientId) {
    return;
  }
  const client = await findById(clientId);
  setState((prev) => ({
    ...prev,
    selectedClient: { id: client.id + "", name: client.name },
  }));
}

async function fetchSelectedUserEffect(
  findById: FindUserById,
  userId: number | null,
  setState: SetState
) {
  if (!userId) {
    return;
  }
  const user = await findById(userId);
  setState((prev) => ({
    ...prev,
    selectedUser: { id: user.id + "", name: user.username },
  }));
}
