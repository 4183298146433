import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

interface eanToModerate {
  ean: string;
  token: string;
}

export interface ProductItemView {
  auntCategoryId: number;
  auntCategoryDescription: string;
  productId: number;
  ean: string;
  sku: string;
  quantityUnit: number;
  quantityPack: number;
  unitMeasurement: string;
  nielsenId: number;
  nielsenDescription: string;
  categoryId: number;
  categoryDescription: string;
  manufacturerId: number;
  manufacturerDescription: string;
  brandId: number;
  brandName: string;
  updateDate: Date;
  status: string;
}

export type ProductSearchView = {
  items: Array<ProductItemView>;
  total: number;
  page: number;
};

export interface ProductFilterForm {
  eans: Array<string> | undefined;
  ids: Array<string> | undefined;
  nielsenIds: Array<number> | undefined;
  productCategoryIds: Array<number> | undefined;
  manufacturerIds: Array<number> | undefined;
  brandIds: Array<number> | undefined;
  page: number;
  pageSize: number;
}

export interface ProductFilterCsvDownloadForm {
  eans: Array<string> | undefined;
  ids: Array<string> | undefined;
  nielsenIds: Array<number> | undefined;
  productCategoryIds: Array<number> | undefined;
  manufacturerIds: Array<number> | undefined;
  brandIds: Array<number> | undefined;
}

export type UseProductRequests = typeof useProductRequests;
export type FindProduct = ReturnType<UseProductRequests>["findProduct"];
export type DownloadCsv = ReturnType<UseProductRequests>["downloadCsv"];
export type FetchProductQueue =
  ReturnType<UseProductRequests>["fetchProductQueue"];

export default function useProductRequests() {
  const findProduct = (params?: ProductFilterForm) => baseFindProduct(params);

  const downloadCsv = (params?: ProductFilterCsvDownloadForm) =>
    baseDownloadCsv(params);

  const updateProductWithCsv = (file: File) => baseUpdateProductWithCsv(file);

  const fetchProductQueue = () => baseFetchProductQueue();

  return {
    findProduct,
    downloadCsv,
    updateProductWithCsv,
    fetchProductQueue,
  };
}

async function baseFindProduct(
  params?: ProductFilterForm
): Promise<ProductSearchView> {
  const response = await http.post(`${baseUrl}/api/v1/product/search`, params);
  return response.data;
}

async function baseDownloadCsv(
  params?: ProductFilterCsvDownloadForm
): Promise<string> {
  const response = await http.post(
    `${baseUrl}/api/v1/product/download/products.csv`,
    params
  );
  return response.data;
}

async function baseUpdateProductWithCsv(file: File): Promise<void> {
  const formData = new FormData();
  formData.append("file", file);
  await http.post(`${baseUrl}/api/v1/product/update-csv`, formData, {});
}

async function baseFetchProductQueue(): Promise<eanToModerate> {
  const response = await http.get(baseUrl + `/api/v1/products/queue`);
  return response?.data;
}
