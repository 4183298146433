import { mediaPrefix } from "../../../../constants";
import useProductBrandManagementPage from "../../../service/product/useProductBrandManagementPage";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";


export default function ProductBrandManagementPage() {

  const {
    handleFindOnSubmit, handleOnChange, handlePageOnChange, filter, items, totalPages, page, handleEditOnClick, handleOnDeleteClick, emptyProductBrand
  } = useProductBrandManagementPage();

  return (
    <div>
      <SectionTitle title="Listagem de Marcas" description="Consulta, edição e criação de marcas de produtos." />
      
      <form onSubmit={ handleFindOnSubmit }>
        <Input
          onChange={ handleOnChange }
          value={ filter }
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre a marca pelo nome"
          addons={
            [ <Button key="1" type="info" submit>Pesquisar</Button> ]
          }
        />
      </form>
      <div>
        <Button onClick={ () => handleEditOnClick(emptyProductBrand) } type="confirm">Criar Marca</Button>
        <Spacer ratio={ 2/3 } />
      </div>
      <TableResponsiveWrapper>
        <table className={ styles.table }>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Id</th>
              <th>Imagem</th>
              <th className={ `${ styles.fullWidth } ${ styles.left }` }>Nome</th>
              <th>Marca Própria</th>
              <th>Grupo Econômico</th>
              <th>Fabricante</th>
              <th>Qtd. Marcas</th>
            </tr>
          </thead>
          <tbody>
            {
              !items ? (
                <tr><td colSpan={ 7 }>Carregando....</td></tr>
              ) : (
                items.length === 0 ? (
                  <tr><td colSpan={ 7 }>Sem resultados</td></tr>
                ) : (
                  items.map(item => (
                    <tr key={ item.id }>
                      <td>
                        <Button small type="warning" onClick={ () => handleEditOnClick(item) }>
                          Editar
                        </Button> { ' ' }
                        <Button onClick={ () => handleOnDeleteClick(item) } disabled={ item.productQtt! > 0 } type="cancel" small>
                          Apagar
                        </Button>
                      </td>
                      <td>
                        { item.id }
                      </td>
                      <td>
                        <div className={ styles.imageWrapper }>
                          {
                            item.file 
                              ? <img src={mediaPrefix + item.file} alt="Logo da Marca" />
                              : <span className={ styles.imageMissing }>Sem<br />imagem</span>
                          }
                        </div>
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { item.name || "Não Informado" }
                      </td>
                      <td>
                        { item.ownBrand ? "sim" : "não" }
                      </td>
                      <td>
                        { item.economicGroup || "Não Informado" }
                      </td>
                      <td>
                        { item.manufacturer?.name || "Não Informado" }
                      </td>
                      <td>
                        { item.productQtt || 0 }
                      </td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}