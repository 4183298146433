import styles from "./ui.module.css";

export function SectionTitle(props: { title: string, description: string }) {
  return (
    <div>
      <h3 className={ styles.sectionTitle }>
        { props.title }
      </h3>
      <p className={ styles.sectionTitleDescription }>
        { props.description }
      </p>
    </div>
  )
}