import { useEffect, useState } from "react";
import styled from "styled-components";
import Card from "../components/Card";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Table from "../components/Table";
import { SubTitle, Title } from "../components/Titles";
import useClientCompany from "../hooks/useClientCompany";
import useHorusAccess from "../hooks/useHorusAccess";
import { isEmptyObj } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const Wrapper = styled.div``;

const breadcrump = [
  {
    name: "Início",
    path: "/dashboard",
  },
  {
    name: "Relatório de Acessos",
    path: "/events",
  },
];

function downloadTableAsCsv(table) {
  // Select rows from table_id
  let rows = table.querySelectorAll("tr");
  // Construct csv
  let csv = [];
  for (let i = 0; i < rows.length; i++) {
    let row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(";"));
  }
  let csv_string = csv.join("\n");
  // Download it
  let link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  let d = new Date();
  let filename =
    "eventsLog-" +
    d.getDate() +
    "-" +
    (d.getMonth() + 1) +
    "-" +
    d.getFullYear() +
    ".csv";
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

//show utilization of the dashboard by dashboard, company and user.
function EventsPage() {
  const { getAllClientCompanies } = useClientCompany();
  const {
    getDashboardDetailedAccessHistory,
    getCompanyUsersLastUse,
    getAccessHistoryByTypeValue,
    createEvent,
  } = useHorusAccess();
  const [events, setEvents] = useState(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [companies, setCompanies] = useState(null);
  const [companyUsers, setCompanyUsers] = useState({});
  const [error, setError] = useState(null);
  const [event] = useState({});

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access events";

    createEvent(event);
  }, []);

  useEffect(() => {
    if (!companies) {
      getAllClientCompanies().then((response) => {
        setCompanies(response.data);
      });
    }
  }, [companies, setCompanies, getAllClientCompanies]);

  const onChangeCompany = async (companyId) => {
    setLoadingUsers(true);
    setCompanyUsers(null);
    setEvents(null);
    if (companyId !== "Selecione...") {
      try {
        const userResponse = await getCompanyUsersLastUse(companyId);
        setCompanyUsers(userResponse.data);
      } catch (error) {
        let err;
        err =
          error.response && error.response.status === 500
            ? new Error(
                "Ocorreu um erro em nossos servidores. Por favor, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
              )
            : err;
        setError(err);
      }
    }
    setLoadingUsers(false);
  };

  const onChangeUser = async (userId) => {
    setEvents(null);
    setLoadingEvents(true);
    try {
      const dashResponse = await getDashboardDetailedAccessHistory(userId);
      setEvents(dashResponse.data);
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    }
    setLoadingEvents(false);
  };

  const onClickAnchor = async (dict) => {
    document.getElementById("companySel").value = "Selecione...";
    setCompanyUsers(null);
    setEvents(null);
    setLoadingEvents(true);
    try {
      const dashResponse = await getAccessHistoryByTypeValue(
        Object.keys(dict)[0],
        Object.values(dict)[0]
      );
      setEvents(dashResponse.data);
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    }
    setLoadingEvents(false);
  };

  return (
    <>
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Registro de Eventos</Title>
      <Wrapper>
        <label>Empresa </label>
        <select
          id="companySel"
          onChange={(e) => {
            onChangeCompany(e.target.value);
          }}
        >
          <option defaultValue value="Selecione...">
            Selecione...
          </option>
          {!isEmptyObj(companies)
            ? companies.map((company, index) => {
                return (
                  <option key={index} value={company.id}>
                    {company.name}
                  </option>
                );
              })
            : null}
          <option value="0">Outros</option>
        </select>
        {!loadingUsers ? (
          !isEmptyObj(companyUsers) ? (
            <>
              <SubTitle>Usuários</SubTitle>
              <Table>
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th>Último evento</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {companyUsers.map((user, index) => {
                    return (
                      <tr key={index}>
                        <td>{user.username}</td>
                        <td>
                          {user.date
                            .replace("T", " ")
                            .replace(/(\.[a-zA-Z0-9-+-:]+)/, "")}
                        </td>
                        <td>
                          <a
                            href="#events"
                            style={{
                              cursor: "pointer",
                              textDecorationLine: "underline",
                            }}
                            onClick={(e) => {
                              onChangeUser(user.userId);
                            }}
                          >
                            Selecionar
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : null
        ) : (
          <Card>
            <Loading style={{ margin: "20px auto" }} />
          </Card>
        )}
        {!loadingEvents ? (
          !isEmptyObj(events) ? (
            <>
              <SubTitle id="events">Eventos</SubTitle>
              <button
                onClick={(e) => {
                  downloadTableAsCsv(document.querySelector("#eventsTable"));
                }}
              >
                download
              </button>
              <Table id="eventsTable">
                <thead>
                  <tr>
                    <th>Id do Usuário</th>
                    <th>Categoria</th>
                    <th>Dashboard</th>
                    <th>Ocorrência</th>
                    <th>Aba</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {events.map((event, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              onClickAnchor({ user_id: event.userId });
                            }}
                          >
                            {event.username}
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={(e) => {
                              onClickAnchor({ category: event.category });
                            }}
                          >
                            {event.category}
                          </button>
                        </td>
                        {event.category === "report" ? (
                          event.action === "view" ? (
                            <>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ dashboard: event.label });
                                  }}
                                >
                                  {event.label}
                                </button>
                              </td>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ action: event.action });
                                  }}
                                >
                                  Acessou a dashboard
                                </button>
                              </td>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ action: event.action });
                                  }}
                                >
                                  Aba principal
                                </button>
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ dashboard: event.action });
                                  }}
                                >
                                  {event.action}
                                </button>
                              </td>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ action: event.action });
                                  }}
                                >
                                  Mudou de aba
                                </button>
                              </td>
                              <td>
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ label: event.label });
                                  }}
                                >
                                  {event.label}
                                </button>
                              </td>
                            </>
                          )
                        ) : (
                          <>
                            <td>
                              {event.label ? (
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ dashboard: event.label });
                                  }}
                                >
                                  {event.label}
                                </button>
                              ) : null}
                            </td>
                            <td>
                              <button
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  onClickAnchor({ action: event.action });
                                }}
                              >
                                {event.action}
                              </button>
                            </td>
                            <td>
                              {event.label ? (
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    onClickAnchor({ label: event.label });
                                  }}
                                >
                                  {event.label}
                                </button>
                              ) : null}
                            </td>
                          </>
                        )}
                        <td>
                          <button
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              onClickAnchor({
                                date: event.date
                                  .replace("T", " ")
                                  .replace(/(\.[a-zA-Z0-9-+-:]+)/, ""),
                              });
                            }}
                          >
                            {event.date
                              .replace("T", " ")
                              .replace(/(\.[a-zA-Z0-9-+-:]+)/, "")}
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : null
        ) : (
          <Card>
            <Loading style={{ margin: "20px auto" }} />
          </Card>
        )}
      </Wrapper>
    </>
  );
}

export default EventsPage;
