import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../../api/http";
import { AuthUserModel } from "../../../../authentication/login";
import { baseUrl } from "../../../../constants";
import { authCredentials } from "../../../../utils/authCredentials";
import useFetchClientOptions from "../../../service/client/useFetchClientOptions";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import { FetchDataResult, Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import { HSpacer } from "../../ui/HSpacer";
import { Group, UserForm, UserView } from "../UserInterfaces";
import useFetchUserOptions from "./useFetchUserOptions";
import useUserRequests, { SendWelcomeEmail } from "./useUserRequests";

/**
 * Hook com funções e variáveis usadas na página de criação de usuário
 */
export default function useUserCreate(edit: boolean) {
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();
  const moderator = authCredentials.get.authUser();

  const { openModal, closeModal } = useModal();

  const [state, setState] = useState(initialState);

  const { sendWelcomeEmail } = useUserRequests();

  const {
    form,
    selectedClient,
    sendingUser,
    selectedEditClient,
    selectedEditUser,
    selectedGroups,
    fetchingUser,
    enabled,
    sendingWelcomeEmail,
  } = state;

  const fetchUserOpts = useFetchUserOptions({
    clientId: selectedEditClient ? parseInt(selectedEditClient.id) : undefined,
  });

  const fetchClientOpts = useFetchClientOptions();

  const handleFetchClientIpts = useCallback(
    () => baseHandleFetchClientOpts(fetchClientOpts),
    [fetchClientOpts]
  );

  const handleFormOnChange = useCallback(
    (e: ChangeEvent) => baseHandleFormOnChange(setState, e),
    [setState]
  );

  const handleCheckOnClick = useCallback(
    () => baseHandleCheckOnClick(setState),
    [setState]
  );

  const handleClientOnChange = useCallback(
    (o: Option<null> | null) => baseHandleClientOnChange(setState, o),
    [setState]
  );

  const fetchUser = (id: number) => baseFetchUser(id);

  const createUser = (form: UserForm) => baseCreateUser(form);

  const handleSendButtonOnClick = useCallback(
    () =>
      baseHandleSendButtonOnClick(
        createUser,
        setState,
        state,
        openModal,
        history,
        moderator
      ),
    [state, moderator]
  );

  const handleEditClientOnChange = useCallback(
    (selected: Option<null> | null) =>
      baseHandleEditClientOnChange(setState, selected),
    [setState]
  );

  const handleEditUserOnChange = useCallback(
    (selected: Option<null> | null) =>
      baseHandleEditUserOnChange(history, setState, selected),
    [history, setState]
  );

  const handleOnSelectedGroupsChange = useCallback(
    (selectedGroups: Array<Group> | null) =>
      setState((prev) => ({ ...prev, selectedGroups })),
    [setState]
  );

  const handleToggleUserActivationOnClick = useCallback(
    () =>
      baseToggleUserActivationOnClick(setState, selectedEditUser, openModal),
    [selectedEditUser, openModal]
  );

  const handleOnSendWelcomeEmailConfirmClick = useCallback(
    () =>
      baseHandleOnSendWelcomeEmailConfirmClick(
        sendWelcomeEmail,
        setState,
        selectedEditUser,
        openModal
      ),
    [sendWelcomeEmail, setState, selectedEditUser, openModal]
  );

  const handleOnSendWelcomeEmailClick = useCallback(
    () =>
      baseHandleOnSendWelcomeEmailClick(
        openModal,
        closeModal,
        handleOnSendWelcomeEmailConfirmClick
      ),
    [openModal, closeModal, handleOnSendWelcomeEmailConfirmClick]
  );

  const validation = useMemo(() => validate(state), [state]);

  const buttonText = useMemo(
    () => getButtonText(validation, edit, sendingUser),
    [validation, sendingUser, edit]
  );

  useEffect(() => loadUserEffect(fetchUser, setState, openModal, id), [id]);

  const showForm = !edit || selectedClient?.id;

  return {
    moderator,
    form,
    selectedClient,
    sendingUser,
    selectedEditClient,
    selectedEditUser,
    selectedGroups,
    fetchingUser,
    enabled,
    handleFetchClientIpts,
    handleFormOnChange,
    handleClientOnChange,
    handleSendButtonOnClick,
    fetchUserOpts,
    fetchClientOpts,
    handleEditClientOnChange,
    handleEditUserOnChange,
    handleOnSelectedGroupsChange,
    handleToggleUserActivationOnClick,
    validation,
    buttonText,
    showForm,
    sendWelcomeEmail,
    handleOnSendWelcomeEmailClick,
    handleCheckOnClick,
    sendingWelcomeEmail,
  };
}

/**
 * Tipo do objeto histórico
 */
type History = ReturnType<typeof useHistory>;

/**
 * Tipo do objeto que contém os dados de validação do formulário
 */
interface Validation {
  clientId: string[];
  name: string[];
  email: string[];
  errors: number;
}

/**
 * Tipo do estado do componente.
 */
interface State {
  form: {
    name: string | null;
    email: string | null;
    staff: boolean;
    role: string | null;
    sendEmail: boolean;
  };
  selectedClient: null | Option<null>;
  selectedEditClient: null | Option<null>;
  selectedEditUser: null | Option<null>;
  selectedGroups: null | Array<Group>;
  sendingUser: boolean;
  fetchingUser: boolean;
  sendingWelcomeEmail: boolean;
  enabled: boolean;
}

const initialState: State = {
  form: {
    name: null,
    email: null,
    staff: false,
    role: null,
    sendEmail: true,
  },
  selectedClient: null,
  selectedEditClient: null,
  selectedEditUser: null,
  selectedGroups: null,
  sendingUser: false,
  fetchingUser: false,
  sendingWelcomeEmail: false,
  enabled: true,
};

type ChangeEvent = { target: { value?: any; name?: any } };

type FetchUser = (id: number) => Promise<UserView>;

type CreateUser = (form: UserForm) => Promise<UserView>;

function baseHandleFetchClientOpts(
  fetchClientOpts: (
    filter: string,
    offset: number | undefined
  ) => Promise<FetchDataResult<null>>
) {
  return fetchClientOpts;
}

/**
 * Um callback onChange que irá atualizar no form o atributo com o nome igual
 * ao name do input alterado
 */
function baseHandleFormOnChange(
  setState: (action: SetStateAction<State>) => void,
  e: ChangeEvent
) {
  setState((prev) => ({
    ...prev,
    form: {
      ...prev.form,
      [e.target.name]: e.target.value,
    },
  }));
}

/**
 * Um callback onCheck que irá atualizar no form o atributo com o nome igual
 * ao name do checkbox clicado
 */
function baseHandleCheckOnClick(
  setState: (action: SetStateAction<State>) => void
) {
  setState((prev) => ({
    ...prev,
    form: {
      ...prev.form,
      sendEmail: !prev.form.sendEmail,
    },
  }));
}

/**
 * Um callback onChange para atualizar o estado com a opção selecionada no AsyncSelect
 */
function baseHandleClientOnChange(
  setState: (action: SetStateAction<State>) => void,
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    selectedClient: option,
    form: { ...prev.form, staff: false, role: null },
  }));
}

/**
 * Valida os dados do formulário
 */
function validate(state: State): Validation {
  const validation: Validation = {
    clientId: [],
    name: [],
    email: [],
    errors: 0,
  };

  if (!state.form.name || !state.form.name.match(/^.+( .+)+$/)) {
    validation.name.push("O nome e o sobrenome são obrigatórios");
    validation.errors++;
  }
  if (!state.selectedClient?.id) {
    validation.clientId.push("A empresa é obrigatória");
    validation.errors++;
  }
  if (
    !state.form.email ||
    !state.form.email.match(
      /^[a-zA-Z0-9-_+]+(\.[a-zA-Z0-9-_+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
    )
  ) {
    validation.email.push("Email é obrigatório");
    validation.errors++;
  }

  return validation;
}

/**
 * Salva um usuário na api
 */
async function baseCreateUser(form: UserForm): Promise<UserView> {
  if (form?.id) {
    const response = await http.put(`${baseUrl}/api/v1/user`, form);
    return response?.data;
  }

  const response = await http.post(`${baseUrl}/api/v1/user`, form);
  return response?.data;
}

/**
 * On click do botão de enviar, tenta salvar o usuário, redireciona pra página de edição e mostra um modal de sucesso.
 * Em caso de falha, mostra um modal com o erro.
 */
async function baseHandleSendButtonOnClick(
  createUser: CreateUser,
  setState: (action: SetStateAction<State>) => void,
  state: State,
  openModal: OpenModal,
  history: History,
  moderator: AuthUserModel | null
) {
  if (
    ["HORUS", "HORUS - FREELANCER"].includes(
      state.selectedClient?.name || ""
    ) &&
    moderator?.role !== "ADMIN"
  ) {
    openModal("Você não tem autorização para criar um usuário interno");
    return;
  }

  const role =
    state.selectedClient?.name !== "HORUS" &&
    state.selectedClient?.name !== "HORUS - FREELANCER"
      ? "ADMIN"
      : state.form.role;

  const fullName = state?.form?.name?.trim();

  const form: UserForm = {
    id: state.selectedEditUser ? parseInt(state.selectedEditUser.id) : null,
    firstName: fullName?.split(" ")?.[0] ?? "",
    lastName: fullName?.split(" ")?.[1] ?? "",
    email: state?.form?.email?.toLowerCase() ?? "",
    role: role ?? "",
    groups: state?.selectedGroups?.map((g) => g.id)!,
    staff:
      state?.selectedClient?.name?.toUpperCase() === "HORUS" ||
      state.selectedClient?.name?.toUpperCase() === "HORUS - FREELANCER",
    active: state?.enabled,
    clientName: state?.selectedClient?.name ?? "",
    clientId: state?.selectedClient ? parseInt(state.selectedClient?.id) : null,
  };

  setState((prev) => ({ ...prev, sendingUser: true }));

  try {
    const result = await createUser(form);

    history.push(`/user/edit/${result?.id}`);

    openModal("Parceiro criado com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingUser: false }));
  }
}

/**
 * Retorna o texto que vai ficar no botão de enviar
 */
function getButtonText(
  validation: Validation,
  edit: boolean,
  loading: boolean
) {
  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1
      ? `Existe 1 erro`
      : `Existem ${validation.errors} erros`;
  }

  return !edit ? "Criar" : "Editar";
}

async function baseFetchUser(id: number): Promise<UserView> {
  const response = await http.get(`${baseUrl}/api/v1/user/edit/${id}`);
  return response.data;
}

/**
 * Recupera da api o usuário selecionado para editar
 * e preenche o formulário de edição com ela.
 * Caso o usuário saia da página de editar pra página de criar esse mesmo
 * effect irá limpar o formulário.
 */
function loadUserEffect(
  fetch: FetchUser,
  setState: (action: SetStateAction<State>) => void,
  openModal: OpenModal,
  id?: string
) {
  const fn = async () => {
    if (!id) {
      setState(initialState);
      return;
    }

    setState((prev) => ({ ...prev, fetchingUser: true }));

    try {
      const resp = await fetch(parseInt(id));

      const form = {
        name: resp.firstName + " " + resp.lastName,
        email: resp.email,
        staff: resp.staff,
        active: resp.active,
        role: resp.role,
        sendEmail: true,
      };

      const selectedEditClient = !resp.clientId
        ? null
        : {
            id: "" + resp.clientId,
            name: resp.clientName!,
          };

      const selectedEditUser = {
        id,
        name: resp.firstName + " " + resp.lastName,
      };

      const selectedClient = !resp.clientId
        ? null
        : {
            id: "" + resp.clientId,
            name: resp.clientName!,
          };

      const selectedGroups = resp.groups.length === 0 ? null : resp.groups;

      setState((prev) => ({
        ...prev,
        form,
        selectedEditClient,
        selectedEditUser,
        selectedClient,
        selectedGroups,
        fetchingUser: false,
        enabled: resp.active,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  };

  fn();
}

/**
 * Trata o evento de selecionar a empresa onde está o usuário quer editar
 */
function baseHandleEditClientOnChange(
  setState: (action: SetStateAction<State>) => void,
  selectedEditClient: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    form: { ...prev.form, email: null, name: null, role: null, staff: false },
    selectedClient: null,
    selectedEditClient,
    selectedEditUser: null,
    selectedGroups: null,
  }));
}

/**
 * Trata o evento de selecionar o usuário que quer editar
 */
function baseHandleEditUserOnChange(
  history: History,
  setState: (action: SetStateAction<State>) => void,
  selectedEditUser: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedEditUser }));
  history.push(
    "/user/edit" + (selectedEditUser ? "/" + selectedEditUser.id : "")
  );
}

async function baseToggleUserActivationOnClick(
  setState: (action: SetStateAction<State>) => void,
  selectedEditUser: Option<null> | null,
  openModal: OpenModal
) {
  try {
    const editUser = selectedEditUser ? parseInt(selectedEditUser?.id) : null;

    await http.put(`${baseUrl}/api/v1/user/${editUser}/toggle-activation`);

    setState((prev) => ({ ...prev, enabled: !prev.enabled }));

    openModal("Ativação do usuário alterada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseHandleOnSendWelcomeEmailConfirmClick(
  sendWelcomeEmail: SendWelcomeEmail,
  setState: (action: SetStateAction<State>) => void,
  selectedEditUser: Option<null> | null,
  openModal: OpenModal
) {
  if (!selectedEditUser) return;

  try {
    setState((prev) => ({ ...prev, sendingWelcomeEmail: true }));

    await sendWelcomeEmail(parseInt(selectedEditUser.id));

    openModal("Email de Boas Vindas enviado com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingWelcomeEmail: false }));
  }
}

async function baseHandleOnSendWelcomeEmailClick(
  openModal: OpenModal,
  closeModal: CloseModal,
  onConfirm: () => void
) {
  const closeModalAndConfirm = () => {
    closeModal();
    onConfirm();
  };

  openModal(
    <div style={{ textAlign: "center" }}>
      Confirma enviar o e-mail de boas vindas para o usuário?
      <br />
      <br />
      <Button type="confirm" onClick={closeModalAndConfirm}>
        Sim
      </Button>
      <HSpacer />
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}
