import { createContext, useContext } from "react";
import { useLocation } from "react-router";
import { Menu } from "../../types";

export interface GlobalContextProps {
  menus: Menu[] | null,
  setMenus: (menus: Menu[] | null) => void
}

export const initialProps: GlobalContextProps = {
  menus: null,
  setMenus: () => { throw new Error("Erro") }
}

export const GlobalContext = createContext(initialProps);

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export function useMenu(pathArgument?: string): Menu {

  const { menus } = useContext(GlobalContext);

  const location = useLocation();

  const path = pathArgument || location.pathname;

  const defaultMenu = { name: "Desconhecido", url: path, subMenus: [] };

  return menus ? menus.find(m => m.url.startsWith(path)) || defaultMenu : defaultMenu;

}