import { useCallback } from "react";
import { http } from "../api/http";
import { baseUrl } from "../constants";
import useModal from "../hooks/useModal";

function usePlans() {
  const { createModal } = useModal();

  const getPlansByCompId = (compId) =>
    http.get(baseUrl + "/api/v1/plan-company/" + compId);

  const getPlansByCompanyIdAndTypeId = (companyId, type) =>
    http.get(baseUrl + "/api/v1/plan/" + companyId + "/" + type);

  const getClients = async (search, offset) => {
    try {
      const body = {
        params: {
          search,
          offset,
          limit: 50,
        },
      };
      const { data: clients } = await http.get(
        baseUrl + `/client-company/description`,
        { params: { ...body.params } }
      );
      return {
        options: clients.map((c) => ({
          id: c.id,
          name: c.name,
        })),
        hasMore: clients.length === 10,
      };
    } catch (e) {
      console.log(e.message);
    }
  };

  const getPlans = async (compId) => {
    if (!compId) return;
    const { data } = await getPlansByCompId(compId);
    return data;
  };

  const getManufacturers = async function (search, offset) {
    const { data: manufacturers } = await http.get(
      baseUrl + `/api/v1/products/manufacturers`,
      {
        params: {
          search,
          offset,
          limit: 10,
        },
      }
    );
    return {
      options: manufacturers.map((m) => ({ id: m.id, name: m.name })),
      hasMore: manufacturers.length === 10,
    };
  };

  const getManufacturer = async function (manufacturerId) {
    const { data: manufacturer } = await http.get(
      baseUrl + `/api/v1/products/manufacturer/${manufacturerId}`
    );
    return manufacturer;
  };

  const getCategoriesPowerBi = async () => {
    const { data } = await http.get(
      baseUrl + `/api/v1/products/child-categories`
    );
    return data;
  };

  const getFgvCategories = async () => {
    const { data } = await http.get(
      baseUrl + `/api/v1/checkout/fgv/categories`
    );
    return data;
  };

  const getMetricsCategories = async () => {
    const { data } = await http.get(
      baseUrl + `/api/v1/checkout/metricsweb/categories`
    );
    return data;
  };

  const getPaymentCollection = async (id) => {
    try {
      const { data } = await http.get(baseUrl + `/api/v1/vindi/${id}`);
      return data;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>
            Ocorreu um erro ao buscar informações sobre cobranças externas. Não
            será possível prosseguir com a atualização
          </p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const createPlan = useCallback(async (userId, plan) => {
    try {
      const { data } = await http.post(
        baseUrl + `/api/v1/plan/${userId}`,
        plan
      );
      createModal(
        <div className="align-center">
          <p>Assinatura criada com sucesso.</p>
          <br />
          <br />
        </div>,
        { replace: true }
      );
      return data;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar criar a assinatura.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  });

  const updatePlan = async (userId, plan) => {
    try {
      const { data } = await http.put(`${baseUrl}/api/v1/plan/${userId}`, plan);
      createModal(
        <div className="align-center">
          <p>Assinatura atualizada com sucesso.</p>
          <p>
            ATENÇÃO: Segmentações associadas à assinatura poderão necessitar de
            atualização.
          </p>
          <br />
          <br />
        </div>,
        { replace: true }
      );
      return data;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar atualizar a assinatura.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  };

  const cancelPlan = async (userId, plan) => {
    try {
      const { data } = await http.put(
        `${baseUrl}/api/v1/plan/cancel/${userId}`,
        plan
      );
      createModal(
        <div className="align-center">
          <p>Assinatura cancelada com sucesso.</p>
          <p>
            ATENÇÃO: Assinaturas com recorrência de pagamento externo terão as
            faturas canceladas, mesmo que estejam em inadimplência.
          </p>
          <p>
            Verificar com setor financeiro a melhor maneira de realizar
            cobranças pendentes, caso necessário.
          </p>
          <br />
          <br />
        </div>,
        { replace: true }
      );
      return data;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar cancelar a assinatura.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  };

  return {
    getClients,
    getPlans,
    createPlan,
    getPlansByCompId,
    updatePlan,
    cancelPlan,
    getPlansByCompanyIdAndTypeId,
    getCategoriesPowerBi,
    getManufacturers,
    getManufacturer,
    getFgvCategories,
    getMetricsCategories,
    getPaymentCollection,
  };
}

export default usePlans;
