import React from 'react'
import { UF } from "../../../constants"
import AsyncSelect2 from "../../AsyncSelect2"
import Input from '../../Input'

function ProfessionalPlan({ plan, setPlan, getManufacturers, childCategories, manufacturerName, setManufacturerName }) {

  return (
    <div>
      { childCategories && <Input
        label="Categoria"
        value={ plan?.params?.categories }
        placeholder="Selecione..."
        onChange={ options => { setPlan( plan => {
          if (options.includes("all")) {
              return { ...plan, params: { ...plan.params, categories: childCategories.map(c => c.id.toString()) } }
          }
          if (options.includes("remove all")) {
              return { ...plan, params: { ...plan.params, categories: [] } }
          }
          return { ...plan, params: { ...plan.params, categories: options } }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...childCategories.map((c, i) => ({ key: c.id, id: c.id, name: c.name })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />  }
      <Input
        label="UF"
        placeholder="Selecione..."
        value={ plan?.params?.ufs }
        onChange={ options => { setPlan( plan => {
          if (options.includes("all")) {
              return { ...plan, params: { ...plan.params, ufs: UF.map(uf => uf) } }
          }
          if (options.includes("remove all")) {
              return { ...plan, params: { ...plan.params, ufs: [] } }
          }
          return { ...plan, params: { ...plan.params, ufs: options } }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...UF.map((uf, i) => ({ key: i, id: uf, name: uf })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
       <AsyncSelect2
        label="Fabricante"
        value={plan?.params?.manufacturer}
        valueName={manufacturerName}
        onChange={(option) => {
          setManufacturerName(option?.name);
          setPlan((plan) => {
            return {
              ...plan, params: { ...plan.params, 
                manufacturer: option ? option.id : null
              }
            };
          });
        }}
        fetchData={getManufacturers}
      />
      <br/>
    </div>
  )
}

export default ProfessionalPlan;