import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import useHandleOnChange from "../../service/form/useHandleOnChange";

export default function useReadCouponReport() {
  const [stateReport, setStateReport] = useState(initialReportData);

  const [stateParams, setStateParams] = useState(initialParams);

  const handleOnChangeGroupBy = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) =>
      setStateParams((prev) => ({ ...prev, groupBy: e.target.value })),
    [setStateParams]
  );

  const handleReportOnChange = useHandleOnChange(setStateReport);

  const handleParamsOnChange = useHandleOnChange(setStateParams);

  const fetchData = useCallback(
    () => baseFetchImportReportData(stateParams, setStateReport),
    [stateParams, setStateReport]
  );

  return {
    ...stateReport,
    ...stateParams,
    paramsValues,
    handleOnChangeGroupBy,
    handleReportOnChange,
    handleParamsOnChange,
    fetchData,
    getColor,
    getMachineClass,
    parseStatusName,
    formatTime,
    translateLabel,
  };
}

type SetState = Dispatch<SetStateAction<Report>>;

interface ReadCouponData {
  status: string;
  machine: string;
  time: number;
  visitTime: number;
  type: string;
  sum: number;
}

interface TotalReadCouponData {
  status: string;
  total: number;
  time: number;
  visitTime: number;
  percent: number;
}

interface PerStatus {
  [id: string]: {
    status: string;
    total: number;
    visitTime: number;
    time: number;
    percent: number;
  };
}

interface StatusGroup {
  [id: string]: {
    total: number;
    time: number;
    visitTime: number;
  };
}

export interface StatusDataReport {
  type: string;
  total: number;
  time: number;
  visitTime: number;
  data: TotalReadCouponData[];
}

export interface MachineDataReport {
  performance: number;
  machine: string;
  conclusive: number;
  total: number;
}

interface Report {
  loading: boolean;
  status: StatusDataReport[];
  machines: MachineDataReport[];
}

interface Params {
  groupBy: string | null;
  filterBy: string | null;
  countBy: string | null;
  filterValue: string | null;
  machinesOnly: boolean;
  interval: string | null;
}

interface Machine {
  [id: string]: {
    performance: number;
    machine: string;
    conclusive: number;
    total: number;
  };
}

interface Group {
  [id: string]: {
    type: string;
    data: ReadCouponData[];
  };
}

interface Colors {
  [id: string]: string;
}

const initialReportData: Report = {
  loading: false,
  status: [],
  machines: [],
};

const initialParams: Params = {
  groupBy: "extractor",
  filterBy: null,
  countBy: "status",
  filterValue: null,
  machinesOnly: false,
  interval: null,
};

const colors: Colors = {
  ValidCoupon: "#2980b9",
  NotFoundAccessKeyException: "purple",
  AccessDeniedException: "black",
  PageTimeoutException: "#95a5a6",
  DomTimeoutException: "darkred",
  CouldNotBreakCaptchaException: "#FF8C00",
  InvalidAccessKeyException: "#8A2BE2",
  UnhandledAlertException: "#FF7F50",
  TimeoutException: "#DC143C",
  NoSuchElementException: "#D8BFD8",
  UnhandledSituationException: "#EE82EE",
  ContingencyException: "#EEE8AA",
  RuntimeException: "#2F4F4F",
  AccessKeyIsDownException: "cyan",
  CanceledCouponException: "pink",
  ProxyRefusingConnectionsException: "yellow",
  UnsupportedContextException: "brown",
  InvalidCouponException: "#A30000",
};

const paramsValues = [
  { id: "proxy", name: "proxy" },
  { id: "provider", name: "provider" },
  { id: "status", name: "status" },
  { id: "machine", name: "machine" },
  { id: "type", name: "type" },
  { id: "extractor", name: "extractor" },
  { id: "userAgent", name: "userAgent" },
  { id: "requester", name: "requester" },
  { id: "breaker", name: "breaker" },
  { id: "browser", name: "browser" },
];

function getColor(label: string) {
  if (!colors[label]) {
    colors[label] =
      "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6);
  }

  return colors[label];
}

function parseStatusName(name: string) {
  if (!name) {
    return "null";
  }

  if (name.match(/^[A-Z]([a-z]*[A-Z])*[a-z]+$/)) {
    return name.replace(/[a-z]/g, "");
  }

  return translateLabel(name);
}

function formatTime(time: number) {
  let seconds = Math.floor(time / 1000);
  let millisecondsLeft = Math.floor(time % 1000);

  let formattedSeconds = seconds.toString().padStart(2, "0");
  let formattedMilliseconds = millisecondsLeft.toString().padStart(3, "0");

  return `${formattedSeconds}.${formattedMilliseconds}s`;
}

function getMachineClass(percent: number) {
  if (percent === 0) {
    return "down";
  }
  if (percent < 50) {
    return "bad";
  }
  if (percent < 80) {
    return "regular";
  }
  if (percent < 100) {
    return "ok";
  }
  if (percent < 120) {
    return "good";
  }

  return "excelent";
}

const ips = {
  "159.65.35.18": "Storm 01",
  "167.71.83.11": "Storm 02",
  "192.241.135.198": "Storm 03",
  "167.71.87.117": "Storm 04",
  "167.71.162.220": "Storm 05",
  "165.227.182.72": "Storm 06",
  "165.227.182.94": "Storm 07",
  "167.71.166.238": "Storm 08",
  "165.22.43.251": "Storm 09",
  "68.183.59.81": "Storm 10",
  "68.183.152.3": "Storm 11",
  "138.197.2.87": "Storm 12",
  "159.203.82.125": "Storm 13",
  "159.65.190.194": "Storm 14",
  "167.71.95.172": "Storm 15",
  "142.93.182.5": "Importer 01",
  "68.183.57.208": "Importer 02",
  "45.55.56.21": "Importer 03",
  "159.89.38.71": "Importer 04",
  "167.71.83.233": "Importer 05",
  "192.241.152.4": "Importer 06",
  "142.93.176.156": "Importer 07",
};

function zeroPad(num: number, numZeros: number) {
  let n = Math.abs(num);
  let zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  let zeroString = Math.pow(10, zeros).toString().substr(1);
  if (num < 0) {
    zeroString = "-" + zeroString;
  }

  return zeroString + n;
}

function translateLabel(label: string) {
  if (!label) {
    return "Null";
  }

  Object.entries(ips).forEach(
    (entry) => (label = label.replace(entry[0], entry[1]))
  );
  return label
    .replace(/([A-Za-z]+?)_([A-Za-z]+)/, "$2 $1")
    .replace("resumed", "Resumed")
    .replace("complete", "Complete")
    .replace("sat", "SAT")
    .replace("-", " ")
    .replace("_", " ");
}

function groupData(items: ReadCouponData[]) {
  const group: Group = {};
  const machines: Machine = {};

  for (let i = 1; i <= 30; i++) {
    machines[zeroPad(i, 2)] = {
      machine: zeroPad(i, 2),
      conclusive: 0,
      total: 0,
      performance: 0,
    };
  }

  items.forEach((item) => {
    item.type = item.type || "";

    if (!group[item.type]) {
      group[item.type] = {
        type: item.type,
        data: [],
      };
    }
    if (!machines[item.machine]) {
      machines[item.machine] = {
        machine: item.machine,
        conclusive: 0,
        total: 0,
        performance: 0,
      };
    }
    if (
      [
        "success",
        "CanceledCouponException",
        "NotFoundAccessKeyException",
        "ContingencyException",
      ].includes(item.status)
    ) {
      machines[item.machine].conclusive += item.sum;
    }
    machines[item.machine].total += item.sum;
    group[item.type].data.push(item);
  });

  const states = Object.entries(group)
    .map((entry) => {
      const preCalcTotal = entry[1].data
        .map((i) => i.sum)
        .reduce((a, b) => a + b);
      let total = 0;
      let time = 0;
      let visitTime = 0;
      const perStatus: PerStatus = {};
      entry[1].data.forEach((item) => {
        if (!perStatus[item.status]) {
          perStatus[item.status] = {
            status: item.status === "success" ? "ValidCoupon" : item.status,
            total: item.sum,
            visitTime: item.visitTime,
            time: item.time,
            percent: (item.sum / preCalcTotal) * 100,
          };
          time = (total * time + item.sum * item.time) / (total + item.sum);
          visitTime =
            (total * visitTime + item.sum * item.visitTime) /
            (total + item.sum);
          total += item.sum;
          return;
        }
        time = (total * time + item.sum * item.time) / (total + item.sum);
        visitTime =
          (total * visitTime + item.sum * item.visitTime) / (total + item.sum);
        perStatus[item.status].time =
          (perStatus[item.status].time * perStatus[item.status].total +
            item.time * item.sum) /
          (perStatus[item.status].total + item.sum);
        perStatus[item.status].visitTime =
          (perStatus[item.status].visitTime * perStatus[item.status].total +
            item.visitTime * item.sum) /
          (perStatus[item.status].total + item.sum);
        perStatus[item.status].total += item.sum;
        total += item.sum;
        perStatus[item.status].percent =
          (perStatus[item.status].total / preCalcTotal) * 100;
      });

      return {
        type: entry[0],
        total: total,
        time: time,
        visitTime: visitTime,
        data: Object.entries(perStatus)
          .map((i) => i[1])
          .sort((a, b) => (a.status || "").localeCompare(b.status)),
      };
    })
    .sort((a, b) =>
      translateLabel(a.type || "").localeCompare(translateLabel(b.type))
    );

  const total: StatusDataReport = {
    type: "Total",
    total: 0,
    time: 0,
    visitTime: 0,
    data: [],
  };

  const statusGroup: StatusGroup = {};

  states.forEach((state) => {
    state.data.forEach((status) => {
      if (!statusGroup[status.status]) {
        statusGroup[status.status] = {
          total: status.total,
          time: status.time,
          visitTime: status.visitTime,
        };
        total.time =
          (total.time * total.total + status.time * status.total) /
          (total.total + status.total);
        total.visitTime =
          (total.visitTime * total.total + status.visitTime * status.total) /
          (total.total + status.total);
        total.total += status.total;
        return;
      }
      statusGroup[status.status].time =
        (statusGroup[status.status].time * statusGroup[status.status].total +
          status.time * status.total) /
        (statusGroup[status.status].total + status.total);
      statusGroup[status.status].visitTime =
        (statusGroup[status.status].visitTime *
          statusGroup[status.status].total +
          status.visitTime * status.total) /
        (statusGroup[status.status].total + status.total);
      total.time =
        (total.time * total.total + status.time * status.total) /
        (total.total + status.total);
      total.visitTime =
        (total.visitTime * total.total + status.visitTime * status.total) /
        (total.total + status.total);
      statusGroup[status.status].total += status.total;
      total.total += status.total;
    });
  });

  total.data = Object.entries(statusGroup).map((entry) => {
    return {
      status: entry[0] === "success" ? "ValidCoupon" : entry[0],
      total: entry[1].total,
      time: entry[1].time,
      visitTime: entry[1].visitTime,
      percent: (entry[1].total / total.total) * 100,
    };
  });

  states.unshift(total);
  const machineArray = Object.entries(machines).map((i) => i[1]);
  const machineAvg =
    machineArray.map((i) => i.total).reduce((a, b) => a + b) /
    machineArray.length;
  machineArray.forEach((machine) => {
    machine.performance = machine.total * (100 / machineAvg);
  });

  return {
    status: states,
    machines: machineArray.sort((a, b) => a.machine.localeCompare(b.machine)),
  };
}

async function baseFetchImportReportData(params: Params, setState: SetState) {
  setState((prev) => ({ ...prev, loading: true }));
  const response = await http.get(baseUrl + "/api/v1/report/read-coupon", {
    params,
  });

  const data = response.data as ReadCouponData[];

  const groupedData = groupData(data);

  setState((prev) => ({
    ...prev,
    loading: false,
    status: groupedData.status,
    machines: groupedData.machines,
  }));
}
