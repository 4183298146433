import { Switch, Route } from "react-router-dom";

import { Title } from '../../../components/Titles';
import Card from "../ui/Card";
import Tabs from "../ui/Tabs";
import SubTitle from "../ui/SubTitle";
import ProductListPage from "./product-list/ProductListPage";
import { useMenu } from "../../service/context/globalContext";
import ProductCategoryCreatePage from "./product-category/ProductCategoryCreatePage";
import NielsenCategoryCreatePage from "./nielsen-category/NielsenCategoryCreatePage";
import ManufacturerManagementPage from "./manufacturer/ManufacturerManagementPage";
import ProductQueuePage from "../../../pages/ProductQueuePage";
import Platform from "../ui/Platform";
import InsertProductPage from "./InsertProduct/InsertProductPage";
import ProductBrandManagementPage from "./brand/ProductBrandManagementPage";
import ProductCompanyCodePage from "./product-company-code/ProductCompanyCodePage";
import UpdateProductPage from "./UpdateProduct/UpdateProductPage";
import LoadNextItem from "../queue/LoadNextItem";

function ProductModerationPage() {

  const menu = useMenu("/product");

  return (
    <Platform>
      <Title>{menu.name}</Title>
      <SubTitle>
        Criação, listagem e alteração de produtos
      </SubTitle>
      <Tabs menu={menu} />
      <Card>
        <Switch>
          <Route exact path="/product/moderation">
            <ProductQueuePage />
          </Route>
          <Route exact path="/product/manufacturer/:page">
            <ManufacturerManagementPage />
          </Route>
          <Route exact path="/product/list">
            <ProductListPage />
          </Route>
          <Route exact path="/product/product-category/new">
            <ProductCategoryCreatePage />
          </Route>
          <Route exact path={ [ "/product/product-category/edit", "/product/product-category/edit/:id" ] }>
            <ProductCategoryCreatePage edit />
          </Route>
          <Route exact path="/product/nielsen-category/new">
            <NielsenCategoryCreatePage />
          </Route>
          <Route exact path={ [ "/product/nielsen-category/edit", "/product/nielsen-category/edit/:id" ] }>
            <NielsenCategoryCreatePage edit />
          </Route>
          <Route path="/product/brand/:page">
            <ProductBrandManagementPage />
          </Route>
          <Route path="/product/insert">
            <InsertProductPage />
          </Route>
          <Route path="/product/update">
            <UpdateProductPage />
          </Route>
          <Route path="/product/product-company-code">
            <ProductCompanyCodePage />
          </Route>
          <Route path="/product/load-next/:type">
            <LoadNextItem />
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}

export default ProductModerationPage