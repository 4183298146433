import styled, { css } from "styled-components";

export const CheckboxContainer = styled.button`
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: flex-start;
  border: none;
  height: 12px;
  width: 100%;
`;

export const CheckboxLabel = styled.span`
  margin-top: 0;
  width: 100%;
  text-align: left;
`;

export const CheckboxBox = styled.div`
  max-width: 10px;
  max-height: 10px;
  width: 100%;
  height: 100%;
  border: 1px solid #777a88;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px;

  svg {
    width: 10px;
    height: 10px;
  }

  ${(props) =>
    props.isChecked &&
    css`
      border-color: #039cf8;
      background-color: #039cf8;
      color: white;
    `}
`;
