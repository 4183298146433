import { SetStateAction, useCallback, useEffect, useState } from "react";
import { isEmpty } from "../../../utils/validation";
import PartnerCouponReportDetailsModal from "../../components/user/partner/PartnerCouponReportDetailsModal";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import { fill } from "../string";
import usePartnerRequests, {
  DownloadPartnerCouponConsumedCsv,
  GetPartnerCouponConsumed,
  PartnerCouponConsumed,
  PartnerCouponConsumedDetail,
} from "./usePartnerRequests";

export default function usePartnerCouponConsumed() {
  const { openModal } = useModal();

  const { getPartnerCouponConsumed, downloadPartnerCouponConsumedCsv } =
    usePartnerRequests();

  const [state, setState] = useState(initialState);

  const { startMonth, endMonth, initialMonth, finalMonth } = state;

  const handleOnChange = useHandleOnChange(setState);

  const fetchData = useCallback(
    () =>
      baseFetchData({
        initialMonth,
        finalMonth,
        setState,
        openModal,
        getPartnerCouponConsumed,
      }),
    [initialMonth, finalMonth]
  );

  const handleOnFilterClick = useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        initialMonth: startMonth,
        finalMonth: endMonth,
      })),
    [setState, startMonth, endMonth]
  );

  const showDetails = useCallback(
    (details: PartnerCouponConsumedDetail[]) =>
      openModal(<PartnerCouponReportDetailsModal details={details} />),
    [openModal]
  );

  const handleCsvDownloadOnClick = useCallback(
    () =>
      baseHandleCsvDownloadOnClick(
        downloadPartnerCouponConsumedCsv,
        setState,
        openModal,
        state
      ),
    [downloadPartnerCouponConsumedCsv, setState, openModal, state]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    ...state,
    handleOnChange,
    handleOnFilterClick,
    showDetails,
    handleCsvDownloadOnClick,
  };
}

export interface State {
  partnerCoupons: null | PartnerCouponConsumed[];
  startMonth: null | string;
  endMonth: null | string;
  initialMonth: null | string;
  finalMonth: null | string;
  loading: boolean;
}

const date = new Date();

const defaultMonth = `${date.getFullYear()}-${fill(
  String(date.getMonth() + 1),
  "0",
  2
)}`;

const initialState: State = {
  partnerCoupons: null,
  startMonth: defaultMonth,
  endMonth: defaultMonth,
  initialMonth: defaultMonth,
  finalMonth: defaultMonth,
  loading: false,
};

interface FetchDataParams {
  initialMonth: null | string;
  finalMonth: null | string;
  getPartnerCouponConsumed: GetPartnerCouponConsumed;
  openModal: OpenModal;
  setState: (action: SetStateAction<State>) => void;
}

async function baseFetchData(params: FetchDataParams) {
  const {
    initialMonth,
    finalMonth,
    setState,
    getPartnerCouponConsumed,
    openModal,
  } = params;

  try {
    setState((prev) => ({ ...prev, loading: true }));

    const data = await getPartnerCouponConsumed(initialMonth!, finalMonth!);

    setState((prev) => ({ ...prev, partnerCoupons: data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleCsvDownloadOnClick(
  downloadCsv: DownloadPartnerCouponConsumedCsv,
  setState: (action: SetStateAction<State>) => void,
  openModal: OpenModal,
  state: State
) {
  if (isEmpty(state.endMonth) || isEmpty(state.startMonth)) return;

  try {
    const form = {
      startMonth: state.startMonth!,
      endMonth: state.endMonth!,
    };

    setState((prev) => ({ ...prev, loading: true }));

    const csvData = await downloadCsv(form);

    const csvFile = new Blob([csvData], { type: "text/csv" });

    let tempLink = document.createElement("a");

    tempLink.download = "partnerCouponConsumed.csv";
    let url = window.URL.createObjectURL(csvFile);
    tempLink.href = url;

    tempLink.style.display = "none";
    document.body.appendChild(tempLink);

    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}
