import Button from "../form/Button";
import ReadMachinesReportCard from "./ReadMachinesReportCard";
import ReadStatusReportCard from "./ReadStatusReportCard";
import useReadCouponReport from "./useReadCouponReport";
import styles from "./ReadCouponReport.module.css";
import Input from "../form/Input";
import Select from "../form/Select";
import { SectionTitle } from "../ui/SectionTitle";

export default function ReadCouponReport() {

  const {
    loading, status, machines, interval, groupBy, countBy, filterBy, filterValue, paramsValues, handleParamsOnChange, fetchData
  } = useReadCouponReport();

  return (
    <div id="app">
      <div>
        <SectionTitle
          title="Relatório Extratores"
          description="Performance dos Extratores de Nota Fiscal"
        />
        <div className={ styles.filters }>
          <Input
            label="Digite o período (minutos):"
            name="interval"
            placeholder="Vazio busca total de hoje"
            value={ interval }
            type="number"
            onChange={ handleParamsOnChange }
          />
          <Select
            label="Agrupar por:"
            name="groupBy"
            placeholder="Selecione..."
            value={ groupBy }
            onChange={ handleParamsOnChange }
            options={ paramsValues }
          />
          <Select
            label="Contabilizar por:"
            name="countBy"
            placeholder="Selecione..."
            value={ countBy }
            onChange={ handleParamsOnChange }
            options={ paramsValues }
          />
          <Select
            label="Filtrar por:"
            name="filterBy"
            placeholder="Selecione..."
            value={ filterBy }
            onChange={ handleParamsOnChange }
            options={ paramsValues }
          />
          <Input
            label="Digite o valor do filtro:"
            name="filterValue"
            placeholder="Selecione..."
            value={ filterValue }
            onChange={ handleParamsOnChange }
          />
          <div className={ styles.moveButtonUp }></div>
          <Button onClick={ () => fetchData() } type="confirm" addInputLabelSpacing disabled={loading}>
            Buscar
          </Button>
        </div>
      </div>
      {
        !loading && status.length === 0 && machines.length === 0 ? null : (
          loading ? (
            <div><br/>Carregando....</div>
          ) : (
            <div>
              <div className={ styles.readCouponCardWrapper } >
                {
                  status.length === 0 ? (
                    <div><br/>Busca não retornou dados de status</div>
                  ) : (
                    status.map(item => (
                      <ReadStatusReportCard report={item} />
                    ))
                  )
                }
              </div>
              <div className={ styles.readCouponMachineCardWrapper } >
                {
                  machines.length === 0 ? (
                    <div>Busca não retornou dados de máquinas</div>
                  ) : (
                    machines.map(item => (
                      <ReadMachinesReportCard report={item} />
                    ))
                  )
                }
              </div>
            </div>
          )
        )
      }
    </div>
  )
}