import useClientCreatePage from "../../service/client/useClientCreatePage";
import Button from "../form/Button";
import Spacer from "../ui/Spacer";
import AsyncSelect from "../form/AsyncSelect";
import groupStyles from "./styles.module.css"
import styles from "../ui/ui.module.css"
import { SectionTitle } from "../ui/SectionTitle";

export default function ClientUserList(props: Props) {

  const { selectedEditClient, selectedUser, addingUsers, removingUsers, users, fetchUserOptions, handleOnAddUserClick, handleOnRemoveUserClick, handleSelectedUserOnChange } = props;

  if (!selectedEditClient) return <></>;

  return (
    <div>
      <SectionTitle title="Usuários do Cliente" description="Listar, Inserir e Remover Usuários Vinculados ao Cliente." />

      <div className={ groupStyles.addUserToGroupForm }>
        <AsyncSelect
          fetchData={ fetchUserOptions }
          value={ selectedUser }
          onChange={ handleSelectedUserOnChange }
          placeholder="Selecione o usuário a ser adicionado"
        />
        <Button type="confirm" disabled={ !selectedUser || addingUsers } onClick={ handleOnAddUserClick }>
          { addingUsers ? "Carregando..." : "Adicionar" }
        </Button>
      </div>
      <Spacer ratio={ 1.3 } />
      {
        users.length === 0 ? (
          <h4 style={{ fontWeight: "bolder" }}>Não há usuários associados a este cliente</h4> 
        ) : (
          <table className={ styles.table }>
            <thead>
              <tr>
                <th>Ações</th>
                <th className={ `${ styles.fullWidth } ${ styles.left }` }>Usuário</th>
              </tr>
            </thead>
            <tbody>
              {
                users.map(user => (
                  <tr key={ user.id }>
                    <td>
                      <Button
                        small
                        disabled={ !!removingUsers.find(i => i === user.id) }
                        type="cancel"
                        onClick={ () => handleOnRemoveUserClick(user.id) }>
                        { !!removingUsers.find(i => i === user.id) ? "..." : "Remover" }
                      </Button>
                    </td>
                    <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                      { user.username }
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        )
      }
    </div>
  )
}

type Props = ReturnType<typeof useClientCreatePage>