import { useState } from "react";
import AsyncSelect2 from "../components/AsyncSelect2";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Table from "../components/Table";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import BasicPlan from "../components/forms/BasicPlan";
import FgvPremiumPlan from "../components/forms/FgvPremiumPlan";
import PowerbiPlan from "../components/forms/PowerbiPlan";
import ProfessionalPlan from "../components/forms/ProfessionalPlan";
import { baseUrl } from "../constants";
import usePlans from "../hooks/usePlans";
import { isEmptyObj, isNotEmpty, makeValidator } from "../utils";

const breadcrump = [
  {
    name: "Início",
    path: "/dashboard",
  },
  {
    name: "Assinaturas",
    path: "/plans",
  },
];

const planValidator = makeValidator({
  value: isNotEmpty("Valor"),
});

function CreatePlanForm(props) {
  const {
    onCreatePlan,
    loading,
    planToEdit,
    setPlanToEdit,
    fetchManufacturers,
    collapsed,
    setCollapsed,
    onEditCancel,
    chosenClient,
    childCategories,
    fgvCategories,
  } = props;
  const { getValueErrors, getAllErrors } = planValidator;

  if (collapsed && chosenClient.id) {
    return (
      <Button
        layout="confirm"
        disabled={loading}
        onClick={() => setCollapsed(false)}
      >
        {loading ? "Carregando..." : "Nova Assinatura"}
      </Button>
    );
  }

  const fetchStatus = [
    { id: null, name: "Sem Status" },
    { id: "PENDING", name: "PENDING" },
    { id: "APPROVED", name: "APPROVED" },
    { id: "REJECTED", name: "REJECTED" },
    { id: "CANCELED", name: "CANCELED" },
  ];

  const fetchPlanType = [
    { id: "POWERBI", name: "PowerBI" },
    { id: "BASIC", name: "Basico" },
    { id: "PROFESSIONAL", name: "Profissional" },
    { id: "FGV_PREMIUM", name: "FGV_PREMIUM" },
    { id: "METRICS_WEB", name: "METRICS_WEB" },
  ];

  const handleOnFormSubmit = async (e) => {
    e.preventDefault();
    await onCreatePlan(planToEdit);
    setCollapsed(true);
  };

  const handleOnCancel = () => {
    setCollapsed(true);
    if (planToEdit) {
      onEditCancel();
    }
  };

  return (
    <Collapse collapsed={collapsed}>
      <SubTitle>
        {planToEdit?.id > 0
          ? `Editar Assinatura ${planToEdit.id}`
          : "Criar Nova Assinatura"}
      </SubTitle>
      <form onSubmit={handleOnFormSubmit}>
        <Card>
          <Input
            label="Tipo de Plano"
            value={planToEdit?.plan}
            onChange={(e) =>
              setPlanToEdit({
                ...planToEdit,
                plan: e.target.value,
                ufs: [],
                channels: [],
                categories: [],
                manufacturer: null,
                manufacturerName: null,
              })
            }
            options={fetchPlanType}
          />
          <Input
            type="number"
            label="Valor"
            value={planToEdit?.value}
            onChange={(e) =>
              setPlanToEdit({ ...planToEdit, value: e.target.value })
            }
            validate={getValueErrors}
          />
          {(function () {
            if (planToEdit.plan === "POWERBI") {
              return (
                <PowerbiPlan
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                  childCategories={childCategories}
                  fetchManufacturers={fetchManufacturers}
                />
              );
            }
            if (planToEdit.plan === "FGV_PREMIUM") {
              return (
                <FgvPremiumPlan
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                  fgvCategories={fgvCategories}
                />
              );
            }
            if (planToEdit.plan === "PROFESSIONAL") {
              return (
                <ProfessionalPlan
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                  childCategories={childCategories}
                  fetchManufacturers={fetchManufacturers}
                />
              );
            }
            if (planToEdit.plan === "BASIC") {
              return (
                <BasicPlan
                  planToEdit={planToEdit}
                  setPlanToEdit={setPlanToEdit}
                  childCategories={childCategories}
                />
              );
            }
          })()}
          <Input
            label="Status"
            value={planToEdit?.status}
            onChange={(e) =>
              setPlanToEdit({ ...planToEdit, status: e.target.value })
            }
            options={fetchStatus}
          />
          {!planToEdit?.createdAt ? null : (
            <Input
              label="Data de Criação"
              readOnly
              type="datetime-local"
              value={planToEdit?.createdAt}
            />
          )}
          <Input
            label="Data de Finalização"
            type="datetime-local"
            value={planToEdit?.finishedAt}
            onChange={(e) =>
              setPlanToEdit({ ...planToEdit, finishedAt: e.target.value })
            }
          />
        </Card>
        <Button layout="confirm" disabled={getAllErrors(planToEdit) || loading}>
          {loading ? "Carregando..." : planToEdit ? "Editar" : "Cadastrar"}
        </Button>{" "}
        <Button layout="cancel" onClick={handleOnCancel}>
          Cancelar
        </Button>
      </form>
    </Collapse>
  );
}

function PlanPage() {
  const { createPlan, getPlansByCompId, updatePlan } = usePlans();

  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [message, setMessage] = useState(null);
  const [planToEdit, setPlanToEdit] = useState({ plan: "POWERBI" });
  const [chosenClient, setChosenClient] = useState({ id: null, name: null });
  const [collapsed, setCollapsed] = useState(true);
  const [childCategories, setChildCategories] = useState([]);
  const [fgvCategories, setFgvCategories] = useState([]);

  const planObjectMount = (plan) => {
    let data = {
      params:
        plan.plan === "POWERBI"
          ? {
              categories: plan.categories?.length > 0 ? plan.categories : null,
              ufs: plan.ufs?.length > 0 ? plan.ufs : null,
              channels: plan.channels?.length > 0 ? plan.channels : null,
              manufacturer: plan.manufacturer > 0 ? plan.manufacturer : 0,
            }
          : plan.plan === "FGV_PREMIUM"
          ? {
              categories: plan.categories?.length > 0 ? plan.categories : null,
              ufs: plan.ufs?.length > 0 ? plan.ufs : null,
              channels: plan.channels?.length > 0 ? plan.channels : null,
              manufacturer: plan.manufacturer > 0 ? plan.manufacturer : 0,
            }
          : plan.plan === "METRICS_WEB"
          ? {
              categories: plan.categories?.length > 0 ? plan.categories : null,
              ufs: plan.ufs?.length > 0 ? plan.ufs : null,
              channels: plan.channels?.length > 0 ? plan.channels : null,
              manufacturer: plan.manufacturer > 0 ? plan.manufacturer : 0,
            }
          : plan.plan === "PROFESSIONAL"
          ? {
              categories: plan.categories?.length > 0 ? plan.categories : null,
              ufs: plan.ufs?.length > 0 ? plan.ufs : null,
              channels: plan.channels?.length > 0 ? plan.channels : null,
              manufacturer: plan.manufacturer > 0 ? plan.manufacturer : 0,
            }
          : {
              categories: plan.categories?.length > 0 ? plan.categories : null,
              ufs: plan.ufs?.length > 0 ? plan.ufs : null,
              channels: plan.channels?.length > 0 ? plan.channels : null,
              manufacturer: plan.manufacturer > 0 ? plan.manufacturer : 0,
            },
      id: plan.id,
      value: plan.value,
      plan: plan.plan,
      clientId: chosenClient.id,
      createdAt: plan.createdAt,
      finishedAt: plan.finishedAt,
      status: plan.status,
    };
    return data;
  };
  const handleOnCreatePlan = async (planToEdit) => {
    setLoading(true);
    try {
      if (planToEdit?.id) {
        await updatePlan({ planToEdit });
      } else {
        await createPlan({ ...planToEdit, clientId: chosenClient.id });
      }
      setPlans(null);
      setMessage(
        `Assinatura ${planToEdit ? "editada" : "criada"} com sucesso!`
      );
      if (!isEmptyObj(planToEdit)) {
        setPlanToEdit({ plan: "POWERBI" });
      }
    } catch (err) {
      setMessage(
        <>
          Ocorreu um erro ao editar/criar a assinatura:
          <br />
          {err.message}
        </>
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchClients = async function (search, offset) {
    try {
      const { data: clients } = await http.get(
        baseUrl + `/client-company/description`,
        {
          params: {
            search,
            offset,
            limit: 10,
          },
        }
      );

      return {
        options: clients.map((c) => ({
          id: c.id,
          name: c.name,
          reportId: c.reportId,
          um: c.unitOfMeasurement,
        })),
        hasMore: clients.length === 10,
      };
    } catch (e) {
      console.log(e.message);
    }
  };

  const fetchManufacturers = async function (search, offset, ncm) {
    const { data: manufacturers } = await http.get(
      baseUrl + `/api/v1/products/manufacturers`,
      {
        params: {
          search,
          offset,
          limit: 10,
        },
      }
    );
    return {
      options: manufacturers.map((m) => ({ id: m.id, name: m.name })),
      hasMore: manufacturers.length === 10,
    };
  };

  //basic professional powerbi
  const fetchChildCategories = async function () {
    if (!childCategories.length > 0) {
      const { data: categories } = await http.get(
        baseUrl + `/api/v1/products/child-categories`
      );
      setChildCategories(
        categories.map((c) => ({ key: c.id, id: c.id, name: c.name }))
      );
    }
  };

  //fgv
  const fetchFgvCategories = async function () {
    if (!fgvCategories.length > 0) {
      const { data: categories } = await http.get(
        baseUrl + `/api/v1/checkout/fgv/categories`
      );
      setFgvCategories(
        categories.map((c) => ({ key: c.id, id: c.id, name: c.name }))
      );
    }
  };

  //Falta as categorias do METRICSweb

  const handleOnEditCancel = () => {
    setPlanToEdit({ plan: "POWERBI" });
  };

  const setEditPlan = (plan) => {
    setCollapsed(false);
    setPlanToEdit(plan);
    if (plan.plan === "FGV_PREMIUM") {
      fetchFgvCategories();
      return;
    }
    fetchChildCategories();
  };

  const fetchPlans = async (compId) => {
    setLoading(true);
    if (compId) {
      getPlansByCompId(compId).then((response) => {
        setPlans(response.data);
      });
    }
    setLoading(false);
  };

  return (
    <Template breadcrump={breadcrump}>
      <Modal
        close={() => setMessage(null)}
        children={message}
        hidden={!message}
      />
      <Title>Escolha o Cliente</Title>
      <div>
        <AsyncSelect2
          label="Cliente"
          value={chosenClient.id}
          valueName={chosenClient.name}
          onChange={(e) => {
            setPlanToEdit({ plan: "POWERBI" });
            setPlans([]);
            setCollapsed(true);
            fetchPlans(e?.id);
            setChosenClient({ id: e?.id, name: e?.name });
          }}
          fetchData={fetchClients}
        />
      </div>
      <Title>Assinaturas</Title>
      <CreatePlanForm
        onCreatePlan={handleOnCreatePlan}
        onEditCancel={handleOnEditCancel}
        loading={loading}
        planToEdit={planToEdit}
        setPlanToEdit={setPlanToEdit}
        fetchManufacturers={CreatePlanForm}
        collapsed={collapsed}
        setCollapsed={setCollapsed}
        chosenClient={chosenClient}
        childCategories={childCategories}
        fgvCategories={fgvCategories}
        fetchChildCategories={fetchChildCategories}
        fetchFgvCategories={fetchFgvCategories}
      />
      {loading ? (
        <Loading />
      ) : (
        <Table>
          <thead>
            <tr>
              <th className="align-center">Id</th>
              <th className="align-center">Tipo</th>
              <th className="align-center">Parâmetros</th>
              <th className="align-center">Valor</th>
              <th className="align-center">Data Criação</th>
              <th className="align-center">Data Finalização</th>
              <th className="align-center">status</th>
              <th className="align-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            {plans?.length > 0 ? (
              plans
                .filter((p) => p.plan !== "OTHER")
                .map((plan) => {
                  return (
                    <tr key={plan.id}>
                      <td className="align-center">{plan.id}</td>
                      <td className="align-center">{plan.plan}</td>
                      <td className="align-center limit-size">
                        {JSON.stringify(plan.params).replace(
                          /{|}|\(|\)|\"/g,
                          ""
                        )}
                      </td>
                      <td className="align-center">{plan.value}</td>
                      <td className="align-center">{plan.createdAt}</td>
                      <td className="align-center">{plan.finishedAt}</td>
                      <td className="align-center">{plan.status}</td>
                      <td className="align-center">
                        <Button
                          layout="info"
                          onClick={() => setEditPlan(plan)}
                          disabled={planToEdit?.id === plan.id}
                        >
                          {planToEdit?.id === plan.id
                            ? "Editando..."
                            : "Editar"}
                        </Button>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td colSpan="2">Nenhuma assinatura cadastrada.</td>
              </tr>
            )}
          </tbody>
        </Table>
      )}
    </Template>
  );
}

export default PlanPage;
