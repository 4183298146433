import Table from "../../Table";
import Button from "../../Button";

export default function ProdCatDisplay({ category, goToEdit }) {
  return (
    <Table>
      <thead>
        <tr>
          <th className="align-center">Id</th>
          <th className="align-center">Nome</th>
          <th className="align-center">Und. Medida</th>
          <th className="align-center">Preço Médio</th>
          <th className="align-center">Categoria Pai</th>
          <th className="align-center">Categoria Nielsen</th>
          <th className="align-center">Tags Permitidas</th>
          <th className="align-center">Ações</th>
        </tr>
      </thead>
      <tbody>
        {
          !category ? null : (
            <tr key={category.id}>
              <td className="align-center">{category.id}</td>
              <td className="align-center">{category.name}</td>
              <td className="align-center">{category.unitOfMeasurement}</td>
              <td className="align-center">{category.median}</td>
              <td className="align-center">{category.parentCategory}</td>
              <td className="align-center">{category.nielsenCategory}</td>
              <td className="align-center">
                {category.tagCes ? <p>{category.tagCes}</p> : <p>Tag CES indefinida</p>}
                {category.tagSeason ? <p>Sazonal</p> : null}
                {category.tagPackage ? <p>Embalagem</p> : null}
                {category.tagEspecificity ? <p>Especificidade</p> : null}
                {category.tagLine ? <p>Linha</p> : null}
                {category.tagLinearMeasure ? <p>Metragem</p> : null}
              </td>
              <td className="align-center">
                <Button layout="info" onClick={() => goToEdit(category)}>
                  Editar
                </Button>
              </td>
            </tr>
          )
        }
      </tbody>
    </Table>
  )
}