import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { isEmpty, isNotEmpty, isPositive } from "../../../utils/validation";
import CsvUploadModal from "../../components/product/product-company-code/CsvUploadModal";
import ProductCompanyCodeDeleteAlert from "../../components/product/product-company-code/ProductCompanyCodeDeleteAlert";
import ProductCompanyCodeEditModal from "../../components/product/product-company-code/ProductCompanyCodeEditModal";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import useProductCompanyCodeRequests, {
  CreateProductCompanyCode,
  DeleteProductCompanyCode,
  DownloadProductCompanyCodeCsv,
  EditProductCompanyCode,
  FindProductCompanyCode,
  MassCreateProductCompanyCode,
  MassDeleteProductCompanyCode,
  MassEditProductCompanyCode,
  ProductCompanyCode,
  ProductCompanyCodeSearchForm,
} from "./useProductCompanyCodeRequestsRequests";

export default function useProductCompanyCodePage() {
  const {
    findProductCompanyCode,
    createProductCompanyCode,
    editProductCompanyCode,
    deleteProductCompanyCode,
    downloadProductCompanyCodeCsv,
    massCreateProductCompanyCode,
    massDeleteProductCompanyCode,
    massEditProductCompanyCode,
  } = useProductCompanyCodeRequests();

  const [state, setState] = useState(initialState);

  const [csv, setCsv] = useState<Csv>({ header: null, items: null });

  const [showHiddenFields, setShowHiddenFields] = useState(false);

  const [newProductCompanyCodeForm, setNewProductCompanyCodeForm] = useState(
    initialNewProductCompanyCode
  );

  const fileInput = useRef<HTMLInputElement>();

  const { openModal } = useModal();

  const handleOnChange = useHandleOnChange(setState);

  const handleNewPccFormOnChange = useHandleOnChange(
    setNewProductCompanyCodeForm
  );

  const handleShowHiddenFieldsOnCLick = useCallback(
    () => setShowHiddenFields(!showHiddenFields),
    [showHiddenFields]
  );

  const handleFindProductCompanyCode = useCallback(
    (page: number) =>
      baseHandleFindProductCompanyCode(
        findProductCompanyCode,
        setState,
        openModal,
        state,
        page
      ),
    [state]
  );

  const handleEditModalOnClick = useCallback(
    (selectedProductCompanyCode: ProductCompanyCode) =>
      openModal(
        <ProductCompanyCodeEditModal
          productCompanyCode={selectedProductCompanyCode}
        />
      ),
    [openModal]
  );

  const handleDeleteModalOnClick = useCallback(
    (selectedProductCompanyCode: ProductCompanyCode) =>
      openModal(
        <ProductCompanyCodeDeleteAlert
          productCompanyCode={selectedProductCompanyCode}
        />
      ),
    [openModal]
  );

  const handleCreateOnConfirm = useCallback(
    (form: ProductCompanyCode) =>
      baseHandleCreateOnConfirm(
        form,
        createProductCompanyCode,
        setState,
        openModal
      ),
    [createProductCompanyCode, openModal, setState]
  );

  const handleEditOnConfirm = useCallback(
    (form: ProductCompanyCode) =>
      baseHandleEditOnConfirm(
        form,
        editProductCompanyCode,
        setState,
        openModal
      ),
    [editProductCompanyCode, openModal, setState]
  );

  const handleDeleteOnConfirm = useCallback(
    (form: ProductCompanyCode) =>
      baseHandleDeleteOnConfirm(
        form,
        deleteProductCompanyCode,
        setState,
        openModal
      ),
    [deleteProductCompanyCode, openModal, setState]
  );

  const handleFileOnChange = useCallback(
    () => baseHandleFileOnChange(fileInput, setCsv, openModal),
    [setCsv, openModal]
  );

  const handleUploadOnClick = useCallback(
    () => baseHandleUploadOnClick(fileInput),
    []
  );

  const handleSendCsvOnClick = useCallback(
    (type: string) =>
      baseSendCsv(
        csv,
        setState,
        openModal,
        massCreateProductCompanyCode,
        massEditProductCompanyCode,
        massDeleteProductCompanyCode,
        type
      ),
    [
      csv,
      setState,
      openModal,
      massCreateProductCompanyCode,
      massDeleteProductCompanyCode,
      massEditProductCompanyCode,
    ]
  );

  const handleCsvUploadModalOnClick = useCallback(
    (type: string) => openModal(<CsvUploadModal type={type} />),
    [openModal]
  );

  const handleDownloadCsvOnClick = useCallback(
    () =>
      baseHandleDownloadCsvOnClick(
        downloadProductCompanyCodeCsv,
        setState,
        openModal,
        state
      ),
    [downloadProductCompanyCodeCsv, setState, openModal, state]
  );

  useEffect(() => {
    baseHandleFindProductCompanyCode(
      findProductCompanyCode,
      setState,
      openModal,
      initialState,
      initialState.page
    );
  }, [initialState]);

  return {
    ...state,
    newProductCompanyCodeForm,
    showHiddenFields,
    handleShowHiddenFieldsOnCLick,
    findProductCompanyCode,
    handleFindProductCompanyCode,
    handleOnChange,
    handleEditModalOnClick,
    handleDeleteModalOnClick,
    handleNewPccFormOnChange,
    handleCreateOnConfirm,
    handleEditOnConfirm,
    handleDeleteOnConfirm,
    handleFileOnChange,
    handleUploadOnClick,
    handleSendCsvOnClick,
    fileInput,
    csv,
    handleCsvUploadModalOnClick,
    handleDownloadCsvOnClick,
  };
}

interface Csv {
  header: Header | null;
  items: Partial<ProductCompanyCode>[] | null;
}

interface Header {
  cnpjIndex: number | null;
  companyIdIndex: number | null;
  eanIndex: number | null;
  productIdIndex: number | null;
  productCodeIndex: number | null;
  descriptionIndex: number | null;
  itemIdIndex: number | null;
  createDateIndex: number | null;
  dateIndex: number | null;
  scoreIndex: number | null;
}

interface State {
  cnpj: string | null;
  productCode: string | null;
  items: Array<ProductCompanyCode> | null;
  page: number;
  pageSize: number;
  totalPages: number | null;
  loading: boolean;
  loadingFile: boolean;
  loadingAction: boolean;
}

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  cnpj: null,
  productCode: null,
  loading: false,
  items: null,
  page: 1,
  pageSize: 50,
  totalPages: null,
  loadingFile: false,
  loadingAction: false,
};

const initialNewProductCompanyCode: ProductCompanyCode = {
  cnpj: "",
  description: "",
  productCode: "",
  productId: 0,
  itemId: 0,
  companyId: 0,
  productDescription: "",
  ean: "",
  createDate: "",
  date: "",
  score: "",
};

async function baseHandleFindProductCompanyCode(
  findProductCompanyCode: FindProductCompanyCode,
  setState: SetState,
  openModal: OpenModal,
  state: State,
  page: number
) {
  const form: ProductCompanyCodeSearchForm = {
    cnpj: state.cnpj,
    productCodes: isEmpty(state.productCode)
      ? null
      : state.productCode!.replaceAll(/, +| +,/g, ",").split(","),
    page: page,
    pageSize: state.pageSize,
  };

  setState((prev) => ({ ...prev, loading: true, data: null }));

  try {
    const data = await findProductCompanyCode(form);
    setState((prev) => ({
      ...prev,
      items: data.items,
      page: data.page,
      totalPages: data.total,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleCreateOnConfirm(
  form: ProductCompanyCode,
  createProductCompanyCode: CreateProductCompanyCode,
  setState: SetState,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, loadingAction: true }));

  try {
    await createProductCompanyCode(form);
    openModal("Produto Baixa cadastrado com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingAction: false }));
  }
}

async function baseHandleEditOnConfirm(
  form: ProductCompanyCode,
  editProductCompanyCode: EditProductCompanyCode,
  setState: SetState,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, loadingAction: true }));

  try {
    await editProductCompanyCode(form);
    openModal("Produto Baixa atualizada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingAction: false }));
  }
}

async function baseHandleDeleteOnConfirm(
  form: ProductCompanyCode,
  deleteProductCompanyCode: DeleteProductCompanyCode,
  setState: SetState,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, loadingAction: true }));

  try {
    await deleteProductCompanyCode(form);
    openModal("Produto Baixa deletada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingAction: false }));
  }
}

async function baseHandleDownloadCsvOnClick(
  downloadCsv: DownloadProductCompanyCodeCsv,
  setState: SetState,
  openModal: OpenModal,
  state: State
) {
  if (isEmpty(state.cnpj) && isEmpty(state.productCode)) {
    openModal(<>Cnpj e/ou código do produto precisam estar preenchidos.</>);
    return;
  }

  const form = {
    cnpj: state.cnpj,
    productCodes: isEmpty(state.productCode)
      ? null
      : state.productCode!.replaceAll(/, +| +,/g, ",").split(","),
  };

  try {
    setState((prev) => ({ ...prev, loadingExcel: true }));

    const csvData = await downloadCsv(form);

    const csvFile = new Blob([csvData], { type: "text/csv" });

    let tempLink = document.createElement("a");

    tempLink.download = "produtobaixa.csv";
    let url = window.URL.createObjectURL(csvFile);
    tempLink.href = url;

    tempLink.style.display = "none";
    document.body.appendChild(tempLink);

    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingExcel: false }));
  }
}

function getHeaders(cells: string[]) {
  let cnpjIndex = null;
  let companyIdIndex = null;
  let eanIndex = null;
  let productIdIndex = null;
  let productCodeIndex = null;
  let descriptionIndex = null;
  let itemIdIndex = null;
  let createDateIndex = null;
  let dateIndex = null;
  let scoreIndex = null;

  for (let i = 0; i < cells.length; i++) {
    if (cells[i] === "cnpj") cnpjIndex = i;
    if (cells[i] === "empresa_id") companyIdIndex = i;
    if (cells[i] === "ean_genuino") eanIndex = i;
    if (cells[i] === "produto_id") productIdIndex = i;
    if (cells[i] === "cod_produto") productCodeIndex = i;
    if (cells[i] === "descricao") descriptionIndex = i;
    if (cells[i] === "id_item") itemIdIndex = i;
    if (cells[i] === "data_criacao") createDateIndex = i;
    if (cells[i] === "date") dateIndex = i;
    if (cells[i] === "score") scoreIndex = i;
  }

  return {
    cnpjIndex,
    companyIdIndex,
    eanIndex,
    productIdIndex,
    productCodeIndex,
    descriptionIndex,
    itemIdIndex,
    createDateIndex,
    dateIndex,
    scoreIndex,
  };
}

function getProductCompanyCode(cells: string[], header: Header) {
  const productCompanyCode: Partial<ProductCompanyCode> = {};

  if (header.cnpjIndex !== null)
    productCompanyCode.cnpj = isNotEmpty(cells[header.cnpjIndex])
      ? cells[header.cnpjIndex]
      : null;
  if (header.companyIdIndex !== null)
    productCompanyCode.companyId = isPositive(
      Number(cells[header.companyIdIndex])
    )
      ? Number(cells[header.companyIdIndex])
      : null;
  if (header.eanIndex !== null)
    productCompanyCode.ean = isNotEmpty(cells[header.eanIndex])
      ? cells[header.eanIndex]
      : null;
  if (header.productIdIndex !== null)
    productCompanyCode.productId = isPositive(
      Number(cells[header.productIdIndex])
    )
      ? Number(cells[header.productIdIndex])
      : null;
  if (header.productCodeIndex !== null)
    productCompanyCode.productCode = isNotEmpty(cells[header.productCodeIndex])
      ? cells[header.productCodeIndex]
      : null;
  if (header.descriptionIndex !== null)
    productCompanyCode.description = isNotEmpty(cells[header.descriptionIndex])
      ? cells[header.descriptionIndex]
      : null;
  if (header.itemIdIndex !== null)
    productCompanyCode.itemId = isPositive(Number(cells[header.itemIdIndex]))
      ? Number(cells[header.itemIdIndex])
      : null;
  if (header.createDateIndex !== null)
    productCompanyCode.createDate = isNotEmpty(cells[header.createDateIndex])
      ? cells[header.createDateIndex].replace(" ", "T")
      : null;
  if (header.dateIndex !== null)
    productCompanyCode.date = isNotEmpty(cells[header.dateIndex])
      ? cells[header.dateIndex]
      : null;
  if (header.scoreIndex !== null)
    productCompanyCode.score = isNotEmpty(cells[header.scoreIndex])
      ? cells[header.scoreIndex]
      : null;

  return productCompanyCode;
}

const baseHandleUploadOnClick = (
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>
) => {
  fileInput?.current?.click();
};

const baseHandleFileOnChange = (
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
  setCsv: Dispatch<SetStateAction<Csv>>,
  openModal: OpenModal
) => {
  if (fileInput!.current!.files![0])
    baseFileOnChange(fileInput, setCsv, openModal);
};

function baseFileOnChange(
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
  setCsv: Dispatch<SetStateAction<Csv>>,
  openModal: OpenModal
) {
  let productCompanyCodes: Partial<ProductCompanyCode>[] = [];
  const reader = new FileReader();

  // Lê o arquivo selecionado pelo usuário como texto
  reader.readAsText(fileInput!.current!.files![0]);

  // Após carregar o arquivo executa o método
  reader.onload = () => {
    try {
      const data = reader.result;
      const rows = data?.toString().split(/\r?\n/);

      if (rows === undefined) return;

      // Verifica o index das colunas dos headers
      const header: Header = getHeaders(rows[0].split(";"));

      // Verifica o produto para cada linha de dados
      for (let i = 1; i < rows.length; i++) {
        const productCompanyCode = getProductCompanyCode(
          rows[i].split(";"),
          header
        );
        if (
          !(
            isEmpty(productCompanyCode.cnpj) ||
            isEmpty(productCompanyCode.productCode)
          )
        ) {
          productCompanyCodes.push(productCompanyCode);
        }
      }

      setCsv({ header, items: productCompanyCodes });
    } catch (e) {
      openModal(getErrorMessage(e));
      console.log(e);
    }
  };

  reader.onerror = (e) => {
    openModal(getErrorMessage(e));
    console.log(e);
  };

  // Remove o arquivo de referência da memória
  fileInput!.current!.value = "";
}

async function baseSendCsv(
  csv: Csv,
  setState: Dispatch<SetStateAction<State>>,
  openModal: OpenModal,
  massCreateProductCompanyCode: MassCreateProductCompanyCode,
  massEditProductCompanyCode: MassEditProductCompanyCode,
  massDeleteProductCompanyCode: MassDeleteProductCompanyCode,
  type: string
) {
  if (csv.items?.length === 0) return;

  try {
    setState((prev) => ({ ...prev, loadingAction: true }));

    let data: string[] = [];

    if (type === "create") {
      data = await massCreateProductCompanyCode(csv.items!);
    }
    if (type === "edit") {
      data = await massEditProductCompanyCode(csv.items!);
    }
    if (type === "delete") {
      data = await massDeleteProductCompanyCode(csv.items!);
    }

    openModal(
      <div style={{ textAlign: "center" }}>
        <div style={{ fontWeight: "bolder", marginBottom: "1em" }}>
          Informação
        </div>
        <div style={{ marginBottom: "1em" }}>{data[0]}</div>
        <div
          style={{ maxHeight: "300px", overflow: "auto", textAlign: "left" }}
        >
          {data.map((i, index) =>
            index === 0 ? null : (
              <div style={{ marginBottom: "1em" }} key={index}>
                {i}
              </div>
            )
          )}
        </div>
      </div>
    );
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingAction: false }));
  }
}
