import { useEffect, useRef } from "react";

export type SetTimeout = (fn: () => void, millis: number) => any

function useSetTimout() {

  const ref = useRef<any[]>([]);

  useEffect(() => {

    const handles = ref.current;

    return () => {
      handles.forEach(i => clearTimeout(i))
    }

  }, [ ref ])

  return (fn: () => void, millis: number) => {
    const handle = setTimeout(fn, millis)
    ref.current.push(handle);
    return handle;
  }


}

export default useSetTimout;