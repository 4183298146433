import { http } from "../api/http";
import { baseUrl } from "../constants";

function useEventPartner() {
  const getEventPartnerById = (eventPartnerId, partner) =>
    http.get(baseUrl + "/partner/event/" + eventPartnerId + "/" + partner);

  return { getEventPartnerById };
}

export default useEventPartner;
