import { Route, Switch } from "react-router-dom";
import { Title } from "../../../components/Titles";
import { useMenu } from "../../service/context/globalContext";
import Card from "../ui/Card";
import Platform from "../ui/Platform";
import SubTitle from "../ui/SubTitle";
import Tabs from "../ui/Tabs";
import TagManagementPage from "./TagManagementPage";

export default function TagRoutes() {
  const menu = useMenu("/tag");

  return (
    <Platform>
      <Title>{menu.name}</Title>
      <SubTitle>Listagem, criação e alteração de Tags</SubTitle>
      <Tabs menu={menu} />
      <Card>
        <Switch>
          <Route exact path="/tag/list/:page">
            <TagManagementPage />
          </Route>
        </Switch>
      </Card>
    </Platform>
  );
}
