import { FormEvent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { History, SetState } from "../../types";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import useQuery from "../url/useQuery";
import useClientRequests, {
  ClientSearchForm,
  ClientSearchItemView,
  ClientSearchView,
} from "./useClientRequests";

export default function useClientListPage() {
  const [state, setState] = useState<State>(initialState);

  const { search } = useClientRequests();

  const { openModal } = useModal();

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const query = useQuery();

  const { filter: formFilter } = state;

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const findClients = useCallback(
    () => baseFindOnSubmit(search, page, openModal, setState, filter),
    [page, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFilterOnSubmit(history, formFilter, e),
    [formFilter]
  );

  const handleEditOnClick = (clientId: number) =>
    baseHandleEditOnClick(history, clientId);

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [formFilter]
  );

  useEffect(() => {
    findClients();
  }, [page, filter]);

  return {
    ...state,
    page,
    handleFindOnSubmit,
    handleOnChange,
    handleEditOnClick,
    handlePageOnChange,
  };
}

interface State {
  filter: string;
  totalPages: number;
  items: ClientSearchItemView[];
  loading: boolean;
}

const initialState: State = {
  filter: "",
  totalPages: 1,
  items: [],
  loading: false,
};

async function baseFindOnSubmit(
  search: (params: ClientSearchForm) => Promise<ClientSearchView>,
  page: number,
  openModal: OpenModal,
  setState: SetState<State>,
  filter: string
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));
    const response = await search({ filter, page });
    setState((prev) => ({
      ...prev,
      items: response.items,
      totalPages: response.total,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFilterOnSubmit(
  history: History,
  filter: string,
  e: FormEvent
) {
  e.preventDefault();

  if (filter) {
    history.push("/client/list/1?filter=" + filter);
  } else {
    history.push("/client/list/1?filter=");
  }
}

function baseHandleEditOnClick(history: History, clientId: number) {
  history.push(`/client/edit/${clientId}`);
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number
) {
  history.push("/client/list/" + page + "?filter=" + filter);
}
