export function getRandomRgb() {
  let num = Math.round(0xffffff * Math.random());
  let r = num >> 16;
  let g = (num >> 8) & 255;
  let b = num & 255;

  return [r, g, b];
}

export function tableToCSV(table: HTMLTableElement) {
  const csvData: string[] = [];

  const rows = table.getElementsByTagName("tr");

  for (let i = 0; i < rows.length; i++) {
    const cols = rows[i].querySelectorAll("td,th");

    const csvrow = [];
    for (let j = 0; j < cols.length; j++) {
      csvrow.push(cols[j].innerHTML);
    }

    csvData.push(csvrow.join(","));
  }

  return csvData.join("\n");
}
