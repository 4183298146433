import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Loading from "../../../components/Loading";
import useCompanyRequests, {
  FetchCompanyQueue,
} from "../../service/company/useCompanyRequests";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useProductRequests, {
  FetchProductQueue,
} from "../../service/product/useProductRequests";

const moderationQueueTypes = ["product", "company"];

export default function LoadNextItem() {
  const { type } = useParams<{ type: string }>();
  const { openModal } = useModal();
  const { fetchCompanyQueue } = useCompanyRequests();
  const { fetchProductQueue } = useProductRequests();

  const getNextProductToModerate = () =>
    baseGetNextProductToModerate(fetchProductQueue, openModal);

  const getNextCompanyToModerate = () =>
    baseGetNextCompanyToModerate(fetchCompanyQueue, openModal);

  useEffect(() => {
    if ("product" === type) {
      getNextProductToModerate();
    }
    if ("company" === type) {
      getNextCompanyToModerate();
    }
  }, [type]);

  if (!moderationQueueTypes.includes(type)) {
    return (
      <h2>
        Não foi possível verificar qual tipo de item está moderando. Por favor
        volte ao menu principal e reinicie a fila de moderação
      </h2>
    );
  }

  return (
    <div>
      <h2>
        Por favor, aguarde enquanto o próximo{" "}
        {"product" === type ? "EAN" : "CNPJ"} é carregado.
      </h2>
      <Loading />
    </div>
  );
}

async function baseGetNextProductToModerate(
  fetchProductQueue: FetchProductQueue,
  openModal: OpenModal
) {
  try {
    const data = await fetchProductQueue();
    const token = data.token ?? "";
    if (data.ean) {
      return window.location.replace(
        "/product2/" + data.ean + "?token=" + token
      );
    }
    openModal("Não foi encontrado um EAN disponível para ser moderado");
  } catch (err) {
    openModal("Erro ao buscar um EAN da fila de produtos");
  }
}

async function baseGetNextCompanyToModerate(
  fetchCompanyQueue: FetchCompanyQueue,
  openModal: OpenModal
) {
  try {
    const nextComp = await fetchCompanyQueue();
    if (nextComp) {
      return window.location.replace("/company/" + nextComp);
    }
    openModal("Não foi encontrado um CNPJ disponível para ser moderado");
  } catch (err) {
    openModal("Erro ao buscar um CNPJ da fila de empresas");
  }
}
