import Cookies from "js-cookie";
import { AuthUserModel } from "../../../authentication/login";
import { AUTH_CREDENTIALS } from "../../../constants";
import { getCurrentDomain as domain } from "../getCurrentDomain";

const accessToken = (token: string) =>
  Cookies.set(AUTH_CREDENTIALS.ACCESS_TOKEN, token, { domain });

const authUser = (user: string, claims: AuthUserModel) =>
  Cookies.set(
    AUTH_CREDENTIALS.AUTH_USER,
    JSON.stringify({
      user: user,
      firstName: claims.firstName,
      lastName: claims.lastName,
      role: claims.role,
      is_staff: claims.is_staff,
      company: claims.company,
      groups: claims.groups,
      userId: claims.userId,
    }),
    { domain }
  );

const refreshToken = (refreshToken: string) =>
  window.localStorage.setItem(AUTH_CREDENTIALS.REFRESH_TOKEN, refreshToken);

export const setCredentials = {
  accessToken,
  authUser,
  refreshToken,
};
