import { useEffect, useState } from "react";

import Button from "../components/Button";
import Collapse from "../components/Collapse";
import { Col, Row } from "../components/Grid";
import Template from "../components/Template";
import { Title } from "../components/Titles";
import CategoryDisplay from "../components/productCategory/display";
import SaveProductCategoryForm from "../components/productCategory/form";
import Search from "../components/productCategory/search";

import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import useProductCategory from "../hooks/useProductCategory";
import { authCredentials } from "../utils/authCredentials";

export default function ProductCategoryPage() {
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState(null);
  const [formType, setFormType] = useState("PRODUCTCATEGORY");
  const { createModal } = useModal();
  const { createEvent } = useHorusAccess();

  const {
    fetchCategories,
    fetchNielsenCategories,
    fetchProdCatAttachedToNielsen,
  } = useProductCategory();

  const authUser = authCredentials.get.authUser();

  //Helpers
  const goToEdit = async (categoryToUpdate) => {
    createModal(
      <SaveProductCategoryForm
        category={categoryToUpdate}
        moderator={authUser.username}
        formType={formType}
        fetchCategories={fetchCategories}
      />,
      { width: "1500px", backDropClose: false }
    );
  };

  const changeFormType = (type) => {
    setFormType(type);
    setCategory(null);
  };

  const onSelectNielsenCategory = (option) => {
    setCategory(option);
    fetchProdCatAttachedToNielsen(option.id).then((response) => {
      setCategories(response);
    });
  };

  // Log access event
  useEffect(() => {
    let event = {};

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access product categories";

    createEvent(event);
  }, []);

  return (
    <Template>
      <Title>
        {formType === "PRODUCTCATEGORY"
          ? "Categorias de produto"
          : "Categorias Nielsen"}
      </Title>
      <Row>
        <Col>
          <Search
            category={category}
            setCategory={setCategory}
            onSelectNielsenCategory={onSelectNielsenCategory}
            goToEdit={goToEdit}
            formType={formType}
            fetchCategories={fetchCategories}
            fetchNielsenCategories={fetchNielsenCategories}
          />
        </Col>
        <Col>
          <Button
            style={{ marginTop: "5px", height: "45px" }}
            type="button"
            layout="info"
            onClick={() => {
              setCategory(null);
              goToEdit();
            }}
          >
            {formType === "PRODUCTCATEGORY"
              ? "Criar Nova Categoria de Produto"
              : "Criar Nova Categoria Nielsen"}
          </Button>
        </Col>
        <Col>
          <div style={{ marginTop: "18px" }}>
            <input
              type="checkbox"
              onChange={() => {
                formType === "PRODUCTCATEGORY"
                  ? changeFormType("NIELSENCATEGORY")
                  : changeFormType("PRODUCTCATEGORY");
              }}
            />
            Categoria Nielsen
          </div>
        </Col>
      </Row>
      <Collapse collapsed={!category}>
        <CategoryDisplay
          category={formType === "PRODUCTCATEGORY" ? category : categories}
          goToEdit={goToEdit}
        />
      </Collapse>
    </Template>
  );
}
