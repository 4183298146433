import styled, { css } from 'styled-components'

export const Row = styled.div`
  margin 0 -10px;
  :after {
    clear: both;
    display: block;
    content: ' ';
  }
`

export const Col = styled.div`
  float: left;
  padding: 0 10px;
  box-sizing: border-box;
  min-height: 1px;
  ${ props => {
    if (props.width) {
      return css`width: ${ props.width };`
    }
    return null
  } }
`