import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult } from "../../components/form/AsyncSelect";
import {
  AsyncSelectModalForm,
  PaginationType,
} from "../../components/form/AsyncSelectModal";
import { BrandWithCategoryAddOn } from "./useCompanyRequests";

interface Response<T> {
  result: Array<{ value: string; description: string; addOns?: T }>;
}

export interface CompanyBrandItem {
  id: number;
  name: string;
  file: string;
  economicGroup: string;
  wideBrand: boolean;
  categoryId: number;
  categoryName: string;
  channel: number;
  cnpj8: string;
}

export type UseFetchCompanyOpts = typeof useFetchCompanyOpts;
export type FindCompanyBrandModalOpts =
  ReturnType<UseFetchCompanyOpts>["findCompanyBrandModalOpts"];

export default function useFetchCompanyOpts() {
  const findCompanyBrandOpts = (filter: string, offset: number | undefined) =>
    baseFindCompanyBrandOpts(filter, offset);

  const findCompanyCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchCompanyCategoryOpts(filter, offset);

  const findCompanyBrandModalOpts = (params?: AsyncSelectModalForm) =>
    baseFindCompanyBrandModalOpts(params);

  return {
    findCompanyBrandOpts,
    findCompanyCategoryOpts,
    findCompanyBrandModalOpts,
  };
}

async function baseFindCompanyBrandOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<BrandWithCategoryAddOn>> {
  const response = await http.get(`${baseUrl}/api/v1/company/brand/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as Response<BrandWithCategoryAddOn>;
  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
      addOns: i.addOns,
    })),
    hasMore: data.result.length === 10,
  };
}

async function baseFetchCompanyCategoryOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(
    `${baseUrl}/api/v1/company/category/options`,
    {
      params: { filter, offset, limit: 10 },
    }
  );

  const data = response.data as Response<null>;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

async function baseFindCompanyBrandModalOpts(
  params?: AsyncSelectModalForm
): Promise<PaginationType<CompanyBrandItem>> {
  const response = await http.get(
    `${baseUrl}/api/v1/company/brand/modal-options`,
    { params }
  );
  return response.data;
}
