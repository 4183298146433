import { http } from "../api/http";
import { baseUrl } from "../constants";

function useHorusAccess() {
  const getAllDashboardAccessHistoryByUserId = (userId) =>
    http.get(baseUrl + "/event/" + userId);

  const getDashboardDetailedAccessHistory = (userId) =>
    http.get(baseUrl + "/event-detailed/" + userId);

  const getCompanyUsersLastUse = (companyId) =>
    http.get(baseUrl + "/event-last/" + companyId);

  const getAccessHistoryByTypeValue = (type, value) =>
    http.get(baseUrl + "/event/filter?type=" + type + "&value=" + value);

  const createEvent = (event) => http.post(baseUrl + "/event", event);

  return {
    getAllDashboardAccessHistoryByUserId,
    getDashboardDetailedAccessHistory,
    getCompanyUsersLastUse,
    getAccessHistoryByTypeValue,
    createEvent,
  };
}

export default useHorusAccess;
