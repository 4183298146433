import styled from 'styled-components'

export const Title = styled.h1`
  font-size: 32px;
  margin: 15px 0 10px 0;
  padding: 0 0 2px 0;
  border-bottom: solid 1px black;
`

export const SubTitle = styled.h2`
  font-size: 22px;
  font-weight: normal;
  margin: 15px 0 10px 0;
  padding: 0;
`

export const SubTitle2 = styled.h3`
  font-size: 14px;
  font-weight: bolder;
  margin: 10px 0 10px 0;
  padding: 0;
`