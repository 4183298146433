import React from "react";
import Input from "../../Input";
import FGVSelect from "../components/fgvCategories";

const Fgv = ({ plan, setPlan, fgvCategories }) => {
  const [childCategories, setChildCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (options) => {
    setPlan((plan) => {
      if (options.includes("all")) {
        return {
          ...plan,
          categories: childCategories.map((category) => category.id.toString()),
        };
      }
      if (options.includes("remove all")) {
        return { ...plan, categories: [] };
      }
      return { ...plan, categories: options };
    });
  };

  const loadCategories = async () => {
    try {
      setLoading(true);
      const categories = await fgvCategories();
      childCategories && setChildCategories(categories);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  const options = [
    { id: "all", name: "Selecionar Tudo" },
    ...childCategories.map((category, i) => ({
      key: category.id,
      id: category.id,
      name: category.name,
    })),
    { id: "remove all", name: "Remover Tudo" },
  ];

  React.useEffect(
    () => !loading && !childCategories.length && loadCategories()
  );

  return (
    <>
      {
        plan?.id ? (
          <>
            <Input
              label="Recorrência"
              value={plan?.params?.recurrence}
              readOnly
            />

            <Input
              label="Parcelas"
              value={plan?.params?.installments}
              readOnly
            />
          </>
        ) : (
          <>
            <Input
              label="Recorrência"
              placeholder="Selecione..."
              onChange={({ target }) =>
                setPlan({
                  ...plan,
                  params: { ...plan.params, recurrence: target.value === "null" ? null : target.value, installments: 1 },
                })
              }
              type="text"
              options={[
                { id: null, name: "Sem recorrência" },
                { id: "MONTH", name: "Mensal" },
                { id: "YEAR", name: "Anual" },
              ]}
              value={plan?.params?.recurrence}
            />

            <Input
              label="Parcelas"
              placeholder="Selecione..."
              onChange={({ target }) =>
                setPlan({
                  ...plan,
                  params: { ...plan.params, installments: target.value },
                })
              }
              type="number"
              value={plan?.params?.installments}
              min="1"
              max={plan?.params?.recurrence === "MONTH" ? 1 : 3}
            />
          </>
          )
      }

      <FGVSelect
        plan={plan}
        setPlan={setPlan}
        fgvCategories={childCategories}
      />

      <Input
        label="Cupom de desconto"
        placeholder="Digite um cupom válido"
        onChange={({ target }) =>
          setPlan({
            ...plan,
            params: { ...plan.params, discountCoupon: (target.value === "" ? null : target.value) },
          })
        }
        value={plan?.params?.discountCoupon}
        type="text"
        max="12"
      />
    </>
  );
};

export default Fgv;
