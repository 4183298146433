import { useCallback } from "react";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { FetchDataResult } from "../../../components/form/AsyncSelect";

export default function useFetchUserOptions(
  params: { clientId?: number } = {}
) {
  const { clientId } = params;

  return useCallback(
    (filter: string, offset: number | undefined) =>
      baseFetchOpts(clientId, filter, offset),
    [clientId]
  );
}

interface Response {
  result: Array<{ value: string; description: string }>;
}

async function baseFetchOpts(
  clientId: number | undefined,
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/user/options`, {
    params: { filter, offset, limit: 10, clientId },
  });

  const data = response.data as Response;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}
