import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import ManufacturerDelete from "../../components/product/manufacturer/ManufacturerDelete";
import ManufacturerEdit from "../../components/product/manufacturer/ManufacturerEdit";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import useQuery from "../url/useQuery";

export default function useManufacturerManagement() {
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const { filter: formFilter, pageSize } = state;

  const query = useQuery();

  const filter = query.get("filter") || "";

  const { openModal } = useModal();

  const handleOnChange = useHandleOnChange(setState);

  const findManufacturers = useCallback(
    () => baseFindManufacturers(page, pageSize, openModal, setState, filter),
    [page, pageSize, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, pageSize, formFilter, e),
    [history, pageSize, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) =>
      baseHandlePageOnChange(history, formFilter, page, pageSize),
    [history, formFilter, pageSize]
  );

  const handleEditOnClick = useCallback(
    (selectedManufacturer: Manufacturer) =>
      openModal(
        <ManufacturerEdit
          selectedManufacturer={selectedManufacturer}
          reloadManufactures={findManufacturers}
        />
      ),
    [openModal, findManufacturers]
  );

  const handleOnDeleteClick = useCallback(
    (manuf: Manufacturer) => openModal(<ManufacturerDelete manuf={manuf} />),
    [openModal]
  );

  useEffect(() => {
    findManufacturers();
  }, [findManufacturers]);

  return {
    ...state,
    page,
    findManufacturers,
    handleFindOnSubmit,
    handlePageOnChange,
    handleOnChange,
    handleEditOnClick,
    handleOnDeleteClick,
  };
}

export type ChangeEvent = { target: { value?: any; name?: any } };

export interface State {
  filter: string;
  pageSize: number;
  totalPages: number | null;
  items: Array<Manufacturer> | null;
  loading: boolean;
}

export interface Manufacturer {
  id: number | null;
  name: string;
  site: string | null;
  cnpj: Array<string>;
  brandQtt: number | null;
  ean13: string | null;
}

export interface Validation {
  name: string[];
  site: string[];
  cnpj: string[];
  errors: number;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  pageSize: 20,
  totalPages: null,
  loading: false,
  items: null,
};

async function baseFindManufacturers(
  page: number,
  pageSize: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(
      `${baseUrl}/api/v1/product/manufacturer/search-description`,
      {
        params: { page, pageSize, filter },
      }
    );

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  pageSize: number,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("?pageSize=" + pageSize + "&filter=" + filter);
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number,
  pageSize: number
) {
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push(
    "/product/manufacturer/" +
      page +
      "?pageSize=" +
      pageSize +
      "&filter=" +
      filter
  );
}
