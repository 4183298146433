import useProductCategoryListPage from "../../../service/product/useProductListPage";
import AsyncSelect from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";


function ProductListPage() {

  const {

    fetchingCategory, fetchProductCategoryOpts, handleProductCategorySelectOnChange, handleNielsenCategorySelectOnChange, handleManufacturerSelectOnChange,
    handleProductBrandSelectOnChange, fetchManufacturerOpts, fetchNielsenCategoryOpts, fetchProductBrandOpts,
    selectedProductCategories, selectedNielsenCategories, selectedBrands, selectedManufacturers, listEans, listIds, removeProductCategory, removeNielsenCategory,
    removeManufacturer, removeProductBrand, data, fetchData, setState, onDownloadCsvClick, handleModerateOnClick,
    loading, loadingExcel

  } = useProductCategoryListPage();

  return (
    <div>
      <SectionTitle
        title="Listagem de Produto"
        description="Consulte produtos de acordo com os filtros selecionados"
      />
      {
        fetchingCategory ? <div style={{ padding: ".2em", fontSize: ".8em" }}>Buscando categoria......</div> : (
          (
            <>
              <Input
                value={listIds}
                onChange={e => setState(prev => ({ ...prev, listIds: e.target.value }))}
                placeholder="Pode ser informado uma lista de IDs separados por virgula. Ex: 00000000000,00000000000,00000000000"
                label="Filtrar por ID"
              />
              <Input
                value={listEans}
                onChange={e => setState(prev => ({ ...prev, listEans: e.target.value }))}
                placeholder="Pode ser informado uma lista de EANs separados por virgula. Ex: 00000000000,00000000000,00000000000"
                label="Filtrar por EAN"
              />
              <AsyncSelect
                fetchData={fetchProductCategoryOpts}
                onChange={handleProductCategorySelectOnChange}
                label="Filtrar por segmento"
              />

              <div className={styles.removeLines}>
                {
                  selectedProductCategories.map(category => (

                    <div key={category.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeProductCategory(category.id)}>
                        -
                      </button>
                      {category.name}
                    </div>

                  ))
                }
              </div>

              <AsyncSelect
                fetchData={fetchNielsenCategoryOpts}
                onChange={handleNielsenCategorySelectOnChange}
                label="Filtrar por categoria"
              />

              <div className={styles.removeLines}>
                {
                  selectedNielsenCategories.map(categoryNielsen => (

                    <div key={categoryNielsen.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeNielsenCategory(categoryNielsen.id)}>
                        -
                      </button>
                      {categoryNielsen.name}
                    </div>

                  ))
                }
              </div>

              <AsyncSelect
                fetchData={fetchProductBrandOpts}
                onChange={handleProductBrandSelectOnChange}
                label="Filtrar por marca"
              />

              <div className={styles.removeLines}>
                {
                  selectedBrands.map(brand => (

                    <div key={brand.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeProductBrand(brand.id)}>
                        -
                      </button>
                      {brand.name}
                    </div>

                  ))
                }
              </div>

              <AsyncSelect
                fetchData={fetchManufacturerOpts}
                onChange={handleManufacturerSelectOnChange}
                label="Filtrar por fabricante"
              />

              <div className={styles.removeLines}>
                {
                  selectedManufacturers.map(manufacturer => (

                    <div key={manufacturer.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeManufacturer(manufacturer.id)}>
                        -
                      </button>
                      {manufacturer.name}
                    </div>

                  ))
                }
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button type="confirm" onClick={() => fetchData(1)} disabled={loading}>
                    {loading ? "Carregando..." : "Filtrar"}
                  </Button>
                </div>
                <div>
                  <Button type="confirm" onClick={onDownloadCsvClick} disabled={loadingExcel}>
                    {loadingExcel ? "Baixando..." : "Baixar Excel"}
                  </Button>
                </div>
              </div>
              <Spacer ratio={2 / 3} />
            </>
          )
        )
      }

      <TableResponsiveWrapper>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID do produto</th>
              <th>EAN</th>
              <th className={`${styles.fullWidth} ${styles.left}`}>SKU</th>
              <th>Qtd Unit</th>
              <th>Qtd Pack</th>
              <th>Unidade de medida</th>
              <th>ID cat. tia</th>
              <th>Cat. tia</th>
              <th>ID categoria</th>
              <th>Categoria</th>
              <th>ID segmento</th>
              <th>Segmento</th>
              <th>ID fabricante</th>
              <th>Fabricante</th>
              <th>ID marca</th>
              <th>Marca</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {
              !data ? (
                <tr>
                  <td colSpan={15}>
                    Carregando...
                  </td>
                </tr>
              ) : (
                data.items.map(product => (
                  <tr key={product.productId}>
                    <td>
                      <Button small type="warning" onClick={() => handleModerateOnClick(product.ean)}>
                        Moderar
                      </Button> {' '}
                    </td>
                    <td>
                      {product.productId}
                    </td>
                    <td>
                      {product.ean}
                    </td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {product.sku}
                    </td>
                    <td>
                      {product.quantityUnit}
                    </td>
                    <td>
                      {product.quantityPack}
                    </td>
                    <td>
                      {product.unitMeasurement}
                    </td>
                    <td>
                      {product.auntCategoryId}
                    </td>
                    <td>
                      {product.auntCategoryDescription}
                    </td>
                    <td>
                      {product.nielsenId}
                    </td>
                    <td>
                      {product.nielsenDescription}
                    </td>
                    <td>
                      {product.categoryId}
                    </td>
                    <td>
                      {product.categoryDescription}
                    </td>
                    <td>
                      {product.manufacturerId}
                    </td>
                    <td>
                      {product.manufacturerDescription}
                    </td>
                    <td>
                      {product.brandId}
                    </td>
                    <td>
                      {product.brandName}
                    </td>
                    <td>
                      {product.status}
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      {
        !data ? null : (
          <Pagination
            page={data.page}
            totalPages={data.total}
            onChangePage={fetchData}
          />
        )
      }
    </div>
  );

}

export default ProductListPage;