import { http } from "../api/http";
import { baseUrl } from "../constants";

function useNewClient() {
  const createClient = (newClient) =>
    http.post(baseUrl + "/new-client", newClient);

  return { createClient };
}

export default useNewClient;
