import Input from "../../../form/Input";
import AsyncSelect from "../../../form/AsyncSelect";
import useProductCategoryCreate from "./useProductCategoryCreatePage";
import Select from "../../../form/Select";
import Button from "../../../form/Button";
import ValidationMessages from "../../../form/ValidationMessages";
import EditProductCategoryHeader from "./EditProductCategoryHeader";
import LoadingCategory from "./LoadingCategory";
import { SectionTitle } from "../../../ui/SectionTitle";

const volumeUnities = [
  { id: "Und", name: "Unidade" },
  { id: "Lt", name: "Litro" },
  { id: "Kg", name: "Quilo" },
]

interface Props {
  edit?: boolean 
}

function ProductCategoryCreatePage(props: Props) {

  const { edit = false } = props

  const productCategoryCreate = useProductCategoryCreate(edit);

  const {

    validation, showForm, form, sendingCategory, fetchingCategory, buttonText, selectedFatherCategory, selectedEditCategory, handleEditCategoryOnChange,
    handleFormOnChange, fetchProductCategoryOpts, handleProductCategoryOnChange, handleSendButtonOnClick

  } = productCategoryCreate;

  return (
    <div>
      {
        !edit ? (
          <SectionTitle
            title="Criar categoria de produto"
            description="Criar uma nova categoria de produto"
          />
        ) : (
          <div>
            <SectionTitle
              title="Editar categoria de produto"
              description="Alterar uma categoria de produto já existente"
            />
            <AsyncSelect
              fetchData={ fetchProductCategoryOpts }
              onChange={ handleEditCategoryOnChange }
              value={ selectedEditCategory }
              label="Selecione a categoria para editar"
            />
          </div>
        )
      }
      {
        fetchingCategory ? <LoadingCategory /> : (
          !showForm ? null : (
            <>

              <EditProductCategoryHeader { ...productCategoryCreate } />

              <Input
                label="Novo Nome"
                name="description"
                value={ form.description }
                onChange={ handleFormOnChange }
              />
  
              <ValidationMessages messages={ validation.description } />
  
              <AsyncSelect
                fetchData={ fetchProductCategoryOpts }
                onChange={ handleProductCategoryOnChange }
                value={ selectedFatherCategory }
                label="Categoria Pai"
              />
  
              <ValidationMessages messages={ validation.fatherCategoryId } />
  
              <Select
                options={ volumeUnities }
                value={ form.volumeUnity }
                onChange={ handleFormOnChange }
                label="Unidade de Volume"
                name="volumeUnity"
              />
  
              <ValidationMessages messages={ validation.volumeUnity } />
  
              <Button type="confirm" disabled={ validation.errors > 0 || sendingCategory } onClick={ handleSendButtonOnClick }>
                { buttonText }
              </Button>   
            
            </>
          )
        )
      }

    </div>
  );

}

export default ProductCategoryCreatePage;