import React from "react";
import Table from "../../Table";
import { Title } from "../../Titles";
import Button from "../../Button";
import { mediaPrefix } from "../../../constants";

export default function BrandDisplay({ brand, goToEdit }) {

  return (
    <>
      <Title>Marcas</Title>
      <Table>
        <thead>
          <tr>
            <th className="align-center">Id</th>
            <th className="align-center">Nome</th>
            <th className="align-center">Marca Própria</th>
            <th className="align-center">Grupo Econômico</th>
            <th className="align-center">Fabricante</th>
            <th className="align-center">Imagem</th>
            <th className="align-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          {
            !brand ? null : (
              <tr key={brand.id}>
                <td className="align-center">{brand.id}</td>
                <td className="align-center">{brand.name}</td>
                <td className="align-center">{brand.ownBrand ? "Sim" : "Não"}</td>
                <td className="align-center">{brand.economicGroup}</td>
                <td className="align-center">{brand.manufacturer}</td>
                <td className="align-center"><img src={mediaPrefix + brand.image} alt=""/></td>
                <td className="align-center">
                  <Button layout="info" onClick={() => goToEdit(brand)}>
                    Editar
                  </Button>
                </td>
              </tr>
            )
          }
        </tbody>
      </Table>
    </>
  );
}
