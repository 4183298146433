import React from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Collapse from "../components/Collapse";
import Template from "../components/Template";
import { SubTitle } from "../components/Titles";
import Editor from "../components/plans/details";
import useCompany from "../hooks/useCompany";
import usePlans from "../hooks/usePlans";

const PlansDetails = () => {
  const { clientId, userId, clientName, planId } = useParams();

  const [plan, setPlan] = React.useState();
  const [plans, setPlans] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [manufacturerName, setManufacturerName] = React.useState(null);
  const [childCategories, setChildCategories] = React.useState(null);
  const [channels, setChannels] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const {
    getPlans,
    getCategoriesPowerBi,
    getManufacturers,
    getManufacturer,
    getFgvCategories,
    getMetricsCategories,
    createPlan: createPlanHook,
    updatePlan: updatePlanHook,
    cancelPlan: cancelPlanHook,
    getPaymentCollection,
  } = usePlans();

  const { getChannels } = useCompany();

  const loadPlan = async () => {
    !client && setClient({ id: clientId, name: clientName });
    !plans && (await getPlans(clientId).then((data) => setPlans(data)));
  };

  const fetchManufacturer = async () => {
    !manufacturerName &&
      (await getManufacturer(plan.params.manufacturer).then((data) =>
        setManufacturerName(data.name)
      ));
  };

  const externalPayment = async (plan) => {
    if (plan.plan === "FGV_PREMIUM" || plan.plan === "METRICS_WEB") {
      await getPaymentCollection(plan?.id).then((data) => {
        data === "ERROR" ? console.log("ERROR") : setPlan({ ...plan, ...data });
      });
    }
  };

  const fetchChannels = React.useCallback(async () => {
    !channels &&
      (await getChannels().then((response) => {
        setChannels(response.data.channels);
      }));
  }, [channels, setChannels, getChannels]);

  React.useEffect(() => {
    if (planId === "add") {
      setPlan({ id: null, plan: "POWERBI", clientId: clientId });
    } else if (plans && planId) {
      const plan = plans.find((item) => Number(item.id) === Number(planId));
      plan && setPlan(plan);
      externalPayment(plan);
    }
  }, [plans, planId]);

  const loadChildCategories = async () => {
    const categories = await getCategoriesPowerBi();
    categories && setChildCategories(categories);
  };

  const createPlan = async () => {
    if (window.confirm("Criar plano com os dados informados?")) {
      setLoading(true);
      await createPlanHook(userId, plan);
      setLoading(false);
    }
  };

  const updatePlan = async () => {
    if (
      window.confirm(
        "Atualizar assinatura " +
          plan.id +
          " do plano: " +
          plan.plan +
          " \ncom os dados informados?. Atentar-se à possível necessidade de alteração no valor do plano de acordo com as novas configurações." +
          " \nAssinaturas com cobranças externas terão novos valores alterados na próxima fatura do cartão."
      )
    ) {
      setLoading(true);
      await updatePlanHook(userId, plan);
      setLoading(false);
    }
  };

  const cancelPlan = async () => {
    if (
      window.confirm(
        "Cancelar assinatura " +
          plan.id +
          " do plano: " +
          plan.plan +
          " ?." +
          " \nAssinaturas com cobranças externas terão as faturas, futuras e pendentes, canceladas"
      )
    ) {
      setLoading(true);
      await cancelPlanHook(userId, plan);
      setLoading(false);
    }
  };

  const title = () =>
    plan?.id
      ? "Editar Assinatura " + plan?.plan
      : "Criar Assinatura " + plan?.plan;

  React.useEffect(() => !childCategories && loadChildCategories);

  React.useEffect(() => !client && !plans && loadPlan());

  React.useEffect(() => plan?.params?.manufacturer > 0 && fetchManufacturer());

  React.useEffect(() => !channels && fetchChannels());

  return (
    <>
      {loading ? <div className="overlay"></div> : null}
      <Template>
        <Collapse>
          <SubTitle>{title()}</SubTitle>
          <Editor
            setPlan={setPlan}
            plan={plan}
            childCategories={childCategories && childCategories}
            channels={channels}
            getManufacturers={getManufacturers}
            manufacturerName={manufacturerName}
            setManufacturerName={setManufacturerName}
            getFgvCategories={getFgvCategories}
            getMetricsCategories={getMetricsCategories}
            createPlan={createPlan}
            updatePlan={updatePlan}
            cancelPlan={cancelPlan}
          />
        </Collapse>
      </Template>
    </>
  );
};

export default PlansDetails;
