import Button from "../../Button";
import { Col, Row } from "../../Grid";
import Input from '../../Input';
import AsyncSelect2 from '../../AsyncSelect2';
import { isEmptyObj, isNullText, validateNumericSize, validateTextSize } from "../../../utils";

const leftCol = "66%";
const rightCol = "33%";

export default function ProductCategoryForm({categoryToSave, editTag, onChangeTag, fetchParentCategories, submitCategory, updateCategory}) {

  const isValidCategory = category => {
    if (
      (!categoryToSave || isEmptyObj(categoryToSave)) ||
      (isNullText(categoryToSave?.name?.trim()))
    ){
      return false;
    }
    
    return true;
  }

  return (
    <div>
      <Row>
        <Col width="100%">
          <Row>
            <Col width={ leftCol }>
              <Input
                label="Categoria*"
                placeholder="Nome"
                value={ categoryToSave?.name }
                onChange={ e => updateCategory({ name: validateTextSize(e.target.value, 1, 100) }) }
              />
            </Col>

            <Col width={ rightCol }>
              <Input
                label="Unidade de Medida*"
                placeholder="Selecione..."
                value={ categoryToSave?.unitOfMeasurement }
                onChange={ e => updateCategory({ unitOfMeasurement: isNullText(e.target.value) ? null : e.target.value }) }
                options={[ { id: null, name: "Inexistente" }, { id: "Kg", name: "Quilo(kg)" }, { id: "Lt", name: "Litro(lt)" }, { id: "Und", name: "Unidade(Und)" }, ]}
              />
            </Col>
          </Row>

          <Row>
            <Col width="33%">
              <Input
                label="Preço Médio"
                type="number"
                placeholder="Preço Médio"
                value={ categoryToSave?.median }
                onChange={ e => updateCategory({ median: validateNumericSize(e.target.value, 1, 20) }) }
              />
            </Col>
            
            <Col width="33%">
              <AsyncSelect2
                label="Categoria Pai"
                placeholder="Selecione..."
                value={ categoryToSave?.parentId }
                valueName={ categoryToSave?.parentCategory }
                onChange={ option => updateCategory({
                    parentId: option ? option.id : null, parentCategory: option ? option.name : null, parentType: option ? option.type : null
                }) }
                fetchData={ fetchParentCategories }
              />
            </Col>

            <Col width="33%">
              <Input
                label="Densidade"
                type="number"
                placeholder="Densidade"
                value={ categoryToSave?.density }
                onChange={ e => updateCategory({ density: validateNumericSize(e.target.value, 1, 20) }) }
              />
            </Col>
          </Row>
          
          <Row>
            <Col width="25%">
              { 
                editTag ? (
                  <Input
                    label="Tipo de Tag"
                    placeholder="Selecione..."
                    value={ categoryToSave?.tagCes }
                    onChange={ e => updateCategory({ tagCes: isNullText(e.target.value) ? null : e.target.value }) }
                    options={[ { id: null, name: "Inexistente" }, { id: "SABOR", name: "Sabor" }, { id: "ESSENCIA", name: "Essência" }, { id: "COR", name: "Cor" }, ]}
                  />
                ): (
                  <Input
                    label="Tipo de Tag"
                    placeholder="Selecione..."
                    value={ categoryToSave?.tagCes }
                    readOnly
                  />
                )
              }
            </Col>
            <br/>
            <Col>
             <div style={{marginTop: "6px"}}>
                <input 
                  type="checkbox"
                  checked={ editTag }
                  onChange={ () => onChangeTag() }
                />
                Editar Tags
              </div>
            </Col>
          </Row>
          <Row>
            <p>Outras Tags:</p>
            <Col width="20%">
              <input 
                type="checkbox"
                disabled={ !editTag }
                checked={ categoryToSave?.tagEspecificity }
                onChange={ () => updateCategory({ tagEspecificity: categoryToSave?.tagEspecificity ? 0: 1 }) }
              />
              Especificidade
            </Col>
            <Col width="20%">
              <input 
                type="checkbox"
                disabled={ !editTag }
                checked={ categoryToSave?.tagLine }
                onChange={ () => updateCategory({ tagLine: categoryToSave?.tagLine ? 0: 1 }) }
              />
              Linha
            </Col>
            <Col width="20%">
              <input 
                type="checkbox"
                disabled={ !editTag }
                checked={ categoryToSave?.tagLinearMeasure }
                onChange={ () => updateCategory({ tagLinearMeasure: categoryToSave?.tagLinearMeasure ? 0: 1 }) }
              />
              Metragem
            </Col>
            <Col width="20%">
              <input 
                type="checkbox"
                disabled={ !editTag }
                checked={ categoryToSave?.tagSeason }
                onChange={ () => updateCategory({ tagSeason: categoryToSave?.tagSeason ? 0: 1 }) }
              />
              Sazonal
            </Col>
            <Col width="20%">
              <input 
                type="checkbox"
                disabled={ !editTag }
                checked={ categoryToSave?.tagPackage }
                onChange={ () => updateCategory({ tagPackage: categoryToSave?.tagPackage ? 0: 1 }) }
              />
              Embalagem
            </Col>
          </Row>
        </Col>
      </Row>

      <br/>
      <Row>
        <Button
          type="button"
          layout="confirm"
          disabled={ !isValidCategory }
          onClick={() => submitCategory(categoryToSave)}
        >
          Salvar
        </Button>
      </Row>
    </div>
  )
}