import { http } from "../api/http";
import { baseUrl } from "../constants";

function useGroup() {
  const getAllGroups = () => http.get(baseUrl + "/groups");

  const getGroupById = (groupId) => http.get(baseUrl + "/groups/" + groupId);

  const getAllGroupUsersById = (groupId) =>
    http.get(baseUrl + "/group-users/" + groupId);

  const getAllUserGroupsById = (userId) =>
    http.get(baseUrl + "/user-groups/" + userId);

  const createGroup = (group) => http.post(baseUrl + "/groups", group);

  const updateGroup = (group) => http.put(baseUrl + "/groups", group);

  const attachGroupUsers = (groupUser) =>
    http.put(baseUrl + "/group-users", groupUser);

  const deleteGroup = (group) => http.delete(baseUrl + "/groups", group.id);

  const deleteGroupUser = (groupUserId) =>
    http.delete(baseUrl + "/group-users/" + groupUserId);

  const removeGroupDash = (companyDash) =>
    http.post(baseUrl + "/group-user-remove", companyDash);

  return {
    getAllGroups,
    getGroupById,
    getAllGroupUsersById,
    getAllUserGroupsById,
    createGroup,
    updateGroup,
    attachGroupUsers,
    deleteGroup,
    deleteGroupUser,
    removeGroupDash,
  };
}

export default useGroup;
