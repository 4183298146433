import { ChangeEvent, ReactNode, CSSProperties } from "react";
import styles from "./Form.module.css";

interface Props {
  label?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  value?: string | null
  name?: string,
  addons?: Array<ReactNode>,
  disabled?: boolean,
  valid?: boolean,
  minHeight?: CSSProperties['minHeight']
}

function TextArea(props: Props) {
  const { label, onChange, value, name, addons, disabled = false, valid = true, minHeight } = props;

  const handleHover = (event: React.MouseEvent<HTMLTextAreaElement>) => {
    event.currentTarget.style.cursor = "text";
  };

  return (
    <div className={ `${ styles.inputGroup } ${ disabled ? styles.inputLineDisabled : "" } ${ !valid ? styles.inputLineInvalid : "" }` }>
      {
        !label ? null : <label className={ styles.label }>{ label }</label>
      }
      <div className={ styles.inputLine }>
        <textarea
          className={ styles.input }
          value={ value === null ? "" : value }
          onChange={ onChange }
          name={ name }
          disabled={ disabled }
          style={{fontFamily: "inherit", minHeight, resize: 'none' }}
          onMouseOver={handleHover}
        />
        {
          addons
        }
      </div>
    </div>
  )
}

export default TextArea