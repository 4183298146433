import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

export type UseDashboardRequests = typeof useDashboardRequests;

export type FindById = ReturnType<UseDashboardRequests>["findById"];
export type FindDatasetIdBi =
  ReturnType<UseDashboardRequests>["findDatasetIdBi"];

export interface Dashboard {
  reportIdBi: string;
  groupIdBi: string;
  datasetIdBi: string;
  description: string;
  id: number;
  link: string;
  deployEnvironment: string;
  analysisServices: string[] | null;
}

export interface SearchDashboardDatasetView {
  datasetBiId: string;
}

export interface SearchDashboardDatasetForm {
  reportBiId: string;
  groupBiId: string;
}

export default function useDashboardRequests() {
  const findById = (id: number) => baseFindById(id);

  const findDatasetIdBi = (form: SearchDashboardDatasetForm) =>
    baseFindDatasetIdBi(form);

  return {
    findById,
    findDatasetIdBi,
  };
}

async function baseFindById(id: number): Promise<Dashboard> {
  const response = await http.get(`${baseUrl}/api/v1/dashboard/${id}`);
  return response.data as Dashboard;
}

async function baseFindDatasetIdBi(
  form: SearchDashboardDatasetForm
): Promise<SearchDashboardDatasetView> {
  const response = await http.post(`${baseUrl}/embed/datasets`, form);
  return response.data as SearchDashboardDatasetView;
}
