import { authCredentials } from "../utils/authCredentials";

function Gate(props) {
  const { allow = ["ADMIN"], children } = props;

  const { role } = authCredentials.get.authUser();

  if (role !== "ADMIN" && !allow.includes(role)) {
    return null;
  }

  return children;
}

export function SubGate(props) {
  const { allow = [], children } = props;
  const { role, groups } = authCredentials.get.authUser();
  let groupList = groups;

  if (groupList.includes(145)) groupList = [...groupList, 144];

  for (let i = 0; i < allow.length; i++) {
    if (role === "ADMIN" || groupList.includes(allow[i])) {
      return children;
    }
  }

  return null;
}

export default Gate;
