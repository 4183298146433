import { useCallback } from "react";

type SetState<T> = (update: (prev: T) => T) => void;
type Event = { target: { value?: any, name?: any } };

export type HandleOnChange = ReturnType<typeof useHandleOnChange>;

/**
 * Gera um callback de onChange que pode ser usado por vários formulários.
 * Ele irá atribuir o valor do evento no objeto do estado no campo name.
 * Por exemplo, o objeto é { x: "Valor" }
 * E o input é <input onChange={ handleOnChange } name="x" value={ state.x }>
 * O handleOnChange irá alterar o campo x porque tem o name x;
 */
function useHandleOnChange<T>(setState: SetState<T>) {

  return useCallback((e: Event) => setState((prev: any) => ({ 
    ...prev, [ e.target.name ]: e.target.value === "" ? null : e.target.value
  })), [ setState ]);

}

export default useHandleOnChange;