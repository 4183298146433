import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useHistory } from "react-router";
import useFetchClientOptions from "../../../service/client/useFetchClientOptions";
import { DashboardAssociation } from "../../../service/dashboard/types";
import useDashboardAssociationRequests, {
  FindByTargetIdAndDashboardId,
} from "../../../service/dashboard/useDashboardAssociationRequests";
import useDashboardRequests, {
  FindById as FindDashboardById,
} from "../../../service/dashboard/useDashboardRequests";
import useFetchDashboardOptions from "../../../service/dashboard/useFetchDashboardOptions";
import { getErrorCode } from "../../../service/error/getErrorCode";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import { NOT_FOUND_ERROR } from "../../../service/error/types";
import useLoading, {
  IsLoading,
  StartLoading,
} from "../../../service/loading/useLoading";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import { Option } from "../../form/AsyncSelect";
import useFetchGroupOptions from "../../user/group/useFetchGroupOptions";
import useFetchUserOptions from "../../user/user/useFetchUserOptions";
import { AssociationTargetData, AssociationType } from "./types";

export default function useChooseAssociationForm() {
  const { startLoading, isLoading } = useLoading();

  const [state, setState] = useState(initialState);

  const { findBy, associationType, client, dashboard, user, group } = state;

  const { findById: findDashboardById } = useDashboardRequests();

  const { openModal } = useModal();

  const history = useHistory();

  const { findByTargetIdAndDashboardId } = useDashboardAssociationRequests();

  const fetchDashboardOptions = useFetchDashboardOptions(findBy);
  const fetchUserOptions = useFetchUserOptions({
    clientId: client?.id ? parseInt(client?.id) : undefined,
  });
  const fetchGroupOptions = useFetchGroupOptions();
  const fetchClientOptions = useFetchClientOptions();

  const handleLinkOnCheck = () =>
    setState((prev) => ({ ...prev, findBy: "link" }));

  const handleDescriptionOnCheck = () =>
    setState((prev) => ({ ...prev, findBy: "description" }));

  const formComplete = getFormComplete(
    associationType,
    dashboard,
    user,
    group,
    client
  );
  const targetId = getTargetId(associationType, user, group, client);

  const handleOnDashboardSelect = (option: Option<null> | null) =>
    setState((prev) => ({ ...prev, dashboard: option }));

  const handleOnUserSelect = (option: Option<null> | null) =>
    setState((prev) => ({ ...prev, user: option }));

  const handleOnGroupSelect = (option: Option<null> | null) =>
    setState((prev) => ({ ...prev, group: option }));

  const handleOnClientSelect = (option: Option<null> | null) =>
    setState((prev) => ({ ...prev, client: option }));

  const handleOnAssociationTypeSelect = (e: ChangeEvent<HTMLSelectElement>) =>
    baseHandleOnAssociationTypeSelect(setState, e);

  const handleNextOnClick = useCallback(
    () =>
      baseHandleNextOnClick(
        associationType,
        targetId,
        dashboard,
        user,
        group,
        client,
        startLoading,
        findByTargetIdAndDashboardId,
        history,
        openModal,
        findDashboardById
      ),
    [associationType, targetId, dashboard, user, group, client]
  );

  const buttonText = getButtonText(isLoading, formComplete);

  const nextButtonDisabled = !formComplete || isLoading();

  const explanation = getExplanation(state);

  return {
    ...state,
    fetchDashboardOptions,
    fetchUserOptions,
    fetchGroupOptions,
    fetchClientOptions,
    formComplete,
    handleOnDashboardSelect,
    handleOnUserSelect,
    handleOnGroupSelect,
    handleOnClientSelect,
    handleNextOnClick,
    associationTypeSelectOptions,
    handleLinkOnCheck,
    handleDescriptionOnCheck,
    handleOnAssociationTypeSelect,
    buttonText,
    nextButtonDisabled,
    explanation,
  };
}

const LOADING_NEXT_BUTTON_KEY = "LOADING_NEXT_BUTTON_KEY";

const associationTypeSelectOptions: Array<{
  id: AssociationType;
  name: string;
}> = [
  { id: "client", name: "Cliente" },
  { id: "group", name: "Grupo" },
  { id: "user", name: "Usuário" },
];

export type UseChooseAssociationForm = typeof useChooseAssociationForm;

export interface State {
  user: Option<null> | null;
  group: Option<null> | null;
  client: Option<null> | null;
  dashboard: Option<null> | null;
  associationType: string | null;
  findBy: "link" | "description";
}

export type SetState = Dispatch<SetStateAction<State>>;

type History = ReturnType<typeof useHistory>;

const initialState: State = {
  user: null,
  group: null,
  client: null,
  dashboard: null,
  associationType: null,
  findBy: "description",
};

function baseHandleOnAssociationTypeSelect(
  setState: SetState,
  e: ChangeEvent<HTMLSelectElement>
) {
  if (!e) return null;
  setState((prev) => ({
    ...prev,
    associationType: e.target.value,
    user: null,
    group: null,
    client: null,
  }));
}

function getFormComplete(
  type: string | null,
  dashboard: Option<null> | null,
  user: Option<null> | null,
  group: Option<null> | null,
  client: Option<null> | null
): boolean {
  if (dashboard === null) {
    return false;
  }

  if (getTargetId(type, user, group, client) === null) {
    return false;
  }

  return true;
}

function getTargetId(
  type: string | null,
  user: Option<null> | null,
  group: Option<null> | null,
  client: Option<null> | null
): number | null {
  if (type === "user") {
    return user?.id ? parseInt(user?.id) : null;
  }

  if (type === "client") {
    return client?.id ? parseInt(client?.id) : null;
  }

  if (type === "group") {
    return group?.id ? parseInt(group?.id) : null;
  }

  return null;
}

function getExplanation(state: State) {
  const { associationType: type, user, client, group, dashboard } = state;

  if (type === "user" && user) {
    return `Você irá vincular o dashboard ${dashboard?.name} ao usuário ${user.name}`;
  }

  if (type === "client" && client) {
    return `Você irá vincular o dashboard ${dashboard?.name} à todos os usuários do cliente ${client.name}`;
  }

  if (type === "group" && group) {
    return `Você irá vincular o dashboard ${dashboard?.name} à todos os usuários do grupo ${group.name}`;
  }

  return null;
}

function getButtonText(isLoading: IsLoading, formComplete: boolean) {
  if (isLoading()) {
    return "Carregando...";
  }
  if (!formComplete) {
    return "Complete o preenchimento do formulário.";
  }
  return "Customizar vínculo de dashboard";
}

async function baseHandleNextOnClick(
  associationType: string | null,
  targetId: number | null,
  dashboardOption: Option<null> | null,
  user: Option<null> | null,
  group: Option<null> | null,
  client: Option<null> | null,
  startLoading: StartLoading,
  findByTargetIdAndDashboardId: FindByTargetIdAndDashboardId,
  history: History,
  openModal: OpenModal,
  findById: FindDashboardById
) {
  const finish = startLoading({ key: LOADING_NEXT_BUTTON_KEY });

  try {
    const dashboardId = parseInt(dashboardOption?.id ?? "0");
    let result: DashboardAssociation | null = null;

    try {
      result = await findByTargetIdAndDashboardId(
        associationType,
        targetId,
        dashboardId
      );
    } catch (e) {
      if (getErrorCode(e) !== NOT_FOUND_ERROR) throw e;
    }

    const dashboard = await findById(dashboardId);

    const associationTarget: AssociationTargetData = {
      dashboard,
      user,
      group,
      client,
      associationType,
    };
    sessionStorage.setItem(
      "associationTarget",
      JSON.stringify(associationTarget)
    );

    if (!result) {
      history.push("/dashboards/association/new");
      return;
    }

    history.push("/dashboards/association/edit/" + result.id);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    finish();
  }
}
