import Button from "../../form/Button";
import Input from "../../form/Input";
import useUserListPage from "./useUserListPage";
import styles from "../../ui/ui.module.css";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";

export default function UserListPage() {

  const {
    handleFindOnSubmit, handleOnChange, handlePageOnChange, filter, items, totalPages, page, handleEditOnClick
  } = useUserListPage();

  return (
    <div>
      <SectionTitle title="Listagem de Usuário" description="Consulta de usuários cadastrados na plataforma." />
      
      <form onSubmit={ handleFindOnSubmit }>
        <Input
          onChange={ handleOnChange }
          value={ filter }
          name="filter"
          label="Filtre usuário pelo email ou nome"
          addons={
            [ <Button key="1" type="info" submit>Pesquisar</Button> ]
          }
        />
      </form>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>Ações</th>
            <th>Id</th>
            <th>Email</th>
            <th>Nome</th>
            <th>Empresa</th>
          </tr>
        </thead>
        <tbody>
          {
            !items ? (
              <tr><td colSpan={ 7 }>Carregando....</td></tr>
            ) : (
              items.length === 0 ? (
                <tr><td colSpan={ 7 }>Sem resultados</td></tr>
              ) : (
                items.map(item => (
                  <tr key={ item.id }>
                    <td>
                      <Button small type="warning" onClick={ () => handleEditOnClick(item.id) } transparent>
                        Editar
                      </Button>
                    </td>
                    <td>
                      { item.id }
                    </td>
                    <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                      { item.email }
                    </td>
                    <td>
                      { item.name }
                    </td>
                    <td>
                      { item.client }
                    </td>
                  </tr>
                ))
              )
            )
          }
        </tbody>
      </table>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}