import { QUERY_API } from "../constants";
import { authCredentials } from "../utils/authCredentials";

export const logout = async (redirect = true, to?: string) => {
  authCredentials.remove.accessToken();
  authCredentials.remove.authUser();
  authCredentials.remove.refreshToken();

  window.localStorage.removeItem(QUERY_API.HOST);
  window.localStorage.removeItem(QUERY_API.USER);
  window.localStorage.removeItem(QUERY_API.PASSWORD);

  let defaultTo = "";

  if (redirect) {
    if (typeof to !== "undefined") defaultTo = `?${to}`;
    window.location.pathname = `/${defaultTo}`;
  }
};
