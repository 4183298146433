import { Link, useHistory } from "react-router-dom";
import { Title } from "../../components/Titles";
import { useGlobalContext } from "../service/context/globalContext";
import styles from "./HomePage.module.css";
import Platform from "./ui/Platform";
import SubTitle from "./ui/SubTitle";

export default function HomePage() {
  const { menus } = useGlobalContext();
  const history = useHistory();

  if (menus?.length === 1) {
    if (menus[0].subMenus.length === 1) {
      history.push(menus[0].subMenus[0].url);
    }
  }

  return (
    <Platform>
      <Title>Home</Title>
      <SubTitle>Bem vindo</SubTitle>
      {!menus
        ? null
        : menus.map((menu) => (
            <div className={styles.homeSection} key={menu.url}>
              <div className={styles.homeSectionTitle}>{menu.name}</div>
              <div>
                {menu.subMenus.map((subMenu, index) => (
                  <Link
                    to={subMenu.url}
                    className={styles.homeShortcut}
                    key={subMenu.url + index}
                  >
                    {subMenu.name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
    </Platform>
  );
}
