import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import useDashboardRequests, {
  Dashboard,
  FindDatasetIdBi,
} from "../../service/dashboard/useDashboardRequests";
import useFetchDashboardOptions from "../../service/dashboard/useFetchDashboardOptions";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import { isEmptyString } from "../../service/string";
import useSetTimout, { SetTimeout } from "../../service/time/useSetTimeout";
import { Option } from "../form/AsyncSelect";
import Button from "../form/Button";

function useDashboardForm(edit: boolean) {
  const setTimout = useSetTimout();

  const history = useHistory();

  const { openModal, closeModal } = useModal();

  const { id: pathId } = useParams<{ id?: string }>();

  const [state, setState] = useState(initialState);

  const { findBy, form } = state;

  const { description } = form;

  const { findDatasetIdBi } = useDashboardRequests();

  const setForm = (fn: (old: DashboardForm) => DashboardForm) =>
    setState((prev) => ({ ...prev, form: fn(prev.form) }));

  const handleFormOnChange = useHandleOnChange(setForm);

  const handleEditDashboardOnChange = (option: Option<null> | null) =>
    baseHandleEditDashboardOnChange(history, setState, option);

  const handleLinkOnCheck = () =>
    setState((prev) => ({ ...prev, findBy: "link" }));

  const handleDescriptionOnCheck = () =>
    setState((prev) => ({ ...prev, findBy: "description" }));

  const fetchDashboard = (id: number) => baseFetchDashboard(id);

  const fetchOpts = useFetchDashboardOptions(findBy);

  const loadDashboard = useCallback(
    (id?: string) =>
      baseLoadDashboard(fetchDashboard, setState, openModal, findBy, id),
    [findBy]
  );

  const handleGenerateLinkOnClick = useCallback(
    () => baseGenerateLinkOnClick(description, setState),
    [description]
  );

  const validation = useMemo(() => validate(form), [form]);

  const sendButtonText = makeSendButtonText(state, validation);

  const sendButtonDisabled = validation.errors > 0 || state.saving;

  const showForm = getShowForm(edit, state);

  const handleConfirmOnClick = useCallback(
    () => baseHandleConfirmSendOnClick(state, setState, openModal, history),
    [state]
  );

  const handleSendOnClick = useCallback(
    () =>
      baseHandleSendOnClick(
        handleConfirmOnClick,
        openModal,
        closeModal,
        state,
        setTimout
      ),
    [state]
  );

  const handleOnFindDatasetClick = useCallback(
    () =>
      baseHandleOnFindDatasetClick(state, setState, openModal, findDatasetIdBi),
    [state]
  );

  const handleOnAnalysisServicesChange = (analysisServices: string[] | null) =>
    setState((prev) => ({
      ...prev,
      form: { ...prev.form, analysisServices },
    }));

  useEffect(() => {
    loadDashboard(pathId);
  }, [pathId]);

  return {
    ...state,
    sendButtonText,
    handleFormOnChange,
    handleEditDashboardOnChange,
    fetchOpts,
    validation,
    handleGenerateLinkOnClick,
    handleLinkOnCheck,
    handleDescriptionOnCheck,
    sendButtonDisabled,
    showForm,
    handleSendOnClick,
    handleOnFindDatasetClick,
    handleOnAnalysisServicesChange,
  };
}

interface State {
  findBy: "link" | "description";
  form: DashboardForm;
  loadingDashboard: boolean;
  selectedDashboard: Option<null> | null;
  saving: boolean;
  findingDataset: boolean;
}

interface DashboardForm {
  reportIdBi: string | null;
  groupIdBi: string | null;
  datasetIdBi: string | null;
  description: string | null;
  id: number | null;
  link: string | null;
  deployEnvironment: string;
  analysisServices: string[] | null;
}

interface Validation {
  reportIdBi: string[];
  groupIdBi: string[];
  datasetIdBi: string[];
  description: string[];
  link: string[];
  errors: number;
  deployEnvironment: string[];
}

const emptyDashboard = {
  reportIdBi: null,
  groupIdBi: null,
  datasetIdBi: null,
  id: null,
  link: null,
  description: null,
  analysisServices: [],
  deployEnvironment: "horus",
};

const initialState: State = {
  findBy: "description",
  form: emptyDashboard,
  loadingDashboard: false,
  selectedDashboard: null,
  saving: false,
  findingDataset: false,
};

type History = ReturnType<typeof useHistory>;

type FetchDashboard = (id: number) => Promise<Dashboard>;

type SetState = Dispatch<SetStateAction<State>>;

function getShowForm(edit: boolean, state: State) {
  if (!edit) {
    return true;
  }
  return state.selectedDashboard !== null && state.form.id !== null;
}

function makeSendButtonText(state: State, validation: Validation) {
  if (validation.errors) {
    return validation.errors === 1 ? "1 Erro" : validation.errors + " Erros";
  }
  if (state.saving) {
    return "Salvando...";
  }
  return "Salvar";
}

function validate(form: DashboardForm): Validation {
  const validation: Validation = {
    reportIdBi: [],
    groupIdBi: [],
    datasetIdBi: [],
    description: [],
    link: [],
    deployEnvironment: [],
    errors: 0,
  };

  if (isEmptyString(form.description)) {
    validation.description.push("A descrição é obrigatória");
    validation.errors++;
  }
  if (form.description !== null && form.description.length > 100) {
    validation.description.push("A descrição tem limite de 100 caracteres.");
    validation.errors++;
  }
  if (isEmptyString(form.reportIdBi)) {
    validation.reportIdBi.push("O report id é obrigatório");
    validation.errors++;
  }
  if (form.reportIdBi !== null && form.reportIdBi.length > 50) {
    validation.reportIdBi.push("O reportIdBi tem limite de 50 caracteres.");
    validation.errors++;
  }
  if (isEmptyString(form.groupIdBi)) {
    validation.groupIdBi.push("O group id é obrigatório");
    validation.errors++;
  }
  if (form.groupIdBi !== null && form.groupIdBi.length > 50) {
    validation.reportIdBi.push("O groupIdBi tem limite de 50 caracteres.");
    validation.errors++;
  }
  if (isEmptyString(form.link)) {
    validation.link.push("O link é obrigatório");
    validation.errors++;
  }
  if (form.link !== null && form.link.length > 100) {
    validation.link.push("O link tem limite de 100 caracteres.");
    validation.errors++;
  }
  if (form.link && !form.link.match(/^[a-z0-9-]+$/)) {
    validation.link.push("O link só pode ter letras minúsculas, números e -");
    validation.errors++;
  }
  console.log(form.deployEnvironment)
  if (isEmptyString(form.deployEnvironment)) {
    validation.deployEnvironment.push("O ambiente é obrigatório");
    validation.errors++;
  }

  return validation;
}

async function baseFetchDashboard(id: number): Promise<Dashboard> {
  const response = await http.get(`${baseUrl}/api/v1/dashboard/${id}`);
  return response?.data;
}

function baseHandleEditDashboardOnChange(
  history: History,
  setState: (action: SetStateAction<State>) => void,
  selectedDashboard: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedDashboard }));
  history.push(
    "/dashboards/edit" + (selectedDashboard ? "/" + selectedDashboard.id : "")
  );
}

function baseGenerateLinkOnClick(
  description: string | null,
  setState: SetState
) {
  setState((prev) => ({
    ...prev,
    form: {
      ...prev.form,
      link: !description
        ? null
        : description
          .trim()
          .toLowerCase()
          .replace("á", "a")
          .replace("ã", "a")
          .replace("é", "e")
          .replace("ê", "e")
          .replace("ó", "o")
          .replace("ô", "o")
          .replace("ú", "u")
          .replace("û", "u")
          .replace("ç", "c")
          .replaceAll(/[^0-9a-z]/g, "-"),
    },
  }));
}

async function baseLoadDashboard(
  fetchDashboard: FetchDashboard,
  setState: SetState,
  openModal: OpenModal,
  findBy: string,
  id?: string
) {
  if (!id) {
    setState((prev) => ({ ...prev, form: emptyDashboard }));
    return;
  }

  try {
    setState((prev) => ({ ...prev, loadingDashboard: true }));

    const form = await fetchDashboard(parseInt(id));

    setState((prev) => ({
      ...prev,
      form: { ...form, deployEnvironment: (form.deployEnvironment ? form.deployEnvironment : "horus"), analysisServices: form.analysisServices || [] },
      selectedDashboard: {
        id: form.id + "",
        name: findBy === "description" ? form.description : form.link,
      },
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingDashboard: false }));
  }
}

function makeConfirmMessage(state: State) {
  if (state.selectedDashboard) {
    return "Confirma editar o dashboard " + state.selectedDashboard.name + "?";
  }
  return "Confirma salvar o dashboard " + state.form.description + "?";
}

async function baseHandleSendOnClick(
  confirm: () => void,
  openModal: OpenModal,
  closeModal: () => void,
  state: State,
  setTimeout: SetTimeout
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>{makeConfirmMessage(state)}</div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          setTimeout(() => confirm(), 1000);
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

async function baseHandleConfirmSendOnClick(
  state: State,
  setState: SetState,
  openModal: OpenModal,
  history: History
) {
  try {
    setState((prev) => ({ ...prev, saving: true }));

    const response = await http.post(`${baseUrl}/api/v1/dashboard`, state.form);

    const id = response.data.id;

    openModal("Dashboard salvo com sucesso!");

    history.push("/dashboards/edit/" + id);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, saving: false }));
  }
}

async function baseHandleOnFindDatasetClick(
  state: State,
  setState: SetState,
  openModal: OpenModal,
  findDatasetIdBi: FindDatasetIdBi
) {
  try {
    setState((prev) => ({ ...prev, findingDataset: true }));

    const { datasetBiId: datasetIdBi } = await findDatasetIdBi({
      reportBiId: state.form.reportIdBi ?? "",
      groupBiId: state.form.groupIdBi ?? "",
    });

    setState((prev) => ({ ...prev, form: { ...prev.form, datasetIdBi } }));
  } catch (e) {
    openModal("Dataset não encontrado");
  } finally {
    setState((prev) => ({ ...prev, findingDataset: false }));
  }
}

export default useDashboardForm;
