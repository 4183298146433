import { LegacyRef } from "react";
import { SectionTitle } from "../../ui/SectionTitle";
import useInsetProduct from "./useInsertProductPage";
import Button from "../../form/Button";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";

export default function InsertProductPage() {

  const { loading, header, items, fileInput, invalidEans, handleFileOnChange, handleUploadOnClick, handleSendOnClick } = useInsetProduct();

  return (
    <div>
      <SectionTitle title="Inserção de Produtos" description="Inserir produtos na base de dados para poderem ser moderados. Há um limite de 500 itens por CSV." />
      <input
        type="file"
        accept=".csv"
        ref={ fileInput as LegacyRef<HTMLInputElement> }
        style={{ display: "none" }}
        onChange={ handleFileOnChange }
      />
      <Button type="info" onClick={ handleUploadOnClick } disabled={ loading }>
        Upload
      </Button> {" "}
      <Button type="confirm" onClick={ handleSendOnClick } disabled={ items === null || items.length === 0 || loading }>
        {`Enviar ${items ? items.length: ""}`}
      </Button> {" "}
      {
        (items && items.length > 0) || invalidEans.eans.length > 0 ? (
          <span>(EANs ok: {items ? items.length : 0}; EANs inválidos: {invalidEans.eans.length})</span>
        ) : null
      }
      {
        !header ? (
          null
        ) : (
          items!.length === 0 ? (
            <h2>Sem resultados válidos</h2>
          ) : (
            <div>
              <h2>Essa tabela exibe até 5 dos produtos que serão enviados, para conferência dos campos</h2>
              <TableResponsiveWrapper>
                <table className={ styles.table }>
                  <thead>
                    <tr>
                      <th>Ean</th>
                      <th>Descrição</th>
                      <th>Categoria Produto Id</th>
                      <th>Categoria Nielsen Id</th>
                      <th>Fabricante Id</th>
                      <th>Marca Id</th>
                      <th>Quantidade</th>
                      <th>Unidade de Volume</th>
                      <th>NCM</th>
                      <th>Quantidade Pack</th>
                    </tr>
                  </thead>
                  {
                    items?.slice(0, 5).map(item => (
                      <tr key={ item.ean }>
                        <td>
                          { item.ean }
                        </td>
                        <td>
                          { item.description || "Não Informado" }
                        </td>
                        <td>
                          { item.productCategoryId || "Não Informado" }
                        </td>
                        <td>
                          { item.nielsenCategoryId || "Não Informado" }
                        </td>
                        <td>
                          { item.manufacturerId || "Não Informado" }
                        </td>
                        <td>
                          { item.brandId || "Não Informado" }
                        </td>
                        <td>
                          { item.quantity || "Não Informado" }
                        </td>
                        <td>
                          { item.unitOfMeasurement || "Não Informado" }
                        </td>
                        <td>
                          { item.ncm || "Não Informado" }
                        </td>
                        <td>
                          { item.quantityPack || "Não Informado" }
                        </td>
                      </tr>
                    ))
                  }
                </table>
              </TableResponsiveWrapper>
            </div>
          )
        )
      }
    </div>
  )
}