import { ReactNode, useContext } from "react";
import { RowContext } from "./Row";
import styles from "./ui.module.css";

export default function Col(props: { width?: number, children: ReactNode }) {

  const { spacing } = useContext(RowContext);

  const { width = 1/2 } = props;

  return (
    <div className={ styles.col } style={{ width: `${ width * 100 }%`, padding: `0 ${ spacing }` }}>
      { props.children }
    </div>
  )

}