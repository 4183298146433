import React from "react";
import Button from "../../../Button";

const FGVSelect = ({ fgvCategories, plan, setPlan }) => {
  const handleHasHistory = (event, id) => {
    let items = [];
    if (plan?.params?.categories) {
      items = [...plan.params.categories];
    }
    let index = plan.params.categories.findIndex(
      (category) => category.id === id
    );
    items[index].hasHistory = items[index].hasHistory ? false : true;
    setPlan((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        categories: [...items],
      },
    }));
  };

  const onRemove = (event, id) => {
    event.preventDefault();
    setPlan((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        categories: [
          ...plan?.params?.categories?.filter((item) => item.id !== id),
        ],
      },
    }));
  };

  const addOption = ({ target }) => {
    let items = [];
    if (plan?.params?.categories) {
      items = [...plan.params.categories];
    }

    let categoriesList = fgvCategories.filter(
      (category) =>
        !plan.params?.categories?.find(
          (selectCategories) =>
            selectCategories.id === category.id &&
            selectCategories.name === category.name
        )
    );

    switch (target.value.toString()) {
      case "all":
        categoriesList.forEach((category, index) => {
          items.push({
            hasHistory: false,
            id: category.id,
            name: category.name,
          });
        });

        break;

      case "all-with-history":
        categoriesList.forEach((category, index) => {
          items.push({
            hasHistory: true,
            id: category.id,
            name: category.name,
          });
        });
        break;

      case "remove-all":
        items = [];
        break;

      default:
        const selectedIndex = target.options.selectedIndex;
        let id = target.options[selectedIndex].getAttribute("data-key");
        items.push({ hasHistory: false, id: id, name: target.value });
        break;
    }

    setPlan((prevState) => ({
      ...prevState,
      params: {
        ...prevState.params,
        categories: [...items],
      },
    }));

    target.value = "DEFAULT";
  };

  const loadSelect = () =>
    fgvCategories.filter(
      (category) =>
        !plan?.params?.categories?.find(
          (selectCategories) =>
            selectCategories.id === category.id &&
            selectCategories.name === category.name
        )
    );

  const showSelectAll = () => loadSelect().length;

  React.useEffect(() => loadSelect());

  return (
    <>
      <label style={{ fontSize: "12px", fontWeight: "600" }}>Categorias</label>
      <br />
      <select onChange={addOption} defaultValue={"DEFAULT"}>
        <option value="DEFAULT" selected disabled>
          Selecione
        </option>

        {showSelectAll() ? (
          <>
            <option value="all">Selecione todos (Sem histórico)</option>
            <option value="all-with-history">
              Selecione todos (Com histórico)
            </option>
          </>
        ) : (
          <option value="remove-all">Remover todos</option>
        )}

        {fgvCategories
          ?.filter(
            (category) =>
              !plan?.params?.categories?.find(
                (selectCategories) =>
                  selectCategories.id === category.id &&
                  selectCategories.name === category.name
              )
          )
          .map((category, index) => {
            return (
              <option key={index} data-key={category.id} value={category.name}>
                {category.name}
              </option>
            );
          })}
      </select>

      <ul>
        {plan?.params?.categories &&
          plan.params.categories.map(({ id, name, hasHistory }) => (
            <>
              <li key={id}>
                <Button
                  type="button"
                  layout="error"
                  small
                  onClick={(e) => onRemove(e, id)}
                >
                  <i className="fa fa-times" />
                </Button>
                {name}
                <span>
                  <input
                    type="checkbox"
                    defaultChecked={hasHistory}
                    onClick={(e) => handleHasHistory(e, id)}
                  />{" "}
                  com historico
                </span>
                <br />
              </li>
            </>
          ))}
      </ul>
    </>
  );
};

export default FGVSelect;
