import useProductCategoryCreatePage from "./useProductCategoryCreatePage";

import styles from "../CategoryRegistrationPage.module.css";
import Button from "../../form/Button";

type Props = ReturnType<typeof useProductCategoryCreatePage>

function EditProductCategoryHeader(props: Props) {

  const { selectedEditCategory, enabled, sendingCategory, handleToggleCategoryOnClick, handleDeleteCategoryOnClick } = props;

  if (!selectedEditCategory) return <></>;

  const { name: title, id } = selectedEditCategory;

  return (
    <div style={{ marginBottom: "1em" }}>

      <h2 className={ styles.subTitle } style={{ color: enabled ? "black" : "gray" }}>{`${title} - ID: ${id}`}</h2>
      {
        enabled ? null : (
          <div className={ styles.disabledWarning }>
            Esta categoria está desativada
          </div>
        )
      }

      <h3 className={ styles.subTitle2 }>Ações</h3>

      <Button type="warning" disabled={ sendingCategory } onClick={ handleToggleCategoryOnClick }>
        { sendingCategory ? "Carregando..." : (enabled ? "Desativar Categoria" : "Ativar Categoria") }
      </Button> { " " }

      <Button type="cancel" disabled={ sendingCategory } onClick={ handleDeleteCategoryOnClick }>
        { sendingCategory ? "Carregando..." : "Deletar Categoria" }
      </Button>

      <h3 className={ styles.subTitle2 }>Editar</h3>

    </div>
  )

}

export default EditProductCategoryHeader;