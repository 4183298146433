import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { http } from "../../../../api/http";
import { baseUrl, mediaPrefix } from "../../../../constants";
import { Brand } from "../../../service/company/useCompanyBrandpage";
import useFetchCompanyOpts from "../../../service/company/useFetchCompanyOpts";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import AsyncSelect, { Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Checkbox from "../../form/Checkbox";
import Input from "../../form/Input";
import ValidationMessages from "../../form/ValidationMessages";

export type Props = {
  selectedBrand: Brand;
};

export default function CompanyBrandEdit(props: Props) {
  const { selectedBrand } = props;

  const fileInput = useRef<HTMLInputElement | null>(null);

  const { openModal } = useModal();

  const [state, setState] = useState<{
    loading: boolean;
    brand: BrandForm;
    fileSelected: Blob | null;
    imageSrc: string | ArrayBuffer | null | undefined;
  }>({
    loading: false,
    brand: {
      id: selectedBrand.id,
      name: selectedBrand.name,
      file: selectedBrand.file,
      economicGroup: selectedBrand.economicGroup,
      cnpj8: selectedBrand.cnpj8,
      category: selectedBrand.category,
      wideBrand: selectedBrand.wideBrand,
    },
    fileSelected: null,
    imageSrc: selectedBrand.file
      ? mediaPrefix + selectedBrand.file
      : "/static/placeholder.png",
  });

  const validation = useMemo(() => validate(state.brand), [state.brand]);

  const { findCompanyCategoryOpts: handleFetchCategoryOpts } =
    useFetchCompanyOpts();

  const handleOnSave = useCallback(
    () => saveBrand(openModal, setState, state),
    [openModal, setState, state]
  );

  const onUploadFileClick = () => {
    fileInput?.current?.click();
  };

  const setPreviewImage = (file: File | undefined) => {
    if (!file) return null;
    if (file?.size > 128000) {
      alert("Este arquivo excede o limite de 128KB!");
      return;
    }

    if (!file) {
      setState((prev) => ({
        ...prev,
        fileSelected: null,
        imageSrc: prev.brand
          ? mediaPrefix + prev.brand.file
          : "/static/placeholder.png",
      }));

      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setState((prev) => ({
        ...prev,
        fileSelected: file,
        imageSrc: e?.target?.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  const onUploadFileSelected = () => {
    const file = fileInput?.current?.files?.[0];
    setPreviewImage(file);
  };

  const onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  if (state.loading) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <Input
        label="Nome"
        name="name"
        placeholder="Nome"
        value={state.brand.name}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            brand: {
              ...prev.brand,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <ValidationMessages messages={validation.name} />

      <Input
        label="CNPJ8"
        name="cnpj8"
        placeholder="Primeiros 8 números do cnpj"
        value={state.brand.cnpj8}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            brand: {
              ...prev.brand,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <AsyncSelect
        fetchData={handleFetchCategoryOpts}
        onChange={(option) =>
          setState((prev) => ({
            ...prev,
            brand: {
              ...prev.brand,
              category: option,
            },
          }))
        }
        value={state.brand.category}
        label="Selecione a Categoria da Empresa"
      />

      <ValidationMessages messages={validation.category} />

      <Checkbox
        name="wideBrand"
        checked={state.brand.wideBrand}
        label="Grande Rede"
        onChange={() =>
          setState((prev) => ({
            ...prev,
            brand: {
              ...prev.brand,
              wideBrand: !prev.brand.wideBrand,
            },
          }))
        }
      />

      <Input
        label="Grupo Econômico"
        name="economicGroup"
        placeholder="Grupo Econômico"
        value={state.brand.economicGroup}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            brand: {
              ...prev.brand,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <div className="h-separator" id="image-title">
        Imagem
      </div>
      <div className="h-ignore-input-spacing" />
      <p className="h-section-explanation">Defina a imagem da marca</p>
      <div className="h-row">
        <div className="h-col h-col-2">
          <input
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={onUploadFileSelected}
          />
          <div className="h-dropzone" onDragOver={onDragOver}>
            <img
              className="h-brand-image"
              src={
                state.imageSrc
                  ? state.imageSrc.toString()
                  : mediaPrefix + "static/placeholder.png"
              }
              alt=""
            />
          </div>
        </div>
        <div className="h-col h-col-8">
          <Button type="info" onClick={onUploadFileClick}>
            Upload
          </Button>
        </div>
      </div>
      <br />

      <Button
        type="confirm"
        disabled={validation.errors > 0 || state.loading}
        onClick={() => handleOnSave()}
      >
        Salvar
      </Button>
    </>
  );
}

type SetState = Dispatch<
  SetStateAction<{
    loading: boolean;
    brand: BrandForm;
    fileSelected: Blob | null;
    imageSrc: string | ArrayBuffer | null | undefined;
  }>
>;

interface Validation {
  name: string[];
  category: string[];
  errors: number;
}

interface BrandForm {
  category: Option<null> | null;
  id: number | null;
  name: string | null;
  file: string | null;
  economicGroup: string | null;
  cnpj8: string | null;
  wideBrand: boolean;
}

function validate(brand: BrandForm) {
  const validation: Validation = {
    name: [],
    category: [],
    errors: 0,
  };

  if (
    !brand?.name ||
    !brand?.name.match(
      /^[a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+( [a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+)*$/
    )
  ) {
    validation.name.push("O nome é obrigatório");
    validation.errors++;
  }
  if (brand?.category === null) {
    validation.category.push("Categoria é obrigatória");
    validation.errors++;
  }

  return validation;
}

async function saveBrand(
  openModal: OpenModal,
  setState: SetState,
  state: { loading: boolean; brand: BrandForm; fileSelected: Blob | null }
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const formData = new FormData();
    if (state.fileSelected) formData.append("newImage", state.fileSelected);
    formData.append("formString", JSON.stringify(state.brand));

    const responseStr = state.brand.id
      ? "Marca alterada com sucesso."
      : "Marca cadastrada com sucesso.";

    const response = await http.post(
      `${baseUrl}/api/v1/company/brand`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );

    setState((prev) => ({
      ...prev,
      brand: { ...prev.brand, id: response.data.id },
    }));

    openModal(responseStr);
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}
