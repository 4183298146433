import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

export interface ServiceReportView {
  key: string;
  serviceDescription: string;
  nextReportSeconds: number | null;
  lastReportStatus: string;
  lastReportDate: string;
  lastDescription: string;
  pendingReport: boolean;
}

export interface PartnerCouponExtractorReportView {
  queue: string;
  state: string;
  timestamp: number;
  attempts: number;
  tests: number;
  founded: number;
  giveUps: number;
  positionAverage: number;
  testTimeAverage: number;
}

export interface HourlyPartnerImportPerformanceItem {
  amountDuplicated: number;
  amountSent: number;
  amountProcessed: number;
  amountInvalid: number;
  lateness: number;
  sentHourDay: number;
  uf: string;
  model: string;
  partner: string;
  origin: string;
}

export interface MonthlyPartnerImportDetailItem {
  amountDuplicated: number;
  amountSent: number;
  amountProcessed: number;
  amountInvalid: number;
  lateness: number;
  sentYearMonth: number;
  uf: string;
  model: string;
  partner: string;
  origin: string;
  emissionYearMonth: number;
}

export interface GetServiceReportsParams {
  filter?: string;
}

export interface GetPartnerCouponExtractorReportsParams {
  start: number;
}

export interface GetPartnerReportParams {
  start: number;
  end: number;
}

export type UseReportRequests = typeof useReportRequests;
export type GetServiceReports =
  ReturnType<UseReportRequests>["getServiceReports"];
export type GetPartnerCouponExtractorReports =
  ReturnType<UseReportRequests>["getPartnerCouponExtractorReports"];
export type GetMonthlyPartnerImportDetailItems =
  ReturnType<UseReportRequests>["getMonthlyPartnerImportDetailItems"];
export type GetHourlyPartnerImportPerformanceItem =
  ReturnType<UseReportRequests>["getHourlyPartnerImportPerformanceItem"];

export default function useReportRequests() {
  const getServiceReports = (params: GetServiceReportsParams) =>
    baseGetServiceReports(params);

  const removeOnClick = (key: string) => baseRemoveServiceReport(key);

  const getPartnerCouponExtractorReports = (
    params: GetPartnerCouponExtractorReportsParams
  ) => baseGetPartnerCouponExtractorReports(params);

  const getMonthlyPartnerImportDetailItems = (params: GetPartnerReportParams) =>
    baseGetMonthlyPartnerImportDetailItems(params);

  const getHourlyPartnerImportPerformanceItem = (
    params: GetPartnerReportParams
  ) => baseGetHourlyPartnerImportPerformanceItem(params);

  return {
    getServiceReports,
    removeOnClick,
    getPartnerCouponExtractorReports,
    getMonthlyPartnerImportDetailItems,
    getHourlyPartnerImportPerformanceItem,
  };
}

async function baseGetServiceReports(
  params: GetServiceReportsParams
): Promise<{ results: ServiceReportView[] }> {
  const response = await http.get(baseUrl + "/api/v1/report/services", {
    params,
  });
  return response.data;
}

async function baseRemoveServiceReport(key: string) {
  await http.delete(baseUrl + "/api/v1/report/remove-service/" + key);
}

async function baseGetPartnerCouponExtractorReports(
  params: GetPartnerCouponExtractorReportsParams
): Promise<{ result: PartnerCouponExtractorReportView[] }> {
  const response = await http.get(
    baseUrl + "/api/v1/report/partner-coupon-extractor",
    { params }
  );
  return response.data;
}

async function baseGetMonthlyPartnerImportDetailItems(
  params: GetPartnerReportParams
): Promise<{ result: Array<MonthlyPartnerImportDetailItem> }> {
  const response = await http.get(baseUrl + "/api/v1/report/partner/monthly", {
    params,
  });
  return response.data;
}

async function baseGetHourlyPartnerImportPerformanceItem(
  params: GetPartnerReportParams
): Promise<{ result: Array<HourlyPartnerImportPerformanceItem> }> {
  const response = await http.get(baseUrl + "/api/v1/report/partner/hourly", {
    params,
  });
  return response.data;
}
