import AsyncSelect from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Checkbox from "../../form/Checkbox";
import Select from "../../form/Select";
import Alert from "../../ui/Alert";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import useChooseAssociationForm, {
  UseChooseAssociationForm,
} from "./useChooseAssociationForm";

function UserTargetSection(props: ReturnType<UseChooseAssociationForm>) {
  const {
    associationType,
    user,
    client,
    fetchClientOptions,
    fetchUserOptions,
    handleOnClientSelect,
    handleOnUserSelect,
  } = props;

  if (associationType !== "user") return null;

  return (
    <div>
      <AsyncSelect
        fetchData={fetchClientOptions}
        onChange={handleOnClientSelect}
        value={client}
        label="De qual cliente é o usuário?"
      />
      {!client ? null : (
        <AsyncSelect
          fetchData={fetchUserOptions}
          onChange={handleOnUserSelect}
          value={user}
          label="Selecione o usuário"
        />
      )}
    </div>
  );
}

function GroupTargetSection(props: ReturnType<UseChooseAssociationForm>) {
  const { associationType, group, fetchGroupOptions, handleOnGroupSelect } =
    props;

  if (associationType !== "group") return null;

  return (
    <div>
      <AsyncSelect
        fetchData={fetchGroupOptions}
        onChange={handleOnGroupSelect}
        value={group}
        label="Selecione o grupo"
      />
    </div>
  );
}

function ClientTargetSection(props: ReturnType<UseChooseAssociationForm>) {
  const { associationType, client, fetchClientOptions, handleOnClientSelect } =
    props;

  if (associationType !== "client") return null;

  return (
    <div>
      <AsyncSelect
        fetchData={fetchClientOptions}
        onChange={handleOnClientSelect}
        value={client}
        label="Selecione o cliente"
      />
    </div>
  );
}

export default function ChooseAssociationForm() {
  const hookProps = useChooseAssociationForm();

  const {
    handleDescriptionOnCheck,
    handleLinkOnCheck,
    fetchDashboardOptions,
    handleOnDashboardSelect,
    handleOnAssociationTypeSelect,
    handleNextOnClick,
    dashboard,
    findBy,
    associationType,
    associationTypeSelectOptions,
    buttonText,
    nextButtonDisabled,
    explanation,
  } = hookProps;

  return (
    <div>
      <SectionTitle
        title="Seleção de Dashboard e Alvo"
        description="Especifique que dashboard você quer vincular à que usuário, grupo ou cliente."
      />

      <div>
        <Checkbox
          checked={findBy === "description"}
          label="Buscar por Descrição"
          onChange={handleDescriptionOnCheck}
        />
        <Checkbox
          checked={findBy === "link"}
          label="Buscar por Link"
          onChange={handleLinkOnCheck}
        />
        <AsyncSelect
          fetchData={fetchDashboardOptions}
          onChange={handleOnDashboardSelect}
          value={dashboard}
          label="Selecione um dashboard para vincular à alguém"
        />
      </div>

      <div>
        <Select
          label="Vincular esse dashboard a um usuário específico, aos usuários de um cliente ou aos usuários de um grupo?"
          value={associationType}
          name="associationType"
          onChange={handleOnAssociationTypeSelect}
          options={associationTypeSelectOptions}
        />
      </div>

      <UserTargetSection {...hookProps} />
      <GroupTargetSection {...hookProps} />
      <ClientTargetSection {...hookProps} />

      <Spacer ratio={1 / 3} />

      {!explanation ? null : (
        <>
          <Alert type="info">{explanation}</Alert>

          <Spacer />
        </>
      )}

      <Button
        onClick={handleNextOnClick}
        disabled={nextButtonDisabled}
        type="info"
      >
        {buttonText}
      </Button>
    </div>
  );
}
