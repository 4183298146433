import { useCallback } from "react";
import { http } from "../api/http";
import { baseUrl } from "../constants";
import useModal from "./useModal";

export default function useProductManufacturer() {
  const { createModal } = useModal();

  const fetchManufacturers = async function (search, offset) {
    try {
      const { data: manufacturers } = await http.get(
        baseUrl + `/api/v1/products/manufacturers`,
        {
          params: {
            search,
            offset,
            limit: 10,
          },
        }
      );
      return {
        options: manufacturers.map((m) => ({ id: m.id, name: m.name })),
        hasMore: manufacturers.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar fabricantes.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const existsManufacturer = useCallback(async (data) => {
    try {
      return http.get(`${baseUrl}/api/v1/products/manufacturer/exists`, {
        params: {
          manufacturerId: data.manufacturerId,
          manufacturer: data.manufacturer?.trim(),
        },
      });
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao verificar duplicidade de marca de produto</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  }, []);

  return { fetchManufacturers, existsManufacturer };
}
