import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { doNothing } from '../utils'

export const Logo = styled.img`
  margin-bottom: 60px;
`

export const Button = styled.button`
  margin-top: 40px;
  background-color transparent;
  border: solid 1px white;
  padding: 10px 80px;
  color: white;
  text-transform: uppercase;
  border-radius: 5px;
  font-size: 16px;

  :hover {
    background-color: rgba(0, 0, 0, 0.7);
    font-weight: bolder;
    cursor: pointer;
  }
`

const RadioButtonWrapper = styled.div`
  user-select: none;
`

const RadioButtonBox = styled.div`
  width: 12px;
  height: 12px;
  padding: 3px;
  border: solid 1px white;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  cursor: pointer;
  ${ props => props.checked && css`
    ::after {
      width: 100%;
      height: 100%;
      content: ' ';
      display: block;
      background-color: white;
    }
  ` }
`

const RadioButtonText = styled.div`
  display: inline-block;
  color: white;
  vertical-align: middle;
  cursor: pointer;
`

export function RadioButton({ setChecked = doNothing, checked, text }) {
  const handleOnClick = useCallback(e => {
    setChecked(old => !old)
  }, [ setChecked ])
  return (
    <RadioButtonWrapper>
      <RadioButtonBox checked={ checked } onClick={ handleOnClick } />
      <RadioButtonText onClick={ handleOnClick } >
        { text }
      </RadioButtonText>
    </RadioButtonWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
`

const FancyInput = styled.input`
  width: 100%;
  background-color: #e8f0fe;
  padding: 10px;
  ${ props => props.togglesVisibility && css`
    padding-right: 45px;
  ` }
  border: solid 1px white;
  color: black;
  font-size: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-sizing: border-box;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 300;
  :-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus, :-webkit-autofill:active, :-internal-autofill-previewed, :-webkit-autofill::first-line {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
    font-weight: 300;
    font-size: 20px;
  }
`

const InputEyeIcon = styled.div`
  color: black;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`

export function Input({ setValue = doNothing, value, placeholder, password: togglesVisibility }) {
  const [ visible, setVisible ] = useState(!togglesVisibility)

  const handleOnChange = useCallback(e => {
    setValue(e.target.value)
  }, [ setValue ])

  const handleOnClick = useCallback(e => {
    setVisible(old => !old)
  }, [ setVisible ])

  return (
    <InputWrapper>
      <FancyInput 
        onChange={ handleOnChange }
        value={ value }
        placeholder={ placeholder }
        togglesVisibility={ togglesVisibility }
        type={ !visible  ? "password" : "text" }
      />
      { togglesVisibility ? (
        <InputEyeIcon onClick={ handleOnClick }>
          <i className={ visible ? "fa fa-eye" : "fa fa-eye-slash" } />
        </InputEyeIcon>
      ) : null}
    </InputWrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(/static/supermarket.jpg);
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  font-weight: 300;
`

const Darker = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  :before {
    content: ' ';
    height: 1px;
    display: block;
  }
`

export function Template({ children }) {
  return (
    <Wrapper>
      <Darker>{ children }</Darker>
    </Wrapper>
  )
}