import styled from 'styled-components'

const Table = styled.table`
  margin-top: 10px;
  width: 100%;
  border-collapse: collapse;
  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

  td, th {
    padding: 7px;
    width: 1px;
    white-space: nowrap;
    font-weight: normal;
    font-size: 14px;
  }

  .bigger {
    width: 100%;
  }

  th {
    text-align: left;
    background-color: #2f3640;
    color: white;
  }

  tr.inactive {
    color: grey;
  }

  tr.deleted {
    color: grey;
    text-decoration-line: line-through;
  }

  tr:nth-child(2n) {
    background-color: lightgray;
  }

  tr:nth-child(2n+1) {
    background-color: white;
  }
`

export default Table