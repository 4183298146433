import ptBr from "date-fns/locale/pt-BR";
import styled from "styled-components";

import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { Loader } from "google-maps";
import CompanyService from "./components/CompanyService";
import Loading from "./components/Loading";
import Modal from "./components/Modal";
import PrivateRoute from "./components/PrivateRoute";
import RedirectLoggedRoute from "./components/RedirectLoggedRoute";
import GoogleMapsContext from "./context/GoogleMapsContext";
import UtilsContext from "./context/UtilsContext";
import HomePage from "./intranet/components/HomePage";
import ClientManagementPage from "./intranet/components/client/ClientManagementPage";
import CompanyPage from "./intranet/components/company/CompanyPage";
import DashboardRegistrationPage from "./intranet/components/dashboard/DashboardManagementPage";
import OthersPage from "./intranet/components/others/OthersPage";
import QueueSetupPage2 from "./intranet/components/prioritization-massive-moderation/QueueSetupPage2";
import ProductManagementPage from "./intranet/components/product/ProductManagementPage";
import ProductModerationPage from "./intranet/components/product/ProductModerationPage";
import ReportsPage from "./intranet/components/reports/ReportsPage";
import TagRoutes from "./intranet/components/tag/TagRoutes";
import UserManagementPage from "./intranet/components/user/UserManagementPage";
import { GlobalContext } from "./intranet/service/context/globalContext";
import {
  Context as LoadingContext,
  initialState as loadingInitialState,
} from "./intranet/service/loading/useLoading";
import BrandPage from "./pages/BrandPage";
import BrandsPage from "./pages/BrandsPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import ClientCompanyPage from "./pages/ClientCompanyPage";
import CompanyQueuePage from "./pages/CompanyQueuePage";
import DashboardPage from "./pages/DashboardPage";
import DashboardSegmentationPage from "./pages/DashboardSegmentationPage";
import DashboardUserSegmentationPage from "./pages/DashboardUserSegmentationPage";
import EventsPage from "./pages/EventsPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import GroupPage from "./pages/GroupPage";
import IncidencePage from "./pages/IncidencePage";
import InsertItemsPage from "./pages/InsertItemsPage";
import LoginPage from "./pages/LoginPage";
import NewClientPage from "./pages/NewClientPage";
import PartnerPage from "./pages/PartnerPage";
import PartnerProfilePage from "./pages/PartnerProfilePage/index";
import PlanPage from "./pages/PlanPage";
import PlansDetails from "./pages/PlansDetails";
import ProductBrandPage from "./pages/ProductBrandPage";
import ProductCategoryPage from "./pages/ProductCategoryPage";
import ProductPage from "./pages/ProductPage";
import ProductPage2 from "./pages/ProductPage2";
import ResearchDetailsPage from "./pages/ResearchDetailsPage";
import ResearchPage from "./pages/ResearchPage";
import ResultsPage from "./pages/ResultsPage";
import SingleClientCompanyPage from "./pages/SingleClientCompanyPage";
import SingleDashboardPage from "./pages/SingleDashboardPage";
import SingleGroupPage from "./pages/SingleGroupPage";

registerLocale("ptBr", ptBr);
setDefaultLocale("ptBr");

function Routes() {
  const [menus, setMenus] = useState(null);

  return (
    <GlobalContext.Provider value={{ menus, setMenus }}>
      <Switch>
        <RedirectLoggedRoute path="/" exact>
          <LoginPage />
        </RedirectLoggedRoute>
        <Route path="/forget-password">
          <ForgetPasswordPage />
        </Route>
        <Route path="/change-password/:token">
          <ChangePasswordPage />
        </Route>
        <PrivateRoute path="/report">
          <ReportsPage />
        </PrivateRoute>
        <PrivateRoute path="/dashboards">
          <DashboardRegistrationPage />
        </PrivateRoute>
        <PrivateRoute path="/dashboard" exact>
          <HomePage />
        </PrivateRoute>
        <PrivateRoute path="/new-client-page" exact>
          <NewClientPage />
        </PrivateRoute>
        <PrivateRoute path="/companies" exact>
          <CompanyQueuePage />
        </PrivateRoute>
        <PrivateRoute path="/company">
          <CompanyPage />
        </PrivateRoute>
        <PrivateRoute path="/product">
          <ProductModerationPage />
        </PrivateRoute>
        <PrivateRoute path="/product2/:ean" exact>
          <ProductPage2 />
        </PrivateRoute>
        <PrivateRoute path="/product/:ean" exact>
          <ProductPage />
        </PrivateRoute>
        <PrivateRoute path="/product-brands" exact>
          <ProductBrandPage />
        </PrivateRoute>
        <PrivateRoute path="/product-categories" exact>
          <ProductCategoryPage />
        </PrivateRoute>
        <PrivateRoute path="/queue-setup" exact>
          <QueueSetupPage2 />
        </PrivateRoute>
        <PrivateRoute path={["/user", "/users", "/users/:userId"]}>
          <UserManagementPage />
        </PrivateRoute>
        <PrivateRoute path="/client-company" exact>
          <ClientCompanyPage />
        </PrivateRoute>
        <PrivateRoute path="/client-company/:companyId">
          <SingleClientCompanyPage />
        </PrivateRoute>
        <PrivateRoute path="/client">
          <ClientManagementPage />
        </PrivateRoute>
        <PrivateRoute path="/results">
          <ResultsPage />
        </PrivateRoute>
        <PrivateRoute path="/incidence">
          <IncidencePage />
        </PrivateRoute>
        <PrivateRoute path="/plans">
          <PlanPage />
        </PrivateRoute>
        <PrivateRoute path="/plans-details/:clientId/:userId/:planId/:clientName">
          <PlansDetails />
        </PrivateRoute>
        <PrivateRoute path="/nps/:id">
          <ResearchDetailsPage />
        </PrivateRoute>
        <PrivateRoute path="/nps">
          <ResearchPage />
        </PrivateRoute>
        <PrivateRoute path="/dash" exact>
          <DashboardPage />
        </PrivateRoute>
        <PrivateRoute path="/dash/:dashId">
          <SingleDashboardPage />
        </PrivateRoute>
        <PrivateRoute path="/Dashboard-segmentation/:companyId/:dashId/:userId">
          <DashboardUserSegmentationPage />
        </PrivateRoute>
        <PrivateRoute path="/Dashboard-segmentation/:companyId/:dashId">
          <DashboardSegmentationPage />
        </PrivateRoute>
        <PrivateRoute path="/groups" exact>
          <GroupPage />
        </PrivateRoute>
        <PrivateRoute path="/groups/:groupId">
          <SingleGroupPage />
        </PrivateRoute>
        <PrivateRoute path="/brands">
          <BrandsPage />
        </PrivateRoute>
        <PrivateRoute path="/brand/:id">
          <BrandPage />
        </PrivateRoute>
        <PrivateRoute path="/events">
          <EventsPage />
        </PrivateRoute>
        <PrivateRoute path="/promotion-partner" exact>
          <PartnerPage />
        </PrivateRoute>
        <PrivateRoute path="/promotion-partner/:partner/:partnerId">
          <PartnerProfilePage />
        </PrivateRoute>
        <PrivateRoute path="/insert-items">
          <InsertItemsPage />
        </PrivateRoute>
        <PrivateRoute path="/product">
          <ProductManagementPage />
        </PrivateRoute>
        <PrivateRoute path="/others">
          <OthersPage />
        </PrivateRoute>
        <PrivateRoute path="/tag">
          <TagRoutes />
        </PrivateRoute>
        <Route>Página não encontrada</Route>
      </Switch>
    </GlobalContext.Provider>
  );
}

const AtCenter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

function App() {
  const [google, setGoogle] = useState();
  const [modals, setModals] = useState([]);
  const [loading, setLoading] = useState(loadingInitialState);
  const modalRef = useRef();

  useEffect(() => {
    const loader = new Loader("AIzaSyDmfh7U9qOn18bb2-Z2CzDHvOHUwWwwZqQ");
    loader.load().then((google) => {
      setGoogle(google);
    });
  }, []);

  const currentModal = modals.length ? modals[modals.length - 1] : null;

  const createModal = useCallback(
    (content, options = {}) => {
      const { replace, ...modalProps } = options;
      const modal = {
        content,
        options: modalProps,
        key: Math.floor(Math.random() * 10000),
      };
      if (modalRef.current && replace) {
        modalRef.current.toggleModal(false);
        setTimeout(() => setModals((modals) => [...modals, modal]), 600);
      } else {
        setModals([modal]);
      }
    },
    [setModals]
  );
  const toggleModal = useCallback(
    (open) => {
      if (modalRef.current) modalRef.current.toggleModal(open);
    },
    [modalRef]
  );
  const closeModal = useCallback(() => toggleModal(false), [toggleModal]);
  const deleteModal = useCallback(
    () => setModals((modals) => modals.slice(0, modals.length - 1)),
    [setModals]
  );

  return (
    <Suspense
      fallback={
        <AtCenter>
          <Loading />
        </AtCenter>
      }
    >
      <GoogleMapsContext.Provider value={{ google }}>
        <LoadingContext.Provider value={[loading, setLoading]}>
          <UtilsContext.Provider
            value={{
              createModal,
              openModal: createModal,
              toggleModal,
              closeModal,
            }}
          >
            <CompanyService>
              {!currentModal ? null : (
                <Modal
                  ref={modalRef}
                  {...currentModal.options}
                  children={currentModal.content}
                  key={currentModal.key}
                  onClose={deleteModal}
                />
              )}
              <Router>
                <Routes />
              </Router>
            </CompanyService>
          </UtilsContext.Provider>
        </LoadingContext.Provider>
      </GoogleMapsContext.Provider>
    </Suspense>
  );
}

export default App;
