import {
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useReportRequests, {
  GetMonthlyPartnerImportDetailItems,
  MonthlyPartnerImportDetailItem,
} from "../../service/report/useReportRequests";
import { compareStrings, fill } from "../../service/string";
import { tableToCSV } from "../../service/ui";
import { SetState } from "../../types";

const allowedPartners = [
  "AMSTEL BBB23",
  "CUPOM VERDE",
  "GLOBAL",
  "GLOBAL BRILUX",
  "GLOBAL CONDOR",
  "GLOBAL KIBON",
  "IFOOD",
  "MAKROSYS",
  "MANDARIN WICKBOLD",
  "NESTLE FAZBEM",
  "NSSOFT",
  "VPGSS",
  "IZIO",
];

export default function usePartnerCouponSentReport() {
  const tableRef = useRef<HTMLTableElement>(null);

  const { openModal } = useModal();

  const { getMonthlyPartnerImportDetailItems } = useReportRequests();

  const [state, setState] = useState(initialState);

  const { formMonth, selectedMonth } = state;

  const handleOnChange = useHandleOnChange(setState);

  const fetchData = useCallback(
    () =>
      baseFetchData({
        selectedMonth,
        setState,
        openModal,
        getMonthlyPartnerImportDetailItems,
      }),
    [state]
  );

  const handleOnFilterClick = useCallback(
    () => baseHandleOnFilterClick(setState, formMonth!),
    [state]
  );

  const handleOnCsvDownload = () => baseHandleOnCsvDownload(tableRef);

  useEffect(() => {
    fetchData();
  }, []);

  return {
    handleOnChange,
    handleOnFilterClick,
    handleOnCsvDownload,
    tableRef,
    ...state,
  };
}

export interface State {
  reports: null | MonthlyPartnerCouponSent[];
  formMonth: null | string;
  selectedMonth: number;
  loading: boolean;
}

const date = new Date();

const defaultMonth = parseInt(
  `${date.getFullYear() - 2000}${fill(String(date.getMonth() + 1), "0", 2)}`
);

const initialState: State = {
  reports: null,
  formMonth: String(defaultMonth).replace(/(\d{2})(\d{2})/, "$2/20$1"),
  selectedMonth: defaultMonth,
  loading: false,
};

interface FetchDataParams {
  selectedMonth: number;
  getMonthlyPartnerImportDetailItems: GetMonthlyPartnerImportDetailItems;
  openModal: OpenModal;
  setState: (action: SetStateAction<State>) => void;
}

interface MonthlyPartnerCouponSent {
  amountSent: number;
  partner: string;
  origin: string;
}

// Sets the month form the input to filter results

async function baseHandleOnFilterClick(
  setState: SetState<State>,
  formMonth: string
) {
  setState((prev) => ({
    ...prev,
    selectedMonth: parseInt(
      formMonth.replaceAll(/^(\d{2})\/20(\d{2})$/g, "$2$1")
    ),
  }));
}

// Retrieve and prepare report data to be shown methods

function generateKey(report: MonthlyPartnerCouponSent) {
  return `${report.partner}:${report.origin}`;
}

function mergeReports(
  a: MonthlyPartnerCouponSent,
  b: MonthlyPartnerCouponSent
): MonthlyPartnerCouponSent {
  return {
    amountSent: a.amountSent + b.amountSent,
    partner: a.partner,
    origin: a.origin,
  };
}

function groupReports(reports: MonthlyPartnerCouponSent[]) {
  const group: Record<string, MonthlyPartnerCouponSent> = {};

  reports.forEach((report) => {
    const key = generateKey(report);

    const adjustedReport = { ...report, model: "Vários", uf: "Vários" };

    group[key] = group[key]
      ? mergeReports(group[key], adjustedReport)
      : adjustedReport;
  });

  return Object.values(group);
}

async function baseFetchData(params: FetchDataParams) {
  const {
    selectedMonth,
    setState,
    getMonthlyPartnerImportDetailItems,
    openModal,
  } = params;

  try {
    setState((prev) => ({ ...prev, loading: true }));

    const { result: reports } = await getMonthlyPartnerImportDetailItems({
      start: selectedMonth,
      end: selectedMonth,
    });

    const ajustedPartnerReports = reports.map(adjustUserEdition);

    const groupedReports = groupReports(ajustedPartnerReports);

    groupedReports.sort((a, b) => {
      const partnerCompare = compareStrings(a.partner, b.partner);

      if (partnerCompare !== 0) {
        return partnerCompare;
      }

      return compareStrings(a.origin, b.origin);
    });

    const filteredReports = groupedReports.filter((r) =>
      allowedPartners.includes(r.partner)
    );

    setState((prev) => ({ ...prev, reports: filteredReports, loading: false }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function adjustUserEdition(
  report: MonthlyPartnerImportDetailItem
): MonthlyPartnerImportDetailItem {
  return {
    ...report,
    partner: report.partner.toUpperCase().replaceAll(/_/g, " "),
  };
}

function baseHandleOnCsvDownload(ref: RefObject<HTMLTableElement>) {
  if (!ref.current) {
    return;
  }

  const csv = tableToCSV(ref.current);

  downloadCSVFile(csv);
}

function downloadCSVFile(csvData: string) {
  const csvFile = new Blob([csvData], { type: "text/csv" });

  let tempLink = document.createElement("a");

  tempLink.download = "report.csv";
  let url = window.URL.createObjectURL(csvFile);
  tempLink.href = url;

  tempLink.style.display = "none";
  document.body.appendChild(tempLink);

  tempLink.click();
  document.body.removeChild(tempLink);
}
