import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Template from "../components/Template";
import { Title } from "../components/Titles";
import useResearch from "../hooks/useResearch";

export default function ResearchDetailsPage() {
  const { id } = useParams();

  const [error, setError] = React.useState(null);

  const {
    loadOne,
    loadAllCompanies,
    addQuestion,
    updateQuestion,
    researchPublished,
  } = useResearch();

  const [period, setPeriod] = React.useState({
    start: null,
    end: null,
  });
  const [state, setState] = React.useState({
    id: null,
    loading: false,
    question: "",
    description: "",
    active: "",
    companies: [],
  });

  const getCompanyIndex = (id, items) => {
    let companyId = items.findIndex(function (item, i) {
      return item.id === id;
    });

    return companyId;
  };

  const handleSelect = async (id, active) => {
    if (!period.start && !period.end && active) {
      return;
    }

    let companies = state.companies;
    let companyId = getCompanyIndex(id, companies);

    if (!active) {
      companies[companyId].select = false;
      setState({ ...state, companies: companies });
      return;
    }

    if (active) {
      companies[companyId].select = true;
      companies[companyId].period.start = period.start;
      companies[companyId].period.end = period.end;
      setPeriod({ ...period, start: null, end: null });
      setState({ ...state, companies: companies });
    }
  };

  const isEmpty = () => !!state.question && !!state.question;

  const loadCompanies = async (state) => {
    if (state?.companies?.length) return;
    if (state?.loading) return;
    try {
      setState({ ...state, loading: true });
      await loadAllCompanies().then(async (data) => {
        let allCompanies = [];
        await data.forEach((item) => {
          const company = {
            id: item.id,
            name: item.name,
            select: false,
            period: {
              start: null,
              end: null,
            },
          };

          allCompanies.push(company);
        });
        setState({ ...state, companies: allCompanies, loading: false });
      });
    } catch (error) {}
  };
  const loadQuestion = async (state) => {
    if (isEmpty() || state.id === 0 || !state?.companies?.length) return;
    if (state?.loading) return;

    try {
      setState({ ...state, loading: true });
      await loadOne(id)
        .then(({ id, question, description, active, companies }) => {
          let allCompanies = state.companies;
          !!companies &&
            companies.forEach((company) => {
              let companyId = getCompanyIndex(company.companyId, allCompanies);
              allCompanies[companyId].select = true;
              allCompanies[companyId].period.start = company.startDate;
              allCompanies[companyId].period.end = company.endDate;
            });
          setState({
            ...state,
            id: id,
            question: question,
            description: description,
            companies: allCompanies,
            active: active,
            loading: false,
          });
        })
        .catch(() => {
          setState({
            ...state,
            id: 0,
            loading: false,
          });
        });
    } catch (error) {
      setError(new Error("Infelizmente ocorreu um erro, tente novamente"));
    }
  };

  const formatDate = (d) =>
    `${d.substring(0, 4)}-${d.substring(5, 7)}-${d.substring(
      8,
      10
    )} ${d.substring(11, 13)}:${d.substring(14, 16)}:00`;

  const addCompanies = async (idReason) => {
    try {
      const companySelected = state?.companies.filter(
        (company) => company.select
      );

      let companyArray = [];
      companySelected.forEach((company) =>
        companyArray.push({
          company_id: company.id,
          start_date: formatDate(company.period.start),
          end_date: formatDate(company.period.end),
        })
      );
      await researchPublished(idReason, companyArray);
    } catch (error) {
      setError(new Error("Infelizmente ocorreu um erro, tente novamente"));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (state.loading) return;
    try {
      setState({ ...state, loading: true });
      if (state.id === 0) {
        const data = await addQuestion(state.question, state.description);
        await addCompanies(data.id);
        setState({ ...state, loading: false, id: data.id });
        return;
      }

      if (state.id && state.id > 0) {
        await updateQuestion(state.id, state.question, state.description);
        await addCompanies(state.id);
      }
      setState({ ...state, loading: false });
    } catch (error) {
      setError(new Error("Infelizmente ocorreu um erro, tente novamente"));
    }
  };

  useEffect(() => {
    loadQuestion(state);
    loadCompanies(state);
  }, [state]);

  return !state?.loading ? (
    <>
      {error && <Modal close={() => setError(null)}>{error.message}</Modal>}
      <Template>
        <Title>Pesquisa de Satisfação</Title>

        <div className="h-research">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <Collapse>
              <Card>
                <div className="row-1">
                  <div className="col">
                    <Input
                      type="text"
                      label="Pergunta"
                      value={state?.question}
                      onChange={({ target }) =>
                        setState({ ...state, question: target.value })
                      }
                    />
                  </div>
                  <div className="col">
                    <label className="sc-gWHgXt iAlOcC">Descrição</label>
                    <textarea
                      value={state?.description}
                      onChange={({ target }) =>
                        setState({ ...state, description: target.value })
                      }
                    ></textarea>
                  </div>
                </div>

                <div className="row-2">
                  <div className="companies">
                    <label className="sc-gWHgXt iAlOcC">Empresas</label>
                    <ul>
                      {!!state.companies &&
                        state?.companies
                          .filter((company) => !company.select)
                          .map((company, index) => {
                            return (
                              <li key={company.id}>
                                <div>{company.name}</div>
                                {period.start && period.end && (
                                  <button
                                    className="add h-research-add"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      handleSelect(company.id, true);
                                    }}
                                  >
                                    +
                                  </button>
                                )}
                              </li>
                            );
                          })}
                    </ul>

                    <div className="h-research-period">
                      <Input
                        type="datetime-local"
                        value={period.start}
                        onChange={(event) =>
                          setPeriod({ ...period, start: event.target.value })
                        }
                        label="Data de início"
                      />
                      <Input
                        type="datetime-local"
                        value={period.end}
                        onChange={(event) =>
                          setPeriod({ ...period, end: event.target.value })
                        }
                        label="Data de fim"
                      />
                    </div>
                  </div>

                  <div className="companies">
                    <label className="sc-gWHgXt iAlOcC">
                      Empresas Selecionadas
                    </label>
                    <ul>
                      {state?.companies
                        .filter((company) => company.select)
                        .map((company) => {
                          return (
                            <li key={company.id}>
                              <div>
                                <b>{company?.name}</b>
                                <span>
                                  {formatDate(company?.period?.start)} -{" "}
                                  {formatDate(company?.period?.end)}{" "}
                                </span>
                              </div>
                              <div>
                                <button
                                  className="remove h-research-remove"
                                  onClick={() =>
                                    handleSelect(company.id, false)
                                  }
                                >
                                  X
                                </button>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                  <div className="company-period"></div>
                </div>
              </Card>
            </Collapse>
            <Button layout="confirm">Enviar</Button>{" "}
            <Button
              type="button"
              layout="cancel"
              onClick={() => (window.location.href = "/nps")}
            >
              Cancelar
            </Button>
          </form>
        </div>
      </Template>
    </>
  ) : (
    <Template>
      <div className="h-search-loading">
        <Loading />
      </div>
    </Template>
  );
}
