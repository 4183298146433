import { useEffect, useRef } from "react";

export function useInterval(frequency: number, fn: () => Promise<any> | any, dependencies: any[]) {
  
  const ref = useRef<any[]>([]);

  useEffect(() => {

    const handles = ref.current;
    
    const setTimeoutFn = async () => {
      try {
        await promisefy(fn());
      } finally {
        handles.push(setTimeout(setTimeoutFn, frequency));
      }
    };

    setTimeoutFn();

    return () => {
      handles.forEach(handle => clearTimeout(handle));
    }

  // eslint-disable-next-line
  }, [ ...dependencies, frequency ]);

}

function promisefy<T>(value: Promise<T> | T): Promise<T> {
  if (value instanceof Promise) {
    return value;
  } else {
    return Promise.resolve(value);
  }
}