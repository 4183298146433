import { useCallback, useEffect, useState } from "react";
import { http } from "../../../api/http";

export default function useGroupedImportReport() {
  const [loading, setLoading] = useState(false);
  const [showToImport] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [groupsOpen, setGroupsOpen] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState(["type", "user"]);
  const [lastSelectedGroups, setLastSelectedGroups] = useState([
    "type",
    "user",
  ]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [data, setData] = useState(null);
  const [err, setErr] = useState(null);
  const [filters, setFilters] = useState([]);
  const [axis, setAxis] = useState("user");

  const selectGroup = useCallback(
    (group) => baseSelectGroup(group, selectedGroups, setSelectedGroups),
    [selectedGroups, setSelectedGroups]
  );

  const doSearch = useCallback(
    () =>
      baseDoSearch(
        fromDate,
        toDate,
        showToImport,
        filters,
        selectedGroups,
        setLastSelectedGroups,
        setData,
        setErr,
        setLoading
      ),
    [
      fromDate,
      toDate,
      showToImport,
      filters,
      selectedGroups,
      setLastSelectedGroups,
      setData,
      setErr,
      setLoading,
    ]
  );

  useEffect(
    () => redefineAxis(lastSelectedGroups, axis, setAxis),
    [lastSelectedGroups, axis, setAxis]
  );

  useEffect(
    () => organizeAxis(data, axis, showToImport),
    [data, axis, showToImport]
  );

  return {
    err,
    setErr,
    filtersOpen,
    setFiltersOpen,
    groupsOpen,
    setGroupsOpen,
    filters,
    setFilters,
    selectedGroups,
    selectGroup,
    fields,
    fieldNames,
    validateDate,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    data,
    lastSelectedGroups,
    axis,
    setAxis,
    showToImport,
    loading,
    doSearch,
    downloadTableAsCsv,
  };
}

const fields = [
  "createDate",
  "type",
  "user",
  "region",
  "channel",
  "category",
  "brand",
  "issueDay",
];

const fieldNames = {
  createDate: "Data Criação",
  type: "Tipo",
  user: "Usuário",
  region: "Região",
  channel: "Canal",
  category: "Categoria",
  brand: "Marca",
  issueDay: "Data de Emissão",
};

function baseSelectGroup(group, selectedGroups, setSelectedGroups) {
  if (selectedGroups.includes(group)) {
    if (selectedGroups.length === 1) {
      return;
    }
    setSelectedGroups([...selectedGroups].filter((i) => i !== group));
    return;
  }

  setSelectedGroups([...selectedGroups, group]);
}

function validateDate(str) {
  if (str && !str.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
    return {
      message: "A data deve estar no formato dd/mm/yyyy",
    };
  }

  return null;
}

async function search({ from, to }, setLoading) {
  const params = { merge: 1 };
  if (from) {
    params.from = from;
  }
  if (to) {
    params.to = to;
  }

  setLoading(true);
  try {
    const result = await http.get(
      "https://coupon-report.ehorus.com.br/api/query/coupon",
      {
        params,
        headers: {
          Authorization: `Basic ${window.btoa(
            "reporter_api_user:D79qDpzTk%_aQf@KGPqfkVxX85=QTsd5GmUdtFNnXwKwLj8yF9"
          )}`,
        },
      }
    );

    setLoading(false);
    return result.data;
  } catch (err) {
    setLoading(false);
    throw err;
  }
}

async function baseDoSearch(
  fromDate,
  toDate,
  showToImport,
  filters,
  selectedGroups,
  setLastSelectedGroups,
  setData,
  setErr,
  setLoading
) {
  try {
    const result = await search(
      {
        from: fromDate.replace(
          /(\d{2})\/(\d{2})\/(\d{4})/,
          "$3-$2-$1 00:00:00"
        ),
        to: toDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, "$3-$2-$1 23:59:59"),
      },
      setLoading
    );

    const united = {};

    if (showToImport) {
      append(united, result.to_import, "toImport", 0);
    }

    append(united, result.imported, "imported", 0);

    const planified = [];

    planify(united, 0, [], planified);

    const filtered = applyFilters(planified, filters);

    const reAgrouped = reAgroup(filtered, selectedGroups);

    setLastSelectedGroups(selectedGroups);

    setData(reAgrouped);
  } catch (err) {
    setErr(err);
  }
}

function redefineAxis(lastSelectedGroups, axis, setAxis) {
  if (!lastSelectedGroups.includes(axis)) {
    setAxis(lastSelectedGroups[0]);
  }
}

function organizeAxis(data, axis, showToImport) {
  if (!data) {
    return;
  }

  const series = [];

  const importedGroups = {};
  const toImportGroups = {};

  data.forEach((item) => {
    if (!importedGroups[item[axis]]) {
      importedGroups[item[axis]] = { label: item[axis], y: 0 };
    }
    if (!toImportGroups[item[axis]]) {
      toImportGroups[item[axis]] = { label: item[axis], y: 0 };
    }
    importedGroups[item[axis]].y += item.imported;
    toImportGroups[item[axis]].y += item.toImport;
  });

  if (showToImport) {
    series.push({
      type: "column",
      label: "A importar",
      dataPoints: Object.values(toImportGroups),
    });
  }
  series.push({
    type: "column",
    label: "Importados",
    dataPoints: Object.values(importedGroups),
  });
}

const planify = (obj, nestLevel, fieldValues, arr) => {
  Object.keys(obj).forEach((key) => {
    if (nestLevel < fields.length - 1) {
      planify(obj[key], nestLevel + 1, [...fieldValues, key], arr);
    } else {
      const newFieldValues = [...fieldValues, key];
      const item = {
        imported: obj[key].imported || 0,
        toImport: obj[key].toImport || 0,
      };
      for (let i = 0; i < fields.length; i++) {
        item[fields[i]] = newFieldValues[i];
      }
      arr.push(item);
    }
  });
};

const applyFilters = (data, filters) => {
  return data.filter((item) => {
    let valid = true;
    filters.forEach((filter) => {
      if (!filter.value) {
        return;
      }
      if (filter.operator === "=") {
        valid = valid && filter.value.split(";").includes(item[filter.field]);
      } else if (filter.operator === "!=") {
        valid = valid && !filter.value.split(";").includes(item[filter.field]);
      }
    });
    return valid;
  });
};

const append = (current, toAppend, attrName, nestLevel) => {
  Object.entries(toAppend).forEach((entry) => {
    if (nestLevel < fields.length - 1) {
      if (!current[entry[0]]) {
        current[entry[0]] = {};
      }
      append(current[entry[0]], toAppend[entry[0]], attrName, nestLevel + 1);
    } else {
      if (!current[entry[0]]) {
        current[entry[0]] = {};
      }
      if (!current[entry[0]][attrName]) {
        current[entry[0]][attrName] = 0;
      }
      current[entry[0]][attrName] += entry[1];
    }
  });
};

const reAgroup = (arr, keys) => {
  const indexed = {};
  arr.forEach((item) => {
    const key = keys.map((groupKey) => item[groupKey]).join(":");
    if (!indexed[key]) {
      indexed[key] = {
        toImport: 0,
        imported: 0,
      };
      keys.forEach((groupKey) => {
        indexed[key][groupKey] = item[groupKey];
      });
    }
    indexed[key].toImport += item.toImport;
    indexed[key].imported += item.imported;
  });
  return Object.values(indexed);
};

function downloadTableAsCsv(table_id) {
  // Select rows from table_id
  let rows = document.querySelectorAll("table#" + table_id + " tr");
  // Construct csv
  let csv = [];
  for (let i = 0; i < rows.length; i++) {
    let row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (let j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      let data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push('"' + data + '"');
    }
    csv.push(row.join(";"));
  }
  let csv_string = csv.join("\n");
  // Download it
  let filename =
    "export_" + table_id + "_" + new Date().toLocaleDateString() + ".csv";
  let link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
