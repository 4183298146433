import Input from "../form/Input";
import { SectionTitle } from "../ui/SectionTitle";
import Button from "../form/Button";
import usePartnerCouponSentReport from "./usePartnerCouponSentReport";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";
import { formatPartner, formatOrigin } from "./MonthlyPartnerReport";

export default function PartnerCouponSentReport() {

  const {
    formMonth, reports, handleOnChange, handleOnFilterClick, loading, tableRef, handleOnCsvDownload
  } = usePartnerCouponSentReport()

  return (
    <div>
      <SectionTitle title="Relatório Mensal de Notas Enviadas por Parceiros" description="Acumulado mensal de notas envidas por parceiros" />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}>
          <Input
            label="Mês de Referência"
            placeholder="Ex: 05/2022"
            name="formMonth"
            value={ formMonth }
            onChange={ handleOnChange }
          />
          <Button addInputLabelSpacing onClick={ handleOnFilterClick } disabled={ !formMonth || loading || !formMonth.match(/^\d{2}\/\d{4}$/) } type="info">
            { loading ? "Carregando..." : "Buscar" }
          </Button>
        </div>
        {
          loading ? null : (
            <Button addInputLabelSpacing onClick={ handleOnCsvDownload } type="confirm">
              Baixar Excel
            </Button>
          )
        }
      </div>
      <div style={{ display: "flex", justifyContent: "flex-start", gap: "10px" }}></div>
      {
        !reports ? null : (
          <TableResponsiveWrapper>
            <table className={ styles.table } ref={ tableRef }>
              <thead>
                <tr>
                  <th style={{ width: "100%", textAlign: "left" }}>
                    Parceiro
                  </th>
                  <th>
                    Tipo
                  </th>
                  <th>
                    Enviadas
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  reports?.map((report, index) => (
                    <tr key={ index }>
                      <td style={{ width: "100%", textAlign: "left" }}>
                        { formatPartner(report.partner) }
                      </td>
                      <td>
                        { formatOrigin(report.origin) }
                      </td>
                      <td>
                        { report.amountSent }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </TableResponsiveWrapper>
        )
      }
    </div>
  )

}