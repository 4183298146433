import { Chart as ChartJS } from "chart.js";
import {
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useSeriesRequests, {
  FindSeriesItem,
  SeriesItemReportForm,
  SeriesItemView,
} from "../../service/report/useSeriesRequests";
import { getRandomRgb } from "../../service/ui";
import { useLocalStorage } from "../../service/useLocalStorage";
import { SetState } from "../../types";
import AsyncSelect, { FetchDataResult } from "../form/AsyncSelect";
import Button from "../form/Button";
import Input from "../form/Input";
import { Option } from "../form/Select";
import { HSpacer } from "../ui/HSpacer";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import stylesReport from "./reports.module.css";

export interface ChartFilters {
  seriesTypes: Option[];
  start: string | null;
  end: string | null;
  description: string;
  id: number;
}

export interface ChartData {
  data: Array<SeriesItemView>;
  selectedSeriesTypes: Option[];
}

interface State {
  charts: ChartFilters[];
  reports: ChartData[];
  formStart: null | string;
  formEnd: null | string;
  formDescription: null | string;
  selectedSeriesTypes: Option[];
  selectedStart: number;
  selectedEnd: number;
  loading: boolean;
}

const defaults = getDefaultStartEnd();

const initialState: State = {
  charts: [],
  reports: [],
  formStart: null,
  formEnd: null,
  formDescription: null,
  selectedSeriesTypes: [],
  selectedStart: defaults.start,
  selectedEnd: defaults.end,
  loading: false,
};

interface OptionsView {
  result: {
    id: string;
    description: string;
  }[];
}

interface ChartSectionProps {
  filters: ChartFilters;
  onRemove: (e: { id: number }) => void;
}

interface ChartSectionState {
  data: ChartData | null;
  error: ReactNode | null;
}

export function SeriesReport() {
  const [state, setState] = useState<State>(initialState);

  const { loading, selectedSeriesTypes, formEnd, formStart, formDescription } =
    state;

  const [charts, setCharts] = useLocalStorage<ChartFilters[]>(
    "reports.selectedSeries",
    []
  );

  const handleOnChange = useHandleOnChange(setState);

  const fetchSeriesType = (filter: string) => baseFetchSeriesType(filter);

  const addNewGraph = useCallback(() => {
    setCharts((prev) => [
      {
        start: formStart,
        end: formEnd,
        seriesTypes: selectedSeriesTypes,
        id: generateChartId(),
        description: formDescription!,
      },
      ...prev,
    ]);
  }, [setCharts, formStart, formEnd, selectedSeriesTypes, formDescription]);

  const handleOnRemoveChart = useCallback(
    (e: { id: number }) => baseHandleOnRemoveChart(setCharts, e.id),
    [setCharts]
  );

  const handleOnSeriesTypeChange = useCallback(
    (o: Option | null) => baseSeriesTypeSelectOnChange(setState, o),
    [setState]
  );

  const removeSeriesType = useCallback(
    (id: string) => baseRemoveSeriesType(setState, id),
    [setState]
  );

  const validation = validate(state);

  return (
    <div>
      <SectionTitle
        title="Relatórios de Séries"
        description="Consulte as séries de monitoria disponíveis"
      />

      <div style={{ display: "flex", gap: "10px" }}>
        <div style={{ minWidth: "300px" }}>
          <Input
            label="Descrição"
            placeholder="Descrição do relatório"
            name="formDescription"
            value={formDescription}
            onChange={handleOnChange}
          />
        </div>

        <div style={{ minWidth: "200px" }}>
          <Input
            label="Início"
            placeholder="Ex: 12/05/2022 15h"
            name="formStart"
            value={formStart}
            onChange={handleOnChange}
            valid={validation.formStart}
          />
        </div>

        <div style={{ minWidth: "200px" }}>
          <Input
            label="Fim"
            placeholder="Ex: 12/05/2022 22h"
            name="formEnd"
            value={formEnd}
            onChange={handleOnChange}
            valid={validation.formEnd}
          />
        </div>

        <div style={{ minWidth: "400px", flex: 1 }}>
          <AsyncSelect
            fetchData={fetchSeriesType}
            onChange={handleOnSeriesTypeChange}
            label="Séries"
          />
        </div>

        <Button
          onClick={addNewGraph}
          disabled={
            !validation.allValid || selectedSeriesTypes.length === 0 || loading
          }
          type="confirm"
          addInputLabelSpacing
        >
          {loading ? "Carregando..." : "Gerar gráfico"}
        </Button>
      </div>

      <Spacer />

      <div className={stylesReport.removeLines}>
        {selectedSeriesTypes.map((type) => (
          <div key={type.id} className={stylesReport.removeLine}>
            <button
              className={stylesReport.removeButton}
              onClick={() => removeSeriesType(type.id)}
            >
              -
            </button>
            {type.name}
          </div>
        ))}
      </div>

      {charts.map((chart) => (
        <div key={chart.id}>
          <ChartSection filters={chart} onRemove={handleOnRemoveChart} />
        </div>
      ))}
    </div>
  );
}

function ChartSection(props: ChartSectionProps) {
  const { filters, onRemove } = props;

  const seriesChartRef = useRef<HTMLCanvasElement>(null);

  const [state, setState] = useState<ChartSectionState>({
    data: null,
    error: null,
  });

  const { data, error } = state;

  const { findSeriesItem } = useSeriesRequests();

  const fetchChartData = useCallback(
    (filters: ChartFilters) => baseFetchChartData(findSeriesItem, filters),
    [findSeriesItem]
  );

  const updateChartState = useCallback(
    () => baseUpdateChartState(fetchChartData, filters, setState),
    [fetchChartData, filters, setState]
  );

  useEffect(() => {
    updateChartState();
  }, [updateChartState]);

  useEffect(
    () => handleOnChartDataUpdate(data, seriesChartRef),
    [data, seriesChartRef]
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <SectionTitle
        title={filters.description}
        description={`Exibindo ${filters.seriesTypes.length} séries`}
      />
      {error ? error : !data ? "Carregando..." : null}
      <div style={{ height: "300px" }}>
        <canvas ref={seriesChartRef} />
      </div>
      <Spacer />
      <div style={{ textAlign: "right" }}>
        <Button type="confirm" small transparent onClick={updateChartState}>
          Atualizar
        </Button>
        <HSpacer />
        <Button
          type="cancel"
          small
          transparent
          onClick={() => onRemove({ id: filters.id })}
        >
          Remover
        </Button>
      </div>
    </div>
  );
}

function handleOnChartDataUpdate(
  data: ChartData | null,
  seriesChartRef: RefObject<HTMLCanvasElement>
) {
  if (!data) {
    return;
  }

  const chartJsData = getChartDataAsChartJsConfigurationObject(data);

  if (!seriesChartRef.current) return;

  const amountsChart = new ChartJS(seriesChartRef.current, {
    type: "line",
    data: chartJsData.seriesChartData,
    options: chartJsData.options,
  });

  return () => {
    amountsChart.destroy();
  };
}

function generateChartId() {
  return Math.floor(Math.random() * 1000000);
}

async function baseUpdateChartState(
  fetchChartData: (filters: ChartFilters) => Promise<SeriesItemView[]>,
  filters: ChartFilters,
  setState: SetState<ChartSectionState>
) {
  setState((prev) => ({ ...prev, data: null, error: null }));
  try {
    const items = await fetchChartData(filters);
    setState((prev) => ({
      ...prev,
      data: { data: items, selectedSeriesTypes: filters.seriesTypes },
      error: null,
    }));
  } catch (e) {
    setState((prev) => ({ ...prev, error: getErrorMessage(e) }));
  }
}

async function baseFetchChartData(
  findSeriesItem: FindSeriesItem,
  filters: ChartFilters
): Promise<SeriesItemView[]> {
  const defaults = getDefaultStartEnd();

  const filterParams = {
    end: !filters.end
      ? defaults.end
      : new Date(transformDate(filters.end)).getTime(),
    start: !filters.start
      ? defaults.start
      : new Date(transformDate(filters.start)).getTime(),
  };

  const form: SeriesItemReportForm = {
    types: filters.seriesTypes.map((o) => o.id),
    start: filterParams.start,
    end: filterParams.end,
  };

  const { result: report } = await findSeriesItem(form);

  return report;
}

async function baseFetchSeriesType(
  filter: String
): Promise<FetchDataResult<null>> {
  const response = await http.get(
    `${baseUrl}/api/v1/report/series-type/options?filter=` + filter
  );

  const data = response.data as OptionsView;

  return {
    options: data.result.map((i) => ({ id: i.id, name: i.description })),
    hasMore: data.result.length === 10,
  };
}

function baseSeriesTypeSelectOnChange(
  setState: SetState<State>,
  option: Option | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedSeriesTypes: [...prev.selectedSeriesTypes, option],
    };
  });
}

function baseRemoveSeriesType(setState: SetState<State>, id: string) {
  setState((prev) => ({
    ...prev,
    selectedSeriesTypes: [...prev.selectedSeriesTypes].filter(
      (o) => o.id !== id
    ),
  }));
}

function getDefaultStartEnd() {
  const today = new Date();

  const start =
    today.getMonth() +
    1 +
    "/" +
    today.getDate() +
    "/" +
    today.getFullYear() +
    " 00:00:00";
  const end =
    today.getMonth() +
    1 +
    "/" +
    today.getDate() +
    "/" +
    today.getFullYear() +
    " " +
    today.getHours() +
    ":00:00";

  const startDate = new Date(start);
  const endDate = new Date(end);

  return {
    start: startDate.getTime(),
    end: endDate.getTime(),
  };
}

function transformDate(date: string): string {
  const dateParts = date.split("/");
  const day = dateParts[0];
  const month = dateParts[1];
  const year = dateParts[2].substring(0, 4);
  const rest = dateParts[2].substring(4).replace(/h/, ":00:00");

  return `${month}/${day}/${year} ${rest}`;
}

function validate(state: State) {
  const validation = {
    allValid: true,
    formStart: true,
    formEnd: true,
    formDescription: true,
  };

  if (
    state.formStart != null &&
    state.formStart.match(/^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g) === null
  ) {
    validation.allValid = false;
    validation.formStart = false;
  }

  if (
    state.formEnd != null &&
    state.formEnd.match(/^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g) === null
  ) {
    validation.allValid = false;
    validation.formEnd = false;
  }

  if (!state.formDescription) {
    validation.allValid = false;
    validation.formDescription = false;
  }

  return validation;
}

function getChartDataAsChartJsConfigurationObject(report: ChartData) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  const seriesChartData = {
    labels: report.data.map((d) => {
      const date = new Date(d.id);
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${hours}:${minutes}`;
    }),
    datasets: report.selectedSeriesTypes.map((type) => {
      const color = getRandomRgb();
      return {
        label: type.name,
        data: report.data.map((d) => d.values[type.id]),
        borderColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`,
        backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.2)`,
      };
    }),
  };

  return { options, seriesChartData };
}

function baseHandleOnRemoveChart(
  setCharts: SetState<ChartFilters[]>,
  id: number
) {
  setCharts((prev) => prev.filter((i) => i.id !== id));
}

export default SeriesReport;
