import { useState } from "react";
import useModal from "../../service/modal/useModal";
import Button from "../form/Button";

interface Props {
  message: string;
  onConfirm: () => void;
}

export default function BasicConfirmationForModal({
  message,
  onConfirm,
}: Props) {
  const { closeModal } = useModal();
  const [ loading, setLoading ] = useState(false);

  return (
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        {message}
        <br />
        <br />
        Deseja continuar?
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "6px",
        }}
      >
        <Button
          type="confirm"
          disabled={loading}
          onClick={() => {
            setLoading(true);
            onConfirm();
          }}
        >
          Sim
        </Button>
        <Button
          type="cancel"
          disabled={loading}
          onClick={closeModal}>
          Não
        </Button>
      </div>
    </div>
  );
}
