import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import { ufCodes } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useReportRequests, {
  GetMonthlyPartnerImportDetailItems,
  MonthlyPartnerImportDetailItem,
} from "../../service/report/useReportRequests";
import { compareStrings, fill, isEmptyString } from "../../service/string";
import { tableToCSV } from "../../service/ui";
import { SetState } from "../../types";
import Button from "../form/Button";
import Input from "../form/Input";
import { SectionTitle } from "../ui/SectionTitle";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";

export function MonthlyPartnerReport() {
  const [state, setState] = useState<State>(initialState);

  const tableRef = useRef<HTMLTableElement>(null);

  const { openModal } = useModal();

  const { getMonthlyPartnerImportDetailItems } = useReportRequests();

  const { formMonth, reports, selectedMonth } = state;

  const handleOnChange = useHandleOnChange(setState);

  const validation = validate(state);

  const filter = useCallback(() => baseFilter(setState, state), [state]);

  const onDownloadCsvClick = () => baseOnDownloadCsvClick(tableRef);

  const fetchData = useCallback(
    () =>
      baseFetchData({
        setState,
        openModal,
        getMonthlyPartnerImportDetailItems,
        month: selectedMonth,
      }),
    [state]
  );

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <SectionTitle
        title="Relatório Mensal de Parceiro"
        description="Acumulado mensal de notas envidas por parceiros"
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Input
            label="Mês de Referência"
            placeholder="Ex: 05/2022"
            name="formMonth"
            value={formMonth}
            onChange={handleOnChange}
            valid={validation.formMonth}
            addons={[
              <Button
                key="1"
                onClick={filter}
                disabled={!validation.allValid}
                type="info"
              >
                Ok
              </Button>,
            ]}
          />
        </div>
        <div>
          <Button
            onClick={onDownloadCsvClick}
            disabled={!reports?.length}
            type="confirm"
            addInputLabelSpacing
            fullWidth
          >
            Baixar Excel
          </Button>
        </div>
      </div>
      {!reports ? (
        "Carregando..."
      ) : (
        <TableResponsiveWrapper>
          <table className={styles.table} ref={tableRef}>
            <thead>
              <tr>
                <th>Parceiro</th>
                <th>Tipo</th>
                <th>Mês de Emissão</th>
                <th>Inválidas</th>
                <th>Duplicadas</th>
                <th>Processadas</th>
                <th>Enviadas</th>
              </tr>
            </thead>
            <tbody>
              {reports.map((report, index) => (
                <tr key={index}>
                  <td>{formatPartner(report.partner)}</td>
                  <td>{formatOrigin(report.origin)}</td>
                  <td>{formatEmission(report.emissionYearMonth)}</td>
                  <td>{report.amountInvalid}</td>
                  <td>{report.amountDuplicated}</td>
                  <td>{report.amountProcessed}</td>
                  <td>{report.amountSent}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableResponsiveWrapper>
      )}
    </div>
  );
}

interface State {
  reports: null | MonthlyPartnerImportDetailItem[];
  formMonth: null | string;
  selectedMonth: number;
}

const date = new Date();
const defaultMonth = parseInt(
  `${date.getFullYear() - 2000}${fill(String(date.getMonth() + 1), "0", 2)}`
);

const initialState: State = {
  reports: null,
  formMonth: null,
  selectedMonth: defaultMonth,
};

function validate(state: State) {
  const validation = {
    allValid: true,
    formMonth: true,
  };

  if (
    state.formMonth != null &&
    state.formMonth.match(/^(\d{2})\/\d{2}(\d{2})$/g) === null
  ) {
    validation.allValid = false;
    validation.formMonth = false;
  }

  return validation;
}

interface FetchDataParams {
  month: number;
  getMonthlyPartnerImportDetailItems: GetMonthlyPartnerImportDetailItems;
  openModal: OpenModal;
  setState: SetState<State>;
}

async function baseFetchData(params: FetchDataParams) {
  const { month, setState, getMonthlyPartnerImportDetailItems, openModal } =
    params;

  try {
    const { result: reports } = await getMonthlyPartnerImportDetailItems({
      start: month,
      end: month,
    });

    const groupedReports = groupReports(reports);

    groupedReports.sort(sortReports);

    setState((prev) => ({ ...prev, reports: groupedReports }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

export function formatOrigin(origin?: string) {
  if (!origin) return "Desconhecido";

  if (origin === "SEFAZ") return "Extração de Nota no Site";

  if (origin === "PROVIDED") return "Arquivo de Nota Fiscal";

  return origin;
}

export function formatPartner(partner?: string) {
  if (!partner) return "Desconhecido";

  return partner.replace("_", " ").toUpperCase();
}

export function formatUf(code?: string) {
  if (!code) return "Desconhecido";

  if (code === "Vários") return "Vários";

  return code
    .split(", ")
    .map((i) => ufCodes.find((uf) => uf.code === i) || { name: "Desconhecido" })
    .map((i) => i.name)
    .join(", ");
}

export function formatEmission(emission?: string | number) {
  return emission
    ? String(emission).replaceAll(/^(\d{2})(\d{2})$/g, "$2/20$1")
    : "Desconhecido";
}

function sortReports(
  a: MonthlyPartnerImportDetailItem,
  b: MonthlyPartnerImportDetailItem
) {
  const partnerCompare = compareStrings(a.partner, b.partner);

  if (partnerCompare !== 0) {
    return partnerCompare;
  }

  const originCompare = compareStrings(a.origin, b.origin);

  if (originCompare !== 0) {
    return originCompare;
  }

  const modelCompare = compareStrings(a.model, b.model);

  if (modelCompare !== 0) {
    return modelCompare;
  }

  const ufCompare = compareStrings(formatUf(a.uf), formatUf(b.uf));

  if (ufCompare !== 0) {
    return ufCompare;
  }

  return a.emissionYearMonth - b.emissionYearMonth;
}

function baseFilter(setState: SetState<State>, state: State) {
  setState((prev) => ({
    ...prev,

    selectedMonth: !state.formMonth
      ? defaultMonth
      : parseInt(state.formMonth.replaceAll(/^(\d{2})\/20(\d{2})$/g, "$2$1")),
  }));
}

function downloadCSVFile(csvData: string) {
  const csvFile = new Blob([csvData], { type: "text/csv" });

  let tempLink = document.createElement("a");

  tempLink.download = "report.csv";
  let url = window.URL.createObjectURL(csvFile);
  tempLink.href = url;

  tempLink.style.display = "none";
  document.body.appendChild(tempLink);

  tempLink.click();
  document.body.removeChild(tempLink);
}

function baseOnDownloadCsvClick(ref: RefObject<HTMLTableElement>) {
  if (!ref.current) {
    return;
  }

  const csv = tableToCSV(ref.current);

  downloadCSVFile(csv);
}

function groupReports(reports: MonthlyPartnerImportDetailItem[]) {
  const group: Record<string, MonthlyPartnerImportDetailItem> = {};

  reports.forEach((report) => {
    const key = generateKey(report);

    const adjustedReport = { ...report, model: "Vários", uf: "Vários" };

    group[key] = group[key]
      ? mergeReports(group[key], adjustedReport)
      : adjustedReport;
  });

  return Object.values(group);
}

function generateKey(report: MonthlyPartnerImportDetailItem) {
  return `${report.partner}:${report.origin}:${report.emissionYearMonth}:${report.sentYearMonth}`;
}

function mergeReports(
  a: MonthlyPartnerImportDetailItem,
  b: MonthlyPartnerImportDetailItem
): MonthlyPartnerImportDetailItem {
  return {
    amountDuplicated: a.amountDuplicated + b.amountDuplicated,
    amountSent: a.amountSent + b.amountSent,
    amountProcessed: a.amountProcessed + b.amountProcessed,
    amountInvalid: a.amountInvalid + b.amountInvalid,

    lateness:
      a.amountProcessed + b.amountProcessed > 0
        ? (a.lateness * a.amountProcessed + b.lateness * b.amountProcessed) /
          (a.amountProcessed + b.amountProcessed)
        : 0,

    emissionYearMonth: a.emissionYearMonth,
    sentYearMonth: a.sentYearMonth,
    partner: a.partner,
    origin: a.origin,
    uf: mergeLists(a.uf, b.uf) || "Desconhecido",
    model: mergeLists(a.model, b.model) || "Desconhecido",
  };
}

export function mergeLists(
  a: string | null | undefined,
  b: string | null | undefined
) {
  if (a === b) {
    return a;
  }

  if (isEmptyString(a)) {
    return b!;
  }

  if (isEmptyString(b)) {
    return a!;
  }

  const set = new Set(
    [...a!.split(", "), ...b!.split(", ")].filter((i) => !isEmptyString(i))
  );

  const array: string[] = [];

  set.forEach((i) => array.push(i));

  array.sort((a, b) => a.localeCompare(b));

  return array.join(", ");
}
