import { isEmptyObj, validateNumericSize } from "../../../../../../utils";
import AsyncSelect2 from "../../../../../../components/AsyncSelect2";
import Input from "../../../../../../components/Input"
import Button from "../../../../../../components/Button";
import { useState } from "react";
import useModal from "../../../../../../hooks/useModal";
import MassProdConfirmModal from "./confirmModal";


export default function ProductModeration(props) {
  const { selectedItems, fullListSize, fetchCategories, fetchBrands, fetchProductStatus, handleUpdateProducts, loading,
          searchProductsAttributes } = props;

  const [ newProduct, setNewProduct ] = useState({
    description: null,
    category: null,
    categoryId: null,
    categoryReport: null,
    reportId: null,
    brand: null,
    brandId: null,
    manufacturer: null,
    manufacturerId: null,
    unitOfMeasurement: null,
    quantity: null,
    quantityPerPack: null,
    status: null,
    saleType: null,
    tags: [],
  });

  // OTHER HOOKS
  const { createModal } = useModal(false);

  // HELPERS
  const fetchUnitOfMeasurement = [
    { id: "Kg", name: "Quilo (kg)" },
    { id: "Lt", name: "Litro (l)" },
    { id: "Und", name: "Unidade (Und)" }
  ]

  const saleTypes = [
    { id: "", name: "Selecione..." },
    { id: "0", name: "0 - Sem Classificação" },
    { id: "1", name: "1 - Baixo" },
    { id: "2", name: "2 - Médio" },
    { id: "3", name: "3 - Alto" }
  ]

  const setProductQuantity = function (quantity) {
    quantity = validateNumericSize(quantity, 1, 6);
    setNewProduct({ ...newProduct, quantity })
  }

  const setProductQuantityPerPack = function (quantityPerPack) {
    quantityPerPack = validateNumericSize(quantityPerPack, 1, 4);
    setNewProduct({ ...newProduct, quantityPerPack })
  }

  // MODALS
  const setConfirmModal = () => createModal((
    <MassProdConfirmModal
      searchProductsAttributes={ searchProductsAttributes }
      newProduct={ newProduct }
      selectedItems={ selectedItems.length }
      fullListSize={ fullListSize }
      handleUpdateProducts={ handleUpdateProducts }
      loading={ loading }
    />
  ), {replace: true})

  return (
    <div>
      <AsyncSelect2
        label="Categoria Produto"
        value={ newProduct.categoryId }
        valueName={ newProduct.category }
        onChange={ option => setNewProduct({ ...newProduct, categoryId: option?.id, category: option?.name, categoryReport: option?.nielsen, reportId: option?.reportId, categoryUM: option?.um, unitOfMeasurement: null }) }
        fetchData={ fetchCategories }
      /><br/>
      <AsyncSelect2
        label="Marca"
        value={ newProduct.brandId }
        valueName={ newProduct.brand }
        onChange={ option => setNewProduct({ ...newProduct, brandId: option?.id, brand: option?.name, manufacturerId: option?.manufacturerId , manufacturer: option?.manufacturer }) }
        fetchData={ fetchBrands }
      /><br/>
      <Input
        label="Status"
        value={ newProduct.status }
        placeholder="Selecione..."
        onChange={ e => setNewProduct({ ...newProduct, status: e.target.value }) }
        options={ fetchProductStatus }
      />
      <Input
        label="Unidade de Medida"
        value={ newProduct.unitOfMeasurement }
        placeholder="Selecione..."
        onChange={ e => setNewProduct({ ...newProduct, unitOfMeasurement: e.target.value }) }
        options={ fetchUnitOfMeasurement }
      />
      <div className="h-ignore-input-spacing" />
      <div className="h-input-observation" style={{color: "red"}}>
          <span>Atenção com os produtos selecionados: diferentes categorias podem possuir diferentes unidades de medida</span>
      </div><br/>
      <Input
        label="Quantidade Unitária"
        value={ newProduct.quantity }
        onChange={ e => setProductQuantity(e.target.value) }
      />
      <Input
        label="Quantidade no Pack"
        value={ newProduct.quantityPerPack }
        onChange={ e => setProductQuantityPerPack(e.target.value) }
      />
      <Input
        label="Nível de Preço"
        value={ newProduct.saleType }
        placeholder="Selecione..."
        onChange={ e => setNewProduct({ ...newProduct, saleType: e.target.value }) }
        options={ saleTypes }
      />

      <Button
        layout="confirm"
        disabled={ isEmptyObj(newProduct) }
        onClick={ () => setConfirmModal() }
        className="h-input-button"
      >
        Enviar
      </Button>
    </div>
  )
}