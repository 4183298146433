import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

export default function useProductCompanyCodeRequests() {
  const findProductCompanyCode = (form: ProductCompanyCodeSearchForm) =>
    baseFindProductCompanyCode(form);

  const createProductCompanyCode: CreateProductCompanyCode = (form) =>
    baseCreateProductCompanyCode(form);

  const editProductCompanyCode: EditProductCompanyCode = (form) =>
    baseEditProductCompanyCode(form);

  const deleteProductCompanyCode: DeleteProductCompanyCode = (form) =>
    baseDeleteProductCompanyCode(form);

  const massCreateProductCompanyCode: MassCreateProductCompanyCode = (forms) =>
    baseMassCreateProductCompanyCode(forms);

  const massEditProductCompanyCode: MassEditProductCompanyCode = (forms) =>
    baseMassEditProductCompanyCode(forms);

  const massDeleteProductCompanyCode: MassDeleteProductCompanyCode = (forms) =>
    baseMassDeleteProductCompanyCode(forms);

  const downloadProductCompanyCodeCsv = (
    form: DownloadProductCompanyCodeCsvForm
  ) => baseDownloadProductCompanyCodeCsv(form);

  return {
    findProductCompanyCode,
    createProductCompanyCode,
    editProductCompanyCode,
    deleteProductCompanyCode,
    downloadProductCompanyCodeCsv,
    massCreateProductCompanyCode,
    massEditProductCompanyCode,
    massDeleteProductCompanyCode,
  };
}

export type FindProductCompanyCode = (
  form: ProductCompanyCodeSearchForm
) => Promise<ProductCompanyCodeSearchView>;

export type CreateProductCompanyCode = (
  form: ProductCompanyCode
) => Promise<void>;

export type EditProductCompanyCode = (
  form: ProductCompanyCode
) => Promise<void>;

export type DeleteProductCompanyCode = (
  form: ProductCompanyCode
) => Promise<void>;

export type MassCreateProductCompanyCode = (
  form: Partial<ProductCompanyCode>[]
) => Promise<Array<string>>;

export type MassEditProductCompanyCode = (
  form: Partial<ProductCompanyCode>[]
) => Promise<Array<string>>;

export type MassDeleteProductCompanyCode = (
  form: Partial<ProductCompanyCode>[]
) => Promise<Array<string>>;

export type DownloadProductCompanyCodeCsv = (
  form: DownloadProductCompanyCodeCsvForm
) => Promise<string>;

export interface DownloadProductCompanyCodeCsvForm {
  cnpj: string | null;
  productCodes: string[] | null;
}

export interface ProductCompanyCode {
  cnpj: string | null;
  companyId: number | null;
  productDescription: string | null;
  ean: string | null;
  productId: number | null;
  productCode: string | null;
  description: string | null;
  itemId: number | null;
  createDate: string | null;
  date: string | null;
  score: string | null;
}

export interface ProductCompanyCodeSearchForm {
  cnpj: string | null;
  productCodes: Array<string> | null;
  page: number;
  pageSize: number;
}

export interface ProductCompanyCodeSearchView {
  items: Array<ProductCompanyCode>;
  page: number;
  total: number;
}

async function baseFindProductCompanyCode(form: ProductCompanyCodeSearchForm) {
  const response = await http.post(
    `${baseUrl}/api/v1/product-company-code/search`,
    form
  );
  return response.data as ProductCompanyCodeSearchView;
}

async function baseCreateProductCompanyCode(form: ProductCompanyCode) {
  await http.post(`${baseUrl}/api/v1/product-company-code`, form);
}

async function baseEditProductCompanyCode(form: ProductCompanyCode) {
  await http.put(`${baseUrl}/api/v1/product-company-code`, form);
}

async function baseDeleteProductCompanyCode(form: ProductCompanyCode) {
  await http.post(`${baseUrl}/api/v1/product-company-code/delete`, form);
}

async function baseDownloadProductCompanyCodeCsv(
  form: DownloadProductCompanyCodeCsvForm
): Promise<string> {
  const response = await http.post(
    `${baseUrl}/api/v1/product-company-code/download/produtobaixa.csv`,
    form
  );
  return response.data as string;
}

async function baseMassCreateProductCompanyCode(
  forms: Partial<ProductCompanyCode>[]
) {
  const response = await http.post(
    `${baseUrl}/api/v1/product-company-code/mass-insert`,
    forms
  );
  return response.data as string[];
}

async function baseMassEditProductCompanyCode(
  forms: Partial<ProductCompanyCode>[]
) {
  const response = await http.put(
    `${baseUrl}/api/v1/product-company-code/mass-update`,
    forms
  );
  return response.data as string[];
}

async function baseMassDeleteProductCompanyCode(
  forms: Partial<ProductCompanyCode>[]
) {
  const response = await http.post(
    `${baseUrl}/api/v1/product-company-code/mass-delete`,
    forms
  );
  return response.data as string[];
}
