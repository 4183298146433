import Button from "../../form/Button";
import Checkbox from "../../form/Checkbox";
import Input from "../../form/Input";
import ValidationMessages from "../../form/ValidationMessages";
import Alert from "../../ui/Alert";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import style from "../../ui/ui.module.css";
import { AssociationTargetData } from "./types";
import useAssociateDashboardForm from "./useAssociateDashboardForm";

const description =
  "Customize os parâmetros deste dashboard para os usuários. " +
  "Sobrepor significa que quando o usuário acessar o dashboard, ele irá ver o relatório " +
  "com parâmetros customizados ao invés dos parâmetros originais do dashboard.";

function DashboardData(props: { data: AssociationTargetData | null }) {
  const { data } = props;

  if (!data) return null;

  return (
    <table className={style.table2}>
      <tbody>
        <tr>
          <td>Descrição Padrão do Dashboard</td>
          <td className={style.fullWidth}>{data.dashboard.description}</td>
        </tr>
        <tr>
          <td>Report ID Power BI Padrão do Dashboard</td>
          <td className={style.fullWidth}>{data.dashboard.reportIdBi}</td>
        </tr>
        <tr>
          <td>Group ID Power BI Padrão do Dashboard</td>
          <td className={style.fullWidth}>{data.dashboard.groupIdBi}</td>
        </tr>
        <tr>
          <td>Dataset ID Power BI Padrão do Dashboard</td>
          <td className={style.fullWidth}>{data.dashboard.datasetIdBi}</td>
        </tr>
        {!data.client ? null : (
          <tr>
            <td>Nome do Cliente</td>
            <td className={style.fullWidth}>{data.client.name}</td>
          </tr>
        )}
        {!data.group ? null : (
          <tr>
            <td>Nome do Grupo</td>
            <td className={style.fullWidth}>{data.group.name}</td>
          </tr>
        )}
        {!data.user ? null : (
          <tr>
            <td>Login do Usuário</td>
            <td className={style.fullWidth}>{data.user.name}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function AssociateDashboardForm() {
  const hookParams = useAssociateDashboardForm();

  const {
    contextAlert,
    handleOnChange,
    handleAvailableOnCheck,
    validation,
    associationTargetData,
    expiredAt,
    available,
    handleOnExpireNowClick,
    overrideDescription,
    overrideReportIdBi,
    overrideGroupIdBi,
    overrideDatasetIdBi,
    customData,
    roleBi,
    id,
    handleOnBackClick,
    isLoading,
    handleOnSaveClick,
  } = hookParams;

  if (isLoading("loadAssociationBy")) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <SectionTitle
        title="Vínculo de Dashboard e Usuário"
        description="Visão geral do vínculo a ser criado/atualizado"
      />

      <Alert type="info">{contextAlert}</Alert>

      <Spacer />

      <DashboardData data={associationTargetData as AssociationTargetData} />

      <Spacer />

      <SectionTitle title="Segmentação" description={description} />

      <Alert type="warn">
        Todos os parâmetros a seguir são usados pelo Power BI. A Intranet não
        valida estes dados e apenas os repassa para o Power BI. O preenchimento
        incorreto destes campos pode causar erros no embedamento.
      </Alert>

      <Spacer />

      <Input
        name="overrideDescription"
        value={overrideDescription}
        onChange={handleOnChange}
        label="Descrição"
        placeholder="Preencha para sobrepor a descrição original"
      />
      <Input
        name="overrideReportIdBi"
        value={overrideReportIdBi}
        onChange={handleOnChange}
        label="Id do Dashboard no Power BI"
        placeholder="Preencha para sobrepor o id do powerbi original"
      />
      <Input
        name="overrideGroupIdBi"
        value={overrideGroupIdBi}
        onChange={handleOnChange}
        label="Id do grupo do Dashboard no Power BI"
        placeholder="Preencha para sobrepor o id do grupo do powerbi original"
      />
      <Input
        name="overrideDatasetIdBi"
        value={overrideDatasetIdBi}
        onChange={handleOnChange}
        label="Id do dataset do Dashboard no Power BI"
        placeholder="Preencha para sobrepor o id do dataset do powerbi original"
      />
      <Input
        name="customData"
        value={customData}
        onChange={handleOnChange}
        label="Custom Data"
        placeholder="Preencha para adicionar um Custom Data"
      />
      <Input
        name="roleBi"
        value={roleBi}
        onChange={handleOnChange}
        label="Role BI"
        placeholder="Preencha para adicionar um Role BI"
      />

      <SectionTitle
        title="Acesso"
        description="Configure uma expiração opcional do acesso à este dashboard. Á partir da data informada o dashboard não irá aparecer mais na listagem."
      />

      <Checkbox
        name="available"
        checked={available}
        label="Ficará disponível para o cliente?"
        onChange={handleAvailableOnCheck}
      />

      <Input
        name="expiredAt"
        value={expiredAt}
        onChange={handleOnChange}
        label="Data de Expiração"
        placeholder="dd/mm/yyyy"
        addons={[
          <Button key="remove" onClick={handleOnExpireNowClick}>
            Remover Acesso Agora
          </Button>,
        ]}
      />

      <ValidationMessages messages={validation.expiredAt} />

      <Spacer />

      {id ? null : (
        <>
          <Button type="info" onClick={handleOnBackClick}>
            Voltar para a seleção de dashboard e alvos
          </Button>{" "}
        </>
      )}

      <Button type="confirm" disabled={isLoading()} onClick={handleOnSaveClick}>
        {isLoading() ? "Carregando..." : "Salvar"}
      </Button>
    </div>
  );
}

export default AssociateDashboardForm;
