import React from 'react'
import { UF, CHANNELS } from "../../constants"
import AsyncSelect2 from "../AsyncSelect2"
import Input from '../Input'

function PowerbiPlan(props) {
  const { planToEdit, setPlanToEdit, fetchManufacturers, childCategories } = props

  return (
    <div>
      <Input
        label="Categoria"
        value={ planToEdit?.categories }
        placeholder="Selecione..."
        onChange={ options => { setPlanToEdit( planToEdit => {
          if (options.includes("all")) {
              return { ...planToEdit, categories: childCategories.map(c => c.id.toString()) }
          }
          if (options.includes("remove all")) {
              return { ...planToEdit, categories: [] }
          }
          return { ...planToEdit, categories: options }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...childCategories.map((c, i) => ({ key: c.id, id: c.id, name: c.name })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
      <Input
        label="UF"
        placeholder="Selecione..."
        value={ planToEdit?.ufs }
        onChange={ options => { setPlanToEdit( planToEdit => {
          if (options.includes("all")) {
              return { ...planToEdit, ufs: UF.map(uf => uf) }
          }
          if (options.includes("remove all")) {
              return { ...planToEdit, ufs: [] }
          }
          return { ...planToEdit, ufs: options }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...UF.map((uf, i) => ({ key: i, id: uf, name: uf })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
      <AsyncSelect2
        label="Fabricante"
        value={ planToEdit.manufacturer }
        valueName={ planToEdit.manufacturerName }
        onChange={ option => { setPlanToEdit(planToEdit => {
          return { ...planToEdit, manufacturer: option ? option.id : null, manufacturerName: option ? option.name : null }
        }) }}
        fetchData={ fetchManufacturers }
      />
      <br/>
      <Input
        label="Canal"
        placeholder="Selecione..."
        value={ planToEdit?.channels }
        onChange={ options => { setPlanToEdit(planToEdit => {
          if (options.includes("all")) {
              return { ...planToEdit, channels: CHANNELS.map(ch => (ch.id).toString()) }
          }
          if (options.includes("remove all")) {
              return { ...planToEdit, channels: [] }
          }
          return { ...planToEdit, channels: options }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...CHANNELS.map(c => ({ key: c.id, id: c.id, name: c.name })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
    </div>
  )
}

export default PowerbiPlan;