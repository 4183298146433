import { useCallback } from "react";
import makeClassNameFromList from "../../../service/ui/makeClassNameFromList";
import Button from "../../form/Button";
import AsyncSelect, { Option } from "../../form/AsyncSelect";
import Spacer from "../../ui/Spacer";
import styles from "../../ui/ui.module.css";
import { Group } from "../UserInterfaces";
import useFetchGroupOptions from "../../user/group/useFetchGroupOptions";

interface Props {
  value: Array<Group> | null;
  onChange: (selectedGroups: Array<Group> | null) => void
}

export default function UserGroupsSelection(props: Props) {

  const { value, onChange } = props;

  const fetchGroupOptions = useFetchGroupOptions();

  const handleOnGroupsSelect = useCallback((o: Option<null> | null) => baseHandleOnGroupsSelect(o, value, onChange), [ value, onChange ]);

  const handleOnAnalysisServicesRemove = useCallback((selected: Group) => baseHandleOnGroupsRemove(selected, value, onChange), [ value, onChange ]);

  return (
    <div>
      <AsyncSelect
        label="Adicionar Grupos"
        onChange={ handleOnGroupsSelect }
        value={ null }
        fetchData={ fetchGroupOptions }
      />

      {
        !value ? null : (
          <>
            <table className={ styles.table }>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th className={ makeClassNameFromList([ styles.fullWidth, styles.left ]) }>Nome do grupo</th>
                </tr>
              </thead>
              <tbody>
                {
                  value.map(item => (
                    <tr key={ item.id }>
                      <td>
                        <Button type="cancel" small onClick={ () => handleOnAnalysisServicesRemove(item) }>
                          Remover
                        </Button>
                      </td>
                      <td className={ makeClassNameFromList([ styles.fullWidth, styles.left ]) }>
                        { item.name }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            <Spacer />
          </>
        )
      }

    </div>
  )

}

function baseHandleOnGroupsSelect(o: Option<null> | null, value: Array<Group> | null, onChange: (value: Array<Group> | null) => void) {

  if (!o || value?.find(g => g.id === parseInt(o.id))) return;

  onChange(value === null ? [ { id: parseInt(o.id), name: o.name } ] : [ ...value, { id: parseInt(o.id), name: o.name } ].sort((a, b) => a.name.localeCompare(b.name)));

}

function baseHandleOnGroupsRemove(selected: Group, value: Array<Group> | null, onChange: (value: Array<Group> | null) => void) {

  if (value === null) return;

  const newValue = value.filter(g => g.id !== selected.id);

  onChange(newValue.length ? newValue : null);

}