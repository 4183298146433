import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { http } from "../api/http";
import { baseUrl } from "../constants";

const FiltersContext = createContext({});

export const FiltersSKUProvider = ({ children, partners }) => {
  const [selectedSKU, setSelectedSKU] = useState([]);
  const [skus, setSKUS] = useState([]);
  const [error, setError] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCreateSKUModal, setOpenCreateSKUModal] = useState(false);
  const [textAreaData, setTextAreaData] = useState("");

  const handleTextAreaChange = (ev) => {
    setError({
      hasError: false,
      message: "",
    });
    setTextAreaData(ev.target.value);
  };

  const getSkus = useCallback(async () => {
    const response = await http.get(
      `${baseUrl}/internal/api/v1/partner/sku-filter/${partners.username}`
    );

    if (response.status !== 200) return;

    setSKUS(response.data);
  }, [partners.username]);

  const handleCreateNote = async () => {
    try {
      const skusFormatted = textAreaData.split("\n").map((element, i) => {
        const [ean, description = ""] = element.split(",");

        return {
          partner: partners.username,
          ean,
          description: description?.trim(),
        };
      });

      const data = skusFormatted.filter((sku) => sku.ean !== "");

      if (data.length === 0) {
        setError({
          hasError: true,
          message: "Por favor, preencha o campo com os dados necessários",
        });
        return;
      }

      await http.post(`${baseUrl}/internal/api/v1/partner/sku-filter`, data);

      getSkus();

      setOpenCreateSKUModal(!openCreateSKUModal);
    } catch (err) {
      if (err.message.includes("412")) {
        setError({
          hasError: true,
          message:
            "Você está tentando inserir produtos que ainda não foram criados, por favor, crie os produtos.",
        });
        return;
      }
    }
  };

  useEffect(() => {
    getSkus();
  }, [getSkus]);

  const handleNoteDelete = async () => {
    try {
      const skuToDelete = selectedSKU.map((sku) => sku.prodId);

      const response = await http.post(
        `${baseUrl}/internal/api/v1/partner/sku-filter/${partners.username}/delete`,
        skuToDelete
      );

      if (response.status === 200) {
        setOpenDeleteModal(!openDeleteModal);
        setSelectedSKU([]);
        getSkus();
      }
    } catch (err) {}
  };

  const handleCheckboxClick = (input, sku) =>
    input.checked
      ? setSelectedSKU([...selectedSKU, sku])
      : setSelectedSKU(
          selectedSKU.filter((filteredSKU) => filteredSKU.prodId !== sku.prodId)
        );

  return (
    <FiltersContext.Provider
      value={{
        selectedSKU,
        handleCreateNote,
        handleCheckboxClick,
        handleNoteDelete,
        handleTextAreaChange,
        skus,
        error,
        openDeleteModal,
        openCreateSKUModal,
        setOpenCreateSKUModal,
        setOpenDeleteModal,
        textAreaData,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

function useFiltersSKU() {
  const context = useContext(FiltersContext);

  return context;
}

export default useFiltersSKU;
