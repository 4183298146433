import { useState } from "react";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Collapse from "../../components/Collapse";
import Input from "../../components/Input";
import Table from "../../components/Table";
import { SubTitle } from "../../components/Titles";
import { parseToDate } from "../../utils/Date";

import {
  allRules,
  createUserPermission,
  isEmail,
  isNotEmpty,
  isValidUserName,
  makeValidator,
} from "../../utils";
import { authCredentials } from "../../utils/authCredentials";

const userValidator = makeValidator({
  username: allRules(
    isNotEmpty("Usuario de acesso"),
    isValidUserName("Usuario de acesso")
  ),
  firstName: isNotEmpty("Nome Parceiro"),
  email: allRules(isNotEmpty("E-mail"), isEmail("E-mail")),
});

function CreatePartnerForm(props) {
  let styleTableProfilePartner = {
    backgroundColor: "white",
  };

  const {
    onUpdatePartner,
    partners,
    isSKUFilterOpen,
    newUserPartner,
    setIsSKUFilterOpen,
    setNewUserPartner,
  } = props;
  const [collapsed, setCollapsed] = useState(true);
  const {
    getUsernameErrors,
    getEmailErrors,
    getFirstNameErrors,
    getAllErrors,
  } = userValidator;
  const authUser = authCredentials.get.authUser();

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          onUpdatePartner(newUserPartner);
          setCollapsed(true);
        }}
      >
        <Card>
          <Table>
            <tbody>
              <tr style={styleTableProfilePartner}>
                <td>Nome Parceiro: </td>
                <td>{partners.firstName}</td>
              </tr>
              <tr style={styleTableProfilePartner}>
                <td>Usuário de acesso: </td>
                <td>{partners.username}</td>
              </tr>
              <tr style={styleTableProfilePartner}>
                <td>Email Parceiro: </td>
                <td>{partners.email}</td>
              </tr>
              <tr style={styleTableProfilePartner}>
                <td>Registro ativo: </td>
                <td>{partners.active ? "SIM" : "NÃO"}</td>
              </tr>
              <tr style={styleTableProfilePartner}>
                <td>Data Registro: </td>
                <td>{parseToDate(partners.dateJoined)}</td>
              </tr>
            </tbody>
          </Table>
        </Card>

        <Collapse collapsed={collapsed}>
          <SubTitle>Editar Parceiro</SubTitle>
          <Card>
            <Input
              label="Usuario de acesso"
              value={newUserPartner.username}
              onChange={(e) =>
                setNewUserPartner({
                  ...newUserPartner,
                  username: e.target.value,
                })
              }
              validate={getUsernameErrors}
            />
            <Input
              label="Nome Parceiro"
              value={newUserPartner.firstName}
              onChange={(e) =>
                setNewUserPartner({
                  ...newUserPartner,
                  firstName: e.target.value,
                })
              }
              validate={getFirstNameErrors}
            />
            <Input
              label="E-mail"
              value={newUserPartner.email}
              onChange={(e) =>
                setNewUserPartner({ ...newUserPartner, email: e.target.value })
              }
              validate={getEmailErrors}
              disabled
            />
          </Card>
        </Collapse>
        {collapsed ? (
          <>
            <Button
              type="button"
              layout="confirm"
              onClick={(ev) => {
                ev.preventDefault();
                setCollapsed(false);
              }}
            >
              Editar Parceiro
            </Button>
            <Button
              type="button"
              style={{ marginLeft: "10px" }}
              layout="info"
              onClick={(ev) => {
                ev.preventDefault();
                setIsSKUFilterOpen((prevState) => !prevState);
              }}
            >
              {isSKUFilterOpen ? "Log's" : "Filtro de SKU"}
            </Button>
          </>
        ) : (
          <>
            <Button
              layout="confirm"
              disabled={
                getAllErrors(partners) ||
                !createUserPermission(authUser, partners)
              }
            >
              Enviar
            </Button>{" "}
            <Button
              type="button"
              layout="cancel"
              onClick={() => setCollapsed(true)}
            >
              Cancelar
            </Button>
          </>
        )}
      </form>
    </>
  );
}

export default CreatePartnerForm;
