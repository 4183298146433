import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";

export interface Client {
  id: number;
  name: string;
  cnpj: string;
  employeeAmount: string;
  clientActivity: string;
  type: string;
}

export type ClientSaveForm = {
  id?: number;
  name: string;
  cnpj: string;
  employeeAmount?: string;
  clientActivity?: string;
  type?: string;
};

export type ClientSearchForm = {
  filter?: string;
  page?: number;
};

export type ClientSearchItemView = {
  id: number;
  name: string;
  cnpj: string;
};

export type ClientSearchView = {
  items: Array<ClientSearchItemView>;
  total: number;
  page: number;
};

export type ClientInformationView = {
  hubspotId: string;
  expireDate: string;
  id: number;
  name: string;
  cnpj: string;
  users: Array<{
    id: number;
    username: string;
  }>;
};

export type UseClientRequests = typeof useClientRequests;
export type FindById = ReturnType<UseClientRequests>["findById"];
export type Search = ReturnType<UseClientRequests>["search"];
export type GetClientInformation =
  ReturnType<UseClientRequests>["getClientInformation"];
export type Save = ReturnType<UseClientRequests>["save"];
export type DeleteClient = ReturnType<UseClientRequests>["deleteClient"];
export type AttachUserToClient =
  ReturnType<UseClientRequests>["attachUserToClient"];
export type DetachUserFromClient =
  ReturnType<UseClientRequests>["detachUserFromClient"];

export default function useClientRequests() {
  const findById = (id: number) => baseFindById(id);

  const search = (params: ClientSearchForm) => baseSearch(params);

  const getClientInformation = (id: number) => baseFindClientInformation(id);

  const save = (client: ClientSaveForm) => baseSaveClient(client);

  const deleteClient = (id: number) => baseDeleteClient(id);

  const attachUserToClient = (params: { clientId: number; userId: number }) =>
    baseAttachUserToClient(params);

  const detachUserFromClient = (params: { clientId: number; userId: number }) =>
    baseDetachUserFromClient(params);

  const sendWelcomeEmailToAll = (id: number) => baseSendWelcomeEmailToAll(id);

  return {
    findById,
    search,
    getClientInformation,
    save,
    deleteClient,
    attachUserToClient,
    detachUserFromClient,
    sendWelcomeEmailToAll,
  };
}

async function baseFindById(id: number): Promise<Client> {
  const response = await http.get(`${baseUrl}/api/v1/client/${id}`);
  return response.data as Client;
}

async function baseSearch(
  params?: ClientSearchForm
): Promise<ClientSearchView> {
  const response = await http.get(`${baseUrl}/api/v1/client/search`, {
    params,
  });
  return response.data;
}

async function baseFindClientInformation(
  id: number
): Promise<ClientInformationView> {
  const response = await http.get(`${baseUrl}/api/v1/client/${id}/report`);
  return response.data;
}

async function baseSaveClient(client: ClientSaveForm): Promise<Client> {
  const response = await http.post(`${baseUrl}/api/v1/client/`, client);
  return response.data as Client;
}

async function baseDeleteClient(id: number): Promise<void> {
  const response = await http.delete(`${baseUrl}/api/v1/client/${id}`);
  return response.data;
}

async function baseAttachUserToClient(params: {
  clientId: number;
  userId: number;
}): Promise<void> {
  await http.put(
    `${baseUrl}/api/v1/client/${params.clientId}/user/${params.userId}`
  );
}

async function baseDetachUserFromClient(params: {
  clientId: number;
  userId: number;
}): Promise<void> {
  await http.delete(
    `${baseUrl}/api/v1/client/${params.clientId}/user/${params.userId}`
  );
}

async function baseSendWelcomeEmailToAll(id: number): Promise<void> {
  await http.post(`${baseUrl}/api/v1/client/welcome-email/${id}`);
}
