import { http } from "../api/http";
import { baseUrl, companyUrl } from "../constants";
import useModal from "./useModal";

function useCompany() {
  const { createModal } = useModal();

  const getCompanyByCnpj = (cnpj) =>
    http.get(companyUrl + "/api/v1/company/" + cnpj);

  const updateCompany = () => http.get(companyUrl + "/api/v1/company");

  const searchBrands = async function (search, offset) {
    try {
      const { data: brands } = await http.get(
        companyUrl + `/api/v1/brands-search`,
        {
          params: {
            search,
            offset,
            limit: 10,
          },
        }
      );

      return {
        options: brands.map((b) => ({
          id: b.id,
          name: b.description,
          file: b.file,
          grandeRede: b.grandeRede,
          cnpj8: b.cnpj8,
          categoryId: b.categoryId,
          economicGroup: b.economicGroup,
          channelId: b.channelId,
        })),
        hasMore: brands.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar marcas.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const searchCategories = async function (search, offset) {
    try {
      const { data: categories } = await http.get(
        companyUrl + `/api/v1/categories-search`,
        {
          params: {
            search,
            offset,
            limit: 10,
          },
        }
      );

      return {
        options: categories.map((c) => ({
          id: c.id,
          name: c.description,
          channel: c.channelName,
          channelId: c.channel,
        })),
        hasMore: categories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar marcas.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const searchChannel = async function (search, offset) {
    try {
      const { data: channels } = await http.get(
        companyUrl + `/api/v1/channels-search`,
        {
          params: {
            search,
            offset,
            limit: 10,
          },
        }
      );

      return {
        options: channels.map((c) => ({ id: c.id, name: c.description })),
        hasMore: channels.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar marcas.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
      return "ERROR";
    }
  };

  const getBrand = (id, options = {}) => {
    const { countCompanies } = options;
    const params = {};

    if (countCompanies) params.count_companies = countCompanies;

    return http.get(companyUrl + "/api/v1/brand/" + id, { params });
  };

  const updateBrand = (brand) => http.post(companyUrl + "/api/v1/brand", brand);

  const getChannels = () => http.get(companyUrl + "/api/v1/channel");

  const getCategories = () => http.get(companyUrl + "/api/v1/category");

  const insertCompanies = (cnpjList) =>
    http.post(baseUrl + "/api/v1/company", cnpjList);

  return {
    getCompanyByCnpj,
    updateCompany,
    searchBrands,
    searchCategories,
    searchChannel,
    getBrand,
    getChannels,
    getCategories,
    updateBrand,
    insertCompanies,
  };
}

export default useCompany;
