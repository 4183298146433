import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import { Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import useCategoryRegistration, {
  CreateProductCategory,
  DeleteProductCategory,
  FetchProductCategory,
  ProductCategoryForm,
  ToggleProductCategory,
} from "../useCategoryRegistration";

/**
 * Hook com funções e variáveis usadas na página de criação de categoria produto
 */
function useProductCategoryCreate(edit: boolean) {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const categoryRegistration = useCategoryRegistration();

  const { openModal, closeModal } = useModal();

  const {
    createProductCategory,
    fetchProductCategory,
    toggleProductCategory,
    deleteProductCategory,
  } = categoryRegistration;

  const [state, setState] = useState(initialState);

  const {
    form,
    selectedFatherCategory,
    sendingCategory,
    selectedEditCategory,
    fetchingCategory,
    enabled,
  } = state;

  const handleFormOnChange = (e: ChangeEvent) =>
    baseHandleFormOnChange(setState, e);

  const handleProductCategoryOnChange = (o: Option<null> | null) =>
    baseHandleProductCategoryOnChange(setState, o);

  const handleSendButtonOnClick = useCallback(
    () =>
      baseHandleSendButtonOnClick(
        createProductCategory,
        setState,
        state,
        openModal,
        history
      ),
    [state]
  );

  const handleEditCategoryOnChange = (selected: Option<null> | null) =>
    baseHandleEditCategoryOnChange(history, setState, selected);

  const validation = useMemo(() => validate(state), [state]);

  const buttonText = useMemo(
    () => getButtonText(validation, edit, sendingCategory),
    [validation, sendingCategory, edit]
  );

  const name = selectedEditCategory?.name;

  const handleConfirmToggleCategoryOnClick = useCallback(
    () =>
      baseHandleConfirmToggleCategoryOnClick(
        toggleProductCategory,
        setState,
        id,
        enabled,
        fetchProductCategory,
        openModal
      ),
    [id, enabled]
  );

  const handleToggleCategoryOnClick = useCallback(
    () =>
      baseHandleToggleCategoryOnClick(
        handleConfirmToggleCategoryOnClick,
        enabled,
        name,
        openModal,
        closeModal
      ),
    [enabled, name]
  );

  const handleConfirmDeleteCategoryOnClick = useCallback(
    () =>
      baseHandleConfirmDeleteCategoryOnClick(
        deleteProductCategory,
        setState,
        id,
        history,
        openModal
      ),
    [id]
  );

  const handleDeleteCategoryOnClick = useCallback(
    () =>
      baseHandleDeleteCategoryOnClick(
        handleConfirmDeleteCategoryOnClick,
        name,
        openModal,
        closeModal
      ),
    [name]
  );

  useEffect(
    () => loadCategoryEffect(fetchProductCategory, setState, openModal, id),
    [id]
  );

  const showForm = !edit || id;

  return {
    ...categoryRegistration,
    buttonText,
    sendingCategory,
    validation,
    handleFormOnChange,
    handleProductCategoryOnChange,
    handleSendButtonOnClick,
    form,
    selectedFatherCategory,
    showForm,
    selectedEditCategory,
    handleEditCategoryOnChange,
    fetchingCategory,
    enabled,
    handleToggleCategoryOnClick,
    handleDeleteCategoryOnClick,
  };
}

/**
 * Tipo do objeto histórico
 */
type History = ReturnType<typeof useHistory>;

/**
 * Tipo do objeto que contém os dados de validação do formulário
 */
interface Validation {
  fatherCategoryId: string[];
  volumeUnity: string[];
  description: string[];
  errors: number;
}

/**
 * Tipo do estado do componente.
 * O form são os campos normais.
 * O selectedFatherCategory e selectedEditCategory também são dos formulários mas
 * eles são usados no AsyncSelect que é um pouco diferente por isso separei eles.
 * sendingCategory e fechingCategory servem pra mostrar loadings pro usuário
 */
interface State {
  form: {
    productCategoryId: number | null;
    volumeUnity: string | null;
    description: string | null;
  };
  selectedFatherCategory: null | Option<null>;
  selectedEditCategory: null | Option<null>;
  sendingCategory: boolean;
  fetchingCategory: boolean;
  enabled?: boolean;
}

const initialState: State = {
  form: {
    productCategoryId: null,
    volumeUnity: null,
    description: null,
  },
  selectedFatherCategory: null,
  selectedEditCategory: null,
  sendingCategory: false,
  fetchingCategory: false,
};

type ChangeEvent = { target: { value?: any; name?: any } };

/**
 * Um callback onChange que irá atualizar no form o atributo com o nome igual
 * ao name do input alterado
 */
function baseHandleFormOnChange(
  setState: (action: SetStateAction<State>) => void,
  e: ChangeEvent
) {
  setState((prev) => ({
    ...prev,
    form: {
      ...prev.form,
      [e.target.name]: e.target.value,
    },
  }));
}

/**
 * Um callback onChange para atualizar o estado com a opção selecionada no AsyncSelect
 */
function baseHandleProductCategoryOnChange(
  setState: (action: SetStateAction<State>) => void,
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    selectedFatherCategory: option,
  }));
}

/**
 * Valida os dados do formulário
 */
function validate(state: State): Validation {
  const validation: Validation = {
    fatherCategoryId: [],
    volumeUnity: [],
    description: [],
    errors: 0,
  };

  if (!state.form.volumeUnity) {
    validation.volumeUnity.push("A unidade de volume é obrigatória");
    validation.errors++;
  }
  if (!state.form.description || state.form.description.match(/^\s+$/)) {
    validation.description.push("A descrição é obrigatória");
    validation.errors++;
  }

  return validation;
}

/**
 * On click do botão de enviar, tenta salvar a categoria, redireciona pra página de edição e mostra um modal de sucesso.
 * Em caso de falha, mostra um modal com o erro.
 */
async function baseHandleSendButtonOnClick(
  createProductCategory: CreateProductCategory,
  setState: (action: SetStateAction<State>) => void,
  state: State,
  openModal: OpenModal,
  history: History
) {
  const form: ProductCategoryForm = {
    categoryFatherId: state.selectedFatherCategory
      ? parseInt(state.selectedFatherCategory.id)
      : undefined,
    productCategoryId: state.form.productCategoryId || undefined,
    volumeUnity: state.form.volumeUnity!,
    description: state.form.description!,
  };

  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    const result = await createProductCategory(form);

    history.push(`/product/product-category/edit/${result.id}`);

    openModal("Categoria criada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

/**
 * Retorna o texto que vai ficar no botão de enviar
 */
function getButtonText(
  validation: Validation,
  edit: boolean,
  loading: boolean
) {
  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1
      ? `Existe 1 erro`
      : `Existem ${validation.errors} erros`;
  }

  return !edit ? "Criar" : "Editar";
}

/**
 * Recupera da api a categoria que o usuário selecionou para editar
 * e preenche o formulário de edição com ela.
 * Caso o usuário saia da página de editar pra página de criar esse mesmo
 * effect irá limpar o formulário.
 */
function loadCategoryEffect(
  fetch: FetchProductCategory,
  setState: (action: SetStateAction<State>) => void,
  openModal: OpenModal,
  id?: string
) {
  const fn = async () => {
    if (!id) {
      setState(initialState);
      return;
    }

    setState((prev) => ({ ...prev, fetchingCategory: true }));

    try {
      const category = await fetch(parseInt(id));

      const form = {
        productCategoryId: parseInt(id),
        volumeUnity: category.volumeUnity,
        description: category.description,
      };

      const selectedEditCategory = {
        id,
        name: category.description,
      };

      const selectedFatherCategory = !category.fatherId
        ? null
        : {
            id: "" + category.fatherId,
            name: category.fatherDescription!,
          };

      setState((prev) => ({
        ...prev,
        form,
        selectedFatherCategory,
        selectedEditCategory,
        fetchingCategory: false,
        enabled: category.type !== 0,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  };

  fn();
}

/**
 * Trata o evento do usuário selecionar a categoria que quer editar
 */
function baseHandleEditCategoryOnChange(
  history: History,
  setState: (action: SetStateAction<State>) => void,
  selectedEditCategory: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedEditCategory }));
  history.push(
    "/product/product-category/edit" +
      (selectedEditCategory ? "/" + selectedEditCategory.id : "")
  );
}

/**
 * Trata o evento do click no botão de ativar ou desativar categoria
 */
async function baseHandleToggleCategoryOnClick(
  toggle: () => void,
  enabled: boolean | undefined,
  name: string | undefined,
  openModal: OpenModal,
  closeModal: () => void
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá {enabled ? "desabilitar" : "habilitar"} a categoria {name}.
        <br />
        Confirma?
      </div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          toggle();
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

/**
 * Trata o evento do click no botão confirmação de ativar ou desativar categoria
 */
async function baseHandleConfirmToggleCategoryOnClick(
  toggle: ToggleProductCategory,
  setState: (action: SetStateAction<State>) => void,
  id: string | undefined,
  enabled: boolean | undefined,
  fetch: FetchProductCategory,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    await toggle(id!, !enabled);

    setState((prev) => ({ ...prev, sendingCategory: false }));

    loadCategoryEffect(fetch, setState, openModal, id);
  } catch (e) {
    openModal(getErrorMessage(e));

    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

/**
 * Trata o evento do click no botão de deletar categoria
 */
async function baseHandleDeleteCategoryOnClick(
  deleteCategory: () => void,
  name: string | undefined,
  openModal: OpenModal,
  closeModal: () => void
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá deletar a categoria {name}.<br />
        Confirma?
      </div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          deleteCategory();
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

/**
 * Trata o evento do click no botão confirmação de deletar categoria
 */
async function baseHandleConfirmDeleteCategoryOnClick(
  deleteCategory: DeleteProductCategory,
  setState: (action: SetStateAction<State>) => void,
  id: string | undefined,
  history: History,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    await deleteCategory(parseInt(id!));

    setState((prev) => ({ ...prev, sendingCategory: false }));

    history.push(`/product/nielsen-category/edit`);

    openModal("Categoria deletada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));

    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

export default useProductCategoryCreate;
