import { google } from "google-maps";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import useGoogleMaps from "../../../hooks/useGoogleMaps";
import { authCredentials } from "../../../utils/authCredentials";
import CompanyModerationConfirmationModal from "../../components/company/CompanyModerationConfirmationModal";
import { History, SetState } from "../../types";
import { getErrorMessage } from "../error/getErrorMessage";
import useModal, { OpenModal } from "../modal/useModal";
import useCompanyRequests, {
  CompanyToModerate,
  FetchCompanyQueue,
  GetCompanyByCnpj,
  GetCompanyTopSales,
  UpdateCompany,
} from "./useCompanyRequests";
import useFetchCompanyOpts from "./useFetchCompanyOpts";

export default function useCompanyModeration() {
  // # REFS
  const instance = useRef<{ infowindow: google.maps.InfoWindow | null }>({
    infowindow: null,
  });
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const markerRef = useRef<google.maps.Marker>();
  const mapRef = useRef<google.maps.Map<Element>>();
  const geocoderRef = useRef<google.maps.Geocoder>();
  const oldPointCoordinatesRef = useRef<{ lat: number; lng: number }>();

  // # HOOKS
  const [state, setState] = useState<State>({
    loading: false,
    loadingSales: false,
    searchCnpj: null,
    searchAddress: null,
    salesWithPercent: [],
    company: {
      id: "",
      status: "",
      cnpj: "",
      companyName: "",
      tradingName: "",
      cnae: "",
      address: "",
      neighborhood: "",
      cep: "",
      city: "",
      uf: "",
      cmb: "",
      setorCensitarioId: 0,
      pointCoordinates: {
        lat: 0,
        lng: 0,
      },
      checkouts: "",
      relevance: 0,
      isBigFranchise: false,
      brand: {
        id: "",
        name: "",
        file: "",
        economicGroup: "",
      },
      category: {
        id: "",
        name: "",
        channel: "",
      },
    },
  });

  const { searchCnpj, searchAddress, company } = state;

  const [googleMapsAddress, setGoogleMapsAddress] = useState<string>("");
  const history = useHistory();
  const { cnpj: pageCnpj } = useParams<{ cnpj: string }>();
  const { openModal } = useModal();

  const moderator = authCredentials.get.authUser();

  const {
    fetchCompanyQueue,
    refreshReservation,
    getCompanyByCnpj,
    getSetorCencitarioByLatLng,
    getCompanyTopSales,
    updateCompany,
  } = useCompanyRequests();
  const { findCompanyBrandModalOpts } = useFetchCompanyOpts();
  const { google } = useGoogleMaps();

  const handleSearchFormOnChange = useCallback(
    (e: { target: { name: string; value: string } }) =>
      setState((prev) => ({ ...prev, [e.target.name]: e.target.value })),
    [setState]
  );

  const handleCompanyOnChange = useCallback(
    (e: { target: { name: string; value: string } }) =>
      setState((prev) => ({
        ...prev,
        company: { ...prev.company, [e.target.name]: e.target.value },
      })),
    [setState]
  );

  const handleCheckboxOnChange = useCallback(
    () =>
      setState((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          isBigFranchise: !prev.company.isBigFranchise,
        },
      })),
    [setState]
  );

  const handleBrandOnChange = useCallback(
    (option: Record<string, any> | null) =>
      baseHandleBrandOnChange(setState, option),
    [setState]
  );

  const handleGoogleMapsOnClick = useCallback(
    (event: google.maps.MapMouseEvent | google.maps.IconMouseEvent) => {
      setState((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          pointCoordinates: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          },
        },
      }));
    },
    [setState]
  );

  const handleSearchCompanyOnClick = useCallback(
    () => baseHandleSearchCompanyOnClick(searchCnpj!, history),
    [searchCnpj, history]
  );

  const handleConfirmOnClick = useCallback(
    (status: string, reason: string) =>
      baseHandleConfirmOnClick(
        status,
        reason,
        company,
        setState,
        updateCompany,
        fetchCompanyQueue,
        openModal
      ),
    [company, setState, updateCompany, fetchCompanyQueue, openModal]
  );

  const fetchSales = useCallback(
    () => baseFetchSales(company.id, getCompanyTopSales, setState, openModal),
    [company.id, getCompanyTopSales, setState, openModal]
  );

  const handleSaveCompanyOnClick = useCallback(
    (status: string) =>
      openModal(
        <CompanyModerationConfirmationModal
          onConfirm={handleConfirmOnClick}
          company={company}
          status={status}
        />
      ),
    [company, handleConfirmOnClick, openModal]
  );

  const handleAddressSearchOnClick = useCallback(
    () =>
      baseHandleAddressSearchOnClick(
        searchAddress!,
        geocoderRef,
        setState,
        openModal
      ),
    [searchAddress, geocoderRef, setState, openModal]
  );

  // # EFFECTS

  // Clears the sales cache when company changes
  useEffect(() => {
    fetchSales();
  }, []);

  // Loads the company when there is cnpj path param
  useEffect(() => {
    if (!pageCnpj) return;
    fetchCompany(pageCnpj, setState, openModal, getCompanyByCnpj);
  }, [pageCnpj]);

  // Syncs google maps
  useEffect(() => {
    let dragEndListener: google.maps.MapsEventListener;
    let clickListener: google.maps.MapsEventListener;
    let markerClickListener: google.maps.MapsEventListener;

    if (!google || !company || !mapContainerRef.current) return;

    if (!geocoderRef.current)
      geocoderRef.current = new (google as google).maps.Geocoder();

    if (!instance.current.infowindow)
      instance.current.infowindow = new (google as google).maps.InfoWindow();

    if (!mapRef.current)
      mapRef.current = new (google as google).maps.Map(
        mapContainerRef.current,
        {
          zoom: 16,
          center: { lat: -22.9271452, lng: -43.37182910000001 },
        }
      );

    if (!markerRef.current)
      markerRef.current = new (google as google).maps.Marker({
        position: company.pointCoordinates,
        map: mapRef.current,
        draggable: true,
      });

    if (
      company.pointCoordinates &&
      company.pointCoordinates.lat &&
      company.pointCoordinates.lng
    ) {
      markerRef.current.setPosition(company.pointCoordinates);
      mapRef.current.setCenter(company.pointCoordinates);

      if (oldPointCoordinatesRef.current !== company.pointCoordinates) {
        geocode({ location: company.pointCoordinates }, geocoderRef)
          .then((address: any) => setGoogleMapsAddress(address))
          .catch(() => setGoogleMapsAddress("ERRO"));

        getSetorCencitarioByLatLng(company.pointCoordinates)
          .then(({ setorCensitario }) =>
            setState((prev) => ({
              ...prev,
              company: {
                ...prev.company,
                city: setorCensitario.city,
                cmb: setorCensitario.cmb,
                neighborhood: setorCensitario.neighborhood,
                uf: setorCensitario.uf,
                setorCensitarioId: setorCensitario.id,
              },
            }))
          )
          .catch(() =>
            setState((prev) => ({
              ...prev,
              company: {
                ...prev.company,
                setorCensitarioId: 0,
                uf: "",
                city: "",
                cmb: "",
                neighborhood: "",
              },
            }))
          );
      }
    } else {
      markerRef.current.setPosition(null);
    }

    oldPointCoordinatesRef.current = company.pointCoordinates;

    markerClickListener = markerRef.current.addListener("click", () => {
      instance.current.infowindow?.open(mapRef.current, markerRef.current);
    });
    clickListener = mapRef.current.addListener(
      "click",
      handleGoogleMapsOnClick
    );
    dragEndListener = markerRef.current.addListener(
      "dragend",
      handleGoogleMapsOnClick
    );

    return () => {
      if (dragEndListener)
        (google as google).maps.event.removeListener(dragEndListener);
      if (clickListener)
        (google as google).maps.event.removeListener(clickListener);
      if (markerClickListener)
        (google as google).maps.event.removeListener(markerClickListener);
    };
  }, [
    google,
    company,
    mapRef,
    markerRef,
    mapContainerRef,
    oldPointCoordinatesRef,
    handleGoogleMapsOnClick,
    setGoogleMapsAddress,
    getSetorCencitarioByLatLng,
  ]);

  //Sets Info Window Content
  useEffect(() => {
    if (instance.current.infowindow)
      instance.current.infowindow.setContent(googleMapsAddress);
  }, [instance, googleMapsAddress]);

  //Refresh company to be moderated reservation to user
  useEffect(
    function () {
      const fn = async () => {
        if (!pageCnpj) return;
        try {
          refreshReservation(pageCnpj);
          const intervalId = setInterval(
            refreshReservation,
            1000 * 60 * 10,
            pageCnpj
          );

          return () => {
            clearInterval(intervalId);
          };
        } catch (e) {
          throw e;
        }
      };
      fn();
    },
    [pageCnpj]
  );

  return {
    ...state,
    mapContainerRef,
    moderator,
    handleSearchFormOnChange,
    handleSearchCompanyOnClick,
    handleCompanyOnChange,
    handleCheckboxOnChange,
    handleSaveCompanyOnClick,
    validateCompany,
    handleConfirmOnClick,
    findCompanyBrandModalOpts,
    handleBrandOnChange,
    handleAddressSearchOnClick,
  };
}

interface State {
  loading: boolean;
  loadingSales: boolean;
  searchCnpj: string | null;
  searchAddress: string | null;
  company: CompanyToModerate;
  salesWithPercent: {
    percent: number;
    total: number;
    categoryName: string;
  }[];
}

function baseHandleBrandOnChange(
  setState: SetState<State>,
  option: Record<string, any> | null
) {
  setState((prev) => ({
    ...prev,
    company: {
      ...prev.company,
      brand: {
        id: !option ? "" : option.id.toString(),
        name: !option ? "" : option.name,
        file: !option ? "" : option.file,
        economicGroup: !option ? "" : option.economicGroup,
        bigFranchise: !option ? false : option.wideBrand,
      },
      category: {
        id: !option?.categoryId ? "" : option.categoryId.toString(),
        name: !option?.categoryName ? "" : option.categoryName,
        channel: !option?.channel ? "" : option.channel.toString(),
      },
    },
  }));
}

async function baseHandleSearchCompanyOnClick(cnpj: string, history: History) {
  window.location.replace("/company/" + cnpj);
}

async function fetchCompany(
  cnpj: string,
  setState: SetState<State>,
  openModal: OpenModal,
  getCompanyByCnpj: GetCompanyByCnpj
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const company = await getCompanyByCnpj(cnpj);

    if (!company.id) {
      setState((prev) => ({ ...prev, loading: false }));
      openModal(
        "Empresa não cadastrada para ser moderada. Favor inserir ou solicitar inserção da empresa para que possa ser moderada."
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      company,
      title: company.companyName,
      searchAddress:
        company.address +
        (company.neighborhood ? ", " + company.neighborhood : "") +
        (company.city ? ", " + company.city : "") +
        (company.uf ? ", " + company.uf : ""),
      loading: false,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleAddressSearchOnClick(
  searchAddress: string,
  geocoderRef: React.MutableRefObject<google.maps.Geocoder | undefined>,
  setState: SetState<State>,
  openModal: OpenModal
) {
  try {
    const pointCoordinates = await getLatLngFromAddress(
      searchAddress,
      geocoderRef
    );
    setState((prev) => ({
      ...prev,
      company: { ...prev.company, pointCoordinates: pointCoordinates },
    }));
  } catch (e) {
    openModal("Erro ao pesquisar endereço");
  }
}

function getLatLngFromAddress(
  address: string,
  geocoderRef: React.MutableRefObject<google.maps.Geocoder | undefined>
): Promise<{ lat: number; lng: number }> {
  return new Promise((resolve, reject) => {
    geocoderRef.current?.geocode({ address }, (results, status) => {
      if (status !== "OK" || !results.length || !results[0].geometry) {
        reject(new Error(status));
        return;
      }
      resolve({
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng(),
      });
    });
  });
}

function geocode(
  params: { location: { lat: number; lng: number } },
  geocoderRef: React.MutableRefObject<google.maps.Geocoder | undefined>
) {
  return new Promise((resolve, reject) => {
    const geocoder = geocoderRef.current;

    if (!geocoder) {
      reject();
      return;
    }

    geocoder.geocode(params, (results, status) => {
      if (status !== "OK") {
        reject();
        return;
      }
      for (let i = 0; i < results.length; i++) {
        if (
          results[i].types.includes("street_address") ||
          results[i].types.includes("route") ||
          results[i].types.includes("premise")
        ) {
          resolve(results[i].formatted_address);
          return;
        }
      }

      reject();
    });
  });
}

async function handleNextCompany(
  fetchCompanyQueue: FetchCompanyQueue,
  openModal: OpenModal
) {
  try {
    const nextComp = await fetchCompanyQueue();
    nextComp
      ? window.location.replace("/company/" + nextComp)
      : openModal("Nenhum cnpj encontrado para iniciar a fila de moderação");
  } catch (err) {
    openModal("Erro ao buscar fila de empresas");
  }
}

async function baseHandleConfirmOnClick(
  status: string,
  reason: string,
  company: CompanyToModerate,
  setState: SetState<State>,
  updateCompany: UpdateCompany,
  fetchCompanyQueue: FetchCompanyQueue,
  openModal: OpenModal
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));
    const isJumpedCompany = "PULADO" === status;
    const form = {
      id: company.id,
      status: isJumpedCompany ? company.status : status,
      checkouts: company.checkouts,
      address: company.address,
      neighborhood: company.neighborhood,
      cep: company.cep,
      city: company.city,
      uf: company.uf,
      categoryId: company.category.id,
      brandId: company.brand.id,
      companyName: company.companyName,
      tradingName: company.tradingName,
      cmb: company.cmb,
      setorCensitarioId: company.setorCensitarioId,
      pointCoordinates: company.pointCoordinates,
      isBigFranchise: company.isBigFranchise,
    };
    await updateCompany(form, isJumpedCompany, reason);
    window.history.pushState({}, "", "/company/load-next/company");
    openModal(
      "Empresa atualizada com sucesso! Por favor, aguarde o carregamento de uma nova empresa."
    );
    handleNextCompany(fetchCompanyQueue, openModal);
  } catch (e: any) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

export function validateCompany(company: CompanyToModerate) {
  const errors = [];

  if (!company.address) errors.push("O endereço está vazio");
  if (!company.neighborhood) errors.push("O bairro está vazio");
  if (!company.cep) errors.push("O CEP está vazio");
  if (!company.city) errors.push("A cidade está vazia");
  if (!company.uf) errors.push("A UF está vazia");
  if (!company.category.id) errors.push("Selecione uma categoria");
  if (!company.brand.id) errors.push("Selecione uma marca");
  if (!company.cmb) errors.push("O CMB está vazio");
  if (!company.setorCensitarioId) errors.push("O setor censitário está vazio");
  if (!company.pointCoordinates.lat) errors.push("A latitude está vazia");
  if (!company.pointCoordinates.lng) errors.push("A longitude está vazia");

  return errors;
}

async function baseFetchSales(
  companyId: string,
  getCompanyTopSales: GetCompanyTopSales,
  setState: Dispatch<SetStateAction<State>>,
  openModal: OpenModal
) {
  try {
    if (!companyId) return;

    setState((prev) => ({ ...prev, loadingSales: true }));

    const response = await getCompanyTopSales(companyId);
    const sales: Array<{ total: number; categoryName: string }> =
      response.sales;

    if (sales?.length) {
      const salesTotal = sales.map((s) => s.total).reduce((a, b) => a + b);
      const salesWithPercent = sales.map((s) => ({
        ...s,
        percent: (s.total / salesTotal) * 100,
      }));

      setState((prev) => ({ ...prev, salesWithPercent }));
    }
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingSales: false }));
  }
}
