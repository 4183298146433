import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { Option } from "../../components/form/AsyncSelect";
import ProductBrandDelete from "../../components/product/brand/ProductBrandDelete";
import ProductBrandEdit from "../../components/product/brand/ProductBrandEditPage";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import useQuery from "../url/useQuery";

export default function useProductBrandManagementPage() {
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const { filter: formFilter, pageSize } = state;

  const query = useQuery();

  const filter = query.get("filter") || "";

  const { openModal } = useModal();

  const handleOnChange = useHandleOnChange(setState);

  const findProductBrands = useCallback(
    () => baseFindProductBrands(page, pageSize, openModal, setState, filter),
    [page, pageSize, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, pageSize, formFilter, e),
    [history, pageSize, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) =>
      baseHandlePageOnChange(history, formFilter, page, pageSize),
    [history, formFilter, pageSize]
  );

  const handleEditOnClick = useCallback(
    (selectedBrand: ProductBrand) =>
      openModal(<ProductBrandEdit selectedProductBrand={selectedBrand} />),
    [openModal]
  );

  const handleOnDeleteClick = useCallback(
    (productBrand: ProductBrand) =>
      openModal(<ProductBrandDelete productBrand={productBrand} />),
    [openModal]
  );

  useEffect(() => {
    findProductBrands();
  }, [findProductBrands]);

  return {
    ...state,
    page,
    findProductBrands,
    handleFindOnSubmit,
    handlePageOnChange,
    handleOnChange,
    handleEditOnClick,
    handleOnDeleteClick,
    emptyProductBrand,
  };
}

export type ChangeEvent = { target: { value?: any; name?: any } };

export interface State {
  filter: string;
  pageSize: number;
  totalPages: number | null;
  items: Array<ProductBrand> | null;
  loading: boolean;
}

export interface ProductBrand {
  id: number | null;
  file: string | null;
  name: string;
  manufacturer: Option<null> | null;
  economicGroup: string | null;
  ownBrand: boolean;
  productQtt?: number;
}

export interface Validation {
  name: string[];
  manufacturer: string[];
  errors: number;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  pageSize: 20,
  totalPages: null,
  loading: false,
  items: null,
};

const emptyProductBrand: ProductBrand = {
  id: null,
  file: null,
  name: "",
  manufacturer: null,
  economicGroup: "",
  ownBrand: false,
  productQtt: 0,
};

async function baseFindProductBrands(
  page: number,
  pageSize: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(`${baseUrl}/api/v1/product/brand/search`, {
      params: { page, pageSize, filter },
    });

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  pageSize: number,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("?pageSize=" + pageSize + "&filter=" + filter);
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number,
  pageSize: number
) {
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push(
    "/product/brand/" + page + "?pageSize=" + pageSize + "&filter=" + filter
  );
}
