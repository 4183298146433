import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import CompanyBrandDelete from "../../components/company/brand/CompanyBrandDelete";
import CompanyBrandEdit from "../../components/company/brand/CompanyBrandEdit";
import { Option } from "../../components/form/AsyncSelect";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useModal, { OpenModal } from "../modal/useModal";
import useQuery from "../url/useQuery";

export default function useCompanyBrandManagement() {
  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const query = useQuery();

  const filter = query.get("filter") || "";

  const [state, setState] = useState({ ...initialState, filter });

  const { filter: formFilter, pageSize } = state;

  const { openModal } = useModal();

  const handleOnChange = useHandleOnChange(setState);

  const findBrands = useCallback(
    () => baseFindBrand(page, pageSize, openModal, setState, filter),
    [page, pageSize, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, pageSize, formFilter, e),
    [history, pageSize, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) =>
      baseHandlePageOnChange(history, formFilter, page, pageSize),
    [history, formFilter, pageSize]
  );

  const handleEditOnClick = useCallback(
    (selectedBrand: Brand) =>
      openModal(<CompanyBrandEdit selectedBrand={selectedBrand} />),
    [openModal]
  );

  const handleOnDeleteClick = useCallback(
    (brand: Brand) => openModal(<CompanyBrandDelete brand={brand} />),
    [openModal]
  );

  useEffect(() => {
    findBrands();
  }, [findBrands]);

  return {
    ...state,
    page,
    handleFindOnSubmit,
    handleOnChange,
    handlePageOnChange,
    handleEditOnClick,
    handleOnDeleteClick,
  };
}

export type ChangeEvent = { target: { value?: any; name?: any } };

export interface State {
  filter: string;
  pageSize: number;
  totalPages: number | null;
  items: Array<Brand> | null;
  loading: boolean;
}

export interface Brand {
  category: Option<null> | null;
  id: number | null;
  name: string | null;
  file: string | null;
  economicGroup: string | null;
  cnpj8: string | null;
  wideBrand: boolean;
  companyQtt: number;
}

export interface Validation {
  name: string[];
  errors: number;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  pageSize: 20,
  totalPages: null,
  loading: false,
  items: null,
};

async function baseFindBrand(
  page: number,
  pageSize: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(`${baseUrl}/api/v1/company/brand/list`, {
      params: { page, pageSize, filter },
    });

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  pageSize: number,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push("/company/brand/1?pageSize=" + pageSize + "&filter=" + filter);
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number,
  pageSize: number
) {
  filter = filter ? encodeURIComponent(filter.replaceAll("%", "\\%")) : "";
  history.push(
    "/company/brand/" + page + "?pageSize=" + pageSize + "&filter=" + filter
  );
}
