import { useContext, useEffect, useRef, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { http } from "../api/http";
import { logout } from "../authentication/logout";
import Gate from "../components/Gate";
import { baseUrl, menus } from "../constants";
import { GlobalContext } from "../intranet/service/context/globalContext";
import { authCredentials } from "../utils/authCredentials";

const Wrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
`;

const Header = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: rgb(153, 21, 43);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 1);
  :after {
    display: block;
    clear: both;
    content: " ";
  }
`;

const Anchor = styled.a`
  display: block;
  float: right;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bolder;
  padding: 14px 15px;
  font-size: 14px;

  :hover {
    background-color: rgb(210, 44, 72);
  }

  cursor: pointer;
`;

const NavAnchor = styled.a`
  display: block;
  color: white;
  text-decoration: none !important;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 14px;

  :hover {
    background-color: black;
  }

  cursor: pointer;
`;

const Nav = styled.div`
  position: absolute;
  z-index: 5;
  width: 250px;
  box-sizing: border-box;
  background-color: #2f3640;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.75);
  border-top: solid 2px black;

  ${(props) =>
    props.open
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`;

const Body = styled.div`
  padding-top: 44px;
  :after {
    content: " ";
    display: block;
    clear: both;
  }
`;

const Logo = styled.img`
  height: 25px;
  padding: 8px;
`;

const BreadCrump = styled.div`
  border: solid 1px lightgray;
  border-radius: 5px;
  background-color: #ebebeb;
`;

const BreadCrumpItem = styled.a`
  padding: 10px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  color: black;
  text-decoration: none;
  ${(props) =>
    !props.current &&
    css`
      color: blue;
    `}
`;

const BreadCrumpSeparator = styled.div`
  display: inline-block;
  vertical-align: middle;
  :after {
    content: ">";
    display: inline;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  box-shadow: 6px 0px 5px -4px rgba(0, 0, 0, 0.75);
  min-height: 100vh;
  min-height: calc(100vh - 44px);
`;

const NavButton = styled.div`
  font-size: 26px;
  color: white;
  float: left;
  padding: 7px;
  margin-left: 10px;
  cursor: pointer;

  :hover {
    background-color: white;
    color: rgb(153, 21, 43);
  }

  ${(props) =>
    props.active &&
    css`
      background-color: white;
      color: rgb(153, 21, 43);
    `}
`;

function BreadCrumpItemWrapper(props) {
  const { current, onClick, name, href } = props;
  return (
    <>
      <BreadCrumpItem href={href} current={current} onClick={onClick}>
        {name}
      </BreadCrumpItem>
      {!current ? <BreadCrumpSeparator /> : null}
    </>
  );
}

const initialMenus = { ...menus };

function Template(props) {
  const { children, history, breadcrump } = props;
  const [navOpen, setNavOpen] = useState(false);
  const [menus, setMenus] = useState(initialMenus);
  const { menus: apiMenus, setMenus: setApiMenus } = useContext(GlobalContext);

  const { role } = authCredentials.get.authUser();

  const handleNavLinkOnClick = (e) => {
    e.preventDefault();
    history.push(e.target.pathname);
  };
  const navRef = useRef();

  useEffect(() => {
    const fn = async () => {
      const response = await http.get(baseUrl + "/api/v1/user/menus");

      setApiMenus(response.data);
    };

    fn();
  }, []);

  useEffect(() => {
    const safeApiMenus = apiMenus || [];

    const indexedApiMenus = {};

    safeApiMenus.forEach((menu) => {
      indexedApiMenus[menu.url] = {
        path: menu.url,
        name: menu.name,
        available: true,
        roles: [role],
      };
    });

    setMenus((prev) => ({ ...prev, ...indexedApiMenus }));
  }, [setMenus, apiMenus]);

  useEffect(() => {
    let onKeyDown;
    let onClick;

    if (navOpen) {
      onKeyDown = (e) => {
        if (e.key === "Escape") setNavOpen(false);
      };
      onClick = (e) => {
        if (navRef.current && !navRef.current.contains(e.target))
          setNavOpen(false);
      };
    }

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("click", onClick);
    };
  }, [navOpen, setNavOpen]);

  const handleNavButtonOnClick = () => setNavOpen((prev) => !prev);

  return (
    <Wrapper>
      <Header ref={navRef}>
        <NavButton active={navOpen} onClick={handleNavButtonOnClick}>
          <i className="fa fa-bars" />
        </NavButton>
        <Link to="/dashboard">
          <Logo src="/static/logo-horus-w.png" alt="Logomarca Horus" />
        </Link>
        <Anchor onClick={() => logout()}>Sair</Anchor>
        <Nav open={navOpen}>
          <>
            {Object.entries(menus)
              .map((entry) => ({ key: entry[0], ...entry[1] }))
              .filter((i) => i.available)
              .map((menu) => (
                <Gate allow={menu.roles} key={menu.key}>
                  <NavAnchor onClick={handleNavLinkOnClick} href={menu.path}>
                    {menu.name}
                  </NavAnchor>
                </Gate>
              ))}
          </>
        </Nav>
      </Header>
      <Body>
        <Content>
          {breadcrump ? (
            <BreadCrump>
              {breadcrump.map(({ path, name }, index) => {
                return (
                  <BreadCrumpItemWrapper
                    key={index}
                    name={name}
                    href={path}
                    onClick={handleNavLinkOnClick}
                    current={index === breadcrump.length - 1}
                  />
                );
              })}
            </BreadCrump>
          ) : (
            <div style={{ margin: "-20px" }}></div>
          )}
          {children}
        </Content>
      </Body>
    </Wrapper>
  );
}

export default withRouter(Template);
