import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import { Col, Row } from "../components/Grid";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Table from "../components/Table";
import Template from "../components/Template";
import { Title } from "../components/Titles";
import { DASHBOARDS } from "../constants";
import useCompany from "../hooks/useCompany";
import useDashboard from "../hooks/useDashboard";
import usePlans from "../hooks/usePlans";
import useUsers from "../hooks/useUsers";
import {
  allRules,
  isDateValid,
  isEmptyObj,
  isNotEmpty,
  makeValidator,
} from "../utils";

const userValidator = makeValidator({
  date: allRules(isNotEmpty("Data Histórico"), isDateValid("Data Histórico")),
});

function InternalDashForm(props) {
  const {
    onInsertSegment,
    dashSegmentation,
    setDashSegmentation,
    plans,
    type,
    CHANNELS,
  } = props;
  const [collapsed, setCollapsed] = useState(true);
  const [UFS, setUFS] = useState(null);
  const [channels, setChannels] = useState(null);
  const [selCategories, setSelCategories] = useState(null);
  const [selSegments, setSelSegments] = useState(null);
  const { getDateErrors, getAllErrors } = userValidator;

  useEffect(() => {
    if (dashSegmentation.planSelected) {
      dashSegmentation.signatureId = plans[dashSegmentation.planSelected].id;
      dashSegmentation.manufacturer =
        plans[dashSegmentation.planSelected].params.manufacturer;
      if ("ufs" in plans[dashSegmentation.planSelected].params) {
        let ufList = [];
        for (
          let i = 0;
          i < plans[dashSegmentation.planSelected].params.ufs.length;
          i++
        ) {
          ufList.push(plans[dashSegmentation.planSelected].params.ufs[i]);
        }
        setUFS(ufList);
      }
      if ("channels" in plans[dashSegmentation.planSelected].params) {
        let chaList = [];
        for (
          let i = 0;
          i < plans[dashSegmentation.planSelected].params.channels.length;
          i++
        ) {
          chaList.push(
            CHANNELS.filter(
              (chId) =>
                chId.id ===
                plans[dashSegmentation.planSelected].params.channels[i]
            )[0]
          );
        }
        setChannels(chaList);
      }
      if ("categories" in plans[dashSegmentation.planSelected].params) {
        let catList = [];
        for (
          let i = 0;
          i <
          Object.keys(plans[dashSegmentation.planSelected].params.categories)
            .length;
          i++
        ) {
          catList.push({
            id: Object.keys(
              plans[dashSegmentation.planSelected].params.categories
            )[i],
            value: Object.values(
              plans[dashSegmentation.planSelected].params.categories
            )[i],
          });
        }
        setSelCategories(catList);
      }
      if ("segments" in plans[dashSegmentation.planSelected].params) {
        let segList = [];
        for (
          let i = 0;
          i <
          Object.keys(plans[dashSegmentation.planSelected].params.segments)
            .length;
          i++
        ) {
          segList.push({
            id: Object.keys(
              plans[dashSegmentation.planSelected].params.segments
            )[i],
            value: Object.values(
              plans[dashSegmentation.planSelected].params.segments
            )[i],
          });
        }
        setSelSegments(segList);
      }
    }
  }, [dashSegmentation.planSelected, plans]);

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (window.confirm("Verifique se os dados estão corretos.")) {
          setCollapsed(true);
          onInsertSegment(dashSegmentation);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <Card>
          <Input
            label="Visualiza Dashboard?"
            placeholder="Selecione..."
            value={dashSegmentation.visible}
            onChange={(e) =>
              setDashSegmentation({
                ...dashSegmentation,
                visible: e.target.value,
              })
            }
            options={[
              { id: true, name: "Sim" },
              { id: false, name: "Não" },
            ]}
          />
          {!plans ? null : (
            <Input
              label="Assinatura"
              placeholder="Selecione..."
              value={dashSegmentation.planSelected}
              onChange={(e) =>
                setDashSegmentation({
                  ...dashSegmentation,
                  planSelected: e.target.value,
                })
              }
              options={plans.map((plan, index) => {
                return {
                  id: index,
                  name: JSON.stringify(plan.params)
                    .replaceAll("{", "")
                    .replaceAll("}", "")
                    .replaceAll('"', ""),
                };
              })}
            />
          )}
          {!dashSegmentation.planSelected ? null : (
            <>
              <div>
                {!(type === "POWERBI") ? null : (
                  <>
                    <Input
                      label="Id do dataset"
                      value={dashSegmentation.datasetBiId}
                      onChange={(e) =>
                        setDashSegmentation({
                          ...dashSegmentation,
                          datasetBiId: e.target.value,
                        })
                      }
                    />
                    <Input
                      label="Role"
                      value={dashSegmentation.role}
                      onChange={(e) =>
                        setDashSegmentation({
                          ...dashSegmentation,
                          role: e.target.value,
                        })
                      }
                    />
                  </>
                )}
              </div>
              <div>
                {!UFS ? null : (
                  <Input
                    label="UF"
                    value={dashSegmentation.ufs}
                    placeholder="Selecione..."
                    onChange={(next) => {
                      setDashSegmentation((prev) => {
                        if (
                          "ufs" in dashSegmentation &&
                          prev.ufs.includes("all") &&
                          next.length > 1
                        ) {
                          return {
                            ...prev,
                            ufs: next.filter((i) => i !== "all"),
                          };
                        }
                        if ("ufs" in dashSegmentation && next.includes("all")) {
                          return { ...prev, ufs: ["all"] };
                        }
                        return { ...prev, ufs: next };
                      });
                    }}
                    options={[
                      { id: "all", name: "Todas as UFs" },
                      ...UFS.map((i) => ({ id: i, name: i })),
                    ]}
                    multiple
                  />
                )}
              </div>
              <div>
                {!selCategories ? null : (
                  <Input
                    label="Categoria"
                    value={dashSegmentation.categories}
                    placeholder="Selecione..."
                    onChange={(next) => {
                      setDashSegmentation((prev) => {
                        if (
                          "categories" in dashSegmentation &&
                          prev.categories.includes("all") &&
                          next.length > 1
                        ) {
                          return {
                            ...prev,
                            categories: next.filter((i) => i !== "all"),
                          };
                        }
                        if (
                          "categories" in dashSegmentation &&
                          next.includes("all")
                        ) {
                          return { ...prev, categories: ["all"] };
                        }
                        return { ...prev, categories: next };
                      });
                    }}
                    options={[
                      { id: "all", name: "Todas as categorias" },
                      ...selCategories.map((i) => ({
                        id: i.id,
                        name: i.id + " - " + i.value,
                      })),
                    ]}
                    multiple
                  />
                )}
              </div>
              <div>
                {!selSegments ? null : (
                  <Input
                    label="Segmentos"
                    value={dashSegmentation.segments}
                    placeholder="Selecione..."
                    onChange={(next) => {
                      setDashSegmentation((prev) => {
                        if (
                          "segments" in dashSegmentation &&
                          prev.segments.includes("all") &&
                          next.length > 1
                        ) {
                          return {
                            ...prev,
                            segments: next.filter((i) => i !== "all"),
                          };
                        }
                        if (
                          "segments" in dashSegmentation &&
                          next.includes("all")
                        ) {
                          return { ...prev, segments: ["all"] };
                        }
                        return { ...prev, segments: next };
                      });
                    }}
                    options={[
                      { id: "all", name: "Todos os segmentos" },
                      ...selSegments.map((i) => ({
                        id: i.id,
                        name: i.id + " - " + i.value,
                      })),
                    ]}
                    multiple
                  />
                )}
              </div>
              <div>
                {!channels ? null : (
                  <Input
                    label="Canal"
                    value={dashSegmentation.channels}
                    placeholder="Selecione..."
                    onChange={(next) => {
                      setDashSegmentation((prev) => {
                        if (
                          "channels" in dashSegmentation &&
                          prev.channels.includes("all") &&
                          next.length > 1
                        ) {
                          return {
                            ...prev,
                            channels: next.filter((i) => i !== "all"),
                          };
                        }
                        if (
                          "channels" in dashSegmentation &&
                          next.includes("all")
                        ) {
                          return { ...prev, channels: ["all"] };
                        }
                        return { ...prev, channels: next };
                      });
                    }}
                    options={[
                      { id: "all", name: "Todos os canais" },
                      ...channels.map((i) => ({
                        id: i.id,
                        name: i.id + " - " + i.description,
                      })),
                    ]}
                    multiple
                  />
                )}
              </div>
              <div>
                <Input
                  label="Data Histórico"
                  value={dashSegmentation.date}
                  placeholder={"yyyy-mm-dd"}
                  onChange={(e) =>
                    setDashSegmentation({
                      ...dashSegmentation,
                      date: e.target.value,
                    })
                  }
                  validate={getDateErrors}
                />
              </div>
            </>
          )}
        </Card>
      </Collapse>
      {collapsed ? (
        <Button type="button" layout="info" onClick={() => setCollapsed(false)}>
          Redefinir segmentação
        </Button>
      ) : (
        <>
          <Button
            layout="confirm"
            disabled={
              getAllErrors(dashSegmentation) || !dashSegmentation.visible
            }
          >
            Adicionar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
      <hr />
      <br />
    </form>
  );
}

function ShowSegmentations(props) {
  const { categories, segments, dashSeg, CHANNELS } = props;

  return (
    <div style={{ height: "100%" }}>
      {dashSeg.dataset_id ? (
        <p>
          <strong>Dataset Bi Id: </strong>
          {dashSeg.dataset_id}
        </p>
      ) : null}
      {dashSeg.role_bi ? (
        <p>
          <strong>Role Bi: </strong>
          {dashSeg.role_bi}
        </p>
      ) : null}
      <p>
        <strong>UFs: </strong>
        {!dashSeg.ufs
          ? null
          : dashSeg.ufs.map((uf) => {
              if (uf === "all") return "Todas";
              return uf + "; ";
            })}
      </p>
      <p>
        <strong>Canais: </strong>
        {!dashSeg.channels
          ? null
          : dashSeg.channels.map((channel) => {
              if (channel === "all") return "Todos";
              return (
                CHANNELS.filter((i) => i.id === parseInt(channel))[0]
                  .description + "; "
              );
            })}
      </p>
      {!categories ? null : (
        <p>
          <strong>Categorias: </strong>
          {!dashSeg.categories
            ? null
            : dashSeg.categories.map((cat) => {
                if (cat === "all") return "Todas";
                return categories.filter((i) => i.id === cat)[0].value + "; ";
              })}
        </p>
      )}
      {!segments ? null : (
        <p>
          <strong>Segmentos: </strong>
          {!dashSeg.segments
            ? null
            : dashSeg.segments.map((seg) => {
                if (seg === "all") return "Todos";
                return segments.filter((i) => i.id === seg)[0].value + "; ";
              })}
        </p>
      )}
    </div>
  );
}

function DashboardUserSegmentationPage() {
  const { companyId, dashId, userId } = useParams();
  const {
    getDashboard,
    dashVisualizationPermission,
    internalDashVisualizationPermission,
  } = useDashboard();
  const { getPlansByCompanyIdAndTypeId } = usePlans();
  const { getUserById } = useUsers();
  const [user, setUser] = useState(null);
  const [dashSegmentation, setDashSegmentation] = useState(null);
  const [plans, setPlans] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [type, setType] = useState(null);
  const [combinations, setCombinations] = useState([]);
  const [categories, setCategories] = useState(null);
  const [segments, setSegments] = useState(null);
  const [CHANNELS, setCHANNELS] = useState([]);
  const { getChannels } = useCompany();

  const fetchChannels = React.useCallback(async () => {
    CHANNELS.length <= 0 &&
      (await getChannels().then((response) => {
        setCHANNELS(response.data.channels);
      }));
  }, [CHANNELS, setCHANNELS, getChannels]);

  useEffect(() => CHANNELS.length <= 0 && fetchChannels());

  useEffect(() => {
    if (isEmptyObj(categories) && plans) {
      let catList = [];
      plans.map((plan) => {
        if (plan.finished_at == null && "categories" in plan.params) {
          for (let i = 0; i < Object.keys(plan.params.categories).length; i++) {
            let duplicate = false;
            for (let j = 0; j < catList.length; j++) {
              if (catList[j].id === Object.keys(plan.params.categories)[i]) {
                duplicate = true;
              }
            }
            if (!duplicate)
              catList.push({
                id: Object.keys(plan.params.categories)[i],
                value: Object.values(plan.params.categories)[i],
              });
          }
        }
      });
      if (!isEmptyObj(catList)) {
        setCategories(catList);
      }
    }
  }, [plans, categories, setCategories]);

  useEffect(() => {
    if (isEmptyObj(segments) && plans) {
      let segList = [];
      plans.map((plan) => {
        if (plan.finished_at == null && "segments" in plan.params) {
          for (let i = 0; i < Object.keys(plan.params.segments).length; i++) {
            let duplicate = false;
            for (let j = 0; j < segList.length; j++) {
              if (segList[j].id === Object.keys(plan.params.segments)[i]) {
                duplicate = true;
              }
            }
            if (!duplicate)
              segList.push({
                id: Object.keys(plan.params.segments)[i],
                value: Object.values(plan.params.segments)[i],
              });
          }
        }
      });
      if (!isEmptyObj(segList)) {
        setSegments(segList);
      }
    }
  }, [plans, segments, setSegments]);

  const breadcrump = [
    {
      name: "Início",
      path: "/dashboard",
    },
    {
      name: "Empresas",
      path: "/client-company",
    },
    {
      name: companyId,
      path: "/client-company/" + companyId,
    },
  ];

  const onAttachDetachAllUsers = async (type) => {
    let dashUsers = {};
    dashUsers.menu = dashId;
    dashUsers.clientCompany = companyId;
    dashUsers["config"] = {};
    dashUsers["config"][userId] = { ...dashSegmentation["config"][userId] };
    dashUsers["config"][userId]["visible"] = type;

    try {
      await dashVisualizationPermission(dashUsers);
      dashSegmentation["config"][userId]["visible"] = type;
      setMessage("Permissão de usuário atualizada.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    }
  };

  const onInsertSegment = (dashSegmentation) => {
    let segmentations = {};
    if ("signatureId" in dashSegmentation) {
      segmentations["signatureId"] = dashSegmentation.signatureId;
      delete dashSegmentation.planSelected;
    }
    if ("manufacturer" in dashSegmentation) {
      segmentations["manufacturer"] = dashSegmentation.manufacturer;
      delete dashSegmentation.manufacturer;
    }

    if ("role" in dashSegmentation) {
      segmentations["role_bi"] = dashSegmentation.role;
      delete dashSegmentation.role;
    }
    if ("datasetBiId" in dashSegmentation) {
      segmentations["dataset_id"] = dashSegmentation.datasetBiId;
      delete dashSegmentation.datasetBiId;
    }
    if ("ufs" in dashSegmentation) {
      segmentations["ufs"] = dashSegmentation.ufs;
      delete dashSegmentation.ufs;
    }
    if ("categories" in dashSegmentation) {
      segmentations["categories"] = dashSegmentation.categories;
      delete dashSegmentation.categories;
    }
    if ("channels" in dashSegmentation) {
      segmentations["channels"] = dashSegmentation.channels;
      delete dashSegmentation.channels;
    }
    if ("segments" in dashSegmentation) {
      segmentations["segments"] = dashSegmentation.segments;
      delete dashSegmentation.segments;
    }
    if ("date" in dashSegmentation) {
      segmentations["date"] = dashSegmentation.date;
      delete dashSegmentation.date;
    }

    if (!isEmptyObj(segmentations)) {
      setCombinations([...combinations, segmentations]);
    } else {
      setMessage(
        "Segmentação vazia. Caso salve, as segmentações serão removidas"
      );
    }
  };

  const onDashSegmentation = async (dashSegmentation) => {
    let data = {};
    data.clientCompany = parseInt(companyId);
    data.menu = dashId;
    data.userId = userId;
    data.config = {};
    data["config"][userId] = {
      visible: dashSegmentation.visible === "true",
      segmentations: {},
    };
    data["config"][userId]["segmentations"] = combinations;

    try {
      await dashVisualizationPermission(data);
      const dashPermissionResponse = await internalDashVisualizationPermission(
        companyId,
        dashId
      );
      setDashSegmentation(dashPermissionResponse.data);
      setCombinations([]);
      setMessage("Segmentação de usuário atualizada.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
    }
  };

  useEffect(() => {
    if (!dashSegmentation) {
      getDashboard(dashId).then((response) => {
        internalDashVisualizationPermission(companyId, dashId).then(
          (response) => {
            setDashSegmentation(response.data);
          }
        );
      });
    }
  }, [
    dashSegmentation,
    setDashSegmentation,
    getDashboard,
    dashId,
    internalDashVisualizationPermission,
    companyId,
  ]);

  useEffect(() => {
    if (!plans) {
      let tp = !isNaN(dashId) ? "POWERBI" : null;
      for (let i = 0; i < DASHBOARDS.length; i++) {
        if (dashId === DASHBOARDS[i].id) {
          tp = DASHBOARDS[i].type;
          break;
        }
      }
      setType(tp);
      getPlansByCompanyIdAndTypeId(companyId, tp).then((response) => {
        setPlans(response.data);
      });
    }
  }, [plans, setPlans, getPlansByCompanyIdAndTypeId, companyId, dashId]);

  useEffect(() => {
    if (!user) {
      getUserById(userId).then((response) => {
        setUser(response.data);
      });
    }
  }, [user, setUser, getUserById, userId]);

  return (
    <Template breadcrump={breadcrump}>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      {dashSegmentation && user ? (
        <>
          <Title>Segmentar dashboard para usuário(a): {user.username}</Title>
          {!plans && !type && !dashSegmentation.config ? null : (
            <>
              <Row>
                <p style={{ marginLeft: "10px" }}>
                  <span>
                    <strong>Visualização habilitada: </strong>
                    {dashSegmentation["config"][userId]["visible"]
                      ? "sim"
                      : "não"}
                  </span>
                  <span>
                    <button
                      style={{ marginLeft: "5px" }}
                      onClick={
                        dashSegmentation["config"][userId]["visible"]
                          ? (e) => onAttachDetachAllUsers(false)
                          : (e) => onAttachDetachAllUsers(true)
                      }
                    >
                      Alterar
                    </button>
                  </span>
                </p>
                {!(
                  "segmentations" in dashSegmentation["config"][userId] &&
                  Array.isArray(
                    dashSegmentation["config"][userId]["segmentations"]
                  )
                )
                  ? null
                  : dashSegmentation["config"][userId]["segmentations"].map(
                      (dashSeg, index) => {
                        return (
                          <Col width="30%" key={index}>
                            <Card>
                              <p>
                                <strong>Segmentação {index + 1}</strong>
                              </p>
                              <ShowSegmentations
                                categories={categories}
                                CHANNELS={CHANNELS}
                                segments={segments}
                                dashSeg={dashSeg}
                              />
                            </Card>
                          </Col>
                        );
                      }
                    )}
              </Row>
              <br />
              <InternalDashForm
                onInsertSegment={onInsertSegment}
                dashSegmentation={dashSegmentation}
                setDashSegmentation={setDashSegmentation}
                plans={plans}
                type={type}
                CHANNELS={CHANNELS}
              />
              <Table>
                <thead>
                  <tr>
                    <th>Index</th>
                    <th>Segmentação</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {combinations.map((comb, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {!("ufs" in comb) ? null : (
                            <span>
                              UFs:{" "}
                              {comb.ufs.map((i) => {
                                if (i === "all") return "Todas";
                                return <span>{i + "; "}</span>;
                              })}
                            </span>
                          )}
                          {!("signatureId" in comb) ? null : (
                            <span style={{ marginLeft: "10px" }}>
                              Assinatura: {comb.signatureId}
                            </span>
                          )}
                          {!("categories" in comb) ? null : (
                            <span style={{ marginLeft: "10px" }}>
                              Categorias:{" "}
                              {!categories
                                ? null
                                : comb.categories.map((i) => {
                                    if (i === "all") return "Todas";
                                    return (
                                      <span>
                                        {categories.filter((j) => j.id === i)[0]
                                          .value + "; "}
                                      </span>
                                    );
                                  })}
                            </span>
                          )}
                          {!("channels" in comb) ? null : (
                            <span style={{ marginLeft: "10px" }}>
                              Canais:{" "}
                              {comb.channels.map((i) => {
                                if (i === "all") return "Todos";
                                return (
                                  <span>
                                    {CHANNELS.filter(
                                      (j) => j.id === parseInt(i)
                                    )[0].name + "; "}
                                  </span>
                                );
                              })}
                            </span>
                          )}
                          {!("segments" in comb) ? null : (
                            <span style={{ marginLeft: "10px" }}>
                              Segmentos:{" "}
                              {!segments
                                ? null
                                : comb.segments.map((i) => {
                                    if (i === "all") return "Todos";
                                    return (
                                      <span>
                                        {segments.filter((j) => j.id === i)[0]
                                          .value + "; "}
                                      </span>
                                    );
                                  })}
                            </span>
                          )}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                type="button"
                layout="confirm"
                onClick={() => onDashSegmentation(dashSegmentation)}
              >
                Salvar
              </Button>
            </>
          )}
        </>
      ) : null}
    </Template>
  );
}

export default DashboardUserSegmentationPage;
