import styled, { css } from "styled-components";

const Button = styled.button`
  padding: 7px 10px;
  color: white;
  background-color: green;
  font-weight: bolder;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  font-weight: normal;
  border: 0;
  outline: none;
  vertial-align: middle;
  height: 32px;
  box-sizing: border-box;
  ${(props) => {
    if (props.large) {
      return css`
        font-size: 16px;
      `;
    }
    if (props.small) {
      return css`
        font-size: 12px;
        padding: 3px 5px;
        height: 20px;
      `;
    }
  }}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;
    `}
  ${(props) => {
    if (props.layout === "warm") {
      return css`
        background-color: orange;
      `;
    }
    if (props.layout === "confirm") {
      return css`
        background-color: green;
      `;
    }
    if (props.layout === "cancel") {
      return css`
        background-color: gray;
      `;
    }
    if (props.layout === "error") {
      return css`
        background-color: red;
      `;
    }
    if (props.layout === "info") {
      return css`
        background-color: #238ae6;
      `;
    }
    if (props.layout === "ghost") {
      return css`
        border: none;
        color: black;
        background-color: transparent;
      `;
    }
    return css`
      background-color: transparent;
      color: black;
      border: solid 1px gray;
    `;
  }}
  :hover {
    ${(props) => {
      if (props.disabled) {
        return;
      }
      if (props.layout === "warm") {
        return css`
          background-color: darkorange;
        `;
      }
      if (props.layout === "confirm") {
        return css`
          background-color: darkgreen;
        `;
      }
      if (props.layout === "error") {
        return css`
          background-color: red;
        `;
      }
      if (props.layout === "cancel") {
        return css`
          background-color: lightgray;
          color: black;
        `;
      }
      if (props.layout === "info") {
        return css`
          background-color: blue;
        `;
      }
      return css`
        background-color: lightgray;
      `;
    }}
  }
  ${(props) => {
    if (props.layout === "plan") {
      return css`border-radius 40px;`;
    }
  }}
  ${(props) => {
    if (props.layout === "plan") {
      return css`
        color: black;
      `;
    }
  }}
  ${(props) => {
    if (props.layout === "plan") {
      return css`
        padding: 10px 30px;
      `;
    }
  }}
`;

export default Button;
