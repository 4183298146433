import useReadCouponReport, { StatusDataReport } from "./useReadCouponReport";
import styles from "./ReadCouponReport.module.css";

export default function ReadStatusReportCard(props: { report: StatusDataReport }) {

  const { report } = props;
  
  const { type, total, time, visitTime, data } = report;

  const { getColor, parseStatusName, formatTime } = useReadCouponReport();

  return (
    <div className={styles.readCouponCard}>
      <h2 className={styles.readCouponCardTitle}>{type}</h2>
      <div className="meter" style={ {width: "100%"} }>
        {
          !data ? (
            <tr><td colSpan={ 7 }>Carregando....</td></tr>
          ) : (
            data.length === 0 ? (
              <tr><td colSpan={ 7 }>Sem resultados</td></tr>
            ) : (
              data.sort((a, b) => b.percent - a.percent).map(reportData => (
                <div style={ { float: "left", width: `${reportData.percent}%`, height: "20px", backgroundColor: getColor(reportData.status) } }></div>
              ))
            )
          )
        }
      </div>
      <table className={styles.readCouponCardTable}>
        <tbody>
          <tr>
            <td style={ { textAlign: "left", width: "24%" } }>Total</td>
            <td style={ { textAlign: "right", width: "24%" } }>{ total }</td>
            <td style={ { textAlign: "right", width: "24%" } }>--</td>
            <td style={ { textAlign: "right", width: "24%" } }>{ formatTime(visitTime) }</td>
            <td style={ { textAlign: "right", width: "24%" } }>{ formatTime(time) }</td>
          </tr>
          {
            !data ? (
              <tr><td colSpan={ 7 }>Carregando....</td></tr>
            ) : (
              data.length === 0 ? (
                <tr><td colSpan={ 7 }>Sem resultados</td></tr>
              ) : (
                data.sort((a, b) => b.percent - a.percent).map(reportData => (
                  <tr>
                    <td style={ { textAlign: "left", width: "24%" } }>
                      <div className={ styles.legend } style={ { backgroundColor: getColor(reportData.status) } }></div>
                      { parseStatusName(reportData.status) }
                    </td>
                    <td style={ { textAlign: "right", width: "24%" } }>{ reportData.total }</td>
                    <td style={ { textAlign: "right", width: "24%" } }>{ `${ reportData.percent.toFixed(2) + "%" }` }</td>
                    <td style={ { textAlign: "right", width: "24%" } }>{ formatTime(reportData.visitTime) }</td>
                    <td style={ { textAlign: "right", width: "24%" } }>{ formatTime(reportData.time) }</td>
                  </tr>
                ))
              )
            )
          }
        </tbody>
      </table>
    </div>
  )
}