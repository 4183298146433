import { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { isEan, isNotEmpty, isPositive } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";

export default function useInsetProduct() {
  const [invalidEans, setInvalidEans] = useState<InvalidEans>({ eans: [] });

  const [table, setTable] = useState<Table>({
    loading: false,
    header: null,
    items: null,
  });

  const fileInput = useRef<HTMLInputElement>();

  const { openModal } = useModal();

  const handleFileOnChange = useCallback(
    () => onUploadFileSelected(fileInput, setTable, setInvalidEans),
    [setTable, setInvalidEans]
  );

  const handleUploadOnClick = useCallback(
    () => onUploadFileClick(fileInput, setTable),
    [setTable]
  );

  const handleSendOnClick = useCallback(
    () => baseSendProducts(table, setTable, setInvalidEans, openModal),
    [table, setTable, setInvalidEans, openModal]
  );

  return {
    ...table,
    fileInput,
    invalidEans,
    handleFileOnChange,
    handleUploadOnClick,
    handleSendOnClick,
  };
}

type SetTable = Dispatch<SetStateAction<Table>>;

type SetInvalidEans = Dispatch<SetStateAction<InvalidEans>>;

interface InvalidEans {
  eans: string[];
}

interface Table {
  loading: boolean;
  header: Header | null;
  items: Product[] | null;
}

interface Header {
  eanIndex: number | null;
  descriptionIndex: number | null;
  productCategoryIdIndex: number | null;
  nielsenCategoryIdIndex: number | null;
  manufacturerIdIndex: number | null;
  brandIdIndex: number | null;
  quantityIndex: number | null;
  unitOfMeasurementIndex: number | null;
  ncmIndex: number | null;
  quantityPackIndex: number | null;
}

interface Product {
  ean: string | null;
  description: string | null;
  productCategoryId: number | null;
  nielsenCategoryId: number | null;
  manufacturerId: number | null;
  brandId: number | null;
  quantity: number | null;
  unitOfMeasurement: string | null;
  ncm: string | null;
  quantityPack: number | null;
}

function getHeaders(cells: string[]) {
  let eanIndex = null;
  let descriptionIndex = null;
  let productCategoryIdIndex = null;
  let nielsenCategoryIdIndex = null;
  let manufacturerIdIndex = null;
  let brandIdIndex = null;
  let quantityIndex = null;
  let unitOfMeasurementIndex = null;
  let ncmIndex = null;
  let quantityPackIndex = null;

  for (let i = 0; i < cells.length; i++) {
    if (cells[i].trim().toLocaleLowerCase() === "codigo_ean_gapp") eanIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "descricao")
      descriptionIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "categoria_id")
      productCategoryIdIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "categoria_relatorio_id")
      nielsenCategoryIdIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "fabricante_id")
      manufacturerIdIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "marca_produto_id")
      brandIdIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "qtd") quantityIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "unidade_volume")
      unitOfMeasurementIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "ncm") ncmIndex = i;
    if (cells[i].trim().toLocaleLowerCase() === "qtd_pack")
      quantityPackIndex = i;
  }

  return {
    eanIndex,
    descriptionIndex,
    productCategoryIdIndex,
    nielsenCategoryIdIndex,
    manufacturerIdIndex,
    brandIdIndex,
    quantityIndex,
    unitOfMeasurementIndex,
    ncmIndex,
    quantityPackIndex,
  };
}

function getProducts(cells: string[], header: Header) {
  let ean = null;
  let description = null;
  let productCategoryId = null;
  let nielsenCategoryId = null;
  let manufacturerId = null;
  let brandId = null;
  let quantity = null;
  let unitOfMeasurement = null;
  let ncm = null;
  let quantityPack = null;

  if (header.eanIndex !== null)
    ean = isNotEmpty(cells[header.eanIndex]) ? cells[header.eanIndex] : null;
  if (header.descriptionIndex !== null)
    description = isNotEmpty(cells[header.descriptionIndex])
      ? cells[header.descriptionIndex]
      : null;
  if (header.productCategoryIdIndex !== null)
    productCategoryId = isPositive(Number(cells[header.productCategoryIdIndex]))
      ? Number(cells[header.productCategoryIdIndex])
      : null;
  if (header.nielsenCategoryIdIndex !== null)
    nielsenCategoryId = isPositive(Number(cells[header.nielsenCategoryIdIndex]))
      ? Number(cells[header.nielsenCategoryIdIndex])
      : null;
  if (header.manufacturerIdIndex !== null)
    manufacturerId = isPositive(Number(cells[header.manufacturerIdIndex]))
      ? Number(cells[header.manufacturerIdIndex])
      : null;
  if (header.brandIdIndex !== null)
    brandId = isPositive(Number(cells[header.brandIdIndex]))
      ? Number(cells[header.brandIdIndex])
      : null;
  if (header.quantityIndex !== null)
    quantity = isPositive(
      Number(cells[header.quantityIndex]?.replaceAll(",", "."))
    )
      ? Number(cells[header.quantityIndex]?.replaceAll(",", "."))
      : null;
  if (header.unitOfMeasurementIndex !== null)
    unitOfMeasurement = isNotEmpty(cells[header.unitOfMeasurementIndex])
      ? cells[header.unitOfMeasurementIndex]
      : null;
  if (header.ncmIndex !== null)
    ncm = isNotEmpty(cells[header.ncmIndex]) ? cells[header.ncmIndex] : null;
  quantityPack = !isPositive(Number(cells[header.quantityPackIndex!]))
    ? 1
    : Number(cells[header.quantityPackIndex!]);

  return {
    ean,
    description,
    productCategoryId,
    nielsenCategoryId,
    manufacturerId,
    brandId,
    quantity,
    unitOfMeasurement,
    ncm,
    quantityPack,
  };
}

const onUploadFileClick = (
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
  setTable: SetTable
) => {
  fileInput?.current?.click();
};

const onUploadFileSelected = (
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
  setTable: SetTable,
  setInvalidEans: SetInvalidEans
) => {
  if (fileInput!.current!.files![0])
    baseFileOnChange(fileInput, setTable, setInvalidEans);
};

function baseFileOnChange(
  fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
  setTable: SetTable,
  setInvalidEans: SetInvalidEans
) {
  let products: Product[] = [];
  let invalidEans: string[] = [];
  const reader = new FileReader();
  reader.onload = function () {
    const data = reader.result;
    const rows = data?.toString().split("\n");

    if (rows === undefined) return;

    // Verifica o index das colunas dos headers
    const header: Header = getHeaders(rows[0].split(";"));

    // Verifica o produto para cada linha de dados
    for (let i = 1; i < rows.length; i++) {
      const product: Product = getProducts(rows[i].split(";"), header);

      if (isEan(product.ean!)) {
        products.push(product);
        continue;
      }

      if (i !== rows.length - 1 && product.ean !== null) {
        invalidEans.push(product.ean!);
      }
    }

    // Alerta sobre os EANs inválidos
    setInvalidEans({ eans: invalidEans });

    // Adiciona produtos na tabela.
    setTable({ loading: false, header, items: products });
  };

  // Lê o arquivo selecionado pelo usuário como texto
  reader.readAsText(fileInput!.current!.files![0]);

  // Remove o arquivo de referência da memória
  fileInput!.current!.value = "";
}

async function baseSendProducts(
  table: Table,
  setTable: SetTable,
  setInvalidEans: SetInvalidEans,
  openModal: OpenModal
) {
  if (table.items?.length === 0) return;

  try {
    setTable((prev) => ({ ...prev, loading: true }));

    const responseStr = "Produtos enviados com sucesso.";

    await http.post(baseUrl + "/api/v1/product/insert", table.items);

    openModal(responseStr);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setTable({ loading: false, header: null, items: null });
    setInvalidEans({ eans: [] });
  }
}
