import styles from "../ui/ui.module.css"
import companyStyles from "./companyModeration.module.css";

interface Props {
  salesWithPercent: { categoryName: string, percent: number }[];
}

export default function CompanyTopSalesModal(props: Props) {

  const { salesWithPercent } = props;

  return (
    <div className={ styles.alignCenter }>
      { salesWithPercent.length > 0 ? (
        <table className={ companyStyles.SalesTable }>
          <thead>
            <tr>
              <th>Categoria</th>
              <th>Total</th>
            </tr>
          </thead>
            <tbody>
              {
                salesWithPercent.map((sale, index) => (
                  <tr key={ index }>
                    <td>{ sale.categoryName }</td>
                    <td>{ sale.percent.toFixed(2) }%</td>
                  </tr>
                ))
              }
            </tbody>
        </table>
      ) : (
        <div>Não foram encontradas vendas realizadas por essa empresa.</div>
      )}
    </div>
  )

}