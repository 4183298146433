import {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { authCredentials } from "../../../../utils/authCredentials";
import useFetchClientOptions from "../../../service/client/useFetchClientOptions";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import { FetchDataResult, Option } from "../../form/AsyncSelect";
import { Group, PartnerForm, PartnerView } from "../UserInterfaces";
import useUserRequests from "./useUserRequests";

/**
 * Hook com funções e variáveis usadas na página de criação de usuário
 */
export default function usePartnerCreate(edit: boolean) {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const moderator = authCredentials.get.authUser();

  const { openModal } = useModal();

  const [state, setState] = useState(initialState);

  const { sendWelcomeEmail } = useUserRequests();

  const { form, sendingUser, selectedEditPartner, fetchingUser, enabled } =
    state;

  const fetchClientOpts = useFetchClientOptions();

  const handleFetchClientIpts = () =>
    baseHandleFetchClientOpts(fetchClientOpts);

  const handleFormOnChange = (e: ChangeEvent) =>
    baseHandleFormOnChange(setState, e);

  const handleClientOnChange = (o: Option<null> | null) =>
    baseHandleClientOnChange(setState, o);

  const fetchUser = (id: number) => baseFetchUser(id);

  const createPartner = (form: PartnerForm) => baseCreatePartner(form);

  const handleSendButtonOnClick = useCallback(
    () =>
      baseHandleSendButtonOnClick(
        createPartner,
        setState,
        state,
        openModal,
        history
      ),
    [state]
  );

  const handleEditUserOnChange = (selected: Option<null> | null) =>
    baseHandleEditUserOnChange(history, setState, selected);

  const handleOnSelectedGroupsChange = (selectedGroups: Array<Group> | null) =>
    setState((prev) => ({ ...prev, selectedGroups }));

  const handleToggleUserActivationOnClick = useCallback(
    () =>
      baseToggleUserActivationOnClick(setState, selectedEditPartner, openModal),
    [selectedEditPartner]
  );

  const validation = useMemo(() => validate(state), [state]);

  const buttonText = useMemo(
    () => getButtonText(validation, edit, sendingUser),
    [validation, sendingUser, edit]
  );

  useEffect(() => loadUserEffect(fetchUser, setState, openModal, id), [id]);

  const showForm = !edit;

  return {
    moderator,
    form,
    sendingUser,
    selectedEditPartner,
    fetchingUser,
    enabled,
    handleFetchClientIpts,
    handleFormOnChange,
    handleClientOnChange,
    handleSendButtonOnClick,
    fetchClientOpts,
    handleEditUserOnChange,
    handleOnSelectedGroupsChange,
    handleToggleUserActivationOnClick,
    validation,
    buttonText,
    showForm,
    sendWelcomeEmail,
  };
}

/**
 * Tipo do objeto histórico
 */
type History = ReturnType<typeof useHistory>;

/**
 * Tipo do objeto que contém os dados de validação do formulário
 */
interface Validation {
  contractId: string[];
  contractor: string[];
  bp: string[];
  username: string[];
  name: string[];
  email: string[];
  partnerPermissionLevel: string[];
  reprocessPermission: string[];
  errors: number;
}

/**
 * Tipo do estado do componente.
 */
interface State {
  form: {
    productId: string | null | undefined;
    contractId: string | null | undefined;
    contractor: string | null | undefined;
    bp: string | null | undefined;
    username: string | null;
    name: string | null;
    email: string | null;
    partnerPermissionLevel: string | null;
    reprocessPermission: string | null;
  };
  selectedEditPartner: null | Option<null>;
  sendingUser: boolean;
  fetchingUser: boolean;
  enabled: boolean;
}

const initialState: State = {
  form: {
    name: null,
    email: null,
    username: null,
    partnerPermissionLevel: null,
    reprocessPermission: null,
    contractId: undefined,
    contractor: undefined,
    bp: undefined,
    productId: undefined,
  },
  selectedEditPartner: null,
  sendingUser: false,
  fetchingUser: false,
  enabled: true,
};

type ChangeEvent = { target: { value?: any; name?: any } };

type FetchUser = (id: number) => Promise<PartnerView>;

type CreatePartner = (form: PartnerForm) => Promise<number>;

function baseHandleFetchClientOpts(
  fetchClientOpts: (
    filter: string,
    offset: number | undefined
  ) => Promise<FetchDataResult<null>>
) {
  return fetchClientOpts;
}

/**
 * Um callback onChange que irá atualizar no form o atributo com o nome igual
 * ao name do input alterado
 */
function baseHandleFormOnChange(
  setState: (action: SetStateAction<State>) => void,
  e: ChangeEvent
) {
  setState((prev) => ({
    ...prev,
    form: {
      ...prev.form,
      [e.target.name]: e.target.value,
    },
  }));
}

/**
 * Um callback onChange para atualizar o estado com a opção selecionada no AsyncSelect
 */
function baseHandleClientOnChange(
  setState: (action: SetStateAction<State>) => void,
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    selectedClient: option,
    form: { ...prev.form, staff: false, partnerPermissionLevel: null },
  }));
}

/**
 * Valida os dados do formulário
 */
function validate(state: State): Validation {
  const validation: Validation = {
    username: [],
    name: [],
    email: [],
    partnerPermissionLevel: [],
    reprocessPermission: [],
    errors: 0,
    contractId: [],
    contractor: [],
    bp: [],
  };

  if (!state.form.username || !state.form.username.match(/[A-Za-z0-9_-]+/)) {
    validation.username.push("O usuário de acesso é obrigatório");
    validation.errors++;
  }
  if (state.form.username !== null) {
    if (state.form.username.length > 15) {
      validation.username.push(
        "O nome de usuário não pode conter mais de 15 digitos"
      );
      validation.errors++;
    }
  }
  if (!state.form.name || !state.form.name.match(/[A-Za-z0-9_-]+/)) {
    validation.name.push("O nome é obrigatório");
    validation.errors++;
  }
  if (
    !state.form.email ||
    !state.form.email.match(
      /^[a-zA-Z0-9-_+]+(\.[a-zA-Z0-9-_+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
    )
  ) {
    validation.email.push("Email é obrigatório");
    validation.errors++;
  }
  if (!state.form.partnerPermissionLevel) {
    validation.partnerPermissionLevel.push(
      "É necessário informar o nível da Horus Promo do parceiro"
    );
    validation.errors++;
  }
  if (!state.form.reprocessPermission) {
    validation.reprocessPermission.push(
      "É necessário informar a permissão de reprocessamento do parceiro"
    );
    validation.errors++;
  }

  return validation;
}

/**
 * Salva um usuário na api
 */
async function baseCreatePartner(form: PartnerForm): Promise<number> {
  if (form.id) {
    const response = await http.put(`${baseUrl}/api/v1/partner`, form);
    return response.data.result;
  }

  const response = await http.post(`${baseUrl}/api/v1/partner`, form);
  return response.data.result;
}

/**
 * On click do botão de enviar, tenta salvar o usuário, redireciona pra página de edição e mostra um modal de sucesso.
 * Em caso de falha, mostra um modal com o erro.
 */
async function baseHandleSendButtonOnClick(
  createPartner: CreatePartner,
  setState: (action: SetStateAction<State>) => void,
  state: State,
  openModal: OpenModal,
  history: History
) {
  const form: PartnerForm = {
    id: state.selectedEditPartner
      ? parseInt(state.selectedEditPartner.id)
      : null,
    username: state.form.username!,
    name: state.form.name!,
    email: state.form.email!,
    contractId: state.form.contractId ? state.form.contractId : null,
    contractor: state.form.contractor ? state.form.contractor : null,
    bp: state.form.bp ? state.form.bp : null,
    productId: state.form.productId ? state.form.productId : null,
    partnerPermissionLevel: state.form.partnerPermissionLevel!,
    reprocessPermission: state.form.reprocessPermission!,
  };

  setState((prev) => ({ ...prev, sendingUser: true }));

  try {
    const result = await createPartner(form);

    history.push(`/user/partner/edit/${result}`);

    openModal("Parceiro criado com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingUser: false }));
  }
}

/**
 * Retorna o texto que vai ficar no botão de enviar
 */
function getButtonText(
  validation: Validation,
  edit: boolean,
  loading: boolean
) {
  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1
      ? `Existe 1 erro`
      : `Existem ${validation.errors} erros`;
  }

  return !edit ? "Criar" : "Editar";
}

async function baseFetchUser(id: number): Promise<PartnerView> {
  const response = await http.get(`${baseUrl}/api/v1/partner/edit/${id}`);
  return response.data;
}

/**
 * Recupera da api o usuário selecionado para editar
 * e preenche o formulário de edição com ela.
 * Caso o usuário saia da página de editar pra página de criar esse mesmo
 * effect irá limpar o formulário.
 */
function loadUserEffect(
  fetch: FetchUser,
  setState: (action: SetStateAction<State>) => void,
  openModal: OpenModal,
  id?: string
) {
  const fn = async () => {
    if (!id) {
      setState(initialState);
      return;
    }

    setState((prev) => ({ ...prev, fetchingUser: true }));

    try {
      const resp = await fetch(parseInt(id));

      const form = {
        username: resp.username,
        name: resp.name,
        email: resp.email,
        contractId: resp.contractId,
        contractor: resp.contractor,
        bp: resp.bp,
        productId: resp.productId,
        partnerPermissionLevel: resp.partnerPermissionLevel,
        reprocessPermission: resp.reprocessPermission,
      };

      const selectedEditPartner = {
        id,
        name: resp.name,
      };

      setState((prev) => ({
        ...prev,
        form,
        selectedEditPartner,
        fetchingUser: false,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  };

  fn();
}

/**
 * Trata o evento de selecionar o usuário que quer editar
 */
function baseHandleEditUserOnChange(
  history: History,
  setState: (action: SetStateAction<State>) => void,
  selectedEditUser: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedEditUser }));
  history.push(
    "/partner/edit" + (selectedEditUser ? "/" + selectedEditUser.id : "")
  );
}

async function baseToggleUserActivationOnClick(
  setState: (action: SetStateAction<State>) => void,
  selectedEditUser: Option<null> | null,
  openModal: OpenModal
) {
  try {
    const editUser = selectedEditUser ? parseInt(selectedEditUser?.id) : null;

    await http.put(`${baseUrl}/api/v1/partner/${editUser}/toggle-activation`);

    setState((prev) => ({ ...prev, enabled: !prev.enabled }));

    openModal("Ativação do usuário alterada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}
