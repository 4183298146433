import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import useAnalysisServicesRequests, {
  FindAnalysisServices,
} from "../../service/dashboard/useAnalysisServicesRequests";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../service/modal/useModal";
import makeClassNameFromList from "../../service/ui/makeClassNameFromList";
import { SetState } from "../../types";
import Button from "../form/Button";
import Select, { Option } from "../form/Select";
import Spacer from "../ui/Spacer";
import styles from "../ui/ui.module.css";

interface Props {
  value: string[] | null;
  onChange: (value: string[] | null) => void;
}

interface State {
  options: Option[] | null;
}

export default function AnalysisServicesSelectForm(props: Props) {
  const { findAnalysisServices } = useAnalysisServicesRequests();

  const { openModal } = useModal();

  const { value, onChange } = props;

  const [state, setState] = useState<State>({ options: null });

  const { options } = state;

  const handleOnAnalysisServicesSelect = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) =>
      baseHandleOnAnalysisServicesSelect(e, value, onChange),
    [value, onChange]
  );

  const handleOnAnalysisServicesRemove = useCallback(
    (selected: string) =>
      baseHandleOnAnalysisServicesRemove(selected, value, onChange),
    [value, onChange]
  );

  const availableOptions = useMemo(
    () => makeAvailableOptions(options, value),
    [options, value]
  );

  const load = () => baseLoad(findAnalysisServices, setState, openModal);

  useEffect(() => {
    load();
  }, []);

  return (
    <div>
      {!availableOptions ? (
        <>
          Carregando opções...
          <Spacer />
        </>
      ) : (
        <Select
          label="Adicionar Instância do Analysis Services utilizada por este Dashboard"
          onChange={handleOnAnalysisServicesSelect}
          value={null}
          options={availableOptions}
        />
      )}

      {!value ? null : (
        <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Ações</th>
                <th
                  className={makeClassNameFromList([
                    styles.fullWidth,
                    styles.left,
                  ])}
                >
                  Nome
                </th>
              </tr>
            </thead>
            <tbody>
              {value.map((item) => (
                <tr key={item}>
                  <td>
                    <Button
                      transparent
                      type="cancel"
                      small
                      onClick={() => handleOnAnalysisServicesRemove(item)}
                    >
                      Remover
                    </Button>
                  </td>
                  <td
                    className={makeClassNameFromList([
                      styles.fullWidth,
                      styles.left,
                    ])}
                  >
                    {item}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Spacer />
        </>
      )}
    </div>
  );
}

function baseHandleOnAnalysisServicesSelect(
  e: ChangeEvent<HTMLSelectElement>,
  value: string[] | null,
  onChange: (value: string[] | null) => void
) {
  if (!e.target.value) return;

  onChange(
    value === null
      ? [e.target.value]
      : [...value, e.target.value].sort((a, b) => a.localeCompare(b))
  );
}

function baseHandleOnAnalysisServicesRemove(
  selected: string,
  value: string[] | null,
  onChange: (value: string[] | null) => void
) {
  if (value === null) return;

  const newValue = value.filter((i) => i !== selected);

  onChange(newValue.length ? newValue : null);
}

function makeAvailableOptions(
  options: Option[] | null,
  value: string[] | null
) {
  if (!options) return options;

  if (!value) return options;

  return options.filter((o) => !value.find((i) => i === o.id));
}

async function baseLoad(
  findAnalysisServices: FindAnalysisServices,
  setState: SetState<State>,
  openModal: OpenModal
) {
  try {
    const result = await findAnalysisServices();
    setState((prev) => ({
      ...prev,
      options: result.map((i) => ({ id: i.name, name: i.name })),
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}
