import { Redirect, Route } from "react-router-dom"
import { getCredentials } from '../utils/authCredentials/getCredentials'

function PrivateRoute(props) {
  const { children, ...otherProps } = props;
  let hasCredentials = false

  if(getCredentials.accessToken() && getCredentials.authUser()){
    hasCredentials = true
  }

  return (
    <Route { ...otherProps }>
      {
        hasCredentials ? children : <Redirect to='/' />
      }
    </Route>
  )
}

export default PrivateRoute