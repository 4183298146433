import React from "react";
import AsyncSelect2 from "../../AsyncSelect2";
import useProductBrand from "../../../hooks/useProductBrand";

export default function  SearchBrand({ brand, setBrand, goToEdit }) {

  const { searchBrands } = useProductBrand();

  return (
    <>
      <div>
        <AsyncSelect2
          label="Buscar Marca de Produto para Editar"
          value={brand?.id}
          valueName={brand?.name}
          onChange={option => {
            setBrand(option);
            goToEdit(option);
          }}
          fetchData={searchBrands}
        />
      </div>
    </>
  );
}
