import { Title } from "../../../components/Titles";
import SubTitle from "../ui/SubTitle";
import Card from "../ui/Card";
import Tabs from "../ui/Tabs";
import Platform from "../ui/Platform";
import { Route, Switch } from "react-router-dom";
import { useMenu } from "../../service/context/globalContext";
import ClientListPage from "./ClientListPage";
import ClientCreatePage from "./ClientCreatePage";

export default function ClientManagementPage() {

  const menu = useMenu("/client");

  return (
    <Platform>
      <Title>{menu.name}</Title>
      <SubTitle>
        Listagem, criação e alteração de Clientes
      </SubTitle>
      <Tabs menu={menu} />
      <Card>
        <Switch>
          <Route exact path="/client/list/:page">
            <ClientListPage />
          </Route>
          <Route exact path="/client/new">
            <ClientCreatePage />
          </Route>
          <Route exact path={ [ "/client/edit", "/client/edit/:id" ] }>
            <ClientCreatePage edit />
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}