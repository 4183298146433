import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult, Option } from "../form/AsyncSelect";

/**
 * Hook que possui funções usadas pelos formulários de moderação de categoria
 */
function useCategoryRegistration() {
  const fetchMotherCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchCategoryOpts("mother", filter, offset);

  const fetchProductCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchCategoryOpts("product", filter, offset);

  const fetchNielsenCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchCategoryOpts("nielsen", filter, offset);

  const fetchProductCategory = (id: number) => baseFetchProductCategory(id);

  const createProductCategory = (form: ProductCategoryForm) =>
    baseCreateProductCategory(form);

  const deleteProductCategory = (id: number) => baseDeleteProductCategory(id);

  const fetchNielsenCategory = (id: number) => baseFetchNielsenCategory(id);

  const createNielsenCategory = (form: NielsenCategoryForm) =>
    baseCreateNielsenCategory(form);

  const deleteNielsenCategory = (nielsenId: number) =>
    baseDeleteNielsenCategory(nielsenId);

  const toggleProductCategory = (id: string, enable: boolean) =>
    baseToggleProductCategory(id, enable);

  const saveMotherCategoryEdit = (
    selectedMotherCategory: Option<null> | null
  ) => baseSaveMotherCategoryEdit(selectedMotherCategory);

  const saveAuntCategoryEdit = (selectedAuntCategory: Option<null> | null) =>
    baseSaveAuntCategoryEdit(selectedAuntCategory);

  return {
    fetchMotherCategoryOpts,
    fetchProductCategoryOpts,
    fetchNielsenCategoryOpts,
    fetchProductCategory,
    createProductCategory,
    fetchNielsenCategory,
    createNielsenCategory,
    deleteNielsenCategory,
    toggleProductCategory,
    deleteProductCategory,
    saveMotherCategoryEdit,
    saveAuntCategoryEdit,
  };
}

/**
 * Retorno da request de buscar opções de formulário
 */
interface OptionsView {
  result: {
    value: number;
    description: string;
  }[];
}

/**
 * Payload da request de criar categoria de produto
 */
export interface ProductCategoryForm {
  categoryFatherId: number | undefined;
  productCategoryId: number | undefined;
  volumeUnity: string;
  description: string;
}

/**
 * Retorno da request de buscar categoria de produto
 */
export interface ProductCategoryView {
  id: number;
  description: string;
  fatherId?: number;
  fatherDescription?: string;
  volumeUnity: string;
  type: number;
}

/**
 * Retorno da request de buscar categoria nielsen
 */
export interface NielsenCategoryView {
  description: string;
  nielsenId: number;
  creationDate: string;
  updateDate: string;
  motherCategory: string;
  motherCategoryId: number;
  auntCategory: string;
  auntCategoryId: number;
  lvl1CategoryDescription: string;
  lvl1CategoryId: number;
  productCategories: { value: number; description: string }[];
}

/**
 * Payload da request de criar categoria nielsen
 */
export interface NielsenCategoryForm {
  nielsenCategoryId: number | undefined;
  motherCategoryId: number | undefined;
  motherCategoryDescription: string | undefined;
  auntCategoryId: number | undefined;
  auntCategoryDescription: string | undefined;
  lvl1CategoryId: number;
  productCategoryIds: number[];
  description: string;
}

/**
 * Retorno da request de criar categoria de produto
 */
export interface ProductCategoryUpdateView {
  id: number;
}

/**
 * Retorno da request de criar categoria nielsen
 */
export interface NielsenCategoryUpdateView {
  nielsenId: number;
}

/**
 * Tipo da função de criar categoria de produto
 */
export type CreateProductCategory = (
  form: ProductCategoryForm
) => Promise<ProductCategoryUpdateView>;

/**
 * Tipo da função de deletar categoria de produto
 */
export type DeleteProductCategory = (nielsenId: number) => Promise<void>;

/**
 * Tipo da função de buscar categoria de produto
 */
export type FetchProductCategory = (id: number) => Promise<ProductCategoryView>;

/**
 * Tipo da função de criar categoria nielsen
 */
export type CreateNielsenCategory = (
  form: NielsenCategoryForm
) => Promise<NielsenCategoryUpdateView>;

/**
 * Tipo da função de deletar categoria nielsen
 */
export type DeleteNielsenCategory = (nielsenId: number) => Promise<void>;

/**
 * Tipo da função de buscar categoria nielsen
 */
export type FetchNielsenCategory = (id: number) => Promise<NielsenCategoryView>;

/**
 * Tipo da função de ativar ou desativar categoria de produto
 */
export type ToggleProductCategory = (
  id: string,
  enable: boolean
) => Promise<void>;

/**
 * Recupera da api uma lista de opções da categoria do tipo informado
 */
async function baseFetchCategoryOpts(
  type: string,
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(
    `${baseUrl}/api/v1/${type}-category/options`,
    {
      params: { filter, offset, limit: 10 },
    }
  );

  const data = response.data as OptionsView;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

/**
 * Cria uma categoria produto na api
 */
async function baseCreateProductCategory(
  form: ProductCategoryForm
): Promise<ProductCategoryUpdateView> {
  const response = await http.post(`${baseUrl}/api/v1/product-category`, form);
  return response.data;
}

/**
 * Deleta uma categoria produto na api
 */
async function baseDeleteProductCategory(id: number): Promise<void> {
  await http.delete(`${baseUrl}/api/v1/product-category/${id}`);
}

/**
 * Busca uma categoria produto na api e a categoria pai caso necessário,
 * só por causa da descrição, tem que otimizar isso depois.
 */
async function baseFetchProductCategory(
  id: number
): Promise<ProductCategoryView> {
  const response = await http.get(`${baseUrl}/api/v1/product-category/${id}`);
  const data = response.data as ProductCategoryView;

  if (!data.fatherId) {
    return data;
  }

  const fatherResponse = await http.get(
    `${baseUrl}/api/v1/product-category/${data.fatherId}`
  );
  const fatherData = fatherResponse.data as ProductCategoryView;

  data.fatherDescription = fatherData.description;

  return data;
}

/**
 * Cria uma categoria nielsen na api
 */
async function baseCreateNielsenCategory(
  form: NielsenCategoryForm
): Promise<NielsenCategoryUpdateView> {
  const response = await http.post(`${baseUrl}/api/v1/nielsen-category`, form);
  return response.data;
}

/**
 * Deleta uma categoria nielsen na api
 */
async function baseDeleteNielsenCategory(nielsenId: number): Promise<void> {
  await http.delete(`${baseUrl}/api/v1/nielsen-category/${nielsenId}`);
}

/**
 * Busca uma categoria produto na api e a categoria pai caso necessário,
 * só por causa da descrição, tem que otimizar isso depois.
 */
async function baseFetchNielsenCategory(
  id: number
): Promise<NielsenCategoryView> {
  const response = await http.get(`${baseUrl}/api/v1/nielsen-category/${id}`);
  return response.data as NielsenCategoryView;
}

/**
 * Habilita ou desabilita categorias pai
 */
async function baseToggleProductCategory(
  id: string,
  enable: boolean
): Promise<void> {
  await http.post(
    `${baseUrl}/api/v1/product-category/${id}/${enable ? "enable" : "disable"}`
  );
}

/**
 * Edita o nome de categorias mãe
 */
async function baseSaveMotherCategoryEdit(
  selectedMotherCategory: Option<null> | null
): Promise<void> {
  await http.post(
    `${baseUrl}/api/v1/mother-category/${selectedMotherCategory?.id}?name=${selectedMotherCategory?.name}`
  );
}

/**
 * Edita o nome de categorias tia
 */
async function baseSaveAuntCategoryEdit(
  selectedAuntCategory: Option<null> | null
): Promise<void> {
  await http.post(
    `${baseUrl}/api/v1/aunt-category/${selectedAuntCategory?.id}?name=${selectedAuntCategory?.name}`
  );
}

export default useCategoryRegistration;
