import { ReactNode } from "react";

function SubTitle(props: { children?: ReactNode }) {
  return (
    <p className="h-title-obs">
      { props.children }
    </p>
  )
}

export default SubTitle;