import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { login } from "../authentication/login";
import {
  Button,
  Input,
  Logo,
  RadioButton,
  Template,
} from "../components/FrontPageForms";
import Modal from "../components/Modal";

const Form = styled.form`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  max-width: 90%;
  text-align: center;
`;

const ForgetPasswordLink = styled.div`
  color: white;
  margin-top: 8px;
  user-select: none;
  cursor: pointer;
  font-size: 13px;

  :hover {
    text-decoration: underline;
  }
`;

const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const closeErr = useCallback(() => setErr(null), [setErr]);

  return {
    loading,
    err,
    closeErr,

    login: async (credentials) => {
      setLoading(true);

      try {
        await login(credentials);
        window.location.pathname = "/dashboard";
      } catch (err) {
        setLoading(false);

        if (err.response) {
          if (err.response.status === 401) {
            setErr(new Error("Usuário ou Senha estão incorretos"));
            return;
          } else if (err.response.status === 206) {
            setErr(new Error("Usuário ou Senha estão incorretos"));
            return;
          }
        }

        setErr(new Error("Ocorreu um erro ao tentar logar"));
      }
    },
  };
};

function LoginPage() {
  const [checked, setChecked] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { loading, login, err, closeErr } = useLogin();
  const history = useHistory();

  const handleOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      login({ user: username, password });
    },
    [login, username, password]
  );

  return (
    <Template>
      {!err ? null : <Modal close={closeErr}>{err.message}</Modal>}
      <Form onSubmit={handleOnSubmit}>
        <Logo src="/static/logo-horus-w.png" />
        <Input placeholder="Usuário" value={username} setValue={setUsername} />
        <Input
          placeholder="Senha"
          password
          value={password}
          setValue={setPassword}
        />
        <RadioButton
          checked={checked}
          setChecked={setChecked}
          text="Manter-se logado"
        />
        <Button>{loading ? "Carregando..." : "Login"}</Button>
        <ForgetPasswordLink onClick={() => history.push("/forget-password")}>
          Esqueci minha senha
        </ForgetPasswordLink>
      </Form>
    </Template>
  );
}

export default LoginPage;
