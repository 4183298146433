import { UF } from "../../constants"
import Input from '../Input'

function BasicPlan(props) {
  const { planToEdit, setPlanToEdit, childCategories } = props

  return (
    <div>
      <Input
        label="Categoria"
        value={ planToEdit?.categories }
        placeholder="Selecione..."
        onChange={ options => { setPlanToEdit( planToEdit => {
          if (options.includes("all")) {
              return { ...planToEdit, categories: childCategories.map(c => c.id.toString()) }
          }
          if (options.includes("remove all")) {
              return { ...planToEdit, categories: [] }
          }
          return { ...planToEdit, categories: options }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...childCategories.map((c, i) => ({ key: c.id, id: c.id, name: c.name })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
      <Input
        label="UF"
        placeholder="Selecione..."
        value={ planToEdit?.ufs }
        onChange={ options => { setPlanToEdit( planToEdit => {
          if (options.includes("all")) {
              return { ...planToEdit, ufs: UF.map(uf => uf) }
          }
          if (options.includes("remove all")) {
              return { ...planToEdit, ufs: [] }
          }
          return { ...planToEdit, ufs: options }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...UF.map((uf, i) => ({ key: i, id: uf, name: uf })), { id: "remove all", name: "Remover Tudo" } ] }
        multiple
      />
    </div>
  )
}

export default BasicPlan;