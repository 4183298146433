import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { FetchDataResult } from "../../../components/form/AsyncSelect";

export default function useFetchGroupOptions() {
  return (filter: string, offset: number | undefined) =>
    baseFetchOpts(filter, offset);
}
interface Response {
  result: Array<{ value: string; description: string }>;
}

async function baseFetchOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/group/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as Response;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}
