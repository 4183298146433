import { useCallback, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`

const SelectedOption = styled.div`
  width: 100%;
  border: 0;
  font-size: 13px;
  cursor: pointer;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 0 7px;
  line-height: 32px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  overflow: hidden;

  ${ props => props.open && css`
    border-color: green;
  ` }
`

const Input = styled.input`
  width: 100%;
  border: 0;
  border-bottom: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
`

const OptionsBox = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: white;
  border: solid 1px lightgray;
  border-top: 0;
  z-index: 1000;
`

const Loading = styled.div`
  font-size: 13px;
  padding: 5px;
  color: lightgray;
`

const Option = styled.div`
  font-size: 13px;
  padding: 5px;
  user-select: none;
  :hover {
    background-color: lightgray;
    cursor: pointer;
  }
`

const Label = styled.label`
  transition: .2s linear;
  font-size: 12px;
  font-weight: bolder;
  ${ props => props.open && css`
    color: green;
  ` }
`

function AsyncSelect(props) {
  const { fetchData, fetchItem, value, placeholder = "Selecione...", onChange, label, required = false } = props;
  const [ search, setSearch ] = useState("");
  const [ optionsState, setOptionsState ] = useState({
    current: null,
    options: null,
    optionsLoading: false
  });
  const { options, optionsLoading, current } = optionsState;
  const [ open, setOpen ] = useState(false)
  const wrapperRef = useRef();
  const searchInputRef = useRef();

  const close = useCallback(() => {
    setOpen(false);
    setSearch("");
  }, [ setOpen, setSearch ])

  useEffect(() => {
    const handleClick = e => {
      if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
        close();
      }
    }
    const handleEsc = e => {
      if (e.key === 'Escape') {
        close();
      }
    }
    document.addEventListener("click", handleClick);
    document.addEventListener("keydown", handleEsc);
    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("keydown", handleEsc);
    };
  }, [ close ])

  useEffect(() => {
    if ((current && current.id === value) || !value) {
      return;
    }
    fetchItem(value).then(current => {
      setOptionsState(prev => ({ ...prev, current }))
    })
  }, [ value, fetchItem, setOptionsState, current ])

  useEffect(() => {
    if (!search) {
      return;
    }
    const timeout = setTimeout(() => {
      setOptionsState(prev => ({ ...prev, optionsLoading: true }));
      fetchData(search).then(options => {
        setOptionsState(prev => ({ ...prev, optionsLoading: false, options }));
      });
    }, 500);

    return () => clearTimeout(timeout);
  }, [ search, fetchData, setOptionsState ]);

  const handleOptionOnClick = option => {
    close();
    if (!onChange) {
      return;
    }
    onChange(option.id, { selectedOption: option });
    setOptionsState(prev => ({ ...prev, current: option }))
  }

  useEffect(() => { if (searchInputRef.current && open) searchInputRef.current.focus(); }, [ open ])

  const handleSelectedOptionOnClick = () => {
    setOpen(true);
  }

  return (
    <InputWrapper ref={ wrapperRef }>
      <Label open={ open }>{ label }</Label>
      <SelectedOption 
        open={ open }
        onClick={ handleSelectedOptionOnClick }>
          { !value ? placeholder : ( !current ? "Carregando..." : current.name ) }
      </SelectedOption>
      <OptionsBox style={{ display: open ? "block" : "none" }}>
        <Input
          placeholder="Pesquise..."
          value={ search }
          onChange={ e => setSearch(e.target.value) }
          ref={ searchInputRef }
        />
        {
          required ? null : (
            <Option onClick={ () => handleOptionOnClick({ id: null, name: placeholder }) }>
              { placeholder }
            </Option>
          )
        }
        {
          optionsLoading ? <Loading>Carregando...</Loading> : (!options ? null : options.map(option => {
            return (
              <Option key={ option.id } onClick={ () => handleOptionOnClick(option) }>
                { option.name }
              </Option>
            )
          }))
        }
      </OptionsBox>
    </InputWrapper>
  )
}

export default AsyncSelect