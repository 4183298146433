import useClientCreatePage from "../../service/client/useClientCreatePage"
import Button from "../form/Button";
import { SectionTitle } from "../ui/SectionTitle";
import styles from "../ui/ui.module.css";

export default function EditClientHeader(props: Props) {

  const { selectedEditClient, loading, handleWelcomeEmailOnClick, handleDeleteClientOnClick } = props;

  if (!selectedEditClient) return <></>;

  const { name: title } = selectedEditClient;

  return (
    <div style={{ marginBottom: "1em" }}>

      <h2 className={ styles.subTitle }>{ title }</h2>

      <SectionTitle title="Ações Rápidas" description="Realizar determinadas ações com apenas um click." />

      <Button type="info" disabled={ loading } onClick={ handleWelcomeEmailOnClick }>
        { loading ? "Carregando..." : "Enviar Email de Boas-Vindas" }
      </Button> { " " }

      <Button type="cancel" disabled={ loading } onClick={ handleDeleteClientOnClick }>
        { loading ? "Carregando..." : "Deletar Cliente" }
      </Button>
      <br/><br/>

      <SectionTitle title="Campos de Edição" description="Preencha os campos, atentamente, para editar um cliente." />

    </div>
  )

}

type Props = ReturnType<typeof useClientCreatePage>