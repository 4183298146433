import React from "react";
import Button from "../../Button";
import Card from "../../Card";
import Input from "../../Input";
import FormFields, {
  BASIC,
  FGV,
  POWERBI,
  PROFESSIONAL,
  METRICSWEB,
} from "../form";

export default function Details({
  plan,
  setPlan,
  childCategories,
  getManufacturers,
  channels,
  manufacturerName,
  setManufacturerName,
  getFgvCategories,
  getMetricsCategories,
  createPlan,
  updatePlan,
  cancelPlan
}) {
  const strategy = {
    BASIC: (
      <BASIC plan={plan} setPlan={setPlan} childCategories={childCategories} />
    ),
    PROFESSIONAL: (
      <PROFESSIONAL
        plan={plan}
        setPlan={setPlan}
        childCategories={childCategories && childCategories}
        getManufacturers={getManufacturers}
        manufacturerName={manufacturerName}
        setManufacturerName={setManufacturerName}
      />
    ),
    POWERBI: (
      <>
        <POWERBI
          plan={plan}
          setPlan={setPlan}
          childCategories={childCategories}
          channels={channels}
          getManufacturers={getManufacturers}
          manufacturerName={manufacturerName}
          setManufacturerName={setManufacturerName}
        />
      </>
    ),
    FGV_PREMIUM: (
      <>
        <FGV plan={plan} setPlan={setPlan} fgvCategories={getFgvCategories} />
      </>
    ),
    METRICS_WEB: (
      <METRICSWEB
        plan={plan}
        setPlan={setPlan}
        getMetricsCategories={getMetricsCategories}
      />
    ),
  };

  const options = {
    type: [
      { id: "POWERBI", name: "PowerBI" },
      { id: "FGV_PREMIUM", name: "FGV_PREMIUM" },
      { id: "METRICS_WEB", name: "METRICS_WEB" },
      { id: "BASIC", name: "Basico" },
      { id: "PROFESSIONAL", name: "Profissional" }
    ]
  };

  return (
    <>
      <form onSubmit={(event) => { event.preventDefault(); plan?.id ? updatePlan() : createPlan() }}>
        <Card>
          {plan?.id ? null : (
            <Input
              label="Tipo de Plano"
              value={plan?.plan}
              options={options.type}
              onChange={({ target }) => setPlan({ plan: target.value, clientId: plan.clientId })}
            />
          )}
          <FormFields setPlan={setPlan} plan={plan}>
            {plan && strategy[plan.plan]}
          </FormFields>
          <Button layout="confirm" type="submit">Salvar</Button>{" "}
          {plan?.id ? <Button layout="error" type="button" onClick={() => cancelPlan()}>Cancelar Plano</Button> : null}
        </Card>
      </form>
    </>
  );
}
