import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import makeClassNameFromList from "../../../service/ui/makeClassNameFromList";
import { SetState } from "../../../types";
import AsyncSelect, { FetchData, Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import styles from "../../ui/ui.module.css";
import useFetchUserOptions from "../user/useFetchUserOptions";
import groupStyles from "./styles.module.css";
import useGroupRequests, {
  AttachUserToGroup,
  DeleteGroup,
  DetachUserFromGroup,
  GetGroupInformation,
  GroupInformationView,
  GroupSaveForm,
  GroupSearchItemView,
  GroupSearchView,
  Save,
  Search,
} from "./useGroupRequests";

function GroupManagementPage() {
  const { openModal, closeModal } = useModal();

  const [state, setState] = useState(initialState);

  const { data, filter, loading } = state;

  const actualPage = data?.page || 1;

  const {
    search,
    save,
    deleteGroup,
    getGroupInformation,
    attachUserToGroup,
    detachUserFromGroup,
  } = useGroupRequests();

  const fetchUserOptions = useFetchUserOptions();

  const fetchData = useCallback(
    (page: number) => baseFetchData(search, filter, page, setState, openModal),
    [filter]
  );

  const refresh = useCallback(() => fetchData(actualPage), [actualPage]);

  const handleOnSeeClick = useCallback(
    (id: number) => {
      openModal(
        <GroupInformationModal
          openModal={openModal}
          fetchUserOptions={fetchUserOptions}
          getGroupInformation={getGroupInformation}
          attachUserToGroup={attachUserToGroup}
          detachUserFromGroup={detachUserFromGroup}
          id={id}
        />
      );
    },
    [
      getGroupInformation,
      openModal,
      fetchUserOptions,
      attachUserToGroup,
      detachUserFromGroup,
    ]
  );

  const saveGroup = () =>
    openModal(
      <GroupSaveModal save={save} openModal={openModal} refresh={refresh} />
    );

  const openGroupEditModal = (group: GroupSearchItemView) => {
    openModal(
      <GroupSaveModal
        group={group}
        save={save}
        openModal={openModal}
        refresh={refresh}
      />
    );
  };

  const openDeleteGroupModal = (id: number) =>
    openModal(
      <GroupDeleteModal
        refresh={refresh}
        deleteGroup={deleteGroup}
        openModal={openModal}
        closeModal={closeModal}
        id={id}
      ></GroupDeleteModal>
    );

  useEffect(() => {
    onEnterComponentEffect(search, setState, openModal);
  }, []);

  return (
    <div>
      <SectionTitle
        title="Gestão de Grupos"
        description="Consultar grupos, editar, deletar, criar, adicionar usuários a grupos ou remover"
      />
      <Input
        value={filter}
        onChange={(e) =>
          setState((prev) => ({ ...prev, filter: e.target.value }))
        }
        placeholder="Nome do grupo"
        label="Filtrar"
        addons={[
          <Button key="1" onClick={() => fetchData(1)} disabled={loading}>
            {loading ? "Carregando..." : "Filtrar"}
          </Button>,
        ]}
      />
      <div>
        <Button onClick={() => saveGroup()} type="confirm">
          Criar grupo
        </Button>
        <Spacer ratio={2 / 3} />
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Ações</th>
            <th>Id</th>
            <th className={`${styles.fullWidth} ${styles.left}`}>Nome</th>
            <th>Integrantes</th>
          </tr>
        </thead>
        <tbody>
          {!data ? (
            <tr>
              <td colSpan={3}>Carregando...</td>
            </tr>
          ) : (
            data.items.map((item) => (
              <tr key={item.id}>
                <td>
                  <Button
                    type="info"
                    small
                    onClick={() => handleOnSeeClick(item.id)}
                    transparent
                  >
                    Gerenciar Integrantes
                  </Button>{" "}
                  <Button
                    onClick={() => openGroupEditModal(item)}
                    type="warning"
                    small
                    transparent
                  >
                    Editar
                  </Button>{" "}
                  <Button
                    onClick={() => openDeleteGroupModal(item.id)}
                    type="cancel"
                    small
                    transparent
                  >
                    Apagar
                  </Button>
                </td>
                <td>{item.id}</td>
                <td className={`${styles.fullWidth} ${styles.left}`}>
                  {item.name}
                </td>
                <td>{item.users}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {!data ? null : (
        <Pagination
          page={data.page}
          totalPages={data.total}
          onChangePage={fetchData}
        />
      )}
    </div>
  );
}

interface State {
  data: GroupSearchView | null;
  filter: string;
  loading: boolean;
}

const initialState: State = {
  data: null,
  filter: "",
  loading: false,
};

type Refresh = () => Promise<void>;

async function onEnterComponentEffect(
  search: Search,
  setState: SetState<State>,
  openModal: OpenModal
) {
  try {
    const data = await search({});
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseFetchData(
  search: Search,
  filter: string,
  page: number,
  setState: SetState<State>,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, loading: true }));

  try {
    const data = await search({ filter, page });
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleOnSaveGroup(
  save: Save,
  openModal: OpenModal,
  saveForm: GroupSaveForm
) {
  try {
    await save(saveForm);

    openModal(
      <div className={styles.center}>O grupo foi salvo com sucesso</div>
    );
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function GroupSaveModal(props: {
  group?: GroupSearchItemView;
  save: Save;
  openModal: OpenModal;
  refresh: Refresh;
}) {
  const {
    group = { name: "", id: undefined },
    save,
    openModal,
    refresh,
  } = props;

  const { name: originalName, id } = group;

  const [state, setState] = useState<{ name: string; loading: boolean }>({
    name: originalName,
    loading: false,
  });

  const { name, loading } = state;

  const handleOnSaveGroup = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await baseHandleOnSaveGroup(save, openModal, { name, id });
      await refresh();
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  }, [save, openModal, name, refresh, id]);

  return (
    <div className={styles.center}>
      <Input
        value={name}
        onChange={(e) =>
          setState((prev) => ({ ...prev, name: e.target.value }))
        }
        placeholder="Nome do grupo"
        label="Nome"
      />

      <Button
        onClick={handleOnSaveGroup}
        type="confirm"
        disabled={!name || loading}
      >
        {loading ? "Carregando..." : "Salvar Grupo"}
      </Button>
    </div>
  );
}

function GroupInformationModal(props: {
  fetchUserOptions: FetchData<null>;
  getGroupInformation: GetGroupInformation;
  attachUserToGroup: AttachUserToGroup;
  detachUserFromGroup: DetachUserFromGroup;
  openModal: OpenModal;
  id: number;
}) {
  const {
    fetchUserOptions,
    getGroupInformation,
    attachUserToGroup,
    detachUserFromGroup,
    openModal,
    id,
  } = props;

  const [state, setState] = useState<{
    selectedUser: Option<null> | null;
    data: GroupInformationView | null;
    error: ReactNode;
    removing: number[];
    adding: boolean;
  }>({
    selectedUser: null,
    data: null,
    error: null,
    removing: [],
    adding: false,
  });

  const { data, selectedUser, error, adding, removing } = state;

  const loadGroup = useCallback(() => {
    getGroupInformation(id)
      .then((data) => setState((prev) => ({ ...prev, data })))
      .catch((err) =>
        setState((prev) => ({ ...prev, error: getErrorMessage(err) }))
      );
  }, [getGroupInformation, setState, id]);

  const handleOnAddUserClick = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, adding: true }));
      await attachUserToGroup({
        groupId: id,
        userId: parseInt(selectedUser!.id),
      });
      setState((prev) => ({ ...prev, selectedUser: null, adding: false }));
      await loadGroup();
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  }, [attachUserToGroup, loadGroup, openModal, setState, selectedUser, id]);

  const handleOnRemoveUserClick = useCallback(
    async (userId: number) => {
      try {
        setState((prev) => ({ ...prev, removing: [...prev.removing, userId] }));
        await detachUserFromGroup({ groupId: id, userId });
        await loadGroup();
        setState((prev) => ({
          ...prev,
          removing: prev.removing.filter((i) => i !== userId),
        }));
      } catch (e) {
        openModal(getErrorMessage(e));
      }
    },
    [detachUserFromGroup, loadGroup, openModal, setState, id]
  );

  useEffect(() => {
    loadGroup();
  }, [loadGroup]);

  if (!data) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div>{data.name}</div>
      <Spacer ratio={1.3} />
      {data.users.length === 0 ? (
        <h4 style={{ fontWeight: "bolder" }}>
          Não há usuários associados a este grupo
        </h4>
      ) : (
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <div
            className={styles.gridTable}
            style={{ gridTemplateColumns: "1fr min-content min-content" }}
          >
            <Fragment>
              <div
                className={makeClassNameFromList([
                  styles.left,
                  styles.gridTableHeader,
                  styles.gridTableStickyHeader,
                ])}
              >
                Usuário
              </div>
              <div
                className={makeClassNameFromList([
                  styles.center,
                  styles.gridTableHeader,
                  styles.gridTableStickyHeader,
                ])}
              >
                Empresa
              </div>
              <div
                className={makeClassNameFromList([
                  styles.center,
                  styles.gridTableHeader,
                  styles.gridTableStickyHeader,
                ])}
              >
                Ações
              </div>
            </Fragment>
            {data.users.map((user, index) => (
              <Fragment key={user.id}>
                <div
                  className={makeClassNameFromList([
                    index % 2 !== 0 ? styles.bgRow : null,
                  ])}
                  style={{ wordBreak: "break-all" }}
                >
                  {user.username}
                </div>
                <div
                  className={makeClassNameFromList([
                    index % 2 !== 0 ? styles.bgRow : null,
                    styles.center,
                  ])}
                >
                  {user.clientName}
                </div>
                <div
                  className={makeClassNameFromList([
                    index % 2 !== 0 ? styles.bgRow : null,
                    styles.center,
                  ])}
                >
                  <Button
                    small
                    disabled={!!removing.find((i) => i === user.id)}
                    type="cancel"
                    onClick={() => handleOnRemoveUserClick(user.id)}
                  >
                    {!!removing.find((i) => i === user.id) ? "..." : "Remover"}
                  </Button>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      )}
      <Spacer ratio={1.3} />
      <div className={groupStyles.addUserToGroupForm}>
        <AsyncSelect
          placeholder="Adicionar usuário..."
          fetchData={fetchUserOptions}
          value={selectedUser}
          onChange={(selectedUser) =>
            setState((prev) => ({ ...prev, selectedUser }))
          }
        />
        <Button
          type="confirm"
          disabled={!selectedUser || adding}
          onClick={handleOnAddUserClick}
        >
          {adding ? "Carregando..." : "Adicionar"}
        </Button>
      </div>
    </div>
  );
}

function GroupDeleteModal(props: {
  deleteGroup: DeleteGroup;
  openModal: OpenModal;
  closeModal: CloseModal;
  id: number;
  refresh: Refresh;
}) {
  const { deleteGroup, openModal, id, closeModal, refresh } = props;

  const [state, setState] = useState<{ loading: boolean }>({ loading: false });

  const handleOnConfirmButtonClick = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await deleteGroup(id);
      await refresh();

      openModal(<div className={styles.center}>O grupo foi apagado</div>);
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  }, [setState, deleteGroup, openModal, id, refresh]);

  return (
    <div className={styles.center}>
      <p>Tem certeza que deseja apagar o grupo?</p>
      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button onClick={handleOnConfirmButtonClick} type="confirm">
            Confirmar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

export default GroupManagementPage;
