import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";

export interface User {
  id: number;
  lastLogin: string;
  superuser: boolean;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  staff: boolean;
  active: boolean;
  dateJoined: string;
}

export type UseUserRequests = typeof useUserRequests;
export type FindById = ReturnType<UseUserRequests>["findById"];
export type SendWelcomeEmail = ReturnType<UseUserRequests>["sendWelcomeEmail"];

export default function useUserRequests() {
  const findById = (id: number) => baseFindById(id);

  const sendWelcomeEmail = (id: number) => baseSendWelcomeEmail(id);

  return {
    findById,
    sendWelcomeEmail,
  };
}

async function baseFindById(id: number): Promise<User> {
  const response = await http.get(`${baseUrl}/api/v1/user/${id}`);
  return response.data as User;
}

async function baseSendWelcomeEmail(userId: number): Promise<void> {
  await http.post(`${baseUrl}/api/v1/user/send-welcome-email`, {
    userId,
    report: false,
  });
}
