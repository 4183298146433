import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
export interface QueryExecuteForm {
  query: string;
  host: string;
  dbUser: string;
  dbPassword: string;
  charset: string;
  intoOutfile: string;
  fieldsTerminatedBy: string;
  enclosedBy: string;
  linesTerminatedBy: string;
}

export type UseQueryRequests = typeof useQueryRequests;
export type QueryExecute = ReturnType<UseQueryRequests>["queryExecute"];

export default function useQueryRequests() {
  const queryExecute = (form: QueryExecuteForm) => baseQueryExecte(form);

  return { queryExecute };
}

async function baseQueryExecte(
  form: QueryExecuteForm
): Promise<{ result: string }> {
  const response = await http.post(`${baseUrl}/api/v1/query/execute`, form);
  return response.data;
}
