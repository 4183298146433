import { useState } from "react";
import Button from "../../../../../components/Button";


export default function RemovePriority(props) {
  const { selectedItems, handleRemoveAllPriorities, handleRemovePriorities } = props;
  const [ removingItems, setRemovingItems ] = useState(false);

  return (
    <div>
      {
        selectedItems === 0 ? (
          <div>
            <p style={{ "color": "red", "textAlign": "center" }}><strong>ATENÇÃO!!!</strong></p>
            <p style={{ "color": "red", "textAlign": "center" }}>REMOVER PRIORIZAÇÃO DE TODOS OS PRODUTOS?</p>
          </div>
        ) : (
          <div>
            <p style={{ "textAlign": "center" }}>{ selectedItems } item(s) selecionado(s)</p>
            <p style={{ "textAlign": "center" }}>Tem certeza que deseja remover priorização?</p>
          </div>
        )
      }
      <Button
        layout="confirm"
        onClick={ selectedItems > 0 ? (
          () => {
            setRemovingItems(true);
            handleRemovePriorities()
          }
        ) : (
          () => {
            setRemovingItems(true);
            handleRemoveAllPriorities()
          }
        ) }
        disabled={ removingItems }
      >
        { removingItems ? "Carregando... este processo pode demorar alguns minutos" : "Remover" }
      </Button>
    </div>
  )
}