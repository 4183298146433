
export const DASHBOARD_ID_QUERY_PARAM = "did";
export const USER_ID_QUERY_PARAM = "uid";
export const GROUP_ID_QUERY_PARAM = "gid";
export const CLIENT_ID_QUERY_PARAM = "cid";

export interface SearchDashboardAssociationPathParams {
  dashboardId?: string;
  userId?: string;
  groupId?: string;
  clientId?: string;
}

export function buildSearchDashboardAssociationsPath(params: SearchDashboardAssociationPathParams = {}) {

  const { dashboardId, userId, groupId, clientId } = params;

  const urlSearchParams = new URLSearchParams();

  if (dashboardId) urlSearchParams.set(DASHBOARD_ID_QUERY_PARAM, dashboardId);
  if (userId) urlSearchParams.set(USER_ID_QUERY_PARAM, userId);
  if (groupId) urlSearchParams.set(GROUP_ID_QUERY_PARAM, groupId);
  if (clientId) urlSearchParams.set(CLIENT_ID_QUERY_PARAM, clientId);

  const queryString = urlSearchParams.toString();

  return "/dashboards/search" + (queryString ? "?" : "") + queryString;

}

export function buildEditDashboardAssociationPath(id: number) {
  return "/dashboards/association/edit/" + id;
}