import styles from "./Form.module.css";

interface Props {
  label?: string;
  onChange?: (e: { target: { name: string; checked: boolean } }) => void;
  checked?: boolean;
  name?: string;
}

function Checkbox({ label, onChange, checked, name }: Props) {
  return (
    <div className={styles.checkboxWrapper}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          name={name}
        />
        {label}
      </label>
    </div>
  );
}

export default Checkbox;
