import React, { useState } from "react";
import styled, { css } from "styled-components";
import { convertInvalidValuesToNull } from "../utils";
import Button from "./Button";

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const inputCss = css`
  width: 100%;
  border: 0;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  height: 32px;
  overflow: hidden;
  border-radius: 4px;
  ${(props) => {
    if (props.readOnly) {
      return css`
        cursor: default;
        color: gray;
      `;
    }
  }}
  :focus {
    ${(props) => {
      if (props.readOnly) {
        return;
      }
      if (props.valid) {
        return css`
          border-color: green;
        `;
      }
      return css`
        border-color: rgb(153, 21, 43);
      `;
    }}
  }
`;

const TextLine = styled.input`
  ${inputCss}
`;

const TextLineMultiple = styled.textarea`
  ${inputCss}
  height: 150px;
  font-family: inherit;
`;

const Select = styled.select`
  width: 100%;
  border: 0;
  border: solid 1px lightgray;
  outline: 0px !important;
  padding: 6px;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;
  :focus {
    ${(props) => {
      if (props.valid) {
        return css`
          border-color: green;
        `;
      }
      return css`
        border-color: rgb(153, 21, 43);
      `;
    }}
  }
`;

const Label = styled.label`
  transition: 0.2s linear;
  font-size: 12px;
  font-weight: bolder;
  ${(props) => {
    if (!props.focused) {
      return;
    }
    if (props.valid) {
      return css`
        color: green;
      `;
    }
    return css`
      color: rgb(153, 21, 43);
    `;
  }}
`;

const ValidationText = styled.div`
  color: red;
  font-weight: bolder;
  font-size: 10px;
  margin-top: 2px;
  position: absolute;
  right: 0;
`;

function isEmpty(arr) {
  return !arr || arr.length === 0;
}

function MultiSelectItem(props) {
  const { children, onRemove } = props;
  return (
    <div style={{ padding: "3px 0" }}>
      <Button type="button" layout="error" small onClick={onRemove}>
        <i className="fa fa-times" />
      </Button>
      <div
        style={{
          verticalAlign: "middle",
          display: "inline-block",
          padding: "0 3px",
          fontSize: "12px",
        }}
      >
        {children}
      </div>
    </div>
  );
}

function MultiSelect(props) {
  const {
    value = [],
    onChange,
    id,
    placeholder,
    options,
    setFocused,
    validationResult,
  } = props;

  const handleOnFocus = (e) => {
    setFocused(true);
  };

  const handleOnChange = (e) => {
    onChange([...value, e.target.value]);
  };

  const optionMap = {};

  options.forEach((i) => {
    optionMap[i.id] = i.name;
  });

  let theOptions = options;

  if (placeholder) {
    theOptions = [{ name: placeholder }, ...options];
  }

  return (
    <>
      <Select
        id={id}
        onChange={handleOnChange}
        valid={isEmpty(validationResult)}
        onFocus={handleOnFocus}
        onBlur={() => setFocused(false)}
        value=""
      >
        {theOptions
          .filter((option) => !value.find((i) => i === String(option.id)))
          .map((option) => (
            <option
              key={option.id || ""}
              value={option.id}
              style={{ display: option.id !== undefined ? "block" : "none" }}
            >
              {option.name}
            </option>
          ))}
      </Select>
      {value.map((item) => {
        return (
          <MultiSelectItem
            key={item}
            onRemove={() => {
              onChange([...value].filter((i) => i !== item));
            }}
          >
            {optionMap[item]}
          </MultiSelectItem>
        );
      })}
    </>
  );
}

function Inner(props) {
  const {
    value = "",
    placeholder,
    list,
    onChange,
    onBlur,
    id,
    type = "text",
    validate,
    options,
    setValidationResult,
    validationResult,
    setFocused,
    multiple,
    textarea,
    readOnly,
    theRef: ref,
    onFocus,
    ...otherProps
  } = props;

  const handleOnKeyUp = (e) => {
    if (validate) setValidationResult(validate(e.target.value));
  };

  const handleOnFocus = (e) => {
    if (onFocus) onFocus(e);
    if (readOnly) return;
    if (validate)
      setValidationResult(validate(convertInvalidValuesToNull(e.target.value)));
    setFocused(true);
  };

  if (options && multiple) return <MultiSelect {...props} />;

  if (options) {
    let theOptions = options;

    if (placeholder) {
      theOptions = [{ name: placeholder }, ...options];
    }

    return (
      <Select
        {...otherProps}
        id={id}
        ref={ref}
        onChange={(e) => {
          if (!onChange) return;

          const value = convertInvalidValuesToNull(e.target.value);
          if (validate) setValidationResult(validate(value));

          onChange(e, { value });
        }}
        value={value === null || value === undefined ? "null" : value}
        valid={isEmpty(validationResult)}
        onFocus={handleOnFocus}
        onBlur={(e) => {
          setFocused(false);
          if (onBlur) onBlur(e);
        }}
        readOnly={readOnly}
      >
        {theOptions.map((option) => {
          return (
            <option
              key={String(option.id)}
              value={String(option.id)}
              style={{ display: option.id !== undefined ? "block" : "none" }}
            >
              {option.name}
            </option>
          );
        })}
      </Select>
    );
  }

  const Component = !textarea ? TextLine : TextLineMultiple;

  return (
    <Component
      {...otherProps}
      id={id}
      onChange={onChange}
      value={value === undefined || value === null ? "" : value}
      valid={isEmpty(validationResult)}
      onFocus={handleOnFocus}
      onBlur={(e) => {
        if (onBlur) onBlur(e);
        setFocused(false);
      }}
      onKeyUp={handleOnKeyUp}
      type={type}
      autoComplete="off"
      list={list}
      placeholder={placeholder}
      readOnly={readOnly}
      ref={ref}
    />
  );
}

function Input(props) {
  const { label } = props;
  const [focused, setFocused] = useState(false);
  const [validationResult, setValidationResult] = useState(null);
  const innerProps = {
    ...props,
    validationResult,
    setFocused,
    setValidationResult,
  };
  return (
    <InputWrapper className="bko-input-wrapper">
      <Label focused={focused} valid={isEmpty(validationResult)}>
        {label}
      </Label>
      <Inner {...innerProps} />
      {!isEmpty(validationResult)
        ? validationResult.map((message, index) => {
            return <ValidationText key={index}>{message}</ValidationText>;
          })
        : null}
    </InputWrapper>
  );
}

const InputForwardRef = React.forwardRef((props, ref) => (
  <Input {...props} theRef={ref} />
));

export default InputForwardRef;
