import { useEffect, useState } from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import { Column33, Column50 } from "../components/Column";
import Input from "../components/Input";
import Modal from "../components/Modal";
import { PlanPrice, PlanTitle } from "../components/PlanStyle";
import Template from "../components/Template";
import { SubTitle } from "../components/Titles";
import useNewClient from "../hooks/useNewClient";
import usePlan from "../hooks/usePlan";
import { allRules, isCnpj, isEmail, isNotEmpty, makeValidator } from "../utils";

const userValidator = makeValidator({
  firstName: isNotEmpty("Nome"),
  lastName: isNotEmpty("Sobrenome"),
  companyName: isNotEmpty("Empresa"),
  plan: isNotEmpty("Plano"),
  price: isNotEmpty("Preço"),
  cnpj: allRules(isNotEmpty("CNPJ"), isCnpj("CNPJ")),
  email: allRules(isNotEmpty("E-mail"), isEmail("E-mail")),
});

function Presentation() {
  return (
    <>
      <h2>Importante:</h2>
      <ul>
        <h3>
          <li>
            O usuário cadastrado na criação da empresa será considerado como
            administrador da conta
          </li>
        </h3>
        <h3>
          <li>
            Caso a customização necessária do plano não esteja disponível, NÃO
            cadastre em um plano alternativo. Entre em contato com a TI
          </li>
        </h3>
      </ul>
    </>
  );
}

function CreateClientForm(props) {
  const [newClient, setNewClient] = useState({});
  const { onCreateClient, loading, value: plans } = props;
  const {
    getFirstNameErrors,
    getLastNameErrors,
    getEmailErrors,
    getPlanErros,
    getPriceErros,
    getCompanyNameErrors,
    getCnpjErrors,
    getAllErrors,
  } = userValidator;

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        onCreateClient(newClient);
      }}
    >
      <SubTitle>Assine já!</SubTitle>
      <Card>
        <Input
          label="Nome"
          value={newClient.firstName}
          onChange={(e) =>
            setNewClient({ ...newClient, firstName: e.target.value })
          }
          validate={getFirstNameErrors}
        />
        <Input
          label="Sobrenome"
          value={newClient.lastName}
          onChange={(e) =>
            setNewClient({ ...newClient, lastName: e.target.value })
          }
          validate={getLastNameErrors}
        />
        <Input
          label="E-Mail"
          id="email"
          value={newClient.email}
          onChange={(e) =>
            setNewClient({ ...newClient, email: e.target.value })
          }
          validate={getEmailErrors}
        />
        <Input
          label="Empresa"
          value={newClient.companyName}
          onChange={(e) =>
            setNewClient({ ...newClient, companyName: e.target.value })
          }
          validate={getCompanyNameErrors}
        />
        <Input
          label="CNPJ"
          type="number"
          value={newClient.cnpj}
          onChange={(e) => setNewClient({ ...newClient, cnpj: e.target.value })}
          validate={getCnpjErrors}
        />
        <Input
          label="Plano"
          placeholder="Selecione..."
          value={newClient.plan}
          onChange={(e) => setNewClient({ ...newClient, plan: e.target.value })}
          options={[
            { id: "legado", name: "legado" },
            { id: "trial", name: "trial" },
          ]}
          validate={getPlanErros}
        />
        {newClient.plan &&
        newClient.plan !== "legado" &&
        newClient.plan !== "trial" ? (
          <PlanServices newClient={newClient} setNewClient={setNewClient} />
        ) : null}
        {newClient.planServices ? (
          <ServiceAttr newClient={newClient} setNewClient={setNewClient} />
        ) : null}
        <Input
          label="Preço"
          type="number"
          value={newClient.price}
          onChange={(e) =>
            setNewClient({ ...newClient, price: e.target.value })
          }
          validate={getPriceErros}
        />
      </Card>
      <Button layout="confirm" disabled={getAllErrors(newClient)}>
        Enviar
      </Button>{" "}
      <Button type="button" layout="cancel">
        Cancelar
      </Button>
    </form>
  );
}

function PlanServices(props) {
  const { newClient, setNewClient } = props;
  const [loading, setLoading] = useState(true);
  const [planServices, setPlanServices] = useState(null);
  const { getAllPlanServices } = usePlan();

  useEffect(() => {
    if (!planServices) {
      getAllPlanServices(newClient.planId).then((response) => {
        setPlanServices(response.data);
        setLoading(false);
      });
    }
  }, [planServices, setPlanServices, getAllPlanServices, newClient.planId]);

  return (
    <Input
      label="Servico"
      placeholder="Selecione..."
      value={newClient.planServices}
      onChange={(e) =>
        setNewClient({ ...newClient, planServices: e.target.value })
      }
      options={
        !loading
          ? planServices.services.map((planService, index) => {
              return { id: planService.id, name: planService.name };
            })
          : null
      }
    />
  );
}

function ServiceAttr(props) {
  const { newClient, setNewClient } = props;
  const [planServicesAttributes, setPlanServicesAttributes] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getServiceAttributes } = usePlan();

  useEffect(() => {
    if (!planServicesAttributes) {
      getServiceAttributes(newClient.planServices).then((response) => {
        setPlanServicesAttributes(response.data);
        setLoading(false);
      });
    }
  }, [
    planServicesAttributes,
    setPlanServicesAttributes,
    getServiceAttributes,
    newClient.planServices,
  ]);

  return (
    <div>
      {!loading
        ? planServicesAttributes.serviceAttributes.map((attr, index) => {
            if (attr.type === "list") {
              return (
                <Input
                  label={attr.name}
                  value={newClient.attributes}
                  onChange={(e) =>
                    setNewClient({ ...newClient, planServices: e.target.value })
                  }
                  options={attr.options.map((opt, index) => {
                    return { id: opt, value: opt };
                  })}
                />
              );
            }
            return (
              <Input
                label={attr.name}
                value={newClient.attributes}
                onChange={(e) =>
                  setNewClient({ ...newClient, planServices: e.target.value })
                }
              />
            );
          })
        : null}
    </div>
  );
}

function Plans(props) {
  const { loading, value: plans } = props;

  return (
    <Column33>
      <PlanTitle>Basico</PlanTitle>
      <PlanPrice>R$1200,00</PlanPrice>
      <p>
        <planDescription>Descrição do plano a ser apresentada</planDescription>
      </p>
      <p>Composição do plano:</p>
      <planServices>
        <ul>
          <li>Quantidade de categorias: 1</li>
          <li>Quantidade de regiões: 1</li>
        </ul>
      </planServices>
      <Button large layout="plan">
        Contratar
      </Button>
    </Column33>
  );
}

function NewClientPage() {
  const [error, setError] = useState(null);
  const { createClient } = useNewClient();

  const onCreateClient = async (client) => {
    try {
      await createClient(client);
    } catch (error) {
      setError(
        error.response && error.response.status === 400
          ? new Error("Usuário já existe")
          : error
      );
    }
  };
  return (
    <Template>
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Column50>
        <Presentation />
      </Column50>
      <Column50>
        <CreateClientForm onCreateClient={onCreateClient} />
      </Column50>
      <Plans />
    </Template>
  );
}

export default NewClientPage;
