import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Loading from "../../components/Loading";
import Table from "../../components/Table";
import { SubTitle } from "../../components/Titles";
import { isEmptyObj } from "../../utils";
import { decrementDay } from "../../utils/Date";
import Card from "../../components/Card";

const Wrapper = styled.div``;

const statusTranslation = [
  { id: "DONE", name: "Processados" },
  { id: "ERROR", name: "Processados com erros" },
  { id: "PARTIAL", name: "Resumidos" },
  { id: "TO_READ", name: "Enviados" },
  { id: "TO_READ_C", name: "Contigências" },
];

function UsagePartnerRelatory(props) {
  const {
    eventPartners,
    setEventPartners,
    getEventPartnerById,
    eventloading,
    setEventLoading,
    setError,
    setMessage,
    partner,
  } = props;
  const [_, setCollapsed] = useState(true);

  const onChangePartner = async (dayInterval) => {
    setEventLoading(true);
    try {
      const eventPartnerResponse = await getEventPartnerById(
        dayInterval,
        partner
      );
      setEventPartners(eventPartnerResponse.data);
      setEventLoading(false);
    } catch (error) {
      setError(error);
    }
  };

  const onSetQtdConsumed = async (data) => {
    setMessage(
      <Wrapper>
        <Table>
          <thead>
            <tr>
              <th>Estado</th>
              <th>Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {data.detailsStates && !isEmptyObj(data.detailsStates)
              ? data.detailsStates.map((detail, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>{detail.state}</td>
                        <td>{detail.qtd}</td>
                      </tr>
                    </Fragment>
                  );
                })
              : null}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th>{data.total}</th>
            </tr>
          </tfoot>
        </Table>
      </Wrapper>
    );
  };

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        setCollapsed(true);
      }}
    >
      <Wrapper>
        <SubTitle>Log</SubTitle>
        <label>Opção </label>
        <select
          id="usage-realatory"
          onChange={(e) => {
            onChangePartner(e.target.value);
          }}
        >
          <option defaultValue value="1">
            {decrementDay(new Date(), 1)}
          </option>
          <option defaultValue value="2">
            {decrementDay(new Date(), 2)}
          </option>
          <option defaultValue value="3">
            {decrementDay(new Date(), 3)}
          </option>
          <option defaultValue value="5">
            {decrementDay(new Date(), 5)}
          </option>
          <option defaultValue value="7">
            {decrementDay(new Date(), 7)}
          </option>
          <option defaultValue value="11">
            {decrementDay(new Date(), 11)}
          </option>
          <option defaultValue value="13">
            {decrementDay(new Date(), 13)}
          </option>
          <option defaultValue value="15">
            {decrementDay(new Date(), 15)}
          </option>
        </select>
        <Card>
          <Wrapper>
            {!eventloading ? (
              <Table>
                <thead>
                  <tr>
                    <th>Status</th>
                    <th>Total</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {eventPartners && !isEmptyObj(eventPartners) ? (
                    eventPartners.map((event, index) => {
                      return (
                        <Fragment key={index}>
                          <tr>
                            <td>
                              {typeof statusTranslation.find(
                                (status) => status.id === event.status
                              ) === "undefined"
                                ? ""
                                : statusTranslation.find(
                                    (status) => status.id === event.status
                                  ).name}
                            </td>
                            <td>{event.total}</td>
                            <td>
                              <button onClick={(e) => onSetQtdConsumed(event)}>
                                Detalhes
                              </button>
                            </td>
                          </tr>
                        </Fragment>
                      );
                    })
                  ) : (
                    <tr>
                      <td>Sem dados para exibição</td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <Card>
                <Loading style={{ margin: "20px auto" }} />
              </Card>
            )}
          </Wrapper>
        </Card>
      </Wrapper>
    </form>
  );
}

export default UsagePartnerRelatory;
