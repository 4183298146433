import useReadCouponReport, { MachineDataReport } from "./useReadCouponReport";
import styles from "./ReadCouponReport.module.css";

export default function ReadMachinesReportCard(props: { report: MachineDataReport }) {

  const { report } = props;
  
  const { performance, machine, conclusive, total } = report;

  const { getMachineClass } = useReadCouponReport();

  return (
    <div className={ styles.readCouponMachineCard }>
      <h2 className={ styles.machineTitle }>{`Máquina: ${ machine }`}</h2>
      <div className={
        `${
          getMachineClass(performance) === "bad" ? styles.machineBulletBad :
          getMachineClass(performance) === "down" ? styles.machineBulletDown :
          getMachineClass(performance) === "excelent" ? styles.machineBulletExcelent :
          getMachineClass(performance) === "good" ? styles.machineBulletGood :
          getMachineClass(performance) === "ok" ? styles.machineBulletOk :
          styles.machineBulletRegular
        }`
      }>
        { total }
        <br/>
        { `${((conclusive / total) * 100).toFixed(2) + "%"}` }
      </div>
    </div>
  )
}