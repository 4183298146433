import styles from "../ui/ui.module.css";
import stylesReadCouponReport from "./ReadCouponReport.module.css";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import { SectionTitle } from "../ui/SectionTitle";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import useDebugReadCouponReport from "./useDebugReadCouponReport";
import Spacer from "../ui/Spacer";

export default function ManufacturerManagementPage() {

  const { params, items, statusValues, fetchData, newPageUrl, handleOnChange, formatDate, loading } = useDebugReadCouponReport();

  return (
    <div>
      <SectionTitle title="Debug Extratores" description="Listagem com Informações das Extrações de Notas Fiscais" />
      
      <div>
        <div className={ stylesReadCouponReport.filters }>
          <Input
            label="Extrator:"
            name="extractor"
            placeholder="Ex: SEResumedExtractor"
            value={ params.extractor }
            onChange={ handleOnChange }
          />
          <Input
            label="Provedor"
            name="provider"
            placeholder="Digite o provedor"
            value={ params.provider }
            onChange={ handleOnChange }
          />
          <Input
            label="Chave de Acesso"
            name="accessKey"
            placeholder="Digite a chave de 44 dígitos"
            value={ params.accessKey }
            onChange={ handleOnChange }
          />
          <Select
            label="Status"
            name="status"
            placeholder="Selecione..."
            value={ params.status }
            onChange={ handleOnChange }
            options={ statusValues }
          />
          <div className={ stylesReadCouponReport.moveButtonUp }></div>
          <Button onClick={ () => fetchData() } type="confirm" addInputLabelSpacing disabled={ loading }>
            Buscar
          </Button>
        </div>
      </div>
      <Spacer />
      {
        !items || loading ? null : (
          <TableResponsiveWrapper>
            <table className={ styles.table }>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Máquina</th>
                  <th>Cliente</th>
                  <th>Proxy</th>
                  <th className={ `${ styles.fullWidth } ${ styles.left }` }>Chave de Acesso</th>
                  <th>Status</th>
                  <th>Data - Hora</th>
                </tr>
              </thead>
              <tbody>
                {
                  items.length === 0 ? (
                      <tr><td colSpan={ 7 }>Sem resultados</td></tr>
                  ) : (
                    items.map(item => (
                      <tr key={ item.accesskey }>
                        <td>
                          {
                            !item.debug ? "Sem dados de debug" : (
                              <div>
                                <Button onClick={ () => newPageUrl(item.debug, "/stacktrace.txt") } type="info" small>
                                  Log
                                </Button> { ' ' }
                                <Button onClick={ () => newPageUrl(item.debug, "/screenshot.png") } type="warning" small>
                                  Print
                                </Button> { ' ' }
                                <Button onClick={ () => newPageUrl(item.debug, "/pagesource.txt") } type="cancel" small>
                                  HTML
                                </Button>
                              </div>
                            )
                          }
                        </td>
                        <td>
                          { item.machine || "Não Informado" }
                        </td>
                        <td>
                          { item.requester || "Não Informado" }
                        </td>
                        <td>
                          { item.provider?.replaceAll(/[a-z]/g, "") || "Não Informado" }
                        </td>
                        <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                          { item.accesskey || "Não Informado" }
                        </td>
                        <td>
                          { item.status }
                        </td>
                        <td>
                          { formatDate(item.end) }
                        </td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </table>
          </TableResponsiveWrapper>
        )
      }
    </div>
  )
}