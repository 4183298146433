import { useCallback } from "react";
import { http } from "../api/http";
import { baseUrl } from "../constants";

function useProduct() {
  const getProductPage = useCallback(function (ean) {
    return http.get(baseUrl + `/api/v2/product/${ean}`);
  }, []);

  const searchProduct = (description, category) =>
    http.get(
      baseUrl +
        "/api/v1/products/search?description=" +
        description +
        "&category=" +
        category
    );

  const getProductByEan = (ean) =>
    http.get(baseUrl + "/api/v1/products/" + ean);

  const getAllCategories = () =>
    http.post(baseUrl + "/api/v1/products/categories");

  const getCategoriesByDescription = (ncm, catId) =>
    http.post(baseUrl + "/api/v1/products/categories/" + ncm + "/" + catId);

  const getCategory = (catId) =>
    http.post(baseUrl + "/api/v1/products/category/" + catId);

  const getComercialReferencesByEan = (ean) =>
    http.post(baseUrl + "/api/v1/products/comercialRefs/" + ean);

  const getAllNCMS = () => http.get(baseUrl + "/api/v1/products/ncms");

  const getNCMS = (desc) => http.get(baseUrl + "/api/v1/products/ncms/" + desc);

  const sendNCMRequest = (ncm) =>
    http.post(baseUrl + "/api/v1/products/ncm-request", ncm);

  const saveNcm = (ncm) => http.post(baseUrl + "/api/v1/products/ncms", ncm);

  const updateNcmCategory = (updateNcm) =>
    http.put(baseUrl + "/api/v1/products/ncm-add-category", updateNcm);

  const googleSearch = (ean) =>
    http.get(baseUrl + "/api/v1/products/googleSearch/" + ean);

  const collisionCheck = (ean) =>
    http.get(baseUrl + "/api/v1/products/collisionCheck/" + ean);

  const getEansList = (desc) =>
    http.get("https://product.prod.grgi9.com/v1/grouped-products/" + desc);

  const getCommomValues = (commonValues) =>
    http.get(
      baseUrl +
        "/api/v1/products/common-values?ean=" +
        commonValues.ean +
        "&category=" +
        commonValues.categoryId +
        "&qtd=" +
        commonValues.quantity
    );

  const updateProduct = (product) =>
    http.put(baseUrl + "/api/v1/products", product);

  const insertProducts = (eanList) =>
    http.post(baseUrl + "/api/v1/products", eanList);

  return {
    getProductPage,
    searchProduct,
    getProductByEan,
    getAllCategories,
    getCategoriesByDescription,
    getCategory,
    getComercialReferencesByEan,
    getAllNCMS,
    getNCMS,
    sendNCMRequest,
    saveNcm,
    updateNcmCategory,
    googleSearch,
    collisionCheck,
    getEansList,
    getCommomValues,
    updateProduct,
    insertProducts,
  };
}

export default useProduct;
