import React from "react";
import Button from "../components/Button";
import Card from "../components/Card";
import Loading from "../components/Loading";
import { Title } from "../components/Titles";
import Search from "../components/plans/client";
import ClientListPlans from "../components/plans/clientsList";
import useClientCompany from "../hooks/useClientCompany";
import usePlans from "../hooks/usePlans";

export default function PlansPage() {
  const [client, setClient] = React.useState({ id: null, name: null });
  const [clientUser, setClientUser] = React.useState(null);
  const [listClientUser, setListClientUser] = React.useState([]);
  const [plans, setPlans] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { getClients, getPlans, cancelPlan: cancelPlanHook } = usePlans();
  const { getAllCompanyUsersById } = useClientCompany();

  const goToEdit = () => {
    window.location.href = `/plans-details/${client?.id}/${clientUser}/add/${client?.name}`;
  };

  const handleSearch = (seachClient) => {
    if (!seachClient?.id || !seachClient?.name) return;
    setLoading(true);
    if (seachClient?.id !== client?.id) {
      setClientUser(null);
      setListClientUser([]);
      setClient(seachClient);
      getPlans(seachClient.id).then((data) => setPlans(data));
      getAllCompanyUsersById(seachClient.id).then((response) => {
        setListClientUser(response.data);
      });
    }
    setLoading(false);
  };

  const handleSearchUser = (userId) => {
    if (userId === "null") return setClientUser(null);
    setClientUser(userId);
  };

  const cancelPlan = async (userId, plan) => {
    if (
      window.confirm(
        "Cancelar assinatura " + plan.id + " do plano: " + plan.plan + " ?"
      )
    ) {
      setLoading(true);
      await cancelPlanHook(userId, plan);
      setLoading(false);
    }
  };

  return !loading ? (
    <>
      <Title>Gerenciamento de Assinaturas</Title>
      <Search
        client={client}
        clientUser={clientUser}
        listClientUser={listClientUser}
        listClient={getClients}
        handleChange={handleSearch}
        handleChangeUser={handleSearchUser}
      />

      {!clientUser ? null : (
        <Button type="button" layout="info" onClick={() => goToEdit()}>
          Criar Assinatura
        </Button>
      )}

      <ClientListPlans
        plans={plans}
        client={client}
        userId={clientUser}
        cancelPlan={cancelPlan}
      />
    </>
  ) : (
    <Card>
      <Loading style={{ margin: "20px auto" }} />
    </Card>
  );
}
