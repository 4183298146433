import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult } from "../../components/form/AsyncSelect";

export default function useFetchProductOptions() {
  const fetchProductBrandOpts = (filter: string, offset: number | undefined) =>
    baseFetchProductBrandOpts(filter, offset);

  const fetchManufacturerOpts = (filter: string, offset: number | undefined) =>
    baseFetchManufacturerOpts(filter, offset);

  return { fetchProductBrandOpts, fetchManufacturerOpts };
}

interface Response {
  result: Array<{ value: string; description: string }>;
}

async function baseFetchProductBrandOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/product/brand/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as Response;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

async function baseFetchManufacturerOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/manufacturer/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as Response;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}
