import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import AsyncSelect from "../components/AsyncSelect";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import { Col, Row } from "../components/Grid";
import Input from "../components/Input";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import { UNITOFMEASUREMENT, mediaPrefix, menus } from "../constants";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import useProduct from "../hooks/useProduct";
import {
  isEmptyObj,
  isNotEmpty,
  isNotEmptyList,
  makeValidator,
} from "../utils";
import { authCredentials } from "../utils/authCredentials";

const Separator = styled.div`
  padding: 2px 0;
  font-size: 12px;
  border-bottom: solid 1px lightgray;
  margin-bottom: 15px;
`;

const BrandImage = styled.img`
  height: 181px;
  width: 100%;
  background-color: #e6e7e8;
  object-fit: contain;
  border: solid 1px lightgray;
`;

const ToggleButton = styled.label`
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 15px;
    height: 25px;
  }

  /* Hide default HTML checkbox */
  &.switch .checkbox {
    opacity: 0;
    width: 0px;
    height: 0px;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    height: 15px;
    width: 30px;
    background-color: rgb(153, 21, 43);
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    margin-top: 2px;
    margin-left: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: rgb(153, 21, 43);
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
  }
`;

const HoverBox = styled.div`
  .box {
    position: relative;
    z-index: 2;
  }

  &:hover .inBox {
    display: block;
  }

  .inBox {
    display: none;
    position: absolute;
    top: 10%;
    right: 15%;
    width: 250px;
    background-color: rgb(153, 21, 43);
    color: #fff;
    border: 1px solid white;
    z-index: 10;
  }
`;

const ContainerSelect = styled.div`
  padding-bottom: 15px;
`;
const ContainerTag = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function containTerms(text) {
  return text.match(
    /(gtin *:*-* *[0-9]+|ncm *:*-* *([0-9]{8}|[0-9]{4}\.[0-9]{2}\.[0-9]{2})|marca *:*-* *[a-z0-9]+)/gi
  );
}

function CreateNcm(props) {
  const {
    newProduct,
    setNewProduct,
    setNCMList,
    NCMList,
    categories,
    setBlockUpdate,
    setCollapsedNcmWarning,
    setCollapsedCategoryWarning,
  } = props;
  const { closeModal } = useModal();
  const { saveNcm } = useProduct();
  const [newNCM, setNewNCM] = useState({ ncm: newProduct.ncm, catProdId: [] });

  const onSaveNcm = async (nemNCM) => {
    try {
      const responseNcm = await saveNcm(newNCM);
      if (responseNcm.status === 200) {
        setBlockUpdate(false);
        setCollapsedNcmWarning(true);
        if (!newNCM.catProdId.includes(newProduct.categoryId)) {
          setBlockUpdate(true);
          setCollapsedCategoryWarning(false);
          setNewProduct({ ...newProduct, categoryId: null });
        }
        newNCM.catProdId = newNCM.catProdId.join();
        setNCMList([...NCMList, newNCM]);
        closeModal();
      }
    } catch (e) {
      throw e;
    }
  };

  const ncmValidator = makeValidator({
    ncm: isNotEmpty("NCM"),
    description: isNotEmpty("Descrição"),
    catProdId: isNotEmptyList("Categorias Relacionadas"),
  });
  const { getNcmErrors, getValueErrors, getCatProdIdErrors, getAllErrors } =
    ncmValidator;

  const fetchNCMByDescription = useCallback(
    async (ncm) => {
      if (ncm == null || "") return null;
      var changeKeys = NCMList.filter((i) => i.ncm.includes(ncm));
      var newNCMList = [];
      for (var i = 0; i < changeKeys.length; i++) {
        newNCMList.push({ id: changeKeys[i].ncm, name: changeKeys[i].ncm });
      }
      return newNCMList;
    },
    [NCMList]
  );

  const fetchNCM = useCallback(
    async (ncm) => {
      return {
        id: NCMList.filter((i) => i.ncm === ncm)[0].ncm,
        name: NCMList.filter((i) => i.ncm === ncm)[0].ncm,
      };
    },
    [NCMList]
  );

  const fetchCategoriesByDescription = useCallback(
    async (desc) => {
      if (desc == null || "") return null;
      var regexp = new RegExp(desc, "i");
      return categories.filter((i) => regexp.test(i.name));
    },
    [categories]
  );

  const fetchCategory = useCallback(
    async (catId) => {
      return categories.filter((i) => i.id === catId)[0];
    },
    [categories]
  );

  const removeCat = (cat) => {
    var newList = [...newNCM.catProdId].filter((i) => i !== cat);
    setNewNCM({ ...newNCM, catProdId: newList });
  };

  return (
    <>
      {!(NCMList && categories) ? null : (
        <>
          <SubTitle>Cadastrar novo NCM</SubTitle>
          <div>
            <Input
              label="NCM"
              value={newNCM.ncm}
              readOnly
              validate={getNcmErrors}
            />
            <Input
              label="Descrição*"
              placeholder="Descrição"
              value={newNCM.description}
              onChange={(e) =>
                setNewNCM({ ...newNCM, description: e.target.value })
              }
              validate={getValueErrors}
            />
            <AsyncSelect
              label="NCM Mãe"
              value={newNCM.parentId}
              placeholder="Selecione..."
              fetchData={fetchNCMByDescription}
              fetchItem={fetchNCM}
              onChange={(parentId) => setNewNCM({ ...newNCM, parentId })}
            />
            <ContainerSelect>
              <AsyncSelect
                label={"Categorias Relacionadas*"}
                placeholder="Selecione..."
                fetchData={fetchCategoriesByDescription}
                fetchItem={fetchCategory}
                onChange={(categoryId) =>
                  setNewNCM({
                    ...newNCM,
                    catProdId: [...newNCM.catProdId, categoryId],
                  })
                }
                validate={getCatProdIdErrors}
              />
              <ContainerTag>
                {(newNCM.catProdId || []).map((cat, idx) => {
                  return (
                    <>
                      <div style={{ padding: "3px 0" }}>
                        <Button
                          type="button"
                          layout="error"
                          small
                          onClick={() => {
                            removeCat(cat);
                          }}
                        >
                          <i className="fa fa-times" />
                        </Button>
                        <div
                          style={{
                            verticalAlign: "middle",
                            display: "inline-block",
                            padding: "0 3px",
                            fontSize: "12px",
                          }}
                        >
                          {cat}
                        </div>
                      </div>
                    </>
                  );
                })}
              </ContainerTag>
            </ContainerSelect>
            <br />
            <hr />
            <Button
              layout="confirm"
              onClick={() => {
                onSaveNcm(newNCM);
              }}
              disabled={getAllErrors(newNCM)}
            >
              Salvar
            </Button>
          </div>
        </>
      )}
    </>
  );
}

function UpdateNcmCategories(props) {
  const {
    ncm,
    categories,
    ncmCategories,
    setNcmCategories,
    newProduct,
    setNewProduct,
  } = props;
  const { updateNcmCategory } = useProduct();
  const { closeModal } = useModal();
  const [updateNCM, setUpdateNCM] = useState({ catProdId: [] });

  const updateNcmCategoriesValidator = makeValidator({
    catProdId: isNotEmpty("Categoria"),
  });
  const { getCatProdIdErros, getAllErrors } = updateNcmCategoriesValidator;

  const authUser = authCredentials.get.authUser();

  const onUpdateNcmCategory = useCallback(async (updateNCM) => {
    updateNCM.ncm = ncm;
    try {
      const response = await updateNcmCategory(updateNCM);
      setNcmCategories([
        categories.filter((i) => i.id === updateNCM.catProdId[0])[0],
        ...ncmCategories,
      ]);
      closeModal();
    } catch (e) {
      throw e;
    }
  });

  const onOtherNcmCategory = useCallback(async (updateNCM) => {
    setNewProduct({
      ...newProduct,
      categoryId: updateNCM.catProdId[0],
      categoryRequest: true,
    });
    setNcmCategories([
      categories.filter((i) => i.id === updateNCM.catProdId[0])[0],
      ...ncmCategories,
    ]);
    closeModal();
  });

  const fetchCategoriesByDescription = useCallback(
    async (desc) => {
      if (desc == null || "") return null;
      var regexp = new RegExp(desc, "i");
      return categories.filter((i) => regexp.test(i.name));
    },
    [categories]
  );

  const fetchCategory = useCallback(
    async (catId) => {
      return categories.filter((i) => i.id === catId)[0];
    },
    [categories]
  );

  return (
    <div>
      <SubTitle>Adicionar Categoria ao NCM</SubTitle>
      <AsyncSelect
        Label="Categoria*"
        placeholder="Selecione..."
        value={updateNCM.catProdId[0]}
        fetchData={fetchCategoriesByDescription}
        fetchItem={fetchCategory}
        onChange={(catProdId) =>
          setUpdateNCM({ ...updateNCM, catProdId: [catProdId] })
        }
        validate={getCatProdIdErros}
      />
      <Button
        type="button"
        layout="confirm"
        onClick={(e) =>
          authUser.role !== "ADMIN"
            ? onUpdateNcmCategory(updateNCM)
            : onOtherNcmCategory(updateNCM)
        }
        disabled={getAllErrors(updateNCM)}
      >
        Salvar
      </Button>
    </div>
  );
}

function ProductPage() {
  const {
    getComercialReferencesByEan,
    getProductByEan,
    getAllNCMS,
    getNCMS,
    getAllCategories,
    getCategoriesByDescription,
    getCategory,
    updateProduct: updateProductRequest,
    googleSearch,
    getEansList,
    getCommomValues,
  } = useProduct();
  const { createModal, closeModal } = useModal();
  const { ean: pageEan, ncm: pageNcm } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { createEvent } = useHorusAccess();
  const [productData, setProductData] = useState({ ncm: "", ean: "" });
  const [newProduct, setNewProduct] = useState({});
  const [commonValues, setCommonValues] = useState(null);
  const [categories, setCategories] = useState(null);
  const [ncmCategories, setNcmCategories] = useState(null);
  const [NCMs, setNCMs] = useState(null);
  const [NCMList, setNCMList] = useState(null);
  const [comercialRefs, setComercialRefs] = useState(null);
  const [googleSearchRefs, setGoogleSearchRefs] = useState(null);
  const [googleSearchParams, setGoogleSearchParams] = useState(null);
  const [eansList, setEansList] = useState([]);
  const [checkedEansList, setCheckedEansList] = useState([]);
  const [blockUpdate, setBlockUpdate] = useState(false);
  const [collapsedNcmWarning, setCollapsedNcmWarning] = useState(true);
  const [collapsedCategoryWarning, setCollapsedCategoryWarning] =
    useState(true);
  const [collapsedFirst, setCollapsedFirst] = useState(false);
  const [collapsedSecond, setCollapsedSecond] = useState(true);
  const [collapsedThird, setCollapsedThird] = useState(true);
  const breadcrumb = [
    menus.home,
    menus.product,
    { name: "Atualizar Produto", path: location.pathname },
  ];

  const authUser = authCredentials.get.authUser();

  const instance = useRef({
    currentPage: 1,
    multiplier: 1,
  });

  const newNCMWarning = (prod) => {
    createModal(
      <div>
        <p>Solicitar a criação do NCM: {prod.ncm}</p>
        <hr />
        <Button
          layout="confirm"
          type="button"
          disabled={newProduct.ncm.length !== 8}
          onClick={(e) => {
            onSendNCMRequest(prod.ncm);
          }}
        >
          Solicitar novo NCM
        </Button>
      </div>
    );
  };

  const onSendNCMRequest = () => {
    closeModal();
    setBlockUpdate(false);
    setCollapsedNcmWarning(true);
    setNewProduct({ ...newProduct, ncmRequest: true });
  };

  const createNcmModal = () => {
    createModal(
      <CreateNcm
        newProduct={newProduct}
        setNewProduct={setNewProduct}
        getNCMS={getNCMS}
        setNCMList={setNCMList}
        NCMList={NCMList}
        categories={categories}
        setBlockUpdate={setBlockUpdate}
        setCollapsedNcmWarning={setCollapsedNcmWarning}
        setCollapsedCategoryWarning={setCollapsedCategoryWarning}
      />
    );
  };

  const updateNcmCategoryModal = () => {
    createModal(
      <UpdateNcmCategories
        userAuth={authUser}
        ncm={newProduct.ncm}
        categories={categories}
        ncmCategories={ncmCategories}
        setNcmCategories={setNcmCategories}
        newProduct={newProduct}
        setNewProduct={setNewProduct}
      />
    );
  };

  const fetchProduct = useCallback(
    async (ean) => {
      try {
        const responseProduct = await getProductByEan(ean);
        setGoogleSearchParams(ean);
        setProductData(responseProduct.data);
        if (pageNcm) responseProduct.data.ncm = pageNcm;
        setNewProduct(responseProduct.data);
      } catch (error) {
        createModal("Erro ao recuperar produto");
      }
    },
    [createModal, pageNcm, getProductByEan]
  );

  const fetchGoogleSearchReferences = useCallback(
    async (ean) => {
      const response = await googleSearch(ean);
      setGoogleSearchRefs(response.data.items);
    },
    [googleSearch]
  );

  const fetchComercialReferences = useCallback(
    async (ean) => {
      const responseComRef = await getComercialReferencesByEan(ean);
      setComercialRefs(responseComRef.data);
    },
    [getComercialReferencesByEan]
  );

  const fetchEansList = useCallback(
    async (description) => {
      const responseEansList = await getEansList(description);
      var newList = responseEansList.data.filter(
        (i) => !checkedEansList.includes(i)
      );
      setEansList([...newList, ...checkedEansList]);
    },
    [checkedEansList, getEansList, getComercialReferencesByEan]
  );

  const fetchAllNCMs = useCallback(async () => {
    const response = await getAllNCMS();
    return setNCMList(response.data);
  }, [getAllNCMS]);

  const fetchNCMS = useCallback(
    async (ncm) => {
      newProduct.ncmRequest = false;
      newProduct.categoryRequest = false;

      if (!NCMList || !categories) return;

      if (ncm && ncm.length > 0) {
        var changeKeys = NCMList.filter((i) => i.ncm.includes(ncm));
        var newNCMList = [];
        for (let i = 0; i < changeKeys.length; i++) {
          newNCMList.push({
            id: changeKeys[i].ncm,
            name: changeKeys[i].ncm,
            catProdId: changeKeys[i].catProdId,
          });
        }
        setNCMs(newNCMList);

        if (newNCMList.length > 0 && ncm.length === 8) {
          var categoriesList = [];
          if ("catProdId" in newNCMList[0] && newNCMList[0].catProdId) {
            for (let j = 0; j < newNCMList[0].catProdId.length; j++) {
              categoriesList.push(
                categories.filter(
                  (cat) => cat.id === parseInt(newNCMList[0].catProdId[j])
                )[0]
              );
            }
          }
          if (
            categoriesList.filter((k) =>
              k !== undefined ? k.id === newProduct.categoryId : false
            ).length < 1
          )
            setNewProduct((prev) => ({ ...prev, categoryId: null }));
          if (!(categoriesList[0] === undefined))
            setNcmCategories(categoriesList);
          setCollapsedNcmWarning(true);
          setBlockUpdate(false);
          return;
        }

        setNcmCategories([]);
        setBlockUpdate(true);
        setCollapsedNcmWarning(false);
        return;
      }

      setNcmCategories([]);
      setCollapsedNcmWarning(true);
      setBlockUpdate(false);
    },
    [NCMList, categories]
  );

  const fetchAllCategories = useCallback(async () => {
    const responseAllCat = await getAllCategories();
    return setCategories(responseAllCat.data);
  }, [getAllCategories]);

  const fetchCategoriesByDescription = useCallback(
    async (desc) => {
      const responseCat = await getCategoriesByDescription(
        newProduct.ncm || "null",
        desc
      );

      return responseCat.data.map((i) => ({ id: i.id, name: i.name }));
    },
    [newProduct.ncm, getCategoriesByDescription]
  );

  const fetchCategory = useCallback(
    async (catid) => {
      let cat;
      try {
        const response = await getCategory(catid);
        cat = response.data;
      } catch (e) {
        throw e;
      }
      return { id: cat.id, name: cat.name };
    },
    [getCategory]
  );

  const calculateQuantity = useCallback(async (value) => {
    const response = await getCommomValues(newProduct);
    setCommonValues(response.data);
    var oldMultiplier = instance.current.multiplier;
    if (isNaN(value) && newProduct.quantity) {
      var baseValue = productData.quantity;
      var unit = UNITOFMEASUREMENT.filter((i) => i.id === value)[0];
      instance.current.multiplier = unit.multiplier;
      setProductData({
        ...productData,
        quantity: ((baseValue * unit.multiplier) / oldMultiplier).toFixed(6),
        unitOfMeasurement: value,
      });
      setNewProduct({ ...newProduct, unitOfMeasurement: unit.type });
      return;
    }
    setProductData({ ...productData, quantity: value });
    setNewProduct({
      ...newProduct,
      quantity: ((value * 1) / oldMultiplier).toFixed(6),
    });
  });

  // # LISTENERS
  const handleOnBackwardClick = () => {
    if (collapsedFirst) {
      instance.current.currentPage--;
      if (instance.current.currentPage === 1) {
        setCollapsedFirst(false);
        setCollapsedSecond(true);
        setCollapsedThird(true);
      } else if (instance.current.currentPage === 2) {
        setCollapsedFirst(true);
        setCollapsedSecond(false);
        setCollapsedThird(true);
      }
    }
  };

  const handleOnForwardClick = () => {
    if (collapsedThird) {
      instance.current.currentPage++;
      if (instance.current.currentPage === 2) {
        setCollapsedFirst(true);
        setCollapsedSecond(false);
        setCollapsedThird(true);
      } else if (instance.current.currentPage === 3) {
        setCollapsedFirst(true);
        setCollapsedSecond(true);
        setCollapsedThird(false);
      }
    }
  };

  const editNewProduct = (ean) => {
    closeModal();
    if (ean === "") {
      history.push(menus.product.path + location.search);
    }
    history.push("/product/" + ean);
  };

  const handleOnChangeEan = async (e) => {
    var ean = e.target.value;
    setNewProduct({ ...newProduct, ean: ean });

    if (ean !== productData.ean) {
      try {
        const responseProduct = await getProductByEan(ean);
        if (responseProduct.data) {
          createModal(
            <div>
              <p>
                O EAN: {responseProduct.data.ean} entra em conflito com o
                produto: {responseProduct.data.description}.
              </p>
              <p>
                Deseja editar o produto: {responseProduct.data.description} ?
              </p>
              <Button
                type="button"
                style={{ marginTop: "25px", float: "right" }}
                layout="info"
                onClick={(e) => editNewProduct(responseProduct.data.ean)}
              >
                Editar
              </Button>
            </div>
          );
          setBlockUpdate(true);
          return;
        }
      } catch (error) {
        createModal("Erro ao verificar ean");
      }
    }
    setBlockUpdate(false);
  };

  // # EFFECTS
  // Log access event
  useEffect(() => {
    let event = {};
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access product moderation";

    createEvent(event);
  }, []);

  // Loads the product when there is ean path param
  useEffect(() => {
    if (!pageEan) return;
    fetchProduct(pageEan);
  }, [pageEan, fetchProduct]);

  // Loads categories based on ncm
  useEffect(() => {
    if (!productData || isEmptyObj(productData) || !NCMList) return;
    const ncmTimer = setTimeout(() => {
      fetchNCMS(newProduct.ncm);
    }, 1500);
    return () => clearTimeout(ncmTimer);
  }, [productData, newProduct.ncm, fetchNCMS, NCMList]);

  // Loads all catgories
  useEffect(() => {
    fetchAllCategories();
  }, [fetchAllCategories]);

  // Loads all NCMs
  useEffect(() => {
    fetchAllNCMs();
  }, [fetchAllNCMs]);

  // fetch comercial references
  useEffect(() => {
    if (!newProduct || isEmptyObj(newProduct)) return;
    const comRefTimer = setTimeout(() => {
      fetchComercialReferences(newProduct.ean);
    }, 1500);
    return () => clearTimeout(comRefTimer);
  }, [newProduct.ean, fetchComercialReferences]);

  // Fetch comercial reference using Google
  useEffect(() => {
    if (!newProduct || isEmptyObj(newProduct)) return;
    const googleRefTimer = setTimeout(() => {
      fetchGoogleSearchReferences(productData.ean);
    }, 1500);
    return () => clearTimeout(googleRefTimer);
  }, [newProduct.ean, fetchGoogleSearchReferences]);

  // Load list of eans of the same product
  useEffect(() => {
    if (!newProduct || isEmptyObj(newProduct)) return;
    const eansListTimer = setTimeout(() => {
      fetchEansList(newProduct.description);
    }, 1500);
    return () => clearTimeout(eansListTimer);
  }, [newProduct.description, fetchEansList]);

  // # HTML
  return (
    <Template breadcrumb={breadcrumb}>
      <Title>Atualizar Produto</Title>
      {isEmptyObj(productData) || isEmptyObj(newProduct) ? null : (
        <>
          <Card>
            <Collapse collapsed={collapsedFirst}>
              <Row>
                <Col width="60%">
                  <Separator>Campos de edição</Separator>
                  <Col width="20%">
                    <Input
                      label={"EAN inicial: " + productData.ean}
                      value={newProduct.ean}
                      onChange={(e) => handleOnChangeEan(e)}
                    />
                    {eansList.length === 0 ? null : (
                      <div style={{ fontSize: "12px", marginTop: "-15px" }}>
                        {eansList.map((ean, index) => {
                          return (
                            <div key={index}>
                              <input
                                className="eansList"
                                type="checkbox"
                                id={index}
                                value={ean}
                                checked={checkedEansList.includes(ean)}
                                onChange={(e) => {
                                  setCheckedEansList((prev) =>
                                    e.target.checked
                                      ? [...prev, ean]
                                      : prev.filter((i) => i !== ean)
                                  );
                                }}
                              />
                              <label htmlFor={index}>{ean}</label>
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <br />
                    {!NCMList ? null : (
                      <div>
                        <datalist id="ncmList">
                          {!NCMs
                            ? null
                            : NCMs.map((ncm, index) => {
                                return (
                                  <option value={ncm.id} key={index}></option>
                                );
                              })}
                        </datalist>
                        <Input
                          list="ncmList"
                          label={"Ncm inicial: " + productData.ncm}
                          value={newProduct.ncm}
                          onChange={(e) => {
                            setNewProduct({
                              ...newProduct,
                              ncm: e.target.value,
                            });
                          }}
                        />
                        {newProduct.ncm == null ||
                        newProduct.ncm === "" ? null : (
                          <Collapse collapsed={collapsedNcmWarning}>
                            <div style={{ color: "red", fontSize: "12px" }}>
                              <p>NCM não encontrado no banco de dados.</p>
                              {newProduct.ncm.length !== 8 ? (
                                <p>NCM é composto por 8 digitos</p>
                              ) : authUser.role !== "ADMIN" ? (
                                <div>
                                  <p>
                                    Para informar sobre o novo NCM e prosseguir
                                    com a moderação clique em 'Solicitar NCM'
                                  </p>
                                  <button
                                    disabled={newProduct.ncm.length !== 8}
                                    type="button"
                                    onClick={(e) => newNCMWarning(newProduct)}
                                  >
                                    Solicitar NCM
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <p>
                                    Caso NCM procurado não seja encontrado
                                    clicar em 'Salvar NCM'
                                  </p>
                                  <button
                                    disabled={newProduct.ncm.length !== 8}
                                    type="button"
                                    onClick={(e) => createNcmModal()}
                                  >
                                    Salvar NCM
                                  </button>
                                </div>
                              )}
                            </div>
                          </Collapse>
                        )}
                      </div>
                    )}
                  </Col>
                  <Col width="80%">
                    <Input
                      label={"Descrição inicial: " + productData.description}
                      value={newProduct.description}
                      onChange={(e) =>
                        setNewProduct({
                          ...newProduct,
                          description: e.target.value,
                        })
                      }
                    />
                    <Col width="90%" style={{ marginLeft: "-10px" }}>
                      {!categories ? null : !newProduct.ncm ||
                        newProduct.ncm === "" ||
                        newProduct.ncm.length !== 8 ? (
                        <AsyncSelect
                          label={"Categoria inicial: " + productData.category}
                          value={newProduct.categoryId}
                          fetchData={fetchCategoriesByDescription}
                          fetchItem={fetchCategory}
                          onChange={(categoryId) =>
                            setNewProduct({
                              ...newProduct,
                              categoryId: categoryId,
                            })
                          }
                        />
                      ) : (
                        <Input
                          label={"Categoria inicial: " + productData.category}
                          value={newProduct.categoryId}
                          onChange={(e) =>
                            setNewProduct({
                              ...newProduct,
                              categoryId: e.target.value,
                            })
                          }
                          options={(ncmCategories || []).map((cat) => {
                            return cat;
                          })}
                        />
                      )}
                    </Col>
                    <Col width="10%">
                      <Button
                        type="button"
                        layout="info"
                        style={{ marginTop: "5px", height: "45px" }}
                        onClick={(e) => updateNcmCategoryModal()}
                      >
                        Outras categorias
                      </Button>
                    </Col>
                    <Col width="20%" style={{ marginLeft: "-10px" }}>
                      <Input
                        label="Unidade de Medida"
                        value={productData.unitOfMeasurement}
                        placeholder="Selecione..."
                        onChange={(e) => {
                          calculateQuantity(e.target.value);
                        }}
                        options={
                          authUser.role === "ADMIN"
                            ? UNITOFMEASUREMENT
                            : UNITOFMEASUREMENT.filter(
                                (i) => i.type === newProduct.unitOfMeasurement
                              )
                        }
                      />
                      {!commonValues ||
                      commonValues.unitOfMeasurement ===
                        newProduct.unitOfMeasurement ? null : (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Unidade de volume para a categoria deveria ser{" "}
                          {commonValues.unitOfMeasurement}
                        </span>
                      )}
                    </Col>
                    <Col width="20%">
                      <Input
                        label="Quantidade"
                        value={productData.quantity}
                        placeholder="0.00"
                        onChange={(e) => {
                          calculateQuantity(e.target.value);
                        }}
                      />
                      {!commonValues ? null : (
                        <span style={{ color: "red", fontSize: "12px" }}>
                          Quantidade sugerida {commonValues.suggestedQuantity}
                          {commonValues.unitOfMeasurement}
                        </span>
                      )}
                    </Col>
                  </Col>
                </Col>
                <Col width="20%">
                  <Separator>
                    <a
                      href={
                        "https://www.google.com/search?q=" + googleSearchParams
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      Busca Google
                    </a>
                    <span style={{ marginLeft: "20px" }}>
                      <span>EAN</span>
                      <ToggleButton className="switch">
                        <input
                          className="checkbox"
                          type="checkbox"
                          onChange={(e) => {
                            setGoogleSearchParams(() =>
                              e.target.checked
                                ? newProduct.description
                                : newProduct.ean
                            );
                          }}
                        />
                        <span className="slider"></span>
                        <span style={{ marginLeft: "35px" }}>Descrição</span>
                      </ToggleButton>
                    </span>
                  </Separator>
                  <Card style={{ fontSize: "12px" }}>
                    {!googleSearchRefs
                      ? null
                      : googleSearchRefs.map((item, index) => {
                          return (
                            <HoverBox className="box" key={index}>
                              <p>
                                <a
                                  href={item.link}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  {item.title}
                                </a>
                              </p>
                              <HoverBox className="inBox">
                                {!(
                                  "snippet" in item &&
                                  "pagemap" in item &&
                                  "metatags" in item.pagemap &&
                                  "og:description" in item.pagemap.metatags[0]
                                )
                                  ? null
                                  : !containTerms(
                                      item.pagemap.metatags[0]["og:description"]
                                    )
                                  ? null
                                  : containTerms(
                                      item.snippet +
                                        " " +
                                        item.pagemap.metatags[0][
                                          "og:description"
                                        ]
                                    ).map((term, index) => {
                                      return <p key={index}>{term}</p>;
                                    })}
                                {!(
                                  "pagemap" in item &&
                                  "cse_image" in item.pagemap &&
                                  item.pagemap.cse_image.length > 0
                                ) ? null : (
                                  <img
                                    src={item.pagemap.cse_image[0].src}
                                    width="250"
                                    height="250"
                                  />
                                )}
                              </HoverBox>
                            </HoverBox>
                          );
                        })}
                  </Card>
                </Col>
                <Col width="20%">
                  <Separator>Referências Comerciais</Separator>
                  <Card style={{ fontSize: "12px" }}>
                    {!comercialRefs
                      ? null
                      : comercialRefs.map((comRef, index) => {
                          return <p key={index}>{comRef.description}</p>;
                        })}
                  </Card>
                </Col>
              </Row>
            </Collapse>
            <Collapse collapsed={collapsedSecond}>
              ************************* A PARTIR DAQUI, NADA MAIS É VÁLIDO ERA
              SÓ PARA ADIANTAR *********************************
              <Row>
                <Col width="20%">
                  {productData.file ? (
                    <BrandImage src={mediaPrefix + productData.file} />
                  ) : (
                    <BrandImage src="/static/placeholder.png" />
                  )}
                  <a
                    href={
                      "https://www.google.com/search?q=" +
                      productData.description +
                      "&tbm=isch"
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    Buscar imagem
                  </a>
                </Col>
                <Col width="80%"></Col>
              </Row>
            </Collapse>
            <Collapse collapsed={collapsedThird}>
              <Row>
                <Input
                  label="Tag descrição base"
                  value={newProduct.tagBaseDescription}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      description: e.target.value,
                    })
                  }
                  validate={newProduct.tagBaseDescription}
                />
                <Input
                  label="Tag Sabor/Essência/Cor"
                  value={newProduct.tagSEC}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      description: e.target.value,
                    })
                  }
                  validate={newProduct.tagSEC}
                />
                <Input
                  label="Tag Especificidade"
                  value={newProduct.tagSpecificity}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      description: e.target.value,
                    })
                  }
                />
                <Input
                  label="Tag Embalagem"
                  value={newProduct.tagPackage}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      description: e.target.value,
                    })
                  }
                />
                <Input
                  label="Tag Linha"
                  value={newProduct.tagLine}
                  onChange={(e) =>
                    setNewProduct({
                      ...newProduct,
                      description: e.target.value,
                    })
                  }
                />
              </Row>
            </Collapse>
            <Separator>Ações</Separator>
            {!collapsedThird ? null : (
              <Button
                disabled={blockUpdate || !newProduct.categoryId}
                style={{ float: "right" }}
                onClick={handleOnForwardClick}
              >
                Avançar
              </Button>
            )}
            {!collapsedFirst ? null : (
              <Button
                disabled={blockUpdate}
                style={{ float: "right" }}
                onClick={handleOnBackwardClick}
              >
                Anterior
              </Button>
            )}
          </Card>
        </>
      )}
    </Template>
  );
}

export default ProductPage;
