import useUserCreatePage from "./useUserCreatePage";

import Button from "../../form/Button";
import { HSpacer } from "../../ui/HSpacer";
import { SectionTitle } from "../../ui/SectionTitle";

type Props = ReturnType<typeof useUserCreatePage>;

export default function EditUserHeader(props: Props) {
  const {
    selectedEditUser,
    enabled,
    sendingUser,
    handleToggleUserActivationOnClick,
    sendingWelcomeEmail,
    handleOnSendWelcomeEmailClick,
  } = props;

  if (!selectedEditUser) return <></>;

  return (
    <div style={{ marginBottom: "1em" }}>
      <SectionTitle
        title="Ações Rápidas"
        description="Realizar determinadas ações com apenas um click."
      />

      {enabled ? null : <div>Este usuário está desativado</div>}

      <Button
        type={enabled ? "cancel" : "confirm"}
        disabled={sendingUser}
        onClick={handleToggleUserActivationOnClick}
      >
        {sendingUser
          ? "Carregando..."
          : enabled
          ? "Desativar usuário"
          : "Ativar usuário"}
      </Button>

      <HSpacer />

      <Button
        type="info"
        disabled={sendingWelcomeEmail}
        onClick={handleOnSendWelcomeEmailClick}
      >
        {sendingWelcomeEmail
          ? "Carregando..."
          : "Reenviar Email de Boas Vindas"}
      </Button>

      <br />
      <br />

      <SectionTitle
        title="Campos de Edição"
        description="Preencha os campos, atentamente, para editar um usuário."
      />
    </div>
  );
}
