import { baseUrl } from "../constants";

function usePlan() {
  const getAllPlans = () => http.get(baseUrl + "/v1/plan");
  const getAllPlanServices = (planId) =>
    http.get(baseUrl + "/v1/plan-service/" + planId);
  const getServiceAttributes = (ServiceId) =>
    http.get(baseUrl + "/v1/service-attribute/" + ServiceId);

  return { getAllPlans, getAllPlanServices, getServiceAttributes };
}

export default usePlan;
