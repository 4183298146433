
export default function Spacer(props: { ratio?: number }) {

  const { ratio = 1 } = props;

  const value = 15 * ratio;

  return (
    <div style={{ height: value + "px" }} />
  )
}