import Button from "../../form/Button";
import Input from "../../form/Input";
import styles from "../../ui/ui.module.css";
import Pagination from "../../ui/Pagination";
import {SectionTitle} from "../../ui/SectionTitle";
import usePartnerListPage from "./usePartnerListPage";

export default function PartnerListPage() {

  const {
    handleFindOnSubmit, handleOnChange, handlePageOnChange, filter, items, totalPages, page, handleEditOnClick, handleResetPasswordOnClick, confirmInactivatePartner, confirmActivatePartner, handleAddFilterClick, handleAddWebhookClick
  } = usePartnerListPage();

  return (
    <div>
      <SectionTitle title="Listagem de Parceiro" description="Consulta de parceiros cadastrados na plataforma." />
      
      <form onSubmit={ handleFindOnSubmit }>
        <Input
          onChange={ handleOnChange }
          value={ filter }
          name="filter"
          label="Filtre parceiro pelo email ou nome"
          addons={
            [ <Button key="1" type="info" submit>Pesquisar</Button> ]
          }
        />
      </form>
      <table className={ styles.table }>
        <thead>
          <tr>
            <th>Ações</th>
            <th>Nome</th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>
          {
            !items ? (
              <tr><td colSpan={ 7 }>Carregando....</td></tr>
            ) : (
              items.length === 0 ? (
                <tr><td colSpan={ 7 }>Sem resultados</td></tr>
              ) : (
                items.map(item => (
                  <tr key={ item.id }>
                    <td className={ `${ styles.left }` }>
                      <Button small type="warning" onClick={ () => handleEditOnClick(item.id) } transparent>
                        Editar
                      </Button>{' '}
                      <Button small type="warning" onClick={ () => handleResetPasswordOnClick(item.id, item.name) } transparent>
                        Resetar Senha
                      </Button>{' '}
                      <Button small type="confirm" onClick={ () => handleAddFilterClick( item.name, item.username ) } transparent>
                          Filtros
                      </Button>{' '}
                      <Button small type="confirm" onClick={ () => handleAddWebhookClick( item.name, item.username ) } transparent>
                          Webhook
                      </Button>{' '}
                      {
                          item.active ? (
                              <Button small type="cancel" onClick={ () => confirmInactivatePartner(item.id) } transparent>
                                  Desativar
                              </Button>
                          ) : (
                              <Button small type="cancel" onClick={ () => confirmActivatePartner(item.id) } transparent>
                                  Ativar
                              </Button>
                          )
                      }
                    </td>
                    <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                      { item.name }
                    </td>
                    <td>
                      { item.username }
                    </td>
                  </tr>
                ))
              )
            )
          }
        </tbody>
      </table>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}