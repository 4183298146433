import { PartnerCouponConsumedDetail } from "../../../service/partner/usePartnerRequests";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css"


type Prop = {
  details: PartnerCouponConsumedDetail[]
}

export default function PartnerCouponReportDetailsModal(props: Prop) {

  const {details} = props;

  return (
    !details ? null :(
      <TableResponsiveWrapper>
        <table className={ styles.table }>
          <thead>
            <tr>
              <th>Data</th>
              <th>Importações Válidas</th>
              <th>Importações Inválidas</th>
              <th>Leituras Válidas</th>
              <th>Leituras Inválidas</th>
              <th>Autenticações</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {
              details.map((detail, index) => (
                <tr key={ index }>
                  <td>
                    { detail.date }
                  </td>
                  <td>
                    { detail.importValid }
                  </td>
                  <td>
                    { detail.importInvalid }
                  </td>
                  <td>
                    { detail.readValid }
                  </td>
                  <td>
                    { detail.readInvalid }
                  </td>
                  <td>
                    { detail.authRequests }
                  </td>
                  <td>
                    { detail.importValid + detail.importInvalid + detail.readValid + detail.readInvalid + detail.authRequests }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
    )
  )
  
}