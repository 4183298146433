import {
  Key,
  LegacyRef,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from "react";
import { isEmptyList } from "../../../utils/validation";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useModal from "../../service/modal/useModal";
import { SetState } from "../../types";
import Button from "../form/Button";
import { TableResponsiveWrapper } from "./TableResponsiveWrapper";
import styles from "./ui.module.css";

type SetTable = SetState<Table>;

interface Table {
  loading: boolean;
  header: ReactElement | null;
  content: ReactElement | null;
}

interface Props {
  handleSendFile: (file: File) => void;
}

export default function CsvList({ handleSendFile }: Props) {
  const fileInput = useRef<HTMLInputElement>();
  const { openModal } = useModal();

  const [table, setTable] = useState<Table>({
    loading: false,
    header: null,
    content: null,
  });
  const [sendingFile, setSendingFile] = useState(false);

  const handleSendFileOnClick = async (file: File) => {
    try {
      setSendingFile(true);
      await handleSendFile(file);
      openModal(
        "Arquivo enviado. Um email será enviado com detalhes do processo."
      );
    } catch (e: any) {
      openModal(getErrorMessage(e));
    } finally {
      setSendingFile(false);
    }
  };
  const handleUploadOnClick = useCallback(
    () => fileInput?.current?.click(),
    []
  );
  const handleFileOnChange = useCallback(() => {
    if (fileInput!.current!.files![0]) baseFileOnChange(fileInput, setTable);
  }, []);

  function baseFileOnChange(
    fileInput: React.MutableRefObject<HTMLInputElement | undefined>,
    setTable: SetTable
  ) {
    try {
      const reader = new FileReader();
      setTable({ loading: true, header: null, content: null });

      reader.onload = function () {
        const data = reader.result;
        const rows = data?.toString().split("\n");
        if (rows === undefined) return;

        if (isEmptyList(rows) || rows.length < 2) {
          setTable((prev) => ({ ...prev, header: null, content: null }));
          return;
        }

        const headers = rows[0].split(";");

        setTable((prev) => ({
          ...prev,
          header: (
            <tr>
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          ),
          content: (
            <>
              {rows.slice(1, 6).map((row: string, index: Key) => (
                <tr key={index}>
                  {row.split(";").map((value, subIndex) => (
                    <td key={subIndex}>{value}</td>
                  ))}
                </tr>
              ))}
            </>
          ),
        }));
      };

      // Lê o arquivo selecionado pelo usuário como texto
      reader.readAsText(fileInput!.current!.files![0]);
    } finally {
      setTable((prev) => ({ ...prev, loading: false }));
    }
  }

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        ref={fileInput as LegacyRef<HTMLInputElement>}
        style={{ display: "none" }}
        onChange={handleFileOnChange}
      />
      <Button
        type="info"
        onClick={handleUploadOnClick}
        disabled={sendingFile || table.loading}
      >
        Upload
      </Button>{" "}
      <Button
        type="confirm"
        onClick={() => handleSendFileOnClick(fileInput!.current!.files![0])}
        disabled={
          sendingFile ||
          !fileInput?.current?.files ||
          table.content === null ||
          table.loading
        }
      >
        {sendingFile ? "Processando arquivo" : "Enviar"}
      </Button>
      {table.header === null ? null : (
        <div>
          <h2>
            Essa tabela exibe até 5 linhas de itens que serão enviados, para
            conferência dos campos
          </h2>
          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>{table.header}</thead>
              <tbody>{table.content}</tbody>
            </table>
          </TableResponsiveWrapper>
        </div>
      )}
    </div>
  );
}
