import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import { ProductBrand } from "../../../service/product/useProductBrandManagementPage";
import Button from "../../form/Button";

export default function ProductBrandDelete(props: {
  productBrand: ProductBrand;
}) {
  const { productBrand } = props;

  const { openModal, closeModal } = useModal();

  const [state, setState] = useState<{
    loading: boolean;
    productBrand: ProductBrand;
  }>({
    loading: false,
    productBrand: {
      id: productBrand.id,
      file: productBrand.file,
      name: productBrand.name,
      manufacturer: productBrand.manufacturer,
      economicGroup: productBrand.economicGroup,
      ownBrand: productBrand.ownBrand,
      productQtt: productBrand.productQtt,
    },
  });

  const handleOnDelete = useCallback(
    () => deleteProductBrand(openModal, setState, state),
    [openModal, setState, state]
  );

  return (
    <div>
      <p>Tem certeza que deseja deletar a marca {state.productBrand.name}?</p>

      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button
            type="confirm"
            disabled={state.productBrand.productQtt! > 0 || state.loading}
            onClick={() => handleOnDelete()}
          >
            Deletar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

type SetState = Dispatch<
  SetStateAction<{ loading: boolean; productBrand: ProductBrand }>
>;

async function deleteProductBrand(
  openModal: OpenModal,
  setState: SetState,
  state: { loading: boolean; productBrand: ProductBrand }
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    await http.delete(
      `${baseUrl}/api/v1/product/brand/${state.productBrand.id}`
    );

    openModal("Marca deletada com sucesso.");
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}
