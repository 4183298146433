import useCompanyCategoryPage from "../../service/company/category/useCompanyCategoryPage";
import Button from "../form/Button";
import Input from "../form/Input";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";

export default function CompanyCategoryPage() {
  const {
    openCategoryCreateModal,
    openCategoryEditModal,
    openCategoryDeleteModal,
    handleOnChange,
    handlePageOnChange,
    handleFindOnSubmit,
    totalPages,
    page,
    filter,
    loading,
    items,
  } = useCompanyCategoryPage();

  return (
    <div>
      <SectionTitle
        title="Gestão de Tipo de loja"
        description="Consulta, edição e criação de tipos de loja."
      />

      <form onSubmit={handleFindOnSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre o tipo de loja pelo nome"
          addons={[
            <Button key="1" type="info" submit>
              Pesquisar
            </Button>,
          ]}
        />
      </form>
      <Button onClick={() => openCategoryCreateModal()} type="confirm">
        Criar Tipo de Loja
      </Button>
      <Spacer ratio={2 / 3} />

      {loading ? (
        <tr>
          <td colSpan={7}>Carregando....</td>
        </tr>
      ) : !items || items.length === 0 ? (
        <tr>
          <td colSpan={7}>Sem resultados</td>
        </tr>
      ) : (
        <>
          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Id</th>
                  <th className={`${styles.fullWidth} ${styles.left}`}>Nome</th>
                  <th>Canal</th>
                  <th>Id Canal</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Button
                        onClick={() => openCategoryEditModal(item)}
                        small
                        type="warning"
                      >
                        Editar
                      </Button>{" "}
                      <Button
                        onClick={() => openCategoryDeleteModal(item.id)}
                        type="cancel"
                        small
                      >
                        Remover
                      </Button>
                    </td>
                    <td>{item.id}</td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {item.description}
                    </td>
                    <td>{item.channel}</td>
                    <td>{item.channelId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableResponsiveWrapper>
          <Pagination
            page={page}
            totalPages={totalPages === null ? undefined : totalPages}
            onChangePage={handlePageOnChange}
          />
        </>
      )}
    </div>
  );
}
