import React from "react";
import { UF } from "../../../constants";
import Input from "../../Input";

function BasicPlan({ plan, setPlan, childCategories }) {
  const handleChangeCategories = (options) => {
    setPlan((plan) => {
      if (options.includes("all")) {
        return {
          ...plan, params: { ...plan.params, categories: childCategories.map((c) => c.id.toString()) }
        };
      }
      if (options.includes("remove all")) {
        return { ...plan, params: { ...plan.params, categories: [] } };
      }
      return { ...plan, params: { ...plan.params, categories: options } };
    });
  };

  const handleChangeUfs = (options) => {
    setPlan((plan) => {
      if (options.includes("all")) {
        return { ...plan, params: { ...plan.params, ufs: UF.map((uf) => uf) } };
      }
      if (options.includes("remove all")) {
        return { ...plan, params: { ...plan.params, ufs: [] } };
      }
      return { ...plan, params: { ...plan.params, ufs: options } };
    });
  };

  const options = {
    categories: childCategories && [
      { id: "all", name: "Selecionar Tudo" },
      ...childCategories.map((c, i) => ({ key: c.id, id: c.id, name: c.name })),
      { id: "remove all", name: "Remover Tudo" },
    ],
    uf: UF && [
      { id: "all", name: "Selecionar Tudo" },
      ...UF.map((uf, i) => ({ key: i, id: uf, name: uf })),
      { id: "remove all", name: "Remover Tudo" },
    ],
  };

  return (
    <div>
      {childCategories && (
        <Input
          label="Categoria"
          value={plan?.params?.categories}
          placeholder="Selecione..."
          onChange={handleChangeCategories}
          options={options?.categories}
          multiple
        />
      )}
      <Input
        label="UF"
        placeholder="Selecione..."
        value={plan?.params?.ufs}
        onChange={handleChangeUfs}
        options={options?.uf}
        multiple
      />

      
    </div>
  );
}

export default BasicPlan;
