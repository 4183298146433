import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Table from "../components/Table";
import { SubTitle, Title } from "../components/Titles";
import useEventPartner from "../hooks/useEventPartner";
import usePartner from "../hooks/usePartner";
import {
  allRules,
  createUserPermission,
  isEmail,
  isEmptyObj,
  isNotEmpty,
  isValidUserName,
  makeValidator,
  validateTextSize,
} from "../utils";
import { authCredentials } from "../utils/authCredentials";

const userValidator = makeValidator({
  username: allRules(
    isNotEmpty("Usuario de acesso"),
    isValidUserName("Usuario de acesso")
  ),
  firstName: isNotEmpty("Nome Parceiro"),
  email: allRules(isNotEmpty("E-mail"), isEmail("E-mail")),
});

function CreatePartnerForm(props) {
  const { onCreatePartner, newUserPartner, setNewUserPartner } = props;
  const [collapsed, setCollapsed] = useState(true);
  const {
    getUsernameErrors,
    getEmailErrors,
    getFirstNameErrors,
    getAllErrors,
  } = userValidator;

  const authUser = authCredentials.get.authUser();

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        onCreatePartner(newUserPartner);
        setCollapsed(true);
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Criar Novo Parceiro</SubTitle>
        <Card>
          <Input
            label="Usuario de acesso"
            value={newUserPartner.username}
            onChange={(e) =>
              setNewUserPartner({ ...newUserPartner, username: e.target.value })
            }
            validate={getUsernameErrors}
          />
          <Input
            label="Nome Parceiro"
            value={newUserPartner.firstName}
            onChange={(e) =>
              setNewUserPartner({
                ...newUserPartner,
                firstName: validateTextSize(e.target.value, 1, 14),
              })
            }
            validate={getFirstNameErrors}
          />
          <Input
            label="E-mail"
            value={newUserPartner.email}
            onChange={(e) =>
              setNewUserPartner({ ...newUserPartner, email: e.target.value })
            }
            validate={getEmailErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Adicionar Parceiro
        </Button>
      ) : (
        <>
          <Button
            layout="confirm"
            disabled={
              getAllErrors(newUserPartner) ||
              !createUserPermission(authUser, newUserPartner)
            }
          >
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

const Wrapper = styled.div``;

function PartnerPage() {
  const { getAllPartners, createPartner, disabledPartner, activePartner } =
    usePartner();
  const { getEventPartnerById } = useEventPartner();
  const [eventPartners, setEventPartners] = useState(null);
  const [partners, setPartner] = useState(null);
  const [newUserPartner, setNewUserPartner] = useState({});
  const [loading, setLoading] = useState(true);
  const [eventloading, setEventLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const history = useHistory();

  const onCreatePartner = async (userPartner) => {
    setLoading(true);
    try {
      await createPartner(userPartner);
      const partnersResponse = await getAllPartners();
      setPartner(partnersResponse.data);
      setMessage("Parceiro cadastrado com sucesso!");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Cadastro não autorizado.")
          : error;
      err =
        error.response && error.response.status === 409
          ? new Error("Usuário já se encontra cadastrado.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const partnerActive = async (partnerId) => {
    setLoading(true);
    try {
      await activePartner(partnerId);
      const partnersResponse = await getAllPartners();
      setPartner(partnersResponse.data);
      setMessage("Parceiro ativado com sucesso!");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Ativação não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const partnerDisabled = async (partnerId) => {
    setLoading(true);
    try {
      await disabledPartner(partnerId);
      const partnersResponse = await getAllPartners();
      setPartner(partnersResponse.data);
      setMessage("Parceiro desabilitado com sucesso!");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Desativação não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!partners) {
      getAllPartners().then((response) => {
        setPartner(response.data);
        setLoading(false);
      });
    }
  }, [partners, setPartner, setLoading, getAllPartners]);

  useEffect(() => {
    if (!eventPartners) {
      getEventPartnerById(1).then((response) => {
        setEventPartners(response.data);
        setEventLoading(false);
      });
    }
  }, [eventPartners, setEventPartners, getEventPartnerById, setEventLoading]);

  return (
    <>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Parceiros</Title>
      <Wrapper>
        <CreatePartnerForm
          onCreatePartner={onCreatePartner}
          newUserPartner={newUserPartner}
          setNewUserPartner={setNewUserPartner}
        />
        {!loading ? (
          <Table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {partners && !isEmptyObj(partners) ? (
                partners.map((partner, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>{partner.firstName}</td>
                        <td>
                          <button
                            type="button"
                            layout="delete"
                            onClick={(e) =>
                              history.push(
                                "/user/promotion-partner/" +
                                  partner.username +
                                  "/" +
                                  partner.id
                              )
                            }
                          >
                            Visualizar Perfil
                          </button>
                          {partner.active ? (
                            <button
                              style={{ marginLeft: "5px" }}
                              type="button"
                              layout="delete"
                              onClick={(e) => partnerDisabled(partner.id)}
                            >
                              Desativar
                            </button>
                          ) : (
                            <button
                              style={{ marginLeft: "5px" }}
                              type="button"
                              layout="delete"
                              onClick={(e) => partnerActive(partner.id)}
                            >
                              Ativar
                            </button>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  );
                })
              ) : (
                <tr>
                  <td>Sem dados para exibição</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        ) : (
          <Card>
            <Loading style={{ margin: "20px auto" }} />
          </Card>
        )}
      </Wrapper>
    </>
  );
}

export default PartnerPage;
