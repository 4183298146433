import { useState, useEffect, useCallback } from 'react';

export function useLocalStorage<T>(key: string, initialValue: T): [T, (valueOrUpdater: T | ((prevState: T) => T)) => void] {
  const hasStorage = typeof window !== 'undefined' && 'localStorage' in window;

  const [value, setValue] = useState<T>(() => {
    if (!hasStorage) return initialValue;

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const handleSetValue = useCallback((valueOrUpdater: T | ((prevState: T) => T)) => {
    setValue(prevValue => {
      if (typeof valueOrUpdater === 'function') {
        return (valueOrUpdater as ((prevState: T) => T))(prevValue);
      } else {
        return valueOrUpdater as T;
      }
    });
  }, [ setValue ]);

  useEffect(() => {
    if (hasStorage) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value, hasStorage]);

  return [value, handleSetValue];
}
