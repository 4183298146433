import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { Dashboard } from "./useDashboardRequests";

export interface SaveAnalysisServicesForm {
  name: string;
  powered: boolean;
}

export interface AnalysisServicesInstance {
  name: string;
  powered: boolean;
  lastUseDt: string;
}

export interface AnalysisServicesInstanceDetailed {
  name: string;
  powered: boolean;
  azureStatus: string;
  lastUseDt: string;
  powerOnManualRequestedAt: string;
  expectedNextToggleAt: string;
  dashboards: Array<Dashboard>;
  powerOffAllowed: boolean;
}

export type UseAnalysisServicesRequests = typeof useAnalysisServicesRequests;
export type SaveAnalysisServices =
  ReturnType<UseAnalysisServicesRequests>["saveAnalysisServices"];
export type DeleteAnalysisServices =
  ReturnType<UseAnalysisServicesRequests>["deleteAnalysisServices"];
export type FindAnalysisServices =
  ReturnType<UseAnalysisServicesRequests>["findAnalysisServices"];
export type FindAnalysisServicesDetailed =
  ReturnType<UseAnalysisServicesRequests>["findAnalysisServicesDetailed"];
export type TurnInstanceOn =
  ReturnType<UseAnalysisServicesRequests>["turnInstanceOn"];
export type TurnInstanceOff =
  ReturnType<UseAnalysisServicesRequests>["turnInstanceOff"];

export default function useAnalysisServicesRequests() {
  const saveAnalysisServices = (form: SaveAnalysisServicesForm) =>
    baseSaveAnalysisServices(form);

  const deleteAnalysisServices = (name: string) =>
    baseDeleteAnalysisServices(name);

  const findAnalysisServices = () => baseFindAnalysisServices();

  const findAnalysisServicesDetailed = () => baseFindAnalysisServicesDetailed();

  const turnInstanceOn = (name: string) => baseTurnInstanceOn(name);

  const turnInstanceOff = (name: string) => baseTurnInstanceOff(name);

  return {
    saveAnalysisServices,
    deleteAnalysisServices,
    findAnalysisServices,
    findAnalysisServicesDetailed,
    turnInstanceOn,
    turnInstanceOff,
  };
}

async function baseSaveAnalysisServices(form: SaveAnalysisServicesForm) {
  await http.post(baseUrl + "/api/v1/analysis-services", form);
}

async function baseDeleteAnalysisServices(name: string) {
  await http.delete(baseUrl + "/api/v1/analysis-services/" + name);
}

async function baseFindAnalysisServices(): Promise<
  Array<AnalysisServicesInstance>
> {
  const response = await http.get(baseUrl + "/api/v1/analysis-services");
  return response.data.result;
}

async function baseFindAnalysisServicesDetailed(): Promise<
  AnalysisServicesInstanceDetailed[]
> {
  const response = await http.get(
    baseUrl + "/api/v1/analysis-services/details"
  );
  return response.data.items;
}

async function baseTurnInstanceOn(name: string): Promise<void> {
  const response = await http.post(
    baseUrl + "/api/v1/analysis-services/" + name + "/power-on"
  );
  return response.data.items;
}

async function baseTurnInstanceOff(name: string): Promise<void> {
  const response = await http.post(
    baseUrl + "/api/v1/analysis-services/" + name + "/power-off"
  );
  return response.data.items;
}
