import { getRandomRgb } from "./intranet/service/ui";

export const baseUrl = process.env.REACT_APP_BASE_URL;

export const companyUrl = "https://company-master.ehorus.com.br";

export const authToken = "YXPbX2F1dGhfc2VjcmV0X2tleV9ncmdpOS5jb20=";

export const mediaPrefix = "https://assets.ehorus.com.br/";

export const reportApi = "https://coupon-report.ehorus.com.br";

export const mainColor = "rgb(153,21,43)";

export const AUTH_CREDENTIALS = {
  ACCESS_TOKEN: "@access_token-horus",
  REFRESH_TOKEN: "@refresh_token-horus",
  AUTH_USER: "@auth_user-horus",
}

export const QUERY_API = {
  HOST: "dbHostQueryApi",
  USER: "dbUserQueryApi",
  PASSWORD: "dbUserPasswordQueryApi",
};

export const UF = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const PRODUCT_STATUS = [
  { id: "", name: "Selecione..." },
  { id: "AGUARDANDO", name: "AGUARDANDO" },
  { id: "BLOQUEADO", name: "BLOQUEADO" },
  { id: "LIBERADO", name: "LIBERADO" },
  { id: "MODERACAO", name: "MODERACAO" },
  { id: "PAUSADO", name: "PAUSADO" },
  { id: "PULADO", name: "PULADO" },
];

export const COMPANY_STATUS = [
  { id: "", name: "Selecione..." },
  { id: "AGUARDANDO", name: "AGUARDANDO" },
  { id: "BLOQUEADO", name: "BLOQUEADO" },
  { id: "LIBERADO", name: "LIBERADO" },
  { id: "PAUSADO", name: "PAUSADO" },
  { id: "PENDENTE", name: "PENDENTE" },
  { id: "PARCIAL", name: "PARCIAL" },
  { id: "INATIVO", name: "INATIVO" },
  { id: "PULADO", name: "PULADO" },
];

export const TAG_TYPES = [
  { id: 'SAZONAL', name: 'Sazonal' },
  { id: 'SABOR', name: 'Sabor' },
  { id: 'COR', name: 'Cor' },
  { id: 'ESSENCIA', name: 'Essência' },
  { id: 'EMBALAGEM', name: 'Embalagem' },
  { id: 'ESPECIFICIDADE', name: 'Especificidade' },
  { id: 'LINHA', name: 'Linha' },
  { id: 'BASE', name: 'Base' },
  { id: 'METRAGEM', name: 'Metragem' },
  { id: 'ARQUETIPO', name: 'Arquetipo' },
  { id: 'PODER DE COMPRA', name: 'Poder de compra' },
  { id: 'PUBLICO ALVO', name: 'Público Alvo' },
  { id: 'APLICAÇÂO', name: 'Aplicação' },
  { id: 'INDICAÇÂO', name: 'Indicação' }
]

export const UNITOFMEASUREMENT = [
  { id: "kl", name: "Quilolitro (kl)", type: "Lt", multiplier: 0.001 },
  { id: "hl", name: "Hectolitro (hl)", type: "Lt", multiplier: 0.01 },
  { id: "dal", name: "Decalitro (dal)", type: "Lt", multiplier: 0.1 },
  { id: "Lt", name: "Litro (l)", type: "Lt", multiplier: 1 },
  { id: "dl", name: "Decilitro (dl)", type: "Lt", multiplier: 10 },
  { id: "cl", name: "Centilitro (cl)", type: "Lt", multiplier: 100 },
  { id: "ml", name: "Mililitro (ml)", type: "Lt", multiplier: 1000 },
  { id: "Kg", name: "Quilograma (kg)", type: "Kg", multiplier: 1 },
  { id: "hg", name: "Hectograma (hg)", type: "Kg", multiplier: 10 },
  { id: "dag", name: "Decagrama (dag)", type: "Kg", multiplier: 100 },
  { id: "g", name: "Grama (g)", type: "Kg", multiplier: 1000 },
  { id: "dg", name: "Decigrama (dg)", type: "Kg", multiplier: 10000 },
  { id: "cg", name: "Centigrama (cg)", type: "Kg", multiplier: 100000 },
  { id: "mg", name: "Miligrama (mg)", type: "Kg", multiplier: 1000000 },
  { id: "Und", name: "Unidade (Und)", type: "Und" },
  { id: "pk", name: "Pacote", type: "Und" },
];

export const PLANS = ["BASIC", "PROFESSIONAL", "POWERBI"];

export const DASHBOARDS = [
  {
    id: "basic_dash",
    name: "Dashboard Básico",
    type: "BASIC",
  },
  {
    id: "market_share_per_segment",
    name: "Share das Marcas por Segmento",
    type: "PROFESSIONAL",
  },
  {
    id: "professional_dash",
    name: "Dashboard Professional",
    type: "PROFESSIONAL",
  },
];

export const menus = {
  home: {
    name: "Início",
    path: "/dashboard",
    available: true,
  },
  users: {
    name: "Cadastro de Usuários",
    path: "/users",
    roles: ["INSIGHTS"],
    available: true,
  },
  clientes: {
    name: "Cadastro de Clientes",
    path: "/client-company",
    roles: ["INSIGHTS"],
    available: true,
  },
  company: {
    name: "Moderação de Empresas",
    path: "/companies",
    roles: ["DATA_SCIENCE", "MODERATION"],
    available: true,
  },
  category: {
    name: "Moderação de Categorias",
    path: "/category-moderation/product/new",
    roles: ["DATA_SCIENCE", "MODERATION"],
    available: true,
  },
  partner: {
    name: "Cadastro de Parceiros",
    path: "/promotion-partner",
    roles: ["COMERCIAL"],
    available: true,
  },
  brands: {
    name: "Cadastro de Marcas",
    path: "/brands",
    roles: ["DATA_SCIENCE"],
    available: true,
  },
  product: {
    name: "Cadastro de Produtos",
    path: "/products",
    roles: ["DATA_SCIENCE", "MODERATION"],
    available: true,
  },
  queue: {
    name: "Priorização / Atualização Múltipla de Produtos",
    path: "/queue-setup",
    roles: ["DATA_SCIENCE"],
    available: true,
  },
  productBrands: {
    name: "Cadastro de Marcas de Produtos",
    path: "/product-brands",
    roles: ["DATA_SCIENCE"],
    available: true,
  },
  productCategories: {
    name: "Cadastro de Categorias de Produtos",
    path: "/product-categories",
    roles: ["DATA_SCIENCE"],
    available: false,
  },
  dashboards: {
    name: "Gestão de Dashboards",
    path: "/dashboards",
    roles: ["DATA_SCIENCE"],
    available: false,
  },
};

export const restrictiveGroups = {
  moderation: {
    product: 7,
    company: 153,
    advanced: 144,
    master: 145,
  },
  subscription: 147,
  horusUserManagement: 154,
};

export const CHANNELS = [
  {
    id: 1,
    name: "AS 1-4",
  },
  {
    id: 2,
    name: "AS 5+",
  },
  {
    id: 3,
    name: "C&C",
  },
  {
    id: 4,
    name: "BAR",
  },
  {
    id: 5,
    name: "TRADICIONAL",
  },
  {
    id: 6,
    name: "EMPÓRIOS",
  },
  {
    id: 7,
    name: "FARMÁCIA",
  },
  {
    id: 8,
    name: "DOCES, BALAS E BOMBONS",
  },
  {
    id: 9,
    name: "CONVENIÊNCIA",
  },
  {
    id: 10,
    name: "POSTO DE COMBUSTÍVEL",
  },
  {
    id: 11,
    name: "SAÚDE E BELEZA",
  },
  {
    id: 12,
    name: "HAIR CARE E ESTÉTICA",
  },
  {
    id: 13,
    name: "PET SHOP",
  },
  {
    id: 14,
    name: "LIVRARIA, PAPELARIA E BAZAR",
  },
  {
    id: 15,
    name: "MÉDICOS-HOSPITALAR",
  },
  {
    id: 16,
    name: "VESTUÁRIO E CALÇADOS",
  },
  {
    id: 17,
    name: "INFORMÁTICA E TELECOM",
  },
  {
    id: 18,
    name: "FAST FOOD",
  },
  {
    id: 19,
    name: "VAREJO EM GERAL",
  },
  {
    id: 20,
    name: "AUTOMOTIVO",
  },
  {
    id: 21,
    name: "CAFETERIA",
  },
  {
    id: 22,
    name: "INDÚSTRIA ALIMENTÍCIA NO VAREJO",
  },
  {
    id: 23,
    name: "FARMÁCIAS REDES",
  },
  {
    id: 24,
    name: "ATACADO ESPECIALISTA",
  },
  {
    id: 99,
    name: "OUTROS",
  },
  {
    id: 100,
    name: "CENTRO DE DISTRIBUIÇÃO",
  },
];
export const ufCodes = [
  { code: "11", name: "RO", color: getRandomRgb() },
  { code: "12", name: "AC", color: [255, 11, 227] },
  { code: "13", name: "AM", color: getRandomRgb() },
  { code: "14", name: "RR", color: getRandomRgb() },
  { code: "15", name: "PA", color: getRandomRgb() },
  { code: "16", name: "AP", color: getRandomRgb() },
  { code: "17", name: "TO", color: getRandomRgb() },
  { code: "21", name: "MA", color: getRandomRgb() },
  { code: "22", name: "PI", color: getRandomRgb() },
  { code: "23", name: "CE", color: getRandomRgb() },
  { code: "24", name: "RN", color: getRandomRgb() },
  { code: "25", name: "PB", color: getRandomRgb() },
  { code: "26", name: "PE", color: [255, 166, 11] },
  { code: "27", name: "AL", color: getRandomRgb() },
  { code: "28", name: "SE", color: getRandomRgb() },
  { code: "29", name: "BA", color: [146, 11, 246] },
  { code: "31", name: "MG", color: [255, 0, 0] },
  { code: "32", name: "ES", color: getRandomRgb() },
  { code: "33", name: "RJ", color: [0, 19, 285] },
  { code: "35", name: "SP", color: getRandomRgb() },
  { code: "41", name: "PR", color: getRandomRgb() },
  { code: "42", name: "SC", color: getRandomRgb() },
  { code: "43", name: "RS", color: getRandomRgb() },
  { code: "50", name: "MS", color: getRandomRgb() },
  { code: "51", name: "MT", color: getRandomRgb() },
  { code: "52", name: "GO", color: getRandomRgb() },
  { code: "53", name: "DF", color: [11, 242, 249] },
];
