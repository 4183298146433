import Input from '../Input'

function FgvPremiumPlan(props) {
  const { setPlanToEdit, fgvCategories } = props

  return (
    <div>
      <Input
        label="Categoria"
        value="Selecione uma categoria"
        onChange={ options => { setPlanToEdit( planToEdit => {
          if (options.target.value === "all") {
              return { ...planToEdit, categories: fgvCategories.map(c => ({ id: c.id, name: c.name, hasHistory: false })) }
          }
          if (options.target.value === "remove all") {
              return { ...planToEdit, categories: [] }
          }
          return { ...planToEdit, categories: options.target.value }
        })}}
        options={ [ { id: "all", name: "Selecionar Tudo" }, ...fgvCategories.map((c, i) => ({ key: c.id, id: c.id, name: c.name })), { id: "remove all", name: "Remover Tudo" } ] }
      />
    </div>
  )
}

export default FgvPremiumPlan;