import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import { SubTitle, Title } from "../components/Titles";
import useClientCompany from "../hooks/useClientCompany";
import useDashboard from "../hooks/useDashboard";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { allRules, isNotEmpty, makeValidator, maxLength } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const InputWithButton = styled.div`
  & > div {
    width: calc(100% - 100px);
    float: left;
  }

  button {
    margin-top: 18px;
    width: 100px;
    float: left;
  }

  ::after {
    content: " ";
    display: block;
    clear: both;
  }
`;

const dashValidator = makeValidator({
  reportBiId: allRules(
    isNotEmpty("Id do relatório"),
    maxLength("Id do relatório", 50)
  ),
  groupBiId: allRules(
    isNotEmpty("Id do grupo do relatório"),
    maxLength("Id do grupo do relatório", 50)
  ),
  description: allRules(isNotEmpty("Descrição"), maxLength("Descrição", 100)),
  link: allRules(isNotEmpty("Link"), maxLength("Link", 100)),
  companyId: isNotEmpty("Empresa"),
});
//descption, reportId, groupId, datasetId, link, role
//on single attach user verifying if dashboard has a group equals to it's name , if not, create one.
//attach multiple users on single dashboard page
//show utilization of the dashboard - last six months.
function CreateDashForm(props) {
  const [newDash, setNewDash] = useState({});
  const [companies, setCompanies] = useState(null);
  const { getAllClientCompanies } = useClientCompany();
  const { onCreateDash } = props;
  const [collapsed, setCollapsed] = useState(true);
  const { getDatasetId } = useDashboard();
  const { createModal } = useModal();
  const [searchingDataset, setSearchingDataset] = useState(false);
  const {
    getReportBiIdErrors,
    getGroupBiIdErrors,
    getDescriptionErrors,
    getLinkErrors,
    getAllErrors,
    getCompanyIdErrors,
  } = dashValidator;

  const handleSearchDatasetIdOnClick = async () => {
    setSearchingDataset(true);
    try {
      const { data } = await getDatasetId(newDash);
      setNewDash((prev) => ({ ...prev, ...data }));
    } catch (e) {
      createModal("Ocorreu um erro ao recuperar datasetId");
    }
    setSearchingDataset(false);
  };

  useEffect(() => {
    if (!companies) {
      getAllClientCompanies().then((response) => {
        setCompanies(response.data);
      });
    }
  }, [companies, setCompanies, getAllClientCompanies]);

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          window.confirm(
            "Criar Dashboard: " +
            newDash.description +
            "?\n\nATENÇÃO: Certifique-se que todos os dados estão corretos."
          )
        ) {
          onCreateDash(newDash);
          setCollapsed(true);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Adicionar novo Dashboard</SubTitle>
        <Card>
          <Input
            label="Id do relatório"
            value={newDash.reportBiId}
            onChange={(e) =>
              setNewDash({ ...newDash, reportBiId: e.target.value })
            }
            validate={getReportBiIdErrors}
          />
          <Input
            label="Id do grupo do relatório"
            value={newDash.groupBiId}
            onChange={(e) =>
              setNewDash({ ...newDash, groupBiId: e.target.value })
            }
            validate={getGroupBiIdErrors}
          />
          <InputWithButton>
            <Input
              label="Id do dataset"
              value={newDash.datasetBiId}
              onChange={(e) =>
                setNewDash({ ...newDash, datasetBiId: e.target.value })
              }
            />
            <Button
              type="button"
              onClick={handleSearchDatasetIdOnClick}
              disabled={
                !newDash.reportBiId || !newDash.groupBiId || searchingDataset
              }
            >
              {searchingDataset ? "Carregando..." : "Buscar"}
            </Button>
          </InputWithButton>
          <Input
            label="Descrição"
            value={newDash.description}
            onChange={(e) =>
              setNewDash({ ...newDash, description: e.target.value })
            }
            validate={getDescriptionErrors}
          />
          <Input
            label="Link"
            value={newDash.link}
            onChange={(e) => setNewDash({ ...newDash, link: e.target.value })}
            validate={getLinkErrors}
          />
          <Input
            label="Empresa"
            value={newDash.companyId}
            placeholder="Selecione..."
            onChange={(e) =>
              setNewDash({ ...newDash, companyId: e.target.value })
            }
            options={(companies || []).map((company, index) => {
              return { id: company.id, name: company.name };
            })}
            validate={getCompanyIdErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Adicionar Dashboard
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newDash)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

function DashboardList() {
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { createDash } = useDashboard();
  const history = useHistory();
  const { getAllDashboards } = useDashboard();
  const [dashboards, setDashboards] = useState(null);
  const [loading, setLoading] = useState(true);
  const { createEvent } = useHorusAccess();
  const [event] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const currentDashboards = dashboards
    ? dashboards.slice(indexFirstItem, indexLastItem)
    : {};
  const totalItems = dashboards ? dashboards.length : 0;

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access dashboards";

    createEvent(event);
  }, []);

  const onCreateDash = async (dash) => {
    setLoading(true);
    try {
      await createDash(dash);
      const dashResponse = await getAllDashboards();
      setDashboards(dashResponse.data);
      setMessage("Cadastro concluído");
    } catch (error) {
      setError(
        error.response && error.response.status === 400
          ? new Error("Dashboard já cadastrada")
          : error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dashboards) {
      getAllDashboards().then((response) => {
        setDashboards(response.data);
        setLoading(false);
      });
    }
  });

  return (
    <>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <CreateDashForm onCreateDash={onCreateDash} />
      {!loading ? (
        <>
          <Table>
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Link</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {dashboards
                ? currentDashboards.map((dashboard, index) => {
                  return (
                    <tr key={index}>
                      <td>{dashboard.description}</td>
                      <td>{dashboard.link}</td>
                      <td>
                        <button
                          type="button"
                          layout="delete"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            history.push(
                              "/dashboards/legacy-dashboard/" + dashboard.id
                            )
                          }
                        >
                          Editar Dashboard
                        </button>
                      </td>
                    </tr>
                  );
                })
                : null}
            </tbody>
          </Table>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            onChangePage={onChangePage}
          />
        </>
      ) : null}
    </>
  );
}

const Wrapper = styled.div``;

const breadcrump = [
  {
    name: "Início",
    path: "/dashboard",
  },
  {
    name: "Dashboard",
    path: "/dash",
  },
];

function DashboardPage() {
  return (
    <>
      <Title>Dashboards</Title>
      <Wrapper>
        <DashboardList />
      </Wrapper>
    </>
  );
}

export default DashboardPage;
