import { http } from "../api/http";
import { baseUrl } from "../constants";

function useResearch() {
  const getAllResearch = () => http.get(`${baseUrl}/internal/api/v1/research/`);

  const getOneResearch = (id) =>
    http.get(`${baseUrl}/internal/api/v1/research/${id}`);

  const getAllCompanies = () => http.get(`${baseUrl}/client-company/`);

  const addOneQuestion = (question) =>
    http.post(baseUrl + "/internal/api/v1/research/", question);

  const deleteOneQuestion = (id) =>
    http.delete(`${baseUrl}/internal/api/v1/research/${id}`);

  const updateOneQuestion = (id, question) =>
    http.put(`${baseUrl}/internal/api/v1/research/${id}`, question);

  const addResearch = (id, companies) =>
    http.post(`${baseUrl}/internal/api/v1/research-published/${id}`, companies);

  const loadAll = async () => {
    const { data } = await getAllResearch();
    return data;
  };

  const loadAllCompanies = async () => {
    const { data } = await getAllCompanies();
    return data;
  };

  const loadOne = async (id) => {
    const { data } = await getOneResearch(id);
    return data;
  };

  const addQuestion = async (question, description) => {
    const { data } = await addOneQuestion({
      description: description,
      question: question,
    });
    return data;
  };

  const updateQuestion = async (id, question, description) => {
    return await updateOneQuestion(id, {
      description: description,
      question: question,
    });
  };

  const researchPublished = async (id, companies) => {
    return await addResearch(id, companies);
  };

  return {
    loadAll,
    loadOne,
    loadAllCompanies,
    addQuestion,
    deleteOneQuestion,
    updateQuestion,
    researchPublished,
  };
}

export default useResearch;
