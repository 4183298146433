import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../../../api/http";
import { baseUrl } from "../../../../../constants";
import { getErrorMessage } from "../../../../service/error/getErrorMessage";
import useHandleOnChange from "../../../../service/form/useHandleOnChange";
import useHandleOnCheck from "../../../../service/form/useHandleOnCheck";
import useModal, { OpenModal } from "../../../../service/modal/useModal";
import { isEmptyString } from "../../../../service/string";
import { FetchDataResult, Option } from "../../../form/AsyncSelect";
import useCategoryRegistration, {
  CreateNielsenCategory,
  DeleteNielsenCategory,
  FetchNielsenCategory,
  NielsenCategoryForm,
} from "../useCategoryRegistration";

/**
 * Hook com a lógica do componente NielsenCategoryCreatePage
 */
function useNielsenCategoryCreatePage(edit: boolean) {
  const categoryRegistration = useCategoryRegistration();

  const { createNielsenCategory, fetchNielsenCategory, deleteNielsenCategory } =
    categoryRegistration;

  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const { openModal } = useModal();

  const [state, setState] = useState(initialState);

  const { sendingCategory, selectedMotherCategory } = state;

  const handleOnChange = useHandleOnChange(setState);
  const handleOnCheck = useHandleOnCheck(setState);

  const validation = useMemo(() => validate(state), [state]);

  const buttonText = useMemo(
    () => getButtonText(validation, sendingCategory),
    [validation, sendingCategory]
  );

  const fetchAuntCategoryOpts = useCallback(
    (filter: string, offset: number | undefined) =>
      baseFetchAuntCategoryOpts(
        "aunt",
        filter,
        id,
        selectedMotherCategory,
        offset
      ),
    [selectedMotherCategory, id]
  );

  const handleMotherCategoryOnChange = (o: Option<null> | null) =>
    baseHandleSelectOnChange(setState, "selectedMotherCategory", o);

  const handleAuntCategoryOnChange = (o: Option<null> | null) =>
    baseHandleSelectOnChange(setState, "selectedAuntCategory", o);

  const handleLvl1CategoryOnChange = (o: Option<null> | null) =>
    baseHandleSelectOnChange(setState, "selectedLvl1Category", o);

  const handleProductCategorySelectOnChange = (o: Option<null> | null) =>
    baseProductCategorySelectOnChange(setState, o);

  const removeProductCategory = (id: string) =>
    baseRemoveProductCategory(setState, id);

  const handleSendButtonOnClick = useCallback(
    () =>
      baseHandleSendButtonOnClick(
        setState,
        openModal,
        createNielsenCategory,
        history,
        state
      ),
    [state]
  );

  const handleDeleteButtonOnClick = useCallback(
    () =>
      baseHandleDeleteButtonOnClick(
        setState,
        openModal,
        deleteNielsenCategory,
        history,
        parseInt(id!)
      ),
    [id]
  );

  const handleEditCategoryOnChange = (option: Option<null> | null) =>
    baseHandleEditCategoryOnChange(history, setState, option);

  const toggleConfirmation = (action: string | null) =>
    setState((prev) => ({ ...prev, confirmation: action }));

  const showForm = !edit || id;

  useEffect(
    () => loadCategoryEffect(fetchNielsenCategory, setState, openModal, id),
    [id]
  );

  return {
    ...state,
    ...categoryRegistration,
    handleOnChange,
    buttonText,
    validation,
    handleMotherCategoryOnChange,
    handleLvl1CategoryOnChange,
    handleProductCategorySelectOnChange,
    removeProductCategory,
    handleSendButtonOnClick,
    handleEditCategoryOnChange,
    showForm,
    handleOnCheck,
    handleAuntCategoryOnChange,
    fetchAuntCategoryOpts,
    handleDeleteButtonOnClick,
    toggleConfirmation,
  };
}

/**
 * Tipo do setState
 */
type SetState = Dispatch<SetStateAction<State>>;

/**
 * Tipo do objeto histórico
 */
type History = ReturnType<typeof useHistory>;

/**
 * Retorno da request de buscar opções de formulário
 */
interface OptionsView {
  result: {
    value: number;
    description: string;
  }[];
}

/**
 * Tipo do estado do componente.
 */
interface State {
  createNewMotherCategory: boolean;
  createNewAuntCategory: boolean;
  description: string | null;
  motherCategoryDescription: string | null;
  auntCategoryDescription: string | null;
  selectedMotherCategory: null | Option<null>;
  selectedAuntCategory: null | Option<null>;
  selectedLvl1Category: null | Option<null>;
  selectedEditCategory: null | Option<null>;
  selectedProductCategories: Option<null>[];
  sendingCategory: boolean;
  fetchingCategory: boolean;
  confirmation: string | null;
}

/**
 * Tipo do objeto que contém os dados de validação do formulário
 */
interface Validation {
  description: string[];
  motherCategory: string[];
  lvl1Category: string[];
  productCategory: string[];
  errors: number;
}

/**
 * Valida os dados do formulário
 */
function validate(state: State): Validation {
  const validation: Validation = {
    description: [],
    motherCategory: [],
    lvl1Category: [],
    productCategory: [],
    errors: 0,
  };

  if (isEmptyString(state.description)) {
    validation.description.push("A descrição é obrigatória");
    validation.errors++;
  }

  if (
    (!state.createNewMotherCategory && !state.selectedMotherCategory) ||
    (state.createNewMotherCategory &&
      isEmptyString(state.motherCategoryDescription))
  ) {
    validation.motherCategory.push("A categoria mãe é obrigatória");
    validation.errors++;
  }

  if (!state.selectedLvl1Category) {
    validation.lvl1Category.push("A categoria nível 1 é obrigatória");
    validation.errors++;
  }

  if (!state.selectedProductCategories.length) {
    validation.productCategory.push(
      "Deve haver no mínimo uma categoria produto"
    );
    validation.errors++;
  }

  return validation;
}

/**
 * Retorna o texto que vai ficar no botão de enviar
 */
function getButtonText(validation: Validation, loading: boolean) {
  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1
      ? `Existe 1 erro`
      : `Existem ${validation.errors} erros`;
  }

  return "Enviar";
}

/**
 * Cria o handler pro onChange de um AsyncSelect
 */
function baseHandleSelectOnChange(
  setState: SetState,
  type:
    | "selectedMotherCategory"
    | "selectedAuntCategory"
    | "selectedLvl1Category",
  option: Option<null> | null
) {
  setState((prev) => ({
    ...prev,
    [type]: option,
  }));
}

/**
 * Cria o handler pro onChange da seleção de categoria de produto
 */
function baseProductCategorySelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedProductCategories: [...prev.selectedProductCategories, option],
    };
  });
}

/**
 * Cria a função de remover itens da seleção de categoria
 */
function baseRemoveProductCategory(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedProductCategories: [...prev.selectedProductCategories].filter(
      (o) => o.id !== id
    ),
  }));
}

/**
 * Handler do evento de click no botão de salvar a categoria
 */
async function baseHandleSendButtonOnClick(
  setState: SetState,
  openModal: OpenModal,
  createNielsenCategory: CreateNielsenCategory,
  history: History,
  state: State
) {
  const form: NielsenCategoryForm = {
    nielsenCategoryId: state.selectedEditCategory
      ? parseInt(state.selectedEditCategory?.id)
      : undefined,
    motherCategoryId: !state.createNewMotherCategory
      ? parseInt(state.selectedMotherCategory!.id)
      : undefined,
    motherCategoryDescription: state.createNewMotherCategory
      ? state.motherCategoryDescription!
      : undefined,
    auntCategoryId:
      !state.createNewAuntCategory && state.selectedAuntCategory
        ? parseInt(state.selectedAuntCategory!.id)
        : undefined,
    auntCategoryDescription: state.createNewAuntCategory
      ? state.auntCategoryDescription!
      : undefined,
    lvl1CategoryId: parseInt(state.selectedLvl1Category!.id),
    productCategoryIds: state.selectedProductCategories.map((o) =>
      parseInt(o.id)
    ),
    description: state.description!,
  };

  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    const result = await createNielsenCategory(form);

    history.push(`/product/nielsen-category/edit/${result.nielsenId}`);

    openModal("Categoria criada/atualizada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

/**
 * Handler do evento de click no botão de salvar a categoria
 */
async function baseHandleDeleteButtonOnClick(
  setState: SetState,
  openModal: OpenModal,
  deleteNielsenCategory: DeleteNielsenCategory,
  history: History,
  nielsenId: number
) {
  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    await deleteNielsenCategory(nielsenId);

    history.push(`/product/nielsen-category/edit`);

    openModal("Categoria deletada com sucesso");
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

/**
 * Trata o evento do usuário selecionar a categoria que quer editar
 */
function baseHandleEditCategoryOnChange(
  history: History,
  setState: (action: SetStateAction<State>) => void,
  selectedEditCategory: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedEditCategory }));
  history.push(
    "/product/nielsen-category/edit" +
      (selectedEditCategory ? "/" + selectedEditCategory.id : "")
  );
}

/**
 * Recupera da api a categoria que o usuário selecionou para editar
 * e preenche o formulário de edição com ela.
 * Caso o usuário saia da página de editar pra página de criar esse mesmo
 * effect irá limpar o formulário.
 */
function loadCategoryEffect(
  fetch: FetchNielsenCategory,
  setState: SetState,
  openModal: OpenModal,
  id?: string
) {
  const fn = async () => {
    if (!id) {
      setState(initialState);
      return;
    }

    setState((prev) => ({ ...prev, fetchingCategory: true }));

    try {
      const category = await fetch(parseInt(id));

      setState((prev) => ({
        ...prev,

        description: category.description,
        createNewMotherCategory: false,
        motherCategoryDescription: null,
        selectedMotherCategory: {
          id: "" + category.motherCategoryId,
          name: category.motherCategory,
        },
        selectedAuntCategory: category.auntCategoryId
          ? { id: "" + category.auntCategoryId, name: category.auntCategory }
          : null,
        selectedLvl1Category: {
          id: "" + category.lvl1CategoryId,
          name: category.lvl1CategoryDescription,
        },
        selectedProductCategories: category.productCategories.map((pc) => ({
          id: "" + pc.value,
          name: pc.description,
        })),
        selectedEditCategory: {
          id: "" + category.nielsenId,
          name: category.description,
        },
        fetchingCategory: false,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  };

  fn();
}

async function baseFetchAuntCategoryOpts(
  type: string,
  filter: string,
  nielsenId: string | undefined,
  selectedMotherCategory: Option<null> | null,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const motherId = selectedMotherCategory?.id
    ? selectedMotherCategory.id
    : undefined;

  const response = await http.get(
    `${baseUrl}/api/v1/${type}-category/options`,
    {
      params: { filter, motherId, nielsenId, offset, limit: 10 },
    }
  );

  const data = response.data as OptionsView;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

/**
 * Objeto do estado inicial
 */
const initialState: State = {
  createNewMotherCategory: false,
  description: null,
  motherCategoryDescription: null,
  selectedMotherCategory: null,
  selectedLvl1Category: null,
  selectedEditCategory: null,
  selectedProductCategories: [],
  sendingCategory: false,
  fetchingCategory: false,
  createNewAuntCategory: false,
  auntCategoryDescription: null,
  selectedAuntCategory: null,
  confirmation: null,
};

export default useNielsenCategoryCreatePage;
