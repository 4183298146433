import React from "react";
import { UF } from "../../../constants";
import AsyncSelect2 from "../../AsyncSelect2";
import Input from "../../Input";

const PowerBi = ({ plan, setPlan, childCategories, channels, getManufacturers, manufacturerName, setManufacturerName }) => {

  const toString = arr => arr.map(String)

  const handleChange = {
    categories: (options) => {
      setPlan((plan) => {
        if (options.includes("all")) {
          return { ...plan,  params: { ...plan.params, categories: childCategories.map((c) => c.id.toString()) } };
        }
        if (options.includes("remove all")) {
          return { ...plan,  params: { ...plan.params, categories: [] } };
        }
        return { ...plan,  params: { ...plan.params, categories: options } };
      });
    },
    uf: (options) => {
      setPlan((plan) => {
        if (options.includes("all")) {
          return { ...plan,  params: { ...plan.params, ufs: UF.map((uf) => uf) } };
        }
        if (options.includes("remove all")) {
          return { ...plan,  params: { ...plan.params, ufs: [] } };
        }
        return { ...plan,  params: { ...plan.params, ufs: options } };
      });
    },
    canal: (options) => {
      setPlan((plan) => {
        if (options.includes("all")) {
          return { ...plan,  params: { ...plan.params, channels: channels.map((ch) => ch.id.toString()) } };
        }
        if (options.includes("remove all")) {
          return { ...plan,  params: { ...plan.params, channels: [] } };
        }
        return { ...plan,  params: { ...plan.params, channels: options } };
      });
    },
  };

  const options = {
    categories: childCategories &&  [
      { id: "all", name: "Selecionar Tudo" },
      ...childCategories.map((c, i) => ({
        key: c.id,
        id: c.id,
        name: c.name,
      })),
      { id: "remove all", name: "Remover Tudo" },
    ],

    uf: UF && [
      { id: "all", name: "Selecionar Tudo" },
      ...UF.map((uf, i) => ({ key: i, id: uf, name: uf })),
      { id: "remove all", name: "Remover Tudo" },
    ],
    canal: channels && [
      { id: "all", name: "Selecionar Tudo" },
      ...channels.map((c) => ({ key: c.id, id: c.id, name: c.description })),
      { id: "remove all", name: "Remover Tudo" },
    ],
  };

  return (
    <>
      {childCategories && (
        <Input
          label="Categoria"
          value={plan?.params?.categories}
          placeholder="Selecione..."
          onChange={handleChange.categories}
          options={options.categories}
          multiple
        />
      )}
      <Input
        label="UF"
        placeholder="Selecione..."
        value={plan?.params?.ufs}
        onChange={handleChange.uf}
        options={options.uf}
        multiple
      />
      <AsyncSelect2
        label="Fabricante"
        value={plan?.params?.manufacturer}
        valueName={manufacturerName}
        onChange={(option) => {
          setManufacturerName(option?.name);
          setPlan((plan) => {
            return {
              ...plan, params: { ...plan.params, 
                manufacturer: option ? option.id : null
              }
            };
          });
        }}
        fetchData={getManufacturers && getManufacturers}
      />
      <br />
      <Input
        label="Canal"
        placeholder="Selecione..."
        value={toString(plan?.params?.channels ? plan?.params?.channels : [])}
        onChange={handleChange.canal}
        options={options.canal}
        multiple
      />
      <Input
        label="Data Histórico"
        type="date"
        value={plan?.historyDate}
        onChange={({ target }) =>
          setPlan({ ...plan,  historyDate: target.value })
        }
      />
    </>
  );
};

export default PowerBi;
