import React from "react";
import Button from "../components/Button";
import Loading from "../components/Loading";
import Table from "../components/Table";
import Template from "../components/Template";
import { Title } from "../components/Titles";
import useResearch from "../hooks/useResearch";

export default function ResearchPage() {
  const { loadAll, deleteOneQuestion } = useResearch();

  const [research, setResearch] = React.useState(null);

  const [state] = React.useState({
    loading: false,
    showResearch: false,
  });

  const loadResearch = async () => {
    if (research) return;
    let response = await loadAll();
    setResearch(response);
  };

  const deleteQuestion = async (id) => {
    try {
      await deleteOneQuestion(id);
      let response = await loadAll();
      setResearch(response);
    } catch (error) {}
  };

  React.useEffect(() => loadResearch());


  return !state?.loading ? (
    <>
      <Template>
        <Title>Pesquisa de Satisfação</Title>
        <Button
          type="button"
          layout="confirm"
          onClick={() => (window.location.href = "/nps/add")}
        >
          Adicionar Pesquisa
        </Button>
        <Table>
          <thead>
            <tr>
              <th className="align-center">Id</th>
              <th className="align-left">Pesquisa</th>
              <th className="align-left">Descrição</th>
              <th className="align-left">Status</th>
              <th className="align-left">Ações</th>
            </tr>
          </thead>
          <tbody>
            {research?.map(({ id, question, description, active }) => {
              return (
                <>
                  <tr key={id}>
                    <td className="align-center">#{id}</td>
                    <td className="align-left">{question}</td>
                    <td className="align-left">{description}</td>
                    <td className="align-left">
                      {" "}
                      {active ? "Ativo" : "Inativo"}{" "}
                    </td>
                    <td className="align-left">
                      <button
                      style={{"margin": "0 5px ",}} 
                        onClick={() =>
                          (window.location.href = "/nps/" + id)
                        }
                      >
                        Editar
                      </button>
                      <button onClick={() => deleteQuestion(id)}>
                        Inativar
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}

            {!research && (
              <>
                <tr>
                  <td colSpan="2">Nenhuma pesquisa cadastrada.</td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Template>
    </>
  ) : (
    <Loading />
  );
}
