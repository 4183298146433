import { createContext, ReactNode } from "react";
import styles from "./ui.module.css";

export const RowContext = createContext({ spacing: "10px" })

export default function Row(props: { children: ReactNode, spacing?: string }) {

  const spacing = props.spacing || "10px";

  return (
    <RowContext.Provider value={{ spacing }}>
      <div className={ styles.row } style={{ margin: `0 -${ spacing }` }}>
        { props.children }
      </div>
    </RowContext.Provider>
  )
}