import { FormEvent, useCallback, useState } from "react";
import { Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Input from "../../form/Input";
import useCategoryRegistration from "../useCategoryRegistration";
import { ChangeEvent } from "../../../service/product/useManufacturerManagement";
import { isEmpty } from "../../../../utils/validation";

export type AuntCategoryEditProps = {
  selectedAuntCategory: Option<null> | null;
}

export default function AuntCategoryEdit(props: AuntCategoryEditProps) {

  const { selectedAuntCategory } = props;

  const { saveAuntCategoryEdit } = useCategoryRegistration();
  
  const [ state, setState ] = useState({
    loading: false,
    id: selectedAuntCategory?.id,
    name: selectedAuntCategory?.name
  });

  const handleAuntCategoryOnChange = useCallback((e: ChangeEvent) => setState(prev => ({ ...prev, name: e.target.value })), [ setState ]);

  const handleSaveAuntCategoryEdit = useCallback((e: FormEvent) => baseHandleSaveAuntCategoryEdit(e, saveAuntCategoryEdit, state, setState), [ saveAuntCategoryEdit, state, setState ]);
  
  return(
    <>
      <form onSubmit={ handleSaveAuntCategoryEdit }>
        <Input
          onChange={ handleAuntCategoryOnChange }
          value={ state.name }
          name="name"
          placeholder="Digite..."
          label="Nome"
          addons={
            [ <Button key="1" type="info" disabled={ state.loading || isEmpty(state.name?.trim()) } submit>Salvar</Button> ]
          }
        />
      </form>
    </>
  )
}

async function baseHandleSaveAuntCategoryEdit(
  e: FormEvent,
  saveAuntCategoryEdit: (selectedAuntCategory: Option<null> | null) => Promise<void>,
  state: { loading: boolean, id: string | undefined, name: string | undefined },
  setState: React.Dispatch<React.SetStateAction<{ loading: boolean; id: string | undefined; name: string | undefined; }>>
  ) {
  setState(prev => ({ ...prev, loading: true }));
  e.preventDefault();
  if ( isEmpty(state.id!) || isEmpty(state.name!)) return;
  await saveAuntCategoryEdit({id: state.id!, name: state.name!})
  setState(prev => ({ ...prev, loading: false }));
  window.location.reload();
}