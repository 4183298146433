import { useMemo } from "react";
import { SubTitle } from "../../../components/Titles";
import Card from "../../../components/Card";
import Table from "../../../components/Table";
import Checkbox from "../../../components/Checkbox";
import { ActionButton, ActionsContainer } from "./styles";
import { FiPlusCircle } from "react-icons/fi";
import { FaTrash } from "react-icons/fa";
import useFiltersSKU from "../../../hooks/useFiltersSKU";
import CreateSKUModal from "./CreateSKUModal";
import DeleteSkUModal from "./DeleteSKUModal";

function SKUFilters() {
  const {
    setSelectedSKU,
    setOpenCreateSKUModal,
    setOpenDeleteModal,
    openCreateSKUModal,
    openDeleteModal,
    selectedSKU,
    skus,
    handleCheckboxClick,
  } = useFiltersSKU();

  const allSelected = useMemo(
    () => selectedSKU.length === skus.length,
    [selectedSKU, skus.length]
  );

  const handleSelectAll = () => {
    setSelectedSKU(allSelected ? [] : skus);
  };

  return (
    <>
      <div>
        <SubTitle>Filtro de SKU</SubTitle>

        <ActionsContainer>
          <ActionButton
            onClick={() => setOpenCreateSKUModal(!openCreateSKUModal)}
          >
            <FiPlusCircle />
          </ActionButton>
          <ActionButton
            disabled={selectedSKU.length === 0}
            onClick={() => setOpenDeleteModal(!openDeleteModal)}
          >
            <FaTrash />
            <span>{selectedSKU.length}</span>
          </ActionButton>
        </ActionsContainer>

        <Card>
          <Table>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    checked={allSelected}
                    onClick={() => handleSelectAll()}
                  />
                </th>
                <th>ID</th>
                <th>Descrição</th>
                <th>EAN</th>
              </tr>
            </thead>
            <tbody>
              {skus.length > 0 ? (
                skus.map((sku) => (
                  <tr key={sku.prodId}>
                    <td style={{ minWidth: "40px" }}>
                      <Checkbox
                        checked={selectedSKU.find(
                          (note) => note.prodId === sku.prodId
                        )}
                        onClick={(input) => handleCheckboxClick(input, sku)}
                      />
                    </td>
                    <td style={{ fontWeight: "bold", color: "#333333" }}>
                      {sku.prodId}
                    </td>
                    <td>{sku.description}</td>
                    <td>{sku.ean}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td>Nenhum registro encontrado</td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
      </div>

      {openDeleteModal && <DeleteSkUModal />}

      {openCreateSKUModal && <CreateSKUModal />}
    </>
  );
}

export default SKUFilters;
