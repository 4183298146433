import jwt from "jsonwebtoken";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { login } from "../authentication/login";
import { Button, Input, Logo, Template } from "../components/FrontPageForms";
import Modal from "../components/Modal";
import useUsers from "../hooks/useUsers";
import { authCredentials } from "../utils/authCredentials";

const Wrapper = styled.div`
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  text-align: center;
`;

const Text = styled.div`
  padding: 5px;
  color: white;
  font-weight: bolder;
  margin-top: -12px;
  font-size: 14px;
`;

const BackLink = styled.div`
  color: white;
  margin-top: 8px;
  user-select: none;
  cursor: pointer;
  font-size: 13px;

  :hover {
    text-decoration: underline;
  }
`;
const useLogin = () => {
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);

  const { user } = authCredentials.get.authUser();

  const closeErr = useCallback(() => setErr(null), [setErr]);

  return {
    user,
    loading,
    err,
    closeErr,

    login: async (credentials) => {
      setLoading(true);

      try {
        await login(credentials);
      } catch (err) {
        setLoading(false);

        if (err.response) {
          if (err.response.status === 401) {
            setErr(new Error("Usuário ou Senha estão incorretos"));
            return;
          } else if (err.response.status === 206) {
            setErr(new Error("Usuário ou Senha estão incorretos"));
            return;
          }
        }

        setErr(new Error("Ocorreu um erro ao tentar logar"));
      }
    },
  };
};

function ChangePasswordPage() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errMsg, setErrMsg] = useState(null);
  const { sendChangePassword } = useUsers();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { token } = useParams();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    try {
      if (password !== confirmPassword) {
        setErrMsg("As senhas precisam ser iguais.");
        return;
      }

      setLoading(true);
      await sendChangePassword({ password: password }, token);
      setLoading(false);
      const exp = jwt.decode(token);
      login({ user: exp.username, password });
      history.push("/dashboard");
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.data) {
        switch (err.response.data.error_type) {
          case "CREDENTIALS_NOT_FOUND":
            setErrMsg("Não existe nenhuma conta com este e-mail");
            return;
          default:
        }
      }
      setErrMsg("Um erro ocorreu");
    }
  };

  const handleOnModalClose = (e) => {
    setErrMsg(null);
  };

  return (
    <Template>
      {!errMsg ? null : <Modal close={handleOnModalClose}>{errMsg}</Modal>}
      <Wrapper>
        <form onSubmit={handleOnSubmit}>
          <Logo src="/static/logo-horus-w.png" />
          <Input
            placeholder="Digite uma nova Senha"
            password
            value={password}
            setValue={setPassword}
          />
          <Input
            placeholder="Repita a nova senha"
            password
            value={confirmPassword}
            setValue={setConfirmPassword}
          />
          <Button>{loading ? "Carregando..." : "Trocar a senha"}</Button>
          <BackLink onClick={() => history.push("/")}>Cancelar</BackLink>
        </form>
      </Wrapper>
    </Template>
  );
}

export default ChangePasswordPage;
