import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Template from "../../components/Template";
import styled from "styled-components";
import usePartner from "../../hooks/usePartner";
import useEventPartner from "../../hooks/useEventPartner";

import { Title } from "../../components/Titles";
import { isEmptyObj } from "../../utils";
import Modal from "../../components/Modal";

import { FiEdit3, FiFilter } from "react-icons/fi";
import UsagePartnerRelatory from "./UsagePartnerRelatory";
import CreatePartnerForm from "./CreatePartnerForm";
import SKUFilters from "./SKUFilters/index";
import { FiltersSKUProvider } from "../../hooks/useFiltersSKU";

const Wrapper = styled.div``;

const breadcrump = [
  {
    name: "Home",
    path: "/dashboard",
  },
  {
    name: "Parceiros",
    path: "/promotion-partner",
  },
  {
    name: "Perfil",
    path: "/promotion-partner",
  },
];

function ErrorHandler(status = 500) {
  const errors = {
    204: new Error(
      "Houve uma inconsistência nos dados. Favor repetir a operação."
    ),
    401: new Error("Atualização não autorizada."),
    500: new Error(
      "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
    ),
  };

  return errors[status];
}

function PartnerPage() {
  const { getAllPartners, getPartnerById, updatePartner } = usePartner();
  const { getEventPartnerById } = useEventPartner();
  const [eventPartners, setEventPartners] = useState(null);
  const [partners, setPartner] = useState({});
  const [newUserPartner, setNewUserPartner] = useState({});
  const [_, setLoading] = useState(true);
  const [eventloading, setEventLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [isSKUFilterOpen, setIsSKUFilterOpen] = useState(false);
  const { partner, partnerId } = useParams();

  const onUpdatePartner = async (userPartner) => {
    setLoading(true);
    try {
      await updatePartner(userPartner);
      const partnersResponse = await getPartnerById(partnerId);
      setPartner(partnersResponse.data);
      setMessage("Parceiro atualizado com sucesso!");
    } catch (error) {
      const err = ErrorHandler(error.response.status);

      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isEmptyObj(partners)) {
      getPartnerById(partnerId).then((response) => {
        setPartner(response.data);
        setNewUserPartner(response.data);
        setLoading(false);
      });
    }
  }, [
    partners,
    setPartner,
    setLoading,
    getAllPartners,
    getPartnerById,
    partnerId,
  ]);

  useEffect(() => {
    if (!eventPartners) {
      getEventPartnerById(1, partner).then((response) => {
        setEventPartners(response.data);
        setEventLoading(false);
      });
    }
  }, [
    eventPartners,
    setEventPartners,
    partner,
    getEventPartnerById,
    setEventLoading,
  ]);

  return (
    <>
      {message && <Modal close={() => setMessage(null)}>{message}</Modal>}
      {error && <Modal close={() => setError(null)}>{error.message}</Modal>}

      <Title>Parceiro - {partners.firstName}</Title>
      <Wrapper>
        <CreatePartnerForm
          onUpdatePartner={onUpdatePartner}
          partners={partners}
          isSKUFilterOpen={isSKUFilterOpen}
          setIsSKUFilterOpen={setIsSKUFilterOpen}
          setPartner={setPartner}
          newUserPartner={newUserPartner}
          setNewUserPartner={setNewUserPartner}
        />
      </Wrapper>
      <FiltersSKUProvider partners={partners}>
        {isSKUFilterOpen && <SKUFilters />}
      </FiltersSKUProvider>
      {!isSKUFilterOpen && (
        <UsagePartnerRelatory
          style={{ "margin-top": "10px" }}
          onUpdatePartner={onUpdatePartner}
          eventPartners={eventPartners}
          setEventPartners={setEventPartners}
          getEventPartnerById={getEventPartnerById}
          eventloading={eventloading}
          setEventLoading={setEventLoading}
          setError={setError}
          setMessage={setMessage}
          partner={partner}
        />
      )}
    </>
  );
}

export default PartnerPage;
