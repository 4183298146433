import React, { useEffect } from "react";
import Button from "../components/Button";
import Collapse from "../components/Collapse";
import { Col, Row } from "../components/Grid";
import { Title } from "../components/Titles";
import SaveProductBrandForm from "../components/productBrand/form/SaveProductBrandForm";
import BrandDisplay from "../components/productBrand/list";
import Search from "../components/productBrand/search";

import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { authCredentials } from "../utils/authCredentials";

export default function ProductBrandPage() {
  const [brand, setBrand] = React.useState(null);
  const { createModal } = useModal();
  const { createEvent } = useHorusAccess();

  //Helpers
  const goToEdit = async (brandToUpdate) => {
    createModal(
      <SaveProductBrandForm
        brandComplete={brandToUpdate}
        onSavedBrand={null}
      />,
      { width: "900px", backDropClose: false }
    );
  };

  // Log access event
  useEffect(() => {
    let event = {};
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access product brands";

    createEvent(event);
  }, []);

  return (
    <>
      <Title>Marcas de Produtos</Title>
      <Row>
        <Col width="20%">
          <Search brand={brand} setBrand={setBrand} goToEdit={goToEdit} />
        </Col>
        <Col width="20%">
          <Button
            style={{ marginTop: "12px" }}
            type="button"
            layout="info"
            onClick={() => {
              setBrand(null);
              goToEdit();
            }}
          >
            Criar Nova Marca de Produto
          </Button>
        </Col>
      </Row>
      <Collapse collapsed={!brand}>
        <BrandDisplay brand={brand} goToEdit={goToEdit} />
      </Collapse>
    </>
  );
}
