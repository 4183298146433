import AsyncSelect from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Select from "../../form/Select";
import ValidationMessages from "../../form/ValidationMessages";
import { SectionTitle } from "../../ui/SectionTitle";
import EditProductCategoryHeader from "./EditProductCategoryHeader";
import LoadingCategory from "./LoadingCategory";
import useProductCategoryCreate from "./useProductCategoryCreatePage";

const volumeUnities = [
  { id: "Und", name: "Unidade" },
  { id: "Lt", name: "Litro" },
  { id: "Kg", name: "Quilo" },
];

interface Props {
  edit?: boolean;
}

function ProductCategoryCreatePage(props: Props) {
  const { edit = false } = props;

  const productCategoryCreate = useProductCategoryCreate(edit);

  const {
    validation,
    showForm,
    form,
    sendingCategory,
    fetchingCategory,
    buttonText,
    selectedFatherCategory,
    selectedEditCategory,
    handleEditCategoryOnChange,
    handleFormOnChange,
    fetchProductCategoryOpts,
    handleProductCategoryOnChange,
    handleSendButtonOnClick,
  } = productCategoryCreate;

  return (
    <div>
      {!edit ? (
        <SectionTitle
          title="Criar Segmento"
          description="Criar um novo segmento"
        />
      ) : (
        <div>
          <SectionTitle
            title="Editar Segmento"
            description="Alterar um segmento já existente"
          />
          <AsyncSelect
            fetchData={fetchProductCategoryOpts}
            onChange={handleEditCategoryOnChange}
            value={selectedEditCategory}
            label="Selecione o segmento para editar"
          />
        </div>
      )}
      {fetchingCategory ? (
        <LoadingCategory />
      ) : !showForm ? null : (
        <>
          <EditProductCategoryHeader {...productCategoryCreate} />

          <Input
            label="Novo Nome"
            name="description"
            value={form.description}
            onChange={handleFormOnChange}
          />

          <ValidationMessages messages={validation.description} />

          <AsyncSelect
            fetchData={fetchProductCategoryOpts}
            onChange={handleProductCategoryOnChange}
            value={selectedFatherCategory}
            label="Categoria Pai"
          />

          <ValidationMessages messages={validation.fatherCategoryId} />

          <Select
            options={volumeUnities}
            value={form.volumeUnity}
            onChange={handleFormOnChange}
            label="Unidade de Volume"
            name="volumeUnity"
          />

          <ValidationMessages messages={validation.volumeUnity} />

          <Button
            type="confirm"
            disabled={validation.errors > 0 || sendingCategory}
            onClick={handleSendButtonOnClick}
          >
            {buttonText}
          </Button>
        </>
      )}
    </div>
  );
}

export default ProductCategoryCreatePage;
