import React from 'react'
import Template from '../components/Template'
import styled from 'styled-components'

const Title = styled.h1`

`

const breadcrump = [
  {
    name: "Início", path: "/"
  },
  {
    name: "Resultados de Análise", path: "/results"
  }
]

function ResultsPage() {
  return (
    <Template breadcrump={ breadcrump }>
      <Title>Resultados de Análise</Title>
    </Template>
  )
}

export default ResultsPage