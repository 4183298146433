import Select2 from "../../AsyncSelect2";
import Input from '../../Input';

export default function  ClientPlans({ client, clientUser, listClientUser, listClient, handleChange, handleChangeUser }) {

  return (
    <>
      <div>
        <Select2
          label="Cliente"
          value={client?.id}
          valueName={client?.name}
          onChange={handleChange}
          fetchData={listClient}
        />
      </div>
      {
        listClientUser?.length === 0 ? null : (
          <div>
            <Input
              label="Responsável"
              value={clientUser}
              onChange={e => handleChangeUser(e.target.value)}
              options={
                [{id: null, name: "Selecione..."}, ...listClientUser?.map((user, key) => ({id: user.id, name: user.email, key}))]
              }
            />
          </div>
        )
      }
    </>
  );
}
