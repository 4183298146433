
const addDay = (date, increment) => {
    if (!date instanceof Date) {
        return null;
    }
    date.setDate(date.getDate() + increment);

    return date.getDate() +"/"+ date.getMonth() +"/"+ date.getFullYear();
}


const decrementDay = (date, decrement) => {
    if (!date instanceof Date) {
        return null;
    }
    date.setDate(date.getDate() - decrement);
    return date.getDate() +"/"+ (date.getMonth() + 1 ) +"/"+ date.getFullYear();
}

const parseToDate = (date) => {
    if (!date) {
        return null;
    }
    let newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1)
    return newDate.getDate() +"/"+ (newDate.getMonth() + 1 ) +"/"+ newDate.getFullYear();
}

export { addDay, decrementDay, parseToDate }