import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Generic from "../components/Generic";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import useGroup from "../hooks/useGroup";
import useHorusAccess from "../hooks/useHorusAccess";
import useUsers from "../hooks/useUsers";
import { isEmptyObj, isNotEmpty, makeValidator } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const Wrapper = styled.div``;

const userValidator = makeValidator({
  name: isNotEmpty("Nome"),
});

function UpdateGroupForm(props) {
  const [collapsed, setCollapsed] = useState(true);
  const { onUpdateGroup } = props;
  const { value: newGroup, onChange: setNewGroup } = props;
  const { getNameErrors, getAllErrors } = userValidator;

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          window.confirm(
            "Atualizar grupo: " +
              newGroup.name +
              "?\n\nATENÇÃO: Certifique-se que todos os dados estão corretos."
          )
        ) {
          onUpdateGroup(newGroup);
          setCollapsed(true);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Atualizar Nome do Grupo</SubTitle>
        <Card>
          <Input
            label="Nome"
            value={newGroup.name}
            onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
            validate={getNameErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Atualizar Grupo
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newGroup)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

function AttachUserForm(props) {
  const { onAttachUser } = props;
  const [groupUser, setGroupUser] = useState({});
  const [users, setUsers] = useState(null);
  const [collapsedUser, setCollapsedUser] = useState(true);
  const [loading, setLoading] = useState(true);
  const { getAllUsers } = useUsers();
  const userValidator = makeValidator({
    userIds: isNotEmpty("Usuários"),
  });
  const { getUserIdsErrors, getAllErrors } = userValidator;

  useEffect(() => {
    if (!users) {
      getAllUsers().then((response) => {
        setUsers(response.data);
        setLoading(false);
      });
    }
  });

  return (
    <>
      {!loading ? (
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
            if (
              window.confirm(
                "Tem certeza que deseja vincular os usuários selecionados?"
              )
            ) {
              onAttachUser(groupUser);
              setCollapsedUser(true);
            }
          }}
        >
          <Collapse collapsed={collapsedUser}>
            <SubTitle>Vincular usuários</SubTitle>
            <Card>
              <Input
                label="Usuários"
                value={groupUser.userIds}
                placeholder="Selecione..."
                onChange={(userIds) => setGroupUser({ ...groupUser, userIds })}
                options={(users || []).map((user, index) => {
                  return {
                    id: user.id,
                    name: user.username + " - " + user.email,
                  };
                })}
                multiple
                validate={getUserIdsErrors}
              />
            </Card>
          </Collapse>
          {collapsedUser ? (
            <Button
              type="button"
              layout="info"
              onClick={() => setCollapsedUser(false)}
            >
              Vincular usuários
            </Button>
          ) : (
            <>
              <Button layout="confirm" disabled={getAllErrors(groupUser)}>
                Enviar
              </Button>{" "}
              <Button
                type="button"
                layout="cancel"
                onClick={() => setCollapsedUser(true)}
              >
                Cancelar
              </Button>
            </>
          )}
        </form>
      ) : null}
    </>
  );
}

function SingleGroupPage() {
  const { groupId } = useParams();
  const {
    getAllGroupUsersById,
    updateGroup,
    getGroupById,
    deleteGroupUser,
    attachGroupUsers,
  } = useGroup();
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [groupUsers, setGroupUsers] = useState(null);
  const [newGroup, setNewGroup] = useState({});
  const history = useHistory();
  const { createEvent } = useHorusAccess();
  const [event] = useState({});
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const currentGroupUsers = groupUsers
    ? groupUsers.slice(indexFirstItem, indexLastItem)
    : {};
  const totalItems = groupUsers ? groupUsers.length : 0;

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const breadcrump = [
    {
      name: "Início",
      path: "/dashboard",
    },
    {
      name: "Grupos",
      path: "/groups",
    },
    {
      name: groupId,
      path: "/groups/" + groupId,
    },
  ];

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access group id " + groupId;

    createEvent(event);
  }, [groupId]);

  const onUpdateGroup = async (data) => {
    setLoading(true);
    try {
      const payload = { ...data, id: groupId };
      await updateGroup(payload);
      const groupResponse = await getGroupById(groupId);
      setGroup(groupResponse.data);
      setMessage("Grupo atualizado com sucesso.");
    } catch (error) {
      let err;
      setError(
        error.response && error.response.status === 400
          ? new Error("Grupo já existe")
          : error
      );
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = async (groupUserId) => {
    setLoading(true);
    try {
      await deleteGroupUser(groupUserId);
      const groupUsersResponse = await getAllGroupUsersById(groupId);
      setGroupUsers(groupUsersResponse.data);
      setMessage("Usuário desvinculado.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const onAttachUser = async (groupUser) => {
    setLoading2(true);
    try {
      groupUser.groupId = groupId;
      await attachGroupUsers(groupUser);
      const groupUsersResponse = await getAllGroupUsersById(groupId);
      setGroupUsers(groupUsersResponse.data);
      setMessage("Grupo atrelado ao usuário com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : error;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading2(false);
    }
  };

  useEffect(() => {
    if (!groupUsers) {
      getAllGroupUsersById(groupId).then((response) => {
        setGroupUsers(response.data);
        setLoading2(false);
      });
    }
  }, [groupUsers, setGroupUsers, setLoading2, getAllGroupUsersById, groupId]);

  useEffect(() => {
    if (!group) {
      getGroupById(groupId).then((response) => {
        setGroup(response.data);
        setNewGroup(response.data);
        setLoading(false);
      });
    }
  }, [group, setGroup, setLoading, getGroupById, groupId]);

  return (
    <Template breadcrump={breadcrump}>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Visão do Grupo</Title>
      <Wrapper>
        <UpdateGroupForm
          onUpdateGroup={onUpdateGroup}
          value={newGroup}
          onChange={setNewGroup}
        />
        <br />
        <AttachUserForm onAttachUser={onAttachUser} />
        <div>
          {!loading ? (
            isEmptyObj(groupUsers) ? (
              <SubTitle>Nenhum usuário atrelado ao grupo {group.name}</SubTitle>
            ) : (
              <SubTitle>Usuários atrelados ao grupo {group.name}</SubTitle>
            )
          ) : (
            <Card>
              <Loading style={{ margin: "20px auto" }} />
            </Card>
          )}
        </div>
        {!loading2 ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Usuário</th>
                  <th>Empresa</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentGroupUsers.map((groupUser, index) => {
                  return (
                    <Generic key={index}>
                      <tr>
                        <td>
                          {groupUser.userFirstName} {groupUser.userLastName}
                        </td>
                        <td>{groupUser.username}</td>
                        <td>{groupUser.company}</td>
                        <td>
                          <button
                            type="button"
                            layout="delete"
                            style={{ cursor: "pointer" }}
                            onClick={(e) =>
                              history.push("/users/" + groupUser.userId)
                            }
                          >
                            Visualizar usuário
                          </button>
                          <Button
                            small
                            type="button"
                            layout="error"
                            style={{ "margin-left": "2px", cursor: "pointer" }}
                            onClick={(e) => {
                              if (
                                window.confirm(
                                  "Remover usuário " +
                                    groupUser.username +
                                    " do grupo?"
                                )
                              ) {
                                handleOnDeleteClick(groupUser.id);
                              }
                            }}
                          >
                            <i className="fa fa-trash" />
                          </Button>
                        </td>
                      </tr>
                    </Generic>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          <Card>
            <Loading style={{ margin: "20px auto" }} />
          </Card>
        )}
      </Wrapper>
    </Template>
  );
}

export default SingleGroupPage;
