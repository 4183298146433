import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { http } from "../api/http";
import { companyUrl } from "../constants";
import useModal from "../hooks/useModal";

const CompanyServiceContext = createContext();

export function useCompanyService() {
  return useContext(CompanyServiceContext);
}

export function useCompanyCategories() {
  const { categories, loadCategories } = useContext(CompanyServiceContext);

  useEffect(() => loadCategories(), [loadCategories]);

  return { categories };
}

function CompanyService(props) {
  const { createModal } = useModal();

  const [{ categories }, setState] = useState({
    categories: null,
  });

  // # Companies

  const getCompanyByCnpj = (cnpj) =>
    http.get(companyUrl + "/api/v1/company/" + cnpj);

  const getCompanyQueue = ({ queue, total }) =>
    http
      .get(companyUrl + "/api/v1/company/queue/" + queue + "/" + total)
      .then((response) => ({ queue: response.data.queue, response }));

  const updateCompany = (company, isBigFranchise) =>
    http.post(companyUrl + "/api/v1/company", company, {
      params: { isBigFranchise },
    });

  const getTopSales = (id) =>
    http.get(companyUrl + "/api/v1/company/sales/" + id);

  // # Economic Groups

  const getEconomicGroups = (params) =>
    http.get(companyUrl + "/api/v1/economic-group", { params });

  // # Brands

  const getBrands = (params) => get(companyUrl + "/api/v1/brand", { params });

  const getBrand = (id, options = {}) => {
    const { countCompanies } = options;
    const params = {};

    if (countCompanies) params.count_companies = countCompanies;

    return http.get(companyUrl + "/api/v1/brand/" + id, { params });
  };

  const saveBrand = (brand) => {
    const formData = new FormData();

    if (brand.id) formData.append("id", brand.id);
    if (brand.description) formData.append("description", brand.description);
    if (brand.economicGroup)
      formData.append("economicGroup", brand.economicGroup);
    if (brand.grandeRede) formData.append("grandeRede", brand.grandeRede);
    if (brand.cnpj8) formData.append("cnpj8", brand.cnpj8);
    if (brand.categoryId) formData.append("categoryId", brand.categoryId);
    if (brand.channelId) formData.append("channelId", brand.channelId);
    if (brand.file) formData.append("file", brand.file);

    return http.post(companyUrl + "/api/v1/brand", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };

  const removeBrand = useCallback(
    async (brandId) => {
      try {
        await http.delete(companyUrl + "/api/v1/brand/" + brandId);
        createModal(
          <div className="align-center">
            <p>Marca removida com sucesso.</p>
            <br />
            <br />
          </div>,
          { replace: true }
        );
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar remover a marca.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  // # Channels

  const getChannels = () => http.get(companyUrl + "/api/v1/channel");

  // # Categories

  const getCategories = () => http.get(companyUrl + "/api/v1/category");

  const loadCategories = useCallback(async () => {
    if (categories) return;
    const response = await getCategories();
    setState((prev) => ({ ...prev, categories: response.data.categories }));
  }, [categories, setState, getCategories]);

  // # Setor Censitário

  const getSetorCencitarioByLatLng = (latLng) =>
    http.get(companyUrl + "/api/v1/setor-censitario", { params: latLng });

  const value = {
    getEconomicGroups,
    getCompanyByCnpj,
    getSetorCencitarioByLatLng,
    updateCompany,
    getBrands,
    getBrand,
    getChannels,
    getCategories,
    saveBrand,
    loadCategories,
    categories,
    getTopSales,
    getCompanyQueue,
    removeBrand,
  };

  return (
    <CompanyServiceContext.Provider value={value}>
      {props.children}
    </CompanyServiceContext.Provider>
  );
}

export default CompanyService;
