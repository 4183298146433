import { useCallback } from "react";
import useProductRequests from "./useProductRequests";

export default function useUpdateProductPage() {

  const { updateProductWithCsv } = useProductRequests();

  const handleSendFile = useCallback(async (file: File) => {
    await updateProductWithCsv(file);
  }, [ updateProductWithCsv ]);

  return { handleSendFile }

}