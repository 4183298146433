import React from "react";
import { isNotEmpty, makeValidator } from "../../../utils";
import Input from "../../Input";

export { default as FGV } from './form-fgv'
export { default as BASIC } from './form-basic'
export { default as POWERBI } from './form-powerbi'
export { default as PROFESSIONAL } from './form-professional'
export { default as METRICSWEB } from './form-metricsweb'

export default function FormFields({ plan, setPlan, children }) {  

  const options = {
    status: [
      { id: null, name: "Sem Status" },
      { id: "PENDING", name: "PENDING" },
      { id: "APPROVED", name: "APPROVED" },
      { id: "REJECTED", name: "REJECTED" },
      { id: "CANCELED", name: "CANCELED" },
    ],
  };


  const validator = makeValidator({
    value: isNotEmpty("Valor"),
  });

  const { getValueErrors } = validator;

  const createAtExist = () => !!plan?.createdAt;

  return (
    <>
      {plan && (
        <div>
          <Input
            type="number"
            label="Valor"
            value={plan?.value}
            validate={getValueErrors}
            onChange={({ target }) => setPlan({ ...plan, value: target.value })}
          />

          <Input
            label="Status"
            value={plan?.status}
            onChange={({ target }) =>
              setPlan({ ...plan, status: (target.value === "null" ? null : target.value) })
            }
            options={options.status}
          />

          {children}

          {createAtExist && (
            <Input
              label="Data de Criação"
              readOnly
              type="datetime-local"
              value={plan?.createdAt}
            />
          )}

          <Input
            label="Data de Finalização"
            type="datetime-local"
            value={plan?.finishedAt}
            onChange={({ target }) =>
              setPlan({ ...plan, finishedAt: target.value })
            }
          />
        </div>
      )}
    </>
  );
}
