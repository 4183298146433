import Button from "../form/Button";
import Input from "../form/Input";
import useDashboardListPage from "./useDashboardListPage";
import styles from "../ui/ui.module.css";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import { HSpacer } from "../ui/HSpacer";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";

function DashboardListPage() {

  const {
    handleFindOnSubmit, handleOnChange, handlePageOnChange, filter, items, totalPages, page,
    handleEditOnClick, handleOnDetailsClick
  } = useDashboardListPage();

  return (
    <div>
      <SectionTitle
        title="Listagem de Dashboards"
        description="Consultar dashboards cadastrados"
      />
      <form onSubmit={ handleFindOnSubmit }>
        <Input
          onChange={ handleOnChange }
          value={ filter }
          name="filter"
          placeholder="Digite..."
          label="Filtre dashboards por descrição"
          addons={
            [ <Button key="1" type="info" submit>Pesquisar</Button> ]
          }
        />
      </form>
      <TableResponsiveWrapper>
        <table className={ styles.table }>
          <thead>
            <tr>
              <th>Ações</th>
              <th>ID</th>
              <th className={ styles.left }>Link</th>
              <th className={ styles.left }>Descrição</th>
              <th>Clientes</th>
              <th>Grupos</th>
              <th>Usuários</th>
            </tr>
          </thead>
          <tbody>
            {
              !items ? (
                <tr><td colSpan={ 7 }>Carregando....</td></tr>
              ) : (
                items.length === 0 ? (
                  <tr><td colSpan={ 7 }>Sem resultados</td></tr>
                ) : (
                  items.map(item => (
                    <tr key={ item.id }>
                      <td>
                        <Button transparent small type="confirm" onClick={ () => handleOnDetailsClick(item.id) }>
                          Ver Usuários com Acesso
                        </Button>
                        <HSpacer />
                        <Button transparent small type="warning" onClick={ () => handleEditOnClick(item.id) }>
                          Editar
                        </Button>
                      </td>
                      <td>
                        { item.id }
                      </td>
                      <td className={ styles.left }>
                        { item.link }
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { item.description }
                      </td>
                      <td>
                        { item.clientAmount }
                      </td>
                      <td>
                        { item.groupAmount }
                      </td>
                      <td>
                        { item.userAmount }
                      </td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handlePageOnChange } />
    </div>
  )
}

export default DashboardListPage;