import {
  FormEvent,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useReportRequests, {
  GetServiceReports,
  ServiceReportView,
} from "../../service/report/useReportRequests";
import { SetState } from "../../types";
import Button from "../form/Button";
import Input from "../form/Input";
import { SectionTitle } from "../ui/SectionTitle";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";
import reportsStyles from "./reports.module.css";

export default function ServiceReportsPage() {
  const refreshHandleRef = useRef<any>();

  const [state, setState] = useState(initialState);

  const { services, filter } = state;

  const { getServiceReports, removeOnClick } = useReportRequests();

  const { openModal, closeModal } = useModal();

  const handleOnChange = useHandleOnChange(setState);

  const loadReport = (filter: string | null) =>
    baseLoadReport(
      getServiceReports,
      setState,
      openModal,
      refreshHandleRef,
      filter
    );

  const handleOnSearchFormSubmit = useCallback(
    (e: FormEvent) => {
      e.preventDefault();
      loadReport(filter);
    },
    [filter]
  );

  const deleteReport = (key: string) =>
    baseDeleteOnClick(key, removeOnClick, loadReport, openModal);

  const handleDeleteOnClick = (key: string) =>
    removeReportConfirmation(deleteReport, key, openModal, closeModal);

  useEffect(
    () => () => clearInterval(refreshHandleRef.current),
    [refreshHandleRef]
  );

  useEffect(() => {
    loadReport(null);
  }, []);

  return (
    <div>
      <SectionTitle
        title="Estado dos Serviços"
        description="Consulte se os serviços estão ativos"
      />
      <form onSubmit={handleOnSearchFormSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Filtre por Serviço"
          addons={[
            <Button key="1" submit type="confirm">
              Pesquisar
            </Button>,
          ]}
        />
      </form>
      {!services ? (
        "Carregando..."
      ) : (
        <TableResponsiveWrapper>
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles.left}>Ações</th>
                <th className={styles.left}>Serviço</th>
                <th className={`${styles.fullWidth} ${styles.left}`}>
                  Descrição
                </th>
                <th>Status</th>
                <th>Último Report</th>
              </tr>
            </thead>
            <tbody>
              {services.map((service) => (
                <tr key={service.key}>
                  <td>
                    <div className={`${reportsStyles["tooltip"]}`}>
                      <Button
                        transparent
                        small
                        onClick={() => handleDeleteOnClick(service.key)}
                      >
                        X
                      </Button>
                      <span className={`${reportsStyles["tooltiptext"]}`}>
                        Remover
                      </span>
                    </div>
                  </td>
                  <td className={styles.left}>{service.key}</td>
                  <td className={`${styles.fullWidth} ${styles.left}`}>
                    {service.serviceDescription +
                      (service.lastDescription
                        .replace("{", "")
                        .replace("}", "") === ""
                        ? ""
                        : " - " + service.lastDescription)}
                  </td>
                  <td>
                    <span className={getClassName(service)}>
                      {getStatus(service)}
                    </span>
                  </td>
                  <td>{formatDate(service.lastReportDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableResponsiveWrapper>
      )}
    </div>
  );
}

interface State {
  services: ServiceReportView[] | null;
  filter: string | null;
}

const initialState: State = {
  services: null,
  filter: null,
};

async function baseLoadReport(
  getServiceReports: GetServiceReports,
  setState: SetState<State>,
  openModal: OpenModal,
  intervalRef: MutableRefObject<any>,
  filter: string | null
) {
  const fn = async () => {
    const { results: services } = await getServiceReports({
      filter: filter === null ? undefined : "%25" + filter + "%25",
    });
    setState((prev) => ({ ...prev, services }));
  };

  try {
    await fn();
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(fn, 60000);
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseDeleteOnClick(
  key: string,
  removeOnClick: (key: string) => Promise<void>,
  loadReport: { (filter: string | null): Promise<void>; (arg0: null): any },
  openModal: { (node: ReactNode): void; (arg0: JSX.Element): void }
) {
  try {
    await removeOnClick(key);
    await loadReport(null);
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function removeReportConfirmation(
  deleteReport: (key: string) => void,
  key: string,
  openModal: OpenModal,
  closeModal: () => void
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá deletar o relatório: {key}.<br />
        Confirma?
      </div>
      <Button
        type="cancel"
        onClick={() => {
          closeModal();
          deleteReport(key);
        }}
      >
        Sim
      </Button>{" "}
      <Button type="confirm" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

function formatDate(date: string) {
  return date.replaceAll(
    /^.*?(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+).*$/g,
    "$3/$2/$1 $4:$5:$6"
  );
}

function getStatus(service: ServiceReportView) {
  return service.pendingReport ? "DOWN" : service.lastReportStatus;
}

function getClassName(service: ServiceReportView) {
  const status = getStatus(service);

  if (status === "UP") {
    return reportsStyles.up;
  }
  if (status === "DOWN") {
    return reportsStyles.down;
  }
  return reportsStyles.other;
}
