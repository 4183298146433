import React, { useState, useRef, useEffect } from "react";
import { FiCheck } from "react-icons/fi";
import { CheckboxBox, CheckboxContainer, CheckboxLabel } from "./styles";

function Checkbox({ onClick, name, checked, children, ...props }) {
  const [isChecked, setIsChecked] = useState(false);

  const checkboxRef = useRef(null);

  const handleClick = (ev) => {
    setIsChecked(!isChecked);
    checkboxRef.current.checked = !isChecked;
    onClick && onClick(checkboxRef.current, ev);
  };

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <>
      <CheckboxContainer onClick={handleClick}>
        <CheckboxBox isChecked={isChecked}>
          {isChecked && <FiCheck />}
        </CheckboxBox>
        <CheckboxLabel>{children}</CheckboxLabel>
        <input
          type="checkbox"
          style={{ display: "none" }}
          ref={checkboxRef}
          name={name}
          {...props}
        />
      </CheckboxContainer>
    </>
  );
}

export default Checkbox;
