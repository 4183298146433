import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { Paginated } from "../../types";
import {
  DashboardAssociation,
  DashboardAssociationForm,
  DashboardAssociationSearchItem,
} from "./types";

export type UseDashboardAssociationRequests =
  typeof useDashboardAssociationRequests;

export type FindByTargetIdAndDashboardId =
  ReturnType<UseDashboardAssociationRequests>["findByTargetIdAndDashboardId"];
export type FindById = ReturnType<UseDashboardAssociationRequests>["findById"];
export type Save = ReturnType<UseDashboardAssociationRequests>["save"];
export type Search = ReturnType<UseDashboardAssociationRequests>["search"];

export default function useDashboardAssociationRequests() {
  const findByTargetIdAndDashboardId = (
    target: string | null,
    id: number | null,
    dashboardId: number
  ) => baseByTargetIdAndDashboardId(target, id, dashboardId);

  const findById = (id: number) => baseById(id);

  const save = (association: DashboardAssociationForm) => baseSave(association);

  const search = (params: SearchParams) => baseSearch(params);

  return { findByTargetIdAndDashboardId, findById, save, search };
}

async function baseByTargetIdAndDashboardId(
  target: string | null,
  id: number | null,
  dashboardId: number
): Promise<DashboardAssociation> {
  const response = await http.get(baseUrl + "/api/v1/dashboard/association", {
    params: { [target + "Id"]: id, dashboardId },
  });
  return response?.data;
}

async function baseById(id: number): Promise<DashboardAssociation> {
  const response = await http.get(
    baseUrl + "/api/v1/dashboard/association/" + id
  );
  return response.data;
}

async function baseSave(
  association: DashboardAssociationForm
): Promise<{ id: number }> {
  const response = await http.post(
    baseUrl + "/api/v1/dashboard/association",
    association
  );
  return response.data;
}

export interface SearchParams {
  dashboardId?: string;
  clientId?: string;
  userId?: string;
  groupId?: string;
  page?: number;
}

async function baseSearch(
  params?: SearchParams
): Promise<Paginated<DashboardAssociationSearchItem>> {
  const response = await http.get(
    baseUrl + "/api/v1/dashboard/association/report",
    { params }
  );
  return response.data;
}
