import styled from 'styled-components';
import { mediaPrefix } from "../../../../../../constants";
import Table from "../../../../../../components/Table"

const BrandImage = styled.img`
  max-width: 50px;
`;

export default function DisplayProductList({ items }) {
  return (
    <Table>
      <thead>
        <tr>
          <th className="align-center">Imagem</th>
          <th className="align-center">EAN</th>
          <th>Descrição</th>
          <th className="align-center">Marca</th>
          <th className="align-center">Fabricante</th>
          <th className="align-center">Categoria Produto</th>
          <th className="align-center">Quantidade</th>
          <th className="align-center">Pack</th>
          <th className="align-center">Status</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map(product => (
            <tr key={ product.id }>
              <td className="align-center">
                { product.file ? <BrandImage src={ mediaPrefix + product.file } /> : "Sem Imagem" }
              </td>
              <td className="align-center"> { product.ean }</td>
              <td className="limit-size">{ product.description }</td>
              <td className="limit-size">{ product.brand }</td>
              <td className="limit-size">{ product.manufacturer }</td>
              <td className="limit-size">{ product.category }</td>
              <td className="align-center">{ product.quantity }</td>
              <td className="align-center">{ product.quantityPerPack }</td>
              <td className="align-center">{ product.status }</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );
}