import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { isEan } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import Button from "../../form/Button";
import Input from "../../form/Input";
import TextArea from "../../form/TextArea";
import { SectionTitle } from "../../ui/SectionTitle";
import Tabs from "../../ui/Tabs";
import styles from "../../ui/ui.module.css";

type Props = {};

type Params = {
  partnerUsername: string; // partnerUsername
  partnerName: string; //partnerName
};

interface InputData {
  ean: string;
  description: string;
}

const PartnerFiltersPage: React.FC<Props> = () => {
  const [tab, setTab] = useState("filter-list");

  const { partnerName, partnerUsername } = useParams<Params>();

  const [loading, setLoading] = useState(false);

  const [filterData, setPartnerData] = useState<FilterData[]>([]);

  const { openModal, closeModal } = useModal();

  const fetchPartnerData = useCallback(
    () =>
      baseFetchPartnerData({
        partnerName,
        partnerUsername,
        openModal,
        setLoading,
        setPartnerData,
      }),
    [openModal, partnerName, partnerUsername]
  );

  const openDeleteFilterModal = (filter: FilterData) =>
    openModal(
      <FilterDeleteModal
        partnerName={partnerName}
        partnerUsername={partnerUsername}
        filter={filter}
        openModal={openModal}
        closeModal={closeModal}
        fetchPartnerData={fetchPartnerData}
      ></FilterDeleteModal>
    );

  const openEditFilterModal = (filter: FilterData) =>
    openModal(
      <EditFilterModal
        partnerName={partnerName}
        partnerUsername={partnerUsername}
        filter={filter}
        openModal={openModal}
        closeModal={closeModal}
        fetchPartnerData={fetchPartnerData}
      ></EditFilterModal>
    );

  const [textareaValue, setTextareaValue] = useState("");

  const parseInputData = () => {
    const items = textareaValue.split(";").filter((item) => item.trim() !== "");

    return items.map((item) => {
      const parts = item.trim().split(",");
      const ean = parts[0].trim();
      const description = parts.slice(1).join(",").trim();

      if (!isEan(ean)) {
        throw new Error("EAN inválido: " + ean);
      }

      return { ean, description } as InputData;
    });
  };

  async function handleConfirmClick() {
    setLoading(true);
    let result;
    try {
      const parsedInputs = parseInputData();
      result = await baseHandleOnAddFilterClick(
        openModal,
        partnerName,
        partnerUsername,
        parsedInputs
      );
      setLoading(false);
      await fetchPartnerData();
    } catch (e) {
      setLoading(false);
      openModal(getErrorMessage(e));
    } finally {
      if (result) {
        setTextareaValue("");
      }
    }
  }

  useEffect(() => {
    fetchPartnerData();
  }, [fetchPartnerData]);

  return (
    <div>
      <SectionTitle
        title={`Gerenciar filtros do Parceiro - ${partnerName}`}
        description={`Adicione ou remova os filtros do parceiro.`}
      />

      <Tabs
        options={[
          { name: "Lista de Filtros", link: "filter-list" },
          { name: "Criar Filtros", link: "filter-save" },
        ]}
        onSelectTab={(o) => setTab(o.link)}
        tab={tab}
      />

      {tab !== "filter-save" ? null : (
        <>
          <div>
            <p>
              Digite o EAN seguido da descrição, separados por vírgula. Para
              adicionar múltiplos itens, separe-os com ponto e vírgula.
            </p>
            <p>
              Exemplo: 1234567890123,Descrição do item;2345678901234,Outra
              descrição;
            </p>
          </div>

          <div style={{ margin: "10px 0" }}>
            <TextArea
              label="EAN e Descrição"
              onChange={(e) => setTextareaValue(e.target.value)}
              minHeight="300px"
              value={textareaValue}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={handleConfirmClick}
              type="confirm"
              disabled={loading}
            >
              {loading ? "Aguarde..." : "Cadastrar"}
            </Button>
          </div>
        </>
      )}
      {tab !== "filter-list" ? null : (
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={`${styles.flexCenter}`}>Ações</th>
              <th>EAN</th>
              <th className={`${styles.left} ${styles.fullWidth}`}>
                Descrição
              </th>
              <th className={`${styles.left}`}>ID do Produto</th>
            </tr>
          </thead>
          <tbody>
            {!filterData ? (
              <tr>
                <td colSpan={7}>Carregando....</td>
              </tr>
            ) : filterData.length === 0 ? (
              <tr>
                <td colSpan={7}>Sem resultados</td>
              </tr>
            ) : (
              filterData.map((filter) => (
                <tr key={filter.partner}>
                  <td className={`${styles.flexCenter}`}>
                    <Button
                      small
                      type={"warning"}
                      onClick={() => openEditFilterModal(filter)}
                    >
                      Editar
                    </Button>{" "}
                    <Button
                      small
                      type="cancel"
                      onClick={() => openDeleteFilterModal(filter)}
                    >
                      Remover
                    </Button>{" "}
                  </td>
                  <td className={`${styles.left}`}>{filter.ean.trim()}</td>
                  <td className={`${styles.left} `}>{filter.description}</td>
                  <td className={`${styles.center}`}>{filter.prodId}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

interface FilterData {
  partner: string;
  partnerUsername: string;
  ean: string;
  description: string;
  prodId: number;
}

async function baseHandleOnAddFilterClick(
  openModal: OpenModal,
  partner: string,
  partnerUsername: string,
  listFilters: InputData[]
) {
  try {
    const body = { partnerUsername, listFilters };
    const response = await http.post(`${baseUrl}/api/v1/partner/filter`, body);
    openModal(response.data.result);
    return true;
  } catch (e) {
    openModal(getErrorMessage(e));
    return false;
  }
}

async function baseFetchPartnerData(params: {
  openModal: OpenModal;
  partnerName: string;
  partnerUsername: string;
  setLoading: (loading: boolean) => void;
  setPartnerData: (data: FilterData[]) => void;
}) {
  const { setLoading, setPartnerData, partnerUsername, openModal } = params;

  setLoading(true);
  try {
    const response = await http.get(
      `${baseUrl}/api/v1/partner/filter?partnerName=${partnerUsername}`
    );

    const partnerData: FilterData[] = response.data.result;
    console.log(partnerData);

    setPartnerData(partnerData);
    setLoading(false);
  } catch (e) {
    setLoading(false);
    openModal(getErrorMessage(e));
  }
}

function FilterDeleteModal(props: {
  partnerName: string;
  partnerUsername: string;
  filter: FilterData;
  openModal: OpenModal;
  closeModal: CloseModal;
  fetchPartnerData: () => void;
}) {
  const {
    partnerName,
    partnerUsername,
    filter,

    openModal,
    closeModal,
    fetchPartnerData,
  } = props;

  const [state, setState] = useState<{ loading: boolean }>({ loading: false });

  const handleOnConfirmButtonClick = useCallback(async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      await http.delete(
        `${baseUrl}/api/v1/partner/filter?partnerName=${partnerUsername}&ean=${filter.ean}`
      );
      setState((prev) => ({ ...prev, loading: false }));

      fetchPartnerData();

      openModal(<div className={styles.center}>O filtro foi removido!</div>);
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  }, [
    setState,
    openModal,
    fetchPartnerData,
    partnerName,
    partnerUsername,
    filter,
  ]);

  return (
    <div className={styles.center}>
      <p>Tem certeza que deseja remover o filtro desse parceiro?</p>
      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button onClick={handleOnConfirmButtonClick} type="confirm">
            Confirmar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

function EditFilterModal(props: {
  partnerName: string;
  partnerUsername: string;
  filter: FilterData;
  openModal: OpenModal;
  closeModal: CloseModal;
  fetchPartnerData: () => void;
}) {
  const {
    partnerUsername,
    filter,

    openModal,
    closeModal,
    fetchPartnerData,
  } = props;

  const [state, setState] = useState<{
    loading: boolean;
    ean: string;
    description: string;
  }>({
    loading: false,
    ean: filter.ean,
    description: filter.description,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleOnConfirmButtonClick = async () => {
    setState((prevState) => ({ ...prevState, loading: true }));

    try {
      const { ean, description } = state;
      const listFilters = [{ ean, description }];
      const body = {
        partnerUsername: partnerUsername,
        listFilters,
      };

      await http.put(`${baseUrl}/api/v1/partner/filter`, body);
      setState((prevState) => ({ ...prevState, loading: false }));

      fetchPartnerData();

      openModal(<div className={styles.center}>O filtro foi atualizado!</div>);
    } catch (e) {
      openModal(getErrorMessage(e));
    }
  };

  return (
    <div className={`${styles.center}`}>
      <h3>Editar filtro</h3>
      <div className={`${styles.left} `}>
        <Input
          label="EAN"
          value={state.ean.trim()}
          name="ean"
          onChange={handleInputChange}
          disabled={true}
        />
        <Input
          label="Descrição"
          value={state.description}
          name="description"
          onChange={handleInputChange}
        />
      </div>
      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <Button onClick={handleOnConfirmButtonClick} type="confirm">
            Confirmar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </div>
      )}
    </div>
  );
}

export default PartnerFiltersPage;
