import { useState, SetStateAction, useCallback, useMemo, useEffect } from "react";
import useCategoryRegistration, { CreateProductCategory, FetchProductCategory, ProductCategoryForm, ToggleProductCategory } from "../useCategoryRegistration";
import { Option } from "../../../form/AsyncSelect";
import useModal, { OpenModal } from "../../../../service/modal/useModal";
import { useHistory, useParams } from "react-router";
import { getErrorMessage } from "../../../../service/error/getErrorMessage";
import Button from "../../../form/Button";

/**
 * Hook com funções e variáveis usadas na página de criação de categoria produto
 */
 function useProductCategoryCreate(edit: boolean) {

  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const categoryRegistration =  useCategoryRegistration();

  const { openModal, closeModal } = useModal();

  const { createProductCategory, fetchProductCategory, toggleProductCategory } = categoryRegistration;

  const [ state, setState ] = useState(initialState);

  const { form, selectedFatherCategory, sendingCategory, selectedEditCategory, fetchingCategory, enabled } = state;

  const handleFormOnChange = useCallback((e: ChangeEvent) => baseHandleFormOnChange(setState, e), [ setState ]);

  const handleProductCategoryOnChange = useCallback((o: Option<null> | null) => baseHandleProductCategoryOnChange(setState, o), [ setState ]);

  const handleSendButtonOnClick = useCallback(() => baseHandleSendButtonOnClick(createProductCategory, setState, state, openModal, history), [ createProductCategory, setState, state, openModal, history ]);

  const handleEditCategoryOnChange = useCallback((selected: Option<null> | null) => baseHandleEditCategoryOnChange(history, setState, selected), [ history, setState ])

  const validation = useMemo(() => validate(state), [ state ]);

  const buttonText = useMemo(() => getButtonText(validation, edit, sendingCategory), [ validation, sendingCategory, edit ]);

  const name = selectedEditCategory?.name;

  const handleConfirmToggleCategoryOnClick = useCallback(() => baseHandleConfirmToggleCategoryOnClick(toggleProductCategory, setState, id, enabled, fetchProductCategory, openModal), [ toggleProductCategory, setState, id, enabled, fetchProductCategory, openModal ]);

  const handleToggleCategoryOnClick = useCallback(() => baseHandleToggleCategoryOnClick(handleConfirmToggleCategoryOnClick, enabled, name, openModal, closeModal), [ handleConfirmToggleCategoryOnClick, enabled, name, openModal, closeModal ]);

  useEffect(() => loadCategoryEffect(fetchProductCategory, setState, openModal, id), [ fetchProductCategory, setState, openModal, id ])

  const showForm = !edit || id;

  return { 
    ...categoryRegistration, buttonText, sendingCategory, validation, handleFormOnChange,
    handleProductCategoryOnChange, handleSendButtonOnClick, form, selectedFatherCategory,
    showForm, selectedEditCategory, handleEditCategoryOnChange, fetchingCategory, enabled,
    handleToggleCategoryOnClick
  }

}

/**
 * Tipo do objeto histórico
 */
type History = ReturnType<typeof useHistory>;

/**
 * Tipo do objeto que contém os dados de validação do formulário
 */
interface Validation {
  fatherCategoryId: string[],
  volumeUnity: string[],
  description: string[],
  errors: number
}

/**
 * Tipo do estado do componente.
 * O form são os campos normais.
 * O selectedFatherCategory e selectedEditCategory também são dos formulários mas 
 * eles são usados no AsyncSelect que é um pouco diferente por isso separei eles.
 * sendingCategory e fechingCategory servem pra mostrar loadings pro usuário
 */
interface State {
  form: {
    productCategoryId: number | null,
    volumeUnity: string | null,
    description: string | null
  },
  selectedFatherCategory: null | Option<null>,
  selectedEditCategory: null | Option<null>,
  sendingCategory: boolean,
  fetchingCategory: boolean,
  enabled?: boolean
}

const initialState: State = {
  form: {
    productCategoryId: null,
    volumeUnity: null,
    description: null
  },
  selectedFatherCategory: null,
  selectedEditCategory: null,
  sendingCategory: false,
  fetchingCategory: false
}

type ChangeEvent = { target: { value?: any, name?: any } };

/**
 * Um callback onChange que irá atualizar no form o atributo com o nome igual
 * ao name do input alterado
 */
function baseHandleFormOnChange(setState: (action: SetStateAction<State>) => void, e: ChangeEvent) {

  setState(prev => ({ ...prev, form: { 
    ...prev.form, [ e.target.name ]: e.target.value
  }}));

}

/**
 * Um callback onChange para atualizar o estado com a opção selecionada no AsyncSelect
 */
function baseHandleProductCategoryOnChange(setState: (action: SetStateAction<State>) => void, option: Option<null> | null) {

  setState(prev => ({ 
    ...prev, selectedFatherCategory: option
  }));

}

/**
 * Valida os dados do formulário
 */
function validate(state: State): Validation {
  
  const validation: Validation = {
    fatherCategoryId: [],
    volumeUnity: [],
    description: [],
    errors: 0
  }

  if (!state.form.volumeUnity) {
    validation.volumeUnity.push("A unidade de volume é obrigatória");
    validation.errors++;
  }
  if (!state.form.description || state.form.description.match(/^\s+$/)) {
    validation.description.push("A descrição é obrigatória");
    validation.errors++;
  }

  return validation;

}

/**
 * On click do botão de enviar, tenta salvar a categoria, redireciona pra página de edição e mostra um modal de sucesso.
 * Em caso de falha, mostra um modal com o erro.
 */
async function baseHandleSendButtonOnClick(
  createProductCategory: CreateProductCategory, setState: (action: SetStateAction<State>) => void,
  state: State, openModal: OpenModal, history: History
) {

  const form: ProductCategoryForm = {
    categoryFatherId: state.selectedFatherCategory ? parseInt(state.selectedFatherCategory.id) : undefined,
    productCategoryId: state.form.productCategoryId || undefined,
    volumeUnity: state.form.volumeUnity!,
    description: state.form.description!
  }

  setState(prev => ({ ...prev, sendingCategory: true }))

  try {

    const result = await createProductCategory(form);

    history.push(`/product/product-category/edit/${ result.id }`);

    openModal("Categoria criada com sucesso");

  } catch (e) {

    openModal(getErrorMessage(e));

  } finally {

    setState(prev => ({ ...prev, sendingCategory: false }));

  }

}

/**
 * Retorna o texto que vai ficar no botão de enviar
 */
function getButtonText(validation: Validation, edit: boolean, loading: boolean) {

  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1 ? `Existe 1 erro` : `Existem ${ validation.errors } erros`;
  }

  return !edit ? "Criar" : "Editar";

}

/**
 * Recupera da api a categoria que o usuário selecionou para editar
 * e preenche o formulário de edição com ela.
 * Caso o usuário saia da página de editar pra página de criar esse mesmo
 * effect irá limpar o formulário.
 */
function loadCategoryEffect(
  fetch: FetchProductCategory,
  setState: (action: SetStateAction<State>) => void,
  openModal: OpenModal,
  id?: string
) {

  const fn = async () => {

    if (!id) {
      setState(initialState);
      return
    }

    setState(prev => ({ ...prev, fetchingCategory: true }));

    try {

      const category = await fetch(parseInt(id));

      const form = {
        productCategoryId: parseInt(id),
        volumeUnity: category.volumeUnity,
        description: category.description
      };

      const selectedEditCategory = {
        id, name: category.description
      }

      const selectedFatherCategory = !category.fatherId ? null : {
        id: "" + category.fatherId,
        name: category.fatherDescription!
      }

      setState(prev => ({ ...prev, form, selectedFatherCategory, selectedEditCategory, fetchingCategory: false, enabled: category.type !== 0 }))

    } catch (e) {
      openModal(getErrorMessage(e))
    }
  }

  fn();

}

/**
 * Trata o evento do usuário selecionar a categoria que quer editar
 */
function baseHandleEditCategoryOnChange(history: History, setState: (action: SetStateAction<State>) => void, selectedEditCategory: Option<null> | null) {

  setState(prev => ({ ...prev, selectedEditCategory }))
  history.push("/product/product-category/edit" + (selectedEditCategory ? "/" + selectedEditCategory.id : ""))

}

/**
 * Trata o evento do click no botão de ativar ou desativar categoria
 */
async function baseHandleToggleCategoryOnClick(
  toggle: () => void,
  enabled: boolean | undefined,
  name: string | undefined,
  openModal: OpenModal,
  closeModal: () => void
) {

  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá { enabled ? "desabilitar" : "habilitar" } a categoria { name }.<br/>
        Confirma?
      </div>
      <Button type="confirm" onClick={ () => { closeModal(); toggle(); } }>
        Sim
      </Button>
      { ' ' }
      <Button type="cancel" onClick={ closeModal }>
        Não
      </Button>
    </div>
  )

}

/**
 * Trata o evento do click no botão confirmação de ativar ou desativar categoria
 */
async function baseHandleConfirmToggleCategoryOnClick(
  toggle: ToggleProductCategory,
  setState: (action: SetStateAction<State>) => void, 
  id: string | undefined, 
  enabled: boolean | undefined,
  fetch: FetchProductCategory,
  openModal: OpenModal
) {

  setState(prev => ({ ...prev, sendingCategory: true }));

  try {

    await toggle(id!, !enabled);

    setState(prev => ({ ...prev, sendingCategory: false }));

    loadCategoryEffect(fetch, setState, openModal, id);

  } catch (e) {

    openModal(getErrorMessage(e))

    setState(prev => ({ ...prev, sendingCategory: false }));

  }

}

export default useProductCategoryCreate