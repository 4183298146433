import useClientListPage from "../../service/client/useClientListPage";
import Button from "../form/Button";
import Input from "../form/Input";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";

export default function ClientListPage() {
  const {
    handleFindOnSubmit,
    handleOnChange,
    filter,
    items,
    handleEditOnClick,
    page,
    totalPages,
    handlePageOnChange,
  } = useClientListPage();

  return (
    <div>
      <SectionTitle
        title="Listagem de Clientes"
        description="Consulta, edição e criação de Clientes."
      />

      <form onSubmit={handleFindOnSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre o cliente pelo nome"
          addons={[
            <Button key="1" type="info" submit>
              Pesquisar
            </Button>,
          ]}
        />
      </form>

      <TableResponsiveWrapper>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Id</th>
              <th className={`${styles.fullWidth} ${styles.left}`}>Nome</th>
              <th>CNPJ ou CPF</th>
            </tr>
          </thead>
          <tbody>
            {!items ? (
              <tr>
                <td colSpan={7}>Carregando....</td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td colSpan={7}>Sem resultados</td>
              </tr>
            ) : (
              items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Button
                      small
                      type="warning"
                      onClick={() => handleEditOnClick(item.id)}
                    >
                      Ver
                    </Button>
                  </td>
                  <td>{item.id}</td>
                  <td className={`${styles.fullWidth} ${styles.left}`}>
                    {item.name || "Não Informado"}
                  </td>
                  <td>{item.cnpj || "Não Informado"}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination
        page={page}
        totalPages={totalPages === null ? undefined : totalPages}
        onChangePage={handlePageOnChange}
      />
    </div>
  );
}
