import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { http } from "../api/http";
import AsyncSelect2 from "../components/AsyncSelect2";
import Button from "../components/Button";
import Input from "../components/Input";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import { SubTitle, Title } from "../components/Titles";
import { baseUrl, mainColor, mediaPrefix } from "../constants";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { validateTextSize } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const Alert = styled.div`
  border: solid 1px #b5930c;
  border-radius: 5px;
  padding: 10px;
  background-color: #fefad4;
  margin: 10px 0;
  color: #b5930c;
  font-size: 12px;

  .__title {
    font-weight: bolder;
  }

  .__body {
    margin-top: 10px;
  }
`;

const BrandImage = styled.img`
  max-width: 50px;
`;

function ProductQueuePage() {
  // # STATE
  const [state, setState] = useState({
    description: null,
    searchCategory: null,
    searchCategoryName: null,
    searchBrand: null,
    searchBrandName: null,
    searchManufacturer: null,
    searchManufacturerName: null,
    searchStatus: null,
    products: null,
    listSize: 0,
    loadingProducts: false,
    itemsPerPage: 200,
    pageNumber: 1,
    eanList: false,
    searchEanList: null,
  });

  const {
    description,
    searchCategory,
    searchCategoryName,
    searchManufacturer,
    searchManufacturerName,
    searchStatus,
    eanList,
    searchEanList,
    searchBrand,
    searchBrandName,
    products,
    listSize,
    loadingProducts,
    itemsPerPage,
    pageNumber,
  } = state;

  // # OTHER HOOKS

  const { createModal } = useModal();
  const { createEvent } = useHorusAccess();
  const [event] = useState({});

  // # HELPERS
  const editProduct = (ean, token) => () =>
    window.open("/product2/" + ean + "?token=" + (token ? token : ""));

  const fetchStatus = [
    { id: null, name: "Selecione..." },
    { id: "AGUARDANDO", name: "AGUARDANDO" },
    { id: "BLOQUEADO", name: "BLOQUEADO" },
    { id: "LIBERADO", name: "LIBERADO" },
    { id: "MODERACAO", name: "MODERACAO" },
    { id: "PAUSADO", name: "PAUSADO" },
  ];

  const setSearchEansList = function (eans) {
    eans = validateTextSize(eans, 1, 1300)?.replaceAll(/ +/g, "").split(",");
    setState((prev) => ({ ...prev, searchEanList: eans }));
  };

  // REQUESTS
  const fetchCategories = async function (search, offset, ncm) {
    const { data: categories } = await http.get(
      baseUrl + `/api/v1/products/categories`,
      {
        params: {
          search,
          offset,
          ncm,
          limit: 10,
        },
      }
    );
    return {
      options: categories.map((c) => ({ id: c.id, name: c.name })),
      hasMore: categories.length === 10,
    };
  };

  const fetchManufacturers = async function (search, offset) {
    const { data: manufacturers } = await http.get(
      baseUrl + `/api/v1/products/manufacturers`,
      {
        params: {
          search,
          offset,
          limit: 10,
        },
      }
    );
    return {
      options: manufacturers.map((m) => ({ id: m.id, name: m.name })),
      hasMore: manufacturers.length === 10,
    };
  };

  const fetchBrands = async function (search, offset) {
    const { data: brands } = await http.get(
      baseUrl + `/api/v1/products/brands`,
      {
        params: {
          search,
          offset,
          limit: 10,
        },
      }
    );
    return {
      options: brands.map((b) => ({ id: b.id, name: b.name })),
      hasMore: brands.length === 10,
    };
  };

  const fetchProducts = useCallback(
    async (pagination) => {
      setState((prev) => ({ ...prev, loadingProducts: true }));
      try {
        if (!searchEanList) {
          const responseProduct = await http.get(
            baseUrl + `/api/v1/products/search`,
            {
              params: {
                description,
                searchCategory,
                searchBrand,
                searchManufacturer,
                searchStatus,
                limit: 10,
                page: pagination ?? pageNumber,
              },
            }
          );
          const products = responseProduct.data.product;
          const listSize = responseProduct.data.listSize;
          setState((prev) => ({
            ...prev,
            products,
            listSize,
            loadingProducts: false,
          }));
        } else {
          const formData = new FormData();
          formData.append("searchEansList", searchEanList);
          const responseProduct = await http.post(
            baseUrl + `/api/v1/products/search-eans-list`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
          const product = responseProduct.data.product;
          const listSize = responseProduct.data.listSize;
          setState((prev) => ({
            ...prev,
            products: product,
            listSize: listSize,
            loadingProducts: false,
          }));
        }
      } catch (err) {
        createModal("Erro ao buscar produtos");
        setState((prev) => ({ ...prev, loadingProducts: false }));
      }
    },
    [state]
  );

  const fetchQueue = async function () {
    const { data: nextProd } = await http.get(
      baseUrl + `/internal/api/v1/products/queue`
    );
    return nextProd;
  };

  const reviseProducts = async function () {
    const { data: prods } = await http.get(
      baseUrl + `/api/v1/products/revise-products`
    );
    return prods;
  };

  const handleReviseProductsOnClick = async () => {
    setState((prev) => ({ ...prev, pageNumber: 1, loadingProducts: true }));
    try {
      const product = await reviseProducts();
      setState((prev) => ({
        ...prev,
        products: product,
        listSize: 0,
        loadingProducts: false,
      }));
    } catch (e) {
      createModal("Erro ao buscar produtos a serem revisados.");
    } finally {
      setState((prev) => ({ ...prev, loadingProducts: false }));
    }
  };

  const handleStartQueueOnClick = async () => {
    setState((prev) => ({ ...prev, loadingProducts: true }));
    try {
      const nextProd = await fetchQueue();
      const token = nextProd.token ? nextProd.token : "";
      nextProd
        ? window.location.replace(
          "/product2/" + nextProd.ean + "?token=" + token
        )
        : createModal("Nenhum ean encontrado para iniciar a fila de moderação");
    } catch (err) {
      createModal("Erro ao buscar fila de produtos");
    } finally {
      setState((prev) => ({ ...prev, loadingProducts: false }));
    }
  };

  const onChangePage = async (pageNumber) => {
    setState((prev) => ({ ...prev, pageNumber }));
    await fetchProducts(pageNumber);
  };

  const clearItems = () =>
    setState((prev) => ({
      ...prev,
      products: null,
      listSize: 0,
      pageNumber: 1,
    }));

  // # EFFECT
  // Log access event
  useEffect(() => {
    const userCredentials = authCredentials.get.authUser();
    event.userId = userCredentials.userId;
    event.category = "backoffice";
    event.action = "access product search";

    createEvent(event);
  }, []);

  // # HTML

  return (
    <>
      <div className="h-row">
        <div className="h-col h-col-3">
          <Title>Moderação de Produtos</Title>
        </div>
        <Button
          disabled={loadingProducts}
          style={{
            fontSize: "20px",
            height: "50px",
            marginTop: "6px",
            marginBottom: "6px",
            backgroundColor: mainColor,
            color: "white",
          }}
          onClick={handleStartQueueOnClick}
        >
          {loadingProducts ? "Carregando..." : "Iniciar Fila"}
        </Button>
      </div>
      <SubTitle>
        Buscar produtos &nbsp;
        {products?.length > 0 ? null : (
          <Button
            layout="info"
            onClick={handleReviseProductsOnClick}
            disabled={loadingProducts}
            className="h-input-button"
          >
            {loadingProducts ? "Carregando..." : "Produtos a Revisar"}
          </Button>
        )}
      </SubTitle>
      {products?.length > 0 ? null : (
        <div>
          <label className="h-checkbox">
            <input
              type="checkbox"
              checked={eanList}
              onChange={() =>
                setState((prev) => ({
                  ...prev,
                  eanList: !eanList,
                  searchEanList: null,
                }))
              }
            />
            Lista de Eans
          </label>
          {eanList ? (
            <div className="h-row">
              <div className="h-col h-col-2">
                <Input
                  label="Eans"
                  value={searchEanList}
                  onChange={(e) => setSearchEansList(e.target.value)}
                />
              </div>
              <Button
                layout="confirm"
                disabled={!searchEanList || loadingProducts}
                onClick={() => fetchProducts()}
                className="h-input-button"
              >
                {loadingProducts ? "Carregando..." : "Buscar"}
              </Button>
            </div>
          ) : (
            <div className="h-row">
              <div className="h-col h-col-2">
                <Input
                  label="Ean ou descrição"
                  value={description}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </div>
              <div style={{ marginTop: "5px" }} className="h-col h-col-2">
                <AsyncSelect2
                  label="Categoria Produto"
                  value={searchCategory}
                  valueName={searchCategoryName}
                  onChange={(option) =>
                    setState((prev) => ({
                      ...prev,
                      searchCategory: option?.id,
                      searchCategoryName: option?.name,
                    }))
                  }
                  fetchData={fetchCategories}
                />
              </div>
              <div style={{ marginTop: "5px" }} className="h-col h-col-2">
                <AsyncSelect2
                  label="Marca"
                  value={searchBrand}
                  valueName={searchBrandName}
                  onChange={(option) =>
                    setState((prev) => ({
                      ...prev,
                      searchBrand: option?.id,
                      searchBrandName: option?.name,
                    }))
                  }
                  fetchData={fetchBrands}
                />
              </div>
              <div style={{ marginTop: "5px" }} className="h-col h-col-2">
                <AsyncSelect2
                  label="Fabricante"
                  value={searchManufacturer}
                  valueName={searchManufacturerName}
                  onChange={(option) =>
                    setState((prev) => ({
                      ...prev,
                      searchManufacturer: option?.id,
                      searchManufacturerName: option?.name,
                    }))
                  }
                  fetchData={fetchManufacturers}
                />
              </div>
              <div style={{ marginTop: "2px" }} className="h-col h-col-1">
                <Input
                  label="Status"
                  value={searchStatus}
                  placeholder="Selecione..."
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      searchStatus: e.target.value,
                    }))
                  }
                  options={fetchStatus}
                />
              </div>
              <Button
                layout="confirm"
                disabled={
                  (!description &&
                    !searchCategory &&
                    !searchBrand &&
                    !searchManufacturer &&
                    !searchStatus) ||
                  loadingProducts
                }
                onClick={() => fetchProducts()}
                className="h-input-button"
              >
                {loadingProducts ? "Carregando..." : "Buscar"}
              </Button>
            </div>
          )}
        </div>
      )}
      {products?.length > 0 ? (
        <>
          <span>
            Exibindo itens: {200 * (pageNumber - 1) + 1} até{" "}
            {200 * (pageNumber - 1) + products.length}
          </span>
          &nbsp;
          <Button layout="info" disabled={loadingProducts} onClick={clearItems}>
            {loadingProducts ? "Carregando..." : "Limpar Busca"}
          </Button>
          <Table>
            <thead>
              <tr>
                <th className="align-center">Imagem</th>
                <th className="align-center">EAN</th>
                <th>Descrição</th>
                <th className="align-center">Marca</th>
                <th className="align-center">Fabricante</th>
                <th className="align-center">Segmento</th>
                <th className="align-center">Categoria</th>
                <th className="align-center">Quantidade</th>
                <th className="align-center">Quantidade Pack</th>
                <th className="align-center">Unidade Volume</th>
                <th className="align-center">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product.id}>
                  <td className="align-center">
                    {product.file ? (
                      <BrandImage src={mediaPrefix + product.file} />
                    ) : (
                      "Sem Imagem"
                    )}
                  </td>
                  <td className="align-center">{product.ean}</td>
                  <td className="limit-size">{product.description}</td>
                  <td className="limit-size">{product.brand}</td>
                  <td className="limit-size">{product.manufacturer}</td>
                  <td className="limit-size">{product.category}</td>
                  <td className="limit-size">{product.nielsenCategory}</td>
                  <td className="align-center">{product.quantity}</td>
                  <td className="align-center">{product.quantityPerPack}</td>
                  <td className="align-center">{product.unitOfMeasurement}</td>
                  <td className="align-center">{product.status}</td>
                  <td className="align-center">
                    <Button
                      layout="info"
                      onClick={editProduct(product.ean, product.token)}
                    >
                      <i className="fa fa-edit" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={listSize}
            onChangePage={onChangePage}
          />
        </>
      ) : (
        <Alert>
          <div className="__title">
            <i className="fas fa-exclamation-triangle"></i> Nenhuma busca
            realizada
          </div>
          <div className="__body">
            A busca pode ser feita exclusivamente pela categoria ou pela
            descrição/ean, e, também, aceita busca conjunta.
            <br />
            Após o carregamento dos items será possível filtrar por status.
          </div>
        </Alert>
      )}
    </>
  );
}

export default ProductQueuePage;
