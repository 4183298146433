import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import useModal from "../../../service/modal/useModal";
import Button from "../../form/Button";
import Input from "../../form/Input";
import { SectionTitle } from "../../ui/SectionTitle";

interface Props {}

type Params = {
  partnerId: string;
  partnerName: string;
};

const ResetPartnerPasswordPage: React.FC<Props> = () => {
  const { partnerId, partnerName } = useParams<Params>();
  const [specialCharactersAllowed, setSpecialCharAllowed] =
    useState("#@!$%^&*");
  const [loading, setLoading] = useState(false);

  const { openModal } = useModal();

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      await http.post(`${baseUrl}/api/v1/partner/reset-password/${partnerId}`, {
        specialCharactersAllowed,
      });
      openModal("Senha do parceiro resetada com sucesso!");
    } catch (error) {
      console.error("Erro ao redefinir a senha do parceiro:", error);
      openModal("Erro ao redefinir a senha do parceiro.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <SectionTitle
        title={`Resetar senha do parceiro - ${partnerName}`}
        description={`Escolha os caracteres especiais aceitos na geração da nova senha.`}
      />
      <Input
        value={specialCharactersAllowed}
        onChange={(e) => setSpecialCharAllowed(e.target.value)}
        placeholder="Ex: #@!$%^&*"
        label="Caracteres Especiais Permitidos"
      />
      <Button onClick={handleResetPassword} type="confirm" disabled={loading}>
        {loading ? "Carregando..." : "Resetar Senha"}
      </Button>
    </div>
  );
};

export default ResetPartnerPasswordPage;
