import styled, { css } from "styled-components";

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;

  button + button {
    margin-left: 10px;
  }
`;

export const SKUModalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  div {
    margin-top: 10px;

    span {
      opacity: 0.5;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin-top: 20px;
  button + button {
    margin-left: 10px;
  }
`;

export const TextAreaElement = styled.textarea`
  border: 1px solid #313131;
  width: 100%;
  ${(props) =>
    props.hasError &&
    css`
      border: 1px solid red;
    `}
`;

export const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;

  svg {
    width: 17px;
    height: 17px;
  }

  span {
    margin-left: 5px;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const InsertSKUContainer = styled(SKUModalContainer)`
  span {
    opacity: 0.7;
    margin: 10px 0;
  }

  div {
    button {
      & + button {
        margin-left: 10px;
      }
    }
  }
`;
