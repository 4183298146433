import Cookies from "js-cookie";
import { AuthUserModel } from "../../../authentication/login";
import { AUTH_CREDENTIALS } from "../../../constants";

const accessToken = () => Cookies.get(AUTH_CREDENTIALS.ACCESS_TOKEN);
const refreshToken = () =>
  window.localStorage.getItem(AUTH_CREDENTIALS.REFRESH_TOKEN);
const authUser = () =>
  JSON.parse(Cookies.get(AUTH_CREDENTIALS.AUTH_USER) ?? "") as AuthUserModel;

export const getCredentials = {
  accessToken,
  authUser,
  refreshToken,
};
