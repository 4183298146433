import { History } from "history";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult, Option } from "../../components/form/AsyncSelect";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useHandleOnCheck from "../form/useHandleOnCheck";
import useModal, { OpenModal } from "../modal/useModal";
import useCompanyRequests, {
  CompanySearch,
  CompanySearchCsvDownloadForm,
  CompanySearchForm,
  CompanySearchView,
  DownloadCsv,
} from "./useCompanyRequests";

function useCompanyListPage() {
  const history = useHistory();

  const companyRequests = useCompanyRequests();

  const { companySearch, downloadCsv } = companyRequests;

  const statusOpts = [
    { id: "BLOQUEADO", name: "BLOQUEADO" },
    { id: "INATIVO", name: "INATIVO" },
    { id: "LIBERADO", name: "LIBERADO" },
    { id: "PARCIAL", name: "PARCIAL" },
    { id: "PAUSADO", name: "PAUSADO" },
  ];

  const fetchCompanyBrandOpts = (filter: string, offset: number | undefined) =>
    baseFetchOpts(filter, offset);

  const { openModal } = useModal();

  const [state, setState] = useState(initialState);

  const handleOnChange = useHandleOnChange(setState);
  const handleOnCheck = useHandleOnCheck(setState);

  const handleModerateOnClick = useCallback(
    (ean: string) => baseModerationOnClick(history, ean),
    [history]
  );

  const handleCompanyBrandSelectOnChange = (value: Option<null> | null) =>
    baseBrandSelectOnChange(setState, value);

  const removeCompanyBrand = (id: string) =>
    baseRemoveCompanyBrand(setState, id);

  const handleCompanyCategorySelectOnChange = (value: Option<null> | null) =>
    baseCategorySelectOnChange(setState, value);

  const removeCompanyCategory = (id: string) =>
    baseRemoveCompanyCategory(setState, id);

  const handleStatusSelectOnChange = (value: string | null) =>
    baseStatusSelectOnChange(setState, value);

  const removeStatus = (id: string) => baseRemoveStatus(setState, id);

  const fetchData = useCallback(
    (page: number) =>
      baseFetchData(companySearch, setState, openModal, state, page),
    [state]
  );

  const onDownloadCsvClick = useCallback(
    () => baseOnDownloadCsvClick(downloadCsv, setState, openModal, state),
    [state]
  );

  useEffect(() => {
    baseOnEnterComponentEffect(companySearch, setState, openModal);
  }, [setState]);

  return {
    ...state,
    handleOnChange,
    handleCompanyBrandSelectOnChange,
    fetchCompanyBrandOpts,
    fetchData,
    handleCompanyCategorySelectOnChange,
    removeCompanyCategory,
    removeCompanyBrand,
    handleOnCheck,
    handleModerateOnClick,
    setState,
    onDownloadCsvClick,
    handleStatusSelectOnChange,
    removeStatus,
    statusOpts,
  };
}

type SetState = Dispatch<SetStateAction<State>>;

interface State {
  data: CompanySearchView | null;
  cnpjList: string | null;
  selectedBrands: Option<null>[];
  selectedCategories: Option<null>[];
  selectedStatus: string[];
  pageSize: number;
  loading: boolean;
  loadingExcel: boolean;
}

const initialState: State = {
  data: null,
  cnpjList: null,
  selectedBrands: [],
  selectedCategories: [],
  selectedStatus: [],
  pageSize: 100,
  loading: false,
  loadingExcel: false,
};

interface OptionsView {
  result: {
    value: number;
    description: string;
  }[];
}

async function baseOnEnterComponentEffect(
  companySearch: CompanySearch,
  setState: SetState,
  openModal: OpenModal
) {
  const form: CompanySearchForm = {
    cnpjList: undefined,
    brands: undefined,
    categories: undefined,
    status: undefined,
    page: 1,
    pageSize: 100,
  };

  try {
    const data = await companySearch(form);
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseFetchOpts(
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/company/brand/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as OptionsView;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

function baseBrandSelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedBrands: [...prev.selectedBrands, option],
    };
  });
}

function baseRemoveCompanyBrand(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedBrands: [...prev.selectedBrands].filter((o) => o.id !== id),
  }));
}

function baseCategorySelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedCategories: [...prev.selectedCategories, option],
    };
  });
}

function baseRemoveCompanyCategory(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedCategories: [...prev.selectedCategories].filter((o) => o.id !== id),
  }));
}

function baseStatusSelectOnChange(setState: SetState, option: string | null) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedStatus: [...prev.selectedStatus, option],
    };
  });
}

function baseRemoveStatus(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedStatus: [...prev.selectedStatus].filter((o) => o !== id),
  }));
}

async function baseFetchData(
  companySearch: CompanySearch,
  setState: SetState,
  openModal: OpenModal,
  state: State,
  page: number
) {
  if (state.cnpjList === "") state.cnpjList = null;

  const form: CompanySearchForm = {
    cnpjList: state.cnpjList?.replaceAll(/ +/g, "").split(","),
    brands: state.selectedBrands.map((o) => parseInt(o.id)),
    categories: state.selectedCategories.map((o) => parseInt(o.id)),
    status: state.selectedStatus,
    page: page,
    pageSize: state.pageSize,
  };

  setState((prev) => ({ ...prev, loading: true }));

  try {
    const data = await companySearch(form);
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseOnDownloadCsvClick(
  downloadCsv: DownloadCsv,
  setState: SetState,
  openModal: OpenModal,
  state: State
) {
  if (state.cnpjList === "") state.cnpjList = null;

  const form: CompanySearchCsvDownloadForm = {
    cnpjList: state.cnpjList?.replaceAll(/ +/g, "").split(","),
    brands: state.selectedBrands.map((o) => parseInt(o.id)),
    categories: state.selectedCategories.map((o) => parseInt(o.id)),
    status: state.selectedStatus,
  };

  try {
    setState((prev) => ({ ...prev, loadingExcel: true }));

    const csvData = await downloadCsv(form);

    const csvFile = new Blob([csvData], { type: "text/csv" });

    let tempLink = document.createElement("a");

    tempLink.download = "companies.csv";
    tempLink.href = window.URL.createObjectURL(csvFile);

    tempLink.style.display = "none";
    document.body.appendChild(tempLink);

    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingExcel: false }));
  }
}

function baseModerationOnClick(history: History<unknown>, cnpj: string) {
  history.push("/company/" + cnpj);
}

export default useCompanyListPage;
