import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult } from "../../components/form/AsyncSelect";

export default function useFetchDashboardOptions(
  findBy: "link" | "description"
) {
  return (filter: string, offset: number | undefined) =>
    baseFetchOpts(findBy, filter, offset);
}

interface Response {
  result: Array<{ value: string; description: string }>;
}

async function baseFetchOpts(
  findBy: string,
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/dashboard/options`, {
    params: { filter, offset, limit: 10, findBy },
  });

  const data = response.data as Response;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}
