import { Switch, Route } from "react-router-dom";

import { Title } from '../../../components/Titles';
import Card from "../ui/Card";
import Tabs from "../ui/Tabs";
import SubTitle from "../ui/SubTitle";
import { useMenu } from "../../service/context/globalContext";
import Platform from "../ui/Platform";
import InsertItemsPage from "../../../pages/InsertItemsPage";
import QueueSetupPage2 from "../prioritization-massive-moderation/QueueSetupPage2";

export default function OthersPage() {

  const menu = useMenu("/others");

  return (
    <Platform>
      <Title>{menu.name}</Title>
      <SubTitle>
        Outras funcionalidades
      </SubTitle>
      <Tabs menu={menu} />
      <Card>
        <Switch>
          <Route exact path="/others/insert">
            <InsertItemsPage />
          </Route>
          <Route exact path="/others/prioritization-multiple-updates">
            <QueueSetupPage2 />
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}