import React from 'react'

import './Loading.css'

function Loading(props) {
  return (
    <div className="Loading-spinner" { ...props }>
      <div className="rect1"></div> { ' ' }
      <div className="rect2"></div> { ' ' }
      <div className="rect3"></div> { ' ' }
      <div className="rect4"></div> { ' ' }
      <div className="rect5"></div>
    </div>
  )
}

export default Loading