import { useCallback, useState } from "react";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../service/modal/useModal";
import { SetState } from "../../types";
import { Option } from "../form/Select";

export default function useDebugReadCouponReport() {
  const { openModal } = useModal();

  const [state, setState] = useState<State>(initialState);

  const fetchData = useCallback(
    () => baseFindDebug(state.params, openModal, setState),
    [state, openModal, setState]
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent) => baseHandleOnChange(e, setState),
    [setState]
  );

  return {
    ...state,
    statusValues,
    fetchData,
    newPageUrl,
    handleOnChange,
    formatDate,
  };
}

type ChangeEvent = { target: { value?: any; name?: any } };

interface Debug {
  machine: string;
  requester: string;
  provider: string;
  accesskey: string;
  status: string;
  end: number;
  debug: string;
}

interface Params {
  extractor: string;
  provider: string;
  status: string;
  accessKey: string;
}

interface State {
  params: Params;
  items: Array<Debug> | null;
  loading: boolean;
}

const initialState: State = {
  params: {
    extractor: "",
    provider: "",
    status: "",
    accessKey: "",
  },
  items: null,
  loading: false,
};

const statusValues: Option[] = [
  { id: "success", name: "Success" },
  { id: "UnhandledSituationException", name: "UnhandledSituationException" },
  { id: "NotFoundAccessKeyException", name: "NotFoundAccessKeyException" },
  { id: "AccessDeniedException", name: "AccessDeniedException" },
  { id: "PageTimeoutException", name: "PageTimeoutException" },
  { id: "DomTimeoutException", name: "DomTimeoutException" },
  {
    id: "CouldNotBreakCaptchaException",
    name: "CouldNotBreakCaptchaException",
  },
  { id: "InvalidAccessKeyException", name: "InvalidAccessKeyException" },
  { id: "UnhandledAlertException", name: "UnhandledAlertException" },
  { id: "TimeoutException", name: "TimeoutException" },
  { id: "NoSuchElementException", name: "NoSuchElementException" },
  { id: "ContingencyException", name: "ContingencyException" },
  { id: "RuntimeException", name: "RuntimeException" },
  { id: "AccessKeyIsDownException", name: "AccessKeyIsDownException" },
  { id: "CanceledCouponException", name: "CanceledCouponException" },
  {
    id: "ProxyRefusingConnectionsException",
    name: "ProxyRefusingConnectionsException",
  },
  { id: "UnsupportedContextException", name: "UnsupportedContextException" },
  { id: "InvalidCouponException", name: "InvalidCouponException" },
];

async function baseFindDebug(
  params: Params,
  openModal: OpenModal,
  setState: SetState<State>
) {
  try {
    setState((prev) => ({ ...prev, items: [], loading: true }));

    const response = await http.get(
      `${baseUrl}/api/v1/report/read-coupon/debug`,
      { params }
    );

    setState((prev) => ({ ...prev, items: response.data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function newPageUrl(debug: string, extension: string) {
  const baseDebugUrl =
    "http://report:wendWgkfT%40FRb%3FqpTtHdYfu3%26z8jRc%40uDzz%2BhsdV0Ncg4LkmS9@coupon-read-debug.ehorus.com.br/readcoupon/debug/";
  window.open(baseDebugUrl + debug + extension, "_blank");
}

function baseHandleOnChange(e: ChangeEvent, setState: SetState<State>) {
  return setState((prev: any) => ({
    ...prev,
    params: {
      ...prev.params,
      [e.target.name]: e.target.value === "" ? null : e.target.value,
      page: 1,
    },
  }));
}

function zeroPad(num: number, numZeros: number) {
  let n = Math.abs(num);
  let zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  let zeroString = Math.pow(10, zeros).toString().substr(1);
  if (num < 0) {
    zeroString = "-" + zeroString;
  }
  return zeroString + n;
}

function formatDate(datetime: number) {
  const date = new Date(datetime);
  return (
    zeroPad(date.getDate(), 2) +
    "/" +
    zeroPad(date.getMonth() + 1, 2) +
    "/" +
    date.getFullYear() +
    " " +
    zeroPad(date.getHours(), 2) +
    ":" +
    zeroPad(date.getMinutes(), 2) +
    ":" +
    zeroPad(date.getSeconds(), 2)
  );
}
