import useCompanyChannelPage from "../../service/company/channel/useCompanyChannelPage";
import Button from "../form/Button";
import Input from "../form/Input";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";

export default function CompanyChannelPage() {
  const {
    handleFindOnSubmit,
    handleOnChange,
    handlePageOnChange,
    openChannelCreateModal,
    openChannelDeleteModal,
    openChannelEditModal,
    items,
    filter,
    loading,
    page,
    total,
  } = useCompanyChannelPage();

  return (
    <div>
      <SectionTitle
        title="Listagem de Canais"
        description="Consulta, edição e criação de Canais"
      />

      <form onSubmit={handleFindOnSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre o canal pelo nome"
          addons={[
            <Button key="1" type="info" submit>
              Pesquisar
            </Button>,
          ]}
        />
      </form>

      <Button type="confirm" onClick={() => openChannelCreateModal()}>
        Criar Canal
      </Button>

      <Spacer ratio={2 / 3} />
      {loading ? (
        <tr>
          <td colSpan={7}>Carregando....</td>
        </tr>
      ) : !items || items.length === 0 ? (
        <tr>
          <td colSpan={7}>Sem resultados</td>
        </tr>
      ) : (
        <>
          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Id</th>
                  <th className={`${styles.fullWidth} ${styles.left}`}>
                    Descrição
                  </th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Button
                        small
                        type="warning"
                        onClick={() => openChannelEditModal(item)}
                      >
                        Editar
                      </Button>{" "}
                      <Button
                        small
                        type="cancel"
                        onClick={() => openChannelDeleteModal(item.id)}
                      >
                        Remover
                      </Button>{" "}
                    </td>
                    <td>{item.id}</td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {item.name || "Não Informado"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableResponsiveWrapper>
          <Pagination
            page={page}
            totalPages={total === null ? undefined : total}
            onChangePage={handlePageOnChange}
          />
        </>
      )}
    </div>
  );
}
