import useProductCompanyCodePage from "../../../service/product/useProductCompanyCodePage";
import { ProductCompanyCode } from "../../../service/product/useProductCompanyCodeRequestsRequests";
import Button from "../../form/Button";
import Spacer from "../../ui/Spacer";

interface Props {
  productCompanyCode: ProductCompanyCode;
}

export default function ProductCompanyCodeDeleteAlert(props: Props) {

  const { productCompanyCode } = props;

  const { loadingAction, handleDeleteOnConfirm } = useProductCompanyCodePage();
  
  return(
    <div style={{ textAlign: "center" }}>
      <div style={{ fontWeight: "bolder", marginBottom: "1em" }}>
        Alerta
      </div>
      <div>
        Confirma apagar essa entrada da Produtobaixa?
      </div>
      <Spacer ratio={ 2/3 } />
      { loadingAction ? (
        <div style={ { alignSelf: "self-end" }}>
          <Button disabled={true}>Carregando</Button>
        </div>
      ) : (
        <div style={ { alignSelf: "self-end" }}>
          <Button type="cancel" onClick={ () => handleDeleteOnConfirm(productCompanyCode) }>Apagar</Button>
        </div>
      )}
    </div>
  )
  
}