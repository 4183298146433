import { useState } from "react";
import { isValidCnpj, isEmptyList } from "../utils";
import { Title } from "../components/Titles";
import Input from "../components/Input";
import Button from "../components/Button";
import useModal from "../hooks/useModal";
import useCompany from "../hooks/useCompany";

function HasInvalidItems(type, items) {
  let errorList = [];

  // if (type === "PROD") {    
  //   items.forEach(ean => {
  //     if (!isEan(ean)) errorList.push(ean);
  //   });

  //   return errorList;
  // }

  if (type === "COMP") {
    items.forEach(cnpj => {
      if (isNaN(cnpj) || !isValidCnpj(cnpj)) return errorList.push(cnpj);
    });
    return errorList;
  }

  return errorList;
}

export default function InsertItemsPage() {
  const [ type, setType ] = useState("COMP");
  const [ itemList, setItemList ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  // Hooks
  // const { insertProducts } = useProduct();
  const { insertCompanies } = useCompany();
  const { createModal } = useModal();

  // Helpers
  const onChangeItemList = function (items) {
    items = items?.replaceAll(/ +/g, "").split(",");
    setItemList(items);
  }

  // Callers
  const handleInsertItems = async () => {
    if (isEmptyList(itemList)) return;
    try {
      setLoading(true);
      const invalidItems = HasInvalidItems(type, itemList);
      if (!isEmptyList(invalidItems)) {
        createModal(
          <div>
            <p>Items inválidos encontrados:</p>
            <ul>
              {invalidItems.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          </div>
        )
        return;
      }
      // if (type === "PROD") {
      //   await insertProducts(itemList);
      //   return;
      // }
      if (type === "COMP") {
        await insertCompanies(itemList);
      }
      createModal(
        <div>
          <p>Items Enviados com Sucesso</p>
        </div>
      )
    } catch(e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div>
        <div className="h-row">
          <div className="h-col h-col-7">
            <Title>Inserir Empresas</Title>
          </div>
        </div>
        <div className="h-row">
          <div className="h-col h-col-2">
            <Input
              label="Tipo de item a ser inserido"
              value={ type }
              onChange={ e => { setType(e.target.value) }}
              options={ [ {id: "COMP", name: "EMPRESA"} ] }
            />
          </div>
        </div>
        <div className="h-row">
          <div className="h-col h-col-2">
            <Input
              label="CNPJs (somente números)"
              placeholder="cnpj,cnpj,cnpj,..."
              value={ itemList }
              onChange={ e => onChangeItemList(e.target.value) }
            />
          </div>
          <Button
            layout="confirm"
            disabled={ !itemList || loading }
            onClick={ handleInsertItems }
            className="h-input-button"
          >
            { loading ? "Carregando..." : "Inserir" }
          </Button>
        </div>
      </div>
    </>
  )
}