import styled from "styled-components"

const Column20 = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin: 10px 0;
  padding: 15px;
  background-color: white;
  width: 20%;
  float: left;
`

export const Column33 = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin: 0.5%;
  padding: 1%;
  background-color: white;
  width: 30%;
  float: left;
`

export const Column50 = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin: 0.5%;
  padding: 15px;
  background-color: white;
  width: 47%;
  float: left;
  height: 750px;
`

export default Column20