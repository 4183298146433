import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useClientRequests, {
  FindById as FindClientById,
} from "../../../service/client/useClientRequests";
import useFetchClientOptions from "../../../service/client/useFetchClientOptions";
import { DashboardAssociationSearchItem } from "../../../service/dashboard/types";
import useDashboardAssociationRequests, {
  Search,
  SearchParams,
} from "../../../service/dashboard/useDashboardAssociationRequests";
import useDashboardRequests, {
  FindById as FindDashboardById,
} from "../../../service/dashboard/useDashboardRequests";
import useFetchDashboardOptions from "../../../service/dashboard/useFetchDashboardOptions";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import makeClassNameFromList from "../../../service/ui/makeClassNameFromList";
import useQuery from "../../../service/url/useQuery";
import { History, Paginated, SetState } from "../../../types";
import AsyncSelect, { Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Pagination, { OnChagePage } from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";
import useFetchGroupOptions from "../../user/group/useFetchGroupOptions";
import useGroupRequests, {
  FindById as FindGroupById,
} from "../../user/group/useGroupRequests";
import useFetchUserOptions from "../../user/user/useFetchUserOptions";
import useUserRequests, {
  FindById as FindUserById,
} from "../../user/user/useUserRequests";
import {
  SearchDashboardAssociationPathParams,
  buildEditDashboardAssociationPath,
  buildSearchDashboardAssociationsPath,
} from "../paths";

export default function SearchDashboardAssociations() {
  const [state, setState] = useState(initialState);
  const {
    selectedUser,
    selectedGroup,
    selectedClient,
    selectedDashboard,
    result,
    page,
  } = state;

  const { search } = useDashboardAssociationRequests();

  const { openModal } = useModal();

  const query = useQuery();
  const dashboardId = query.get("did");
  const userId = query.get("uid");
  const groupId = query.get("gid");
  const clientId = query.get("cid");

  const history = useHistory();

  const { findById: findUserById } = useUserRequests();
  const { findById: findDashboardById } = useDashboardRequests();
  const { findById: findClientById } = useClientRequests();
  const { findById: findGroupById } = useGroupRequests();

  const fetchUserOptions = useFetchUserOptions();
  const fetchGroupOptions = useFetchGroupOptions();
  const fetchClientOptions = useFetchClientOptions();
  const fetchDashboardOptions = useFetchDashboardOptions("description");

  const handleOnFilterSelected = useCallback(
    (type: AsyncSelectField, option: Option<null> | null) =>
      baseHandleOnFilterSelected({ state, history, type, option }),
    [state, history]
  );

  const handleOnUserSelected = useCallback(
    (option: Option<null> | null) =>
      handleOnFilterSelected("selectedUser", option),
    [handleOnFilterSelected]
  );
  const handleOnGroupSelected = useCallback(
    (option: Option<null> | null) =>
      handleOnFilterSelected("selectedGroup", option),
    [handleOnFilterSelected]
  );
  const handleOnClientSelected = useCallback(
    (option: Option<null> | null) =>
      handleOnFilterSelected("selectedClient", option),
    [handleOnFilterSelected]
  );
  const handleOnDashboardSelected = useCallback(
    (option: Option<null> | null) =>
      handleOnFilterSelected("selectedDashboard", option),
    [handleOnFilterSelected]
  );

  const handleOnPageChange = (page: number) =>
    setState((prev) => ({ ...prev, page }));

  useEffect(() => {
    loadDashboardFilterEffect(
      selectedDashboard,
      dashboardId,
      findDashboardById,
      setState
    );
  }, [selectedDashboard, dashboardId]);

  useEffect(() => {
    loadUserFilterEffect(selectedUser, userId, findUserById, setState);
  }, [selectedUser, userId]);

  useEffect(() => {
    loadGroupFilterEffect(selectedGroup, groupId, findGroupById, setState);
  }, [selectedGroup, groupId]);

  useEffect(() => {
    loadClientFilterEffect(selectedClient, clientId, findClientById, setState);
  }, [selectedClient, clientId]);

  useEffect(() => {
    loadResultEffect({
      setState,
      selectedUser,
      selectedGroup,
      selectedDashboard,
      selectedClient,
      page,
      search,
      openModal,
    });
  }, [selectedUser, selectedGroup, selectedDashboard, selectedClient, page]);

  return (
    <div>
      <SectionTitle
        title="Pesquisar Dashboards Vinculados"
        description="Através desta ferramenta pesquise quem está com acesso aos dashboards do Power BI"
      />

      <AsyncSelect
        label="Pesquisar por Usuário"
        value={selectedUser}
        onChange={handleOnUserSelected}
        fetchData={fetchUserOptions}
      />
      <AsyncSelect
        label="Pesquisar por Grupo"
        value={selectedGroup}
        onChange={handleOnGroupSelected}
        fetchData={fetchGroupOptions}
      />
      <AsyncSelect
        label="Pesquisar por Cliente"
        value={selectedClient}
        onChange={handleOnClientSelected}
        fetchData={fetchClientOptions}
      />
      <AsyncSelect
        label="Pesquisar por Dashboard"
        value={selectedDashboard}
        onChange={handleOnDashboardSelected}
        fetchData={fetchDashboardOptions}
      />

      <Spacer ratio={1 / 3} />

      <DashboardAssociationTable
        result={result}
        onChangePage={handleOnPageChange}
        page={page}
      />
    </div>
  );
}

export interface UrlParamsObject {
  did?: string;
  uid?: string;
  gid?: string;
  cid?: string;
}

export interface State {
  selectedUser: Option<null> | null;
  selectedGroup: Option<null> | null;
  selectedClient: Option<null> | null;
  selectedDashboard: Option<null> | null;
  page: number;
  result: Paginated<DashboardAssociationSearchItem> | null;
}

export const initialState: State = {
  selectedUser: null,
  selectedGroup: null,
  selectedClient: null,
  selectedDashboard: null,
  page: 1,
  result: null,
};

type AsyncSelectField =
  | "selectedUser"
  | "selectedGroup"
  | "selectedClient"
  | "selectedDashboard";

interface HandleOnFilterSelectedParams {
  state: State;
  history: History;
  type: AsyncSelectField;
  option: Option<null> | null;
}

function baseHandleOnFilterSelected(params: HandleOnFilterSelectedParams) {
  const { state, history, type, option } = params;

  const newState: State = {
    ...state,
    selectedClient: null,
    selectedGroup: null,
    selectedUser: null,
    [type]: option,
    page: 1,
  };

  const searchParams: SearchDashboardAssociationPathParams = {
    dashboardId: newState.selectedDashboard?.id,
    userId: newState.selectedUser?.id,
    groupId: newState.selectedGroup?.id,
    clientId: newState.selectedClient?.id,
  };

  history.push(buildSearchDashboardAssociationsPath(searchParams));
}

interface LoadEffectParams {
  selectedUser: Option<null> | null;
  selectedGroup: Option<null> | null;
  selectedClient: Option<null> | null;
  selectedDashboard: Option<null> | null;
  page: number;
  search: Search;
  setState: SetState<State>;
  openModal: OpenModal;
}

async function loadResultEffect(params: LoadEffectParams) {
  const {
    selectedUser,
    selectedGroup,
    selectedDashboard,
    selectedClient,
    page,
    search,
    setState,
    openModal,
  } = params;

  const requestParams: SearchParams = {
    userId: selectedUser?.id,
    groupId: selectedGroup?.id,
    clientId: selectedClient?.id,
    dashboardId: selectedDashboard?.id,
    page,
  };

  try {
    const result = await search(requestParams);

    setState((prev) => ({ ...prev, result }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function loadDashboardFilterEffect(
  option: Option<null> | null,
  dashboardId: string | null,
  find: FindDashboardById,
  setState: SetState<State>
) {
  if (option?.id === dashboardId) {
    return;
  }

  if (!dashboardId) return;

  const result = await find(parseInt(dashboardId));

  setState((prev) => ({
    ...prev,
    selectedDashboard: { id: String(result.id), name: result.description },
  }));
}

async function loadUserFilterEffect(
  option: Option<null> | null,
  userId: string | null,
  find: FindUserById,
  setState: SetState<State>
) {
  const optionId = option ? option.id : null;

  if (optionId === userId) {
    return;
  }

  if (!userId) {
    setState((prev) => ({ ...prev, selectedUser: null }));
    return;
  }

  const result = await find(parseInt(userId));

  setState((prev) => ({
    ...prev,
    selectedUser: { id: String(result.id), name: result.username },
  }));
}

async function loadGroupFilterEffect(
  option: Option<null> | null,
  groupId: string | null,
  find: FindGroupById,
  setState: SetState<State>
) {
  const optionId = option ? option.id : null;

  if (optionId === groupId) {
    return;
  }

  if (!groupId) {
    setState((prev) => ({ ...prev, selectedGroup: null }));
    return;
  }

  const result = await find(parseInt(groupId));

  setState((prev) => ({
    ...prev,
    selectedGroup: { id: String(result.id), name: result.name },
  }));
}

async function loadClientFilterEffect(
  option: Option<null> | null,
  clientId: string | null,
  find: FindClientById,
  setState: SetState<State>
) {
  const optionId = option ? option.id : null;

  if (optionId === clientId) {
    return;
  }

  if (!clientId) {
    setState((prev) => ({ ...prev, selectedClient: null }));
    return;
  }

  const result = await find(parseInt(clientId));

  setState((prev) => ({
    ...prev,
    selectedClient: { id: String(result.id), name: result.name },
  }));
}

interface DashboardAssociationTableProps {
  result: Paginated<DashboardAssociationSearchItem> | null;
  onChangePage: OnChagePage;
  page: number;
}

function DashboardAssociationTable(props: DashboardAssociationTableProps) {
  const { result, page, onChangePage } = props;

  const history = useHistory();

  if (!result) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <TableResponsiveWrapper>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Link</th>
              <th
                className={makeClassNameFromList([
                  styles.fullWidth,
                  styles.left,
                ])}
              >
                Dashboard
              </th>
              <th>Usuário</th>
              <th>Grupo</th>
              <th>Cliente</th>
              <th>Expiração</th>
            </tr>
          </thead>
          <tbody>
            {result.items.length ? null : (
              <tr>
                <td colSpan={7}>Sem resultados!</td>
              </tr>
            )}
            {result.items.map((item) => (
              <tr key={item.id}>
                <td>
                  <Button
                    transparent
                    small
                    type="warning"
                    onClick={() =>
                      history.push(buildEditDashboardAssociationPath(item.id))
                    }
                  >
                    Editar
                  </Button>
                </td>
                <td>{item.dashboardLink}</td>
                <td className={styles.left}>{item.dashboardDescription}</td>
                <td>{item.userDescription || "Nenhum"}</td>
                <td>{item.groupDescription || "Nenhum"}</td>
                <td>{item.clientDescription || "Nenhum"}</td>
                <td>
                  {item.expiredAt
                    ? item.expiredAt.replace(
                        /^^(\d+)-(\d+)-(\d+).*$/,
                        "$3/$2/$1"
                      )
                    : "Nunca"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Spacer />
      <Pagination
        page={page}
        onChangePage={onChangePage}
        totalPages={result.total}
      />
    </div>
  );
}
