import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import useClientCompany from "../hooks/useClientCompany";
import useHorusAccess from "../hooks/useHorusAccess";
import {
  allRules,
  blockUserPermission,
  isCpfOrCnpj,
  isNotEmpty,
  makeValidator,
} from "../utils";
import { authCredentials } from "../utils/authCredentials";

function CreateClientCompanyForm(props) {
  const [newClientCompany, setNewClientCompany] = useState({});
  const [collapsed, setCollapsed] = useState(true);
  const { onCreateClientCompany } = props;
  const { getNameErrors, getCnpjErrors, getAllErrors } = userValidator;

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        onCreateClientCompany(newClientCompany);
        setCollapsed(true);
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Criar Nova Empresa</SubTitle>
        <Card>
          <Input
            label="Nome da empresa"
            value={newClientCompany.name}
            onChange={(e) =>
              setNewClientCompany({ ...newClientCompany, name: e.target.value })
            }
            validate={getNameErrors}
          />
          <Input
            type="number"
            label="CPF/CNPJ"
            value={newClientCompany.cnpj}
            onChange={(e) =>
              setNewClientCompany({ ...newClientCompany, cnpj: e.target.value })
            }
            validate={getCnpjErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Adicionar Empresa
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newClientCompany)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

const userValidator = makeValidator({
  name: isNotEmpty("Nome"),
  cnpj: allRules(isNotEmpty("CPF/CNPJ"), isCpfOrCnpj("CPF/CNPJ")),
});

const Wrapper = styled.div``;

const breadcrump = [
  {
    name: "Início",
    path: "/dashboard",
  },
  {
    name: "Empresas",
    path: "/client-company",
  },
];

function ClientCompanyPage() {
  const history = useHistory();
  const { getAllClientCompanies, deleteClientCompany, createClientCompany } =
    useClientCompany();
  const [clientCompanies, setClientCompanies] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const { createEvent } = useHorusAccess();
  const [event] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const currentClientCompanies = clientCompanies
    ? clientCompanies.slice(indexFirstItem, indexLastItem)
    : {};
  const totalItems = clientCompanies ? clientCompanies.length : 0;

  const authUser = authCredentials.get.authUser();

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onCreateClientCompany = async (clientCompany) => {
    setLoading(true);
    clientCompany.cnpj.length === 14
      ? (clientCompany.type = "J")
      : (clientCompany.type = "F");
    try {
      await createClientCompany(clientCompany);
      const clientCompaniesResponse = await getAllClientCompanies();
      setClientCompanies(clientCompaniesResponse.data);
      setMessage("Cliente cadastrado com sucesso.");
    } catch (error) {
      let err;
      err =
        error.response && error.response.status === 400
          ? new Error("CNPJ já cadastrado")
          : error;
      err =
        error.response && error.response.status === 401
          ? new Error("Alteração não autorizada.")
          : err;
      err =
        error.response && error.response.status === 500
          ? new Error(
              "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
            )
          : err;
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOnDeleteClick = async (clientCompany) => {
    if (blockUserPermission(authUser, clientCompany.id)) {
      setLoading(true);
      try {
        await deleteClientCompany(clientCompany);
        const clientCompaniesResponse = await getAllClientCompanies();
        setClientCompanies(clientCompaniesResponse.data);
      } catch (error) {
        let err;
        err =
          error.response && error.response.status === 401
            ? new Error("Alteração não autorizada.")
            : error;
        err =
          error.response && error.response.status === 500
            ? new Error(
                "Ocorreu um erro em nossos servidores. Por favor, verifique os dados digitados, aguarde um momento e tente novamente.\n\nCaso o erro persista entre em contato com o setor de TI."
              )
            : err;
        setError(err);
      } finally {
        setLoading(false);
      }
    } else {
      setError(
        new Error(
          "Sua conta não tem permissão para ativar/desativar usuários da empresa HORUS"
        )
      );
    }
  };

  // Log access event
  useEffect(() => {
    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access client companies";

    createEvent(event);
  }, []);

  useEffect(() => {
    if (!clientCompanies) {
      getAllClientCompanies().then((response) => {
        setClientCompanies(response.data);
        setLoading(false);
      });
    }
  }, []);

  return (
    <Template breadcrump={breadcrump}>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Clientes</Title>
      <Wrapper>
        <CreateClientCompanyForm
          onCreateClientCompany={onCreateClientCompany}
        />
        {!loading ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Empresa</th>
                  <th>CNPJ</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentClientCompanies.map((clientCompany, index) => {
                  return (
                    <tr key={index}>
                      <td>{clientCompany.name}</td>
                      <td>{clientCompany.cnpj}</td>
                      <td>
                        <button
                          type="button"
                          layout="delete"
                          onClick={(e) =>
                            history.push("/client-company/" + clientCompany.id)
                          }
                        >
                          Visualizar Perfil
                        </button>
                        <Button
                          small
                          type="button"
                          layout="error"
                          style={{ marginLeft: "5px" }}
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Deletar todos os usuários da empresa: " +
                                  clientCompany.name +
                                  "?\n\nAVISO: Essa ação removerá o acesso de todos os usuários dessa empresa aos dashboards."
                              )
                            ) {
                              handleOnDeleteClick(clientCompany);
                            }
                          }}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          <Card>
            <Loading style={{ margin: "20px auto" }} />
          </Card>
        )}
      </Wrapper>
    </Template>
  );
}

export default ClientCompanyPage;
