import { baseUrl } from "../constants";

function usePartner() {
  const getAllPartners = () => http.get(baseUrl + "/partner");

  const getPartnerById = (partnerId) =>
    http.get(baseUrl + "/partner/" + partnerId);

  const createPartner = (userPartner) =>
    http.post(baseUrl + "/partner", userPartner);

  const updatePartner = (userPartner) =>
    http.put(baseUrl + "/partner", userPartner);

  const disabledPartner = (partnerId) =>
    http.put(baseUrl + "/partner/disabled/" + partnerId);

  const activePartner = (partnerId) =>
    http.put(baseUrl + "/partner/active/" + partnerId);

  return {
    getAllPartners,
    getPartnerById,
    createPartner,
    updatePartner,
    disabledPartner,
    activePartner,
  };
}

export default usePartner;
