import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Table from "../../../components/Table";
import useFiltersSKU from "../../../hooks/useFiltersSKU";
import { ButtonsContainer, SKUModalContainer } from "./styles";

function DeleteSkUModal() {
  const { handleNoteDelete, setOpenDeleteModal, openDeleteModal, selectedSKU } =
    useFiltersSKU();

  return (
    <>
      <Modal onClose={() => setOpenDeleteModal(!openDeleteModal)}>
        <SKUModalContainer>
          Você deseja deletar os seguintes produtos?
          <div>
            <Table>
              <tbody>
                {selectedSKU.map((sku) => (
                  <tr key={sku.prodId}>
                    <td style={{ fontWeight: "bold", color: "#333333" }}>
                      {sku.prodId}
                    </td>
                    <td>{sku.description}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <ButtonsContainer>
            <Button
              layout="confirm"
              onClick={() => setOpenDeleteModal(!openDeleteModal)}
            >
              Cancelar
            </Button>
            <Button layout="error" onClick={handleNoteDelete}>
              Deletar
            </Button>
          </ButtonsContainer>
        </SKUModalContainer>
      </Modal>
    </>
  );
}

export default DeleteSkUModal;
