import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { SubTitle } from "../../../components/Titles";
import useFiltersSKU from "../../../hooks/useFiltersSKU";
import { InsertSKUContainer, TextAreaElement } from "./styles";

function CreateSKUModal() {
  const {
    handleCreateNote,
    setOpenCreateSKUModal,
    handleTextAreaChange,
    error,
  } = useFiltersSKU();

  return (
    <Modal onClose={() => setOpenCreateSKUModal((prevState) => !prevState)}>
      <InsertSKUContainer>
        <SubTitle>Criar notas</SubTitle>
        <p>
          Utilize o input abaixo para inserir as suas notas, lembre-se de
          utilizar o seguinte padrão:
        </p>
        <span>[o número do seu ean], O seu texto de descrição</span>
        <span>[o número do seu ean], O seu texto de descrição</span>
        <TextAreaElement
          hasError={error.hasError}
          onChange={handleTextAreaChange}
          rows="10"
          placeholder="Insira as suas notas"
        ></TextAreaElement>

        {error.hasError && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              margin: 0,
            }}
          >
            {error.message}
          </span>
        )}

        <div>
          <Button
            layout="error"
            onClick={() => setOpenCreateSKUModal((prevState) => !prevState)}
          >
            Cancelar
          </Button>
          <Button layout="confirm" onClick={handleCreateNote}>
            Criar
          </Button>
        </div>
      </InsertSKUContainer>
    </Modal>
  );
}

export default CreateSKUModal;
