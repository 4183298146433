import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";
import stylesPcc from "./pcc.module.css";
import useProductCompanyCodePage from "../../../service/product/useProductCompanyCodePage";
import NewProductCompanyCodeForm from "./NewProductCompanyCodeForm";
import { isEmpty } from "../../../../utils/validation";

export default function ProductCompanyCodePage() {

  const {
    cnpj, productCode, items, totalPages, page, loading, loadingFile, showHiddenFields, handleShowHiddenFieldsOnCLick, handleFindProductCompanyCode,
    handleOnChange, handleEditModalOnClick, handleDeleteModalOnClick, handleCsvUploadModalOnClick, handleDownloadCsvOnClick
  } = useProductCompanyCodePage();

  return(
    <div>
      <SectionTitle title="Gestão de Produto Baixa" description="Criação, edição e remoção de entradas da Produto Baixa." />
      
      <div style={ { display: "flex", borderBottom: "solid 1px gray" } }>
        <div style={ { width: "300px"} }>
          <Input
            onChange={ handleOnChange }
            value={ cnpj }
            name="cnpj"
            placeholder="Digite..."
            label="CNPJ"
          />
        </div>
        <div style={ { width: "10px"} }></div>
        <div style={ { width: "220px"} }>
          <Input
            onChange={ handleOnChange }
            value={ productCode }
            name="productCode"
            placeholder="Digite..."
            label="Código do produto"
          />
        </div>
        <div style={ { width: "10px"} }></div>
        <div style={ { alignSelf: "self-end" }}>
          <Button type="confirm" onClick={ () => handleFindProductCompanyCode(1) } disabled={ loading }>Pesquisar</Button>
          <Spacer ratio={ 2/3 } />
        </div>
        <div style={ { width: "-webkit-fill-available"} }></div>
        { showHiddenFields ? null :
          <div style={ { display: "flex" } }>
            <div style={ { alignSelf: "self-end" }}>
              <Button type="info" onClick={ handleShowHiddenFieldsOnCLick }>+Adicionar</Button>
              <Spacer ratio={ 2/3 } />
            </div>
            <div style={ { width: "10px"} }></div>
          </div>
        }
        { loadingFile ? (
          <div style={ { alignSelf: "self-end" }}>
            <Button disabled={ true }>Carregando</Button>
            <Spacer ratio={ 2/3 } />
          </div>
        ) : (
          <div style={ { display: "flex" } }>
            <div className={ stylesPcc.dropdownMenu }>
              <div >
                <Button type="info" >Upload CSV</Button>
                <Spacer ratio={ 2/3 } />
              </div>
              <ul>
                <div onClick={ () => handleCsvUploadModalOnClick("create") }>Inserção em Massa</div>
                <div onClick={ () => handleCsvUploadModalOnClick("edit") }>Edição em Massa</div>
                <div onClick={ () => handleCsvUploadModalOnClick("delete") }>Deleção em Massa</div>
              </ul>
            </div>
            <div style={ { width: "10px"} }></div>
            <div style={ { alignSelf: "self-end" }}>
              <Button type="info" onClick={ handleDownloadCsvOnClick } disabled={ isEmpty(cnpj) && isEmpty(productCode) }>Download CSV</Button>
              <Spacer ratio={ 2/3 } />
            </div>
          </div>
        )}
      </div>
      { !showHiddenFields ? null :
        <div style={ { borderBottom: "solid 1px gray" } }>
          <Spacer ratio={ 2/3 } />
          <NewProductCompanyCodeForm handleShowHiddenFieldsOnCLick={ handleShowHiddenFieldsOnCLick }/>
        </div>
      }
      <Spacer ratio={ 2/3 } />
      <TableResponsiveWrapper>
        <table className={ styles.table }>
          <thead>
            <tr>
              <th>Ações</th>
              <th>CNPJ</th>
              <th>Código do Produto</th>
              <th>ID do Produto</th>
              <th>EAN</th>
              <th className={ `${ styles.fullWidth } ${ styles.left }` }>Descrição do Produto</th>
              <th className={ `${ styles.fullWidth } ${ styles.left }` }>Descrição da Produto Baixa</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {
              loading ? (
                <tr><td colSpan={ 7 }>Carregando....</td></tr>
              ) : (
                items?.length === 0 ? (
                  <tr><td colSpan={ 7 }>Sem resultados</td></tr>
                ) : (
                  items?.map(item => (
                    <tr key={ item.cnpj! + item.productCode }>
                      <td>
                        <Button small type="warning" onClick={ () => handleEditModalOnClick(item) }>
                          Editar
                        </Button> { ' ' }
                        <Button onClick={ () => handleDeleteModalOnClick(item) } type="cancel" small>
                          Apagar
                        </Button>
                      </td>
                      <td>
                        { item.cnpj }
                      </td>
                      <td>
                        { item.productCode || "Não Informado" }
                      </td>
                      <td>
                        { item.productId || "Não Informado" }
                      </td>
                      <td>
                        { item.ean || "Não Informado" }
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { item.productDescription || "Não Informado" }
                      </td>
                      <td className={ `${ styles.fullWidth } ${ styles.left }` }>
                        { item.description || "Não Informado" }
                      </td>
                      <td>
                        { item.createDate }
                      </td>
                    </tr>
                  ))
                )
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination page={ page } totalPages={ totalPages === null ? undefined : totalPages } onChangePage={ handleFindProductCompanyCode } />
    </div>
  )
}