import { useState } from "react";
import { mediaPrefix } from "../../../constants";
import { isEmpty } from "../../../utils/validation";
import { validateCompany } from "../../service/company/useCompanyModeration";
import { CompanyToModerate } from "../../service/company/useCompanyRequests";
import useModal from "../../service/modal/useModal";
import Button from "../form/Button";
import styles from "../ui/ui.module.css"
import { SetState } from "../../types";

interface Props {
  onConfirm: (status: string, reason: string) => Promise<void>;
  company: CompanyToModerate;
  status: string;
}

export default function CompanyModerationConfirmationModal(props: Props) {

  const { onConfirm, company, status } = props;
  const { closeModal } = useModal();
  const [ reason, setReason ] = useState<string>("");
  const [ loading, setLoading ] = useState(false);

  const errors = validateCompany(company);
  const message = useGetMessage(company, status, errors, reason, setReason);

  return (
    <div className={ styles.textCenter}>
      { message }
      <br />
      <br />
      <Button
        type="confirm"
        onClick={ () => { setLoading(true); onConfirm(status, reason) } }
        disabled={ (status === "LIBERADO" && errors.length > 0) || (status === "PULADO" && isEmpty(reason)) || loading }
      >
        { loading ? "Carregando..." : "Sim" }
      </Button>&nbsp;
      <Button type="cancel" onClick={ () => closeModal() } disabled={ loading }>
        Não
      </Button>
    </div>
  )

}

function useGetMessage(company: CompanyToModerate, status: string, errors: string[], reason: string, setReason: SetState<string>) {

  if (status === "INATIVO") {
    return (
      <div>Tem certeza que deseja inativar a empresa?</div>
    )
  }

  if (status === "PAUSADO") {
    return (
      <div>Tem certeza que deseja pausar a empresa?</div>
    )
  }

  if (status === "PULADO") {
    return (
      <>
        <p>Indique o motivo de ter pulado.</p><br/>
        <div className={ styles.left }>
        <input type="radio" name="message" value="Situação cadastral inapta/baixada." onChange={ (e: {target: {value: string}}) => setReason(e.target.value) }/>Situação cadastral inapta/baixada.<br/>
        <input type="radio" name="message" value="Não encontrou a categoria no sistema." onChange={ (e: {target: {value: string}}) => setReason(e.target.value) }/>Não encontrou a Categoria no sistema<br/>
        <input type="radio" name="message" value="Não encontrou a marca no sistema." onChange={ (e: {target: {value: string}}) => setReason(e.target.value) }/>Não encontrou a Marca no sistema<br/><br/>
        </div><br/>
        <p>Tem certeza que deseja pular a empresa?</p><br/>
      </>
    )
  }

  if (errors.length === 0) {
    return (
      <div style={ {textAlign: "left"} }>
        <h3>Confirme os dados a serem salvos:</h3>
        <p>ID: { company.id }</p>
        <p>Razão Social: { company.companyName }</p>
        <p>Nome Fantasia: { company.tradingName }</p>
        <p>CNPJ: { company.cnpj }</p>
        <p>CEP: { company.cep }</p>
        <p>Endereço: { company.address }</p>
        <p>Bairro: { company.neighborhood }</p>
        <p>Cidade: { company.city }</p>
        <p>UF: { company.uf }</p>
        <p>Marca: { company.brand.name } - { company.brand.id }</p>
        <p>Imagem: <img style={{width:"100px", height:"100px"}} src={ mediaPrefix + company.brand.file } alt="/static/placeholder.png"/></p>
        <p>Checkouts: { company.checkouts }</p>
        <p>Grande Rede: { company.isBigFranchise ? "sim" : "não" }</p>
        <hr/>
      </div>
    )
  }

  let message = "Para liberar a empresa você precisa corrigir os seguintes erros: <br />";
  errors.forEach(error => message += "<br />" + error);

  return ( <div dangerouslySetInnerHTML={{ __html: message }} /> );

}