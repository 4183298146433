import qs from "qs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import { Title } from "../components/Titles";
import { baseUrl, mainColor } from "../constants";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { optional } from "../utils";
import { authCredentials } from "../utils/authCredentials";

function CompanyQueuePage() {
  // # STATE
  const [
    {
      queue,
      queueTotal,
      companies,
      loadingCompanies,
      currentQueue,
      currentQueueTotal,
    },
    setState,
  ] = useState({
    queue: undefined,
    queueTotal: undefined,
    currentQueue: undefined,
    currentQueueTotal: undefined,
    companies: undefined,
    loadingCompanies: undefined,
  });

  // # OTHER HOOKS

  const { createModal } = useModal();
  const history = useHistory();
  const { createEvent } = useHorusAccess();
  const [event] = useState({});

  // # HELPERS
  const fetchQueue = async function () {
    const { data: nextProd } = await http.get(
      baseUrl + `/internal/api/v1/company/queue`
    );
    return nextProd;
  };

  const handleStartQueueOnClick = useCallback(async () => {
    setState((prev) => ({ ...prev, loadingProducts: true }));
    try {
      const nextComp = await fetchQueue();
      nextComp
        ? history.push("/x/company/" + nextComp.cnpj)
        : createModal(
            "Nenhum cnpj encontrado para iniciar a fila de moderação"
          );
    } catch (err) {
      createModal("Erro ao buscar fila de empresas");
    } finally {
      setState((prev) => ({ ...prev, loadingProducts: false }));
    }
  }, [createModal]);

  // # EFFECT
  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access company queue";

    createEvent(event);
  }, []);

  useEffect(() => {
    const search = optional(window.location.search)
      .map((s) => s.substring(1))
      .get();
    if (!search) return;
    const { queue, total: queueTotal } = qs.parse(search);
    setState((prev) => ({ ...prev, queue, queueTotal }));

    if (queue && queueTotal) {
      fetchQueue({ queue, queueTotal });
    }
  }, [setState, fetchQueue]);

  // # MEMO
  const queueOptions = useMemo(() => {
    if (!queueTotal) return undefined;

    const charA = "A".charCodeAt(0);
    const options = [];

    for (let i = 1; i <= queueTotal; i++) {
      options.push({
        id: i,
        name: "Fila " + String.fromCharCode(charA + i - 1),
      });
    }

    return options;
  }, [queueTotal]);

  // # HTML

  return (
    <>
      <div className="h-row">
        <Title>Moderação de Empresas</Title>&nbsp;
        <Button
          disabled={false}
          style={{
            fontSize: "20px",
            height: "50px",
            marginTop: "6px",
            marginBottom: "6px",
            backgroundColor: mainColor,
            color: "white",
          }}
          onClick={handleStartQueueOnClick}
        >
          {false ? "Carregando..." : "Iniciar Fila"}
        </Button>
      </div>
    </>
  );
}

export default CompanyQueuePage;
