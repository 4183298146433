import AsyncSelect from "../form/AsyncSelect";
import Button from "../form/Button";
import Checkbox from "../form/Checkbox";
import Input from "../form/Input";
import Select from "../form/Select";
import ValidationMessages from "../form/ValidationMessages";
import { SectionTitle } from "../ui/SectionTitle";
import AnalysisServicesSelectForm from "./AnalysisServicesSelectForm";
import useDashboardForm from "./useDashboardForm";

function DashboardForm(props: { edit?: boolean }) {

  const { edit = false } = props;

  const deployEnvironments = [
    { id: "horus", name: "Horus" },
    { id: "neogrid", name: "Neogrid" },
  ]

  const {
    form, selectedDashboard, validation, handleEditDashboardOnChange,
    handleFormOnChange, fetchOpts, handleGenerateLinkOnClick,
    handleDescriptionOnCheck, handleLinkOnCheck, findBy, sendButtonText,
    sendButtonDisabled, showForm, handleSendOnClick, findingDataset, handleOnFindDatasetClick,
    handleOnAnalysisServicesChange,
  } = useDashboardForm(edit);

  return (
    <div>
      {
        !edit ? (
          <SectionTitle
            title="Criar Dashboard"
            description="Crie um novo dashboard"
          />
        ) : (
          <>
            <SectionTitle
              title="Editar Dashboards"
              description="Alterar um dashboard já existente"
            />
            <Checkbox checked={findBy === "description"} label="Buscar por Descrição" onChange={handleDescriptionOnCheck} />
            <Checkbox checked={findBy === "link"} label="Buscar por Link" onChange={handleLinkOnCheck} />
            <AsyncSelect
              fetchData={fetchOpts}
              onChange={handleEditDashboardOnChange}
              value={selectedDashboard}
              label="Selecione o relatório para editar"
            />
          </>
        )
      }
      {
        !showForm ? null : (
          <>
            <Input
              label="Nome"
              name="description"
              value={form.description}
              onChange={handleFormOnChange}
              placeholder="Nome do dashboard que aparece na listagem do usuário"
            />
            <ValidationMessages messages={validation.description} />
            <Input
              label="Link"
              name="link"
              value={form.link}
              onChange={handleFormOnChange}
              placeholder="Código único que fica na url do relatório"
              addons={[<Button key="1" disabled={!form.description} type="info" onClick={handleGenerateLinkOnClick}>Gerar link</Button>]}
            />
            <ValidationMessages messages={validation.link} />
            <Input
              label="Report BI ID"
              name="reportIdBi"
              value={form.reportIdBi}
              onChange={handleFormOnChange}
              placeholder="Código do relatório power bi"
            />
            <ValidationMessages messages={validation.reportIdBi} />
            <Input
              label="Group BI ID"
              name="groupIdBi"
              value={form.groupIdBi}
              onChange={handleFormOnChange}
              placeholder="Código do grupo power bi"
            />
            <ValidationMessages messages={validation.groupIdBi} />
            <Input
              label="Dataset BI ID"
              name="datasetIdBi"
              value={form.datasetIdBi}
              onChange={handleFormOnChange}
              placeholder="Código do dataset power bi"
              addons={[<Button key="1" disabled={!form.reportIdBi || !form.groupIdBi} type="info" onClick={handleOnFindDatasetClick}>{findingDataset ? "Carregando..." : "Buscar Dataset"}</Button>]}
            />
            <ValidationMessages messages={validation.datasetIdBi} />
            <Select
              options={deployEnvironments}
              value={form.deployEnvironment}
              onChange={handleFormOnChange}
              label="Ambiente de deploy"
              name="deployEnvironment"
            />
            <ValidationMessages messages={validation.deployEnvironment} />
            <AnalysisServicesSelectForm
              value={form.analysisServices}
              onChange={handleOnAnalysisServicesChange}
            />
            <Button type="confirm" disabled={sendButtonDisabled} onClick={handleSendOnClick}>
              {sendButtonText}
            </Button>
          </>
        )
      }
    </div>
  )
}

export default DashboardForm