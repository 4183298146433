import AsyncSelect from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Input from "../../form/Input";
import ValidationMessages from "../../form/ValidationMessages";
import useNielsenCategoryCreatePage from "./useNielsenCategoryCreatePage"
import Checkbox from "../../form/Checkbox";

import styles from "./NielsenCategoryCreatePage.module.css";
import { SectionTitle } from "../../ui/SectionTitle";

interface Props {
  edit?: boolean
}

function NielsenCategoryCreatePage(props: Props) {

  const { edit = false } = props;

  const {
    
    fetchNielsenCategoryOpts, handleEditCategoryOnChange, selectedEditCategory, fetchingCategory, id,
    showForm, description, handleOnChange, validation, fetchMotherCategoryOpts, fetchProductCategoryOpts,
    handleMotherCategoryOnChange, handleLvl1CategoryOnChange, handleProductCategorySelectOnChange,
    handleSendButtonOnClick, selectedLvl1Category, selectedMotherCategory, selectedProductCategories, sendingCategory,
    buttonText, removeProductCategory, handleOnCheck, createNewMotherCategory, motherCategoryDescription,
    createNewAuntCategory, auntCategoryDescription, fetchAuntCategoryOpts, handleAuntCategoryOnChange, selectedAuntCategory,
    handleDeleteButtonOnClick, toggleConfirmation, confirmation, handleEditMotherOnClick, handleEditAuntOnClick
  } = useNielsenCategoryCreatePage(edit)

  return (
    <div>
      {
        !edit ? (
          <SectionTitle
            title="Criar Categoria"
            description="Crie uma nova categoria"
          />
        ) : (
          <>
            <SectionTitle
              title="Editar Categoria"
              description="Editar uma categoria já existente"
            />
            <AsyncSelect
              fetchData={ fetchNielsenCategoryOpts }
              onChange={ handleEditCategoryOnChange }
              value={ selectedEditCategory }
              label="Selecione a categoria para editar"
            />
  
            { !id ? null :
              <Input
                label="ID"
                value={ id }
                disabled
              />
            }
          </>
        )
      }
      {
        fetchingCategory ? <div style={{ padding: ".2em", fontSize: ".8em" }}>Buscando categoria......</div> : (
          !showForm ? null : (
            <>
  
              <Input
                label="Novo Nome"
                name="description"
                value={ description }
                onChange={ handleOnChange }
              />

              <ValidationMessages messages={ validation.description } />

              <Checkbox
                name="createNewMotherCategory"
                label="Criar uma nova categoria mãe"
                onChange={ handleOnCheck }
                checked={ createNewMotherCategory }
              />

              {
                createNewMotherCategory ? (
                  <Input
                    label="Nome da nova categoria mãe"
                    name="motherCategoryDescription"
                    value={ motherCategoryDescription }
                    onChange={ handleOnChange }
                  />
                ) : (
                  <div>
                    <AsyncSelect
                      fetchData={ fetchMotherCategoryOpts }
                      onChange={ handleMotherCategoryOnChange }
                      value={ selectedMotherCategory }
                      label="Categoria Mãe"
                    />
                    {
                      selectedMotherCategory === null ? null : (
                        <div>
                          <Button type="confirm" disabled={ selectedMotherCategory === null } onClick={ () => handleEditMotherOnClick(selectedMotherCategory) }>
                            Editar Categoria Mãe
                          </Button>
                          <br/>
                          <br/>
                        </div>
                      )
                    }
                  </div>
                )
              }

              <ValidationMessages messages={ validation.motherCategory } />

              <Checkbox
                name="createNewAuntCategory"
                label="Criar uma nova categoria Tia"
                onChange={ handleOnCheck }
                checked={ createNewAuntCategory }
              />

              {
                createNewAuntCategory ? (
                  <Input
                    label="Nome da nova categoria Tia"
                    name="auntCategoryDescription"
                    value={ auntCategoryDescription }
                    onChange={ handleOnChange }
                  />
                ) : (
                  <div>
                    <AsyncSelect
                      fetchData={ fetchAuntCategoryOpts }
                      onChange={ handleAuntCategoryOnChange }
                      value={ selectedAuntCategory }
                      label="Categoria Tia"
                    />
                    {
                      selectedAuntCategory === null ? null : (
                        <div>
                          <Button type="confirm" disabled={ selectedAuntCategory === null } onClick={ () => handleEditAuntOnClick(selectedAuntCategory) }>
                            Editar Categoria Tia
                          </Button>
                          <br/>
                          <br/>
                        </div>
                      )
                    }
                  </div>
                )
              }

              <AsyncSelect
                fetchData={ fetchProductCategoryOpts }
                onChange={ handleLvl1CategoryOnChange }
                value={ selectedLvl1Category }
                label="Categoria Nível 1"
              />

              <ValidationMessages messages={ validation.lvl1Category } />

              <AsyncSelect
                fetchData={ fetchProductCategoryOpts }
                onChange={ handleProductCategorySelectOnChange }
                label="Adicione segmentos"
              />

              <ValidationMessages messages={ validation.productCategory } />

              <div className={ styles.removeLines }>
                {
                  selectedProductCategories.map(category => (

                    <div key={ category.id } className={ styles.removeLine }>
                      <button className={ styles.removeButton } onClick={ () => removeProductCategory(category.id) }>
                        -
                      </button>
                      { category.name }
                    </div>

                  ))
                }
              </div>
              {
                confirmation === "save" ? (
                  <div>
                    <h3>Tem certeza que quer savar?</h3>
                    <Button type="confirm" disabled={ validation.errors > 0 || sendingCategory } onClick={ handleSendButtonOnClick }>
                      Sim
                    </Button> {" "}
                    <Button type="cancel" disabled={ sendingCategory } onClick={ () => toggleConfirmation(null) }>
                      Não
                    </Button>
                  </div>
                ) : (
                  confirmation === "delete" ? (
                    <div>
                      <h3>Tem certeza que quer deletar?</h3>
                      <p>Todas as segmentos atrelados a essa categoria não terão mais vínculo de categoria e os produtos que forem desses segmentos terão o campo de id de categoria relatório nulificados.</p>
                      <p>Certifique-se de reestabelecer novos vínculos entre os segmentos com outras categorias, se for o caso.</p>
                      <Button type="confirm" disabled={ sendingCategory } onClick={ handleDeleteButtonOnClick }>
                        Sim
                      </Button> {" "}
                      <Button type="cancel" disabled={ sendingCategory } onClick={ () => toggleConfirmation(null) }>
                        Não
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Button type="confirm" disabled={ validation.errors > 0 || sendingCategory } onClick={ () => toggleConfirmation("save") }>
                        { buttonText }
                      </Button> {" "}

                      <Button type='cancel' disabled={ sendingCategory || !edit } onClick={ () => toggleConfirmation("delete") }>
                        Deletar
                      </Button>
                    </div>
                  )
                )
              }
            
            </>
          )
        )
      }
    </div>
  );

}

export default NielsenCategoryCreatePage;