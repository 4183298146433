import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { isEmpty } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useHandleOnChange from "../../../service/form/useHandleOnChange";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import useQuery from "../../../service/url/useQuery";
import Button from "../../form/Button";
import styles from "../../ui/ui.module.css";

export default function usePartnerListPage() {
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const { filter: formFilter } = state;

  const query = useQuery();

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const { openModal, closeModal } = useModal();

  const findPartners = useCallback(
    () => baseFindPartners(page, openModal, setState, filter),
    [page, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, formFilter, e),
    [history, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [history, formFilter]
  );

  const handleEditOnClick = useCallback(
    (id: number) => baseHandleOnEditClick(history, id),
    [history]
  );

  const handleResetPasswordOnClick = useCallback(
    (id: number, name: string) => baseHandleOnResetPassword(history, id, name),
    [history]
  );

  const confirmInactivatePartner = useCallback(
    (id: number) =>
      baseConfirmInactivatePartner(
        id,
        openModal,
        closeModal,
        setState,
        findPartners
      ),
    [openModal, closeModal, setState, findPartners]
  );

  const confirmActivatePartner = useCallback(
    (id: number) =>
      baseConfirmActivatePartner(
        id,
        openModal,
        closeModal,
        setState,
        findPartners
      ),
    [openModal, closeModal, setState, findPartners]
  );

  const handleAddFilterClick = useCallback(
    (name: string, username: string) =>
      baseHandleAddFilterClick(name, username, history),
    [history]
  );

  const handleAddWebhookClick = useCallback(
    (name: string, username: string) =>
      baseHandleAddWebhookClick(name, username, history),
    [history]
  );

  useEffect(() => {
    findPartners();
  }, [findPartners]);

  return {
    ...state,
    page,
    findPartners,
    handleFindOnSubmit,
    handlePageOnChange,
    handleOnChange,
    handleEditOnClick,
    handleResetPasswordOnClick,
    confirmActivatePartner,
    confirmInactivatePartner,
    handleAddFilterClick,
    handleAddWebhookClick,
  };
}

interface State {
  filter: string;
  totalPages: number | null;
  items: Array<PartnerResumedInfo> | null;
  loading: boolean;
  listEan: string;
}

interface PartnerResumedInfo {
  id: number;
  username: string;
  email: string;
  name: string;
  active: number;
  role: string;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  totalPages: null,
  loading: false,
  items: null,
  listEan: "",
};

async function baseFindPartners(
  page: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(`${baseUrl}/api/v1/partner/search`, {
      params: { page, filter },
    });

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  if (filter) {
    history.push("/user/partner/search/1?filter=" + filter);
  } else {
    history.push("/user/partner/search/1?filter=");
  }
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number
) {
  history.push(
    "/user/partner/search/" +
      page +
      "?filter=" +
      (!isEmpty(filter) ? filter : "")
  );
}

function baseHandleOnEditClick(history: History, id: number) {
  history.push("/user/partner/edit/" + id);
}

function baseHandleOnResetPassword(history: History, id: number, name: string) {
  history.push(`/user/partner/reset-password/${id}/${name}`);
}

function baseConfirmActivatePartner(
  id: number,
  openModal: OpenModal,
  closeModal: CloseModal,
  setState: SetState,
  findPartners: () => void
) {
  const confirmModal = (
    <div className={styles.center}>
      <p>Tem certeza que deseja ativar o parceiro?</p>
      <Button onClick={handleConfirmClick} type="confirm">
        Confirmar
      </Button>{" "}
      <Button onClick={handleCancelClick} type="cancel">
        Cancelar
      </Button>
    </div>
  );

  function handleConfirmClick() {
    baseHandleOnActivateClick(id, openModal, setState, findPartners);
    closeModal();
  }

  function handleCancelClick() {
    closeModal();
  }

  openModal(confirmModal);
}

async function baseHandleOnActivateClick(
  id: number,
  openModal: OpenModal,
  setState: SetState,
  findPartners: () => void
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.put(`${baseUrl}/api/v1/partner/activate/` + id);

    openModal(response.data.result);

    findPartners();
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseConfirmInactivatePartner(
  id: number,
  openModal: OpenModal,
  closeModal: CloseModal,
  setState: SetState,
  findPartners: () => void
) {
  const confirmModal = (
    <div className={styles.center}>
      <p>Tem certeza que deseja inativar o parceiro?</p>
      <Button onClick={handleConfirmClick} type="confirm">
        Confirmar
      </Button>{" "}
      <Button onClick={handleCancelClick} type="cancel">
        Cancelar
      </Button>
    </div>
  );

  function handleConfirmClick() {
    baseHandleOnInactivateClick(id, openModal, setState, findPartners);
    closeModal();
  }

  function handleCancelClick() {
    closeModal();
  }

  openModal(confirmModal);
}

async function baseHandleOnInactivateClick(
  id: number,
  openModal: OpenModal,
  setState: SetState,
  findPartners: () => void
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.put(
      `${baseUrl}/api/v1/partner/inactivate/` + id
    );

    openModal(response.data.result);

    findPartners();
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleAddFilterClick(
  name: string,
  username: string,
  history: History
) {
  history.push(`/user/partner/filters/${username}/${name}`);
}

function baseHandleAddWebhookClick(
  name: string,
  username: string,
  history: History
) {
  history.push(`/user/partner/webhook/${username}/${name}`);
}
