import { ReactNode } from "react";

import styles from "./ui.module.css";

interface Props {
  children: ReactNode,
  type: "info" | "warn"
}

const classMap: Record<Props["type"], { style: string, title: string }> = {
  info: { style: styles.alertInfo, title: "Informação" },
  warn: { style: styles.alertWarn, title: "Alerta" }
}

export default function Alert(props: Props) {
  return (
    <div className={ `${ styles.alert } ${ classMap[props.type].style }` }>
      <div className={ styles.alertTitle }>
        { classMap[props.type].title }
      </div>
      <div className={ styles.alertBody }>
        { props.children }
      </div>
    </div>
  )
}