import { Key, useCallback, useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import { mediaPrefix } from "../../../constants";
import { isEmpty, isEmptyList } from "../../../utils/validation";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../service/modal/useModal";
import Pagination from "../ui/Pagination";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";
import Button from "./Button";
import Input from "./Input";

const typeMap = {
  companyBrand: "Marca de Empresa",
  productBrand: "Marca de Produto",
  productCategory: "Categoria de Produto",
  Manufacturer: "Fabricante",
};
interface Props {
  fetchData: (
    form: AsyncSelectModalForm
  ) => Promise<PaginationType<Record<string, any>>>;
  onSelectItem: (item: Record<string, any> | null) => void;
  imageSrcColumn: string;
  value: string;
  type: keyof typeof typeMap;
}

interface ModalRef {
  toggleModal: (open: boolean) => void;
}

export type PaginationType<T> = {
  items: Array<T>;
  total: number;
  page: number;
};

export interface AsyncSelectModalForm {
  filter: string;
  page: number;
  pageSize: number;
}

interface State<T> {
  data: PaginationType<T> | null;
  searchText: string;
  pageSize: number;
  loading: boolean;
  imageSrcIndex: number;
}

type SetState<T> = React.Dispatch<React.SetStateAction<State<T>>>;

export default function AsyncSelectModal(props: Props) {
  const { fetchData, onSelectItem, imageSrcColumn, value, type } = props;
  const { openModal } = useModal();
  const [hidden, setHidden] = useState<boolean>(true);
  const [state, setState] = useState<State<Record<string, any>>>({
    data: null,
    searchText: "",
    pageSize: 10,
    loading: false,
    imageSrcIndex: -1,
  });

  const searchTextRef = useRef<string>("");
  const modalRef = useRef<ModalRef>();
  const handleToggleModal = () => {
    setHidden(false);
    if (modalRef.current) {
      modalRef.current.toggleModal(true);
    }
  };

  const fetchSelectModalData = useCallback(
    (page: number) =>
      baseFetchSelectModalData(
        fetchData,
        openModal,
        setState,
        state.searchText,
        state.pageSize,
        imageSrcColumn,
        page
      ),
    [state.searchText, state.pageSize, imageSrcColumn]
  );

  const TableHeaders = () => {
    if (!state.data || state.data.items.length === 0) {
      return null;
    }

    const headers = Object.keys(state.data.items[0]);

    return (
      <tr>
        {headers.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    );
  };

  const TableBody = () => {
    if (state.loading) {
      return (
        <tr>
          <td colSpan={15}>Carregando...</td>
        </tr>
      );
    }

    if (!state.data) {
      return null;
    }

    if (state.data.items.length === 0) {
      return (
        <tr>
          <td colSpan={15}>Nenhum resultado encontrado.</td>
        </tr>
      );
    }

    return (
      <>
        {state.data?.items.map((item: Record<string, any>, index: Key) => (
          <tr key={index} onClick={() => onItemClick(item)}>
            {Object.values(item).map((value, subIndex) => (
              <td key={subIndex}>
                {subIndex !== state.imageSrcIndex ? (
                  value
                ) : (
                  <div className={styles.imageWrapper}>
                    {!isEmpty(value) ? (
                      <img src={mediaPrefix + value} alt="Logo da Marca" />
                    ) : (
                      <span className={styles.imageMissing}>
                        Sem
                        <br />
                        imagem
                      </span>
                    )}
                  </div>
                )}
              </td>
            ))}
          </tr>
        ))}
      </>
    );
  };

  function onItemClick(item: Record<string, any> | null) {
    onSelectItem(item);
    setHidden(true);
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTextRef.current === state.searchText) {
        fetchSelectModalData(1);
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [fetchSelectModalData, state.searchText]);

  useEffect(() => {
    searchTextRef.current = state.searchText;
  }, [state.searchText]);

  return (
    <div style={{ marginTop: "18px", marginBottom: "10px" }}>
      <Button
        type={isEmpty(value) ? "cancel" : "confirm"}
        onClick={handleToggleModal}
      >
        {isEmpty(value) ? "Buscar " + typeMap[type] : value}
      </Button>{" "}
      {isEmpty(value) ? null : (
        <Button type="cancel" onClick={() => onItemClick(null)}>
          Remover {typeMap[type]}
        </Button>
      )}
      <Modal close={() => {}} ref={modalRef} hidden={hidden}>
        <div>
          <Input
            value={state.searchText}
            onChange={(e) =>
              setState((prev) => ({ ...prev, searchText: e.target.value }))
            }
            placeholder="Digite..."
            label="Busca automática a partir do 2º caractere:"
          />

          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>
                <TableHeaders />
              </thead>
              <tbody>
                <TableBody />
              </tbody>
            </table>
          </TableResponsiveWrapper>
          {!state.data ? null : (
            <Pagination
              page={state.data.page}
              totalPages={state.data.total}
              onChangePage={fetchSelectModalData}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

async function baseFetchSelectModalData<T extends Record<string, any>>(
  itemsSearch: (form: AsyncSelectModalForm) => Promise<PaginationType<T>>,
  openModal: OpenModal,
  setState: SetState<T>,
  searchText: string,
  pageSize: number,
  imageSrcColumn: string,
  page: number
) {
  if (isEmpty(searchText) || searchText.length < 2) return;

  const form = {
    filter: searchText,
    page: page,
    pageSize: pageSize,
  };

  setState((prev) => ({ ...prev, loading: true }));

  try {
    const data = await itemsSearch(form);
    setState((prev) => ({
      ...prev,
      data,
      imageSrcIndex: !isEmptyList(data.items)
        ? Object.keys(data.items[0]).findIndex((h) => h === imageSrcColumn)
        : -1,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}
