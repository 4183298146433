import { History } from "history";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { FetchDataResult, Option } from "../../components/form/AsyncSelect";
import { getErrorMessage } from "../error/getErrorMessage";
import useHandleOnChange from "../form/useHandleOnChange";
import useHandleOnCheck from "../form/useHandleOnCheck";
import useModal, { OpenModal } from "../modal/useModal";
import useProductRequests, {
  DownloadCsv,
  FindProduct,
  ProductFilterCsvDownloadForm,
  ProductFilterForm,
  ProductSearchView,
} from "./useProductRequests";

function useProductListPage() {
  const history = useHistory();

  const productRequests = useProductRequests();

  const { findProduct, downloadCsv } = productRequests;

  const fetchProductCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchOpts("product-category", filter, offset);

  const fetchNielsenCategoryOpts = (
    filter: string,
    offset: number | undefined
  ) => baseFetchOpts("nielsen-category", filter, offset);

  const fetchProductBrandOpts = (filter: string, offset: number | undefined) =>
    baseFetchOpts("product-brand", filter, offset);

  const fetchManufacturerOpts = (filter: string, offset: number | undefined) =>
    baseFetchOpts("manufacturer", filter, offset);

  const { openModal } = useModal();

  const [state, setState] = useState(initialState);

  const handleOnChange = useHandleOnChange(setState);
  const handleOnCheck = useHandleOnCheck(setState);

  const handleProductCategorySelectOnChange = useCallback(
    (o: Option<null> | null) => baseProductCategorySelectOnChange(setState, o),
    [setState]
  );
  const handleNielsenCategorySelectOnChange = useCallback(
    (o: Option<null> | null) => baseNielsenCategorySelectOnChange(setState, o),
    [setState]
  );
  const handleProductBrandSelectOnChange = useCallback(
    (o: Option<null> | null) => baseBrandCategorySelectOnChange(setState, o),
    [setState]
  );
  const handleManufacturerSelectOnChange = useCallback(
    (o: Option<null> | null) => baseManufacturerSelectOnChange(setState, o),
    [setState]
  );
  const handleModerateOnClick = useCallback(
    (ean: string) => baseModerationOnClick(history, ean),
    [history]
  );

  const removeProductCategory = useCallback(
    (id: string) => baseRemoveProductCategory(setState, id),
    [setState]
  );
  const removeNielsenCategory = useCallback(
    (id: string) => baseRemoveNielsenCategory(setState, id),
    [setState]
  );
  const removeProductBrand = useCallback(
    (id: string) => baseRemoveProductBrand(setState, id),
    [setState]
  );
  const removeManufacturer = useCallback(
    (id: string) => baseRemoveManufacturer(setState, id),
    [setState]
  );

  const fetchData = useCallback(
    (page: number) =>
      baseFetchData(findProduct, setState, openModal, state, page),
    [state]
  );

  const onDownloadCsvClick = useCallback(
    () => baseOnDownloadCsvClick(downloadCsv, setState, openModal, state),
    [state]
  );

  useEffect(() => {
    baseOnEnterComponentEffect(findProduct, setState, openModal);
  }, []);

  return {
    ...state,
    handleOnChange,
    handleProductCategorySelectOnChange,
    handleNielsenCategorySelectOnChange,
    handleProductBrandSelectOnChange,
    handleManufacturerSelectOnChange,
    fetchProductCategoryOpts,
    fetchNielsenCategoryOpts,
    fetchProductBrandOpts,
    fetchData,
    fetchManufacturerOpts,
    removeProductCategory,
    removeNielsenCategory,
    removeProductBrand,
    removeManufacturer,
    handleOnCheck,
    handleModerateOnClick,
    setState,
    onDownloadCsvClick,
  };
}

type SetState = Dispatch<SetStateAction<State>>;

interface State {
  data: ProductSearchView | null;
  listEans: string | null;
  listIds: string | null;
  selectedBrands: Option<null>[];
  selectedManufacturers: Option<null>[];
  selectedNielsenCategories: Option<null>[];
  selectedProductCategories: Option<null>[];
  sendingCategory: boolean;
  fetchingCategory: boolean;
  pageSize: number;
  loading: boolean;
  loadingExcel: boolean;
}

const initialState: State = {
  data: null,
  listEans: null,
  listIds: null,
  selectedBrands: [],
  selectedManufacturers: [],
  selectedNielsenCategories: [],
  selectedProductCategories: [],
  sendingCategory: false,
  fetchingCategory: false,
  pageSize: 100,
  loading: false,
  loadingExcel: false,
};

interface OptionsView {
  result: {
    value: number;
    description: string;
  }[];
}

async function baseOnEnterComponentEffect(
  findProduct: FindProduct,
  setState: SetState,
  openModal: OpenModal
) {
  const form: ProductFilterForm = {
    eans: undefined,
    ids: undefined,
    nielsenIds: undefined,
    productCategoryIds: undefined,
    manufacturerIds: undefined,
    brandIds: undefined,
    page: 1,
    pageSize: 100,
  };

  try {
    const data = await findProduct(form);
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseFetchOpts(
  type: string,
  filter: string,
  offset: number | undefined
): Promise<FetchDataResult<null>> {
  const response = await http.get(`${baseUrl}/api/v1/${type}/options`, {
    params: { filter, offset, limit: 10 },
  });

  const data = response.data as OptionsView;

  return {
    options: data.result.map((i) => ({
      id: "" + i.value,
      name: i.description,
    })),
    hasMore: data.result.length === 10,
  };
}

function baseProductCategorySelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedProductCategories: [...prev.selectedProductCategories, option],
    };
  });
}

function baseNielsenCategorySelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedNielsenCategories: [...prev.selectedNielsenCategories, option],
    };
  });
}

function baseBrandCategorySelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedBrands: [...prev.selectedBrands, option],
    };
  });
}

function baseManufacturerSelectOnChange(
  setState: SetState,
  option: Option<null> | null
) {
  setState((prev) => {
    if (option === null) return prev;

    return {
      ...prev,
      selectedManufacturers: [...prev.selectedManufacturers, option],
    };
  });
}

function baseRemoveProductCategory(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedProductCategories: [...prev.selectedProductCategories].filter(
      (o) => o.id !== id
    ),
  }));
}

function baseRemoveNielsenCategory(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedNielsenCategories: [...prev.selectedNielsenCategories].filter(
      (o) => o.id !== id
    ),
  }));
}

function baseRemoveProductBrand(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedBrands: [...prev.selectedBrands].filter((o) => o.id !== id),
  }));
}

function baseRemoveManufacturer(setState: SetState, id: string) {
  setState((prev) => ({
    ...prev,
    selectedManufacturers: [...prev.selectedManufacturers].filter(
      (o) => o.id !== id
    ),
  }));
}

async function baseFetchData(
  findProduct: FindProduct,
  setState: SetState,
  openModal: OpenModal,
  state: State,
  page: number
) {
  if (state.listEans === "") state.listEans = null;
  if (state.listIds === "") state.listIds = null;

  const form: ProductFilterForm = {
    eans: state.listEans?.replaceAll(/ +/g, "").split(","),
    ids: state.listIds?.replaceAll(/ +/g, "").split(","),
    nielsenIds: state.selectedNielsenCategories.map((o) => parseInt(o.id)),
    productCategoryIds: state.selectedProductCategories.map((o) =>
      parseInt(o.id)
    ),
    manufacturerIds: state.selectedManufacturers.map((o) => parseInt(o.id)),
    brandIds: state.selectedBrands.map((o) => parseInt(o.id)),
    page: page,
    pageSize: state.pageSize,
  };

  setState((prev) => ({ ...prev, loading: true, data: null }));

  try {
    const data = await findProduct(form);
    setState((prev) => ({ ...prev, data }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseOnDownloadCsvClick(
  downloadCsv: DownloadCsv,
  setState: SetState,
  openModal: OpenModal,
  state: State
) {
  if (state.listEans === "") state.listEans = null;
  if (state.listEans === "") state.listEans = null;

  const form: ProductFilterCsvDownloadForm = {
    eans: state.listEans?.replaceAll(/ +/g, "").split(","),
    ids: state.listIds?.replaceAll(/ +/g, "").split(","),
    nielsenIds: state.selectedNielsenCategories.map((o) => parseInt(o.id)),
    productCategoryIds: state.selectedProductCategories.map((o) =>
      parseInt(o.id)
    ),
    manufacturerIds: state.selectedManufacturers.map((o) => parseInt(o.id)),
    brandIds: state.selectedBrands.map((o) => parseInt(o.id)),
  };

  try {
    setState((prev) => ({ ...prev, loadingExcel: true }));

    const csvData = await downloadCsv(form);

    const csvFile = new Blob([csvData], { type: "text/csv" });

    let tempLink = document.createElement("a");

    tempLink.download = "products.csv";
    let url = window.URL.createObjectURL(csvFile);
    tempLink.href = url;

    tempLink.style.display = "none";
    document.body.appendChild(tempLink);

    tempLink.click();
    document.body.removeChild(tempLink);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loadingExcel: false }));
  }
}

export default useProductListPage;

function baseModerationOnClick(history: History<unknown>, ean: string) {
  history.push("/product2/" + ean);
}
