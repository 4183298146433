import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { isEmpty } from "../../../utils/validation";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import { History, SetState } from "../../types";
import useModal, { OpenModal } from "../modal/useModal";
import useCompanyRequests, {
  FetchCompanyQueue,
  VerifyRegisteredCnpjs,
} from "./useCompanyRequests";

export default function useCompanyModerationSearchPage() {
  const [state, setState] = useState<State>({ loading: false, cnpjs: "" });

  const { cnpjs } = state;

  const { fetchCompanyQueue, verifyRegisteredCnpjs } = useCompanyRequests();

  const { openModal } = useModal();

  const history = useHistory();

  const handleOnChange = useHandleOnChange(setState);

  const handleFetchQueue = () =>
    baseFetchQueue(history, fetchCompanyQueue, setState, openModal);

  const handleManualQueue = useCallback(
    () =>
      baseManualQueue(
        cnpjs,
        history,
        verifyRegisteredCnpjs,
        setState,
        openModal
      ),
    [cnpjs, history, verifyRegisteredCnpjs]
  );

  return { ...state, handleFetchQueue, handleOnChange, handleManualQueue };
}

interface State {
  loading: boolean;
  cnpjs: string;
}

async function baseFetchQueue(
  history: History,
  fetchQueue: FetchCompanyQueue,
  setState: SetState<State>,
  openModal: OpenModal
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));
    const nextComp = await fetchQueue();
    nextComp
      ? history.push("/company/" + nextComp)
      : openModal("Nenhum cnpj encontrado para iniciar a fila de moderação");
  } catch (err) {
    openModal("Erro ao buscar fila de empresas");
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseManualQueue(
  cnpjs: string,
  history: History,
  registeredCnpjs: VerifyRegisteredCnpjs,
  setState: SetState<State>,
  openModal: OpenModal
) {
  setState((prev) => ({ ...prev, loading: true }));

  if (isEmpty(cnpjs)) return;

  try {
    const cnpjList = cnpjs.split(",");

    const result = await registeredCnpjs(cnpjList);

    if (result.length === 0) {
      openModal(
        "Nenhum cnpj válido encontrado para iniciar a fila de moderação"
      );
      return;
    }

    history.push("/x/company/" + result.pop());
  } catch (err) {
    openModal("Erro ao buscar fila de empresas");
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}
