import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Option } from "../../components/form/AsyncSelect";
import Button from "../../components/form/Button";
import useFetchUserOptions from "../../components/user/user/useFetchUserOptions";
import { History, SetState } from "../../types";
import { getErrorMessage } from "../error/getErrorMessage";
import useModal, { OpenModal } from "../modal/useModal";
import useClientRequests, {
  AttachUserToClient,
  Client,
  ClientInformationView,
  ClientSaveForm,
  DetachUserFromClient,
  GetClientInformation,
} from "./useClientRequests";
import useFetchClientOptions from "./useFetchClientOptions";

export default function useClientCreatePage(edit: boolean) {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const { openModal, closeModal } = useModal();

  const {
    getClientInformation,
    deleteClient,
    save,
    sendWelcomeEmailToAll,
    attachUserToClient,
    detachUserFromClient,
  } = useClientRequests();

  const [state, setState] = useState(initialState);

  const { loading, selectedEditClient, selectedUser } = state;

  const validation = useMemo(() => validate(state), [state]);

  const buttonText = useMemo(
    () => getButtonText(validation, edit, loading),
    [validation, loading, edit]
  );

  const name = selectedEditClient?.name;

  const fetchClientOpts = useFetchClientOptions();

  const fetchUserOptions = useFetchUserOptions();

  const handleFormOnChange = useCallback(
    (e: Event) => {
      setState((prev) => ({
        ...prev,
        form: {
          ...prev.form,
          [e.target.name]: e.target.value === "" ? null : e.target.value,
        },
      }));
    },
    [setState]
  );

  const handleSendButtonOnClick = useCallback(
    () =>
      baseHandleSendButtonOnClick(save, setState, state, openModal, history),
    [state]
  );

  const handleEditClientOnChange = (selected: Option<null> | null) =>
    baseHandleEditCLientOnChange(history, selected);

  const handleSelectedUserOnChange = (selected: Option<null> | null) =>
    baseHandleSelectedUserOnChange(setState, selected);

  const handleConfirmWelcomeEmail = useCallback(
    () =>
      baseHandleConfirmWelcomeEmail(
        sendWelcomeEmailToAll,
        setState,
        openModal,
        id ? parseInt(id) : undefined
      ),
    [id]
  );

  const handleWelcomeEmailOnClick = () =>
    baseHandleWelcomeEmailOnClick(
      handleConfirmWelcomeEmail,
      openModal,
      closeModal
    );

  const handleConfirmDeleteClientOnClick = useCallback(
    () =>
      baseHandleConfirmDeleteClientOnClick(
        deleteClient,
        setState,
        id ? parseInt(id) : undefined,
        history,
        openModal
      ),
    [id]
  );

  const handleDeleteClientOnClick = useCallback(
    () =>
      baseHandleDeleteClientOnClick(
        handleConfirmDeleteClientOnClick,
        name,
        openModal,
        closeModal
      ),
    [name]
  );

  const handleOnAddUserClick = useCallback(
    () =>
      baseHandleOnAddUserClick(
        getClientInformation,
        attachUserToClient,
        loadCLientEffect,
        openModal,
        setState,
        selectedUser,
        id ? parseInt(id) : undefined
      ),
    [selectedUser, id]
  );

  const handleOnRemoveUserClick = useCallback(
    (userId: number) =>
      baseHandleOnRemoveUserClick(
        getClientInformation,
        detachUserFromClient,
        loadCLientEffect,
        openModal,
        setState,
        id ? parseInt(id) : undefined,
        userId
      ),
    [id]
  );

  useEffect(
    () => loadCLientEffect(getClientInformation, setState, openModal, id),
    [id]
  );

  const showForm = !edit || id;

  return {
    ...state,
    id,
    validation,
    showForm,
    buttonText,
    handleEditClientOnChange,
    handleFormOnChange,
    fetchClientOpts,
    handleSendButtonOnClick,
    fetchUserOptions,
    handleDeleteClientOnClick,
    handleOnAddUserClick,
    handleWelcomeEmailOnClick,
    handleOnRemoveUserClick,
    handleSelectedUserOnChange,
  };
}

type Event = { target: { value?: any; name?: any } };

interface Validation {
  name: string[];
  cnpj: string[];
  errors: number;
}

interface State {
  form: {
    hubspotId: string | null;
    id: number | null;
    name: string;
    cnpj: string;
    expireDate: string | null;
  };
  selectedEditClient: Option<null> | null;
  users: {
    id: number;
    username: string;
  }[];
  loading: boolean;
  fetchingClient: boolean;
  removingUsers: number[];
  addingUsers: boolean;
  selectedUser: Option<null> | null;
}

const initialState: State = {
  form: {
    hubspotId: null,
    id: null,
    name: "",
    cnpj: "",
    expireDate: null,
  },
  selectedEditClient: null,
  users: [],
  loading: false,
  fetchingClient: false,
  removingUsers: [],
  addingUsers: false,
  selectedUser: null,
};

function validate(state: State): Validation {
  const validation: Validation = {
    name: [],
    cnpj: [],
    errors: 0,
  };

  if (
    !state.form.name ||
    !state.form.name.match(
      /^[a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+-¨]+( [a-zA-Zà-úÀ-Úä-üÄ-Ü0-9&+-¨]+)*$/
    )
  ) {
    validation.name.push("O nome é obrigatório");
    validation.errors++;
  }
  if (
    !state.form.cnpj ||
    !state.form.cnpj.match(
      /^[a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+-¨]+( [a-zA-Zà-úÀ-Úä-üÄ-Ü0-9&+-¨]+)*$/
    )
  ) {
    validation.cnpj.push("A indentificação é obrigatória");
    validation.errors++;
  }

  return validation;
}

function getButtonText(
  validation: Validation,
  edit: boolean,
  loading: boolean
) {
  if (loading) {
    return "Carregando...";
  }

  if (validation.errors > 0) {
    return validation.errors === 1
      ? `Existe 1 erro`
      : `Existem ${validation.errors} erros`;
  }

  return !edit ? "Criar" : "Editar";
}

function baseHandleEditCLientOnChange(
  history: History,
  selectedClient: Option<null> | null
) {
  if (!selectedClient) {
    history.push(`/client/edit/`);
    return;
  }

  history.push(`/client/edit/${selectedClient?.id}`);
}

function baseHandleSelectedUserOnChange(
  setState: SetState<State>,
  selected: Option<null> | null
) {
  setState((prev) => ({ ...prev, selectedUser: selected }));
}

async function baseHandleDeleteClientOnClick(
  deleteClient: () => void,
  name: string | undefined,
  openModal: OpenModal,
  closeModal: () => void
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá deletar o Cliente {name}.<br />
        Confirma?
      </div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          deleteClient();
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

async function baseHandleConfirmDeleteClientOnClick(
  deleteClient: (id: number) => Promise<void>,
  setState: SetState<State>,
  id: number | undefined,
  history: History,
  openModal: OpenModal
) {
  if (!id) return;

  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    await deleteClient(id!);

    openModal("Cliente deletado com sucesso.");

    history.push("/client/list/1");
  } catch (e) {
    openModal(getErrorMessage(e));

    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

async function baseHandleWelcomeEmailOnClick(
  sendWelcomeEmail: () => void,
  openModal: OpenModal,
  closeModal: () => void
) {
  openModal(
    <div style={{ textAlign: "center" }}>
      <div style={{ marginBottom: "1em" }}>
        Você irá enviar email de boas-vindas a todos os usuários deste cliente.
        <br />
        Confirma?
      </div>
      <Button
        type="confirm"
        onClick={() => {
          closeModal();
          sendWelcomeEmail();
        }}
      >
        Sim
      </Button>{" "}
      <Button type="cancel" onClick={closeModal}>
        Não
      </Button>
    </div>
  );
}

async function baseHandleConfirmWelcomeEmail(
  sendWelcomeEmail: (id: number) => Promise<void>,
  setState: SetState<State>,
  openModal: OpenModal,
  id: number | undefined
) {
  if (!id) return;

  setState((prev) => ({ ...prev, sendingCategory: true }));

  try {
    await sendWelcomeEmail(id!);

    openModal("Emails enviados.");
  } catch (e) {
    openModal(getErrorMessage(e));

    setState((prev) => ({ ...prev, sendingCategory: false }));
  }
}

async function baseHandleSendButtonOnClick(
  save: (client: ClientSaveForm) => Promise<Client>,
  setState: SetState<State>,
  state: State,
  openModal: OpenModal,
  history: History
) {
  try {
    const form = {
      id: state.form.id!,
      name: state.form.name,
      cnpj: state.form.cnpj,
      expireDate: state.form.expireDate,
      hubspotId: state.form.hubspotId?.trim(),
    };

    const response = await save(form);

    openModal("Dados do Cliente salvados com sucesso.");

    history.push(`/client/edit/${response.id || ""}`);
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

async function baseHandleOnAddUserClick(
  getClientInformation: GetClientInformation,
  attachUserToClient: AttachUserToClient,
  loadClient: (
    fetchClient: (id: number) => Promise<ClientInformationView>,
    setState: SetState<State>,
    openModal: OpenModal,
    id: string | undefined
  ) => void,
  openModal: OpenModal,
  setState: SetState<State>,
  selectedUser: Option<null> | null,
  id: number | undefined
) {
  if (!id) return;

  try {
    setState((prev) => ({ ...prev, adding: true }));

    await attachUserToClient({
      clientId: id!,
      userId: parseInt(selectedUser!.id),
    });

    setState((prev) => ({ ...prev, selectedUser: null, adding: false }));

    loadClient(getClientInformation, setState, openModal, id?.toString());
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function baseHandleOnRemoveUserClick(
  getClientInformation: GetClientInformation,
  detachUserFromClient: DetachUserFromClient,
  loadClient: (
    fetchClient: (id: number) => Promise<ClientInformationView>,
    setState: SetState<State>,
    openModal: OpenModal,
    id: string | undefined
  ) => void,
  openModal: OpenModal,
  setState: SetState<State>,
  id: number | undefined,
  userId: number
) {
  if (!id) return;

  try {
    setState((prev) => ({
      ...prev,
      removingUsers: [...prev.removingUsers, userId],
    }));

    await detachUserFromClient({ clientId: id!, userId });

    loadClient(getClientInformation, setState, openModal, id?.toString());
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function loadCLientEffect(
  fetchClient: (id: number) => Promise<ClientInformationView>,
  setState: SetState<State>,
  openModal: OpenModal,
  id: string | undefined
) {
  async function fn() {
    if (!id) {
      setState(initialState);
      return;
    }

    try {
      setState((prev) => ({ ...prev, fetchingClient: true }));

      const response = await fetchClient(parseInt(id));

      const form = {
        id: parseInt(id),
        name: response.name,
        cnpj: response.cnpj,
        expireDate: response.expireDate,
        hubspotId: response.hubspotId,
      };

      const selectedEditClient = { id, name: response.name };

      setState((prev) => ({
        ...prev,
        form,
        selectedEditClient,
        users: response.users,
      }));
    } catch (e) {
      openModal(getErrorMessage(e));
    } finally {
      setState((prev) => ({ ...prev, fetchingClient: false }));
    }
  }

  fn();
}
