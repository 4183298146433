import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/Button";
import Card from "../components/Card";
import Collapse from "../components/Collapse";
import Input from "../components/Input";
import Modal from "../components/Modal";
import Pagination from "../components/Pagination";
import Table from "../components/Table";
import Template from "../components/Template";
import { SubTitle, Title } from "../components/Titles";
import useGroup from "../hooks/useGroup";
import useHorusAccess from "../hooks/useHorusAccess";
import { isNotEmpty, makeValidator } from "../utils";
import { authCredentials } from "../utils/authCredentials";

function CreateGroupForm(props) {
  const [newGroup, setNewGroup] = useState({});
  const [collapsed, setCollapsed] = useState(true);
  const { onCreateGroup } = props;
  const { getNameErrors, getAllErrors } = userValidator;

  return (
    <form
      autoComplete="off"
      onSubmit={(e) => {
        e.preventDefault();
        if (
          window.confirm(
            "Criar grupo: " +
              newGroup.name +
              "?\n\nATENÇÃO: Certifique-se que todos os dados estão corretos."
          )
        ) {
          onCreateGroup(newGroup);
          setCollapsed(true);
        }
      }}
    >
      <Collapse collapsed={collapsed}>
        <SubTitle>Criar Novo Grupo</SubTitle>
        <Card>
          <Input
            label="Nome do grupo"
            value={newGroup.name}
            onChange={(e) => setNewGroup({ ...newGroup, name: e.target.value })}
            validate={getNameErrors}
          />
        </Card>
      </Collapse>
      {collapsed ? (
        <Button
          type="button"
          layout="confirm"
          onClick={() => setCollapsed(false)}
        >
          Adicionar Grupo
        </Button>
      ) : (
        <>
          <Button layout="confirm" disabled={getAllErrors(newGroup)}>
            Enviar
          </Button>{" "}
          <Button
            type="button"
            layout="cancel"
            onClick={() => setCollapsed(true)}
          >
            Cancelar
          </Button>
        </>
      )}
    </form>
  );
}

const userValidator = makeValidator({
  name: isNotEmpty("Nome"),
});

const Wrapper = styled.div``;

const breadcrump = [
  {
    name: "Início",
    path: "/dashboard",
  },
  {
    name: "Grupos",
    path: "/groups",
  },
];

function GroupPage() {
  const { getAllGroups, createGroup } = useGroup();
  const [groups, setGroups] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { createEvent } = useHorusAccess();
  const [event] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const indexLastItem = currentPage * itemsPerPage;
  const indexFirstItem = indexLastItem - itemsPerPage;
  const [searchGroups, setSearchGroups] = useState([]);
  const [currentGroups, setCurrentGroups] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  const onSearch = (search) => {
    setSearchGroups(groups);
    setTotalItems(groups.length);
    setCurrentPage(1);
    if (search === null || search === "") return;
    const foundGroups = groups.filter((group) =>
      group.name.toLowerCase().includes(search)
    );
    setSearchGroups(foundGroups);
    setCurrentGroups(foundGroups.slice(indexFirstItem, indexLastItem));
    setTotalItems(foundGroups.length);
    setCurrentPage(1);
  };

  const onChangePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentGroups(searchGroups.slice(indexFirstItem, indexLastItem));
  };

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access groups";

    createEvent(event);
  }, []);

  const onCreateGroup = async (group) => {
    setLoading(true);
    try {
      await createGroup(group);
      const groupsResponse = await getAllGroups();
      setGroups(groupsResponse.data);
      setMessage("Cadastro concluído");
    } catch (error) {
      setError(
        error.response && error.response.status === 400
          ? new Error("Grupo já existe")
          : error
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!groups) {
      getAllGroups().then((response) => {
        setGroups(response.data);
        setLoading(false);
      });
    }
  }, [groups, setGroups, setLoading, getAllGroups]);

  return (
    <Template breadcrump={breadcrump}>
      {!message ? null : (
        <Modal close={() => setMessage(null)}>{message}</Modal>
      )}
      {!error ? null : (
        <Modal close={() => setError(null)}>{error.message}</Modal>
      )}
      <Title>Grupos</Title>
      <Wrapper>
        <CreateGroupForm onCreateGroup={onCreateGroup} />
        <div style={{ textAlign: "center" }}>
          <input
            label="Busca"
            placeholder="Nome do Grupo"
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
        {!loading ? (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Índice</th>
                  <th>Grupo</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {currentGroups.map((group, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{group.name}</td>
                      <td>
                        <button
                          type="button"
                          layout="delete"
                          style={{ cursor: "pointer" }}
                          onClick={(e) => history.push("/groups/" + group.id)}
                        >
                          Visualizar perfil
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              onChangePage={onChangePage}
            />
          </>
        ) : (
          "Carregando..."
        )}
      </Wrapper>
    </Template>
  );
}

export default GroupPage;
