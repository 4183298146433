import { useCallback } from "react";

type SetState<T> = (update: (old: T) => T) => void;
type Event = { target: { checked?: boolean, name?: any } };

export type HandleOnCheck = ReturnType<typeof useHandleOnCheck>;

function useHandleOnCheck<T>(setState: SetState<T>) {

  return useCallback((e: Event) => setState((prev: any) => ({ ...prev, [ e.target.name ]: e.target.checked })), [ setState ]);

}

export default useHandleOnCheck;