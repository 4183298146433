import { useState } from "react";
import useAnalysisServicesRequests, {
  AnalysisServicesInstanceDetailed,
  FindAnalysisServicesDetailed,
} from "../../../service/dashboard/useAnalysisServicesRequests";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, {
  CloseModal,
  OpenModal,
} from "../../../service/modal/useModal";
import { useInterval } from "../../../service/time/useInterval";
import { SetState } from "../../../types";
import Button from "../../form/Button";
import { SectionTitle } from "../../ui/SectionTitle";
import styles from "./styles.module.css";

export default function AnalysisServicesManagementPage() {
  const { findAnalysisServicesDetailed } = useAnalysisServicesRequests();
  const { openModal, closeModal } = useModal();
  const [{ instances }, setState] = useState(initialState);

  const refresh = (showErrorModal: boolean) =>
    baseRefresh(
      { openModal, findAnalysisServicesDetailed, setState },
      showErrorModal
    );

  const onToggleClick = (instance: AnalysisServicesInstanceDetailed) =>
    baseOnToggleClick({ openModal, refresh, closeModal }, instance);

  useInterval(10_000, () => refresh(false), []);

  return (
    <div>
      <SectionTitle
        title="Analysis Services"
        description="Registrar e consultar instâncias do Analysis Services"
      />
      {!instances ? (
        "Carregando..."
      ) : (
        <div className={styles.cardWrapper}>
          {instances.map((instance) => {
            const azureStatus = adjustAzureStatus(instance.azureStatus);
            const status = instance.powered ? "Ativo" : "Inativo";
            return (
              <div className={styles.card} key={instance.name}>
                <div className={styles.instanceTitleTop}>Instância</div>
                <div className={styles.instanceTitle}>{instance.name}</div>
                <div className={styles.instanceSpacer} />
                <div className={styles.instanceStatusWrapper}>
                  {azureStatus === status ? (
                    <div className={styles.instanceProperty}>
                      <div className={styles.instancePropertyName}>
                        Status Interno/Azure
                      </div>
                      <div className={styles.instancePropertyValue}>
                        {getStatusIndicator(status)} {status}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={styles.instanceProperty}>
                        <div className={styles.instancePropertyName}>
                          Status Interno
                        </div>
                        <div className={styles.instancePropertyValue}>
                          {getStatusIndicator(status)} {status}
                        </div>
                      </div>
                      <div className={styles.instanceStatusItem}>
                        <div className={styles.instancePropertyName}>
                          Status Azure
                        </div>
                        <div className={styles.instancePropertyValue}>
                          {getStatusIndicator(azureStatus)} {azureStatus}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={styles.instanceSpacer} />
                <div className={styles.instanceStatusItem}>
                  <div className={styles.instancePropertyName}>
                    Último Acesso:
                  </div>
                  <div className={styles.instancePropertyValue}>
                    {adjustDt(instance.lastUseDt)}
                  </div>
                </div>
                {instance.powerOnManualRequestedAt && (
                  <>
                    <div className={styles.instanceSpacer} />
                    <div className={styles.instanceStatusItem}>
                      <div className={styles.instancePropertyName}>
                        Ativada Manualmente em:
                      </div>
                      <div className={styles.instancePropertyValue}>
                        {adjustDt(instance.powerOnManualRequestedAt)}
                      </div>
                    </div>
                  </>
                )}
                <div className={styles.instanceSpacer} />
                <div className={styles.instanceStatusItem}>
                  <div className={styles.instancePropertyName}>
                    Previsão de {instance.powered ? "Desativar" : "Ativar"}{" "}
                    Automaticamente em:
                  </div>
                  <div className={styles.instancePropertyValue}>
                    {adjustDt(instance.expectedNextToggleAt)}
                  </div>
                </div>
                <div className={styles.instanceSpaceRemaining} />
                <div>
                  <Button
                    type={instance.powered ? "cancel" : "confirm"}
                    onClick={() => onToggleClick(instance)}
                  >
                    {instance.powered ? "Desativar" : "Ativar"}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

interface State {
  instances: Array<AnalysisServicesInstanceDetailed> | null;
}

const initialState: State = {
  instances: null,
};

async function baseRefresh(
  context: {
    findAnalysisServicesDetailed: FindAnalysisServicesDetailed;
    setState: SetState<State>;
    openModal: OpenModal;
  },
  showErrorModal: boolean
) {
  const { findAnalysisServicesDetailed, setState, openModal } = context;

  try {
    const instances = await findAnalysisServicesDetailed();
    setState((prev) => ({ ...prev, instances }));
  } catch (e) {
    if (showErrorModal) {
      openModal(getErrorMessage(e));
    }
    console.log(e);
  }
}

function baseOnToggleClick(
  context: {
    openModal: OpenModal;
    refresh: (showErrorModal: boolean) => Promise<void>;
    closeModal: CloseModal;
  },
  instance: AnalysisServicesInstanceDetailed
) {
  const { openModal, closeModal, refresh } = context;

  const onConfirm = async () => {
    await refresh(true);
    closeModal();
  };

  if (instance.powered) {
    if (instance.powerOffAllowed) {
      openModal(
        <PowerOffInstanceModal instance={instance} onConfirm={onConfirm} />
      );
    } else {
      openModal(<CannotPowerOffInstanceModal instance={instance} />);
    }
  } else {
    openModal(
      <PowerOnInstanceModal instance={instance} onConfirm={onConfirm} />
    );
  }
}

function adjustAzureStatus(status?: string | null) {
  if (!status) {
    return "";
  }
  if (status === "Succeeded") {
    return "Ativo";
  }
  if (status === "Resuming") {
    return "Ativando";
  }
  if (status === "Pausing") {
    return "Desativando";
  }
  if (status === "Paused") {
    return "Inativo";
  }
  return status;
}

function getStatusIndicator(status: string) {
  if (status === "Ativo") {
    return (
      <div
        className={`${styles.statusIndicator} ${styles.statusIndicatorActive}`}
      />
    );
  }
  if (status === "Ativando" || status === "Pausing") {
    return (
      <div
        className={`${styles.statusIndicator} ${styles.statusIndicatorChanging}`}
      />
    );
  }
  if (status === "Inativo") {
    return (
      <div
        className={`${styles.statusIndicator} ${styles.statusIndicatorInactive}`}
      />
    );
  }
  return null;
}

function adjustDt(date?: string | null): string | null | undefined {
  return date
    ? date.replace(/^(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)/, "$3/$2/$1 $4:$5:$6")
    : date;
}

function CannotPowerOffInstanceModal(props: {
  instance: AnalysisServicesInstanceDetailed;
}) {
  const { closeModal } = useModal();
  const { instance } = props;
  return (
    <div className={styles.toggleModalWrapper}>
      <div className={styles.toggleModalTitle}>{instance.name}</div>
      <div className={styles.toggleModalSpacer1}></div>
      <div className={styles.toggleModalMessageTitle}>Importante</div>
      <div className={styles.toggleModalMessage}>
        Não é possível desativar a instância, existem clientes utilizando essa
        instância. Se não houver novos usos, ela será desativada automaticamente
        em {adjustDt(instance.expectedNextToggleAt)}.
      </div>
      <div className={styles.toggleModalSpacer2}></div>
      <div>
        <Button type="confirm" onClick={closeModal}>
          Ok
        </Button>
      </div>
    </div>
  );
}

function PowerOffInstanceModal(props: {
  instance: AnalysisServicesInstanceDetailed;
  onConfirm: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const { instance, onConfirm } = props;
  const { turnInstanceOff } = useAnalysisServicesRequests();

  const onPowerOffClick = async () => {
    setLoading(true);
    try {
      await turnInstanceOff(instance.name);
      onConfirm();
    } catch (e) {
      openModal(getErrorMessage(e));
      setLoading(false);
    }
  };

  return (
    <div className={styles.toggleModalWrapper}>
      <div className={styles.toggleModalTitle}>{instance.name}</div>
      <div className={styles.toggleModalSpacer1}></div>
      <div className={styles.toggleModalMessageTitle}>Importante</div>
      <div className={styles.toggleModalMessage}>
        A instância será desativada imediatamente e será ativada novamente caso
        um cliente acesse um dashboard que utilize essa instância
      </div>
      <div className={styles.toggleModalSpacer2}></div>
      <div>
        <Button type="cancel" disabled={loading} onClick={onPowerOffClick}>
          {!loading ? "Desativar" : "Carregando..."}
        </Button>
      </div>
    </div>
  );
}

function PowerOnInstanceModal(props: {
  instance: AnalysisServicesInstanceDetailed;
  onConfirm: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const { openModal } = useModal();
  const { instance, onConfirm } = props;
  const { turnInstanceOn } = useAnalysisServicesRequests();

  const onPowerOnClick = async () => {
    setLoading(true);
    try {
      await turnInstanceOn(instance.name);
      onConfirm();
    } catch (e) {
      openModal(getErrorMessage(e));
      setLoading(false);
    }
  };

  return (
    <div className={styles.toggleModalWrapper}>
      <div className={styles.toggleModalTitle}>{instance.name}</div>
      <div className={styles.toggleModalSpacer1}></div>
      <div className={styles.toggleModalMessageTitle}>Importante</div>
      <div className={styles.toggleModalMessage}>
        A instância será desativada automaticamente em uma hora se não houver
        uso de nenhum cliente.
      </div>
      <div className={styles.toggleModalSpacer2}></div>
      <div>
        <Button type="confirm" disabled={loading} onClick={onPowerOnClick}>
          {!loading ? "Ativar" : "Carregando..."}
        </Button>
      </div>
    </div>
  );
}
