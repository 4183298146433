import React, { useState } from 'react'
import styled from 'styled-components'
import { Logo, Template, Input, Button } from '../components/FrontPageForms'
import { useHistory } from 'react-router-dom'
import useUsers from '../hooks/useUsers'
import Modal from '../components/Modal'
import { getErrorMessage } from '../intranet/service/error/getErrorMessage'
import useModal from '../intranet/service/modal/useModal'

const Wrapper = styled.div`
  margin-top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 500px;
  text-align: center;
`

const Text = styled.div`
  padding: 5px;
  color: white;
  font-weight: bolder;
  margin-top: -12px;
  font-size: 14px;
`

const BackLink = styled.div`
  color: white;
  margin-top: 8px;
  user-select: none;
  cursor: pointer;
  font-size: 13px;

  :hover {
    text-decoration: underline;
  }
`

function ForgetPasswordPage() {
  const [ email, setEmail ] = useState("")
  const [ errMsg, setErrMsg ] = useState(null)
  const [ linkSent, setLinkSent ] = useState(false)
  const { sendForgetPasswordLink } = useUsers()
  const [ loading, setLoading ] = useState(false)
  const history = useHistory()
  const { openModal } = useModal();

  const handleOnSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      await sendForgetPasswordLink({ email })
      setLoading(false)
      setLinkSent(true)
    } catch (err) {
      setLoading(false)
      openModal(getErrorMessage(err))
    }
  }

  const handleOnModalClose = e => {
    setErrMsg(null)
  }

  if (linkSent) {
    return (
      <Template>
        <Wrapper>
          <Logo src="/static/logo-horus-w.png" />
          <Text>Um link para troca de senha foi enviado para o seu e-mail.</Text>
          <BackLink onClick={ () => history.push("/") }>
            Voltar
          </BackLink>
        </Wrapper>
      </Template>
    )  
  }

  return (
    <Template>
      {
        !errMsg ? null : (
          <Modal close={ handleOnModalClose }>
            { errMsg }
          </Modal>
        )
      }
      <Wrapper>
        <form onSubmit={ handleOnSubmit }>
          <Logo src="/static/logo-horus-w.png" />
          <Input placeholder="E-Mail" value={ email } setValue={ setEmail } />
          <Text>Insira seu E-mail para recuperar sua senha</Text>
          <Button>{ loading ? "Carregando..." : "Recuperar Senha" }</Button>
          <BackLink onClick={ () => history.push("/") }>
            Voltar
          </BackLink>
        </form>
      </Wrapper>
    </Template>
  )
}

export default ForgetPasswordPage