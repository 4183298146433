export function isCNPJ(cnpj: string) {
  let b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
  let c = cnpj.replace(/[^\d]/g, "");

  if (c.length !== 14) {
    return false;
  }

  if (/0{14}|1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14}/.test(c)) {
    return false;
  }

  for (var i: number = 0, n: number = 0; i < 12; n += parseInt(c[i]) * b[++i]);
  if (parseInt(c[12]) !== ((n %= 11) < 2 ? 0 : 11 - n)) {
    return false;
  }

  for (i = 0, n = 0; i <= 12; n += parseInt(c[i]) * b[i++]);
  if (parseInt(c[13]) !== ((n %= 11) < 2 ? 0 : 11 - n)) {
    return false;
  }

  return true;
}

export function isPositive(number: number) {
  return number && number > 0;
}

export function isNotEmpty(str: string) {
  return !(!str || str.trim() === "");
}

export function isEmpty(str: string | undefined | null) {
  return !str || str.trim() === "";
}

export function isNull(obj: any) {
  return obj === null;
}

export function isNotEmptyList(list: string[] | number[] | Object[]) {
  return !(!list || list.length === 0);
}

export function isEmptyList(list: string[] | number[] | Object[]) {
  return !list || list.length === 0;
}

export function hasLength(list: string[] | number[] | Object[], size: number) {
  return list && list.length === size;
}

export function maxLengthRespected(
  list: string[] | number[] | Object[],
  size: number
) {
  return list && list.length <= size;
}

export function isEmptyObj(obj: Object) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

export function isEmail(email: string) {
  return email.match(
    /^[a-zA-Z0-9-_+]+(\.[a-zA-Z0-9-_+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
  );
}

export function isEan(ean: string) {
  if (isEmpty(ean) || isNaN(Number(ean)) || ean.length < 8 || ean.length > 19) {
    return false;
  }
  let tot = 0;
  let i = 1;
  while (i < ean.length) {
    let num = parseInt(ean[i - 1]);

    if (i % 2 === ean.length % 2) {
      tot = tot + num;
    } else {
      tot = tot + num * 3;
    }
    i++;
  }

  return parseInt(ean[i - 1]) === (10 - (tot % 10)) % 10;
}
