import { SectionTitle } from "../../ui/SectionTitle";
import CsvTable from "../../ui/CsvTable";
import useUpdateProductPage from "../../../service/product/useUpdateProductPage";

export default function UpdateProductPage() {

const { handleSendFile } = useUpdateProductPage();
  return (
    <div>
      <SectionTitle title="Update de Produtos via CSV" description="Atualizar produtos na base de dados com os dados enviados via arquivo CSV. Há um limite de 500 itens por CSV" />
      <CsvTable handleSendFile={ handleSendFile }/>
    </div>
  )
}