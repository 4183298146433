import {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { http } from "../../../../api/http";
import { baseUrl, mediaPrefix } from "../../../../constants";
import { isPositive } from "../../../../utils/validation";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import useFetchProductOptions from "../../../service/product/useFetchProductOptions";
import { ProductBrand } from "../../../service/product/useProductBrandManagementPage";
import AsyncSelect, { Option } from "../../form/AsyncSelect";
import Button from "../../form/Button";
import Checkbox from "../../form/Checkbox";
import Input from "../../form/Input";
import ValidationMessages from "../../form/ValidationMessages";

export type Props = {
  selectedProductBrand: ProductBrand;
};

export default function ProductBrandEdit(props: Props) {
  const { selectedProductBrand } = props;

  const { fetchManufacturerOpts } = useFetchProductOptions();

  const fileInput = useRef<HTMLInputElement>();

  const { openModal } = useModal();

  const [state, setState] = useState<{
    loading: boolean;
    fileSelected: Blob | null;
    productBrand: ProductBrand;
    imageSrc: string | ArrayBuffer | null | undefined;
  }>({
    loading: false,
    productBrand: {
      id: selectedProductBrand.id,
      file: selectedProductBrand.file,
      name: selectedProductBrand.name,
      manufacturer: selectedProductBrand.manufacturer,
      economicGroup: selectedProductBrand.economicGroup,
      ownBrand: selectedProductBrand.ownBrand,
      productQtt: selectedProductBrand.productQtt,
    },
    fileSelected: null,
    imageSrc: selectedProductBrand.file
      ? mediaPrefix + selectedProductBrand.file
      : "/static/placeholder.png",
  });

  const validation = useMemo(
    () => validate(state.productBrand),
    [state.productBrand]
  );

  const handleOwnBrandOnCheck = () =>
    setState((prev) => ({
      ...prev,
      productBrand: {
        ...prev.productBrand,
        ownBrand: !prev.productBrand.ownBrand,
      },
    }));

  const handleManufacturerOnChange = (option: Option<null> | null) => {
    setState((prev) => ({
      ...prev,
      productBrand: { ...prev.productBrand, manufacturer: option },
    }));
  };

  const handleOnSave = useCallback(
    () => saveProductBrand(openModal, setState, state),
    [state]
  );

  const handleOnDeleteProductBrandImage = useCallback(
    () => deleteProductBrandImage(openModal, setState, state),
    [state]
  );

  const onUploadFileClick = () => {
    fileInput?.current?.click();
  };

  const setPreviewImage = (file: Blob) => {
    if (file?.size > 128000) {
      alert("Este arquivo excede o limite de 128KB!");
      return;
    }

    if (!file) {
      setState((prev) => ({
        ...prev,
        fileSelected: null,
        imageSrc: prev.productBrand
          ? mediaPrefix + prev.productBrand.file
          : "/static/placeholder.png",
      }));
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setState((prev) => ({
        ...prev,
        fileSelected: file,
        imageSrc: e?.target?.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  const onUploadFileSelected = () => {
    const file: Blob = fileInput!.current!.files![0];
    setPreviewImage(file);
  };

  const onDragOver = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  if (state.loading) {
    return <div>Carregando...</div>;
  }

  return (
    <>
      <Input
        label="Nome"
        name="name"
        placeholder="Nome"
        value={state.productBrand.name}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            productBrand: {
              ...prev.productBrand,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <ValidationMessages messages={validation.name} />

      <Input
        label="Grupo Econômico"
        name="economicGroup"
        placeholder="Grupo Econômico"
        value={state.productBrand.economicGroup}
        onChange={(e) =>
          setState((prev) => ({
            ...prev,
            productBrand: {
              ...prev.productBrand,
              [e.target.name]: e.target.value,
            },
          }))
        }
      />

      <Checkbox
        label="Marca Própria"
        checked={state.productBrand.ownBrand}
        onChange={handleOwnBrandOnCheck}
      />

      <AsyncSelect
        label="Fabricante"
        value={state.productBrand.manufacturer}
        fetchData={fetchManufacturerOpts}
        onChange={handleManufacturerOnChange}
      />

      <ValidationMessages messages={validation.manufacturerId} />

      <div className="h-separator" id="image-title">
        Imagem
      </div>
      <div className="h-ignore-input-spacing" />
      <p className="h-section-explanation">Defina a imagem da marca</p>
      <div className="h-row">
        <div className="h-col h-col-2">
          <input
            ref={fileInput as LegacyRef<HTMLInputElement>}
            type="file"
            style={{ display: "none" }}
            onChange={onUploadFileSelected}
          />
          <div className="h-dropzone" onDragOver={onDragOver}>
            <img
              className="h-brand-image"
              src={
                state.imageSrc
                  ? state.imageSrc.toString()
                  : mediaPrefix + "static/placeholder.png"
              }
              alt=""
            />
          </div>
        </div>
        <div className="h-col h-col-8">
          <Button type="info" onClick={onUploadFileClick}>
            Carregar Imagem
          </Button>
          <br />
          <br />
          <Button type="cancel" onClick={handleOnDeleteProductBrandImage}>
            Deletar Imagem
          </Button>{" "}
        </div>
      </div>

      <Button
        type="confirm"
        disabled={validation.errors > 0 || state.loading}
        onClick={() => handleOnSave()}
      >
        Salvar
      </Button>
    </>
  );
}

type SetState = Dispatch<
  SetStateAction<{
    loading: boolean;
    fileSelected: Blob | null;
    productBrand: ProductBrand;
    imageSrc: string | ArrayBuffer | null | undefined;
  }>
>;

interface Validation {
  name: string[];
  manufacturerId: string[];
  errors: number;
}

function validate(productBrand: ProductBrand) {
  const validation: Validation = {
    name: [],
    manufacturerId: [],
    errors: 0,
  };

  if (!productBrand?.name) {
    validation.name.push("O nome é obrigatório");
    validation.errors++;
  } else if (
    !productBrand?.name.match(
      /^[a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+( [a-zA-Zà-úÀ-Úä-üÄ-Ü0-9+.,!@#$%&*-_`¨]+)*$/
    )
  ) {
    validation.name.push(
      "O nome possui formatação inválida e/ou caracteres inválidos"
    );
    validation.errors++;
  }

  if (
    !productBrand.manufacturer ||
    !isPositive(parseInt(productBrand.manufacturer.id))
  ) {
    validation.manufacturerId.push("O fabricante é obrigatório");
    validation.errors++;
  }

  return validation;
}

async function deleteProductBrandImage(
  openModal: OpenModal,
  setState: SetState,
  state: {
    loading: boolean;
    fileSelected: Blob | null;
    productBrand: ProductBrand;
  }
) {
  try {
    await http.put(`${baseUrl}/api/v1/product/brand/` + state.productBrand.id);

    setState((prev) => ({
      ...prev,
      fileSelected: null,
      imageSrc: null,
      productBrand: { ...prev.productBrand, file: null },
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

async function saveProductBrand(
  openModal: OpenModal,
  setState: SetState,
  state: {
    loading: boolean;
    fileSelected: Blob | null;
    productBrand: ProductBrand;
  }
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    delete state.productBrand.productQtt;

    const formData = new FormData();
    if (state.fileSelected) formData.append("newImage", state.fileSelected);
    formData.append("formString", JSON.stringify(state.productBrand));

    const responseStr = state.productBrand.id
      ? "Marca alterada com sucesso."
      : "Marca cadastrada com sucesso.";

    const { data } = await http.post(
      `${baseUrl}/api/v1/product/brand`,
      formData,
      {}
    );

    setState((prev) => ({ ...prev, productBrand: data.result }));

    openModal(responseStr);
  } catch (e: any) {
    getErrorMessage(e);
  }
}
