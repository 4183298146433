import { useState, useEffect } from "react";
import Button from "../../Button";
import { Row } from "../../Grid";
import useProductCategory from "../../../hooks/useProductCategory";
import NielsenCategoryForm from "./nielsen-category";
import ProductCategoryForm from "./product-category";

export default function CategoryForm({ category, formType, moderator, fetchCategories }) {
  const [ { categoryToSave, savingCategory, newCategory, editNielsenCategory, createNielsenCategory, editTag, editMotherCategory, createMotherCategory }, setState ] = useState({
    categoryToSave: null,
    savingCategory: false,
    newCategory: category?.id ? false: true,
    editNielsenCategory: false,
    createNielsenCategory: false,
    editTag: false,
    editMotherCategory: false,
    createMotherCategory: false
  });

  const { fetchProductCategories, fetchNielsenCategories, fetchParentCategories, fetchLvl1ParentCategories, fetchParentNielsenCategories,
    saveCategory, saveNielsenCategory } = useProductCategory();

  // Helpers
  const updateCategory = patch => setState(prev => ({ ...prev, categoryToSave: { ...prev.categoryToSave, ...patch }}));
  const changeSavingState = () => setState(prev => ({ ...prev, savingCategory: !savingCategory}));
  const onChangeTag = () => {editTag ? setState( prev => ({ ...prev, editTag: false })) :  setState( prev => ({ ...prev, editTag: true })) }
  const onChangeEditNielsen = () => { editNielsenCategory ?
    setState( prev => ({ ...prev, editNielsenCategory: false, categoryToSave: { ...prev.categoryToSave, nielsen: category ? category.nielsen : null, nielsenId: category ? category.nielsenId : null} })) :
    setState( prev => ({ ...prev, editNielsenCategory: true }))
  } 
  const onChangeCreateNielsen = () => { createNielsenCategory ?
    setState( prev => ({ ...prev, createNielsenCategory: false, categoryToSave: { ...prev.categoryToSave, nielsen: category ? category.nielsen : null, nielsenId: category ? category.nielsenId : null } })) :
    setState( prev => ({ ...prev, createNielsenCategory: true, categoryToSave: { ...prev.categoryToSave, nielsenId: null } }))
  } 
  const onChangeEditMother = () => { editMotherCategory ?
    setState( prev => ({ ...prev, editMotherCategory: false, categoryToSave: { ...prev.categoryToSave, mother: category ? category.mother : null, motherId: category ? category.motherId : null } })) :
    setState( prev => ({ ...prev, editMotherCategory: true }))
  } 
  const onChangeCreateMother = () => { createMotherCategory ?
    setState( prev => ({ ...prev, createMotherCategory: false, categoryToSave: { ...prev.categoryToSave, mother: category ? category.mother : null, motherId: category ? category.motherId : null } })) :
    setState( prev => ({ ...prev, createMotherCategory: true, categoryToSave: { ...prev.categoryToSave, motherId: null } }))
  } 

  const setCategoryFormType = () => {
    if (newCategory) {
      updateCategory({ ...category, moderator });
    } else {
      setState(prev => ({
        ...prev, categoryToSave: { moderator }, editNielsenCategory: false, createNielsenCategory: false, editTag: false, editMotherCategory: false, createMotherCategory: false
      }));
    }
    setState(prev => ({ ...prev, newCategory: !newCategory }));
  }

  const submitCategory = async (categ) => {
    categ.type = categ?.parentType ? categ.parentType + 1 : 1
    changeSavingState();
    if (formType === "PRODUCTCATEGORY") {
      await saveCategory(categ, editNielsenCategory, editTag);
    } else {
      await saveNielsenCategory(categ, editNielsenCategory, createNielsenCategory, editMotherCategory, createMotherCategory);
    }
    changeSavingState();
  }

  const strategy = {
    PRODUCTCATEGORY: (
      <ProductCategoryForm
        categoryToSave={categoryToSave}
        editTag={editTag}
        onChangeTag={onChangeTag}
        fetchParentCategories={fetchCategories}
        submitCategory={submitCategory}
        updateCategory={updateCategory}
      />
    ),
    NIELSENCATEGORY: (
      <NielsenCategoryForm
        categoryToSave={categoryToSave}
        editNielsenCategory={editNielsenCategory}
        createNielsenCategory={createNielsenCategory}
        editMotherCategory={editMotherCategory}
        createMotherCategory={createMotherCategory}
        onChangeEditNielsen={onChangeEditNielsen}
        onChangeCreateNielsen={onChangeCreateNielsen}
        onChangeEditMother={onChangeEditMother}
        onChangeCreateMother={onChangeCreateMother}
        fetchProductCategories={fetchProductCategories}
        fetchLvl1ParentCategories={fetchLvl1ParentCategories}
        fetchNielsenCategories={fetchNielsenCategories}
        fetchParentNielsenCategories={fetchParentNielsenCategories}
        submitCategory={submitCategory}
        updateCategory={updateCategory}
      />
    ),
  };

  // Initializing form
  useEffect(() => {
      if (!category && !moderator) return;
      if (!category && moderator) return updateCategory({ ...category, moderator });
      updateCategory({ ...category, moderator });
  }, [ category, moderator ]);

  return (
    <div>
      {savingCategory ? <div className="overlay"></div> : null}
      <Row>
        {
          newCategory || !category ? (
            <>
              <div className="h-separator" style={{"height": "40px"}}>
                Cadastrar Nova Categoria
                <span style={{"float": "right"}}>
                  <Button disabled={ !category ? true: false } onClick={ setCategoryFormType }>
                    Editar Categoria
                  </Button>
                </span>
              </div>
            </>
          ): (
            <>
              <div className="h-separator" style={{"height": "40px"}}>
                Editar Categoria Selecionada
                <div style={{"float": "right"}}>
                  <Button onClick={ setCategoryFormType }>
                    Criar Nova Categoria
                  </Button>
                </div>
              </div>
            </>
          )
        }
      </Row>
      {strategy[formType]}
    </div>
  )
}