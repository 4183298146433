import Template from '../components/Template';
import { menus } from '../constants';
import { Title } from '../components/Titles';
import styled from 'styled-components';
import Input from '../components/Input';
import { useEffect, useState } from 'react';
import Button from '../components/Button';
import useCompany from '../hooks/useCompany';
import useModal from '../hooks/useModal';
import { useParams } from 'react-router-dom';
import Card from '../components/Card';

const Info = styled.div`
  font-size: 12px;
  color: gray;
  margin: -12px 0 15px 0;
`;

function BrandPage() {

  // # STATE
  const [ state, setState ] = useState({
    brand: null,
    brandForm: null,
    categories: null,
    companiesCount: 0,
    sendingBrand: false
  });
  const { brand, sendingBrand, categories, brandForm, companiesCount } = state;

  // # OTHER HOOKS
  const { id: brandId } = useParams();
  const { getBrand, getCategories, updateBrand } = useCompany();
  const { openModal } = useModal();

  // # EFFECTS
  useEffect(() => {
    getBrand(brandId, { countCompanies: true })
      .then(response => setState(old => ({ ...old, brand: response.data.brand, brandForm: response.data.brand, companiesCount: response.data.companiesCount })))
  }, [ brandId, getBrand ]);

  useEffect(() => {
    getCategories()
      .then(response => setState(old => ({ ...old, categories: response.data.categories })));
  }, [ getCategories ]);

  // # HELPERS
  const makeOnChange = field => e => setState(old => ({ ...old, brandForm: { ...old.brandForm, [field]: e.target.value } }));

  // # LISTENERS
  const handleOnSubmit = async e => {
    e.preventDefault();
    setState(prev => ({ ...prev, sendingBrand: true }));
    try {
      await updateBrand(brandForm);
      openModal("Marca atualizada com sucesso!");
    } catch (err) {
      openModal("Erro ao atualizar marca");
    }
    setState(prev => ({ ...prev, sendingBrand: false }));
  }

  // # VARIABLES
  const breadcrump = [ menus.home, menus.brands, {
    name: brand ? brand.description : "Carregando Marca...",
    path: "/brand/" + brandId
  } ];

  // # HTML
  return (
    <Template breadcrump={ breadcrump }>
      <Title>
        { brand ? brand.name : "Carregando Marca..." }
      </Title>
      { 
        !brand ? null : (
          <Card>
            <form onSubmit={ handleOnSubmit }>
              <Input
                label="Nome"
                value={ brandForm.description }
                onChange={ makeOnChange("description") }
              />
              <Input
                label="Categoria"
                value={ categories ? brandForm.categoryId : "Carregando categorias..." }
                onChange={ makeOnChange("categoryId") }
                readOnly={ !categories }
                options={ categories ? [ { id: null, name: "Sem Categoria" }, ...categories.map(i => ({ id: i.id, name: i.id + " - " + i.description })) ] : null }
              />
              <Info>Mudar esta categoria afetará { companiesCount } empresas</Info>
              <Button layout="confirm" disabled={ sendingBrand }>
                { sendingBrand ? "Carregando..." : "Atualizar" }
              </Button>
            </form>
          </Card>
        )
      }
    </Template>
  )
}

export default BrandPage;