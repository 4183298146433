
import { Route, Switch } from "react-router";
import { Title } from "../../../components/Titles";
import { useMenu } from "../../service/context/globalContext";
import Card from "../ui/Card";
import SubTitle from "../ui/SubTitle";
import Tabs from "../ui/Tabs";
import { MonthlyPartnerReport } from "./MonthlyPartnerReport";
import PartnerCouponExtractor from "./PartnerCouponExtractor";
import { PartnerCouponReports } from "./PartnerCouponReports";
import ServiceReportsPage from "./ServiceReportsPage";
import PartnerCouponSentReport from "./PartnerCouponSentReport";
import SeriesReport from "./SeriesReport";
import EventsPage from "../../../pages/EventsPage";
import Platform from "../ui/Platform";
import ReadCouponReport from "./ReadCouponReport";
import GroupedImportReport from "./GroupedImportReport";
import QueryApiPage from "./QueryApiPage";
import DebugReadCouponReport from "./DebugReadCouponReport";

export default function ReportsPage() {

  const menu = useMenu("/report");

  return (
    <Platform>
      <Title>{ menu?.name }</Title>
      <SubTitle>
        Relatórios de serviços e processos da Horus
      </SubTitle>
      <Tabs menu={ menu } />
      <Card>
        <Switch>
          <Route path="/report/services">
            <ServiceReportsPage />
          </Route>
          <Route path="/report/partner-coupon-extractor">
            <PartnerCouponExtractor />
          </Route>
          <Route path="/report/partner/performance">
            <PartnerCouponReports />
          </Route>
          <Route path="/report/partner/monthly" exact>
            <MonthlyPartnerReport />
          </Route>
          <Route path="/report/partner/coupons-sent">
            <PartnerCouponSentReport />
          </Route>
          <Route path="/report/series">
            <SeriesReport />
          </Route>
          <Route path="/report/events">
            <EventsPage />
          </Route>
          <Route path="/report/read-coupon">
            <ReadCouponReport />
          </Route>
          <Route path="/report/read-coupon-debug" exact>
            <DebugReadCouponReport />
          </Route>
          <Route path="/report/import">
            <GroupedImportReport />
          </Route>
          <Route path="/report/query">
            <QueryApiPage />
          </Route>
        </Switch>
      </Card>
    </Platform>
  )
}