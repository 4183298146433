import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";

export type Channel = {
  id: number;
  name: string;
};

export type ChannelSaveForm = {
  id: number | undefined;
  name: string;
};

export type ChannelSearchForm = {
  page?: number;
  pageSize?: number;
  filter?: string;
};

export type ChannelSearchView = {
  items: Channel[];
  total: number;
  page: number;
  totalItems: number;
};

export type useChannelRequests = typeof useChannelRequest;
export type SaveChannel = ReturnType<useChannelRequests>["saveChannel"];
export type SearchChannel = ReturnType<useChannelRequests>["searchChannel"];
export type DeleteChannel = ReturnType<useChannelRequests>["deleteChannel"];

export default function useChannelRequest() {
  const saveChannel = (channel: ChannelSaveForm) => baseSaveChannel(channel);

  const searchChannel = (params: ChannelSearchForm) =>
    baseSearchChannel(params);

  const deleteChannel = (id: number) => baseDeleteChannel(id);
  return {
    saveChannel,
    searchChannel,
    deleteChannel,
  };
}

async function baseSaveChannel(channel: ChannelSaveForm): Promise<number> {
  const { data } = await http.post(
    `${baseUrl}/api/v1/company/channel/`,
    channel
  );
  return data;
}

async function baseSearchChannel(
  params?: ChannelSearchForm
): Promise<ChannelSearchView> {
  const response = await http.get(`${baseUrl}/api/v1/company/channel/list`, {
    params,
  });
  return response.data;
}

async function baseDeleteChannel(channelId: number): Promise<void> {
  await http.delete(`${baseUrl}/api/v1/company/channel/${channelId}`);
}
