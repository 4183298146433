import styled, { css } from 'styled-components'
import GroupedImportReportFilters from './GroupedImportReportFilters'
import Input from '../form/Input'
import useGroupedImportReport from './useGroupedImportReport'
import Modal from '../../../components/Modal'
import { SectionTitle } from '../ui/SectionTitle'

const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: bolder;
  padding: 0px 9px;
  margin: 10px 0 5px 0;
`

const Grid = styled.div`
  padding: 0 5px;
  :after {
    display: block;
    clear: both;
    content: ' ';
  }
`

const Column = styled.div`
  width: 25%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const SmallColumn = styled.div`
  width: 10%;
  float: left;
  padding: 0 5px;
  box-sizing: border-box;
`

const FilterButton = styled.div`
  width: 100%;
  height: 33px;
  border-radius: 5px;
  font-size: 14px;
  background-color: #95a5a6;
  padding: 8px 5px;
  margin-top: 10%;
  color: white;
  font-weight: bolder;
  text-align: center;
  box-sizing: border-box;
  :hover {
    background-color: gray;
    cursor: pointer;
  }
`

const SearchButton = styled.div`
  border-radius: 5px;
  height: 30px;
  display: inline-block;
  background-color: #95a5a6;
  padding: 6px 30px;
  margin-top: 11%;
  color: white;
  font-weight: bolder;
  text-align: center;
  box-sizing: border-box;
  background-color: rgb(153, 21, 43);
  :hover {
    background-color: rgb(210, 44, 72);
    cursor: pointer;
  }
  ${ props => !props.disabled && css`
    cursor: pointer;
  ` }
  ${ props => props.disabled && css`
    background-color: gray !important;
    opacity: 0.5;
  ` }
`

const FieldList = styled.ul`
  margin: 0;
  padding: 0;
`

const FieldListItem = styled.li`
  list-style: none;
  padding: 10px 5px;
  border-bottom: solid 1px lightgray;
  :last-child {
    border: 0;
  }
  :hover {
    background-color: lightgray;
    cursor: pointer;
  }
  ${
    props => props.selected && css`
      background-color: gray !important;
      color: white;
    `
  }
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    background-color: #95a5a6;
    color: white;
  }

  th, td {
    padding: 5px 10px;
  }

  tr:nth-child(2n + 1) {
    background-color: lightgray;
  }

`

// const GraphicWrapper = styled.div`
//   border: solid 1px gray;
//   padding: 10px;
//   height: 250px;
//   width: 65%;
//   box-sizing: border-box;
//   float: left;
// `

// const GraphicOptions = styled.div`
//   padding: 10px;
//   width: 35%;
//   box-sizing: border-box;
//   float: left;
// `

// const GraphicLine = styled.div`
//   padding: 10px;
//   :after {
//     clear: both;
//     display: block;
//     content: ' '
//   }
// `

const ExcelButton = styled.div`
  display: inline-block;
  float: right;
  margin-top: 5px;
  font-size: 14px;
  background-color: green;
  padding: 8px 5px;
  color: white;
  font-weight: bolder;
  text-align: center;
  :hover {
    background-color: gray;
    cursor: pointer;
  }
`

export default function GroupedImportReport() {

  const {
    err, setErr, filtersOpen, setFiltersOpen, groupsOpen, setGroupsOpen, filters, setFilters, selectedGroups, selectGroup, fields, fieldNames, validateDate,
    fromDate, setFromDate, toDate, setToDate, data, lastSelectedGroups, showToImport, loading, doSearch, downloadTableAsCsv
  } = useGroupedImportReport();

  return (
    <>
      { 
        err ? (
          <Modal close={ () => setErr(null) }>
            { err.message }
          </Modal>
        ) : null
      }
      {
        filtersOpen ? (
          <Modal close={ () => setFiltersOpen(false) }>
            <GroupedImportReportFilters
              fieldNames={ fieldNames }
              value={ filters }
              onChange={ setFilters } />
          </Modal>
        ) : null
      }
      {
        groupsOpen ? (
          <Modal close={ () => setGroupsOpen(false) }>
            <FieldList>
              {
                fields.map(item => {
                  return (
                    <FieldListItem onClick={ () => selectGroup(item) } selected={ selectedGroups.includes(item) }>
                      { fieldNames[item] }
                    </FieldListItem>
                  )
                })
              }
            </FieldList>
          </Modal>
        ) : null
      }
      <div>
        <SectionTitle title="Relatório Importação" description="Acumulado de notas de um período selecionado." />
        <Grid>
          <SmallColumn>
            <FilterButton onClick={ () => setFiltersOpen(true) }>
              Filtros ({ filters.length })
            </FilterButton>
          </SmallColumn>
          <SmallColumn>
            <FilterButton onClick={ () => setGroupsOpen(true) }>
              Agrupamentos ({ selectedGroups.length })
            </FilterButton>
          </SmallColumn>
          <Column>
            <Input 
              label="Data Início"
              placeholder='dd/mm/yyyy'
              validate={ validateDate }
              value={ fromDate }
              onChange={ e => setFromDate(e.target.value) }
            />
          </Column>
          <Column>
            <Input
              label="Data Fim"
              placeholder='dd/mm/yyyy'
              validate={ validateDate }
              value={ toDate }
              onChange={ e => setToDate(e.target.value) }
            />
          </Column>
          <SmallColumn>
            <SearchButton onClick={ doSearch } disabled={ validateDate(fromDate) || validateDate(toDate) || loading }>
              <i className="fa fa-search" />
            </SearchButton>
          </SmallColumn>
        </Grid>
        <SubTitle>
          {
            loading ? "Carregando..." : (
              data ? "Resultados:" : "Você ainda não fez nenhuma pesquisa" 
            )
          }
        </SubTitle>
        {
          data ? (
            <>
              {/* <GraphicLine>
                <GraphicWrapper>
                  <div id="chart-container" style={{ height: "100%", width: "100%" }} />
                </GraphicWrapper>
                <GraphicOptions>
                  <Input
                    label="Eixo X"
                    value={ axis }
                    onChange={ e => setAxis(e.target.value) }
                    options={
                      lastSelectedGroups.map(group => {
                        return {
                          id: group,
                          name: fieldNames[group]
                        }
                      })
                    }
                  />
                </GraphicOptions>
              </GraphicLine> */}
              <ExcelButton onClick={ () => downloadTableAsCsv("data-table") }>
                <i className="fa fa-excel" /> Baixar como CSV
              </ExcelButton>
              <Table id="data-table">
                <thead>
                  <tr>
                    {
                      lastSelectedGroups.map(group => {
                        return (
                          <th key={ group }>
                            { fieldNames[group] }
                          </th>
                        )
                      })
                    }
                    {
                      showToImport ? (
                        <th>Enviado</th>
                      ) : null
                    }
                    <th>Importado</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.map((item, index) => {
                      return (
                        <tr key={ index }>
                          {
                            lastSelectedGroups.map(group => {
                              return (
                                <td key={ group }>
                                  { item[group] }
                                </td>
                              )
                            })
                          }
                          {
                            showToImport ? (
                              <td>{ item.toImport }</td>
                            ) : null
                          }
                          <td>{ item.imported }</td>
                        </tr>
                      )
                    })
                  }
                  <tr>
                    {
                      lastSelectedGroups.map(group => {
                        return (
                          <td key={ group } />
                        )
                      })
                    }
                    {
                      showToImport ? (
                        <td style={{ fontWeight: "bolder" }}>{ data.map(i => i.toImport).reduce((a, b) => a + b, 0) }</td>
                      ) : null
                    }
                    <td style={{ fontWeight: "bolder" }}>{ data.map(i => i.imported).reduce((a, b) => a + b, 0) }</td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : null
        }
      </div>
    </>
  )
}