import { Route, Switch } from "react-router-dom";

import { Title } from "../../../components/Titles";
import { useMenu } from "../../service/context/globalContext";
import LoadNextItem from "../queue/LoadNextItem";
import Card from "../ui/Card";
import Platform from "../ui/Platform";
import SubTitle from "../ui/SubTitle";
import Tabs from "../ui/Tabs";
import CompanyCategoryPage from "./CompanyCategoryPage";
import CompanyChannelPage from "./CompanyChannelPage";
import CompanyListPage from "./CompanyListPage";
import CompanyModeration from "./CompanyModeration";
import CompanyModerationSearchPage from "./CompanyModerationSearchPage";
import CompanyBrandPage from "./brand/CompanyBrandPage";

export default function CompanyPage() {
  const menu = useMenu("/company");

  return (
    <Platform>
      <Title>{menu.name}</Title>
      <SubTitle>Criação, listagem e alteração de empresas</SubTitle>
      <Tabs menu={menu} />
      <Card>
        <Switch>
          <Route exact path="/company/list">
            <CompanyListPage />
          </Route>
          <Route exact path="/company/moderation">
            <CompanyModerationSearchPage />
          </Route>
          <Route exact path="/company/:cnpj">
            <CompanyModeration />
          </Route>
          <Route exact path="/company/brand/:page">
            <CompanyBrandPage />
          </Route>
          <Route exact path="/company/load-next/:type">
            <LoadNextItem />
          </Route>
          <Route exact path="/company/category/:page">
            <CompanyCategoryPage />
          </Route>
          <Route exact path="/company/channel/:page">
            <CompanyChannelPage />
          </Route>
        </Switch>
      </Card>
    </Platform>
  );
}
