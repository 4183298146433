import { mediaPrefix } from "../../../constants";
import useCompanyModeration from "../../service/company/useCompanyModeration";
import AsyncSelectModal from "../form/AsyncSelectModal";
import Button from "../form/Button";
import Input from "../form/Input";
import Col from "../ui/Col";
import Row from "../ui/Row";
import { SectionTitle } from "../ui/SectionTitle";
import styles from "../ui/ui.module.css";
import CompanyTopSalesModal from "./CompanyTopSalesModal";
import companyStyles from "./companyModeration.module.css";

export default function CompanyModeration() {
  const {
    loadingSales,
    searchCnpj,
    searchAddress,
    company,
    mapContainerRef,
    salesWithPercent,
    moderator,
    handleSearchFormOnChange,
    handleSearchCompanyOnClick,
    handleCompanyOnChange,
    handleSaveCompanyOnClick,
    findCompanyBrandModalOpts,
    handleBrandOnChange,
    handleAddressSearchOnClick,
  } = useCompanyModeration();

  const brand = company?.brand;
  const category = company?.category;

  return (
    <div>
      <SectionTitle
        title="Moderação de Empresas"
        description="Moderação de empresas visando sua liberação, pausa ou inativação."
      />
      <div className={companyStyles.cnpjBoxWrapper}>
        <Input
          onChange={handleSearchFormOnChange}
          value={searchCnpj}
          name="searchCnpj"
          placeholder="Digite o CNPJ"
          label="Pesquisar Nova Empresa"
          addons={[
            <Button key="1" type="info" onClick={handleSearchCompanyOnClick}>
              Pesquisar
            </Button>,
          ]}
        />
      </div>
      {!company ? null : (
        <>
          <h3>{company.companyName}</h3>
          <div>
            <div className={companyStyles.separator}>Classificação</div>
            <div className={companyStyles.topInfo}>
              <div>
                <div className={companyStyles.topic}>CNPJ</div>
                <div className={companyStyles.info}>{company.cnpj}</div>
              </div>
              <div>
                <div className={companyStyles.topic}>Grupo Econômico</div>
                <div className={companyStyles.info}>
                  {brand?.economicGroup || "--"}
                </div>
              </div>
              <div>
                <div className={companyStyles.topic}>Razão Social</div>
                <div className={companyStyles.info}>{company.companyName}</div>
              </div>
              <div className={styles.widthFiller}>
                <div className={companyStyles.topic}>CNAE</div>
                <div className={companyStyles.info}>{company.cnae}</div>
              </div>
            </div>
            <Row>
              <Col width={1 / 6}>
                {company.brand.file ? (
                  <img
                    className={companyStyles.brandImage}
                    src={mediaPrefix + company.brand.file}
                    alt=""
                  />
                ) : (
                  <img
                    className={companyStyles.brandImage}
                    src="/static/placeholder.png"
                    alt=""
                  />
                )}
              </Col>
              <Col width={5 / 6}>
                <Col width={2 / 3}>
                  <Row>
                    <Col width={1}>
                      <Input
                        label="Nome Fantasia"
                        placeholder="Nome Fantasia"
                        value={company.tradingName}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col width={1 / 2}>
                      <AsyncSelectModal
                        type="companyBrand"
                        value={company.brand.name}
                        imageSrcColumn="file"
                        fetchData={findCompanyBrandModalOpts}
                        onSelectItem={handleBrandOnChange}
                      />
                    </Col>
                    <Col width={1 / 2}>
                      <Input
                        label="Categoria"
                        value={`Categoria: ${category.id} - ${category.name} - Canal: ${category.channel}`}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col width={1 / 5}>
                      <Input
                        label="Checkouts"
                        placeholder="Número de PDVs"
                        name="checkouts"
                        value={company.checkouts}
                        onChange={handleCompanyOnChange}
                        type="number"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col width={1 / 3}>
                  <div className={styles.textCenter}>
                    <h3>Vendas</h3>
                    {loadingSales ? (
                      <div>Carregando dados de vendas da empresa...</div>
                    ) : (
                      <CompanyTopSalesModal
                        salesWithPercent={salesWithPercent}
                      />
                    )}
                  </div>
                </Col>
              </Col>
            </Row>
            <div className={companyStyles.separator}>Localização</div>
            <Row>
              <Col width={1 / 5}>
                <Input
                  label="CEP"
                  placeholder="Cep"
                  name="cep"
                  value={company.cep}
                  onChange={handleCompanyOnChange}
                />
              </Col>
              <Col width={4 / 5}>
                <Input
                  label="Endereço"
                  placeholder="Endereço"
                  name="address"
                  value={company.address}
                  onChange={handleCompanyOnChange}
                />
              </Col>
              <Col width={1}>
                <div className={companyStyles.searchInputWrapper}>
                  <Input
                    onChange={handleSearchFormOnChange}
                    value={searchAddress}
                    name="searchAddress"
                    placeholder="Pesquisar Endereço"
                    label="Pesquisar Endereço"
                    addons={[
                      <Button
                        key="2"
                        type="info"
                        onClick={handleAddressSearchOnClick}
                      >
                        Pesquisar
                      </Button>,
                    ]}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col width={4 / 5}>
                <div
                  style={{
                    height: "460px",
                    marginBottom: "10px",
                    backgroundColor: "lightgray",
                  }}
                  ref={mapContainerRef}
                ></div>
              </Col>
              <Col width={1 / 5}>
                <div>
                  <div className={companyStyles.topic}>UF</div>
                  <div className={companyStyles.info}>{company.uf || "--"}</div>
                </div>
                <div>
                  <div className={companyStyles.topic}>Cidade</div>
                  <div className={companyStyles.info}>
                    {company.city || "--"}
                  </div>
                </div>
                <div>
                  <div className={companyStyles.topic}>Bairro</div>
                  <div className={companyStyles.info}>
                    {company.neighborhood || "--"}
                  </div>
                </div>
                <div className={styles.widthFiller}>
                  <div className={companyStyles.topic}>latidude</div>
                  <div className={companyStyles.info}>
                    {company.pointCoordinates.lat || "--"}
                  </div>
                </div>
                <div className={styles.widthFiller}>
                  <div className={companyStyles.topic}>longitude</div>
                  <div className={companyStyles.info}>
                    {company.pointCoordinates.lng || "--"}
                  </div>
                </div>
                <div className={styles.widthFiller}>
                  <div className={companyStyles.topic}>
                    ID do Setor Censitário
                  </div>
                  <div className={companyStyles.info}>
                    {company.setorCensitarioId || "--"}
                  </div>
                </div>
                <div className={styles.widthFiller}>
                  <div className={companyStyles.topic}>CMB</div>
                  <div className={companyStyles.info}>
                    {company.cmb || "--"}
                  </div>
                </div>
              </Col>
            </Row>
            <div className={companyStyles.separator}>Ações</div>
            <div className={companyStyles.legend}>
              Inativar - Marca como inativa uma empresa que não está mais em
              funcionamento.
              <br />
              Pausar - Pausar empresa que não apresente dados suficientes para
              possibilitar o cadastro completo.
              <br />
              Salvar - Salvar empresa com todos os dados preenchidos
              corretamente.
            </div>
            {"HORUS" === moderator?.company ? (
              <Button
                type="cancel"
                onClick={() => handleSaveCompanyOnClick("INATIVO")}
              >
                Inativar
              </Button>
            ) : (
              <Button
                type="cancel"
                onClick={() => handleSaveCompanyOnClick("PULADO")}
              >
                Pular
              </Button>
            )}
            &nbsp;
            <Button
              type="info"
              onClick={() => handleSaveCompanyOnClick("PAUSADO")}
            >
              Pausar
            </Button>
            &nbsp;
            <Button
              type="confirm"
              onClick={() => handleSaveCompanyOnClick("LIBERADO")}
            >
              Salvar
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
