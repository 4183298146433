import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
export interface CompanyItemView {
  channel: string;
  id: number;
  cnpj: string;
  brand: string;
  file: string;
  category: string;
  address: string;
  economicGroup: string;
  tradingName: string;
  status: string;
}

export type CompanySearchView = {
  items: Array<CompanyItemView>;
  total: number;
  page: number;
};

export interface CompanySearchForm extends CompanySearchCsvDownloadForm {
  page: number;
  pageSize: number;
}

export interface CompanySearchCsvDownloadForm {
  cnpjList: Array<string> | undefined;
  brands: Array<number> | undefined;
  categories: Array<number> | undefined;
  status: Array<string> | undefined;
}

interface Brand {
  id: string;
  name: string;
  file: string;
  economicGroup: string;
}

interface Category {
  id: string;
  name: string;
  channel: string;
}

export interface BrandWithCategoryAddOn {
  file: string;
  economicGroup: string;
  bigFranchise: boolean;
  categoryId: string;
  categoryName: string;
  channel: string;
}

export interface CompanyToModerate {
  id: string;
  status: string;
  cnpj: string;
  companyName: string;
  tradingName: string;
  cnae: string;
  address: string;
  neighborhood: string;
  cep: string;
  city: string;
  uf: string;
  cmb: string;
  setorCensitarioId: number;
  pointCoordinates: { lat: number; lng: number };
  checkouts: string;
  relevance: number;
  isBigFranchise: boolean;
  brand: Brand;
  category: Category;
}

export interface CompanyUpdateForm {
  id: string;
  status: string;
  checkouts: string;
  address: string;
  neighborhood: string;
  cep: string;
  city: string;
  uf: string;
  categoryId: string;
  brandId: string;
  companyName: string;
  tradingName: string;
  cmb: string;
  setorCensitarioId: number;
  pointCoordinates: {
    lat: number;
    lng: number;
  };
  isBigFranchise: boolean;
}

export type UseCompanyRequests = typeof useCompanyRequests;
export type CompanySearch = ReturnType<UseCompanyRequests>["companySearch"];
export type GetCompanyByCnpj =
  ReturnType<UseCompanyRequests>["getCompanyByCnpj"];
export type UpdateCompany = ReturnType<UseCompanyRequests>["updateCompany"];
export type DownloadCsv = ReturnType<UseCompanyRequests>["downloadCsv"];
export type FetchCompanyQueue =
  ReturnType<UseCompanyRequests>["fetchCompanyQueue"];
export type GetCompanyTopSales =
  ReturnType<UseCompanyRequests>["getCompanyTopSales"];
export type VerifyRegisteredCnpjs =
  ReturnType<UseCompanyRequests>["verifyRegisteredCnpjs"];

export default function useCompanyRequests() {
  const companySearch = (params?: CompanySearchForm) =>
    baseCompanySearch(params);

  const getCompanyByCnpj = (cnpj: string) => baseGetCompanyByCnpj(cnpj);

  const updateCompany = (
    company: CompanyUpdateForm,
    isJumpedCompany: boolean,
    reason: string
  ) => baseUpdateCompany(company, isJumpedCompany, reason);

  const downloadCsv = (params?: CompanySearchCsvDownloadForm) =>
    baseDownloadCsv(params);

  const fetchCompanyQueue = () => baseFetchQueue();

  const getSetorCencitarioByLatLng = (latLng: { lat: Number; lng: number }) =>
    baseGetSetorCencitarioByLatLng(latLng);

  const getCompanyTopSales = (id: string) => baseGetCompanyBrandTopSales(id);

  const verifyRegisteredCnpjs = (cnpjs: Array<string>) =>
    baseVerifyRegisteredCnpjs(cnpjs);

  const refreshReservation = (cnpj: string) => baseRefreshReservation(cnpj);

  return {
    companySearch,
    getCompanyByCnpj,
    updateCompany,
    downloadCsv,
    fetchCompanyQueue,
    getSetorCencitarioByLatLng,
    getCompanyTopSales,
    verifyRegisteredCnpjs,
    refreshReservation,
  };
}

async function baseCompanySearch(
  params?: CompanySearchForm
): Promise<CompanySearchView> {
  const response = await http.post(`${baseUrl}/api/v1/company/search`, params);
  return response.data;
}

async function baseGetCompanyByCnpj(cnpj: string): Promise<CompanyToModerate> {
  const { data } = await http.get(baseUrl + "/api/v1/company/" + cnpj);
  return data.company;
}

async function baseUpdateCompany(
  company: CompanyUpdateForm,
  isJumpedCompany: boolean,
  reason: string
) {
  await http.put(
    `${baseUrl}/api/v1/company?isJumpedCompany=${isJumpedCompany ? "true" : "false"
    }&reason=${reason}`,
    company
  );
}

async function baseDownloadCsv(
  params?: CompanySearchCsvDownloadForm
): Promise<string> {
  const response = await http.post(
    `${baseUrl}/api/v1/company/download/company.csv`,
    params
  );
  return response.data;
}

async function baseFetchQueue(): Promise<string> {
  const { data: nextCompany } = await http.get(
    baseUrl + `/api/v1/company/queue`
  );
  return nextCompany?.cnpj;
}

async function baseGetSetorCencitarioByLatLng(latLng: {
  lat: Number;
  lng: number;
}) {
  const { data } = await http.get(
    baseUrl + "/api/v1/company/setor-censitario",
    { params: latLng }
  );
  return data;
}

async function baseGetCompanyBrandTopSales(id: string) {
  const { data } = await http.get(baseUrl + "/api/v1/company/sales/" + id);
  return data;
}

async function baseVerifyRegisteredCnpjs(
  cnpjs: Array<string>
): Promise<Array<string>> {
  const { data: cnpjList } = await http.post(
    baseUrl + `/internal/api/v1/company/queue`,
    cnpjs
  );
  return cnpjList;
}

async function baseRefreshReservation(pageCnpj: string): Promise<void> {
  await http.get(
    baseUrl + "/api/v1/company/queue/refresh-reservation/" + pageCnpj
  );
}
