import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useParams } from "react-router";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useQuery from "../../service/url/useQuery";
import { buildSearchDashboardAssociationsPath } from "./paths";

function useDashboardListPage() {
  const [state, setState] = useState(initialState);

  const history = useHistory();

  const { page: pageStr } = useParams<{ page: string }>();

  const page = parseInt(pageStr);

  const { filter: formFilter } = state;

  const query = useQuery();

  const filter = query.get("filter") || "";

  const handleOnChange = useHandleOnChange(setState);

  const { openModal } = useModal();

  const findDashboards = useCallback(
    () => baseFindDashboards(page, openModal, setState, filter),
    [page, openModal, setState, filter]
  );

  const handleFindOnSubmit = useCallback(
    (e: FormEvent) => baseHandleFindOnSubmit(history, formFilter, e),
    [history, formFilter]
  );

  const handlePageOnChange = useCallback(
    (page: number) => baseHandlePageOnChange(history, formFilter, page),
    [history, formFilter]
  );

  const handleEditOnClick = useCallback(
    (id: number) => baseHandleOnEditClick(history, id),
    [history]
  );

  const handleOnDetailsClick = useCallback(
    (id: number) => baseHandleOnDetailsClick(history, id),
    [history]
  );

  useEffect(() => {
    findDashboards();
  }, [findDashboards]);

  return {
    ...state,
    page,
    findDashboards,
    handleFindOnSubmit,
    handlePageOnChange,
    handleOnChange,
    handleEditOnClick,
    handleOnDetailsClick,
  };
}

interface State {
  filter: string;
  totalPages: number | null;
  items: Array<DashboardResumedInfo> | null;
  loading: boolean;
}

interface DashboardResumedInfo {
  id: number;
  reportIdBi: string;
  groupIdBi: string;
  datasetIdBi: string;
  description: string;
  link: string;
  clientAmount: number;
  groupAmount: number;
  userAmount: number;
}

type History = ReturnType<typeof useHistory>;

type SetState = Dispatch<SetStateAction<State>>;

const initialState: State = {
  filter: "",
  totalPages: null,
  loading: false,
  items: null,
};

async function baseFindDashboards(
  page: number,
  openModal: OpenModal,
  setState: SetState,
  filter: string | null
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    const response = await http.get(`${baseUrl}/api/v1/dashboard/info`, {
      params: { page, filter, pageSize: 40 },
    });

    setState((prev) => ({
      ...prev,
      totalPages: response.data.total,
      items: response.data.items,
    }));
  } catch (e) {
    openModal(getErrorMessage(e));
  } finally {
    setState((prev) => ({ ...prev, loading: false }));
  }
}

function baseHandleFindOnSubmit(
  history: History,
  filter: string | null,
  e: FormEvent
) {
  e.preventDefault();
  if (filter) {
    history.push("?filter=" + filter);
  } else {
    history.push("?filter=");
  }
}

function baseHandlePageOnChange(
  history: History,
  filter: string,
  page: number
) {
  history.push("/dashboards/list/" + page + "?filter=" + filter);
}

function baseHandleOnEditClick(history: History, id: number) {
  history.push("/dashboards/edit/" + id);
}

function baseHandleOnDetailsClick(history: History, dashboardId: number) {
  history.push(
    buildSearchDashboardAssociationsPath({ dashboardId: String(dashboardId) })
  );
}

export default useDashboardListPage;
