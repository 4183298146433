import axios from "axios";

export function doNothing() {}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function makeValidator(validators) {
  const obj = {
    getAllErrors: (value) => {
      const allResult = [];
      Object.entries(validators).forEach((entry) => {
        const result = entry[1](value[entry[0]]);
        if (result) {
          allResult.push(...result);
        }
      });
      return allResult.length > 0 ? allResult : null;
    },
  };
  Object.entries(validators).forEach((entry) => {
    obj[`get${capitalizeFirstLetter(entry[0])}Errors`] = entry[1];
  });
  return obj;
}

export function isNotEmpty(key) {
  return (value) => {
    if (!value || value === "") {
      return [`${key} não pode ser vazio`];
    }
    return null;
  };
}

export function isNotEmptyList(key) {
  return (value) => {
    if (!value || value.length === 0) {
      return [`${key} não pode ser vazio`];
    }
    return null;
  };
}

export function hasLength(key, size) {
  return (value) => {
    if (value && value.length < size) {
      return [`${key} deve ter no mínimo ${size} dígitos`];
    }
    return null;
  };
}

export function maxLength(key, size) {
  return (value) => {
    if (value && value.length > size) {
      return [`${key} deve ter no máximo ${size} dígitos`];
    }
    return null;
  };
}

export function isEmptyObj(obj) {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }

  return true;
}

export function isEmpty(text) {
  if (!text || text === "") {
    return true;
  }
  return false;
}

export function isNullText(text) {
  if (!text || text === "" || text === "null") {
    return true;
  }
  return false;
}

export function isEmptyList(list) {
  if (!list || list.length === 0) {
    return true;
  }
  return false;
}

export function isFunction(x) {
  return Object.prototype.toString.call(x) === "[object Function]";
}

export function isEmail(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (
      !value.match(
        /^[a-zA-Z0-9-_+]+(\.[a-zA-Z0-9-_+]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/
      )
    ) {
      return [`${key} não tem formato de e-mail`];
    }
    return null;
  };
}

export function comaSplited(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (!value.match(/\d+(,\d+)*/)) {
      return [`${key} Somente números e vírgulas, sem espaços`];
    }
  };
}

export function isValidUserName(key) {
  return (value) => {
    if (!value) {
      return null;
    }
    if (
      value.match(/(?=.*[!@#$%¨&*()*-+_{}[];<>?\|'"\s])([^a-zA-Z0-9^-_+])/gm)
    ) {
      return [`${key} não é válido como nome de usuario`];
    }
    return null;
  };
}

export function isCnpj(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (value.length !== 14) {
      return [`CNPJ deve ter 14 dígitos.`];
    }
    return null;
  };
}

export function isValidCnpj(cnpj) {
  if (!cnpj || cnpj === "" || cnpj.length !== 14) {
    return false;
  }
  return true;
}

export function isEan(ean) {
  if (isEmpty(ean) || isNaN(ean) || ean.length < 8 || ean.length > 19) {
    return false;
  }
  let tot = 0;
  let i = 1;
  while (i < ean.length) {
    let num = parseInt(ean[i - 1]);

    if (i % 2 === ean.length % 2) {
      tot = tot + num;
    } else {
      tot = tot + num * 3;
    }
    i++;
  }

  return parseInt(ean[i - 1]) === (10 - (tot % 10)) % 10;
}

export function isCpf(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (value.length !== 14) {
      return [`CPF deve ter 11 dígitos.`];
    }
    return null;
  };
}

export function isCpfOrCnpj(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (value.length < 11 && value.length !== 11) {
      return [`CPF deve ter 11 digitos.`];
    }
    if (value.length > 11 && value.length !== 14) {
      return [`CNPJ deve ter 14 dígitos`];
    }
    return null;
  };
}

export function isDateValid(key) {
  return (value) => {
    if (!value || value === "") {
      return null;
    }
    if (!value.match(/\d{4}-\d{2}-\d{2}/gm)) {
      return [`${key} a data deverá ser composta por: yyyy-mm-dd`];
    }
    return null;
  };
}

export function allRules(...rules) {
  return (value) => {
    const errors = [];
    rules.forEach((rule) => {
      const result = rule(value);
      if (result) {
        errors.push(...result);
      }
    });
    return errors.length > 0 ? errors : null;
  };
}

export function makeid(length) {
  let result = "";
  let characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function removeItemArray(array, value) {
  return array.filter(function (item) {
    return item !== value;
  });
}

export function locateCep(cep) {
  return axios.get("http://viacep.com.br/ws/" + cep + "/json/");
}

export async function dePromisefy(promise) {
  try {
    return [null, await promise];
  } catch (err) {
    return [err, null];
  }
}

export function optional(value) {
  return {
    map: (fn) => {
      let newValue = value;
      if (value) {
        newValue = fn(newValue);
      }
      return optional(newValue);
    },
    get: () => value,
    getOrDefault: (defaultValue) => value || defaultValue,
  };
}

export function logAndReturn(obj) {
  console.log(obj);
  return obj;
}

export function convertInvalidValuesToNull(value) {
  if (value === "undefined") return null;
  if (value === "null") return null;
  if (value === undefined) return null;
  return value;
}

const horusId = { text: "1", number: 1 };

export function createUserPermission(userAuth, newUser) {
  return !(userAuth.role !== "ADMIN" && newUser.companyId === horusId.text);
}

export function blockUserPermission(userAuth, companyId) {
  return !(userAuth.role !== "ADMIN" && companyId === horusId.number);
}

export function updateUserPermission(userAuth, selectedUser) {
  return !(
    userAuth.role !== "ADMIN" &&
    (selectedUser.companyId === horusId.number ||
      selectedUser.companyId === horusId.text)
  );
}

export function validateNumericSize(str, min, max) {
  if (!str || isNaN(str)) return null;
  return str.match(new RegExp(".".repeat(min) + ".?".repeat(max)))[0];
}

export function validateTextSize(str, min, max) {
  if (!str || str === "") return null;
  return str.match(new RegExp(".".repeat(min) + ".?".repeat(max)))[0];
}

// eslint-disable-next-line
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};
