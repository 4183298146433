import Table from "../../Table";
import { Title } from "../../Titles";
import Button from "../../Button";

export default function ClientListPlans({ client, userId, plans, cancelPlan }) {

  const goToEdit = (plan_id) => {
    window.location.href = `/plans-details/${client?.id}/${userId}/${plan_id}/${client?.name}`
  }

  return (
    <>
      <Title>Assinaturas</Title>
      <Table>
        <thead>
          <tr>
            <th className="align-center">Id</th>
            <th className="align-center">Tipo</th>
            <th className="align-center">Parâmetros</th>
            <th className="align-center">Valor</th>
            <th className="align-center">Data Criação</th>
            <th className="align-center">Data Finalização</th>
            <th className="align-center">Status</th>
            <th className="align-center">Ações</th>
          </tr>
        </thead>
        <tbody>
          {plans ? (
            plans
              .filter((p) => p.plan !== "OTHER")
              .map((plan) => {
                return (
                  <tr key={plan.id}>
                    <td className="align-center">{plan.id}</td>
                    <td className="align-center">{plan.plan}</td>
                    <td className="align-center limit-size">
                      {JSON.stringify(plan.params).replace(/{|}|\(|\)|"/g, "")}
                    </td>
                    <td className="align-center">{plan.value}</td>
                    <td className="align-center">{plan.createdAt}</td>
                    <td className="align-center">{plan.finishedAt}</td>
                    <td className="align-center">{plan.status}</td>
                    <td className="align-center">
                      {plan.status === "CANCELED" ? null: (
                        <>
                          <Button
                            layout="info"
                            onClick={() => goToEdit(plan?.id)}
                            disabled={!userId}
                          >
                            Editar
                          </Button>&nbsp;
                          <Button
                            layout="error"
                            onClick={() => cancelPlan(userId, plan)}
                            disabled={!userId}
                          >
                            Cancelar Plano
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
          ) : (
            <tr>
              <td colSpan="2">Nenhuma assinatura cadastrada.</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
