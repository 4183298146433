import useProductCompanyCodePage from "../../../service/product/useProductCompanyCodePage";
import Input from "../../form/Input";
import Button from "../../form/Button";
import Spacer from "../../ui/Spacer";

interface Props {
  handleShowHiddenFieldsOnCLick: () => void;
}
export default function NewProductCompanyCodeForm(props: Props) {

  const {handleShowHiddenFieldsOnCLick } = props;

  const { loadingAction, newProductCompanyCodeForm, handleNewPccFormOnChange, handleCreateOnConfirm } = useProductCompanyCodePage();

  return (
    <div style={ { display: "flex" } }>
      <Input
        onChange={ handleNewPccFormOnChange }
        value={ newProductCompanyCodeForm.cnpj }
        name="cnpj"
        placeholder="Digite..."
        label="CNPJ*"
      />
      <div style={ { width: "10px"} }></div>
      <div style={ { width: "-webkit-fill-available"} }>
        <Input
          onChange={ handleNewPccFormOnChange }
          value={ newProductCompanyCodeForm.description }
          name="description"
          placeholder="Digite..."
          label="Descrição (app_produtobaixa)*"
        />
      </div>
      <div style={ { width: "10px"} }></div>
      <Input
        onChange={ handleNewPccFormOnChange }
        value={ newProductCompanyCodeForm.productCode }
        name="productCode"
        placeholder="Digite..."
        label="Código do Produto*"
      />
      <div style={ { width: "10px"} }></div>
      <Input
        onChange={ handleNewPccFormOnChange }
        value={ newProductCompanyCodeForm.productId?.toString() }
        name="productId"
        placeholder="Digite..."
        label="ID do Produto*"
      />
      <div style={ { width: "10px"} }></div>
      <Input
        onChange={ handleNewPccFormOnChange }
        value={ newProductCompanyCodeForm.itemId?.toString() }
        name="itemId"
        placeholder="Digite..."
        label="ID do Item"
      />
      <div style={ { width: "10px"} }></div>
      { loadingAction ? (
        <div style={ { alignSelf: "self-end" }}>
          <Button disabled={true}>Carregando</Button>
          <Spacer ratio={ 2/3 } />
        </div>
      ) : (
        <div style={ { display: "flex" } }>
          <div style={ { alignSelf: "self-end" }}>
            <Button type="confirm" onClick={ () => handleCreateOnConfirm(newProductCompanyCodeForm) }>Salvar</Button>
            <Spacer ratio={ 2/3 } />
          </div>
          <div style={ { width: "10px"} }></div>
          <div style={ { alignSelf: "self-end" }}>
            <Button type="cancel" onClick={ handleShowHiddenFieldsOnCLick }>Cancelar</Button>
            <Spacer ratio={ 2/3 } />
          </div>
        </div>
      )}
    </div>
  )
}