import { useState } from "react";
import Button from "../../../../../../components/Button";

export default function MassProdConfirmModal({ newProduct, selectedItems, handleUpdateProducts }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      {
        !selectedItems ? (
          <div>
            <p style={{ "color": "red", "textAlign": "center" }}><strong>ATENÇÃO!!!</strong></p>
            <p style={{ "color": "red", "textAlign": "center" }}>NENHUM ITEM FOI SELECIONADO</p>
          </div>
        ) : (
          <div>
            <p style={{ "textAlign": "center" }}>{ selectedItems } produto(s) selecionado(s)</p>
            <p style={{ "textAlign": "center" }}>Tem certeza que deseja atualizar conformes os campos abaixo:</p>
            { newProduct.category ? <p><strong>Categoria Produto: </strong>{ newProduct.category } - { newProduct.categoryId }</p> : null }
            { newProduct.reportId ? <p><strong>Categoria: </strong>{ newProduct.categoryReport } - { newProduct.reportId }</p> : null }
            { newProduct.brand ? <p><strong>Marca: </strong>{ newProduct.brand }</p> : null }
            { newProduct.manufacturer ? <p><strong>Fabricante: </strong>{ newProduct.manufacturer }</p> : null }
            { newProduct.unitOfMeasurement ? <p><strong>Unidade de medida: </strong>{ newProduct.unitOfMeasurement }</p> : null }
            { newProduct.quantity ? <p><strong>Quantidade unitária: </strong>{ newProduct.quantity }</p> : null }
            { newProduct.quantityPerPack ? <p><strong>Quantidade no pack: </strong>{ newProduct.quantityPerPack }</p> : null }
            { newProduct.status ? <p><strong>Status: </strong>{ newProduct.status }</p> : null }
            { newProduct.saleType ? <p><strong>Nível de Preço: </strong>{ newProduct.saleType }</p> : null }
            { newProduct.tags.length > 0 ? <p><strong>Status: </strong>{ newProduct.tags }</p> : null }
          </div>
        )
      }
      <Button
        layout="confirm"
        onClick={ () => {
          setLoading(true);
          handleUpdateProducts(newProduct)
        } }
        disabled={ !selectedItems || loading }
      >
        { loading ? "Carregando... este processo pode demorar alguns minutos" : "Atualizar" }
      </Button>
    </>
  )
}