import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
export interface SeriesTypeView {
  id: string;
  description: string;
  frequency: number;
  permissions?: Array<string>;
  helthyRange?: Array<number>;
  available: boolean;
}

export interface SeriesItemView {
  id: number;
  values: Record<string, number | null>;
}

export interface SeriesItemReportForm {
  types: Array<string> | null;
  start: number;
  end: number;
}

export interface SeriesErrorView {
  id: string;
  description: string;
  messages: Array<string>;
  timestamp: number;
  value: number;
}

export type UseSeriesRequests = typeof useSeriesRequests;
export type FindSeriesTypeById =
  ReturnType<UseSeriesRequests>["findSeriesTypeById"];
export type FindSeriesItem = ReturnType<UseSeriesRequests>["findSeriesItem"];
export type FindErrors = ReturnType<UseSeriesRequests>["findErrors"];

export default function useSeriesRequests() {
  const findSeriesTypeById = (id: string) => baseFindSeriesTypeById(id);

  const findSeriesItem = (params: SeriesItemReportForm) =>
    baseFindSeriesItem(params);

  const findErrors = () => baseFindErrors();

  return { findSeriesTypeById, findSeriesItem, findErrors };
}

async function baseFindSeriesTypeById(
  id: string
): Promise<Array<SeriesTypeView>> {
  const response = await http.get(`${baseUrl}/api/v1/report/series-type/${id}`);
  return response.data;
}

async function baseFindSeriesItem(
  params: SeriesItemReportForm
): Promise<{ result: Array<SeriesItemView> }> {
  const response = await http.post(
    `${baseUrl}/api/v1/report/series-item`,
    params
  );
  return response.data;
}

async function baseFindErrors(): Promise<{ result: Array<SeriesErrorView> }> {
  const response = await http.get(`${baseUrl}/api/v1/report/failures`);
  return response.data;
}
