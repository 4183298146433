import axios, { AxiosError, AxiosResponse } from "axios";
import { logout } from "../authentication/logout";
import { baseUrl } from "../constants";
import { DATE_TIME_CONSTANTS } from "../enums/dateTime";
import { authCredentials } from "../utils/authCredentials";

let inRefresh = false;
let failedRequestsQueue: any[] = [];

const api = axios.create({
  timeout: DATE_TIME_CONSTANTS.TIME_IN_MILLISECONDS.MINUTE * 2, // 2 minutos
});

api.interceptors.request.use(
  (config) => {
    const accessToken = authCredentials.get.accessToken();
    if (accessToken && config.headers) {
      config.headers.Token = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  async (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      const { user } = authCredentials.get.authUser();
      const token = authCredentials.get.refreshToken();
      const originalConfig = error.config;

      if (!inRefresh) {
        inRefresh = true;
        axios
          .post(
            baseUrl + "/api/v1/auth/refresh",
            { token, user },
            {
              validateStatus: (status) => status === 200,
              headers: {
                Token: "YXPbX2F1dGhfc2VjcmV0X2tleV9ncmdpOS5jb20=",
              },
            }
          )
          .then((response) => {
            const token = response.data.token;
            const refreshToken = response.data.refresh_token;
            authCredentials.set.accessToken(token);
            authCredentials.set.refreshToken(refreshToken);

            failedRequestsQueue.forEach((request) => request.onSuccess(token));
            failedRequestsQueue = [];
          })
          .catch((err) => {
            failedRequestsQueue.forEach((request) => request.onFailure(err));
            failedRequestsQueue = [];
            logout(true);
          })
          .finally(() => {
            inRefresh = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          onSuccess: () => resolve(api(originalConfig)),
          onFailure: (err: AxiosError) => reject(err),
        });
      });
    }
    return Promise.reject(error);
  }
);

export const apiV1 = api;
