import axios, { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import { QUERY_API, baseUrl } from "../constants";
import { authCredentials } from "../utils/authCredentials";

export type AuthUserModel = {
  user: string;
  company: string;
  companyId: number;
  email: string;
  exp: number;
  firstName: string;
  groups: Array<number>;
  is_staff: boolean;
  lastName: string;
  role: string;
  userId: number;
  username: string;
};

const setCredential = (
  response: AxiosResponse<any>,
  user: string,
  claims: AuthUserModel
) => {
  authCredentials.set.accessToken(response.data.token);
  authCredentials.set.authUser(user, claims);
  authCredentials.set.refreshToken(response.data.refresh_token);
  localStorage.removeItem(QUERY_API.HOST);
  localStorage.removeItem(QUERY_API.USER);
  localStorage.removeItem(QUERY_API.PASSWORD);
};

type LoginProps = {
  user: string;
  password: string;
};

export const login = async (credentials: LoginProps) => {
  const response = await axios.post(baseUrl + "/api/v1/auth", credentials, {
    validateStatus: (status) => status === 200,
  });

  const decoded: AuthUserModel | null = jwt.decode(
    response.data.token
  ) as AuthUserModel;

  if (!decoded?.is_staff) {
    throw new Error("Você não possui autorização para acessar este sistema");
  }

  try {
    const { data: responseGroup } = await axios.get(
      baseUrl + `/user-groups/${decoded?.userId}`,
      {
        headers: {
          Token: response.data.token,
        },
      }
    );
    decoded.groups = responseGroup.map((group: any) => group.groupId);
  } catch (e: any) {
    throw new Error(e);
  }
  try {
    const { data: responseGroup } = await axios.get(
      baseUrl + `/user-groups/${decoded.userId}`,
      {
        headers: {
          Token: response.data.token,
        },
      }
    );
    decoded.groups = responseGroup.map((group: any) => group.groupId);
  } catch (e: any) {
    throw new Error(e);
  }

  setCredential(response, credentials.user, decoded);
};
