import { Switch, Route } from "react-router-dom";

import { menus } from '../../../constants';
import Template from '../../../components/Template';
import { Title } from '../../../components/Titles';
import Card from "../ui/Card";
import Tabs from "../ui/Tabs";
import SubTitle from "../ui/SubTitle";
import ManufacturerManagementPage from "./manufacturer/ManufacturerManagementPage";
import { useMenu } from "../../service/context/globalContext";
import ProductCategoryCreatePage from "./category/product-category/ProductCategoryCreatePage";
import NielsenCategoryCreatePage from "./category/nielsen-category/NielsenCategoryCreatePage";
import ProductQueuePage from "../../../pages/ProductQueuePage";

const breadcrump = [ menus.home, menus.product ];

export default function ProductManagementPage() {

  const menu = useMenu("/product");

  return (
    <Template breadcrump={ breadcrump }>
      <Title>Gestão de Produtos</Title>
      <SubTitle>
        Moderação de Produtos, Marcas e Fabricantes.
      </SubTitle>
      <Tabs menu={ menu } />
      <Card>
        <Switch>
          <Route exact path="/product/moderation">
            <ProductQueuePage />
          </Route>
          <Route exact path="/product/manufacturer/:page">
            <ManufacturerManagementPage />
          </Route>
          <Route exact path="/product/product-category/new">
            <ProductCategoryCreatePage />
          </Route>
          <Route exact path={ [ "/product/product-category/edit", "/product-management/product-category/edit/:id" ] }>
            <ProductCategoryCreatePage edit />
          </Route>
          <Route exact path="/product/nielsen-category/new">
            <NielsenCategoryCreatePage />
          </Route>
          <Route exact path={ [ "/product/nielsen-category/edit", "/product-management/nielsen-category/edit/:id" ] }>
            <NielsenCategoryCreatePage edit />
          </Route>
        </Switch>
      </Card>
    </Template>
  )
}