import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";
import { Brand } from "../../../service/company/useCompanyBrandpage";
import { getErrorMessage } from "../../../service/error/getErrorMessage";
import useModal, { OpenModal } from "../../../service/modal/useModal";
import Button from "../../form/Button";

export default function CompanyBrandDelete(props: { brand: Brand }) {
  const { brand } = props;

  const { openModal, closeModal } = useModal();

  const [state, setState] = useState<{ loading: boolean; brand: Brand }>({
    loading: false,
    brand: {
      id: brand.id,
      name: brand.name,
      file: brand.file,
      economicGroup: brand.economicGroup,
      cnpj8: brand.cnpj8,
      category: brand.category,
      wideBrand: brand.wideBrand,
      companyQtt: brand.companyQtt,
    },
  });

  const handleOnDelete = useCallback(
    () => deleteBrand(openModal, setState, state),
    [openModal, setState, state]
  );

  return (
    <div>
      <p>Tem certeza que deseja deletar a Marca {state.brand.name}?</p>

      {state.loading ? (
        <Button disabled={true} type="confirm">
          Carregando...
        </Button>
      ) : (
        <>
          <Button
            type="confirm"
            disabled={Boolean(state.brand.companyQtt) || state.loading}
            onClick={() => handleOnDelete()}
          >
            Deletar
          </Button>{" "}
          <Button onClick={closeModal} type="cancel">
            Cancelar
          </Button>
        </>
      )}
    </div>
  );
}

type SetState = Dispatch<SetStateAction<{ loading: boolean; brand: Brand }>>;

async function deleteBrand(
  openModal: OpenModal,
  setState: SetState,
  state: { loading: boolean; brand: Brand }
) {
  try {
    setState((prev) => ({ ...prev, loading: true }));

    await http.delete(`${baseUrl}/api/v1/company/brand/${state.brand.id}`);

    openModal("Marca deletada com sucesso.");
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}
