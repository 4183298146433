import { ReactNode } from "react";
import useModalJs from "../../../hooks/useModal"

export type OpenModal = (node: ReactNode) => void
export type CloseModal = () => void

function useModal() {

  return useModalJs() as {
    openModal: OpenModal,
    closeModal: CloseModal
  };

}

export default useModal