import { PRODUCT_STATUS } from '../../../../../constants';
import { SubTitle } from '../../../../../components/Titles';
import Button from '../../../../../components/Button';
import Input from '../../../../../components/Input';
import Pagination from '../../../../../components/Pagination'
import useModal from '../../../../../hooks/useModal';
import useProductCategory from '../../../../../hooks/useProductCategory';
import useProductBrand from '../../../../../hooks/useProductBrand';
import useProductManufacturer from '../../../../../hooks/useProductManufacturer';
import RemovePriority from '../components/removePriorityModal';
import QueueSetupButtons from '../components/queueSetupButtons';
import DisplayProductList from './displayProductList';
import ProductModeration from './productMassiveModeration';
import ProductQueueModeration from './productQueueSetup';

export default function ProductQueueSetup(props) {
  const { items, itemsPerPage, pageNumber, searchItemsList: searchEanList, setSearchItemsList, selectedItems, fullListSize, loading,
    handleSearchQueueOnClick, handleFetchPriorityItemsOnClick, handleSendItems, handleUpdateItems,
    handleRemovePriorities, handleRemoveAllPriorities, prioritizedItems, onChangePage, clearItems} = props;

  // # OTHER HOOKS
  const { createModal } = useModal();
  const { fetchCategories } = useProductCategory();
  const { searchBrands: fetchBrands } = useProductBrand();
  const { fetchManufacturers } = useProductManufacturer();

  // MODALS
  const openModerationModal = () => createModal((
    <ProductModeration
      loading={ loading }
      selectedItems={ selectedItems }
      fullListSize={ fullListSize }
      fetchProductStatus={ PRODUCT_STATUS }
      handleUpdateProducts={ handleUpdateItems }
      fetchCategories={ fetchCategories }
      fetchManufacturers={ fetchManufacturers }
      fetchBrands={ fetchBrands }
    />
  ), { replace: true });

  const openQueueModal = () => createModal((
    <ProductQueueModeration
      selectedItems={ selectedItems.length }
      handleSendItems={ handleSendItems }
    />
  ), { replace: true });

  const openRemovePriorityModal = () => createModal((
    <RemovePriority
      selectedItems={ selectedItems.length }
      handleRemoveAllPriorities={ handleRemoveAllPriorities }
      handleRemovePriorities={ handleRemovePriorities }
    />
  ), { replace: true });

  // # HTML
  return (
    <div>
      {
        items?.length > 0 ? null : (
          <div>
            <SubTitle>Buscar produtos</SubTitle>
            <div className="h-row">
              <div className="h-col h-col-2">
                <Input
                  label="Eans"
                  placeholder="ean,ean,ean,ean..."
                  value={ searchEanList }
                  onChange={ e => setSearchItemsList(e.target.value) }
                />
              </div>
              <Button
                layout="confirm"
                disabled={ !searchEanList || loading }
                onClick={ () => handleSearchQueueOnClick() }
                className="h-input-button"
              >
                { loading ? "Carregando..." : "Buscar" }
              </Button>&nbsp;
              <Button
                layout="info"
                disabled={ loading }
                onClick={ handleFetchPriorityItemsOnClick }
                className="h-input-button"
              >
                { loading ? "Carregando..." : "Produtos Priorizados" }
              </Button>
            </div>
          </div>
        )
      }
      
      {
        items?.length > 0 ? (
          <>
            <span>Exibindo itens: { (200 * (pageNumber - 1)) + 1 } - { (200 * (pageNumber - 1)) + items.length }, de um total de: { fullListSize } itens </span>
            <QueueSetupButtons
              prioritizedItems={ prioritizedItems }
              selectedItems={ selectedItems }
              loading={ loading }
              openModerationModal={ openModerationModal }
              openQueueModal={ openQueueModal }
              openRemovePriorityModal={ openRemovePriorityModal }
              clearItems={ clearItems }
            />
            <DisplayProductList
              items={ items }
            />
            <Pagination itemsPerPage={ itemsPerPage } totalItems={ fullListSize } onChangePage={ onChangePage }/>
          </>
        ) : (null)
      }
    </div>
  );
}