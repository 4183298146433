import { Chart as ChartJS } from "chart.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { ufCodes } from "../../../constants";
import { getErrorMessage } from "../../service/error/getErrorMessage";
import useHandleOnChange from "../../service/form/useHandleOnChange";
import useModal, { OpenModal } from "../../service/modal/useModal";
import useReportRequests, {
  GetHourlyPartnerImportPerformanceItem,
  HourlyPartnerImportPerformanceItem,
} from "../../service/report/useReportRequests";
import { fill } from "../../service/string";
import { getRandomRgb } from "../../service/ui";
import { SetState } from "../../types";
import Button from "../form/Button";
import Input from "../form/Input";
import Select, { Option } from "../form/Select";
import Col from "../ui/Col";
import Row from "../ui/Row";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";

export function PartnerCouponReports() {
  const [state, setState] = useState<State>(initialState);

  const {
    reports,
    selectedEnd,
    selectedStart,
    selectedModel,
    selectedOrigin,
    selectedPartner,
    selectedUf,
    selectedGroupBy,
    formEnd,
    formStart,
    formModel,
    formOrigin,
    formPartner,
    formUf,
    formGroupBy,
    partnerOptions,
  } = state;

  const { openModal } = useModal();

  const { getHourlyPartnerImportPerformanceItem } = useReportRequests();

  const handleOnChange = useHandleOnChange(setState);

  const fetchData = useCallback(
    () =>
      baseFetchData({
        setState,
        getHourlyPartnerImportPerformanceItem,
        openModal,
        start: selectedStart,
        end: selectedEnd,
      }),
    [state]
  );

  const filter = useCallback(() => baseFilter(setState, state), [state]);

  const validation = validate(state);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <SectionTitle
        title="Performance de Importação"
        description="Performance da importação de notas a cada hora"
      />
      <Row spacing="10px">
        <Col width={4 / 20}>
          <Input
            label="Início"
            placeholder="Ex: 12/05/2022 15h"
            name="formStart"
            value={formStart}
            onChange={handleOnChange}
            valid={validation.formStart}
          />
        </Col>
        <Col width={4 / 20}>
          <Input
            label="Fim"
            placeholder="Ex: 12/05/2022 22h"
            name="formEnd"
            value={formEnd}
            onChange={handleOnChange}
            valid={validation.formEnd}
          />
        </Col>
        <Col width={2 / 20}>
          <Input
            label="UF"
            placeholder="Ex: RJ"
            name="formUf"
            value={formUf}
            onChange={handleOnChange}
          />
        </Col>
        <Col width={3 / 20}>
          <Input
            label="Modelo"
            placeholder="65, 59 ou 55"
            name="formModel"
            value={formModel}
            onChange={handleOnChange}
          />
        </Col>
        <Col width={2 / 20}>
          <Select
            label="Parceiro"
            options={partnerOptions}
            name="formPartner"
            value={formPartner}
            onChange={handleOnChange}
          />
        </Col>
        <Col width={2 / 20}>
          <Select
            label="Tipo"
            options={[
              { id: "SEFAZ", name: "Web Scrapping" },
              { id: "PROVIDED", name: "Arquivos de Notas" },
            ]}
            name="formOrigin"
            value={formOrigin}
            onChange={handleOnChange}
          />
        </Col>
        <Col width={2 / 20}>
          <Select
            label="Agrupar por"
            options={[
              { id: "partner", name: "Parceiro" },
              { id: "uf", name: "UF" },
              { id: "model", name: "Modelo" },
            ]}
            name="formGroupBy"
            value={formGroupBy}
            onChange={handleOnChange}
          />
        </Col>
        <Col width={1 / 20}>
          <Button
            onClick={filter}
            disabled={!validation.allValid}
            type="confirm"
            addInputLabelSpacing
            fullWidth
          >
            Ok
          </Button>
        </Col>
      </Row>
      <Spacer />
      {!reports ? (
        "Carregando..."
      ) : (
        <Charts
          reports={reports}
          start={selectedStart}
          end={selectedEnd}
          model={selectedModel}
          uf={selectedUf}
          partner={selectedPartner}
          origin={selectedOrigin}
          groupBy={selectedGroupBy}
        />
      )}
    </div>
  );
}

interface ChartsProps {
  reports: HourlyPartnerImportPerformanceItem[];
  start: number;
  end: number;
  model: string | null;
  uf: string | null;
  partner: string | null;
  origin: string | null;
  groupBy: GroupByOption;
}

function Charts(props: ChartsProps) {
  const { reports, start, end, model, uf, partner, origin, groupBy } = props;

  const amountsChartRef = useRef<HTMLCanvasElement>(null);
  const latenessChartRef = useRef<HTMLCanvasElement>(null);
  const groupedChartRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const data = getChartData({
      reports,
      start,
      end,
      model,
      uf,
      partner,
      origin,
      groupBy,
    });
    const groupedData = getGroupedChartData({
      reports,
      start,
      end,
      model,
      uf,
      partner,
      origin,
      groupBy,
    });

    if (!amountsChartRef.current) return;
    if (!latenessChartRef.current) return;
    if (!groupedChartRef.current) return;

    const amountsChart = new ChartJS(amountsChartRef.current, {
      type: "line",
      data: data.amountsChartData,
      options: data.options,
    });
    const latenessChart = new ChartJS(latenessChartRef.current, {
      type: "line",
      data: data.latenessAverageChartData,
      options: data.options,
    });
    const groupedChart = new ChartJS(groupedChartRef.current, {
      type: "line",
      data: groupedData.data,
      options: groupedData.options,
    });

    return () => {
      amountsChart.destroy();
      latenessChart.destroy();
      groupedChart.destroy();
    };
  }, [
    reports,
    start,
    end,
    model,
    uf,
    partner,
    origin,
    groupBy,
    amountsChartRef,
  ]);

  return (
    <Row>
      <Col width={6 / 10}>
        <div style={{ height: "300px" }}>
          <canvas ref={amountsChartRef} />
        </div>
      </Col>
      <Col width={4 / 10}>
        <div style={{ height: "300px" }}>
          <canvas ref={latenessChartRef} />
        </div>
      </Col>
      <Col width={10 / 10}>
        <Spacer ratio={2} />
        <div style={{ height: "300px" }}>
          <canvas ref={groupedChartRef} />
        </div>
      </Col>
    </Row>
  );
}

type GroupByOption = "uf" | "model" | "partner";

interface State {
  reports: null | HourlyPartnerImportPerformanceItem[];
  formPartner: null | string;
  formUf: null | string;
  formOrigin: null | string;
  formModel: null | string;
  formStart: null | string;
  formEnd: null | string;
  formGroupBy: null | string;
  selectedPartner: null | string;
  selectedUf: null | string;
  selectedOrigin: null | string;
  selectedModel: null | string;
  selectedStart: number;
  selectedEnd: number;
  selectedGroupBy: GroupByOption;
  partnerOptions: Option[];
}

const defaults = getDefaultStartEnd();

const initialState: State = {
  reports: null,
  formPartner: null,
  formUf: null,
  formOrigin: null,
  formModel: null,
  formStart: null,
  formEnd: null,
  formGroupBy: null,
  selectedPartner: null,
  selectedUf: null,
  selectedOrigin: null,
  selectedModel: null,
  selectedStart: defaults.start,
  selectedEnd: defaults.end,
  selectedGroupBy: "uf",
  partnerOptions: [],
};

interface FetchDataParams {
  setState: SetState<State>;
  getHourlyPartnerImportPerformanceItem: GetHourlyPartnerImportPerformanceItem;
  openModal: OpenModal;
  start: number;
  end: number;
}

async function baseFetchData(params: FetchDataParams) {
  const {
    setState,
    getHourlyPartnerImportPerformanceItem,
    openModal,
    start,
    end,
  } = params;

  try {
    const { result: reports } = await getHourlyPartnerImportPerformanceItem({
      start,
      end,
    });

    const partnerOptions = reports
      .map((i) => i.partner)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((i) => ({
        id: i,
        name: i,
      }));

    setState((prev) => ({ ...prev, reports, partnerOptions }));
  } catch (e) {
    openModal(getErrorMessage(e));
  }
}

function getDefaultStartEnd() {
  const today = new Date();

  const prefix = `${today.getFullYear() - 2000}${fill(
    String(today.getMonth() + 1),
    "0",
    2
  )}${fill(String(today.getDate()), "0", 2)}`;

  return {
    start: parseInt(prefix + "00"),
    end: parseInt(prefix + fill(String(today.getHours()), "0", 2)),
  };
}

function getGroupedChartData(params: ChartsProps) {
  const { start, end, reports, origin, partner, uf, model, groupBy } = params;

  const filteredReports = filterReports({
    reports,
    origin,
    partner,
    uf,
    model,
  });

  const groups: Record<
    string,
    Record<number, HourlyPartnerImportPerformanceItem>
  > = {};

  filteredReports.forEach((report) => {
    const currentGroup = report[groupBy];

    if (!groups[currentGroup]) {
      groups[currentGroup] = {};
    }

    groups[currentGroup][report.sentHourDay] = groups[currentGroup][
      report.sentHourDay
    ]
      ? mergeReports(groups[currentGroup][report.sentHourDay], report)
      : report;
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  let labels: string[] = [];

  const datasets: Array<{
    label: string;
    data: number[];
    borderColor: string;
    backgroundColor: string;
  }> = Object.entries(groups).map((entry) => {
    const groupedReports = Object.values(entry[1]);
    const filledReports = fillReportBlocks(groupedReports, start, end);

    labels = filledReports.map((i) => getLabel(i));

    if (groupBy === "uf") {
      const uf = ufCodes.find((i) => i.code === entry[0]) || {
        color: getRandomRgb(),
        name: "Desconhecido",
      };

      return {
        label: uf.name,
        data: filledReports.map((i) => i.amountProcessed),
        borderColor: `rgba(${uf.color[0]}, ${uf.color[1]}, ${uf.color[2]}, 1)`,
        backgroundColor: `rgba(${uf.color[0]}, ${uf.color[1]}, ${uf.color[2]}, 0.2)`,
      };
    }

    const color = getRandomRgb();

    return {
      label: entry[0],
      data: filledReports.map((i) => i.amountProcessed),
      borderColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 1)`,
      backgroundColor: `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.2)`,
    };
  });

  return {
    options,
    data: { labels, datasets },
  };
}

function getChartData(params: ChartsProps) {
  const { start, end, reports, origin, partner, uf, model } = params;

  const filteredReports = filterReports({
    reports,
    origin,
    partner,
    uf,
    model,
  });

  const groups: Record<number, HourlyPartnerImportPerformanceItem> = {};

  filteredReports.forEach((report) => {
    groups[report.sentHourDay] = groups[report.sentHourDay]
      ? mergeReports(groups[report.sentHourDay], report)
      : report;
  });

  const groupedReports = Object.values(groups);
  const filledReports = fillReportBlocks(groupedReports, start, end);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
      },
    },
  };

  const labels = filledReports.map((i) => getLabel(i));

  const amountsChartData = {
    labels,
    datasets: [
      {
        label: "Enviadas",
        data: filledReports.map((i) => i.amountSent),
        borderColor: "orange",
        backgroundColor: "rgba(245, 191, 39, 0.2)",
      },
      {
        label: "Processadas",
        data: filledReports.map((i) => i.amountProcessed),
        borderColor: "green",
        backgroundColor: "rgba(58, 245, 39, 0.2)",
      },
      {
        label: "Duplicada",
        data: filledReports.map((i) => i.amountDuplicated),
        borderColor: "blue",
        backgroundColor: "rgba(50, 39, 245, 0.2)",
      },
      {
        label: "Inválidas",
        data: filledReports.map((i) => i.amountInvalid),
        borderColor: "red",
        backgroundColor: "rgba(245, 39, 39, 0.2)",
      },
    ],
  };

  const latenessAverageChartData = {
    labels,
    datasets: [
      {
        label: "Tempo Médio de Atraso (Horas)",
        data: filledReports.map((i) => i.lateness),
        borderColor: "orange",
        backgroundColor: "rgba(245, 191, 39, 0.2)",
      },
    ],
  };

  return { options, amountsChartData, latenessAverageChartData };
}

function mergeReports(
  a: HourlyPartnerImportPerformanceItem,
  b: HourlyPartnerImportPerformanceItem
): HourlyPartnerImportPerformanceItem {
  return {
    amountDuplicated: a.amountDuplicated + b.amountDuplicated,
    amountSent: a.amountSent + b.amountSent,
    amountProcessed: a.amountProcessed + b.amountProcessed,
    amountInvalid: a.amountInvalid + b.amountInvalid,

    lateness:
      a.amountProcessed + b.amountProcessed > 0
        ? (a.lateness * a.amountProcessed + b.lateness * b.amountProcessed) /
          (a.amountProcessed + b.amountProcessed)
        : 0,

    sentHourDay: a.sentHourDay,

    uf: "",
    model: "",
    partner: "",
    origin: "",
  };
}

function fillReportBlocks(
  reports: HourlyPartnerImportPerformanceItem[],
  start: number,
  end: number
) {
  const reportsCopy = [...reports];
  const filledBlocks: HourlyPartnerImportPerformanceItem[] = [];

  let currentBlock = start;

  while (currentBlock <= end) {
    if (reportsCopy.length) {
      const firstBlock = reportsCopy.shift()!;

      if (firstBlock.sentHourDay === currentBlock) {
        filledBlocks.push(firstBlock);
        currentBlock = nextBlock(currentBlock);
        continue;
      } else {
        reportsCopy.unshift(firstBlock);
      }
    }

    filledBlocks.push({
      amountDuplicated: 0,
      amountSent: 0,
      amountProcessed: 0,
      amountInvalid: 0,
      lateness: 0,
      sentHourDay: currentBlock,
      uf: "",
      model: "",
      partner: "",
      origin: "",
    });

    currentBlock = nextBlock(currentBlock);
  }

  return filledBlocks;
}

function getLabel(view: HourlyPartnerImportPerformanceItem) {
  return String(view.sentHourDay).replaceAll(/^\d{6}(\d{2})$/g, "$1:00");
}

function baseFilter(setState: SetState<State>, state: State) {
  const defaults = getDefaultStartEnd();

  const selectedUfObj = state.formUf
    ? ufCodes.find((uf) => uf.name === state.formUf)
    : null;
  const selectedUf = selectedUfObj ? selectedUfObj.code : null;

  setState((prev) => ({
    ...prev,

    selectedEnd: !state.formEnd
      ? defaults.end
      : parseInt(
          state.formEnd.replaceAll(
            /^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g,
            "$3$2$1$4"
          )
        ),
    selectedStart: !state.formStart
      ? defaults.start
      : parseInt(
          state.formStart.replaceAll(
            /^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g,
            "$3$2$1$4"
          )
        ),
    selectedModel: state.formModel,
    selectedOrigin: state.formOrigin,
    selectedPartner: state.formPartner,
    selectedGroupBy: state.formGroupBy
      ? (state.formGroupBy as GroupByOption)
      : initialState.selectedGroupBy,
    selectedUf,
  }));
}

interface FilterReportsParams {
  reports: HourlyPartnerImportPerformanceItem[];
  partner: null | string;
  uf: null | string;
  origin: null | string;
  model: null | string;
}

function filterReports(params: FilterReportsParams) {
  const { reports, partner, uf, origin, model } = params;

  return reports.filter((report) => {
    if (origin && report.origin !== origin) {
      return false;
    }

    if (model && report.model !== model) {
      return false;
    }

    if (uf && report.uf !== uf) {
      return false;
    }

    if (partner && report.partner !== partner) {
      return false;
    }

    return true;
  });
}

function nextBlock(block: number): number {
  const year = Math.floor(block / 1000000) + 2000;
  const month = (Math.floor(block / 10000) % 100) - 1;
  const day = Math.floor(block / 100) % 100;
  const hour = block % 100;

  const date = new Date(year, month, day, hour, 0, 0, 0);

  date.setHours(date.getHours() + 1);

  const format = `${date.getFullYear() - 2000}${fill(
    String(date.getMonth() + 1),
    "0",
    2
  )}${fill(String(date.getDate()), "0", 2)}${fill(
    String(date.getHours()),
    "0",
    2
  )}`;

  return parseInt(format);
}

function validate(state: State) {
  const validation = {
    allValid: true,
    formStart: true,
    formEnd: true,
  };

  if (
    state.formStart != null &&
    state.formStart.match(/^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g) === null
  ) {
    validation.allValid = false;
    validation.formStart = false;
  }

  if (
    state.formEnd != null &&
    state.formEnd.match(/^(\d{2})\/(\d{2})\/\d{2}(\d{2}) (\d{2})h$/g) === null
  ) {
    validation.allValid = false;
    validation.formEnd = false;
  }

  return validation;
}
