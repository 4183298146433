import { AxiosRequestConfig } from "axios";
import { apiV1 } from ".";

export const http = {
  get: (url: string, config?: AxiosRequestConfig) => apiV1.get(url, config),

  post: (url: string, data?: any, config?: AxiosRequestConfig) =>
    apiV1.post(url, data, config),

  put: (url: string, data?: any, config?: AxiosRequestConfig) =>
    apiV1.put(url, data, config),

  delete: (url: string, config?: AxiosRequestConfig) =>
    apiV1.delete(url, config),
};
