import { mediaPrefix } from "../../../../constants";
import useCompanyBrandManagement, {
  Brand,
} from "../../../service/company/useCompanyBrandpage";
import Button from "../../form/Button";
import Input from "../../form/Input";
import Pagination from "../../ui/Pagination";
import { SectionTitle } from "../../ui/SectionTitle";
import Spacer from "../../ui/Spacer";
import { TableResponsiveWrapper } from "../../ui/TableResponsiveWrapper";
import styles from "../../ui/ui.module.css";
import brandStyles from "../styles.module.css";

export default function CompanyBrandPage() {
  const emptyBrand: Brand = {
    id: null,
    name: null,
    file: null,
    economicGroup: null,
    cnpj8: null,
    category: null,
    wideBrand: false,
    companyQtt: 0,
  };

  const {
    handleFindOnSubmit,
    handleOnChange,
    handlePageOnChange,
    filter,
    items,
    totalPages,
    page,
    handleEditOnClick,
    handleOnDeleteClick,
  } = useCompanyBrandManagement();

  return (
    <div>
      <SectionTitle
        title="Gestão de Rede"
        description="Consulta, edição e criação de redes."
      />

      <form onSubmit={handleFindOnSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre a rede pelo nome"
          addons={[
            <Button key="1" type="info" submit>
              Pesquisar
            </Button>,
          ]}
        />
      </form>
      <div>
        <Button onClick={() => handleEditOnClick(emptyBrand)} type="confirm">
          Criar Rede
        </Button>
        <Spacer ratio={2 / 3} />
      </div>
      <TableResponsiveWrapper>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Id</th>
              <th>Imagem</th>
              <th className={`${styles.fullWidth} ${styles.left}`}>Nome</th>
              <th>Grande Marca</th>
              <th>Grupo Econômico</th>
              <th>Categoria</th>
              <th>CNPJ8</th>
              <th>Qtd. Empresas</th>
            </tr>
          </thead>
          <tbody>
            {!items ? (
              <tr>
                <td colSpan={7}>Carregando....</td>
              </tr>
            ) : items.length === 0 ? (
              <tr>
                <td colSpan={7}>Sem resultados</td>
              </tr>
            ) : (
              items.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Button
                      transparent
                      small
                      type="warning"
                      onClick={() => handleEditOnClick(item)}
                    >
                      Editar
                    </Button>{" "}
                    <Button
                      transparent
                      onClick={() => handleOnDeleteClick(item)}
                      disabled={Boolean(item.companyQtt)}
                      type="cancel"
                      small
                    >
                      Apagar
                    </Button>
                  </td>
                  <td>{item.id}</td>
                  <td>
                    <div className={brandStyles.imageWrapper}>
                      {item.file ? (
                        <img
                          src={mediaPrefix + item.file}
                          alt="Logo da Marca"
                        />
                      ) : (
                        <span className={brandStyles.imageMissing}>
                          Sem
                          <br />
                          imagem
                        </span>
                      )}
                    </div>
                  </td>
                  <td className={`${styles.fullWidth} ${styles.left}`}>
                    {item.name || "Não Informado"}
                  </td>
                  <td>{item.wideBrand ? "Sim" : "Não"}</td>
                  <td>{item.economicGroup || "Não Informado"}</td>
                  <td>{item.category?.name || "Não Informado"}</td>
                  <td>{item.cnpj8 || "Não Informado"}</td>
                  <td>{item.companyQtt}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </TableResponsiveWrapper>
      <Pagination
        page={page}
        totalPages={totalPages === null ? undefined : totalPages}
        onChangePage={handlePageOnChange}
      />
    </div>
  );
}
