
export function isEmptyString(value: string | null | undefined): boolean {

  if (!value) {
    return true;
  }

  return value.match(/^\s+$/) ? true : false;

}

export function fill(str: string | null | undefined, char: string, size: number) {

  if (!str) {
    return char.repeat(size);
  }

  if (str.length >= size) {
    return str;
  }

  return char.repeat(size - str.length) + str;

}

export function compareStrings(a: string | null | undefined, b: string | null | undefined) {

  if (a === b) {
    return 0;
  }

  if (a === null || a === undefined) {
    return -1;
  }

  if (b === null || b === undefined) {
    return 1;
  }

  return a.localeCompare(b);

}