import React from 'react'
import Template from '../components/Template'
import Input from '../components/Input'
import { Col, Row } from '../components/Grid'
import Card from '../components/Card'
import Table from '../components/Table'
import { Title, SubTitle } from '../components/Titles'
import Button from '../components/Button'

const breadcrump = [
  {
    name: "Início", path: "/"
  },
  {
    name: "Análise de Incidência", path: "/incidence"
  }
]

function IncidencePage() {
  return (
    <Template breadcrump={ breadcrump }>
      <Title>
        Análise de Incidência
      </Title>
      <SubTitle>
        Período de Análise
      </SubTitle>
      <Card>
        <Row>
          <Col>
            <Input type="date" label="Início" />
          </Col>
          <Col>
            <Input type="date" label="Fim" />
          </Col>
        </Row>
      </Card>
      <SubTitle>
        Universo
      </SubTitle>
      <Table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Sub-Tipo</th>
            <th className="bigger">Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4" style={{ textAlign: "right" }}>
              <Button layout="confirm">
                Adicionar
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <SubTitle>
        Interesses
      </SubTitle>
      <Table>
        <thead>
          <tr>
            <th>Tipo</th>
            <th>Sub-Tipo</th>
            <th className="bigger">Valor</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4" style={{ textAlign: "right" }}>
              <Button layout="confirm">
                Adicionar
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
      <div style={{ height: "15px" }} />
      <Button layout="confirm" large>
        Iniciar Análise
      </Button>
    </Template>
  )
}

export default IncidencePage