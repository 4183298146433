import useTagListPage from "../../service/tag/useTagManagementPage";
import Button from "../form/Button";
import Input from "../form/Input";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";

export default function TagManagementPage() {
  const {
    filter,
    items,
    page,
    totalPages,
    loading,
    loadingExcel,
    handleFindOnSubmit,
    handleOnChange,
    openTagCreatetModal,
    openTagEditModal,
    openTagDeleteModal,
    openRelationshipInformationModal,
    handlePageOnChange,
    onDownloadTagCsvClick,
  } = useTagListPage();

  return (
    <div>
      <SectionTitle
        title="Listagem de Tags"
        description="Consulta, edição e criação de Tags de produto."
      />

      <form onSubmit={handleFindOnSubmit}>
        <Input
          onChange={handleOnChange}
          value={filter}
          name="filter"
          placeholder="Pesquisar..."
          label="Filtre a tag pelo nome"
          addons={[
            <Button key="1" type="info" submit>
              Pesquisar
            </Button>,
          ]}
        />
      </form>
              
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button onClick={() => openTagCreatetModal()} type="confirm">
            Criar Tag
          </Button>
        </div>
        <div>
          <Button type="confirm" onClick={ onDownloadTagCsvClick } disabled={ loadingExcel }>
            { loadingExcel ? "Baixando..." : "Baixar Excel" }
          </Button>
        </div>
      </div>

      <Spacer ratio={2 / 3} />
      {loading ? (
        <tr>
          <td colSpan={7}>Carregando....</td>
        </tr>
      ) : !items || items.length === 0 ? (
        <tr>
          <td colSpan={7}>Sem resultados</td>
        </tr>
      ) : (
        <>
          <TableResponsiveWrapper>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Ações</th>
                  <th>Id</th>
                  <th className={`${styles.fullWidth} ${styles.left}`}>
                    Descrição
                  </th>
                  <th>Tipo</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item) => (
                  <tr key={item.id}>
                    <td>
                      <Button
                        small
                        type="info"
                        onClick={() =>
                          openRelationshipInformationModal(item, "PRODUCT")
                        }
                      >
                        Relacionar Produtos
                      </Button>{" "}
                      <Button
                        small
                        type="info"
                        onClick={() =>
                          openRelationshipInformationModal(item, "SEGMENT")
                        }
                      >
                        Relacionar Segmentos
                      </Button>{" "}
                      <Button
                        small
                        type="warning"
                        onClick={() => openTagEditModal(item)}
                      >
                        Editar
                      </Button>{" "}
                      <Button
                        small
                        type="cancel"
                        onClick={() => openTagDeleteModal(item.id)}
                      >
                        Remover
                      </Button>{" "}
                    </td>
                    <td>{item.id}</td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {item.description || "Não Informado"}
                    </td>
                    <td>{item.type || "Não Informado"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </TableResponsiveWrapper>
          <Pagination
            page={page}
            totalPages={totalPages === null ? undefined : totalPages}
            onChangePage={handlePageOnChange}
          />
        </>
      )}
    </div>
  );
}
