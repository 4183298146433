import { useState } from "react";
import { validateNumericSize } from "../../../../../../utils";
import Input from "../../../../../../components/Input"
import Button from "../../../../../../components/Button";


export default function ProductQueueModeration(props) {
  const { selectedItems, handleSendItems } = props;
  const [ priority, setPriority ] = useState(0);
  const [ sendingItems, setSendingItems ] = useState(false);

  return (
    <div>
      {
        !selectedItems ? (
          <div>
            <p style={{ "color": "red", "textAlign": "center" }}><strong>ATENÇÃO!!!</strong></p>
            <p style={{ "color": "red", "textAlign": "center" }}>NENHUM ITEM SELECIONADO</p>
          </div>
        ) : (
          <div>
            <p style={{ "textAlign": "center" }}>{ selectedItems } item(s) selecionado(s)</p>
            <Input
              label="Defina o nível de prioridade"
              type="number"
              value={ priority }
              onChange={ e => setPriority(validateNumericSize(e.target.value, 1, 2)) }
            />
          </div>
        )
      }
      <Button
        layout="confirm"
        onClick={ () => {
          setSendingItems(true);
          handleSendItems(priority)
        } }
        disabled={ !selectedItems || sendingItems || !priority || isNaN(priority) || priority < 0 }
      >
        { sendingItems ? "Carregando... este processo pode demorar alguns minutos" : "Priorizar" }
      </Button>
    </div>
  )
}