import { FormEvent, ReactNode, useEffect, useRef, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { http } from "../../../api/http";
import { logout } from "../../../authentication/logout";
import { baseUrl } from "../../../constants";
import {
  GlobalContextProps,
  useGlobalContext,
} from "../../service/context/globalContext";
import { useLocalStorage } from "../../service/useLocalStorage";
import styles from "./ui.module.css";

export type Props = {
  children: ReactNode;
};

export default function Platform(props: Props) {
  const { children } = props;

  const filterInputRef = useRef<HTMLInputElement>(null);

  const { menus, setMenus } = useGlobalContext();

  const history = useHistory();

  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchMenusEffect(setMenus);
  }, []);

  const toggleOpen = () => setOpen((prev) => !prev);

  const [open, setOpen] = useLocalStorage<boolean>("menuOpen", true);

  const onFilterFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (!menus) {
      return;
    }
    const filtered = menus
      .flatMap((menu) => menu.subMenus)
      .filter((menu) =>
        menu.name.toLowerCase().includes(filter.toLocaleLowerCase())
      );
    if (!filtered.length) {
      return;
    }
    setFilter("");
    history.push(filtered[0].url);
  };

  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.ctrlKey && event.key === "m") {
        filterInputRef.current?.focus();
      }
    }
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [filterInputRef]);

  let currentOption = 0;

  return (
    <div
      className={`${styles.platform} ${open ? styles.platformMenuOpen : ""}`}
    >
      <div className={styles.platformHeader}>
        <div className={styles.platformHeaderLeft}>
          <div className={styles.platformMenuIcon} onClick={toggleOpen}>
            <i className="fa fa-bars" />
          </div>
          <div
            className={styles.platformLogo}
            onClick={() => history.push("/dashboard")}
          >
            <img src="/static/logo-horus-w.png" alt="Logomarca Horus" />
          </div>
        </div>
        <div className={styles.platformHeaderRight}>
          <button type="button" onClick={() => logout()}>
            Sair
          </button>
        </div>
      </div>
      <div className={styles.platformLeftMenu}>
        <form
          className={styles.platformLeftMenuFilterWrapper}
          onSubmit={onFilterFormSubmit}
        >
          <input
            className={styles.platformLeftMenuFilter}
            type="text"
            placeholder="Pesquise... [Ctrl + M]"
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            ref={filterInputRef}
          />
        </form>
        {!menus
          ? "Carregando..."
          : menus
              .filter((menu) =>
                menu.subMenus.find((submenu) =>
                  submenu.name.toLowerCase().includes(filter.toLowerCase())
                )
              )
              .map((menu) => (
                <div className={styles.menuSection} key={menu.url}>
                  <div className={styles.menuTitle}>{menu.name}</div>
                  {menu.subMenus
                    .filter((subMenu) =>
                      subMenu.name.toLowerCase().includes(filter.toLowerCase())
                    )
                    .map((subMenu) => (
                      <NavLink
                        key={subMenu.url}
                        onClick={() => setFilter("")}
                        to={subMenu.url}
                        className={`${styles.subMenuTitle} ${
                          filter && ++currentOption === 1
                            ? styles.subMenuTitleFocused
                            : null
                        }`}
                        activeClassName={styles.activeSubMenuTitle}
                      >
                        {subMenu.name}
                      </NavLink>
                    ))}
                </div>
              ))}
      </div>
      <div className={styles.platformBody}>{children}</div>
    </div>
  );
}

async function fetchMenusEffect(setMenus: GlobalContextProps["setMenus"]) {
  const response = await http.get(baseUrl + "/api/v1/user/menus");
  setMenus(response?.data);
}
