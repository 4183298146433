import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { mediaPrefix } from "../../../constants";
import useModal from "../../../hooks/useModal";
import useProductBrand from "../../../hooks/useProductBrand";
import useProductManufacturer from "../../../hooks/useProductManufacturer";
import { makeValidator } from "../../../utils";
import AsyncSelect2 from "../../AsyncSelect2";
import Button from "../../Button";
import { Col, Row } from "../../Grid";
import Input from "../../Input";

const leftCol = "70%";
const rightCol = "30%";
const fullCol = "100%";

const DropZone = styled.div`
  border: solid 1px black;
  height: 226px;
  margin-top: 16px;
  position: relative;
`;

const UploadButtonWrapper = styled.div`
  text-align: center;
  padding: 5px;
`;

const validateDescription = (c) => (!c ? ["Descrição é obrigatória"] : null);
const validateManufacturer = (c) => (!c ? ["Descrição é obrigatória"] : null);
const validateEconomicGroup = (c) => (!c ? ["Descrição é obrigatória"] : null);
const validateManufacturerId = (c) =>
  !c ? ["Fabricante é obrigatório"] : null;

const brandValidator = makeValidator({
  description: validateDescription,
  manufacturerId: validateManufacturerId,
  manufacturer: validateManufacturer,
  economicGroup: validateEconomicGroup,
});

function SaveProductBrandForm(props) {
  const { brandComplete, onSavedBrand } = props;

  const {
    getManufacturerIdErrors,
    getDescriptionErrors,
    getManufacturerErrors,
    getEconomicGroupErrors,
  } = brandValidator;

  // # STATE
  const [
    {
      brand,
      imageSrc,
      savingBrand,
      economicGroups,
      newBrand,
      newManufacturer,
      editManufacturer,
    },
    setState,
  ] = useState({
    brand: { ownBrand: false },
    imageSrc: mediaPrefix + "/static/placeholder.png",
    savingBrand: false,
    economicGroups: null,
    newBrand: brandComplete?.id ? false : true,
    newManufacturer: false,
    editManufacturer: false,
  });

  // # REFS
  const fileInput = useRef();

  // # OTHER HOOKS
  const { toggleModal, createModal } = useModal();
  const { saveBrand, removeProductBrand, existsBrand, fetchEconomicGroups } =
    useProductBrand();
  const { fetchManufacturers, existsManufacturer } = useProductManufacturer();

  // # HELPERS
  const updateBrand = (patch) =>
    setState((prev) => ({ ...prev, brand: { ...prev.brand, ...patch } }));
  const setBrandFormType = () => {
    if (newBrand) {
      updateBrand({ ...brandComplete });
      if (brandComplete.image)
        setState((prev) => ({
          ...prev,
          imageSrc: mediaPrefix + brandComplete.image,
        }));
    } else {
      setState((prev) => ({
        ...prev,
        brand: {},
        imageSrc: mediaPrefix + "/static/placeholder.png",
        editManufacturer: false,
        newManufacturer: false,
      }));
    }
    setState((prev) => ({ ...prev, newBrand: !newBrand }));
  };
  const toggleOwnBrand = () => {
    brand.ownBrand
      ? updateBrand({ ownBrand: !brand.ownBrand, economicGroup: null })
      : updateBrand({
          ownBrand: !brand.ownBrand,
          economicGroup: brandComplete?.economicGroup,
        });
  };

  // # FUNCTIONS
  const onUploadFileClick = () => {
    fileInput.current.click();
  };

  const onDrop = (e) => {
    e.preventDefault();
    fileInput.current.files = e.dataTransfer.files;
    setPreviewImage(e.dataTransfer.files[0]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onUploadFileSelected = () => {
    const file = fileInput.current.files[0];
    setPreviewImage(file);
  };

  const setPreviewImage = (file) => {
    if (file.size > 120000) {
      alert("Este arquivo excede o limite de 128KB!");
      return;
    }

    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      setState((prev) => ({ ...prev, imageSrc: e.target.result }));
      updateBrand({ newBrandImg: file });
    };

    reader.readAsDataURL(file);
  };

  const setEconomicGroups = async function () {
    if (economicGroups) return;
    const ecoGroups = await fetchEconomicGroups();
    setState((prev) => ({ ...prev, economicGroups: ecoGroups }));
  };

  const onSubmitClick = async (e) => {
    setState((prev) => ({ ...prev, savingBrand: true }));
    try {
      const data = {
        ...brand,
        changeManufacturer:
          brandComplete?.manufacturerId !== brand.manufacturerId,
      };
      if (fileInput.current.files.length) {
        data.file = fileInput.current.files[0];
      }

      const brandExistsResponse = await existsBrand(data);
      if (brandExistsResponse.data) {
        createModal("Marca já existe");
        return;
      }

      const manufacturerExistsResponse = await existsManufacturer(data);
      if (manufacturerExistsResponse.data) {
        createModal("Fabricante já existe");
        return;
      }

      const response = await saveBrand(data);
      createModal("Marca salva com sucesso", { replace: true });

      if (onSavedBrand) onSavedBrand(response.data);
    } catch (err) {
      createModal("Erro ao salvar marca");
    }
    setState((prev) => ({ ...prev, savingBrand: false }));
  };

  const onSubmitBrandToBeRemoved = async (brandId) => {
    setState((prev) => ({ ...prev, savingBrand: true }));
    await removeProductBrand(brandId);
    setState((prev) => ({ ...prev, savingBrand: false }));
  };

  useEffect(() => {
    if (!brandComplete) return;
    updateBrand({ ...brandComplete });
    if (brandComplete.image)
      setState((prev) => ({
        ...prev,
        imageSrc: mediaPrefix + brandComplete.image,
      }));
  }, [brandComplete]);

  return (
    <>
      <Row>
        {newBrand || !brandComplete ? (
          <>
            <div className="h-separator" style={{ height: "40px" }}>
              Cadastrar Nova Marca
              <span style={{ float: "right" }}>
                <Button
                  disabled={!brandComplete ? true : false}
                  onClick={setBrandFormType}
                >
                  Editar Marca
                </Button>
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="h-separator" style={{ height: "40px" }}>
              Editar Marca Selecionada
              <div style={{ float: "right" }}>
                <Button onClick={setBrandFormType}>Criar Nova Marca</Button>
              </div>
            </div>
          </>
        )}
      </Row>
      <Row>
        <Col width={leftCol}>
          <p>
            {!brand.manufacturerId || newManufacturer ? null : (
              <label className="h-checkbox">
                <input
                  type="checkbox"
                  checked={editManufacturer}
                  onChange={() => {
                    if (!editManufacturer)
                      setState((prev) => ({ ...prev, newManufacturer: false }));
                    setState((prev) => ({
                      ...prev,
                      editManufacturer: !editManufacturer,
                    }));
                  }}
                />
                Editar fabricante
              </label>
            )}
          </p>
          {newManufacturer || editManufacturer ? null : (
            <AsyncSelect2
              label="Fabricante*"
              value={brand.manufacturerId}
              valueName={brand.manufacturer}
              onChange={(option) =>
                updateBrand({
                  manufacturerId: option ? option.id : null,
                  manufacturer: option ? option.name : null,
                })
              }
              fetchData={fetchManufacturers}
              validate={getManufacturerIdErrors}
            />
          )}
          <p>
            {editManufacturer ? null : (
              <label className="h-checkbox">
                <input
                  type="checkbox"
                  checked={newManufacturer}
                  onChange={() => {
                    if (newManufacturer) {
                      updateBrand({
                        manufacturer: brandComplete?.manufacturer,
                        manufacturerId: brandComplete?.manufacturerId,
                      });
                    } else {
                      updateBrand({ manufacturer: null, manufacturerId: null });
                      setState((prev) => ({
                        ...prev,
                        editManufacturer: false,
                      }));
                    }
                    setState((prev) => ({
                      ...prev,
                      newManufacturer: !newManufacturer,
                    }));
                  }}
                />
                Novo fabricante
              </label>
            )}
          </p>
          {!(newManufacturer || editManufacturer) ? null : (
            <Input
              label="Fabricante*"
              placeholder="Nome"
              value={brand.manufacturer}
              onChange={(e) => updateBrand({ manufacturer: e.target.value })}
              validate={getManufacturerErrors}
            />
          )}
          <Input
            label="Marca*"
            placeholder="Nome"
            value={brand.name}
            onChange={(e) => updateBrand({ name: e.target.value })}
            validate={getDescriptionErrors}
          />
          <p>
            <label className="h-checkbox">
              <input
                type="checkbox"
                checked={brand.ownBrand}
                onChange={toggleOwnBrand}
              />
              Marca própria
            </label>
          </p>
          {!brand.ownBrand ? null : (
            <>
              <Input
                list="Redes"
                label="Rede*"
                placeholder="Rede"
                value={brand.economicGroup}
                onChange={(e) => updateBrand({ economicGroup: e.target.value })}
                onFocus={setEconomicGroups}
                validate={getEconomicGroupErrors}
              />
              <datalist id="Redes">
                {(economicGroups || []).map((e) => (
                  <option key={e}>{e}</option>
                ))}
              </datalist>
            </>
          )}
          <input
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={onUploadFileSelected}
          />
        </Col>
        <Col width={rightCol}>
          <DropZone onDrop={onDrop} onDragOver={onDragOver}>
            <img
              className="h-brandImage"
              style={{ height: "100%", width: "100%" }}
              src={imageSrc}
              alt="/static/placeholder.png"
            />
          </DropZone>
          <UploadButtonWrapper>
            <Button layout="info" small onClick={onUploadFileClick}>
              Upload
            </Button>
          </UploadButtonWrapper>
        </Col>
        <Col width={fullCol}>
          <Button
            layout="confirm"
            onClick={onSubmitClick}
            disabled={
              savingBrand ||
              !brand.manufacturer ||
              !brand.name ||
              (brand.ownBrand && !brand.economicGroup)
            }
          >
            {savingBrand ? "Enviando..." : "Enviar"}
          </Button>
          &nbsp;
          <Button onClick={() => toggleModal(false)}>Cancelar</Button>
          {!brand?.id ? null : (
            <>
              &nbsp;
              <Button
                layout="error"
                onClick={() => onSubmitBrandToBeRemoved(brand.id)}
                disabled={savingBrand}
              >
                Excluir
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default SaveProductBrandForm;
