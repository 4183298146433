export function getErrorMessage(e: any) {
  console.log(e);

  const message: string = e.response?.data?.message || e.message || "Um erro interno ocorreu";
  const context: string = e.response?.data?.context;

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Alerta</h3>
      <div></div>
      {message.split("\n").map((i, index) => (
        <div key={index}>{i}</div>
      ))}
      {!context ? null : (
        <div style={{ marginTop: "1.6em", fontSize: ".7em", color: "gray" }}>
          Envie este código ao suporte: {context}
        </div>
      )}
    </div>
  );
}
