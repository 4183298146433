import { http } from "../../../../api/http";
import { baseUrl } from "../../../../constants";

export type GroupInformationView = {
  id: number;
  name: string;
  users: Array<{
    id: number;
    username: string;
    clientId?: number;
    clientName?: string;
  }>;
};

export type GroupSearchForm = {
  filter?: string;
  page?: number;
};

export type GroupSearchItemView = {
  id: number;
  name: string;
  users: number;
};

export type GroupSearchView = {
  items: Array<GroupSearchItemView>;
  total: number;
  page: number;
};

export type GroupSaveForm = {
  id?: number;
  name: string;
};

export type GroupSaveView = { id: number };

export interface Group {
  id: string;
  name: string;
}

export type UseGroupRequests = typeof useGroupRequests;
export type FindById = ReturnType<UseGroupRequests>["findById"];
export type Search = ReturnType<UseGroupRequests>["search"];
export type GetGroupInformation =
  ReturnType<UseGroupRequests>["getGroupInformation"];
export type Save = ReturnType<UseGroupRequests>["save"];
export type DeleteGroup = ReturnType<UseGroupRequests>["deleteGroup"];
export type AttachUserToGroup =
  ReturnType<UseGroupRequests>["attachUserToGroup"];
export type DetachUserFromGroup =
  ReturnType<UseGroupRequests>["detachUserFromGroup"];

export default function useGroupRequests() {
  const findById = (id: number) => baseFindById(id);

  const getGroupInformation = (id: number) => baseGetGroupInformation(id);

  const search = (params: GroupSearchForm) => baseSearch(params);

  const save = (params: GroupSaveForm) => saveGroup(params);

  const deleteGroup = (id: number) => baseDeleteGroup(id);

  const attachUserToGroup = (params: { groupId: number; userId: number }) =>
    baseAttachUserToGroup(params);

  const detachUserFromGroup = (params: { groupId: number; userId: number }) =>
    baseDetachUserToGroup(params);

  return {
    findById,
    getGroupInformation,
    search,
    save,
    deleteGroup,
    attachUserToGroup,
    detachUserFromGroup,
  };
}

async function baseFindById(id: number): Promise<Group> {
  const response = await http.get(`${baseUrl}/api/v1/group/${id}`);
  return response.data as Group;
}

async function baseGetGroupInformation(
  id: number
): Promise<GroupInformationView> {
  const response = await http.get(`${baseUrl}/api/v1/group/${id}/report`);
  return response.data as GroupInformationView;
}

async function baseSearch(params?: GroupSearchForm): Promise<GroupSearchView> {
  const response = await http.get(`${baseUrl}/api/v1/group/search`, { params });
  return response?.data;
}

async function saveGroup(params: GroupSaveForm): Promise<GroupSaveView> {
  const response = await http.post(`${baseUrl}/api/v1/group`, params);
  return response?.data;
}

async function baseDeleteGroup(id: number): Promise<void> {
  const response = await http.delete(`${baseUrl}/api/v1/group/${id}`);
  return response?.data;
}

async function baseAttachUserToGroup(params: {
  groupId: number;
  userId: number;
}): Promise<void> {
  await http.put(
    `${baseUrl}/api/v1/group/${params.groupId}/attach/${params.userId}`
  );
}

async function baseDetachUserToGroup(params: {
  groupId: number;
  userId: number;
}): Promise<void> {
  await http.put(
    `${baseUrl}/api/v1/group/${params.groupId}/detach/${params.userId}`
  );
}
