import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
export interface Tag {
  id: number;
  description: string;
  type: string;
}

export type TagSaveForm = {
  id: number | undefined;
  description: string;
  type: string;
};

export type TagSearchForm = {
  filter?: string;
  page?: number;
  pageSize?: number;
};

export interface TagRelationshipSearchForm extends TagSearchForm {
  tagId: number;
  type: "PRODUCT" | "SEGMENT";
}

export type TagSearchView = {
  items: Array<Tag>;
  total: number;
  page: number;
};

export type TagRelationshipSearchView = {
  items: Array<{ value: number; description: string }>;
  total: number;
  page: number;
};

export type UseTagRequests = typeof useTagRequests;
export type SearchTag = ReturnType<UseTagRequests>["searchTag"];
export type SaveTag = ReturnType<UseTagRequests>["saveTag"];
export type DeleteTag = ReturnType<UseTagRequests>["deleteTag"];
export type GetRelationshipInformation =
  ReturnType<UseTagRequests>["getRelationshipInformation"];
export type AttachProductsToTag =
  ReturnType<UseTagRequests>["attachProductsToTag"];
export type DetachProductFromTag =
  ReturnType<UseTagRequests>["detachProductFromTag"];
export type AttachSegmentsToTag =
  ReturnType<UseTagRequests>["attachSegmentsToTag"];
export type DetachSegmentFromTag =
  ReturnType<UseTagRequests>["detachSegmentFromTag"];
export type DownloadTagCsv = ReturnType<UseTagRequests>["downloadTagCsv"];
export type DownloadTagRelationshipCsv =
  ReturnType<UseTagRequests>["downloadTagRelationshipCsv"];

export default function useTagRequests() {
  const searchTag = (params: TagSearchForm) => baseSearchTag(params);

  const saveTag = (tag: TagSaveForm) => baseSaveTag(tag);

  const deleteTag = (id: number) => baseDeleteTag(id);

  const getRelationshipInformation = (params: TagRelationshipSearchForm) =>
    baseGetRelationshipInformation(params);

  const attachProductsToTag = (params: {
    tagId: number;
    productIds: string[];
  }) => baseAttachProductsToTag(params);

  const detachProductFromTag = (params: { tagId: number; productId: number }) =>
    baseDetachProductFromTag(params);

  const attachSegmentsToTag = (params: {
    tagId: number;
    segmentIds: string[];
  }) => baseAttachSegmentsToTag(params);

  const detachSegmentFromTag = (params: { tagId: number; segmentId: number }) =>
    baseDetachSegmentFromTag(params);

  const downloadTagCsv = (filter: string) => baseDownloadTagCsv(filter);

  const downloadTagRelationshipCsv = (params: {
    tagId: number;
    type: string;
  }) => baseDownloadTagRelationshipCsv(params);

  return {
    searchTag,
    saveTag,
    deleteTag,
    getRelationshipInformation,
    attachProductsToTag,
    detachProductFromTag,
    attachSegmentsToTag,
    detachSegmentFromTag,
    downloadTagCsv,
    downloadTagRelationshipCsv,
  };
}

async function baseSearchTag(params?: TagSearchForm): Promise<TagSearchView> {
  const response = await http.get(`${baseUrl}/api/v1/tag/search`, { params });
  return response.data;
}

async function baseSaveTag(tag: TagSaveForm): Promise<void> {
  await http.post(`${baseUrl}/api/v1/tag/`, tag);
}

async function baseDeleteTag(id: number): Promise<void> {
  await http.delete(`${baseUrl}/api/v1/tag/${id}`);
}

async function baseGetRelationshipInformation(
  params: TagRelationshipSearchForm
): Promise<TagRelationshipSearchView> {
  if ("PRODUCT" === params.type) {
    const response = await http.get(`${baseUrl}/api/v1/tag/products`, {
      params,
    });
    return response.data;
  }

  const response = await http.get(`${baseUrl}/api/v1/tag/segments`, { params });
  return response.data;
}

async function baseAttachProductsToTag(params: {
  tagId: number;
  productIds: string[];
}): Promise<string[]> {
  const response = await http.post(
    `${baseUrl}/api/v1/tag/${params.tagId}/products`,
    params.productIds
  );
  return response.data;
}

async function baseDetachProductFromTag(params: {
  tagId: number;
  productId: number;
}): Promise<void> {
  await http.delete(
    `${baseUrl}/api/v1/tag/${params.tagId}/product/${params.productId}`
  );
}

async function baseAttachSegmentsToTag(params: {
  tagId: number;
  segmentIds: string[];
}): Promise<string[]> {
  const response = await http.post(
    `${baseUrl}/api/v1/tag/${params.tagId}/segments`,
    params.segmentIds
  );
  return response.data;
}

async function baseDetachSegmentFromTag(params: {
  tagId: number;
  segmentId: number;
}): Promise<void> {
  await http.delete(
    `${baseUrl}/api/v1/tag/${params.tagId}/segment/${params.segmentId}`
  );
}

async function baseDownloadTagCsv(filter: string): Promise<string> {
  const response = await http.post(`${baseUrl}/api/v1/tag/download/tag.csv`, {
    description: filter,
  });
  return response.data;
}

async function baseDownloadTagRelationshipCsv(params: {
  tagId: number;
  type: string;
}): Promise<string> {
  const response = await http.post(
    `${baseUrl}/api/v1/tag/download/tag-relationship.csv`,
    params
  );
  return response.data;
}
