import { useCallback } from "react";
import { http } from "../api/http";
import { baseUrl } from "../constants";
import useModal from "./useModal";

export default function useProductCategory() {
  const { createModal } = useModal();

  const fetchProductCategories = useCallback(async function (
    search,
    offset,
    ncm
  ) {
    try {
      const { data: categories } = await http.get(
        baseUrl + `/api/v1/products/product-categories`,
        {
          params: { search, offset, ncm, limit: 10 },
        }
      );
      return {
        options: categories.map((c) => ({
          id: c.id,
          name: c.name,
          um: c.unitOfMeasurement,
          median: c.median,
          reportId: c.reportId,
          nielsen: c.nielsenCategory,
          tagCes: c.tagCes,
          tagSeason: c.tagSeason,
          tagPackage: c.tagPackage,
          tagEspecificity: c.tagEspecificity,
          tagLinearMeasure: c.tagLinearMeasure,
          tagLine: c.tagLine,
          type: c.type,
          parentType: c.parentType,
          key: c.id,
        })),
        hasMore: categories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  },
  []);

  const fetchCategories = useCallback(async (search, offset, ncm) => {
    try {
      const { data: categories } = await http.get(
        baseUrl + `/api/v1/products/categories`,
        {
          params: { search, offset, ncm, limit: 10 },
        }
      );
      return {
        options: categories.map((c) => ({
          id: c.id,
          name: c.name,
          unitOfMeasurement: c.unitOfMeasurement,
          median: c.median,
          parentId: c.parentId,
          parentCategory: c.parentCategory,
          nielsen: c.nielsenCategory,
          reportId: c.reportId,
          nielsenCategory: c.nielsenCategory,
          tagCes: c.tagCes,
          tagSeason: c.tagSeason,
          tagPackage: c.tagPackage,
          tagEspecificity: c.tagEspecificity,
          tagLinearMeasure: c.tagLinearMeasure,
          tagLine: c.tagLine,
          type: c.type,
          parentType: c.parentType,
          key: c.id,
        })),
        hasMore: categories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const fetchParentCategories = useCallback(async (search, offset) => {
    try {
      const { data: parentCategories } = await http.get(
        baseUrl + `/api/v1/products/parent-categories`,
        {
          params: { search, offset, limit: 10 },
        }
      );
      return {
        options: parentCategories.map((p) => ({
          id: p.id,
          name: p.name,
          parentType: p.parentType,
          key: p.id,
        })),
        hasMore: parentCategories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias Pai.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const fetchLvl1ParentCategories = useCallback(async (search, offset) => {
    try {
      const { data: parentCategories } = await http.get(
        baseUrl + `/api/v1/products/lvl1-parent-categories`,
        { params: { search, offset, limit: 10 } }
      );
      return {
        options: parentCategories.map((p) => ({
          id: p.id,
          name: p.name,
          key: p.id,
        })),
        hasMore: parentCategories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias nível 1.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const fetchNielsenCategories = useCallback(async (search, offset) => {
    try {
      const { data: cCategories } = await http.get(
        baseUrl + `/api/v1/products/child-categories-search`,
        { params: { search, offset, limit: 10 } }
      );
      return {
        options: cCategories.map((c) => ({
          id: c.id,
          name: c.name,
          key: c.id,
        })),
        hasMore: cCategories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias Nielsen.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const fetchParentNielsenCategories = useCallback(async (search, offset) => {
    try {
      const { data: parentCategories } = await http.get(
        baseUrl + `/api/v1/products/parent-nielsen-categories-search`,
        { params: { search, offset, limit: 10 } }
      );
      return {
        options: parentCategories.map((p) => ({
          id: p.id,
          name: p.name,
          key: p.id,
        })),
        hasMore: parentCategories.length === 10,
      };
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar buscar Categorias Nielsen Mãe.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const fetchProdCatAttachedToNielsen = useCallback(async (nielsenId) => {
    try {
      const { data: nielsenCategories } = await http.get(
        baseUrl + `/api/v1/products/nielsen-categories/${nielsenId}`
      );
      return nielsenCategories;
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>
            Ocorreu um erro ao tentar buscar Categorias de produtos atrelados à
            Categoria Nielsen.
          </p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, []);

  const saveCategory = useCallback(
    async (category, editNielsenCategory, editTag) => {
      try {
        await http.post(`${baseUrl}/api/v1/products/category`, category, {
          params: { editNielsenCategory, editTag },
        });
        createModal(
          <div className="align-center">
            <p>Solicitação Realizada com Sucesso.</p>
            <br />
            <br />
          </div>,
          { replace: true }
        );
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar Categorias Nielsen.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    []
  );

  const saveNielsenCategory = useCallback(
    async (
      category,
      editNielsenCategory,
      createNielsenCategory,
      editMotherCategory,
      createMotherCategory
    ) => {
      try {
        await http.post(
          `${baseUrl}/api/v1/products/nielsen-category`,
          category,
          {
            params: {
              editNielsenCategory,
              createNielsenCategory,
              editMother: editMotherCategory,
              createMother: createMotherCategory,
            },
          }
        );
        createModal(
          <div className="align-center">
            <p>Solicitação Realizada com Sucesso.</p>
            <br />
            <br />
          </div>,
          { replace: true }
        );
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar Categorias Nielsen.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    []
  );

  return {
    fetchProductCategories,
    fetchCategories,
    fetchParentCategories,
    fetchLvl1ParentCategories,
    fetchNielsenCategories,
    fetchParentNielsenCategories,
    fetchProdCatAttachedToNielsen,
    saveCategory,
    saveNielsenCategory,
  };
}
