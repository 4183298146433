import styles from './Form.module.css';

interface Props {
  messages: string[]
}

function ValidationMessages(props: Props) {

  return (
    <div className={ styles.validationMessageGroup }>
      {
        props.messages.map((m, index) => (
          <div key={ index } className={ styles.validationMessage }>
            { m }
          </div>
        ))
      }
    </div>
  )

}

export default ValidationMessages;