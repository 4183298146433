import { Route, Redirect } from "react-router-dom";
import { getCredentials } from "../utils/authCredentials/getCredentials";

function RedirectLoggedRoute(props) {
  const { children, ...otherProps } = props;

  let hasCredentials = false;

  if (getCredentials.accessToken() && getCredentials.authUser()) {
    hasCredentials = true;
  }

  return (
    <Route {...otherProps}>
      {!hasCredentials ? children : <Redirect to="/dashboard" />}
    </Route>
  );
}

export default RedirectLoggedRoute;
