import { useCallback, useEffect, useReducer, useRef } from "react";
import useSetTimeout from "../hooks/useSetTimeout";

function reducer(prev, action) {
  if (action.type === "SET_OPEN") {
    const { open } = action;
    return { ...prev, open };
  }
  if (action.type === "TYPING_SEARCH") {
    const { search } = action;
    return { ...prev, search, offset: 0 };
  }
  if (action.type === "FETCHING_DATA") {
    return { ...prev, loading: true };
  }
  if (action.type === "FETCHED_DATA") {
    const { options, hasMore, offsetPlus } = action;

    return {
      ...prev,
      loading: false,
      error: false,
      hasMore,
      options,
      offset: prev.offset + offsetPlus,
    };
  }
  if (action.type === "FETCHED_MORE_DATA") {
    const { options, hasMore, offsetPlus } = action;

    return {
      ...prev,
      loading: false,
      error: false,
      hasMore,
      options: [...prev.options, ...options],
      offset: prev.offset + offsetPlus,
    };
  }
  if (action.type === "FETCH_DATA_ERR") {
    return { ...prev, loading: false, error: true };
  }
  if (action.type === "OPTION_SELECTED") {
    return { ...prev, open: false, search: "" };
  }
  return prev;
}

const NOT_FOUND = "Não encontrado";

function AsyncSelect(props) {
  const {
    value = null,
    valueName,
    fetchData = async () => [],
    filterData = [],
    onChange = () => {},
    placeholder = "Selecione...",
    required = false,
    label = "",
  } = props;

  const [state, dispatch] = useReducer(reducer, {
    search: "",
    options: [],
    loading: false,
    hasMore: true,
    open: false,
    offset: 0,
    offsetPlus: 0,
  });

  const { open } = state;

  const setSearchTimeout = useSetTimeout();
  const fetchDataRef = useRef();
  const wrapperRef = useRef();
  const optionsRef = useRef();

  useEffect(() => {
    fetchDataRef.current = fetchData;
  }, [fetchDataRef, fetchData]);

  useEffect(() => {
    if (open) {
      const close = () => {
        dispatch({ type: "SET_OPEN", open: false });
      };
      const handleClick = (e) => {
        if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
          close();
        }
      };
      const handleEsc = (e) => {
        if (e.key === "Escape") {
          close();
        }
      };

      document.addEventListener("click", handleClick);
      document.addEventListener("keydown", handleEsc);

      return () => {
        document.removeEventListener("click", handleClick);
        document.removeEventListener("keydown", handleEsc);
      };
    }
  }, [dispatch, open]);

  const doSearch = useCallback(
    function (search = "", wait = 1000) {
      dispatch({ type: "TYPING_SEARCH", search });

      setSearchTimeout(async function () {
        dispatch({ type: "FETCHING_DATA" });

        try {
          const { options, hasMore } = await fetchDataRef.current(search, 0);

          let filteredData = options;

          if (filterData) {
            filteredData = options.filter(
              (o) =>
                !(
                  filterData.map((f) => f.id).includes(o.id.toString()) ||
                  filterData.map((f) => f.id).includes(o.name)
                )
            );
          }

          dispatch({
            type: "FETCHED_DATA",
            options: filteredData,
            hasMore,
            offsetPlus: (options || []).length - (filterData || []).length,
          });
        } catch (e) {
          console.log(e);
        }
      }, wait);
    },
    [fetchDataRef, dispatch, setSearchTimeout]
  );

  async function continueSearch() {
    dispatch({ type: "FETCHING_DATA" });

    try {
      const { options, hasMore } = await fetchData(state.search, state.offset);

      let filteredData = options;

      if (filterData) {
        filteredData = options.filter(
          (o) =>
            !(
              filterData.map((f) => f.id).includes(o.id.toString()) ||
              filterData.map((f) => f.id).includes(o.name)
            )
        );
      }

      dispatch({
        type: "FETCHED_MORE_DATA",
        options: filteredData,
        hasMore,
        offsetPlus: (options || []).length - (filterData || []).length,
      });
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    if (state.open) {
      optionsRef.current.scrollTop = 0;
      doSearch();
    }
  }, [state.open, optionsRef, doSearch]);

  function selectOption(option) {
    dispatch({ type: "OPTION_SELECTED" });
    onChange(option);
  }

  return (
    <div className="h-as-wrapper" ref={wrapperRef}>
      <div className="h-as-label">{label}</div>
      <div
        className="h-as-select-box"
        onClick={() => {
          dispatch({ type: "SET_OPEN", open: !state.open });
          props.onFocus?.();
        }}
      >
        {valueName ? valueName : value ? NOT_FOUND : placeholder}
      </div>
      <div
        className={`h-as-select-options ${
          state.open ? "h-as-select-options-open" : ""
        }`}
      >
        <input
          className="h-as-search-input"
          value={state.search}
          placeholder="Pesquise..."
          onChange={(e) => doSearch(e.target.value)}
        />
        <div className="h-as-select-option-list" ref={optionsRef}>
          {required ? null : (
            <div
              className={`h-as-option ${
                value === null ? "h-as-option-selected" : ""
              }`}
              onClick={() => selectOption(null)}
            >
              {placeholder}
            </div>
          )}
          {state.options?.map((option) => (
            <div
              className={`h-as-option ${
                value === option.id ? "h-as-option-selected" : ""
              }`}
              key={option.id}
              data-id={option.id}
              onClick={() => selectOption(option)}
            >
              {option.name}
            </div>
          ))}
          {!state.loading && !state.hasMore ? null : (
            <div
              className="h-as-fetch-more"
              onClick={state.loading ? undefined : continueSearch}
            >
              {state.loading ? "Carregando..." : "Buscar mais"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AsyncSelect;
