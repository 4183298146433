import { ReactNode } from "react";
import styles from "./Form.module.css";

export interface Props {
  children?: ReactNode,
  disabled?: boolean,
  type?: keyof typeof classMap,
  onClick?: () => void,
  className?: string,
  small?: boolean,
  submit?: boolean,
  addInputLabelSpacing?: boolean,
  fullWidth?: boolean,
  transparent?: boolean
}

const classMap = {
  "confirm": styles.buttonConfirm,
  "info": styles.buttonInfo,
  "warning": styles.buttonWarning,
  "cancel": styles.buttonCancel
}

function Button(props: Props) {

  const { disabled, children, type, onClick, className, small = false, submit = false, addInputLabelSpacing = false, fullWidth = false, transparent = false } = props

  const fullClassName = [
    styles.button,
    type ? classMap[type] : null,
    className,
    small ? styles.smallButton : null,
    addInputLabelSpacing ? styles.buttonInputLabelSpacing : null,
    fullWidth ? styles.buttonFullWidth : null,
    transparent ? styles.buttonTransparent : null
  ].filter(i => i).join(" ");

  return (
    <button
      type={ submit ? "submit" : "button" }
      onClick={ disabled ? undefined : onClick }
      className={ fullClassName }
      disabled={ disabled }
    >
      { children }
    </button>
  )

}

export default Button