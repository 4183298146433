import { useCallback } from "react";
import { http } from "../../../api/http";
import { baseUrl } from "../../../constants";
import useModal from "../../../hooks/useModal";

export default function useQueueSetup() {
  const { createModal } = useModal();

  const searchProducts = useCallback(
    async ({
      searchAttributes,
      searchItemsList,
      itemsPerPage: limit,
      pageNumber: page,
    }) => {
      try {
        if (!searchItemsList) {
          const description = searchAttributes.description;
          const searchCategory = searchAttributes.searchCategory;
          const searchCategoryReport = searchAttributes.searchCategoryReport;
          const searchBrand = searchAttributes.searchBrand;
          const searchManufacturer = searchAttributes.searchManufacturer;
          const searchStatus = searchAttributes.searchStatus;
          const startQuantity = searchAttributes.startQuantity;
          const endQuantity = searchAttributes.endQuantity;
          const startPackQuantity = searchAttributes.startPackQuantity;
          const endPackQuantity = searchAttributes.endPackQuantity;

          const responseProduct = await http.get(
            baseUrl + `/api/v1/products/search`,
            {
              params: {
                description,
                searchCategory,
                searchCategoryReport,
                searchBrand,
                searchManufacturer,
                searchStatus,
                startQuantity,
                endQuantity,
                startPackQuantity,
                endPackQuantity,
                limit,
                page,
              },
            }
          );

          return responseProduct.data;
        } else {
          const formData = new FormData();
          formData.append("searchEansList", searchItemsList);
          const responseProduct = await http.post(
            baseUrl + `/api/v1/products/search-eans-list`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );

          return responseProduct.data;
        }
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar produtos.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const fetchPrioritizedProducts = useCallback(
    async ({ itemsPerPage: limit, pageNumber: page }) => {
      try {
        const responseProduct = await http.get(
          baseUrl + `/api/v1/products/prioritized-products`,
          { params: { limit, page } }
        );

        return responseProduct.data;
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar produtos priorizados</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const prioritizeProducts = useCallback(
    async ({ selectedItems: items, priority }) => {
      try {
        let json = {
          priority: priority,
          items: items,
        };

        await http.post(baseUrl + `/internal/api/v1/queue/product/setup`, json);
        createModal("Prioridade da fila atualizada", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar priorizar produtos</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const updateProducts = useCallback(
    async ({ newItem: newProduct, selectedItems: itemIds }) => {
      try {
        const formData = new FormData();

        formData.append("itemIds", itemIds);
        if (newProduct.description)
          formData.append("newDescription", newProduct.description?.trim());
        if (newProduct.categoryId)
          formData.append("newCategoryId", newProduct.categoryId);
        if (newProduct.brandId)
          formData.append("newBrandId", newProduct.brandId);
        if (newProduct.manufacturerId)
          formData.append("newManufacturerId", newProduct.manufacturerId);
        if (newProduct.reportId)
          formData.append("newCategoryReportId", newProduct.reportId);
        if (newProduct.unitOfMeasurement)
          formData.append("newUnitOfMeasurement", newProduct.unitOfMeasurement);
        if (newProduct.quantity)
          formData.append("newQuantity", newProduct.quantity);
        if (newProduct.quantityPerPack)
          formData.append("newQuantityPerPack", newProduct.quantityPerPack);
        if (newProduct.status) formData.append("newStatus", newProduct.status);
        if (newProduct.saleType !== null && newProduct.saleType !== "")
          formData.append("newSaleType", newProduct.saleType);
        if (newProduct.tags.length > 0)
          formData.append("newTags", newProduct.tags);
        if (newProduct.tagLine)
          formData.append("newTagLine", newProduct.tagLine);

        await http.post(baseUrl + `/api/v1/product/massive-update`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        createModal("Produtos atualizados", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar atualizar produtos.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data?.message)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const removeProductsPrioritization = useCallback(
    async ({ selectedItems: items }) => {
      try {
        await http.post(
          baseUrl + `/internal/api/v1/queue/product/delete`,
          items
        );
        createModal("Priorização dos produtos removida.", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar remover priorização de produtos.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const removeAllProductsPrioritization = useCallback(async () => {
    try {
      await http.post(baseUrl + `/internal/api/v1/queue/product/clear`);
      createModal("Priorização dos produtos removida.", { replace: true });
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar remover priorização de produtos.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, [createModal]);

  const searchCompanies = useCallback(
    async ({
      searchAttributes,
      searchItemsList,
      itemsPerPage: limit,
      pageNumber: page,
    }) => {
      try {
        if (!searchItemsList) {
          const searchCNPJ = searchAttributes.searchCNPJ;
          const searchCategory = searchAttributes.searchCategory;
          const searchChannel = searchAttributes.searchChannel;
          const searchBrand = searchAttributes.searchBrand;
          const searchCompanyName = searchAttributes.searchCompanyName;
          const searchTradingName = searchAttributes.searchTradingName;
          const searchCheckout = searchAttributes.searchCheckout;
          const searchStatus = searchAttributes.searchStatus;
          const { data } = await http.get(
            baseUrl + `/api/v1/company/search-massive`,
            {
              params: {
                searchCNPJ,
                searchCategory,
                searchChannel,
                searchBrand,
                searchCompanyName,
                searchTradingName,
                searchCheckout,
                searchStatus,
                limit,
                page,
              },
            }
          );

          return data;
        } else {
          const formData = new FormData();
          formData.append("searchCnpjList", searchItemsList);
          const { data } = await http.post(
            baseUrl + `/api/v1/company/search-cnpj-list`,
            formData,
            { headers: { "Content-Type": "form-data" } }
          );

          return data;
        }
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar empresas.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const fetchPrioritizedCompanies = useCallback(
    async ({ itemsPerPage: pageSize, pageNumber: page }) => {
      try {
        const responseProduct = await http.get(
          baseUrl + `/api/v1/company/queue/prioritized`,
          { params: { pageSize, page } }
        );

        return responseProduct.data;
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar buscar as empresas priorizadas</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const prioritizeCompanies = useCallback(
    async ({ selectedItems: items, priority }) => {
      try {
        // await http.post(baseUrl + `/api/v1/company/queue/prioritize`, form, {
        //   cancelToken
        // });
        createModal("Funcionalidade em manutenção", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar priorizar produtos</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const updateCompanies = useCallback(
    async ({ newItem: newCompany, selectedItems }) => {
      try {
        const formData = new FormData();

        formData.append("itemsIds", selectedItems);
        if (newCompany.categoryId)
          formData.append("newCategoryId", newCompany.categoryId);
        if (newCompany.brandId)
          formData.append("newBrandId", newCompany.brandId);
        if (newCompany.channelId)
          formData.append("newChannelId", newCompany.channelId);
        if (newCompany.status) formData.append("newStatus", newCompany.status);

        // await http.post(baseUrl + `/api/v1/company/massive-update`, formData, {
        //   cancelToken, headers: { "Content-Type": "form-data" }
        // });

        createModal("Funcionalidade em manutenção.", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar atualizar empresas.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const removeCompaniesPrioritization = useCallback(
    async ({ selectedItems: items }) => {
      try {
        await http.post(
          baseUrl + `/api/v1/company/queue/delete-priorities`,
          items
        );
        createModal("Priorização das empresas removida.", { replace: true });
      } catch (e) {
        createModal(
          <div className="align-center">
            <p>Ocorreu um erro ao tentar remover priorização de empresas.</p>
            {!e.response ? (
              <p>
                Verifique se está conectado à internet. Caso o problema
                persista, entre em contato com o setor de TI
              </p>
            ) : (
              <>
                <p>Código: {e.response?.status}</p>
                {e.response?.status === 500 ? (
                  <p>
                    Mensagem: Error interno / Falha na comunicação com servidor
                  </p>
                ) : (
                  <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
                )}
                <br />
                <br />
              </>
            )}
          </div>,
          { replace: true }
        );
      }
    },
    [createModal]
  );

  const removeAllCompaniesPrioritization = useCallback(async () => {
    try {
      await http.post(baseUrl + `/api/v1/company/queue/clear`);
      createModal("Priorização das empresas removida.", { replace: true });
    } catch (e) {
      createModal(
        <div className="align-center">
          <p>Ocorreu um erro ao tentar remover priorização de empresas.</p>
          {!e.response ? (
            <p>
              Verifique se está conectado à internet. Caso o problema persista,
              entre em contato com o setor de TI
            </p>
          ) : (
            <>
              <p>Código: {e.response?.status}</p>
              {e.response?.status === 500 ? (
                <p>
                  Mensagem: Error interno / Falha na comunicação com servidor
                </p>
              ) : (
                <p>Mensagem: {JSON.stringify(e.response?.data)}</p>
              )}
              <br />
              <br />
            </>
          )}
        </div>,
        { replace: true }
      );
    }
  }, [createModal]);

  return {
    searchProducts,
    fetchPrioritizedProducts,
    prioritizeProducts,
    updateProducts,
    removeProductsPrioritization,
    removeAllProductsPrioritization,
    searchCompanies,
    fetchPrioritizedCompanies,
    prioritizeCompanies,
    updateCompanies,
    removeCompaniesPrioritization,
    removeAllCompaniesPrioritization,
  };
}
