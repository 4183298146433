import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../components/Button";
import {
  useCompanyCategories,
  useCompanyService,
} from "../components/CompanyService";
import Input from "../components/Input";
import SaveBrandForm from "../components/SaveBrandForm";
import Table from "../components/Table";
import { Title } from "../components/Titles";
import { mediaPrefix } from "../constants";
import useHorusAccess from "../hooks/useHorusAccess";
import useModal from "../hooks/useModal";
import { optional } from "../utils";
import { authCredentials } from "../utils/authCredentials";

const SearchBox = styled.div`
  margin: 20px 0 -10px 0;

  div {
    width: 200px;
    display: inline-block;
  }
`;

const BrandImage = styled.img`
  max-width: 50px;
`;

function BrandsPage() {
  const { createEvent } = useHorusAccess();
  const [event] = useState({});

  // Log access event
  useEffect(() => {
    const authUser = authCredentials.get.authUser();

    event.userId = authUser.userId;
    event.category = "backoffice";
    event.action = "access brands";

    createEvent(event);
  }, []);

  // # STATE
  const [state, setState] = useState({
    brandSearch: "",
    brandSearchLoading: false,
    brands: null,
  });
  const { brands, brandSearch, brandSearchLoading } = state;

  // # OTHER HOOKS
  const { createModal } = useModal();
  const { getBrands } = useCompanyService();
  const { categories } = useCompanyCategories();

  // # LISTENERS
  const handleSearchFormOnSubmit = async (e) => {
    e.preventDefault();
    setState((old) => ({ ...old, brandSearchLoading: true }));
    try {
      const response = await getBrands({
        description: brandSearch,
        limit: 100,
      });
      setState((old) => ({
        ...old,
        brandSearchLoading: false,
        brands: response.data.brands,
      }));
    } catch (err) {
      setState((old) => ({ ...old, brandSearchLoading: false }));
      createModal("Erro ao pesquisar marcas");
    }
  };

  const handleOnBrandSaved = async () => {
    const response = await getBrands({ description: brandSearch, limit: 100 });
    setState((old) => ({
      ...old,
      brandSearchLoading: false,
      brands: response.data.brands,
    }));
  };

  // # HTML
  return (
    <>
      <Title>Cadastro de Marcas</Title>
      <form onSubmit={handleSearchFormOnSubmit}>
        <SearchBox>
          <Input
            placeholder="Pesquise Marcas"
            value={brandSearch}
            onChange={(e) =>
              setState({ ...state, brandSearch: e.target.value })
            }
          />
          <Button
            layout="confirm"
            disabled={!brandSearch || brandSearchLoading}
          >
            {brandSearchLoading ? (
              <>Carregando...</>
            ) : (
              <i className="fa fa-search" />
            )}
          </Button>
        </SearchBox>
      </form>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>ID</th>
            <th className="bigger" colSpan="2">
              Nome
            </th>
            <th style={{ textAlign: "center" }}>Categoria</th>
            <th style={{ textAlign: "center" }}>Ações</th>
          </tr>
        </thead>
        <tbody>
          {brands ? (
            brands.length ? (
              brands.map((brand) => (
                <tr key={brand.id}>
                  <td style={{ textAlign: "center" }}>{brand.id}</td>
                  <td style={{ textAlign: "center" }}>
                    {!brand.file ? null : (
                      <BrandImage src={mediaPrefix + brand.file} />
                    )}
                  </td>
                  <td className="bigger">{brand.description}</td>
                  <td style={{ textAlign: "center" }}>
                    {!categories
                      ? "Categorias carregando..."
                      : !brand.categoryId
                      ? "Sem Categoria"
                      : optional(
                          categories.find((c) => c.id === brand.categoryId)
                        )
                          .map((c) => c.id + " - " + c.description)
                          .getOrDefault("Categoria não encontrada")}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      layout="info"
                      small
                      onClick={() =>
                        createModal(
                          <SaveBrandForm
                            id={brand.id}
                            onSaved={handleOnBrandSaved}
                          />,
                          { width: "900px", backDropClose: false }
                        )
                      }
                    >
                      Editar
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nenhuma marca foi encontrada</td>
              </tr>
            )
          ) : (
            <tr>
              <td colSpan="5">Nenhuma pesquisa foi feita</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default BrandsPage;
