import { LegacyRef } from "react";
import useProductCompanyCodePage from "../../../service/product/useProductCompanyCodePage";
import Button from "../../form/Button";
import Spacer from "../../ui/Spacer";

interface Props {
  type: string;
}

export default function CsvUploadModal(props: Props) {

  const { type } = props;

  const { loadingAction, fileInput, csv, handleUploadOnClick, handleFileOnChange, handleSendCsvOnClick } = useProductCompanyCodePage();
  
  return(
    <div>
      <CsvUploadModalContent type={ type } />
      <input
        type="file"
        accept=".csv"
        ref={ fileInput as LegacyRef<HTMLInputElement> }
        style={{ display: "none" }}
        onChange={ handleFileOnChange }
      />
      { loadingAction ? (
        <div style={ { textAlign: "center" }}>
          <Button disabled={true}>Carregando</Button>
        </div>
      ) : csv.items === null || csv.items.length === 0 ? (
        <div style={{ textAlign: "center" }}>
          <Button type="info" onClick={ handleUploadOnClick }>
            Upload
          </Button>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Button type="info" onClick={ handleUploadOnClick }>
            Upload
          </Button> {" "}
          <Button type="confirm" onClick={ () => handleSendCsvOnClick(type) } disabled={ csv.items === null || csv.items.length === 0 }>
            {`Enviar ${csv.items ? csv.items.length: ""} linhas`}
          </Button>
        </div>
      )}
    </div>
  )
  
}

function CsvUploadModalContent(props: Props) {
  const { type } = props;
  
  if (type === "create") {
    return (
      <>
        <div style={{ textAlign: "center", fontWeight: "bolder", marginBottom: "1em" }}>
          Inserção em Massa
        </div>
        <div style={{ textAlign: "center" }}>
          Selecione um arquivo CSV com as colunas cnpj, cod_produto, produto_id, id_item e descricao.
        </div>
        <Spacer ratio={ 2/3 } />
        <div>
          Observações:
        </div>
        <ul>
          <li>CSV deve ser separado por ponto e vírgula.</li>
          <li>A primeira linha é um cabeçalho com os campos com os nomes das colunas.</li>
          <li>As colunas não precisam estar em uma ordem predefinida.</li>
          <li>Qualquer valor não numérico para números será considerado como NULL.</li>
        </ul>
      </>
    )
  }
  if (type === "edit") {
    return (
      <>
        <div style={{ textAlign: "center", fontWeight: "bolder", marginBottom: "1em" }}>
          Edição em Massa
        </div>
        <div style={{ textAlign: "center" }}>
          Selecione um arquivo CSV para realizar a edição.
        </div>
        <Spacer ratio={ 2/3 } />
        <div>
          Observações:
        </div>
        <ul>
          <li>O CSV deve ser separado por ponto e vírgula.</li>
          <li>A primeira linha é um cabeçalho com os campos com os nomes das colunas.</li>
          <li>O CSV obrigatoriamente precisa ter as colunas cnpj e cod_produto.</li>
          <li>O CSV pode opcionalmente ter as colunas produto_id, id_item e descricao.</li>
          <li>Só os valores das colunas enviadas no csv serão atualizadas, os valores de colunas não presentes serão mantidos.</li>
          <li>As colunas não precisam estar em uma ordem predefinida.</li>
          <li>Qualquer valor não numérico para números será considerado como NULL.</li>
        </ul>
      </>
    )
  }
  return (
    <>
      <div style={{ textAlign: "center", fontWeight: "bolder", marginBottom: "1em" }}>
        Deleção em Massa
      </div>
      <div style={{ textAlign: "center" }}>
        Selecione um arquivo CSV com as colunas cnpj e cod_produto.
      </div>
      <Spacer ratio={ 2/3 } />
      <div>
        Observações:
      </div>
      <ul>
        <li>O CSV deve ser separado por ponto e vírgula.</li>
        <li>A primeira linha é um cabeçalho com os campos com os nomes das colunas.</li>
        <li>O CSV obrigatóriamente precisa ter as colunas cnpj e cod_produto.</li>
        <li>As colunas não precisam estar em uma ordem predefinida.</li>
        <li>Qualquer valor não numérico para números será considerado como NULL.</li>
      </ul>
    </>
  );

}