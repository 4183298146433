import { ChangeEvent, ReactNode } from "react";
import styles from "./Form.module.css";

interface Props {
  label?: string
  placeholder?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string | null
  name?: string,
  addons?: Array<ReactNode>,
  disabled?: boolean,
  valid?: boolean,
  type?: string,
  accept?: string
}

function Input(props: Props) {

  const { label, onChange, value, placeholder, name, addons, type, accept, disabled = false, valid = true } = props

  return (
    <div className={ `${ styles.inputGroup } ${ disabled ? styles.inputLineDisabled : "" } ${ !valid ? styles.inputLineInvalid : "" }` }>
      {
        !label ? null : <label className={ styles.label }>{ label }</label>
      }
      <div className={ styles.inputLine }>
        <input
          className={ styles.input }
          placeholder={ placeholder }
          value={ value === null ? "" : value }
          onChange={ onChange }
          name={ name }
          disabled={ disabled }
          type={ type }
          accept={ accept }
        />
        {
          addons
        }
      </div>
    </div>
  )
}

export default Input