import { useState } from "react";
import useHandleOnChange from "../../../service/form/useHandleOnChange";
import Input from "../../form/Input";
import { ProductCompanyCode } from "../../../service/product/useProductCompanyCodeRequestsRequests";
import Button from "../../form/Button";
import useProductCompanyCodePage from "../../../service/product/useProductCompanyCodePage";

interface Props {
  productCompanyCode: ProductCompanyCode;
}

export default function ProductCompanyCodeEditModal(props: Props) {

  const { productCompanyCode } = props;

  const [ editProductCompanyCodeForm, setState ] = useState<ProductCompanyCode>({
    cnpj: productCompanyCode.cnpj,
    companyId: productCompanyCode.companyId,
    productDescription: productCompanyCode.productDescription,
    ean: productCompanyCode.ean,
    productId: productCompanyCode.productId,
    productCode: productCompanyCode.productCode,
    description: productCompanyCode.description,
    itemId: productCompanyCode.itemId,
    createDate: productCompanyCode.createDate,
    date: productCompanyCode.date,
    score: productCompanyCode.score,
  })

  const handleOnChange = useHandleOnChange(setState);

  const { loadingAction, handleEditOnConfirm } = useProductCompanyCodePage();

  return(
    <div>
      <div style={ { textAlign: "center", fontWeight: "bolder", marginBottom: "1em" } }>
        Editar
      </div>
      <Input
        onChange={ handleOnChange }
        value={ editProductCompanyCodeForm.productId?.toString() }
        name="productId"
        placeholder="Digite..."
        label="ID do Produto*"
        type="number"
      />
      <Input
        onChange={ handleOnChange }
        value={ editProductCompanyCodeForm.description }
        name="description"
        placeholder="Digite..."
        label="Descrição (app_produtobaixa)*"
      />
      <Input
        onChange={ handleOnChange }
        value={ editProductCompanyCodeForm.itemId?.toString() }
        name="itemId"
        placeholder="Digite..."
        label="ID do Item*"
        type="number"
      />
      { loadingAction ? (
        <div style={ { textAlign: "center" }}>
          <Button disabled={true}>Carregando</Button>
        </div>
      ) : (
        <div style={ { textAlign: "center" }}>
          <Button type="confirm" onClick={ () => handleEditOnConfirm(editProductCompanyCodeForm) }>Confirmar</Button>
        </div>
      )}
    </div>
  )
}