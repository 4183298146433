import { mediaPrefix } from "../../../constants";
import useCompanyListPage from "../../service/company/useCompanyListPage";
import useFetchCompanyOpts from "../../service/company/useFetchCompanyOpts";
import AsyncSelect from "../form/AsyncSelect";
import Button from "../form/Button";
import Input from "../form/Input";
import Select from "../form/Select";
import Pagination from "../ui/Pagination";
import { SectionTitle } from "../ui/SectionTitle";
import Spacer from "../ui/Spacer";
import { TableResponsiveWrapper } from "../ui/TableResponsiveWrapper";
import styles from "../ui/ui.module.css";
import companyStyles from "./styles.module.css";

function CompanyListPage() {

  const {

    handleCompanyBrandSelectOnChange, selectedBrands, cnpjList, statusOpts, handleStatusSelectOnChange, removeStatus,
    removeCompanyBrand, data, fetchData, setState, onDownloadCsvClick, handleModerateOnClick, fetchCompanyBrandOpts,
    loading, loadingExcel, handleCompanyCategorySelectOnChange, removeCompanyCategory, selectedCategories, selectedStatus

  } = useCompanyListPage();

  const { findCompanyCategoryOpts } = useFetchCompanyOpts();

  return (
    <div>
      <SectionTitle
        title="Listagem de Empresas"
        description="Consulte empresas de acordo com os filtros selecionados"
      />
      {
        (
          (
            <>
              <Input
                value={cnpjList}
                onChange={e => setState(prev => ({ ...prev, cnpjList: e.target.value }))}
                placeholder="Pode ser informado uma lista de CNPJs separados por virgula. Ex: 00000000000000,00000000000000,00000000000000"
                label="Filtrar por CNPJ"
              />

              <AsyncSelect
                fetchData={fetchCompanyBrandOpts}
                onChange={handleCompanyBrandSelectOnChange}
                label="Filtrar por marca"
              />

              <div className={styles.removeLines}>
                {
                  selectedBrands.map(brand => (

                    <div key={brand.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeCompanyBrand(brand.id)}>
                        -
                      </button>
                      {brand.name}
                    </div>

                  ))
                }
              </div>

              <AsyncSelect
                fetchData={findCompanyCategoryOpts}
                onChange={handleCompanyCategorySelectOnChange}
                label="Filtrar por Categoria"
              />

              <div className={styles.removeLines}>
                {
                  selectedCategories.map(category => (

                    <div key={category.id} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeCompanyCategory(category.id)}>
                        -
                      </button>
                      {category.name}
                    </div>

                  ))
                }
              </div>

              <Select
                options={statusOpts}
                onChange={e => handleStatusSelectOnChange(e.target.value)}
                label="Filtrar por Status"
              />

              <div className={styles.removeLines}>
                {
                  selectedStatus.map(status => (

                    <div key={status} className={styles.removeLine}>
                      <button className={styles.removeButton} onClick={() => removeStatus(status)}>
                        -
                      </button>
                      {status}
                    </div>

                  ))
                }
              </div>

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <Button type="confirm" onClick={() => fetchData(1)} disabled={loading}>
                    {loading ? "Carregando..." : "Filtrar"}
                  </Button>
                </div>
                <div>
                  <Button type="confirm" onClick={onDownloadCsvClick} disabled={loadingExcel}>
                    {loadingExcel ? "Baixando..." : "Baixar Excel"}
                  </Button>
                </div>
              </div>
              <Spacer ratio={2 / 3} />
            </>
          )
        )
      }

      <TableResponsiveWrapper>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Ações</th>
              <th>Logo</th>
              <th>CNPJ</th>
              <th>Rede</th>
              <th>Tipo de loja</th>
              <th>Canal</th>
              <th>Status</th>
              <th className={styles.left}>Nome fantasia</th>
              <th className={`${styles.fullWidth} ${styles.left}`}>Endereço</th>
            </tr>
          </thead>
          <tbody>
            {
              !data ? (
                <tr>
                  <td colSpan={15}>
                    Carregando...
                  </td>
                </tr>
              ) : (
                data.items.map(company => (
                  <tr key={company.id}>
                    <td>
                      <Button small type="warning" onClick={() => handleModerateOnClick(company.cnpj)}>
                        Moderar
                      </Button> {' '}
                    </td>
                    <td>
                      <div className={companyStyles.imageWrapper}>
                        {
                          company.file
                            ? <img src={mediaPrefix + company.file} alt="Logo da Marca" />
                            : <span className={companyStyles.imageMissing}>Sem<br />imagem</span>
                        }
                      </div>
                    </td>
                    <td>
                      {company.cnpj}
                    </td>
                    <td>
                      {company.brand}
                    </td>
                    <td>
                      {company.category}
                    </td>
                    <td>
                      {company.channel}
                    </td>
                    <td>
                      {company.status}
                    </td>
                    <td className={styles.left}>
                      {company.tradingName}
                    </td>
                    <td className={`${styles.fullWidth} ${styles.left}`}>
                      {company.address}
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </table>
      </TableResponsiveWrapper>
      {
        !data ? null : (
          <Pagination
            page={data.page}
            totalPages={data.total}
            onChangePage={fetchData}
          />
        )
      }
    </div>
  );

}

export default CompanyListPage;