import styles from "./ui.module.css";

export type OnChagePage = (page: number) => void

interface Props {

  page: number;
  totalPages?: number;
  onChangePage?: OnChagePage;
  hasMore?: boolean;

}

function Pagination(props: Props) {

  const { page, totalPages, onChangePage = () => null, hasMore = true } = props

  if (totalPages === undefined) return (
    <div className={ styles.pagination }>
      {
        page === 1 ? null : (
          <div onClick={ () => onChangePage(page - 1) } className={ `${ styles.paginationItem }` }>
            Anterior
          </div>
        )
      }
      {
        !hasMore ? null : (
          <div onClick={ () => onChangePage(page + 1) } className={ `${ styles.paginationItem }` }>
            Próximo
          </div>
        )
      }
    </div>
  );

  if (totalPages === 1) {
    return null;
  }

  const buttons = []

  let skip = false;

  for (let i = 1; i <= totalPages; i++) {

    if (Math.abs(page - i) > 2 && i !== 1 && i !== totalPages) {

      if (skip) continue;

      buttons.push(
        <div key={ i } className={ `${ styles.paginationItem } ${ styles.paginationItemDisabled }` }>
          ...
        </div>
      )

      skip = true;

      continue;

    }

    skip = false;

    buttons.push(
      <div key={ i } onClick={ () => onChangePage(i) } className={ `${ styles.paginationItem } ${ i === page ? styles.paginationItemActive : "" }` }>
        { i }
      </div>
    )
  }

  return (
    <div className={ styles.pagination }>
      { buttons }
    </div>
  )
}

export default Pagination;