import { NavLink } from "react-router-dom";
import { Menu } from "../../types";
import styles from "./ui.module.css";

type Option = { link: string, name: string }

export interface Props {
  options?: Array<Option>;
  menu?: Menu;
  tab?: string;
  onSelectTab?: (option: Option) => void;
}

function linkClassName(isActive: boolean) {
  return isActive ? styles.activeTab : ""
}

function Tabs(props: Props) {

  const options = getOptions(props);
  const { onSelectTab, tab } = props;

  return (
    <div>
      <ul className={ styles.tabs }>
        {
          options.map(o => (
            <li key={ o.link }>
              {
                onSelectTab ? (
                  <button className={ linkClassName(tab === o.link) } onClick={ () => onSelectTab(o) }>
                    { o.name }
                  </button>
                ) : (
                  <NavLink className={ linkClassName } to={ o.link }>
                    { o.name }
                  </NavLink>
                )
              }
            </li>
          ))
        }
      </ul>
    </div>
  )

}

function getOptions(props: Props) {

  if (props.menu) {
    return props.menu.subMenus.map(i => ({
      link: i.url, name: i.name
    }))
  }

  if (props.options) {
    return props.options;
  }

  return [];

}

export default Tabs;