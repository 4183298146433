import styled from 'styled-components'

export const PlanTitle = styled.h1`
  color: red;
  font-weight: 500;
  font-size: 50px;
  text-align: center;
`
export const PlanPrice = styled.h3`
  font-weight: 500;
  text-align: center;
`
export default PlanTitle