import useCompanyModerationSearchPage from "../../service/company/useCompanyModerationSearchPage";
import Button from "../form/Button";
import { SectionTitle } from "../ui/SectionTitle";

export default function CompanyModerationSearchPage() {
  const { loading, handleFetchQueue } = useCompanyModerationSearchPage();

  return (
    <>
      <SectionTitle
        title="Fila Automatizada de Moderação"
        description="Inicia a fila de moderação de empresas, seguindo as diretrizes de ordenação."
      ></SectionTitle>
      <Button disabled={loading} type="cancel" onClick={handleFetchQueue}>
        {loading ? "Carregando..." : "Iniciar Moderação"}
      </Button>
      <br />
      <br />
    </>
  );
}
