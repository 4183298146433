import AsyncSelect2 from "../../AsyncSelect2";
import Collapse from "../../Collapse";

export default function  SearchCategory({ category, setCategory, onSelectNielsenCategory, goToEdit, formType, fetchCategories, fetchNielsenCategories }) {

  return (
    <>
      <Collapse collapsed={formType !== "PRODUCTCATEGORY"}>
        <div>
          <AsyncSelect2
            label="Buscar Categoria de Produto para Editar"
            value={category?.id}
            valueName={category?.name}
            onChange={option => {
              if (option?.id) {
                setCategory(option);
                goToEdit(option);
              }
            }}
            fetchData={fetchCategories}
          />
        </div>
      </Collapse>
      <Collapse collapsed={formType !== "NIELSENCATEGORY"}>
        <div>
          <AsyncSelect2
            label="Buscar Categoria Nielsen para Editar"
            value={category?.id}
            valueName={category?.name}
            onChange={option => { if (option?.id) onSelectNielsenCategory(option) }}
            fetchData={fetchNielsenCategories}
          />
        </div>
      </Collapse>
    </>
  );
}
