import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { http } from "../api/http";
import { companyUrl, mediaPrefix } from "../constants";
import useModal from "../hooks/useModal";
import { makeValidator } from "../utils";
import Button from "./Button";
import { useCompanyCategories, useCompanyService } from "./CompanyService";
import { Col, Row } from "./Grid";
import Input from "./Input";

const leftCol = "70%";
const rightCol = "30%";
const fullCol = "100%";

const BrandImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
`;

const DropZone = styled.div`
  border: solid 1px black;
  height: 226px;
  margin-top: 16px;
  position: relative;
`;

const InfoBox = styled.div`
  margin: -15px 0 15px 0;
  font-size: 10px;
  color: gray;
`;

const UploadButtonWrapper = styled.div`
  text-align: center;
  padding: 5px;
`;

const validateCategory = (c) => (!c ? ["Categoria é obrigatória"] : null);
//const validateFile = c => !c ? [ "Logo é obrigatória" ] : null;
const validateName = (n) => (!n ? ["Nome é obrigatório"] : null);
const validateCNPJ8 = (cnpj8) =>
  !cnpj8 ? null : !cnpj8.match(/^\d{8}$/) ? ["Cnpj8 possui 8 dígitos"] : null;

const brandValidator = makeValidator({
  description: validateName,
  cnpj8: validateCNPJ8,
  categoryId: validateCategory,
});

function SaveBrandForm(props) {
  const {
    getAllErrors,
    getCategoryIdErrors,
    getDescriptionErrors,
    getCnpj8Errors,
  } = brandValidator;

  // # PROPS
  const { id: brandId, onSaved, cnpj8 } = props;

  // # STATE
  const [
    {
      brand,
      companiesCount,
      imageSrc,
      savingBrand,
      /*fileSelected,*/ economicGroups,
    },
    setState,
  ] = useState({
    brand: brandId ? null : { cnpj8 },
    imageSrc: "/static/placeholder.png",
    companiesCount: 0,
    savingBrand: false,
    fileSelected: false,
    economicGroups: [],
  });
  const errors = getAllErrors({
    ...brand,
  });

  // # REFS
  const fileInput = useRef();

  // # OTHER HOOKS
  const { categories } = useCompanyCategories();
  const { getBrand, saveBrand, getEconomicGroups, removeBrand } =
    useCompanyService();
  const { toggleModal, createModal } = useModal();

  // # HELPERS
  const updateBrand = (patch) =>
    setState((prev) => ({ ...prev, brand: { ...prev.brand, ...patch } }));

  // # VARIABLES
  const categoryOptions = useMemo(
    () =>
      categories
        ? [
            { id: null, name: "Sem Categoria" },
            ...categories.map((c) => ({
              id: c.id,
              name: c.id + " - " + c.description + " - Canal " + c.channel,
              channelId: c.channel?.toString(),
            })),
          ]
        : null,
    [categories]
  );

  // # FUNCTIONS
  const onUploadFileClick = () => {
    fileInput.current.click();
  };

  const setPreviewImage = (file) => {
    if (file.size > 128000) {
      alert("Este arquivo excede o limite de 128KB!");
      return;
    }

    if (!file) {
      setState((prev) => ({
        ...prev,
        fileSelected: false,
        imageSrc: prev.brand
          ? mediaPrefix + prev.brand.file
          : "/static/placeholder.png",
      }));

      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setState((prev) => ({
        ...prev,
        fileSelected: true,
        imageSrc: e.target.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  const onUploadFileSelected = (e) => {
    const file = fileInput.current.files[0];
    setPreviewImage(file);
  };

  const onDrop = (e) => {
    e.preventDefault();
    fileInput.current.files = e.dataTransfer.files;
    setPreviewImage(e.dataTransfer.files[0]);
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onSubmitClick = async (e) => {
    try {
      const data = { ...brand };
      if (fileInput.current.files.length) {
        data.file = fileInput.current.files[0];
      }
      setState((prev) => ({ ...prev, savingBrand: true }));
      const response = await saveBrand(data);
      createModal("Marca salva com sucesso", { replace: true });
      await new Promise((r) => setTimeout(r, 1000));
      if (onSaved) onSaved({ brand: response.data.brand });
    } catch (err) {
      createModal("Erro ao salvar marca");
    }
    setState((prev) => ({ ...prev, savingBrand: false }));
  };

  const onSubmitBrandToBeRemoved = async (brandId) => {
    setState((prev) => ({ ...prev, savingBrand: true }));
    await removeBrand(brandId);
    setState((prev) => ({ ...prev, savingBrand: false }));
  };

  const updateKeyAccount = async (e) => {
    const keyAccountAux = await http.get(
      companyUrl + `/api/v1/brand/get_key_account/`,
      { params: { query: e } }
    );
    updateBrand({ grandeRede: keyAccountAux.data });
  };

  // # EFFECTS
  useEffect(() => {
    if (brandId && categoryOptions)
      getBrand(brandId, { countCompanies: true }).then((r) =>
        setState((prev) => ({
          ...prev,
          brand: r.data.brand,
          companiesCount: r.data.companiesCount,
          imageSrc: r.data.brand.file
            ? mediaPrefix + r.data.brand.file
            : "/static/placeholder.png",
        }))
      );
  }, [brandId, categoryOptions, setState, getBrand]);

  const currentEconomicGroup = brand ? brand.economicGroup : null;
  // const currentKeyAccount = brand ? brand.grandeRede : false;

  useEffect(() => {
    let timeout;
    if (!currentEconomicGroup) return;
    timeout = setTimeout(() => {
      getEconomicGroups({ limit: 10, name: currentEconomicGroup }).then(
        ({ data: { economicGroups } }) =>
          setState((prev) => ({ ...prev, economicGroups }))
      );
    }, 500);
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [currentEconomicGroup, getEconomicGroups, setState]);

  // # HTML
  if (!brand) {
    return <>Carregando...</>;
  }

  return (
    <>
      <Row>
        <Col width={leftCol}>
          <Input
            label="Nome*"
            placeholder="Nome"
            value={brand.description}
            onChange={(e) => updateBrand({ description: e.target.value })}
            validate={getDescriptionErrors}
          />
          <div className="h-economic-group-div">
            <Input
              list="economic-groups"
              label="Grupo Econômico"
              placeholder="Grupo Econômico"
              value={brand.economicGroup}
              onChange={(e) => {
                e.preventDefault();
                updateBrand({
                  economicGroup: e.target.value
                    ? e.target.value.toUpperCase()
                    : null,
                });
                updateKeyAccount(e.target.value);
              }}
            />
            <datalist id="economic-groups">
              {economicGroups.map((e) => (
                <option key={e.ecgroup}>{e.ecgroup}</option>
              ))}
            </datalist>
            <label className="h-checkbox">
              <input
                type="checkbox"
                checked={brand.grandeRede}
                onClick={() => {
                  brand.grandeRede
                    ? updateBrand({ grandeRede: false })
                    : updateBrand({ grandeRede: true });
                }}
              />
              Grande rede
            </label>
          </div>
          <Input
            label="Categoria*"
            value={categories ? brand.categoryId : "Carregando..."}
            onChange={(e) => {
              updateBrand(
                categoryOptions.filter(
                  (c) => c?.id === parseInt(e.target.value)
                )[0] === undefined
                  ? { categoryId: null, channelId: null }
                  : {
                      categoryId: e.target.value,
                      channelId: categoryOptions
                        .filter((c) => c?.id === parseInt(e.target.value))[0]
                        .channelId?.toString(),
                    }
              );
            }}
            options={categoryOptions}
            readOnly={!categories}
            validate={getCategoryIdErrors}
          />
          {!companiesCount ? null : (
            <InfoBox>Afetará {companiesCount} empresas.</InfoBox>
          )}
          <Input
            label="Cnpj8"
            placeholder="Cnpj8"
            value={brand.cnpj8}
            onChange={(e) => updateBrand({ cnpj8: e.target.value })}
            validate={getCnpj8Errors}
          />
          <input
            ref={fileInput}
            type="file"
            style={{ display: "none" }}
            onChange={onUploadFileSelected}
          />
        </Col>
        <Col width={rightCol}>
          <DropZone onDrop={onDrop} onDragOver={onDragOver}>
            {!imageSrc ? null : <BrandImage src={imageSrc} />}
          </DropZone>
          <UploadButtonWrapper>
            <Button layout="info" small onClick={onUploadFileClick}>
              Enviar Imagem
            </Button>
            *
          </UploadButtonWrapper>
        </Col>
        <Col width={fullCol}>
          <Button
            layout="confirm"
            onClick={onSubmitClick}
            disabled={savingBrand || errors}
          >
            {savingBrand
              ? "Enviando..."
              : "Enviar " + (errors ? "(" + errors.length + " erros)" : "")}
          </Button>
          &nbsp;
          <Button onClick={() => toggleModal(false)}>Cancelar</Button>
          {!brandId ? null : (
            <>
              &nbsp;
              <Button
                layout="error"
                onClick={() => onSubmitBrandToBeRemoved(brandId)}
                disabled={savingBrand || companiesCount !== 0}
              >
                Excluir
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default SaveBrandForm;
