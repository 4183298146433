import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";

const ModalBack = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(4px);
  top: 0;
  left: 0;
  display: ${({ show }) => (show ? "block" : "none")};
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
`;

const Centered = styled.div`
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => props.width || "600px"};
  transition: 0.5s;
  opacity: 0;
  ${(props) =>
    props.opened &&
    css`
      top: 50%;
      opacity: 1;
    `}
`;

const ModalBody = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 2px 2px 25px 1px rgba(0, 0, 0, 0.55);
  border-radius: 6px;
`;

const Close = styled.div`
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bolder;
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

function Modal(props, ref) {
  // # PROPS
  const {
    onClose = props.close, // close now is called onClose, I do this for compatibility
    children,
    width,
    backDropClose = true,
    hidden = false,
  } = props;

  // # REFS
  const openedRef = useRef(true);

  // # STATE
  const [changing, setChanging] = useState(false);
  const [opened, setOpened] = useState(false);

  // # FUNCTIONS
  const toggleModal = useCallback(
    (open) => {
      setChanging(true);
      openedRef.current = open;
      setOpened(open);
    },
    [setChanging, setOpened, openedRef]
  );

  const onClickModalBack = (e) => {
    if (backDropClose && e.target.classList.contains("modal-back"))
      toggleModal(false);
  };

  const handleCloseOnClick = useCallback(
    () => toggleModal(false),
    [toggleModal]
  );

  // # EFFECTS
  useEffect(() => toggleModal(true), [toggleModal]);

  useEffect(() => {
    let timeout = null;

    if (!changing) return;

    timeout = setTimeout(() => {
      setChanging(false);
      if (!openedRef.current) onClose();
    }, 500);

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [changing, openedRef, onClose]);

  useEffect(() => {
    const timeout = setTimeout(() => toggleModal(true), 200);
    return () => clearTimeout(timeout);
  }, [toggleModal]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") toggleModal(false);
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [toggleModal]);

  // # IMPERATIVE HANDLE

  useImperativeHandle(ref, () => {
    return {
      toggleModal,
    };
  });

  // # HTML

  if (hidden) {
    return null;
  }

  return (
    <ModalBack
      onClick={onClickModalBack}
      className="modal-back"
      show={opened || changing}
    >
      <Centered opened={opened} width={width}>
        <Close onClick={handleCloseOnClick}>Clique aqui para fechar</Close>
        <ModalBody>{children}</ModalBody>
      </Centered>
    </ModalBack>
  );
}

export default forwardRef(Modal);
