function GroupedSelect(props) {
  const {
    value, onChange, options = [], id, label, placeholder = "Selecione..."
  } = props;

  return (
    <div className="h-input-wrapper">
      <label htmlFor={ id } className="h-form-label">
        { label }
      </label>
      <select
        id={ id }
        value={ value }
        onChange={ onChange }
        className="h-input">
        <option value="">{ placeholder }</option>
        {
          options.map((group, i) => (
            <optgroup label={ group.type } key={ i }>
              {
                group.options.map((option, j) => (
                  <option key={ j } value={ option.id }>{ option.name }</option>
                ))
              }
            </optgroup>
          ))
        }
      </select>
    </div>
  )
}

export default GroupedSelect;