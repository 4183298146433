import { useCallback, useEffect, useRef } from "react";

function useSetTimeout() {
  const handle = useRef();

  useEffect(() => {
    return () => { if (handle.current) clearTimeout(handle.current) };
  }, [ handle ])

  const setTimeout = useCallback((fn, delay) => {
    if (handle.current) clearTimeout(handle.current);
    
    handle.current = window.setTimeout(fn, delay);
  }, [ handle ])

  return setTimeout;
}

export default useSetTimeout;