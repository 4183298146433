import { http } from "../api/http";
import { baseUrl } from "../constants";

function useDashboard() {
  const getAllDashboards = () => http.get(baseUrl + "/dashboard");

  const getAllDashboardUsers = (dashId) =>
    http.get(baseUrl + "/dashboard-users/" + dashId);

  const getAllDashboardGroups = (dashId) =>
    http.get(baseUrl + "/dashboard-groups/" + dashId);

  const getAllDashboardCompanies = (dashId) =>
    http.get(baseUrl + "/dashboard-companies/" + dashId);

  const getDashboard = (dashId) =>
    http.get(baseUrl + "/dashboard/id/" + dashId);

  const createDash = (dash) => http.post(baseUrl + "/dashboard", dash);

  const updateDash = (dash) => http.put(baseUrl + "/dashboard", dash);

  const getDatasetId = (dashboard) =>
    http.post(baseUrl + "/embed/datasets", dashboard);

  const dashVisualizationPermission = (dashboard) =>
    http.post(baseUrl + "/dashboard/permissions", dashboard);

  const internalDashVisualizationPermission = (CompanyId, dashId) =>
    http.get(baseUrl + "/dashboard/permissions/" + CompanyId + "/" + dashId);

  const removeUserSegmentation = (CompanyId, dashId, userId) =>
    http.delete(
      baseUrl +
        "/dashboard/permissions/" +
        CompanyId +
        "/" +
        dashId +
        "/" +
        userId
    );

  return {
    getAllDashboards,
    getAllDashboardUsers,
    getAllDashboardGroups,
    getAllDashboardCompanies,
    getDashboard,
    createDash,
    updateDash,
    getDatasetId,
    dashVisualizationPermission,
    internalDashVisualizationPermission,
    removeUserSegmentation,
  };
}

export default useDashboard;
